import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageModalComponent } from '../../../message/message-modal.component';
import { AppConstants } from '../../../../../constants/app.contants';
import { CameraPost, UserValidate } from '../../../../../../models/alert/alert';
import { CameraService } from '../../../../../../services/camera/camera.service';
import { AlertService, MessageSeverity } from '../../../../../services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { EmitterService } from '../../../../../services/emitter.service';
import { DatePipe } from '@angular/common';
import { OcurrenceService } from 'src/app/services/Ocurrence/ocurrence.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'panel-camera-alert-modal',
  templateUrl: './panel-camera-alert-modal.component.html',
  styleUrls: ['./panel-camera-alert-modal.component.css'],
  providers: [DatePipe]
})

export class PanelCameraAlertModalComponent implements OnInit {

  alertForm: FormGroup

  hide = true;

  subscription: Subscription;

  loading: boolean = false;

  myDate = new Date();
  localSave: string;
  horaSave: string;
  update = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private CameraService: CameraService,
    private alertService: AlertService,
    private emitterService: EmitterService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<PanelCameraAlertModalComponent>,
  ) { }

  ngOnInit() {
    this.localSave = this.datePipe.transform(this.myDate, 'yyyy/MM/dd');
    this.horaSave = this.datePipe.transform(this.myDate, 'HH:mm');
    this.createForm();
    this.subscription = this.emitterService.getMessage().subscribe(message => {
      if (message == AppConstants.EmitterService.ListPanelCamara) {

      }
    });
  }

  createForm() {
    this.alertForm = this.formBuilder.group({
      // password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      description: [''] // , [Validators.required, Validators.maxLength(500)]
    })
  }

  get f() { return this.alertForm.controls; }

  postCameraSave(model: CameraPost) {
    if(this.data.stateId == 2){
      this.CameraService.postCameraAlertAttentioon(model).subscribe(response => {
        this.alertService.showMessage(
          AppConstants.TitleModal.CREATE_OCURRENCE,
          AppConstants.MessageModal.OCURRENCE_MESSAGER_CONFIRM + ' "' + this.data.mode2 + '" ' + AppConstants.MessageModal.OCURRENCE_NEXT_CONFIRM,
          MessageSeverity.success);
        this.emitterService.sendMessage(AppConstants.EmitterService.ListPanelCamara);
      },
        (error: HttpErrorResponse) => {
          this.alertService.showMessage(
            AppConstants.TitleModal.ERROR_TITLE,
            AppConstants.MessageModal.DATA_ERROR_MESSAGE,
            MessageSeverity.error);
      });
    }else{
      this.CameraService.postCameraAlert(model).subscribe(response => {

        this.alertService.showMessage(
          AppConstants.TitleModal.CREATE_OCURRENCE,
          AppConstants.MessageModal.OCURRENCE_MESSAGER_CONFIRM + ' "' + this.data.mode2 + '" ' + AppConstants.MessageModal.OCURRENCE_NEXT_CONFIRM,
          MessageSeverity.success);
        this.emitterService.sendMessage(AppConstants.EmitterService.ListPanelCamara);
      },
        (error: HttpErrorResponse) => {
          this.alertService.showMessage(
            AppConstants.TitleModal.ERROR_TITLE,
            AppConstants.MessageModal.DATA_ERROR_MESSAGE,
            MessageSeverity.error);
      });
    }

    let registers =
    {
        cameraId: this.data.cameraId
    }
    this.CameraService.postColor(registers).subscribe(
      result => {
      },
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
      }
    );

    this.update = true;
    this.close();
  }

  UserValidateSend(modelUser: UserValidate) {
    // this.CameraService.postUserValidate(modelUser).subscribe(response => {

      // if (response) {

        // this.OcurrenceService.OcurrenceById(this.data.ocurrenceId).subscribe((responses) => {
        //   debugger;
          var model = new CameraPost;
          model.idOcurrence = this.data.ocurrenceId;
          model.EnterpriseId = this.data.enterpiseId;
          model.CameraId = this.data.cameraId;
          model.AlertId = this.data.alertId;
          model.Description = this.alertForm.value["description"];
          model.userId = parseInt(localStorage.getItem(AppConstants.Session.USERID));
          model.stateId = this.data.stateId;
          model.idAttention = this.data.attetionI;
          model.DateRegister =  this.localSave+"T"+this.horaSave+":21.632Z";
          this.postCameraSave(model);
        // });

      // } else {

      //   this.alertService.showMessage(
      //     AppConstants.TitleModal.WARNING_TITLE,
      //     AppConstants.MessageModal.USERNAME_PASSWORD_ERROR_MESSAGE,
      //     MessageSeverity.error);

      // }

    // },
    //   (error: HttpErrorResponse) => {
    //     this.alertService.showMessage(
    //       AppConstants.TitleModal.ERROR_TITLE,
    //       AppConstants.MessageModal.USERNAME_PASSWORD_ERROR_MESSAGE,
    //       MessageSeverity.error);
    //   });

      // this.loading = false;
  }

  openConfirmDialog() {
    if (this.alertForm.valid) {
      const dialogRef = this.dialog.open(MessageModalComponent, {
        data: {
          title: AppConstants.TitleModal.CONFIRM_TITLE,
          message: AppConstants.MessageModal.REGISTER_MESSAGE_ALERT_CONFIRM,
          hasButton: true,
          contentButton: {
            yes: 'SI',
            no: 'CANCELAR'
          }
        },
      })

      dialogRef.afterClosed().subscribe(response => {
        if (response.result == true) {
          if (this.alertForm.valid) {
            if (this.alertForm.value["description"] == "" ||
              this.alertForm.value["description"] == undefined) {

              this.alertService.showMessage(
                AppConstants.TitleModal.WARNING_TITLE,
                AppConstants.MessageModal.REQUIRED_FIELDS_MESSAGE,
                MessageSeverity.error);

              return false;
            }

            this.loading = true;
            var modelUser = new UserValidate;
            modelUser.userId = parseInt(localStorage.getItem(AppConstants.Session.USERID));
            modelUser.password = this.alertForm.value["password"];

            this.UserValidateSend(modelUser);
          }else{
            this.alertForm.markAllAsTouched();
          }
        }else{
          if (this.alertForm.valid) {
          }else{
            this.alertForm.markAllAsTouched();
          }
        }
      });
    }else{
      this.alertForm.markAllAsTouched();
    }
  }

  close() {
    this.dialogRef.close({update: this.update});
  }

  closeModal(){
    this.update = false;
    this.close();
  }

}
