import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import { SensorReadingplaceService } from 'src/app/services/sensor-readingplace/sensor-readingplace.service';
import { SensorService } from 'src/app/services/sensor/sensor.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { MessageModalComponent } from '../message/message-modal.component';
import { SensorTypeService } from 'src/app/services/sensor-type/sensor-type.service';
import { PinModel } from 'src/app/models/zone/pin';
import { ZoneService } from 'src/app/services/zone/zone.service';

@Component({
  selector: 'app-panel-sensor-modal',
  templateUrl: './panel-sensor-modal.component.html',
  styleUrls: ['./panel-sensor-modal.component.css']
})
export class PanelSensorModalComponent implements OnInit {

  sensorForm: FormGroup;
  sensors = [];
  graphs = [];
  sensorReadingPlaces = [];
  sensorTypes = [];
  sensorTypeSelect = 0;
  sensorTypeGraphSelect = 0;
  listPinMaps = Array<PinModel>();
  selectPinId:number;
  selectPinPath:number;
  listDefaultPinMaps = Array<PinModel>();
  constructor(
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private sensorService: SensorService,
    private sensorTypeService: SensorTypeService,
    private sensorReadingplaceService: SensorReadingplaceService,
    private panelIOTService: PanelIOTService,
    public dialog: MatDialog,
    private zoneService: ZoneService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getSensors();
    this.getListPin();
    this.getListPinDefault();
  }

  setValue(){
    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.sensorForm.controls['nomenclature'].setValue(this.data.obj.nomenclature);
      this.sensorForm.controls['idSensor'].setValue(this.data.obj.idSensor);
      this.sensorForm.controls['idReadingPlace'].setValue(this.data.obj.idSensorReadingPlace);
      this.sensorForm.controls['streamingTime'].setValue(this.data.obj.streamingTime);
      this.sensorForm.controls['minAlert'].setValue(this.data.obj.minAlert);
      this.sensorForm.controls['maxAlert'].setValue(this.data.obj.maxAlert);
    }
  }

  createForm(){
    this.sensorForm = this.formBuilder.group({
      idSensor: ['', [Validators.required]],
      idSensorType: ['', [Validators.required]],
      idGraph: ['', [Validators.required]],
      nomenclature: ['', [Validators.required,  Validators.maxLength(30),  Validators.minLength(2)]],
      idReadingPlace: ['', [Validators.required]],
      streamingTime: [60, [Validators.required]],
      minAlert: [0],
      maxAlert: [0]
    });
  }

  getListPin() {

    var filter = {
      idEnterprise: this.data.idEnterprise,
      idHeadquarter: this.data.idHeadquarter,
      idSystem: 2
    }
    console.log(filter);
    this.zoneService.getListPines(filter).subscribe((response => {
      if(response.error == null){
        this.listPinMaps = response.data;
        /*if(this.listPinMaps.length > 0){
          this.locationForm.controls['idPinMap'].setValue(this.listPinMaps[0].id);
        }*/
      }
      /*if(response.idStorageFile == 1){ // Mode Local
         response.enterprisePinMap.forEach(function (x) {
          x.path = environment.pathFile + x.path;
        });
      }
      this.listPinMaps = response.enterprisePinMap;
      if(this.listPinMaps.length > 0){
        this.locationForm.controls['idPinMap'].setValue(this.listPinMaps[0].pinMapId);
      }*/
    }),
    (error: HttpErrorResponse) => {
    });
}

getListPinDefault(){
  var filter = {
    idSystem: 2
  }
  this.zoneService.getListDefaultPines(filter).subscribe((response => {
    if(response.error == null){
      this.listDefaultPinMaps = response.data;
    }
  }),
  (error: HttpErrorResponse) => {
  });
}

selectPinList(item){
  this.selectPinId = item.id;
  this.selectPinPath = item.path;
}

  get f() { return this.sensorForm.controls; }

  getGraphs(change = 0){
    this.spinner.show();
    this.sensorTypeService.getListGraphAll(this.sensorTypeSelect).subscribe((response) => {
      if(response.error == null){
        this.graphs = response.data;
        if(this.data.action == AppConstants.OperationType.UPDATE){
          this.sensorForm.controls['idGraph'].setValue(this.data.obj.idGraph);
        }else{
          this.sensorForm.controls['idGraph'].setValue(this.graphs[0].id);
        }
      }
      if(change == 0){
        this.getSensorReadingplaces();
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
    });
  }

  getSensorTypes(){
    this.spinner.show();
    this.sensorTypeService.getListAll().subscribe((response) => {
      if(response.error == null){
        this.sensorTypes = response.data;
        if(this.data.action == AppConstants.OperationType.UPDATE){
          this.sensorForm.controls['idSensorType'].setValue(this.data.obj.idSensorType);
          this.sensorTypeSelect = this.data.obj.idSensorType;
        }else{
          this.sensorForm.controls['idSensorType'].setValue(this.sensorTypes[0].id);
          this.sensorTypeSelect = this.sensorTypes[0].id;
        }
      }
      this.spinner.hide();
      this.getGraphs();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
    });
  }

  changeSensorType(e:any){
    this.sensorTypeSelect = e.value;
    this.getGraphs(1);
  }

  getSensorReadingplaces(){
    this.spinner.show();
    this.sensorReadingplaceService.getListAll(this.data.idEnterprise,this.data.idHeadquarter).subscribe((response) => {
      if(response.error == null){
        this.sensorReadingPlaces = response.data;
        if(this.sensorReadingPlaces.length>0){
          this.sensorForm.controls['idReadingPlace'].setValue(this.sensorReadingPlaces[0].id);
        }
      }
      this.setValue();
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
    });
  }

  getSensors(){
      this.sensors=this.data.sensors;
      if(this.data.action == AppConstants.OperationType.ADD){
        this.sensorForm.controls['nomenclature'].setValue(this.sensors[0].nomenclatureInit);
        this.sensorForm.controls['idSensor'].setValue(this.sensors[0].id);
      }
      this.getSensorTypes();
  }

  save(){
    if(this.sensorForm.valid && this.selectPinId){
      const payload = {
        id: this.data.action == AppConstants.OperationType.ADD ? 0 : this.data.obj.id,
        nomenclature: this.sensorForm.value["nomenclature"],
        idGraph:this.sensorForm.value["idGraph"],
        idSensorType:this.sensorForm.value["idSensorType"],
        idEnterprise: this.data.isAdminGys ? this.data.idEnterprise : localStorage.getItem(AppConstants.Session.ENTERPRISE),
        idHeadquarter: this.data.idHeadquarter,
        idZone: this.data.idZone,
        idGateway: this.data.idGateway,
        idSensor: this.sensorForm.value["idSensor"],
        idSensorReadingPlace: this.sensorForm.value["idReadingPlace"],
        idUser: localStorage.getItem(AppConstants.Session.USERID),
        streamingTime:this.sensorForm.value["streamingTime"],
        minAlert:this.sensorForm.value["minAlert"],
        maxAlert:this.sensorForm.value["maxAlert"],
        idPin: this.selectPinId
      }

      if(this.data.action == AppConstants.OperationType.ADD){
        this.panelIOTService.createSensor(payload).subscribe(_ => {
          if(_.message == null || _.message == ""){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_HEADQUARTER, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
      }else{
        this.panelIOTService.updateSensor(payload).subscribe(_ => {
          if(_.message == null || _.message == ""){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_HEADQUARTER, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
      }

    }else{
      if(!this.selectPinId){
        this.alertService.showMessage("", "Seleccione el Pin del sensor", MessageSeverity.error);
      }
      this.sensorForm.markAllAsTouched();
    }
  }

  changeSensor(data) {
    var sensor = this.sensors.filter(x => x.id == data.value)[0];
    this.sensorForm.controls['nomenclature'].setValue(sensor.nomenclatureInit);
  }

  close() {
    this.dialog.closeAll();
  }


  delete() {
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
        message: `${AppConstants.MessageModal.DELETE_DEFAULT}`,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteItem();
      }
    });

  }

  deleteItem() {
      this.spinner.show();
      this.panelIOTService.deleteSensor(this.data.obj.id).subscribe((response => {
        this.spinner.hide();
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SENSOR, AppConstants.MessageModal.DELETE, MessageSeverity.success);
        this.dialog.closeAll();
      }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SENSOR, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
    }
}

