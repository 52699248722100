import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, Pipe, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CameraService } from 'src/app/services/camera/camera.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Chart } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import { MessageSeverity, AlertService } from 'src/app/shared/services/alert.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-report-sensor-pbi-show-room',
  templateUrl: './modal-report-sensor-pbi-show-room.component.html',
  styleUrls: ['./modal-report-sensor-pbi-show-room.component.css']
})

@Pipe({ name: 'safe' })

export class ModalReportSensorPbiShowRoomComponent implements OnInit {
  url: SafeResourceUrl;

  constructor(
    private domSanitizer: DomSanitizer,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/view?r=eyJrIjoiMWU5Nzc1ZTQtY2M3MC00MzE1LTg5OGQtOWFhYTAzNTQzMzYzIiwidCI6ImM5NjYxYzI4LTdkN2QtNDJjMS05OGY5LTQ2MTQ4ZTNhNmIzYiIsImMiOjR9");
  }
  
  closeModal(){
    this.dialog.closeAll();
  }
}
