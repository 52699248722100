import { ChangeContext } from '@angular-slider/ngx-slider';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ModalReportSensorPbiShowRoomComponent } from 'src/app/shared/components/modals/showroom/modal-report-sensor-pbi/modal-report-sensor-pbi-show-room.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-showroom-panel-diagram',
  templateUrl: './showroom-panel-diagram.component.html',
  styleUrls: ['./showroom-panel-diagram.component.css']
})
export class ShowroomPanelDiagramComponent implements OnInit {
  private _hubConnection: HubConnection;
  imagePanelOrden = 1;
  showActuatorPanel=false;
  actuators = [];
  staticActuators = [];
  startButton = 0;
  stopButton = 0;
  startAlert = 0;
  stopAlert = 0;
  imagePanel = "";
  stepIndex: number = 0;
  showScreenshot = false;
  showMenu = false;
  docElement: HTMLElement;
  isFullScreen: boolean = false;
  nivel = "";
  temperatura = 0;
  presion = "";
  showDataPanel = true;
  bomba = 0;

  constructor(
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public showroomService: ShowRoomService) {
      var _this = this;
      this.imagePanel = environment.pathFile + "assets/showroom/"+this.showroomService.getImageDiagram()+".png";
      setInterval(function () {
        _this.listPanels();
      },
      1000);

      var _this = this;
      const connection =  this._hubConnection = new HubConnectionBuilder()
        .withUrl(environment.wsUrl +"/ws/showroom",{
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build();
        var _this = this;
        console.log(environment.wsUrl +"/ws/showroom");
        connection.on("updatePanel", (panel) => {
          if(panel.value == "1"){ document.getElementById('actuactorPanel'+panel.id).style.display="initial"; }
              else{ document.getElementById('actuactorPanel'+panel.id).style.display="none"; }
        });
        connection.on("streamingSensors", (sensores) => {
            _this.nivel = _this.showroomService.getNivel();
            _this.temperatura = _this.showroomService.getTemperatura();
            _this.presion = _this.showroomService.getPresion();
        });
        connection.start().catch(err => document.write(err));
  }

  ngOnInit(): void {
    this.docElement = document.documentElement;
    this.getListActuators();
  }

  selectMenu(){
    this.showMenu = !this.showMenu;
  }

  saveActuators(){
    //this.spinner.show();
    console.log('data enviada');
    console.log(this.actuators);
    this.showroomService.updateActuators(this.actuators).subscribe((_) => {
      // this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
       // this.spinner.hide();
    });
  }

  getListActuators(){
    this.spinner.show();
    this.showroomService.getListActuators().subscribe((_) => {
      this.actuators = _.data;
      var start = parseInt(this.actuators.filter(_ => _.id == 6)[0].value);
      var stop = parseInt(this.actuators.filter(_ => _.id == 7)[0].value);
      var startAlert = parseInt(this.actuators.filter(_ => _.id == 11)[0].value);
      var stopAlert = parseInt(this.actuators.filter(_ => _.id == 12)[0].value);
     // if(start==1 && stop==0){ start = 0; }
      this.stopButton = stop;
      this.startButton = start;
      this.stopAlert = stopAlert;
      this.startAlert = startAlert;
      this.actuators.filter(_ => {
        let options;
        _.idOrigin=_.id;
        if(_.idSensors != null){
          _.idPanel = "panelActuator"+_.id;
          if(_.maxLimit > 0){
            _.value =(_.value * 100) / _.maxLimit;
          }
          if(_.type==2){
            _.colors = ['#e5f6ff', '#82cfff'];
          }else{
            if(_.stepOptions != "" && _.stepOptions){
              var stepsArray = [];
              var array = _.stepOptions.split(",");
              array.forEach(function(n) {
                stepsArray.push({value: n});
              })
              options = {
                floor: 0,
                ceil: 100,
                showTicks: false,
                showTicksValues: true,
                stepsArray: stepsArray,
                showSelectionBar: true,
                selectionBarGradient: {
                  from: 'white',
                  to: '#01c4e7'
                }
              };
            }else{
              options = {
                floor: 0,
                ceil: 100,
                showSelectionBar: true,
                selectionBarGradient: {
                  from: 'white',
                  to: '#01c4e7'
                }
              }
            }
          }
        }
        if(_.type == 6){
          _.relationshipActuators.filter(__ => {
            __.idPanel = "panelActuator"+__.id;
            __.update = 0;
            this.actuators.push(__);
          });
        }

        _.dataValue =  _.value;
        _.options = options;
        _.update = 0;
        _.beforeValue = _.value;
      });
      this.staticActuators = this.actuators;
      this.staticActuators = this.staticActuators.filter(_ => _.id != 6 && _.id != 7 && _.id != 11 && _.id != 12);
      console.log(this.staticActuators);
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
    });
  }

  nextPanel(){
    /*this.imagePanelOrden++;
    if(this.imagePanelOrden == 8){
      this.imagePanelOrden=1;
    }

    this.imagePanel = environment.pathFile + "assets/showroom/"+this.imagePanelOrden+".png";*/
  }

  showPanel(id){
    if(id == 4){ //Resistencia
     // var motor = this.actuators.filter(x => x.id == 8)[0]; //motor
      //console.log("actuators" , this.actuators)
      console.log("motor value",this.bomba);
      if(this.bomba == 0){
        this.alertService.showMessage("", "Prenda el motor", MessageSeverity.error);
      }else{
        var data = {
          id: id,
          value: 1
        }
        this.showroomService.updatePanelValue(data).subscribe(_ => {
          console.log("updatePanelValue: ",_);
          document.getElementById('actuactorPanel'+id).style.display="initial";
        });
      }
    }else{
      var data = {
        id: id,
        value: 1
      }
      this.showroomService.updatePanelValue(data).subscribe(_ => {
        console.log("updatePanelValue: ",_);
        document.getElementById('actuactorPanel'+id).style.display="initial";
      });
    }

  }

  closeModal(id){

    var data = {
      id: id,
      value: 0
    }
    this.showroomService.updatePanelValue(data).subscribe(_ => {
      console.log("actuactorPanel: ",_);
      document.getElementById('actuactorPanel'+id).style.display="none";
    });
  }

  playButtonShowRoom(){
    this.startButton = 1;
    this.stopButton = 1;
    var value6,value7;
    this.actuators.filter(_ => {
      if(_.id == 6){
        value6 = 1;
      }
      if(_.id == 7){
         value7 = 0;
      }
    });
    console.log("valor de showroom play",value6);
    this.save(6,value6);
    //this.save(7,value7);
  }

  stopButtonShowRoom(){
    this.startButton = 0;
    this.stopButton = 0;
    var value6,value7;
    this.actuators.filter(_ => {
      if(_.id == 6){
        value6 = 0;
      }
      if(_.id == 7){
        value7 = 1;
      }
    });
    console.log("valor de showroom play",value6);
    this.save(6,value6);
    //this.save(7,value7);
  }

  playButtonAlert(){
    this.showroomService.startAlert().subscribe(_ =>{});
    this.startAlert = 1;
    this.stopAlert = 1;
    var value11,value12;
    this.actuators.filter(_ => {
      if(_.id == 11){
        value11 = 1;
      }
      if(_.id == 12){
        value12 = 0;
      }
    });
    this.save(11,value11);
    this.save(12,value12);
  }

  stopButtonAlert(){
    this.showroomService.stopAlert().subscribe(_ =>{});
    this.startAlert = 0;
    this.stopAlert = 0;
    var value11,value12;
    this.actuators.filter(_ => {
      if(_.id == 11){
        value11 = 0;
      }
      if(_.id == 12){
        value12 = 1;
      }
    });
    this.save(11,value11);
    this.save(12,value12);
  }

  save(id,value){
    var data = {
      id: id,
      value: value
    }
    console.log(data);
    this.showroomService.updateSensorValue(data).subscribe(_ => {
      console.log("UPDATE SENSOR: ",_);
    });
  }

  toggleFullScreen() {
    if (!this.isFullScreen) {
      this.docElement.requestFullscreen();
    }
    else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }

  sharedWindow(value){
    //this.sharedVideoAnalitica(0);
    var data ={
      id: 'sharedControl',
      value: 1
    }
    this.showroomService.updatePanelValue(data).subscribe(_ =>{
      console.log("update shared");
    });
  }

  sharedVideoAnalitica(value){
    this.sharedWindow(0);
    var data ={
      id: 'sharedControl',
      value: 0
    }
    this.showroomService.updatePanelValue(data).subscribe(_ =>{
      console.log("update shared");
    });
  }

  listPanels(){
    var _this = this;
    _this.nivel = _this.showroomService.getNivel();
    _this.temperatura = _this.showroomService.getTemperatura();
    _this.presion = _this.showroomService.getPresion();
    _this.bomba = _this.showroomService.getBomba();

    if(_this.showDataPanel){

      _this.showroomService.getPanels().subscribe(_=>{
        if(_.error == null){
          _this.showDataPanel = false;
          _.data = _.data.filter(_ =>_.id != 'sharedControl');
          _.data.forEach( _ =>{

            if(_.id == 0){
              _this.imagePanel = environment.pathFile + "assets/showroom/"+_.value+".png";
            }else{
              if(_.value){ document.getElementById('actuactorPanel'+_.id).style.display="initial"; }
              else{ document.getElementById('actuactorPanel'+_.id).style.display="none"; }
            }

          });
          _this.showDataPanel = true;
        }
      });
    }
  }

  showPBI(){
    let dialogRef = this.dialog.open(ModalReportSensorPbiShowRoomComponent, {
      width: '1000px',
    });
  }
}
