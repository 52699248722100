<div class="row title-modal">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon color="primary" [mat-dialog-close]="true">close</mat-icon>
    </div>
  </div>
  <mat-dialog-content class="mat-typography mt-3">
    <div class="container">
      <form [formGroup]="sensorForm" [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' " >
        <mat-tab-group>
          <mat-tab label="Sensor">
            <div class="row mt-2">
              <div class="col-lg-6 col-xs-12">
                  <mat-form-field class="w-100">
                      <mat-label>Sensor*</mat-label>
                      <mat-select formControlName="idSensor" (selectionChange)="changeSensor($event)" >
                          <mat-option *ngFor="let sensor of sensors" [value]="sensor.id">
                              {{sensor.name}}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="f.idSensor.hasError('required')">
                        El sensor es <strong>requerida</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-lg-6 col-xs-12">
                <mat-form-field class="w-100">
                    <mat-label>Tipo de Sensor*</mat-label>
                    <mat-select formControlName="idSensorType"  [(ngModel)]="sensorTypeSelect" (selectionChange)="changeSensorType($event)" >
                        <mat-option *ngFor="let sensor of sensorTypes" [value]="sensor.id">
                            {{sensor.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.idSensor.hasError('required')">
                      El tipo de sensor es <strong>requerido</strong>.
                    </mat-error>
                 </mat-form-field>
               </div>
              <div class="col-lg-6 col-xs-12">
                  <mat-form-field class="w-100">
                      <mat-label>Gráfico*</mat-label>
                      <mat-select formControlName="idGraph"  [(ngModel)]="sensorTypeGraphSelect" >
                          <mat-option *ngFor="let graph of graphs" [value]="graph.id">
                              {{graph.graph}}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="f.idGraph.hasError('required')">
                        El gráfico es <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-lg-6 col-xs-12">
                <mat-form-field class="w-100">
                    <mat-label>Ubicación de lectura*</mat-label>
                    <mat-select formControlName="idReadingPlace" >
                        <mat-option *ngFor="let sensorReadingPlace of sensorReadingPlaces" [value]="sensorReadingPlace.id">
                            {{sensorReadingPlace.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.idReadingPlace.hasError('required')">
                      La Ubicación de lectura es <strong>requerida</strong>.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-xs-12">
                <mat-form-field class="w-100">
                    <mat-label>Nomenclatura*</mat-label>
                    <input matInput formControlName="nomenclature" >
                    <mat-error *ngIf="f.nomenclature.hasError('required')">
                        La nomenclatura <strong>requerida</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.nomenclature.errors?.maxlength">
                      La nomenclatura no puede ser mayor a 30 caracteres.
                    </mat-error>
                    <mat-error *ngIf="f.nomenclature.errors?.minlength">
                      La nomenclatura no puede ser menor a 2 caracteres.
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-xs-12">
                <mat-form-field class="w-100">
                    <mat-label>El tiempo de consulta de streaming(s.)*</mat-label>
                    <input matInput  min="0"  type="number"  OnlyNumbers type="text" formControlName="streamingTime" >
                    <mat-error *ngIf="f.streamingTime.hasError('required')">
                      El tiempo de consulta de streaming es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Pin">
            <div class="row">
              <div class="col-12">
                <h4><b>Seleccione el icono de preferencia:</b></h4>
                <div *ngIf="listPinMaps.length > 0">
                  <h4>Mis Pines</h4>
                  <div class="row">
                    <div class="col-3" *ngFor="let item of listPinMaps">
                      <img src="{{item.path}}" class="item-pin" (click)="selectPinList(item)" >
                    </div>
                  </div>
                </div>
                <hr  *ngIf="listPinMaps.length > 0">
                <div *ngIf="listDefaultPinMaps.length > 0">
                  <div class="row">
                    <div class="col-3" *ngFor="let item of listDefaultPinMaps">
                      <img src="{{item.path}}" class="item-pin" (click)="selectPinList(item)" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Alerta">
            <div class="row mt-2">
              <div class="col-lg-6 col-xs-12">
                  <mat-form-field class="w-100">
                    <mat-label>Cantidad de alerta Mínima</mat-label>
                    <input  type="number" min="0" matInput OnlyNumbers formControlName="minAlert" >
                  </mat-form-field>
              </div>
              <div class="col-lg-6 col-xs-12">
                  <mat-form-field class="w-100">
                    <mat-label>Cantidad de alerta Máxima</mat-label>
                    <input  type="number" min="0" matInput OnlyNumbers formControlName="maxAlert" >
                  </mat-form-field>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

      </form>
    </div>
  </mat-dialog-content>

      <div class="row mt-3">
        <div class="col-lg-6 col-xs-12">
            <button mat-button class="btn-danger" (click)="delete()" *ngIf="data.enabledWrite && data.action == 2">ELIMINAR</button>
        </div>
        <div class="col-lg-6 col-xs-12 text-right">
            <button mat-button mat-dialog-close>CERRAR</button>
        <button mat-raised-button color="primary" (click)="save()" *ngIf="data.enabledWrite">GUARDAR</button>
        </div>
      </div>

