<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{title}}
    </div>
    <div class="col-2 text-right">
        <mat-icon color="primary" [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <div class="row mt-3 mb-3" [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="col-3 col-md-3 my-auto">
                <mat-checkbox  [(ngModel)]="selectVideoanalitica"  [checked]="selectVideoanalitica" (change)="changeVideoanaltica($event)" >Videoanalítica</mat-checkbox>
            </div>
            <div class="col-3 col-md-3 my-auto">
                <mat-checkbox  [(ngModel)]="selectIOT"  [checked]="selectIOT" (change)="changeIOT($event)">IOT</mat-checkbox>
            </div>
            <div class="col-3 col-md-3 my-auto">
                <mat-checkbox  [(ngModel)]="selectStreaming" [checked]="selectStreaming" (change)="changeStreaming($event)" >Streaming</mat-checkbox>
            </div>
            <div class="col-3 col-md-3 my-auto">
                <mat-checkbox [(ngModel)]="selectShowRoom" [checked]="selectShowRoom" (change)="changeShowRoom($event)" >ShowRoom</mat-checkbox>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCELAR</button>
    <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite" (click)="update()">GUARDAR</button>
</mat-dialog-actions>
