<section class="container">
    <div class="row section-name">
      <div class="header-title-options">
          <div class="row w-100">
            <div class="col-lg-10 col-xs-12">
              <mat-form-field appearance="standard">
                <mat-label>Buscador</mat-label>
                <input matInput (keyup)="applyUpFilter($event)" (keypress)="applyPressFilter($event)" maxlength="200" #input>
              </mat-form-field>
            </div>
          </div>
      </div>
    </div>
</section>

  <section class="container" >
    <div class="table-responsive">

      <table class="table" mat-table [dataSource]="dataSource" >

        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <ng-container matColumnDef="ruc">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> RUC </th>
          <td class="text-center" *matCellDef="let element"> {{element.ruc}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="businessName">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Nombre</th>
          <td class="text-center" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="address">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Dirección </th>
          <td class="text-center" *matCellDef="let element"> {{element.address}} </td>
        </ng-container>

        <ng-container matColumnDef="enabled">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Habilitado</th>
          <td class="text-center"  *matCellDef="let element">
            <mat-icon *ngIf="element.enabled">check</mat-icon>
            <mat-icon *ngIf="!element.enabled">close</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Acciones </th>
          <td class="text-center" *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="mainMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #mainMenu="matMenu" >
                <button mat-menu-item (click)="openEditModal(element)" *ngIf="enabledWrite">
                  <mat-icon class="icon-cursor" [style.color]="color1"  *ngIf="!isBusiness">create</mat-icon> Editar
                </button>
                <button mat-menu-item (click)="openEditModal(element)" *ngIf="enabledRead && !enabledWrite">
                  <mat-icon class="icon-cursor" [style.color]="color1"  *ngIf="!isBusiness">remove_red_eye</mat-icon> Ver
                </button>
                <button mat-menu-item (click)="openContractModal(element)"><mat-icon [style.color]="color1" class="icon-cursor">article</mat-icon> Contratos</button>
                <button mat-menu-item (click)="openEditStreaming(element)"><mat-icon [style.color]="color1"  class="icon-cursor">videocam</mat-icon> Streaming</button>
                <button mat-menu-item (click)="openEditSystem(element)"><mat-icon  [style.color]="color1"  class="icon-cursor">language</mat-icon> Sistemas</button>
                <button mat-menu-item (click)="openStorage(element)"><mat-icon  [style.color]="color1"  class="icon-cursor">folder_open</mat-icon> Directorio</button>
                <button mat-menu-item (click)="openEditSettings(element)"><mat-icon  [style.color]="color1"  class="icon-cursor">settings</mat-icon> Configuración</button>
                <button mat-menu-item (click)="delete(element)" *ngIf="enabledWrite" ><mat-icon [style.color]="color1"  class="icon-cursor" *ngIf="!isBusiness">delete</mat-icon> Eliminar</button>
              </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
<!--
      <mat-paginator
                     [pageSize]="pageSize"
                     [hidePageSize]="true">
      </mat-paginator>

      <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>-->

      <mat-paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [hidePageSize]="true"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="getServerData($event)">
      </mat-paginator>

    </div>
</section>


<button mat-fab class="fab-button" *ngIf="enabledWrite" [style.background]="color1" [style.color]="color2" aria-label="Add icon" (click)="openAddModal()" >
  <mat-icon>add</mat-icon>
</button>
