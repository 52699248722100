<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
    <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
      <form [formGroup]="dashboardForm"  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
          <div class="row">
              <div class="col-12" >
                <mat-form-field class="w-100">
                    <mat-label>Nombre*</mat-label>
                    <input matInput formControlName="name" >
                    <mat-error *ngIf="f.name.errors?.minlength">
                      El nombre no puede ser menor a 1 caracteres.
                    </mat-error>
                    <mat-error *ngIf="f.name.hasError('required')">
                        El nombre <strong>requerida</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.name.errors?.maxlength">
                      El nombre no puede ser mayor a 30 caracteres.
                    </mat-error>
                </mat-form-field>
              </div>
          </div>
      </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" (click)="save()" *ngIf="data.enabledWrite" >GUARDAR</button>
</mat-dialog-actions>
