<div class="container">


  <section class="filter mt-3">
      <div class="row">
          <div class="col-12">
              <div class="row">
                  <!--<div class="col-12 col-md-4">
                      <mat-form-field class="w-100">
                          <mat-label>Empresa</mat-label>
                          <mat-select
                                  (selectionChange)="changeEnterprise($event)"
                                  [(ngModel)]="enterpriseSelect">
                               <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                                  {{enterprise.businessName}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="col-12 col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Sede</mat-label>
                        <mat-select (selectionChange)="changeHeadquarter($event)" [(ngModel)]="headquarterSelect">
                            <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                                {{headquarter.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>-->
                  <div class="col-12 col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Seleccionar Zona</mat-label>
                      <mat-select (selectionChange)="changeZone($event)"
                                  [(ngModel)]="enterpriseZoneSelect">
                        <mat-option *ngFor="let zone of enterprisesZones" [value]="zone.id">
                          {{zone.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="filter mt-3">
    <div class="row" style="justify-content: center;">
        <div (dragover)="false" (dragend)="false"(mouseup)="handleDrop()">
          <canvas id="canvasLocation" style="border-style: solid; border-color:#D9CDAB"></canvas>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive mt-3">
          <mat-paginator #paginatorTop (page)="handlePaginatorBottom($event)" [pageSize]="4"  [hidePageSize]="true" ></mat-paginator>
          <table class="table" mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="index">
              <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> # </th>
              <td class="text-center" *matCellDef="let element; let i = index">
                {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
              </td>
            </ng-container>

            <ng-container matColumnDef="camera">
              <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Cámara </th>
              <td class="text-center" *matCellDef="let element" > {{element.camera}} </td>
            </ng-container>

            <ng-container matColumnDef="pin">
              <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Pin </th>
              <td class="text-center" *matCellDef="let element" > {{element.pinMap}} </td>
            </ng-container>

            <ng-container matColumnDef="posicion">
              <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Posición </th>
              <td class="text-center" *matCellDef="let element" >
                <span *ngIf="element.oppositeMapDirection == 0" >Predeterminado</span>
                <span *ngIf="element.oppositeMapDirection == 1">Opuesta</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="enabled">
              <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"></th>
              <td class="text-center"  *matCellDef="let element">
                <button mat-icon-button (mousedown)="openModal(element)" [style.color]="color1" *ngIf="enabledWrite" style="outline: none;">
                  <mat-icon aria-label="Edit">add_location</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Eliminar</th>
              <td class="text-center"  *matCellDef="let element">
                <button mat-icon-button (click)="deleteMark(element)" [style.color]="color1" *ngIf="enabledWrite"  style="outline: none;">
                  <mat-icon aria-label="Edit">delete</mat-icon>
                </button>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator #paginatorBottom (page)="handlePaginatorTop($event)" [pageSize]="4" [hidePageSize]="true"></mat-paginator>
        </div>
      </div>
    </div>
  </section>

  <button mat-fab class="fab-button" (click)="openAddModal()" [style.background]="color1" [style.color]="color2" aria-label="Add icon"  *ngIf="enabledWrite && enterprisesZones.length>0">
    <mat-icon>save</mat-icon>
  </button>

</div>
