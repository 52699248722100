<div class="container">
<!--
  <div class="row mt-3 section-name">
      <div class="col">
          Estadísticas de Streaming
      </div>
  </div>-->

  <section class="filter mt-3">
      <div class="col-12">
        <form [formGroup]="reportForm" action="">
          <div class="row">
            <div class="col-12 col-md-3 col-lg-3">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Fecha inicio</mat-label>
                    <input matInput formControlName="startDateReport" readonly [matDatepicker]="picker1" maxlength="10" [(ngModel)] = "date">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 ></mat-datepicker>
                    <mat-error *ngIf="f.startDateReport.hasError('required')">
                      La fecha de Inicio es <strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.startDateReport.hasError('pattern')">
                      La fecha de Inicio<strong> no tiene el formato correcto</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3 col-lg-3">
              <mat-form-field class="w-100" color="primary">
                  <mat-label>Fecha Fin</mat-label>
                  <input matInput formControlName="endDateReport" readonly [matDatepicker]="picker2" maxlength="10" [(ngModel)] = "dateF">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 ></mat-datepicker>
                  <mat-error *ngIf="f.endDateReport.hasError('required')">
                    La fecha Final es<strong> obligatorio</strong>
                  </mat-error>
                  <mat-error *ngIf="f.endDateReport.hasError('pattern')">
                    La fecha Final<strong> no tiene el formato correcto</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3 col-lg-3">
              <mat-form-field class="w-100" color="primary">
                  <mat-label>Hora inicio</mat-label>
                  <input matInput formControlName="startTimeReport" maxlength="5" [(ngModel)]="timeI" value="{{timeI}}" (keypress)="_keyUp($event)">
                  <mat-error *ngIf="f.startTimeReport.hasError('required')">
                    La Hora Inicial es<strong> obligatorio</strong>
                  </mat-error>
                  <mat-error *ngIf="f.startTimeReport.hasError('pattern')">
                    La Hora Inicial tiene <strong> formato incorrecto</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3 col-lg-3">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Hora fin</mat-label>
                    <input matInput formControlName="endTimeReport" maxlength="5" [(ngModel)]="timeF" value="{{timeF}}" (keypress)="_keyUp($event)">
                    <mat-error *ngIf="f.endTimeReport.hasError('required')">
                      La Hora Final es <strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.endTimeReport.hasError('pattern')">
                      La Hora Final tiene <strong> formato incorrecto</strong>
                    </mat-error>
                </mat-form-field>
            </div>
          </div>
          <div class="row">
           <!--<div class="col-12 col-lg-3 col-xs-12">
              <mat-form-field class="w-100">
                  <mat-label>Empresa</mat-label>
                  <mat-select  formControlName="idEnterprise"  (selectionChange)="changeEnterprise($event)" [(ngModel)]="enterpriseSelect">
                      <mat-option [value]="0" *ngIf="enterprises.length > 1">
                        Todos
                      </mat-option>
                      <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                          {{enterprise.businessName}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-3 col-xs-12">
              <mat-form-field class="w-100">
                  <mat-label>Sede</mat-label>
                  <mat-select formControlName="idHeadquarter" (selectionChange)="changeHeadquarter($event)"  [(ngModel)]="headquarterSelect">
                      <mat-option [value]="0" *ngIf="headquarters.length > 1">
                        Todas
                      </mat-option>
                      <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                          {{headquarter.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>-->
            <div class="col-12 col-md-6 col-lg-6">
                <!--<mat-select  placeholder="Cámara" (selectionChange)="changeCameras($event.value)"   [formControl]="cameraReport" multiple>
                  <mat-select-trigger>
                    {{camerasSelect.value ? cameraSelect : ''}}
                    <span *ngIf="camerasSelect.value?.length > 1" class="example-additional-selection">
                      (+{{camerasSelect.value?.length - 1}} {{camerasSelect.value?.length >= 2 ? 'más' : ''}})
                    </span>
                  </mat-select-trigger>
                  <mat-option [value]="0" (click)="allCameras()" *ngIf="cameras.length > 0">
                    Todos
                  </mat-option>
                  <mat-option *ngFor="let camera of cameras" [value]="camera.id_Camera">{{camera.c_Camera}}</mat-option>
                </mat-select>-->
                <label class="mb-1" style="color: rgba(0, 0, 0, 0.6);">Cámaras</label>
                <ng-multiselect-dropdown
                        formControlName = "idCameras"
                        [placeholder]="'Seleccionar'"
                        [settings]="dropdownSettings"
                        [data]="cameras"
                        [(ngModel)]="selectedItems"
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-12 col-md-2 col-lg-2 mt-2">
              <mat-slide-toggle class="mt-3" [formControl]="showGraph" (click)="checkGraph(0)"  toggleColor circle="{{color2}}" bar="#d0d0d0" >Vista Gráfica</mat-slide-toggle>
            </div>
            <div class="col-12 col-md-4 col-lg-4 mt-2">
              <button mat-raised-button  class="mt-2" [style.background]="color1" [style.color]="color2"  (click)="setData()">VER REPORTE</button>
              <button mat-raised-button  class="ml-2 mt-2" [style.background]="color1" [style.color]="color2" (click)="exportExcel()">EXPORTAR EXCEL</button>
            </div>
          </div>
        </form>
      </div>
  </section>

  <section class="table-responsive mt-3" >
    <div *ngIf="!showData">
      <table class="table" mat-table [dataSource]="dataSource" >
        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="enterprise">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Empresa </th>
          <td class="text-center" *matCellDef="let element"> {{element.enterprise}} </td>
        </ng-container>

        <ng-container matColumnDef="camera">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Cámara </th>
          <td class="text-center" *matCellDef="let element"> {{element.camera}} </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Fecha de Registro</th>
          <td class="text-center" *matCellDef="let element"> {{element.createdAt}} </td>
        </ng-container>

        <ng-container matColumnDef="ip">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> IP </th>
          <td class="text-center" *matCellDef="let element"> {{element.ip}} </td>
        </ng-container>

        <ng-container matColumnDef="pc_movil">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">PC / Móvil</th>
          <td class="text-center" *matCellDef="let element"> {{element.pc_movil}} </td>
        </ng-container>

        <ng-container matColumnDef="browser">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Navegador</th>
          <td class="text-center" *matCellDef="let element"> {{element.browser}} </td>
        </ng-container>

        <ng-container matColumnDef="origin">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Origen</th>
          <td class="text-center" *matCellDef="let element"> {{element.origin}} </td>
        </ng-container>

        <ng-container matColumnDef="originDomain">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Dominio</th>
          <td class="text-center" *matCellDef="let element"> {{element.originDomain}} </td>
        </ng-container>

        <ng-container matColumnDef="userAgent">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Agente</th>
          <td class="text-center" *matCellDef="let element"> {{element.userAgent}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>
    <div [style.display]="displayListStadistics" >
      <mat-paginator [pageSize]="12" [hidePageSize]="true"></mat-paginator>
    </div>
  </section>
 
  <section  *ngIf="showData" [style.display]="!displayListStadistics">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
        <canvas id="myChart" height="250px" #chart>{{  chart  }}</canvas>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
        <canvas id="myChart2" height="250px" #chart2>{{  chart2  }}</canvas>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
        <canvas id="myChart3" height="250px" #chart3>{{  chart3  }}</canvas>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
        <canvas id="myChart4" height="250px" #chart4>{{  chart4  }}</canvas>
      </div>
    </div>
  </section>

</div>

