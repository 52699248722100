import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { MessageSeverity, AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-modal-email-show-room',
  templateUrl: './modal-email-show-room.component.html',
  styleUrls: ['./modal-email-show-room.component.css']
})
export class ModalEmailShowRoomComponent implements OnInit {

  securityForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private AlertService: AlertService,
    private showRoomService: ShowRoomService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    if(this.data.action == 1){
      this.securityForm.controls['email'].setValue(this.data.obj.email);
    }
  }

  createForm(){
    this.securityForm = this.formBuilder.group({
      email: ['', [Validators.required,  Validators.maxLength(500), this.noWhitespaceValidator]]
    })
  }

  get f() { return this.securityForm.controls; }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  save(){
    if(this.securityForm.valid){
      const data = {
        email:this.securityForm.value["email"],
        id: this.data.action == 1 ? this.data.obj.id : 0
      }



        this.showRoomService.insertUpdateEmail(data).subscribe(_ => {
          if(_.message == null || _.message == ""){
            if(this.data.action == 1){
              this.AlertService.showMessage(AppConstants.TitleModal.UPDATE_TITLE, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            }else{
              this.AlertService.showMessage(AppConstants.TitleModal.REGISTER_TITLE, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            }

            this.dialog.closeAll();
          }else{
            this.AlertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
    }else{
      this.securityForm.markAllAsTouched();
    }
  }

  close() {
    this.dialog.closeAll();
  }

}
