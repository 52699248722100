<div class="container">

  <section class="filter mt-3">
      <div class="col-12">
        <form [formGroup]="reportForm" action="">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Fecha inicio</mat-label>
                    <input matInput formControlName="startDateReport" readonly [matDatepicker]="picker1" maxlength="10" [(ngModel)] = "date">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 ></mat-datepicker>
                    <mat-error *ngIf="f.startDateReport.hasError('required')">
                      La fecha de Inicio es <strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.startDateReport.hasError('pattern')">
                      La fecha de Inicio<strong> no tiene el formato correcto</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12">
              <mat-form-field class="w-100" color="primary">
                  <mat-label>Fecha Fin</mat-label>
                  <input matInput formControlName="endDateReport" readonly [matDatepicker]="picker2" maxlength="10" [(ngModel)] = "dateF">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 ></mat-datepicker>
                  <mat-error *ngIf="f.endDateReport.hasError('required')">
                    La fecha Final es<strong> obligatorio</strong>
                  </mat-error>
                  <mat-error *ngIf="f.endDateReport.hasError('pattern')">
                    La fecha Final<strong> no tiene el formato correcto</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12">
              <mat-form-field class="w-100" color="primary">
                  <mat-label>Hora inicio</mat-label>
                  <input matInput formControlName="startTimeReport" maxlength="5" [(ngModel)]="timeI" value="{{timeI}}" (keypress)="_keyUp($event)">
                  <mat-error *ngIf="f.startTimeReport.hasError('required')">
                    La Hora Inicial es<strong> obligatorio</strong>
                  </mat-error>
                  <mat-error *ngIf="f.startTimeReport.hasError('pattern')">
                    La Hora Inicial tiene <strong> formato incorrecto</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Hora fin</mat-label>
                    <input matInput formControlName="endTimeReport" maxlength="5" [(ngModel)]="timeF" value="{{timeF}}" (keypress)="_keyUp($event)">
                    <mat-error *ngIf="f.endTimeReport.hasError('required')">
                      La Hora Final es <strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.endTimeReport.hasError('pattern')">
                      La Hora Final tiene <strong> formato incorrecto</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12">
              <mat-form-field class="w-100">
                <mat-label>Seleccionar Zona</mat-label>
                <mat-select formControlName="zoneId" (selectionChange)="changeZone(0)"
                            [(ngModel)]="enterpriseZoneSelect">
                  <mat-option *ngFor="let zone of enterprisesZones" [value]="zone.id">
                    {{zone.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="f.zoneId.hasError('required')">
                    La zona es <strong> obligatorio</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Estado</mat-label>
                    <mat-select [(ngModel)]="statuselect" formControlName="statusReport">
                      <mat-option [value]="0" *ngIf="stats.length > 0">
                        Todos
                      </mat-option>
                      <mat-option *ngFor="let status of stats" [value]="status.id">
                        {{status.name}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12">
              <mat-form-field class="w-100" color="primary">
                <mat-label>Cámara</mat-label>
                <mat-select (selectionChange)="changeCameras()"
                            [(ngModel)]="cameraSelect" formControlName="cameraReport">
                  <mat-option [value]="0" *ngIf="cameras.length > 1">
                    Todos
                  </mat-option>
                  <mat-option *ngFor="let camera of cameras" [value]="camera.cameraId">
                    {{camera.camera}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12">
              <mat-form-field class="w-100" color="primary">
                <mat-label>Tipo de alerta</mat-label>
                <mat-select [(ngModel)]="alertSelect" formControlName="alertReport">
                  <mat-option [value]="0" *ngIf="cameraAlert.length > 1">
                    Todos
                  </mat-option>
                  <mat-option *ngFor="let cameraAlerts of cameraAlert" [value]="cameraAlerts.id_CameraAlert">
                    {{cameraAlerts.c_Alert}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12">
              <mat-form-field class="w-100" color="primary">
                <mat-label>Usuario</mat-label>
                <mat-select [(ngModel)]="userSelect" formControlName="userReport">
                  <mat-option [value]="0" *ngIf="users.length > 0">
                    Todos
                  </mat-option>
                  <mat-option *ngFor="let user of users" [value]="user.userId">
                    {{user.fullName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        </form>
      </div>
  </section>

  <section class="export-section my-3">
    <div class="row">
          <div class="col-6" style="display: flex;align-items: center;">
            <input type="checkbox" [ngModel]="alertError" (click)="checkError()" > VER ALERTAS FALSO POSITIVOS
          </div>
          <div class="col-6 text-right" *ngIf="enterprisesZones.length > 0">
            <button mat-button class="mr-3 btn-color" [style.color]="color2" [style.background]="color1" (click)="setData()">VER REPORTE</button>
            <button mat-raised-button  class="btn-color" [style.color]="color2" [style.background]="color1" (click)="exp()">EXPORTAR</button>
          </div>
    </div>
  </section>

  <section class="table-responsive mt-3">
    <mat-paginator #paginatorTop (page)="handlePaginatorBottom($event)" [pageSize]="12"  [hidePageSize]="true" ></mat-paginator>

    <table class="table" mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="index">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> # </th>
        <td class="text-center" *matCellDef="let element; let i = index">
          {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="camera">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Cámara </th>
        <td class="text-center" *matCellDef="let element"> {{element.camera}} </td>
      </ng-container>

      <ng-container matColumnDef="typeAlert">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Tipo de Alarma </th>
        <td class="text-center" *matCellDef="let element"> {{element.typeAlert}} </td>
      </ng-container>

      <ng-container matColumnDef="insertDateOcurrence">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Fecha de Registro</th>
        <td class="text-center" *matCellDef="let element"> {{element.insertDateOcurrence}} </td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Usuario Atención </th>
        <td class="text-center" *matCellDef="let element"> {{element.userName}} </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Estado</th>
        <td class="text-center" *matCellDef="let element"> {{element.enabled}} </td>
      </ng-container>

      <ng-container matColumnDef="updateCamera">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Fecha Modificación</th>
        <td class="text-center" *matCellDef="let element"> {{element.updateCamera}} </td>
      </ng-container>

      <ng-container matColumnDef="insertDate">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Fecha Atención</th>
        <td class="text-center" *matCellDef="let element"> {{element.insertDate}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Acciones </th>
        <td class="text-center" *matCellDef="let element">
          <mat-icon color="primary" class="icon-cursor" (click)="alerta(element)">remove_red_eye</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator #paginatorBottom (page)="handlePaginatorTop($event)" [pageSize]="12" [hidePageSize]="true"></mat-paginator>
  </section>

</div>
