
<div *ngIf="isPhone && !menuIcon" (click)="minMenu()" [style.color]="color2" class="closeMobileMenu">
  <mat-icon>close</mat-icon>
</div>
<mat-sidenav-container class="sidenav-container" >
    <mat-sidenav #drawer [ngClass]="{'sidenav': !menuIcon, 'sidenavIcon' : menuIcon}"
          mode="side" position="start" opened="true"
         [style.background]="color1">
        <!--
          fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
        -->
        <mat-toolbar  style="background-color: transparent !important;height: 80px;" [ngClass]="{'': !menuIcon, 'mat-toolbar-single-row-icon' : menuIcon }"  >
            <div class="container text-center" style="padding-left: 0px;padding-right: 0px;">
                <img src="{{logo}}"  [ngClass]="{'logo-gs': !menuIcon, 'logo-gs-icon' : menuIcon }"   >
            </div>
        </mat-toolbar>


        <mat-nav-list>
          <mat-accordion>
              <div *ngFor="let navItem of navList" >
                  <div  [ngClass]="{'nav-head nav-item-anychild': !menuIcon, 'nav-head nav-head-icon' : menuIcon }" *ngIf="navItem.pages.length === 0 && navItem.isChild === 0 && navItem.idParent === 0" >
                      <!--<a class="nav-link link-one" [routerLink]="navItem.link" routerLinkActive="selected-head">
                          <mat-icon matTooltip="{{navItem.heading}}">{{navItem.icon}}</mat-icon>
                          <span class="nav-link-text"  *ngIf="!menuIcon"  >{{navItem.heading}}</span>  class="a-item-menu"
                      </a>-->
                      <a [routerLink]="navItem.tag" class="a-item-menu" [ngClass]="{'ml-3': !menuIcon, '' : menuIcon }" (click)="hideMobileMenu()"  [style.color]="color2" >
                          <mat-icon class="expansion-icon" [style.color]="color2"  [ngClass]="{'expansion-icon  mr-4': !menuIcon, 'expansion-icon' : menuIcon }" matTooltip="{{navItem.heading}}" >{{navItem.icon}}</mat-icon>
                          <span class="nav-link-text" [style.color]="color2"  *ngIf="!menuIcon" >{{navItem.name}}</span>
                      </a>
                      <!--
                        <mat-expansion-panel
                      *ngIf="navItem.pages.length == 0"
                      [routerLink]="navItem.tag"
                      class="mat-elevation-z0"
                      [style.background]="color1"
                      hideToggle>
                          <mat-expansion-panel-header
                              [expandedHeight]="'48px'"
                              [ngClass]="{'nav-head px-3 mat-expansion-panel-header-icon': !menuIcon, 'nav-head px-3 mat-content-icon mat-expansion-panel-header-icon' : menuIcon }"
                              >
                              <mat-panel-title class="nav-link pl-0 expansion-title" >
                                  <mat-icon class="expansion-icon mr-4" matTooltip="{{navItem.name}}" >{{navItem.icon}}</mat-icon>
                                  <span class="nav-link-text" *ngIf="!menuIcon" >{{navItem.name}}</span>
                              </mat-panel-title>
                          </mat-expansion-panel-header>
                    </mat-expansion-panel>-->
                  </div>
                  <mat-expansion-panel
                   *ngIf="navItem.pages.length > 0"
                   [style.color]="colorFont"
                   class="mat-elevation-z0 mat-expansion-menu"
                   style="background:transparent"
                   [hideToggle]="menuIcon"
                   >
                      <mat-expansion-panel-header
                          [style.color]="color1"
                          [expandedHeight]="'48px'"
                          [ngClass]="{'nav-head px-3 mat-expansion-panel-header-icon': !menuIcon, 'nav-head' : menuIcon }"
                          >
                          <mat-panel-title [style.color]="color2" [ngClass]="{'pl-0 expansion-title': !menuIcon, 'panel-title-parent pl-0 expansion-title' : menuIcon }" >
                              <mat-icon [ngClass]="{'expansion-icon mr-4': !menuIcon}"  matTooltip="{{navItem.name}}" >{{navItem.icon}}</mat-icon>
                              <span class="nav-link-text" [style.color]="color2"   *ngIf="!menuIcon" >{{navItem.name}}</span>
                          </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="nav-section">
                          <div *ngFor="let navPage of navItem.pages" class="nav-item">
                            <a [style.color]="color2"  [ngClass]="{'nav-link nav-child': !menuIcon, 'nav-link nav-link-icon nav-head-icon' : menuIcon }"
                              [routerLink]="[navPage.tag]" routerLinkActive="selected" (click)="hideMobileMenu()" 
                              matTooltip="{{navPage.name}}"
                              [style.color]="colorFont" >
                              <mat-icon [style.color]="color2"
                              [ngClass]="{'child-icon expansion-icon ml-3 mr-4': !menuIcon, 'child-icon expansion-icon' : menuIcon }"
                              >{{navPage.icon}}</mat-icon>
                              <span class="nav-link-text text-child-menu" [style.color]="color2"  *ngIf="!menuIcon" >{{navPage.name}}</span>
                            </a>
                          </div>
                      </div>
                  </mat-expansion-panel>
              </div>
            <!-- <div class="nav-head" id="logoutMenu"  [style.background]="color1" [style.color]="colorFont" >
                <a class="nav-link-logout" (click)="logout()" [style.background]="color1" [style.color]="colorFont">
                  <mat-icon>exit_to_app</mat-icon>
                  <span class="nav-link-text">Cerrar Sesión</span>
                </a>
              </div>-->
          </mat-accordion>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content  [ngClass]="{'mat-drawer-content': !menuIcon, 'mat-drawer-content-icon scroll-show' : menuIcon }"   >
        <mat-toolbar style="background-color: transparent !important;">
            <!--<button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>-->
            <div class="w-100" >
              <div class="header-container" >
                <div class="row w-100" >
                  <div class="container header-container" [ngClass]="{'header-title-icon' : ((menuIcon && initMenuIcon) || menuIcon) }">
                    <div  style="justify-content: flex-start;
                        justify-items: center;
                        display:flex;
                        align-items: center;
                        align-content: center;">
                        <div>
                          <button mat-icon-button (click)="minMenu()">
                            <mat-icon [@menuIconAnimation]="menuIcon ? 'open' : 'closed'">arrow_back</mat-icon>
                          </button>
                        </div>
                        <div id="titleHeaderNav" [style.color]="color1"></div> <!-- [ngClass]="{'col menu-tour': !menuIcon, 'col menu-tour-icon' : menuIcon }" -->
                    </div>
                  </div>
                </div>



                <div class="row w-100 header-profile"  *ngIf="!isPhone && !isTablet">
                    <div class="col-header mt-3" *ngIf="enterprises.length > 1">
                      <mat-form-field appearance="outline" style="margin-bottom: -1.25em">
                        <mat-label [style.color]="color1">Empresa</mat-label>
                        <mat-select (selectionChange)="changeEnterprise(enterpriseSelect)" [(ngModel)]="enterpriseSelect" [style.color]="color1">
                          <mat-option *ngFor="let item of enterprises" [style.color]="color1" [value]="item.id">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-header mt-3">
                      <mat-form-field appearance="outline" style="margin-bottom: -1.25em">
                        <mat-label [style.color]="color1">Sede</mat-label>
                        <mat-select (selectionChange)="changeHeadquarter(headquarterSelect)" [(ngModel)]="headquarterSelect" [style.color]="color1">
                          <!--<mat-option [value]="0" [style.color]="color1" *ngIf="headquarters.length > 1" >TODAS</mat-option>-->
                          <mat-option *ngFor="let item of headquarters" [style.color]="color1" [value]="item.id">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-header profile">
                        <div class="user-profile" (click)="showProfileOptions()">
                            <span class="mr-1 fullName-user" [style.color]="color1" >{{user}}</span>
                            <!--<span><img width="30px" class="image-profile" src="../../../../assets/images/user-noimage.png"></span>-->
                        </div>
                        <div class="menu-user" [style.color]="color1" (click)="showProfileOptions()" *ngIf="profileoptions">
                            <ul class="menu-ul">
                                <li>{{roleUser}}</li>
                                <li>{{email}}</li>
                                <hr>
                                <li class="li-menu-user" (click)="myProfile()" ><mat-icon class="mr-1">account_circle</mat-icon><span>Mi Perfil</span></li>
                                <hr>
                                <li  class="li-menu-user" (click)="logout()" ><mat-icon class="mr-1">exit_to_app</mat-icon><span>Cerrar Sesión</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row w-100 header-profile"  *ngIf="isPhone || isTablet">
                  <button mat-icon-button [matMenuTriggerFor]="mainMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #mainMenu="matMenu" >
                    <button mat-menu-item style="height: 100%;">
                      <div class="col-header mt-1" *ngIf="enterprises.length > 1">
                        <mat-form-field appearance="outline">
                          <mat-label [style.color]="color1">Empresa</mat-label>
                          <mat-select (selectionChange)="changeEnterprise(enterpriseSelect)" [(ngModel)]="enterpriseSelect" [style.color]="color1">
                            <mat-option *ngFor="let item of enterprises" [style.color]="color1" [value]="item.id">
                              {{item.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-header mt-1">
                        <mat-form-field appearance="outline">
                          <mat-label [style.color]="color1">Sede</mat-label>
                          <mat-select (selectionChange)="changeHeadquarter(headquarterSelect)" [(ngModel)]="headquarterSelect" [style.color]="color1">
                            <!-- <mat-option [value]="0" [style.color]="color1" *ngIf="headquarters.length > 1" >TODAS</mat-option>-->
                            <mat-option *ngFor="let item of headquarters" [style.color]="color1" [value]="item.id">
                              {{item.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </button>
                    <button mat-menu-item [style.color]="color1">
                      <span>{{roleUser}}</span><br>
                      <span>{{email}}</span>
                    </button>
                    <button mat-menu-item (click)="myProfile()"  [style.color]="color1"><mat-icon class="mr-1"  [style.color]="color1" >account_circle</mat-icon><span>Mi Perfil</span></button>
                    <button mat-menu-item (click)="logout()"  [style.color]="color1"><mat-icon class="mr-1"  [style.color]="color1" >exit_to_app</mat-icon><span>Cerrar Sesión</span></button>
                  </mat-menu>
                </div>
              </div>
            </div>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
*ngIf="isHandset$ | async">
<mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
</button> -->
