import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CameraService } from 'src/app/services/camera/camera.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { CameraModalComponent } from 'src/app/shared/components/modals/camera/camera-modal.component';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.css']
})
export class CameraComponent implements OnInit {
  loading: boolean = false;
  dataSource = new MatTableDataSource();
  dataSources = new MatTableDataSource();
  displayedColumns: string[] = ['index','headquarter','model','ipAdress','camera','zone','alerts','active','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginators: MatPaginator;
  subscription: Subscription;
  iframe: string;
  isAdministratorStreaming=false;
  enterprises = [];
  enterpriseSelect: number = 0;
  headquarterSelect = 0;
  headquarters = [];
  addUser:boolean = false;
  actionUser:boolean = false;
  isAdminGys: boolean = false;
  pageIndex: number = 0;
  pageEvent: PageEvent;
  pageSize: number = AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION;
  length: number = 0;
  page: number = 1;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  searchText:string="";
  timer:any;
  timeoutVal = 800;
  enabledRead: number;
  enabledWrite: number;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private CameraService: CameraService,
    private enterpriseService: EnterpriseService,
    private emitterService: EmitterService,
    private router: Router,
    private alertService: AlertService,
    private headquarterService: HeadquarterService
  ) {
    document.getElementById("titleHeaderNav").innerHTML = 'Administración > <span class="font-weight-bold">Cámaras</span>';
  }

  ngOnInit() {
    this.setLS();
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.enterpriseSelect = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = parseInt(localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.dataSource.paginator = this.paginator;
    this.dataSources.paginator = this.paginators;
    this.paginator._intl.itemsPerPageLabel = '';
    this.paginators._intl.itemsPerPageLabel = '';
    this.subscription = this.emitterService.getMessage().subscribe(message => {
      if (message == AppConstants.EmitterService.ListCamera) {
        /*if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) != AppConstants.UserRole.AdministradorGyS){
          this.getCameraList(parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE)));
        }else{
          this.getCameraList(this.enterpriseSelect);
        }*/
        this.getCameraList();
      }
    });

    if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.AdministradorEmpresaStreaming){
      this.isAdministratorStreaming = true;
      this.displayedColumns = ['index','model','ipAdress','camera','active','action'];
    }
   /* if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.AdministradorGyS
    || parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.UsuarioGyS){
      //this.addUser = true;
      this.getEnterprises(1);
    }else{
      this.addUser = false;

      //this.getEnterprises(0);
    }*/
    this.getCameraList();
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  getEnterprises(id:number) {
    if(id == 0){
      this.spinner.show();
      this.enterpriseService.getEnterprises().subscribe((response) => {
      this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
      //this.spinner.hide();
        /*if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) != AppConstants.UserRole.AdministradorGyS){
          this.getCameraList(parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE)));
        }else{
          this.getCameraList(this.enterpriseSelect);
        }*/
        //this.getHeadquarters();
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
    }else{
      this.spinner.show();
      this.enterpriseService.getEnterprises().subscribe((response) => {
      this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
      this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
      var enterprise = this.enterprises.filter(f => f.enterpriseId == this.enterpriseSelect);
      this.iframe = environment.sistemaWebAdmin + "/streaming/camera-by-enterprise/" + enterprise[0]["token"];
      //this.getHeadquarters();
      this.spinner.hide();
        /*if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) != AppConstants.UserRole.AdministradorGyS){
          this.getCameraList(parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE)));
        }else{
          this.getCameraList();
        }*/
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
    }
  }

  openAddModal() {
    let dialogRef = this.dialog.open(CameraModalComponent, {
      data: {
        title: 'Agregar Cámara',
        enterpriseId: this.enterpriseSelect,
        action: AppConstants.OperationType.ADD,
        headquarters: this.headquarters,
        idHeadquarter: this.headquarterSelect,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        color:this.color1,
        colorFont:this.color2,
        listview: this.dataSource},
      width: '800px',
      hasBackdrop: false
    });
    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        this.page = 1;
        this.paginator.pageIndex = 0;
        this.getCameraList();
      }
    });
  }

  openEditModal(datas){
    this.CameraService.getListCameraFilter(
      this.enterpriseSelect,
      parseInt(localStorage.getItem(AppConstants.Session.USERID)),
      datas.id
      ).subscribe((response) => {
      let dialogRef = this.dialog.open(CameraModalComponent, {
        data: {
            title: 'Actualizar Cámara - '+datas.id,
            enterpriseId: this.enterpriseSelect,
            action: AppConstants.OperationType.UPDATE,
            listview: response,
            headquarters: this.headquarters,
            enabledWrite: this.enabledWrite,
            enabledRead: this.enabledRead,
            idHeadquarter: this.headquarterSelect,
            color:this.color1,
            colorFont:this.color2,
            obj:datas},
        width: '800px',
      });
      dialogRef.afterClosed().subscribe(_ => {
        if (_.result == true) {
          this.getCameraList();
        }
      });
    },
    (error: HttpErrorResponse) => {
    });
  }

  changeEnterprise(data) {
    this.enterpriseSelect = data.value;
    var enterprise = this.enterprises.filter(f => f.enterpriseId == this.enterpriseSelect);
    this.iframe = environment.sistemaWebAdmin + "/streaming/camera-by-enterprise/" + enterprise[0]["token"];
    this.getHeadquarters();
  }

  getServerData(event?: PageEvent) {
    this.page = event.pageIndex + 1;
    this.getCameraList();
  }

  getCameraList(){
    this.spinner.show();
    var filter = {
      Page: this.page,
      Rows: AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION,
      IdEnterprise: this.enterpriseSelect,
      IdHeadquarter: this.headquarterSelect,
      enabledWrite: this.enabledWrite,
      enabledRead: this.enabledRead,
      color:this.color1,
      colorFont:this.color2,
      Filter: this.searchText
    };
    this.CameraService.getListCamera(filter).subscribe((_) => {
      if(_.error == null){
        this.dataSource = new MatTableDataSource(_["rows"]);
        this.pageIndex = +_["page"]-1;
        this.length = _["total"];
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  delete(obj) {
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
        message: `${AppConstants.MessageModal.DELETE_CAMERA_CONFIRMATION_MESSAGE1}  ${obj.camera} ? ${AppConstants.MessageModal.DELETE_CAMERA_CONFIRMATION_MESSAGE2}`,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteCamera(obj.id);
      }
    });

  }

  deleteCamera(cameraId: number) {
    this.spinner.show();
    this.CameraService.deleteCamera(cameraId).subscribe((response => {
      this.spinner.hide();
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_CAMERA, AppConstants.MessageModal.DELETE, MessageSeverity.success);
      this.getCameraList();
    }),
    (error: HttpErrorResponse) => {
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_CAMERA, error.error.messages, MessageSeverity.error);
      this.spinner.hide();
    });
  }

  getHeadquarters() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
          this.headquarterSelect = 0;
        }else{
          this.headquarters = [];
        }
        this.getCameraList();
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.headquarters = [];
          this.spinner.hide();
        });
    } else {
      this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
    }
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
    this.getCameraList();
  }

  applyUpFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchText = filterValue.trim().toLowerCase();
      var _this = this;
      window.clearTimeout(_this.timer);
        _this.timer = window.setTimeout(() => {
            _this.page = 1;
            _this.paginator.pageIndex = 0;
            _this.getCameraList();
          }, _this.timeoutVal);
  }

  applyPressFilter(event: Event) {
    var _this = this;
    window.clearTimeout(_this.timer);
  }
}
