import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseSettingService } from 'src/app/services/enterprise-setting/enterprise-setting.service';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';

@Component({
  selector: 'app-enterprise-profile',
  templateUrl: './enterprise-profile.component.html',
  styleUrls: ['./enterprise-profile.component.css']
})
export class EnterpriseProfileComponent implements OnInit {
  settingForm: FormGroup
  title: string;
  loading: boolean = false;
  isAdminGys: boolean = false;
  sessionUserId: string;
  storageFileSelect: number;
  logo = "../../../../../../assets/images/enterprise-default.png";
  fileLogo: File;
  idEnterprise: number;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  enabledRead: number;
  enabledWrite: number;

  colorPrimary: string;
  colorSecundary: string;

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private enterpriseSettingService: EnterpriseSettingService,
    private alertService: AlertService,
    private emitterService: EmitterService) {
      this.idEnterprise = +localStorage.getItem(AppConstants.Session.ENTERPRISE);
      document.getElementById("titleHeaderNav").innerHTML = '<span class="font-weight-bold">Mi Empresa</span>';
    }

    setTheme(){
      this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
      this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
      this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
      this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
       //validate Permisses menu
       var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
       var page = this.router.url.split("/");
       var i = page[page.length-1];
       var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
       if(itemTag.length>0){
         this.enabledRead = itemTag[0].activeRead;
         this.enabledWrite = itemTag[0].activeWrite;
       }else{
         this.enabledRead = 0;
         this.enabledWrite = 0;
       }
    }

  ngOnInit() {
    this.setTheme();
    this.title = AppConstants.TitleModal.UPDATE_ENTERPRISE_SETTING ;
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.sessionUserId = localStorage.getItem(AppConstants.Session.USERID);
    this.createForm();
    this.getData();
  }

  getData() {
    this.spinner.show();
    this.enterpriseSettingService.getEnterpriseSetting(this.idEnterprise).subscribe((response => {
      if(response.error == null && response.enterpriseSetting != null){
        this.settingForm.patchValue({
          idEnterprise: this.idEnterprise,
          color1: "",//response.enterpriseSetting.color1,
          color2: "",
          /*idStorageFile: response.enterpriseSetting.idStorageFile,
          pathFile: response.enterpriseSetting.pathFile,*/
          userId: parseInt(this.sessionUserId)
        });
        this.colorPrimary = response.enterpriseSetting.color1;
        this.colorSecundary = response.enterpriseSetting.color2;
        if(response.enterpriseSetting.logo != "" && response.enterpriseSetting.logo){
          if(response.enterpriseSetting.idStorageType == 2){//azure
            this.logo = response.enterpriseSetting.logo;
          }else{
            this.logo = response.enterpriseSetting.storagePath + "/"+response.enterpriseSetting.logo;
          }
        }
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  createForm() {
    this.settingForm = this.formBuilder.group({
        idEnterprise: this.idEnterprise,
        color1: [true, []],
        color2: [true, []],
        /*idStorageFile: [[Validators.required]],
        pathFile: [],*/
        userId: parseInt(this.sessionUserId)
    });
  }

  get f() { return this.settingForm.controls; }

  openConfirmationModal() {
    if (this.settingForm.valid) {
      let dialogRef = this.dialog.open(MessageModalComponent, {
          data: {
              title: AppConstants.TitleModal.ENTERPRISE_SETTING_UPDATE,
              message: AppConstants.MessageModal.UPDATE_MESSAGE_ENTERPRISE_SETTING_CONFIRM,
              hasButton: true,
              contentButton: {
                  yes: 'SI',
                  no: 'CANCELAR'
              }
          },
      });

      dialogRef.afterClosed().subscribe(response => {
          if (response.result == true) {
            this.update();
          }
      });

    } else {
      this.settingForm.markAllAsTouched();
    }
  }

  update() {
    /*if(this.settingForm.get('idStorageFile').value == 1 && !this.settingForm.get('pathFile').value){
      this.alertService.showMessage("", AppConstants.MessageModal.REQUIRED_ENTERPRISE_SETTING_PATH_FILE, MessageSeverity.error);
      return false;
    }*/

    if(this.settingForm.valid){
      this.spinner.show();

      var formData = new FormData();
      formData.append("color1",this.colorPrimary);
      formData.append("color2",this.colorSecundary);
      formData.append("userId",this.sessionUserId);
      formData.append("idEnterprise",this.idEnterprise.toString());
      formData.append("logo",this.fileLogo);

      this.enterpriseSettingService.updateEnterpriseSetting(formData).subscribe((response => {
        this.alertService.showMessage("", AppConstants.MessageModal.UPDATE_ENTERPRISE_SETTING, MessageSeverity.success);
        this.spinner.hide();
       // localStorage.setItem(AppConstants.Session.LOGO, enterprise.enterprises[0]['logo'].toString());
        localStorage.setItem(AppConstants.Session.THEME_COLOR1, this.colorPrimary);
        localStorage.setItem(AppConstants.Session.THEME_COLOR2, this.colorSecundary);
       
        window.location.reload();
      }),
        (error: HttpErrorResponse) => {
          this.alertService.showMessage("", error.error.messages, MessageSeverity.error);
          this.spinner.hide();
        });
    }else{
      this.settingForm.markAllAsTouched();
      this.alertService.showMessage("", AppConstants.MessageModal, MessageSeverity.error);
    }
  }

  close() {
    this.dialog.closeAll();
  }

  onFileSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      var _this = this;
      reader.onload = (event:any) => {
        _this.logo = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.fileLogo = event.target.files[0];
  }
  }
}

