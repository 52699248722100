<section class="container">
  <div class="row section-name">
    <div class="header-title-options">
        <div class="row w-100">
          <div class="col-lg-10 col-xs-12">
            <mat-form-field appearance="standard">
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="applyUpFilter($event)" (keypress)="applyPressFilter($event)" maxlength="200" #input>
            </mat-form-field>
          </div>
        </div>
    </div>
  </div>
</section>
<div class="container">
    <section class="table-responsive">
      <table class="table" mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <ng-container matColumnDef="headquarter">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > Sede </th>
          <td class="text-center" *matCellDef="let element"> {{element.headquarter}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > Nombre </th>
          <td class="text-center" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > Descripción </th>
          <td class="text-center" *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="ip">
            <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > IP </th>
            <td class="text-center" *matCellDef="let element"> {{element.ip}} </td>
        </ng-container>

        <ng-container matColumnDef="mac">
            <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > MAC </th>
            <td class="text-center" *matCellDef="let element"> {{element.mac}} </td>
        </ng-container>

        <ng-container matColumnDef="nomenclature">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > Nomenclatura </th>
          <td class="text-center" *matCellDef="let element"> {{element.nomenclature}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > Acciones </th>
          <td class="text-center" *matCellDef="let element">
            <mat-icon color="primary" class="icon-cursor" matTooltip="Editar" [style.color]="color1" *ngIf="enabledWrite" (click)="openEditModal(element)">create</mat-icon>
            <mat-icon class="icon-cursor" [style.color]="color1" matTooltip="Ver" *ngIf="!isBusiness && enabledRead && !enabledWrite" (click)="openEditModal(element)">remove_red_eye</mat-icon>
            <mat-icon color="primary" class="icon-cursor" matTooltip="Eliminar" *ngIf="enabledWrite" [style.color]="color1" (click)="delete(element)">delete</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [hidePageSize]="true"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="getServerData($event)">
      </mat-paginator>
    </section>

  </div>

<button mat-fab class="fab-button" *ngIf="enabledWrite" [style.background]="color1" [style.color]="color2" aria-label="Add icon" (click)="openAddModal()" >
  <mat-icon>add</mat-icon>
</button>
