import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Pipe } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DigitalTwinsService } from 'src/app/services/digital-twins/digital-twins.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';

@Component({
  selector: 'app-digital-twins',
  templateUrl: './digital-twins.component.html',
  styleUrls: ['./digital-twins.component.css']
})
@Pipe({ name: 'safe' })
export class DigitalTwinsComponent implements OnInit {
  url:SafeResourceUrl;
  enterpriseSelect: number = 0;
  headquarterSelect: number = 0;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  enabledRead: number;
  enabledWrite: number;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private digitalTwinsService: DigitalTwinsService) {
    document.getElementById("titleHeaderNav").innerHTML = 'IOT > <span class="font-weight-bold">Digital Twins</span>';
  }

  ngOnInit() {
    this.setLS();
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.getDigitalTwins();
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  getDigitalTwins() {

      this.spinner.show();
      this.digitalTwinsService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
        if(response.data.length>0){
          this.url = this._sanitizer.bypassSecurityTrustResourceUrl(response.data[0].url);
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
        
  }

}
