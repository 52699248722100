<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
        <mat-icon [style.color]="data.color"  [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="enterpriseForm"  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>RUC*</mat-label>
                        <input matInput type="text" formControlName="ruc" OnlyNumbers maxlength="11" >
                        <mat-error *ngIf="f.ruc.hasError('required')">
                            El ruc es <strong>requerido</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.ruc.hasError('minlength')">
                            El ruc debe tener <strong>11 dígitos</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Nombre de Empresa*</mat-label>
                        <input matInput formControlName="socialReason" maxlength="100">
                        <!--<mat-error *ngIf="f.socialReason.hasError('required')">
                            La razón social es <strong>requerida</strong>.
                        </mat-error>-->
                        <mat-error *ngIf="f.socialReason.hasError('maxlength')">
                            El nombre de la empresa debe tener máximo <strong>100 carácteres</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.socialReason.hasError('whitespace')">
                            El nombre de la empresa es <strong>requerida</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                    <mat-label>Contacto*</mat-label>
                    <input matInput formControlName="contact" maxlength="80">
                    <mat-error *ngIf="f.contact.hasError('required') || f.contact.hasError('whitespace')">
                        El contacto es <strong>requerido</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.contact.hasError('maxlength')">
                        El contacto debe tener máximo <strong>80 carácteres</strong>.
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Teléfono*</mat-label>
                      <input matInput formControlName="phoneContact" maxlength="11" OnlyNumbers>
                      <mat-error *ngIf="f.phoneContact.hasError('required')">
                          El teléfono del Contacto es <strong>requerido</strong>.
                      </mat-error>
                      <mat-error *ngIf="f.phoneContact.hasError('maxlength')">
                          El teléfono del Contacto debe tener máximo <strong>11 carácteres</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Dirección</mat-label>
                        <input matInput formControlName="address" maxlength="100">
                        <mat-error *ngIf="f.address.hasError('maxlength')">
                            La dirección debe tener máximo <strong>100 carácteres</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Referencia</mat-label>
                        <input matInput formControlName="reference" maxlength="100">
                        <mat-error *ngIf="f.reference.hasError('maxlength')">
                            La referencia debe tener máximo <strong>100 carácteres</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Representante Legal</mat-label>
                        <input matInput formControlName="legalRepresentative" maxlength="80">
                        <mat-error *ngIf="f.legalRepresentative.hasError('maxlength')">
                            El Representante Legal debe tener máximo <strong>80 carácteres</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.legalRepresentative.hasError('whitespace')">
                            El Representante Legal es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Teléfono</mat-label>
                        <input matInput formControlName="phoneLegalRepresentative" maxlength="11" OnlyNumbers>
                        <mat-error *ngIf="f.phoneLegalRepresentative.hasError('maxlength')">
                            El teléfono del Legal debe tener máximo <strong>11 carácteres</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Dominio*</mat-label>
                      <input matInput formControlName="domain" maxlength="255">
                      <mat-error *ngIf="f.domain.hasError('maxlength')">
                          El dominio debe tener máximo <strong>255 carácteres</strong>.
                      </mat-error>
                      <mat-error *ngIf="f.domain.hasError('whitespace')">
                          El dominio es <strong>requerido</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
            </div>
              <div class="row">
                <!--
                <div class="col-12 col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Máximo de Usuarios</mat-label>
                    <input matInput formControlName="maxUsers" maxlength="4" OnlyNumbers>
                    <mat-error *ngIf="f.maxUsers.hasError('required')">
                      El número maximo de usuarios es <strong>requerido</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.maxUsers.hasError('maxlength')">
                      El número maximo de usuarios debe tener máximo <strong>4 carácteres</strong>.
                    </mat-error>
                  </mat-form-field>
                </div>-->
                <input matInput hidden="true"  formControlName="maxUsers" >
               <!-- <div class="col-12 col-md-6">
                  <mat-form-field class="w-100" color="primary">
                    <mat-label>Expiración de licencia</mat-label>
                    <input matInput [matDatepicker]="expirationDate" [min]="todayDate" formControlName="expirationDate" readonly>
                    <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
                    <mat-datepicker #expirationDate></mat-datepicker>
                    <mat-error *ngIf="f.expirationDate.hasError('required')">
                      La fecha de expiración de licencia es <strong>requerida</strong>.
                    </mat-error>
                  </mat-form-field>
                </div>-->
                <div class="col-12 col-md-6" *ngIf="showEdit">
                  <mat-form-field class="w-100">
                      <mat-label>Código de Empresa</mat-label>
                      <input matInput formControlName="codeEnterprise" maxlength="6" readonly>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 my-auto">
                  <mat-checkbox labelPosition="before" formControlName="status">Habilitado</mat-checkbox>
                </div>

                <input matInput hidden="true"  formControlName="expirationDate" >

            </div>

            <!--
            <div class="row" *ngIf="data.operation == 2 && idStorageFile <= 0">
              <div class="alert alert-danger" style="align-items: center;display: flex;">Para poder crear zonas y pin, debe ir a <mat-icon>settings</mat-icon>Configuración y seleccionar el modo de Almacenamiento de archivos</div>
            </div>

            <div class="row" *ngIf="data.operation == 2 && idStorageFile > 0">
                <div class="col-12 col-md-6">

                  <div class="text-right">
                    <button type="button" mat-raised-button (click)="fileInput.click()" style="margin-top:15px;">Añadir Ubicación</button>
                    <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">
                  </div>

                    <div class="table-responsive">
                      <table class="table" mat-table [dataSource]="dataSource">


                        <ng-container matColumnDef="name">
                          <th scope="col" class="text-center" *matHeaderCellDef> Zona Ubicación </th>
                          <td class="text-center" *matCellDef="let element"> {{element.zoneName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                          <th scope="col" class="text-center" *matHeaderCellDef> Acción </th>
                          <td class="text-center" *matCellDef="let element">
                            <mat-icon color="primary" class="icon-cursor" (click)="delete(element)">delete</mat-icon>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>


                    </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="text-right">
                    <button type="button" mat-raised-button (click)="fileInput2.click()" style="margin-top:15px;">Añadir Pin Mapa</button>
                    <input hidden (change)="onFilePinMapSelected($event)" #fileInput2 type="file" id="filePinMap" accept="image/jpeg, image/jpg, image/png">
                  </div>

                    <div class="table-responsive">
                      <table class="table" mat-table [dataSource]="dataSource2">


                        <ng-container matColumnDef="name">
                          <th scope="col" class="text-center" *matHeaderCellDef> Pin </th>
                          <td class="text-center" *matCellDef="let element2"> {{element2.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                          <th scope="col" class="text-center" *matHeaderCellDef> Acción </th>
                          <td class="text-center" *matCellDef="let element2">
                            <mat-icon color="primary" class="icon-cursor" (click)="deletePinMap(element2)">delete</mat-icon>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns2" class="table-background"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                      </table>


                    </div>
                </div>
            </div>
          -->

           <!-- <div class="row">
                <div class="col-12 col-md-12">
                  <div style="text-align: right;">
                    <button type="button" mat-raised-button (click)="fileInput.click()" style="margin-top:15px;">Añadir Ubicación</button>
                  </div>
                 <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">
                    <div class="table-responsive mt-3">
                      <table class="table" mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="index">
                          <th scope="col" class="text-center" *matHeaderCellDef> # </th>
                          <td class="text-center" *matCellDef="let element; let i = index">
                            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                          <th scope="col" class="text-center" *matHeaderCellDef> Zona Ubicación </th>
                          <td class="text-center" *matCellDef="let element"> {{element.zoneName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                          <th scope="col" class="text-center" *matHeaderCellDef> Acción </th>
                          <td class="text-center" *matCellDef="let element">
                            <mat-icon color="primary" class="icon-cursor" (click)="delete(element)">delete</mat-icon>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>

                      <mat-paginator [pageSize]="2"
                        [hidePageSize]="true">
                      </mat-paginator>
                    </div>
                </div>
            </div>-->
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCELAR</button>
    <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite" (click)="save()">GUARDAR</button>
</mat-dialog-actions>

