import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-switch-vertical',
  templateUrl: './chart-switch-vertical.component.html',
  styleUrls: ['./chart-switch-vertical.component.css']
})
export class ChartSwitchVerticalComponent implements OnInit {
  @Input('id') id : any;
  @Input('value') value : any;
  
  constructor() {
    this.id = "PanelSwitchVertical"+this.id;
   }

  ngOnInit(): void {
  }

}
