import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetSensorReadingplace } from 'src/app/models/sensor-readingplace/get-sensor-readingplace';

@Injectable({
  providedIn: 'root'
})
export class SensorReadingplaceService {
  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'SensorReadingplace';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getList(parameters: any): Observable<GetSensorReadingplace> {
    let httpParams = new HttpParams({
      fromObject: parameters,
    });
    return this.httpClient.get<GetSensorReadingplace>(`${this.REQUEST_URL}/pag`, { params: httpParams });
  }

  getListAll(idEnterprise: number = 0, idHeadquarter: number = 0): Observable<GetSensorReadingplace> {
    return this.httpClient.get<GetSensorReadingplace>(`${this.REQUEST_URL}/enterprise/${idEnterprise}/headquarter/${idHeadquarter}`);
  }

  create(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}`,data);
  }

  update(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}`,data);
  }

  delete(id: number){
    return this.httpClient.delete<any>(`${this.REQUEST_URL}/` + id);
  }
}
