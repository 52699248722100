import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { RoleService } from 'src/app/services/role/role.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GetUserInfo } from 'src/app/models/admin/admin';

@Component({
  selector: 'app-role-modal',
  templateUrl: './role-modal.component.html',
  styleUrls: ['./role-modal.component.css']
})
export class RoleModalComponent implements OnInit {
  roleForm: FormGroup;
  headquarterSelect: number;
  headquarters = [];
  edit = false;
  menu = [];

  constructor(
    public dialogRef: MatDialogRef<RoleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private roleService: RoleService,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    this.getListMenu();
    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.edit = true;
      this.roleForm.controls['name'].setValue(this.data.obj.name);
    }
  }

  createForm(){
    this.roleForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(50)]],
    })
  }

  get f() { return this.roleForm.controls; }

  save(){
    if(this.roleForm.valid){
      const payload = {
        name:this.roleForm.value["name"],
        menu: JSON.stringify(this.menu),
        id: this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0,
        idEnterprise: this.data.idEnterprise,
        idUser: localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
      if(this.data.action == AppConstants.OperationType.ADD){
        this.roleService.create(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_ROLE, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
              this.spinner.hide();
          }
        });
      }else{
        this.roleService.update(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ROLE, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.adminService.getUserInfoByUsername(localStorage.getItem(AppConstants.Session.USERNAME))
              .subscribe((response: GetUserInfo) => {
                console.log(response);
                if(response.error == null){
                  var enterprise = response.data;
                  localStorage.setItem(AppConstants.Session.USERID, enterprise.userId.toString());
                  localStorage.setItem(AppConstants.Session.USER, enterprise.fullName.toString());
                  localStorage.setItem(AppConstants.Session.USER, enterprise.fullName.toString());
                  localStorage.setItem(AppConstants.Session.ENTERPRISES, JSON.stringify(enterprise.enterprises));
                  localStorage.setItem(AppConstants.Session.HEADQUARTERS, JSON.stringify(enterprise.headquarters));
                  if(enterprise.enterprises.length>0){
                    localStorage.setItem(AppConstants.Session.ENTERPRISE, enterprise.enterprises[0]['id'].toString());
                    localStorage.setItem(AppConstants.Session.HEADQUARTER, "0");
                  }
                  localStorage.setItem(AppConstants.Session.ENTERPRISE_SYSTEM, enterprise.systems.toString());
                  localStorage.setItem(AppConstants.Session.MENU_ICON, "0");
                  localStorage.setItem(AppConstants.Session.CHANGE_ENTERPRISE_MENU, "0");
                  localStorage.setItem(AppConstants.Session.LOGO, enterprise.enterprises[0]['logo'].toString());
                  localStorage.setItem(AppConstants.Session.THEME_COLOR1, enterprise.enterprises[0]['themeColor1'].toString());
                  localStorage.setItem(AppConstants.Session.THEME_COLOR2, enterprise.enterprises[0]['themeColor2'].toString());
                  var idUser = parseInt(localStorage.getItem(AppConstants.Session.USERID));
                  this.adminService.getMenuEnterpriseRole(parseInt(enterprise.enterprises[0]['id']),parseInt(localStorage.getItem(AppConstants.Session.HEADQUARTER)),idUser).subscribe((response) => {
                      var menu = response.data;
                      localStorage.setItem(AppConstants.Session.MENU, JSON.stringify(menu));
                      window.location.reload();
                  });
                }
                this.spinner.hide();
              }, (error: HttpErrorResponse) => {
                this.spinner.hide();
              });
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
            this.spinner.hide();
          }
        });
      }
    }else{
      this.roleForm.markAllAsTouched();
    }
  }

  getListMenu(){
    this.spinner.show();
    this.adminService.getMenuEnterprise(this.data.idEnterprise).subscribe((_) => {
      var _this = this;
      if(AppConstants.OperationType.UPDATE == this.data.action){
        var json = JSON.parse(_this.data.obj.menu);
        if(json){
          _.data.forEach(x => {
            json.forEach(y => {
              if(x.id == y.id){
                console.log("activeWrite",y.activeWrite);
                console.log("activeRead",y.activeRead);
                x.activeWrite = y.activeWrite
                x.activeRead = y.activeRead
              }
            });
          });
        }
      }
      this.menu = _.data;
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  showCheckbox(item: any){
    if(!item.isChild){
      if(this.menu.filter(x => x.idParent == item.id).length > 0){
        return false;
      }else{
        return true;
      }
    }
    return true;
  }

  close() {
    this.dialog.closeAll();
  }

  activeRead(item: any){
    this.menu.forEach(x => {
      if(x.id == item.id){
        x.activeRead = x.activeRead == 1 ? x.activeRead = 0 : x.activeRead = 1
        return false;
      }
    });
  }

  activeWrite(item: any){
    this.menu.forEach(x => {
      if(x.id == item.id){
        x.activeWrite = x.activeWrite == 1 ? x.activeWrite = 0 : x.activeWrite = 1
        return false;
      }
    });
  }
}
