import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import { SensorReadingplaceService } from 'src/app/services/sensor-readingplace/sensor-readingplace.service';
import { SensorService } from 'src/app/services/sensor/sensor.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { MessageModalComponent } from '../message/message-modal.component';
import { SensorTypeService } from 'src/app/services/sensor-type/sensor-type.service';
import { ActuatorService } from 'src/app/services/actuator/actuator.service';

@Component({
  selector: 'app-panel-actuator-modal',
  templateUrl: './panel-actuator-modal.component.html',
  styleUrls: ['./panel-actuator-modal.component.css']
})
export class PanelActuatorModalComponent implements OnInit {

  sensorForm: FormGroup;
  actuators = [];
  graphs = [];
  sensorReadingPlaces = [];
  sensorTypes = [];
  sensorTypeSelect = 0;
  sensorTypeGraphSelect = 0;
  actuatorSelect = 0;
  constructor(
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private actuatorService: ActuatorService,
    private sensorTypeService: SensorTypeService,
    private sensorReadingplaceService: SensorReadingplaceService,
    private panelIOTService: PanelIOTService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    this.getActuators();
  }

  setValue(){
    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.sensorForm.controls['nomenclature'].setValue(this.data.obj.nomenclature);
      this.sensorForm.controls['idActuator'].setValue(this.data.obj.idActuator);
    }
  }

  createForm(){
    this.sensorForm = this.formBuilder.group({
      idActuator: ['', [Validators.required]],
      nomenclature: ['', [Validators.required,  Validators.maxLength(30),  Validators.minLength(2)]]
    });
  }

  get f() { return this.sensorForm.controls; }

  getActuators(){
    this.spinner.show();
    this.actuatorService.getListAll(this.data.idEnterprise,this.data.idHeadquarter).subscribe((response) => {
      this.actuators = response.data;
      if(this.data.action == AppConstants.OperationType.UPDATE){
        this.actuatorSelect = this.data.obj.idActuator;
      }else{
        this.actuatorSelect = this.actuators[0]["id"];
      }

      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });

     /* if(this.data.action == AppConstants.OperationType.ADD){
        this.sensorForm.controls['nomenclature'].setValue(this.sensors[0].nomenclatureInit);
        this.sensorForm.controls['idSensor'].setValue(this.sensors[0].id);
      } */
  }

  save(){
    if(this.sensorForm.valid){
      const payload = {
        id: this.data.action == AppConstants.OperationType.ADD ? 0 : this.data.obj.id,
        nomenclature: this.sensorForm.value["nomenclature"],
        idEnterprise: this.data.isAdminGys ? this.data.idEnterprise : localStorage.getItem(AppConstants.Session.ENTERPRISE),
        idHeadquarter: this.data.idHeadquarter,
        idGateway: this.data.idGateway,
        idZone: this.data.idZone,
        idActuator: this.sensorForm.value["idActuator"],
        idUser: localStorage.getItem(AppConstants.Session.USERID),
      }

      if(this.data.action == AppConstants.OperationType.ADD){
        this.panelIOTService.createActuator(payload).subscribe(_ => {
          if(_.message == null || _.message == ""){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_HEADQUARTER, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
      }else{
        this.panelIOTService.updateActuator(payload).subscribe(_ => {
          if(_.message == null || _.message == ""){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_HEADQUARTER, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
      }

    }else{
      this.sensorForm.markAllAsTouched();
    }
  }

  changeSensor(data) {
    var sensor = this.actuators.filter(x => x.id == data.value)[0];
    this.sensorForm.controls['idActuator'].setValue(data.value);
    this.sensorForm.controls['nomenclature'].setValue(sensor.nomenclatureInit);
  }

  close() {
    this.dialog.closeAll();
  }


  delete() {
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
        message: `${AppConstants.MessageModal.DELETE_DEFAULT}`,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteItem();
      }
    });

  }

  deleteItem() {
      this.spinner.show();
      this.panelIOTService.deleteSensor(this.data.obj.id).subscribe((response => {
        this.spinner.hide();
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SENSOR, AppConstants.MessageModal.DELETE, MessageSeverity.success);
        this.dialog.closeAll();
      }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SENSOR, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
    }
}

