import { EnterprisePinMaps } from "./enterprisePinMaps";

export class EnterpriseViewModel {
  enterpriseId: number;
  ruc: string;
  businessName: string;
  legalRepresentative: string;
  contac: string;
  representativeTelephone: string;
  contacTelephone: string;
  address: string;
  reference: string;
  maximumUsers: number;
  gyS: number;
  enabled: number;
  userCreation: string;
  userCreationDate: Date;
  userUpdate: string;
  userUpdateDate: Date;
  expirationDate: Date;
  codeEnterprise: string;
  idStorageFile: number;
  enterpriseZone: Array<EnterpriseZones>;
  streamingRtmp: string;
  streamingApi: string;
  streamingFile: string;
  streamingTitle: string;
  streamingDescription: string;
  token: number;
  enterprisePinMap: Array<EnterprisePinMaps>;
  domain: string;
  logo: string;
  themeColor1: string;
  themeColor2: string;
}

export class EnterprisePut {
  enterpriseId: number;
  ruc: string;
  businessName: string;
  legalRepresentative: string;
  contac: string;
  representativeTelephone: string;
  contacTelephone: string;
  address: string;
  reference: string;
  maximumUsers: number;
  gys: number;
  enabled: number;
  userUpdate: string
  zonesDelete: Array<number>;
}

export class Enterprise {
  ruc: string;
  businessName: string;
  legalRepresentative: string;
  contac: string;
  representativeTelephone: string;
  contacTelephone: string;
  address: string;
  reference: string;
  maximumUsers: number;
  gys: number;
  enabled: number;
  userCreation: string
}

export class EnterpriseSearch {
  search: string;
  searchByRuc: boolean;
  searchByBusinesName: boolean;
}

export class EnterpriseContract {
  enterpriseId: number;
  contractId: number;
  contractName: string;
  numberContract: string;
  subscription: Date;
  validityStart: Date;
  validityEnd: Date;
  pathContract: string;
  pathContractName: string;
  pathAdenda: string;
  pathAdendaName: string;
  reasonValidity: string;
  canceled: number;
  adendaName: string;
  adendaId: number
  userCreation: string;
  userCreationDate: Date;
  userUpdate: string;
  userUpdateDate: Date
}

export class EnterpriseZones {
  zoneId: number;
  enterpriseId: number;
  zoneName: string;
  path: string;
  file: File;
  token: number;
}
