<div class="row title-modal" [style.color]="data.color" [style.borderColor]="data.color">
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
    <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
      <form [formGroup]="userForm" >
          <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Gráfico*</mat-label>
                  <mat-select
                          formControlName="idGraph"
                          [(ngModel)]="graphSelect"
                  >
                      <mat-option *ngFor="let graph of graphs" [value]="graph.id">
                          {{graph.name}}
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.idGraph.hasError('required')">
                    El gráfico es <strong>requerido</strong>.
                  </mat-error>
                </mat-form-field>
              </div>
          </div>
      </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
