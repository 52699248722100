import { Component } from "@angular/core";
import { ThemeService } from "src/app/services/theme/theme.service";
import { AppConstants } from "src/app/shared/constants/app.contants";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent  {

  constructor(private themeService: ThemeService) { 
   /*if(localStorage.getItem(AppConstants.Session.THEME) == null || 
      localStorage.getItem(AppConstants.Session.THEME) == ""){
      var enterpriseId = localStorage.getItem(AppConstants.Session.ENTERPRISE);
      alert("enterprise  "+enterpriseId);
      this.themeService.getTheme(parseInt(enterpriseId)).subscribe((response2 => {
        if(response2.theme != null){
          localStorage.setItem(AppConstants.Session.THEME, JSON.stringify(response2.theme));
        }
      }));
    }*/
  }
}
