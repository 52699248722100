
<div class="container">
    <!-- <div class="row mt-3 section-name">
        <div class="col">
          Lista de Zonas
        </div>
    </div>
   <section class="filter mt-3">
      <div class="row">
          <div class="col-lg-3" *ngIf="isAdminGys">
                      <mat-form-field class="w-100">
                          <mat-label>Empresa</mat-label>
                          <mat-select
                                      (selectionChange)="changeEnterprise($event)"
                                      [(ngModel)]="enterpriseSelect">
                               <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                                  {{enterprise.businessName}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
          </div>
          <div class="col-lg-3">
                    <mat-form-field class="w-100">
                        <mat-label>Sede</mat-label>
                        <mat-select
                                    (selectionChange)="changeHeadquarter($event)"
                                    [(ngModel)]="headquarterSelect">
                             <mat-option [value]="0" *ngIf="headquarters.length > 1">
                                Todos
                             </mat-option>
                             <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                                {{headquarter.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        </div>

       <div class="col-lg-3 my-auto text-right">
          <button mat-raised-button color="primary" class="color-white w-100 p-0" (click)="openPinMapModal()">LISTA DE PINES</button>
        </div>
      </div>
  </section>-->
  <mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Mapas" [style.borderColor]="color1">
      <section class="container">
        <div class="row section-name">
          <div class="header-title-options">
              <div class="row w-100">
                <div class="col-lg-10 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Buscador</mat-label>
                    <input matInput (keyup)="applyUpFilter($event)" (keypress)="applyPressFilter($event)" maxlength="200"  #input>
                  </mat-form-field>
                </div>
              </div>
          </div>
        </div>
      </section>
      <section class="table-responsive mt-3">
        <table class="table" mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="index">
            <th scope="col" class="text-center" *matHeaderCellDef  [style.background]="color1" [style.color]="color2"> # </th>
            <td class="text-center" *matCellDef="let element; let i = index">
              {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
            </td>
          </ng-container>

          <ng-container matColumnDef="headquarter">
            <th scope="col" class="text-center" *matHeaderCellDef  [style.background]="color1" [style.color]="color2"> Sede </th>
            <td class="text-center" *matCellDef="let element"> {{element.headquarter}} </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th scope="col" class="text-center" *matHeaderCellDef  [style.background]="color1" [style.color]="color2"> Tipo </th>
            <td class="text-center" *matCellDef="let element"> {{element.typeName}} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th scope="col" class="text-center" *matHeaderCellDef  [style.background]="color1" [style.color]="color2"> Nombre </th>
            <td class="text-center" *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th scope="col" class="text-center" *matHeaderCellDef  [style.background]="color1" [style.color]="color2"> Descripción </th>
            <td class="text-center" *matCellDef="let element"> {{element.description}} </td>
          </ng-container>

          <ng-container matColumnDef="nomenclature">
            <th scope="col" class="text-center" *matHeaderCellDef  [style.background]="color1" [style.color]="color2"> Nomenclatura </th>
            <td class="text-center" *matCellDef="let element"> {{element.nomenclature}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th scope="col" class="text-center" *matHeaderCellDef  [style.background]="color1" [style.color]="color2"> Acciones </th>
            <td class="text-center" *matCellDef="let element">
              <mat-icon color="primary" class="icon-cursor" matTooltip="Ver Imagen" [style.color]="color1" (click)="showImage(element)">remove_red_eye</mat-icon>
              <mat-icon color="primary" class="icon-cursor" matTooltip="Editar" [style.color]="color1" (click)="openEditModal(element)">create</mat-icon>
              <mat-icon color="primary" class="icon-cursor" matTooltip="Eliminar" *ngIf="enabledWrite" [style.color]="color1" (click)="delete(element)">delete</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [hidePageSize]="true"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="getServerData($event)">
        </mat-paginator>
      </section>

    </mat-tab>
    <mat-tab label="Pines" *ngIf="systems.Va > 0 || systems.va > 0" >
      <section class="container">
        <div class="row section-name">
          <div class="header-title-options">
              <div class="row w-100">
                <div class="col-lg-10 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Buscador</mat-label>
                    <input matInput (keyup)="applyFilterPin($event)" maxlength="200"  #input2>
                  </mat-form-field>
                </div>
              </div>
          </div>
        </div>
      </section>
      <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table" mat-table [dataSource]="dataSource2">

                        <ng-container matColumnDef="headquarter">
                            <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Sede </th>
                            <td class="text-center" *matCellDef="let element2"> {{element2.headquarter}} </td>
                        </ng-container>
                    <ng-container matColumnDef="name">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Pin </th>
                        <td class="text-center" *matCellDef="let element2"> {{element2.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Acción </th>
                        <td class="text-center" *matCellDef="let element2">
                            <mat-icon [style.color]="color1" class="icon-cursor" (click)="showImagePin(element2)">remove_red_eye</mat-icon>
                            <mat-icon [style.color]="color1" class="icon-cursor" *ngIf="enabledWrite" (click)="deletePinMap(element2)">delete</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2" class="table-background"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                    </table>
                </div>
            </div>
        </div>

    </div>
    </mat-tab>
  </mat-tab-group>

  </div>


  <button mat-fab class="fab-button" [style.background]="color1" [style.color]="color2"  aria-label="Add icon" *ngIf="tab == 1 && enabledWrite" (click)="addModalPin()" >
    <mat-icon>add</mat-icon>
  </button>

  <button mat-fab class="fab-button" [style.background]="color1" [style.color]="color2"  aria-label="Add icon" *ngIf="tab == 0 && enabledWrite" (click)="openAddModal()" >
    <mat-icon>add</mat-icon>
  </button>
