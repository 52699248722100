import { Component, OnInit } from '@angular/core';
import { io } from "socket.io-client";

@Component({
  selector: 'app-showroom-va-streaming',
  templateUrl: './showroom-va-streaming.component.html',
  styleUrls: ['./showroom-va-streaming.component.css']
})

export class ShowroomVAStreamingComponent implements OnInit  {
  socket = io('ws://192.168.2.51:8080/camera-feed');

  constructor() { 
  }

  ngOnInit(): void {
    this.socket.on('new-frame', message => {
        document.getElementById('camera-frame').setAttribute(
            'src', `data:image/jpeg;base64,${message.base64}`
        );
    });
    var _this = this;
    window.setInterval(() => {
      _this.socket.emit('request-frame', {});
    }, 100);
  }
}
