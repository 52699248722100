<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
    </div>
  </div>
  <mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="powerbiForm"  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="row">
                <div class="col-12" >
                  <mat-form-field class="w-100">
                      <mat-label>URL*</mat-label>
                      <input matInput formControlName="url" >
                      <mat-error *ngIf="f.url.hasError('required')">
                          La URL <strong>requerida</strong>.
                      </mat-error>
                  </mat-form-field>
                </div>
            </div>
        </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCELAR</button>
  <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" (click)="save()" *ngIf="data.enabledWrite" >GUARDAR</button>
  </mat-dialog-actions>
  