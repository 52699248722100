import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { StreamingDomainIframeService } from 'src/app/services/streamingDomainIframe/streamingDomainIframe.service';
import { StreamingStadisticsService } from 'src/app/services/streamingStadistics/streamingStadistics.service';

@Component({
  selector: 'app-iframe-streaming',
  templateUrl: './iframe-streaming.component.html',
  styleUrls: ['./iframe-streaming.component.css']
})
export class IframeStreamingComponent implements OnInit{
  token: string;
  showCamera = true;
  streaming = 'Validando...';
  imagePreview = "";
  showLive = false;
  streamingFile = "";
  streamingUrl = "";
  id = "";
  streamingKey = "";
  successValidate = 0;
  url:string;
  enterpriseId = 0;
  idCamera = 0;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private streamingStadisticsService: StreamingStadisticsService,
    private streamingService: StreamingService,
    private streamingDomainIframeService: StreamingDomainIframeService) {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnInit(){
    this.spinner.show();
    this.url = (window.location != window.parent.location)
    ? document.referrer
    : document.location.href;
    if(!this.url){
      if(window.location.ancestorOrigins != null){
        this.url = window.location.ancestorOrigins[0];
      }
    }

    /*if(this.url == ""){
      this.streaming = 'SU NAVEGADOR NO SOPORTA LA TRANSMISIÓN';
      this.saveStadistics();
      this.spinner.hide();
    }else{
      this.streamingDomainIframeService.validateDomain(this.url).subscribe( _ => {
        if(_.error == null && _.validate){*/
          this.successValidate = 1;
          this.showIframe();
      /*  }else{
          this.streaming = 'NO TIENE PERMISOS PARA VER LA TRANSMISIÓN';
          this.saveStadistics();
          this.spinner.hide();
        }
      });
    }*/
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: { preventDefault: () => void; }) {
    event.preventDefault();
  }


  saveStadistics(){
    var data = {
      successValidate: this.successValidate,
      originDomain: this.url,
      idCamera: this.idCamera,
      idEnterprise: this.enterpriseId,
      origin: 'iframe'
    }
    this.streamingStadisticsService.createStadistics(data).subscribe(_ => {});
  }

  showIframe(){

    this.streamingService.getCameraIframeToken(this.token).subscribe( _ => {
      if(_.camera != null){
        this.streaming = '';
        //this.streamingFile = _.camera.streamingFile;
        if(_.camera.streamingImage == null && !_.camera.streamingImage){
          this.imagePreview =  "../../../../../assets/images/logo-claro.png";
        }else{
          this.imagePreview = _.camera.streamingImage;
        }
        this.streamingUrl = _.camera.streamingUrl;
        this.enterpriseId = _.camera.enterpriseId;
        //this.streamingKey = _.camera.streamingKey;
        this.id = "video"+_.camera.cameraId.toString();
        this.idCamera = _.camera.cameraId;
        this.showCamera = false;
      }else{
        this.streaming = 'LA TRANSMISIÓN HA SIDO DADA DE BAJA';
      }
      this.spinner.hide();
      this.saveStadistics();
    });
  }

  showStreaming(){
    this.showLive = true;
  }
}
