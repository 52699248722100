import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    //license
    var d = new Date();
    var date = d.getDate();
    var month = (d.getMonth()+1);
    var day = date.toString();
    if(date < 10){ day = "0"+date; }
    var m = month.toString();
    if(month < 10){ m = "0"+month; }
    var datestring = d.getFullYear() + "-" + m + "-" + day ;
    if(datestring < environment.expiredLicense){
      this.router.navigate(['/home']);
    }
  }

}
