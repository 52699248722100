<div style="margin:5px;">
    <div *ngIf="item.type==2" class="item-actuator item-type-slider">
        <div style="margin: 10px 0px;">
          <label class="label-actuator mb-2" >{{item.name}}</label>
          <div class="switch-gauge" *ngIf="item.id != 14" >
            <app-chart-switch [id]="item.idPanel" [idOrigin]="item.id" [valueChart]="item.value" [radius]="32"  [maxLimit]="item.maxLimit"  (click)="onChangeToogle(item.value,item.id)" ></app-chart-switch>
          </div>
          <div> 
            <div *ngFor="let childSensor of item.relationshipSensors" >
              <app-chart-line-streaming [id]="childSensor.id" [name]="childSensor.name"  [idCanvas]="item.idPanel + '_' + childSensor.id" [labelChart]="childSensor.name"></app-chart-line-streaming>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="item.type==3" class="item-actuator item-type-slider mt-2">
        <div style="margin: 10px 0px;">
          <label class="label-actuator text-center mb-2">{{item.name}}</label>
          <div class="item-type-slider-transparent">
            <ngx-slider (userChangeEnd)="onInputChange($event,item)"  [(value)]="item.value" [options]="item.options"></ngx-slider>
          </div>
          <!--<div class="div-value-slider mt-2">
            <span class="value-slider" id="value-slider{{item.id}}" >{{item.value}} %</span> 
          </div>-->

          <div> 
            <div *ngFor="let childSensor of item.relationshipSensors">
              <app-chart-line-streaming [id]="childSensor.id" [name]="childSensor.name" [idCanvas]="item.idPanel + '_' + childSensor.id" [labelChart]="childSensor.name"></app-chart-line-streaming>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="item.type==5" class="item-actuator item-actuator-options mt-2" >
        <div style="margin: 10px 0px;">
          <label class="label-actuator text-center" style="margin-bottom: 4%;">{{item.name}}</label>
          <div class="item-type-slider-transparent">
            <ngx-slider id="{{item.id}}" [(value)]="item.value" (userChangeEnd)="onInputChange($event,item)" [options]="item.options"></ngx-slider>
          </div>
          <!--<div class="div-value-slider mt-2" >
            <span class="value-slider" id="value-slider{{item.id}}" >{{item.value}} %</span> 
          </div>-->

          <div> 
            <div *ngFor="let childSensor of item.relationshipSensors">
              <app-chart-line-streaming [id]="childSensor.id" [name]="childSensor.name" [idCanvas]="item.idPanel + '_' + childSensor.id" [labelChart]="childSensor.name"></app-chart-line-streaming>
            </div>
          </div>
        </div>
      </div>
      <!-- Relationship 
      <div *ngIf="item.type==6"  class="item-actuator itemSwitchVertical" style="justify-content: inherit !important;justify-items: inherit !important;display: flex;
      flex-direction: row-reverse;
      margin-left: 174%;">
          <div class="title-switchvertical mb-1"  >
            <app-chart-switch-vertical *ngIf="item.type == 6" (click)="onChangeToogle(item.value,item.id)" [value]="item.value" [id]="item.idPanel" ></app-chart-switch-vertical>
          </div>
          <div class="relationshipActuators" *ngIf="item.relationshipActuators.length > 0">
              <div *ngFor="let itemRelationship of item.relationshipActuators"  class="item-actuator item-type-slider" style="margin-right: 20px;" >
                  <div *ngIf="itemRelationship.relationshipValue == item.value" style="margin: 10px 12px;">
                    <label class="label-actuator mb-2">{{itemRelationship.name}}</label>
                    <div class="switch-gauge" >
                      <app-chart-switch [id]="itemRelationship.idPanel" [valueChart]="itemRelationship.value" [radius]="50" (click)="onChangeToogleChild(item.id,itemRelationship.value,itemRelationship.id)" ></app-chart-switch>
                    </div>
                    <div style="display:flex;"> 
                      <div *ngFor="let childSensor of itemRelationship.relationshipSensors" >
                        <app-chart-line-streaming [id]="childSensor.id" [name]="childSensor.name" [idCanvas]="itemRelationship.idPanel + '_' + childSensor.id" [labelChart]="childSensor.name"></app-chart-line-streaming>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>-->
</div>
