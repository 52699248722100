import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertType } from 'src/app/models/alert/alert-type';
import { AlertsServices } from '../../../../services/alert/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { CameraZoneViewModel } from '../../../../models/camera/camera';
import * as FileSaver from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { CameraService } from 'src/app/services/camera/camera.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { HeatMapService } from 'src/app/services/heatmap/heatmap.service';
import {IonRangeSliderComponent} from "ng2-ion-range-slider";
import { Heatmap } from 'src/app/models/heatmap/heatmap';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/helpers/format-datepicker';

declare var $: any

type IData = number[][];

@Component({
    selector: 'app-camera',
    templateUrl: './heatmap.component.html',
    styleUrls: ['./heatmap.component.css'],
    providers: [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
})
export class HeatmapComponent implements OnInit {

    loading: boolean = false;
    alertTypes = Array<AlertType>();
    zoneSelect: number = 0;
    cameraSelect: number = 0;
    cameras = [];
    enterprisesZones = [];
    enterprises = [];
    enterpriseSelect : number = 0;
    isAdministrator=false;

    pictureUrl: string;
    enterpriseId: number;
    alertTypeId: number = 0;
    cameraId: number = 0;
    defaultPicture: string = "./assets/images/instrucciones_mapacalor.png";
    heatSpectrum: string = "./assets/images/espectro-calor.png";

    dataSource = new MatTableDataSource();
    displayedColumns: string[] = ['index','typeAlert', 'insertDateOcurrence',  'enabled','userName', 'updateCamera', 'insertDate'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('paginatorTop', { static: false }) paginatorTop: MatPaginator;
    @ViewChild('paginatorBottom', { static: false }) paginatorBottom: MatPaginator;
    pageEvent: PageEvent;
    pageIndex: number = 0;
    pageSize: number = 0;
    length: number = 0;
    alertNow: number = 0;
    dateFilter: string;
    reportAlertForm: FormGroup;
    myDate = new Date();
    date = new Date();
    dateF = new Date();
    timeI: string;
    timeF: string;
    dateV:string;
    idStorageFile: number;
    dateIView:string;
    dateFView:string;
    lastDateUrl:string;
    canvas: HTMLCanvasElement;
    circle: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D | null;
    width: number;
    height: number;
    widthInit: number;
    heightInit: number;
   // data: IData;
    radius: number;
    unit8Gradient: Uint8ClampedArray;
    headquarterSelect = 0;
    headquarters = [];
    _this = this;
    lang = "en-US";
    year = 2018;
    displayListStadistics = 'none';
    dataHeatMap:Heatmap;

    constructor(
      private alertService: AlertsServices,
      private spinner: NgxSpinnerService,
      private enterpriseService: EnterpriseService,
      private cameraService: CameraService,
      private datePipe: DatePipe,
      private AlertService: AlertService,
      private formBuilder: FormBuilder,
      private headquarterService: HeadquarterService,
      private zoneService: ZoneService,
      private heatMapService: HeatMapService
      ) {
        document.getElementById("titleHeaderNav").innerHTML = 'Video Analítica > <span class="font-weight-bold">Mapa de calor</span>';
      }

    ngOnInit() {
        this.createForm();
        this.enterpriseId = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
        this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
        this.dataSource.paginator = this.paginator;
        this.getZonesByEnterprise();
        // if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.AdministradorGyS) this.isAdministrator = true;
       // if(this.isAdministrator)
       // this.getEnterprises();
        this.getAlertTypes();
        this.pictureUrl = this.defaultPicture;
       // if(!this.isAdministrator) this.getZonesByEnterprise();
       this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
       this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
       this.dateV = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

       $("#sliderElement").ionRangeSlider({
          skin: "big",
          type: "double",
          grid: true,
          prettify: this.tsToDate
        });
    }


    dateToTS (date) {
      return date.valueOf();
    }

    tsToDate (ts) {
      var d = new Date(ts);

      return d.toLocaleDateString(this.lang, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric'
      });
    }

    setImageHeatMap(image:string, dataPositions:any,date: any, dateF:any,timeI:any, timeF:any,init): void{
      console.log("data origen dataPositions - setImageHeatMap",dataPositions);
      //document.getElementById("background-heat-map").style.backgroundImage = "url('../../../../../assets/heat-map/example-videoanalitica.png')";
      document.getElementById("heat-map").style.backgroundImage = "url("+image+")"; //"url('../../../../../assets/heat-map/example-videoanalitica2.png')";//
      document.getElementById("heat-map").style.backgroundSize = "contain";
      document.getElementById("heat-map").style.backgroundRepeat = "no-repeat";

      var canvas  = <HTMLCanvasElement> document.getElementById('heat-map');
      this.ctx = canvas.getContext("2d");

      this.widthInit = canvas.width;
      this.heightInit = canvas.height;
      this.width = 684;//canvas.width; //650;
      this.height = 385;//canvas.height; //385;

      this.circle = HeatmapComponent.createCanvas();
      this.radius = 5 + 5;
      this.computeRadius(5, 5);

      this.unit8Gradient = HeatmapComponent.computeGradient({
        0.4: "blue",
        0.7: "lime",
        0.8: "yellow",
        1.0: "red"
      });

      dataPositions.values.forEach(_=>{
        //console.log(_.date +" <> "+ dateFrom+ " "+this.timeI && _.date +" <> "+  dateTo+ " "+this.timeF);
        if(_.date >= date+ " "+timeI+":00" && _.date <= dateF+ " "+timeF+":59"){
          _.x = Math.round(parseFloat(_.x)*canvas.width).toString(); //210
          _.y = Math.round((parseFloat(_.y)*canvas.height)).toString(); //95
          _.model = parseInt(_.model).toString();
        }else{
          dataPositions.values = dataPositions.values.filter(person => person.x != _.x && person.y != _.y && person.date != _.date && person.model != _.model);
        }
      });
      // counting repeats of x,y,date
      const contador = {};
      let vecesMasRepetido = 0;
      let idMasRepetido = null;
      dataPositions.values.forEach(({ x,y,model }) => {
        if(parseInt(model) == this.alertTypeId){
          contador[x+"_"+y+"_"+model] = (contador[x+"_"+y+"_"+model] == null ? 1 : contador[x+"_"+y+"_"+model] + 1);
          if (contador[x+"_"+y+"_"+model] > vecesMasRepetido) {
            idMasRepetido = x+"_"+y+"_"+model;
            vecesMasRepetido = contador[x+"_"+y+"_"+model];
          }
        }
      });

      // generate values colors with repeats, assign of color
      var arrayKey = Object.keys(contador);
      var arrayCount = Object.values(contador);
      var dataVal = [];
      var i = 0;
      arrayKey.forEach(_=>{
        var item = _.split("_");
        var c = parseFloat(arrayCount[i].toString());
        if(c>10){ c=10; }
        dataVal.push([parseInt(item[0]),parseInt(item[1]),parseFloat((c/10).toFixed(1))]);
        i++;
      });

      //console.log("values pintar",dataVal);
      this.draw(dataVal);


      if(init==1){
        var slider = $("#sliderElement").data("ionRangeSlider");
        var _this = this;
        slider.update({
          min: this.dateToTS(new Date(dataPositions.values[0].date)),
          max: this.dateToTS(new Date(dataPositions.values[dataPositions.values.length-1].date)),
          from: this.dateToTS(new Date(dataPositions.values[0].date)),
          to: this.dateToTS(new Date(dataPositions.values[dataPositions.values.length-1].date)),
          prettify: this.tsToDate,
          onFinish: function (data) {
            // Called then action is done and mouse is released
            /*console.log("from",data.from);
            console.log("to",data.to);*/
            _this.changeValueMap(_this.formatDate(new Date(data.from)),_this.formatDate(new Date(data.to)));
          },
        });
        // RESET - reset slider to it's first values
        slider.reset();
      }
      console.log("data origen API ",this.dataHeatMap);
      this.displayListStadistics = 'block';
      // DESTROY - destroys slider and restores original input field
      //slider.destroy();
    }

    changeValueMap(datefrom,dateto){
      console.log("data origen API - changeValueMap",this.dataHeatMap);
      var images = this.dataHeatMap.images;
      var datas = this.dataHeatMap.values;
      var dateS = datefrom.split(" ");
      var dateF = dateto.split(" ");
      var imageInit = "";
      images.forEach(_=>{
        if(_.date == dateS[0]){
          imageInit = _.image;
          return false
        }
      });
      this.dataHeatMap.images = images;
      //console.log(dateS[0]+"----------"+dateF[0]+"----------"+dateS[1]+"----------"+dateF[1]);
      //console.log("enviar data value base sin modificar",datas);
      this.setImageHeatMap(imageInit,this.dataHeatMap,dateS[0],dateF[0],dateS[1],dateF[1],0);
    }

    computeRadius(r: number, blur: number): void {
      const { circle } = this;

      const ctx = circle.getContext("2d");

      if (!ctx) {
        throw new Error("The ctx is undefined");
      }

      const r2 = this.radius;

      circle.height = r2 * 2;
      circle.width = r2 * 2;

      ctx.shadowOffsetY = r2 * 2;
      ctx.shadowOffsetX = r2 * 2;

      //ctx.shadowBlur = blur;
      ctx.shadowColor = "black";

      ctx.beginPath();
      ctx.arc(-r2, -r2, r, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fill();
    }

    resize() {
      this.width = this.canvas.width;
      this.height = this.canvas.height;
    }

    draw(data): void { //minOpacity: number
      const { ctx } = this;

      if (!ctx) {
        throw new Error("The ctx is undefined");
      }

      ctx.clearRect(0, 0, this.width, this.height);

      for (let i = 0, len = data.length, p; i < len; i++) {
        p = data[i];
        ctx.globalAlpha = Math.min(p[2], 1);

        if (!this.circle || !this.radius) {
          throw new Error("The circle || radius is undefined");
        }

        ctx.drawImage(this.circle, p[0] - this.radius, p[1] - this.radius);
      }

      const colored = HeatmapComponent.colorize(
        ctx.getImageData(0, 0, this.width, this.height),
        this.unit8Gradient
      );

      ctx.putImageData(colored, 0, 0);
    }

    setImageBackground(image):void{
      var imageObj = new Image();
      var _this = this;
      imageObj.onload = function() {
        _this.ctx.drawImage(imageObj,0,0, _this.widthInit,_this.heightInit);
      };
      imageObj.src = image;
    }


    static computeGradient(grad: Record<number, string>): Uint8ClampedArray {
      const canvas = this.createCanvas();
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("The ctx is undefined");
      }

      const gradient = ctx.createLinearGradient(0, 0, 0, 256);

      canvas.width = 1;
      canvas.height = 256;

      Object.keys(grad).forEach((i) => {
        gradient.addColorStop(+i, grad[+i]);
      });

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, 1, 256);

      return ctx.getImageData(0, 0, 1,256).data;
    }

    static colorize(
      imageData: ImageData,
      gradient: Uint8ClampedArray
    ): ImageData {
      const pixels = imageData.data;

      for (let i = 0, len = pixels.length, j; i < len; i += 4) {
        j = pixels[i + 3] * 4;

        if (j) {
          pixels[i] = gradient[j];
          pixels[i + 1] = gradient[j+1];
          pixels[i + 2] = gradient[j+2];
        }
      }

      return imageData;
    }

    static createCanvas(): HTMLCanvasElement {
      return document.createElement("canvas");
    }


    createForm() {
        this.reportAlertForm = this.formBuilder.group({
            startDate: ['', [Validators.required]],
            endDate: ['', [Validators.required]],
            startHour: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
            endHour: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]]
        });
    }

    ngAfterContentChecked(): void {
      if (this.paginatorTop) {
        this.paginatorBottom.length = this.paginatorTop.length;
      }
  }

  handlePaginatorTop(e): void {
      const { pageSize, pageIndex } = e;
      this.paginatorTop.pageSize = pageSize
      this.paginatorTop.pageIndex = pageIndex;
      this.paginatorTop.page.emit(e);
  }

  handlePaginatorBottom(e): void {
      const { pageSize, pageIndex } = e;
      this.paginatorBottom.pageSize = pageSize
      this.paginatorBottom.length = this.paginatorTop.length;
      this.paginatorBottom.pageIndex = pageIndex;
    }



  _keyUp(event: any) {
    let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode != 58 && charCode > 31
        && (charCode < 48 || charCode > 57)) {
        return false;
      }
  }

  get f() { return this.reportAlertForm.controls; }

      setData(){
        if(!this.cameraId || !this.alertTypeId){
            this.AlertService.showMessage(
                AppConstants.TitleModal.WARNING_TITLE,
                AppConstants.MessageModal.SEARCH_ALERT_NOT_SELECTED_HEATMAP,
                MessageSeverity.error);
        }else{
            let search = this.reportAlertForm.value;
            try{
              this.dateIView = search.startDateReport;
              this.dateFView = search.endDateReport;
            }catch{
              this.dateIView = search.startDateReport;
              this.dateFView = search.endDateReport;

              search.startDateReport = search.startDateReport;
              search.endDateReport = search.endDateReport;
            }
            this.searchs();
        }
      }

    searchs(){
      var filter = {
        enterpriseId: this.enterpriseId,
        dateI: this.dateIView,
        dateF: this.dateFView,
        timeI: this.timeI,
        timeF: this.timeF,
        cameraId: this.cameraSelect,
        alertId: this.alertTypeId
      };
      this.getAlerts(filter,0);
    }

    getEnterprises() {
        this.spinner.show();
        this.enterpriseService.getEnterprises().subscribe((response) => {
          this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
          this.enterpriseId = this.enterprises[0]["enterpriseId"];
         // this.idStorageFile = this.enterprises[0]["idStorageFile"];
          this.enterpriseSelect = this.enterpriseId;
          this.getHeadquarters();
          //this.getZonesByEnterprise();
          this.spinner.hide();
        },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
          });
    }

    getHeadquarters() {
      //if (this.isAdminGys) {
        this.spinner.show();
        this.headquarterService.getListAll().subscribe((response) => {
          if(response.error == null){
            this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
            if(this.headquarters.length>0){
              this.headquarterSelect = this.headquarters[0].id;
            }else{
              this.headquarterSelect = 0;
            }
          }else{
            this.headquarters = [];
          }
          this.getZonesByEnterprise();
          this.spinner.hide();
        },
          (error: HttpErrorResponse) => {
            this.headquarters = [];
            this.spinner.hide();
          });
     /* } else {
        this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
      }*/
    }

    changeEnterprise(data){
      this.enterpriseId = data.value;
      this.cameraId=0;
      this.cameraSelect = 0;
      this.cameras = [];
      this.headquarterSelect = 0;
      this.headquarters = [];
      this.enterprisesZones = [];
      this.getHeadquarters();
    }

    formatLabel(value: number | null) {
      if (!value) {
        return 0;
      }

      let decimalPart = +value.toString().replace(/^[^\.]+/,'0');
      let mm = decimalPart * 60;
      var mmPart = mm.toString().length == 1 ? mm.toString() + "0" : mm.toString();

      if (value >= 0) {
        let valueStr = value.toFixed(2);
        let strArr = valueStr.split(".");
        if(strArr[0].length == 1) {
          strArr[0] = "0" + strArr[0];
        }
        var hhPart = strArr[0];
      }

      return hhPart + ":" + mmPart;
   }

    getAlertTypes() {
        this.spinner.show();
        this.alertService.getTypes().subscribe((response) => {
           this.alertTypes = response;
           this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
            this.spinner.hide();
        });
    }

    changeCamera(id: number) {
        this.cameraId = id;
        this.pictureUrl = this.defaultPicture;
        this.alertTypeId = 0;
        this.dataSource.data = [];
    }

    showPicture(alertTypeId: number) {
      this.alertTypeId = alertTypeId;
       /* var filter = {
            enterpriseId: this.enterpriseId,
            dateI: this.dateV,
            dateF: this.dateV,
            timeI: this.timeI,
            timeF: this.timeF,
            cameraId: this.cameraSelect,
            alertId: this.alertTypeId
          };
        this.alertNow = 0;
        this.getAlerts(filter,1);
        this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
        this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
        this.reportAlertForm.reset({
            startDateReport: new Date(),
            endDateReport: new Date(),
            startTimeReport: this.timeI,
            endTimeReport: this.timeF
        });*/
    }

    changeDate(){
      this.pictureUrl = this.defaultPicture;
    }

    showHeatMap(){
      //this.setData();
       var camera = this.cameras.filter(x => x.cameraId == this.cameraSelect)[0];
        var data = {
          cameraId: this.cameraId,
          alertTypeId: this.alertTypeId,
          cameraIp: camera['ip'],
          idHeadquarter: this.headquarterSelect,
          idEnterprise: this.enterpriseSelect,
          startDate: this.date.toString(),
          endDate: this.dateF.toString(),
          startHour: this.timeI,
          endHour: this.timeF
        }
        data.startDate = this.datePipe.transform(data.startDate, 'yyyy-MM-dd');
        data.endDate = this.datePipe.transform(data.endDate, 'yyyy-MM-dd');
        this.heatMapService.getImageUriHeathmap(data).subscribe((response => {
          if(response.error == null){
            var dataHM = response.data;
            this.dataHeatMap = response.data;
            console.log("data origen API - showHeatMap",this.dataHeatMap);
            if(response.data.values.length>0){
              var dateFrom = this.parseDate(this.date.toLocaleDateString());
              var dateFrom = this.parseDate(this.dateF.toLocaleDateString());
              var _this = this;
              setTimeout(() => {
                console.log("data origen API - showHeatMap 2",_this.dataHeatMap);
                _this.setImageHeatMap(response.data.images[0].image,_this.dataHeatMap,dateFrom,dateFrom,_this.timeI,_this.timeF,1);
              }, 5000);
            }else{
              this.AlertService.showMessage("No existe data con los filtros seleccionados","", MessageSeverity.error);
              this.displayListStadistics = 'none';
            }








            /*if(this.idStorageFile == 1){ // Mode Local
              response.url = environment.pathFile + response.url;
            }
            this.pictureUrl = response.url;
            this.dateFilter = this.parseDate((response.date).split(" ")[0]);
            this.lastDateUrl = response.date;
            var filter = {
              enterpriseId: this.enterpriseId,
              dateI: this.dateFilter,//this.dateV,
              dateF: this.dateFilter,//this.dateV,
              timeI: this.timeI,
              timeF: this.timeF,
              cameraId: this.cameraSelect,
              alertId: this.alertTypeId
            };*/
            //this.getAlerts(filter,1);
          }else{
            this.displayListStadistics = 'none';
            this.length = 0;
            this.pictureUrl = "";
            this.lastDateUrl = "";
            this.alertNow = 0;
            this.dataSource.data = [];
            this.pictureUrl = this.defaultPicture;
            this.AlertService.showMessage("No existe una imagen de mapa de calor en esta fecha","", MessageSeverity.error);
          }
          this.loading = false;
      }),
        (error: HttpErrorResponse) => {
          this.loading = false;
          console.log(error);
      });
      /*
      this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
      this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
      this.reportAlertForm.reset({
          startDateReport: new Date(),
          endDateReport: new Date(),
          startTimeReport: this.timeI,
          endTimeReport: this.timeF
      });*/
    }

    parseDate(dateStr) {
      var date = dateStr.split('/');
      var day = date[0];
      var month = date[1];
      var year = date[2];
      month = month < 10 ? '0'+month : month;
      day = day < 10 ? '0'+day : day;
      return year + '-' + month + '-' + day;
   }

   formatDate(date) {
    var hours = date.getHours();
    var month = (date.getMonth()+1);
    var minutes = date.getMinutes();
    var day = date.getDate();
    var ampm = hours >= 12 ? 'pm' : 'am';
    minutes = minutes < 10 ? '0'+minutes : minutes;
    month = month < 10 ? '0'+month : month;
    day = day < 10 ? '0'+day : day;
    hours = hours < 10 ? '0'+hours : hours;
    var strTime = hours + ':' + minutes;
    return date.getFullYear() + "-" + month + "-" + day + " " + strTime;
  }

    getAlerts(filter: any,picture : number){
        filter.dateI = this.datePipe.transform(filter.dateI, 'dd/MM/yyyy');
        filter.dateF = this.datePipe.transform(filter.dateF, 'dd/MM/yyyy');
        this.cameraService.getHeathmapResumen(filter).subscribe((response => {
            this.length = response.length;
            if(picture){
              this.alertNow = this.length;
            }
            this.dataSource.data = response;
        }));
    }

    searchAlerts(){
      if(this.cameraSelect != 0 && this.alertTypeId != 0){
        this.showHeatMap();
      }else{
        this.AlertService.showMessage("Seleccione la cámara y el tipo de alerta","", MessageSeverity.error);
      }
    }

    getZonesByEnterprise() {
        this.enterprisesZones = [];
        this.spinner.show();
        this.zoneService.getListAll(this.enterpriseId,this.headquarterSelect).subscribe(response => {
          if(response.error == null){
            var zones = response.data.filter(_ => _.type == 1);
            this.enterprisesZones = zones;
            if(this.enterprisesZones.length>0){
              this.zoneSelect = this.enterprisesZones[0]["id"];
              this.getCameras(this.zoneSelect);
            }else{
              this.AlertService.showMessage("No tiene zonas VA registradas, debes de crear una zona para usar este módulo", "", MessageSeverity.error);
            }
          }else{
            this.enterprisesZones = [];
            this.cameras = [];
          }
        });
    }

    getCameras(zoneId: number) {
        this.cameraId = 0;
        this.spinner.show();
        this.cameras = new Array<CameraZoneViewModel>();
        this.cameraService.getCameraByZone(zoneId).subscribe((response => {
            this.cameras = response;
            if(this.cameras.length>0){
              this.cameraSelect = this.cameras[0].cameraId;
              this.cameraId = this.cameras[0].cameraId;
              this.pictureUrl = this.defaultPicture;
              this.alertTypeId = 0;
              this.dataSource.data = [];
            }
            this.spinner.hide();
        }),
          (error: HttpErrorResponse) => {
            this.spinner.hide();
          });
    }

    donwloadPicture() {
      if(this.idStorageFile == 2){ //Azure
        this.cameraService.downloadHeatmap(this.cameraId, this.alertTypeId, this.dateFilter,this.idStorageFile).subscribe((response => {
          let myGuid = uuidv4();
          const file = new Blob([response], { type: response.type });
          FileSaver.saveAs(file, `${myGuid}.jpg`);
          this.loading = false;
        }),
        (error: HttpErrorResponse) => {
        this.loading = false;
        });
      }else if(this.idStorageFile == 1){ // Mode Local
        var data = {
          cameraId: this.cameraId,
          alertTypeId: this.alertTypeId,
          dateFilter: this.dateFilter,
          idHeadquarter: this.headquarterSelect
        }
        this.heatMapService.getImageUriHeathmap(data).subscribe((response => {
          /*if(response.url != "" && response.url != null){
            if(this.idStorageFile == 1){ // Mode Local
              var path = environment.pathFile + response.url;
              let link = document.createElement("a");
              link.download = uuidv4();
              link.href = path;
              link.click();
            }
          }*/
        }));
      }

  }

    public saveToFileSystem(response: any, name: string) {
        let dataType = response.type;
        let binaryData = [];
        let filename = name;
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename) {
            downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    }

    changeHeadquarter(data) {
      this.headquarterSelect = data.value;
      this.cameras = [];
      this.cameraSelect = 0;
      this.cameraId= 0;
      this.getZonesByEnterprise();
    }

}
