import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import html2canvas from "html2canvas"; 
import { MatDialog } from '@angular/material/dialog';
import { NgxCaptureService } from 'ngx-capture';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { __values } from 'tslib';

@Component({
  selector: 'app-showroom-panel',
  templateUrl: './showroom-panel.component.html',
  styleUrls: ['./showroom-panel.component.css']
})
export class ShowroomPanelComponent implements OnInit {
  actuators = [];
  staticActuators = [];
  startButton = 0;
  stopButton = 0;
  startAlert = 0;
  stopAlert = 0;
  stepIndex: number = 0;
  showScreenshot = false;
  showMenu = false;
  humedadLoop:any;
  imagePath:any;
  @ViewChild('screen', { static: true }) screen: any;

  public steps: Array<number>;
  finalValue: number = 0;
  options: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
    selectionBarGradient: {
      from: 'white',
      to: '#01c4e7'
    }
  };

  options2: Options = {
    floor: 0,
    ceil: 100,
    showTicks: false,
    showTicksValues: true,
    stepsArray: [
      {value: 0},
      {value: 10},
      {value: 25},
      {value: 50},
      {value: 100}
    ],
    showSelectionBar: true,
    selectionBarGradient: {
      from: 'white',
      to: '#01c4e7'
    }
  };
  
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _sanitizer: DomSanitizer,
    public showroomService: ShowRoomService,
    public captureService: NgxCaptureService) {
      this.spinner.show();
    }

  ngOnInit(): void {
    this.getListActuators();
  }

  selectMenu(){
    this.showMenu = !this.showMenu;
  }

  onInputChangeSlider($event: any,item:any) {
    this.stepIndex = +$event.value;
    var array = item.stepOptions.split(",");
    var data = parseInt(array[this.stepIndex]);
    this.actuators.filter(_ => {
      if(_.id == item.id){
        if(data != _.value){ _.update = 1; }else{ _.update = 0; }
        _.value = data;
      }
    });
    //document.getElementById("value-slider"+item.id).innerHTML = array[this.stepIndex] + "%";
    this.saveActuators();
  }
  /*
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
  */
  getListActuators(){
    this.showroomService.getListActuators().subscribe((_) => {
      this.actuators = _.data;
      var start = parseInt(this.actuators.filter(_ => _.id == 6)[0].value);
      var stop = parseInt(this.actuators.filter(_ => _.id == 7)[0].value);
      var startAlert = parseInt(this.actuators.filter(_ => _.id == 11)[0].value);
      var stopAlert = parseInt(this.actuators.filter(_ => _.id == 12)[0].value);
     // if(start==1 && stop==0){ start = 0; }
      this.stopButton = stop;
      this.startButton = start; 
      this.stopAlert = stopAlert;
      this.startAlert = startAlert; 
      this.actuators.filter(_ => {
        let options;
        console.log("sensors "+ _.idSensors);
        _.idPanel = "panelActuator"+_.id;
        if(_.maxLimit > 0){
          _.value =(_.value * 100) / _.maxLimit;
        }
        if(_.type==2){
          _.colors = ['#e5f6ff', '#82cfff'];
        }else{
          if(_.stepOptions != "" && _.stepOptions){
            var stepsArray = [];
            var array = _.stepOptions.split(",");
            array.forEach(function(n) {
              stepsArray.push({value: n});
            })
            options = {
              floor: 0,
              ceil: 100,
              showTicks: false,
              showTicksValues: true,
              stepsArray: stepsArray,
              showSelectionBar: true,
              selectionBarGradient: {
                from: 'white',
                to: '#01c4e7'
              }
            };
          }else{
            options = {
              floor: 0,
              ceil: 100,
              showSelectionBar: true,
              selectionBarGradient: {
                from: 'white',
                to: '#01c4e7'
              }
            }
          }
        }

        if(_.type == 6){
          _.relationshipActuators.filter(__ => {
            __.idPanel = "panelActuator"+__.id;
            __.update = 0;
          });
        }
        console.log("valor :"+ _.value);
        _.dataValue =  _.value;
        _.options = options;
        _.update = 0;
        _.beforeValue = _.value;
      });
      this.staticActuators = this.actuators;
      this.staticActuators = this.staticActuators.filter(_ => _.id != 6 && _.id != 7 && _.id != 11 && _.id != 12);
      console.log(this.staticActuators);
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
    });
  }


  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value >= 1000) {
      var data = Math.round(value / 1000);
      return data + '%';
    }
    
    return value;
  }

  formatLabel2(value: number | null) {
    if (!value) {
      return 0;
    }
    var array = [0, 25, 50, 75, 90];
    value =  array[value];

    if (value >= 1000) {
      var data = Math.round(value / 1000);
      return data + '%';
    }
    
    return value;
  }

  onInputChange(changeContext: ChangeContext,item:any): void {
    var data = changeContext.value;
    var id = item.id;
    var beforeValue = item.beforeValue;
    // document.getElementById("value-slider"+id).innerHTML = data.toString() + "%";
    /**/
    this.actuators.filter(_ => {
      if(_.id == id){
        if(data != beforeValue){ _.update = 1; }else{ _.update = 0; }
        var dataValue = data;
        if(item.maxLimit > 0){
          dataValue = (item.maxLimit * data)/100;
        }
        _.dataValue = parseFloat(dataValue.toString());
        _.value = data;
        _.beforeValue = data;
      }
    });
    this.staticActuators = this.actuators;
    this.staticActuators = this.staticActuators.filter(_ => _.id != 6 && _.id != 7 && _.id != 11 && _.id != 12);
    this.saveActuators();
  }

  /*onInputChange(data: any,item:any) {
    console.log(data);
    var data = Math.round(event.value / 1000);
    var id = event.source._elementRef.nativeElement.id
    document.getElementById("value-slider"+id).innerHTML = data.toString() + "%";
    var dataValue = data;
    if(item.maxLimit > 0){
      dataValue = (item.maxLimit * data)/100;
    }
    this.actuators.filter(_ => {
      if(_.id == id){
        if(dataValue != _.value){ _.update = 1; }else{ _.update = 0; }
        _.value = dataValue;
      }
    });
  }*/

  onChangeToogle(value, id){
    var valueActuator;
    if(value){ valueActuator = 0; }else{ valueActuator = 1; }
    this.actuators.filter(_ => {
      if(_.id == id){
        _.update = 1;
        _.value = valueActuator;
        _.dataValue = _.value;
        if(_.type==6){
          _.relationshipActuators.filter(__ => {
                  __.update = 1;
                  __.value = 0;
                  __.dataValue = __.value;
          });
        }
      }
    });
    this.staticActuators = this.actuators;
    this.staticActuators = this.staticActuators.filter(_ => _.id != 6 && _.id != 7 && _.id != 11 && _.id != 12);
    this.saveActuators();

    if(id == 14){ // Humedad
      var dataH = {
        id: id,
        value: valueActuator
      }
      var _this = this;
      clearInterval(_this.humedadLoop);
      this.humedadLoop = setInterval(()=>{
        _this.showroomService.updateSensorlValue(dataH).subscribe(_ =>{
          console.log("update sensor");
        });
      },2000);
    }
  }

  onChangeToogleChild(idParent, value, id){
    this.actuators.filter(_ => {
      if(_.id == idParent){
        _.update = 1;
        _.relationshipActuators.filter(__ =>{
          if(__.id == id){
            __.update = 1;
            if(value){ __.value = 0; }else{ __.value = 1; }
            __.dataValue = __.value;
          }
        });
      }
    });
    this.staticActuators = this.actuators;
    this.staticActuators = this.staticActuators.filter(_ => _.id != 6 && _.id != 7 && _.id != 11 && _.id != 12);
    this.saveActuators();
  }
/*
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
*/
  saveActuators(){
    //this.spinner.show();
    console.log('data enviada');
    console.log(this.actuators);
    this.showroomService.updateActuators(this.actuators).subscribe((_) => {
      // this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
       // this.spinner.hide();
    });
  }

  playButtonShowRoom(){
    this.startButton = 1;
    this.stopButton = 1;
    this.actuators.filter(_ => {
      if(_.id == 6){
        _.update = 1;
        _.value = 1;
      }
      if(_.id == 7){
        _.update = 1;
        _.value = 0;
      }
      _.dataValue = _.value;
    });
    this.saveActuators();
  }

  stopButtonShowRoom(){
    this.startButton = 0;
    this.stopButton = 0;
    this.actuators.filter(_ => {
      if(_.id == 6){
        _.update = 1;
        _.value = 0;
      }
      if(_.id == 7){
        _.update = 1;
        _.value = 1;
      }
      _.dataValue = _.value;
    });
    console.log(this.actuators);
    this.saveActuators();
  }

  playButtonAlert(){
    this.showroomService.startAlert().subscribe(_ =>{});
    this.startAlert = 1;
    this.stopAlert = 1;
    this.actuators.filter(_ => {
      if(_.id == 11){
        _.update = 1;
        _.value = 1;
      }
      if(_.id == 12){
        _.update = 1;
        _.value = 0;
      }
      _.dataValue = _.value;
    });
    this.saveActuators();
  } 

  stopButtonAlert(){
    this.showroomService.stopAlert().subscribe(_ =>{});
    this.startAlert = 0;
    this.stopAlert = 0;
    this.actuators.filter(_ => {
      if(_.id == 11){
        _.update = 1;
        _.value = 0;
      }
      if(_.id == 12){
        _.update = 1;
        _.value = 1;
      }
      _.dataValue = _.value;
    });
    this.saveActuators();
  }

  changeIcon(){
    this.router.navigate(['/gys/showroom']);
  }

  save(){  
    this.spinner.show();
    this.saveActuators();
    var _this = this;
    setTimeout(function(){
      html2canvas(document.querySelector('.control-actuators')).then(function(canvas) {
        _this.imagePath = canvas.toDataURL('image/png');
        _this.showScreenshot = true; 
        _this.spinner.hide();
          setTimeout(function(){
            _this.showScreenshot = false; 
          }, 3000)
      });
    }, 100);
  }

  getDate(){
      var d = new Date(),
          hora = d.getHours(),
          minutos = d.getMinutes(),
          segundos = d.getSeconds(),
          month = (d.getMonth()+1),
          day = d.getDate().toString();
          if(parseInt(day) < 10){ day = "0"+day.toString(); }
          var m = month.toString();
          if(month < 10){ m = "0"+month; }
    return d.getFullYear() + "-" + m + "-" + day + " "+ hora+":"+minutos+":"+segundos;
  }

}
