import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import { ItemdomainIframeStreamingModalComponent } from 'src/app/shared/components/modals/domain-iframe-streaming/domain-iframe-streaming-modal.component';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { ModalEmailShowRoomComponent } from 'src/app/shared/components/modals/showroom/modal-email-show-room/modal-email-show-room.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-showroom-email',
  templateUrl: './showroom-email.component.html',
  styleUrls: ['./showroom-email.component.css']
})
export class ShowroomEmailComponent implements OnInit {
  loading: boolean = false;
  dataSource = new MatTableDataSource();
  dataSources = new MatTableDataSource();
  displayedColumns: string[] = ['email','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginators: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private showRoomService: ShowRoomService,
    ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSources.paginator = this.paginators;
    this.paginator._intl.itemsPerPageLabel = '';
    this.paginators._intl.itemsPerPageLabel = '';
    this.getList();
  }

  getList(){
    this.showRoomService.getEmails().subscribe((_) => {
      if(_.error == null){
        this.dataSource.data = _.data;
      }
    });
  }

  openAddModal() {
    let dialogRef = this.dialog.open(ModalEmailShowRoomComponent, {
      data: { title: 'Agregar Correo', action: 0,obj:null},
      width: '500px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      this.getList();
    });
  }

  openEditModal(data){
      let dialogRef = this.dialog.open(ModalEmailShowRoomComponent, {
        data: { title: 'Actualizar Correo', action: 1,obj:data},
        width: '500px',
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(_ => {
        this.getList();
      });
  }

  delete(obj) {
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
        message: `${AppConstants.MessageModal.DELETE_EMAIL_CONFIRMATION_MESSAGE1}`,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteSecurity(obj.id);
      }
    });

  }

    deleteSecurity(id: number) {
      this.spinner.show();
      this.showRoomService.deleteEmail(id).subscribe((response => {
        this.spinner.hide();
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE, AppConstants.MessageModal.DELETE, MessageSeverity.success);
        this.getList();
      }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
    }
}
