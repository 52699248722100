<div class="panel-showroom row" [ngClass]="showScreenshot ? 'distorcion' : ''"  >
  <div class="control-actuators row mt-3">
    <div *ngFor="let item of staticActuators" class="col-lg-6 col-md-6 col-xs-12 mb-6" >
        <div *ngIf="item.type==2" class="item-actuator item-type-slider">
          <div style="margin: 10px 0px;">
            <label class="label-actuator mb-2">{{item.name}}</label>
            <div class="switch-gauge" >
              <app-chart-switch [id]="item.idPanel" [valueChart]="item.value" [radius]="50" (click)="onChangeToogle(item.value,item.id)" ></app-chart-switch>
            </div>
            <div> 
              <div *ngFor="let childSensor of item.relationshipSensors" >
                <app-chart-line-streaming [id]="childSensor.id" [name]="childSensor.name"  [idCanvas]="childSensor.id" [labelChart]="childSensor.name"></app-chart-line-streaming>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="item.type==3" class="item-actuator item-type-slider mt-2">
          <div style="margin: 10px 0px;">
            <label class="label-actuator text-center mb-2">{{item.name}}</label>
            <div class="item-type-slider-transparent">
              <ngx-slider (userChangeEnd)="onInputChange($event,item)"  [(value)]="item.value" [options]="item.options"></ngx-slider>
            </div>
            <!--<div class="div-value-slider mt-2">
              <span class="value-slider" id="value-slider{{item.id}}" >{{item.value}} %</span> 
            </div>-->

            <div> 
              <div *ngFor="let childSensor of item.relationshipSensors">
                <app-chart-line-streaming [id]="childSensor.id" [name]="childSensor.name" [idCanvas]="childSensor.id" [labelChart]="childSensor.name"></app-chart-line-streaming>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="item.type==5" class="item-actuator item-actuator-options mt-2" >
          <div style="margin: 10px 0px;">
            <label class="label-actuator text-center" style="margin-bottom: 4%;">{{item.name}}</label>
            <div class="item-type-slider-transparent">
              <ngx-slider id="{{item.id}}" [(value)]="item.value" (userChangeEnd)="onInputChange($event,item)" [options]="item.options"></ngx-slider>
            </div>
            <!--<div class="div-value-slider mt-2" >
              <span class="value-slider" id="value-slider{{item.id}}" >{{item.value}} %</span> 
            </div>-->

            <div> 
              <div *ngFor="let childSensor of item.relationshipSensors">
                <app-chart-line-streaming [id]="childSensor.id" [name]="childSensor.name" [idCanvas]="childSensor.id" [labelChart]="childSensor.name"></app-chart-line-streaming>
              </div>
            </div>
          </div>
        </div>
        <!-- Relationship margin-left: 174%;-->
        <div *ngIf="item.type==6"  class="item-actuator itemSwitchVertical" style="justify-content: inherit !important;justify-items: inherit !important;display: flex;
        flex-direction: row-reverse;
        ">
            <div class="title-switchvertical mb-1"  >
              <app-chart-switch-vertical *ngIf="item.type == 6" (click)="onChangeToogle(item.value,item.id)" [value]="item.value" [id]="item.idPanel" ></app-chart-switch-vertical>
            </div>
            <div class="relationshipActuators" *ngIf="item.relationshipActuators.length > 0">
                <div *ngFor="let itemRelationship of item.relationshipActuators"  class="item-actuator item-type-slider" style="margin-right: 20px;" >
                    <div *ngIf="itemRelationship.relationshipValue == item.value" style="margin: 10px 12px;">
                      <label class="label-actuator mb-2">{{itemRelationship.name}}</label>
                      <div class="switch-gauge" >
                        <app-chart-switch [id]="itemRelationship.idPanel" [valueChart]="itemRelationship.value" [radius]="50" (click)="onChangeToogleChild(item.id,itemRelationship.value,itemRelationship.id)" ></app-chart-switch>
                      </div>
                      <div style="display:flex;"> 
                        <div *ngFor="let childSensor of itemRelationship.relationshipSensors" >
                          <app-chart-line-streaming [id]="childSensor.id" [name]="childSensor.name" [idCanvas]="itemRelationship.idPanel + '_' + childSensor.id" [labelChart]="childSensor.name"></app-chart-line-streaming>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>  

  
  <div class="icon-menu" *ngIf="!showMenu" (click)="selectMenu()" >
    <div>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </div>
  </div>

  <div class="menu-options" *ngIf="showMenu" >
    <div class="panel-buttons" *ngIf="staticActuators.length > 0">
     
        <button mat-raised-button (click)="playButtonAlert()" [ngClass]="{'background-primary': startAlert === 0, 'button-active' : startAlert === 1 }" class="w-100 buttonActuator">INICIAR ALERTAS</button>

        <button mat-raised-button (click)="stopButtonAlert()" [ngClass]="{'background-primary': stopAlert === 1, 'button-inactive' : stopAlert === 0 }" class="mt-2 w-100 buttonActuator">DETENER ALERTAS</button>
      
        <button mat-raised-button (click)="playButtonShowRoom()" [ngClass]="{'background-primary': startButton === 0, 'button-active' : startButton === 1 }" class="w-100 mt-2 buttonActuator">INICIAR SHOW ROOM</button>

        <button mat-raised-button (click)="stopButtonShowRoom()" [ngClass]="{'background-primary': stopButton === 1, 'button-inactive' : stopButton === 0 }" class="mt-2 w-100 buttonActuator">CERRAR SHOW ROOM</button>
    
    </div>
    <div class="icon-menu2" (click)="selectMenu()" >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
    <div>
      <div class="footer">
        <div class="btn-save w-100 mb-2" (click)="save()" >
          <span>CAPTURAR VALORES</span>
          <ngx-capture [target]="screen" (resultImage)="save()"></ngx-capture>
        </div>
        <div> 
          <app-clock></app-clock>
        </div>
      </div>
    </div>
  </div>


<div class="logoGySEnterprise" >
  <span class="title-logoEnterprise">G<span style="font-size: 40px;font-weight:100;color:#ffcc2c" >&</span>S</span>
  <span>gestión y sistemas</span>
</div>

 <div id="divCanvasScreenshot" *ngIf="showScreenshot">
  <div id="canvasScreenshot" >
    <img [src]="imagePath" width="600px" height="400px" >
    <h4>Captura de datos exitosa</h4>
  </div>
</div>