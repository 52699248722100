import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { GatewayService } from 'src/app/services/gateway/gateway.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { SensorReadingplaceService } from 'src/app/services/sensor-readingplace/sensor-readingplace.service';
import { SensorTypeService } from 'src/app/services/sensor-type/sensor-type.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-sensor-type-modal',
  templateUrl: './sensor-type-modal.component.html',
  styleUrls: ['./sensor-type-modal.component.css']
})
export class SensorTypeModalComponent implements OnInit {

  gatewayForm: FormGroup;
  headquarterSelect: number;
  headquarters = [];
  edit = false;

  constructor(
    public dialogRef: MatDialogRef<SensorTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private sensorTypeService: SensorTypeService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();

    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.edit = true;
      this.gatewayForm.controls['name'].setValue(this.data.obj.name);
    }
  }

  createForm(){
    this.gatewayForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(50)]]
    })
  }

  get f() { return this.gatewayForm.controls; }

  save(){
    if(this.gatewayForm.valid){
      const payload = {
        name:this.gatewayForm.value["name"],
        id: this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0,
        idUser: localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
      if(this.data.action == AppConstants.OperationType.ADD){
        this.sensorTypeService.create(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_SENSOR_TYPE, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
              this.spinner.hide();
          }
        });
      }else{
        this.sensorTypeService.update(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_SENSOR_TYPE, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
            this.spinner.hide();
          }
        });
      }
    }else{
      this.gatewayForm.markAllAsTouched();
    }
  }


  close() {
    this.dialog.closeAll();
  }
}
