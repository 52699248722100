<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{title}}
    </div>
    <div class="col-2 text-right">
        <mat-icon color="primary" [style.color]="data.color"  [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="streamingForm" autocomplete="off" [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' " >
            <div class="row">
              <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                  <mat-label>Título - Vista Individual*</mat-label>
                  <input matInput formControlName="title" >
                  <mat-error *ngIf="f.titleOne.hasError('required')">
                    El Título - Vista Individual es <strong>requerido</strong>.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Descripción - Vista Individual</mat-label>
                    <input matInput formControlName="description" >
                  </mat-form-field>
              </div>
                <div class="col-12 col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Título - Vista Múltiple*</mat-label>
                    <input matInput formControlName="titleMultiple" >
                    <mat-error *ngIf="f.title.hasError('required')">
                      El Título - Vista Múltiple - Vista Individual es <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                      <mat-label>Descripción - Vista Múltiple</mat-label>
                      <input matInput formControlName="descriptionMultiple" >
                    </mat-form-field>
                </div>

                <!--<div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>RTMP (Ejemplo: 192.12.12.1/app o streaming.com/app)</mat-label>
                        <input matInput formControlName="rtmp" >
                    </mat-form-field>
                </div>-->
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>API *(Ejemplo: http://192.12.12.1/api/lives o http://streaming.com/api/lives)</mat-label>
                        <input matInput formControlName="api" >
                        <mat-error *ngIf="f.api.hasError('required')">
                          El API es <strong>requerida</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--<div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>FILE (Ejemplo: http://192.12.12.1/example o http://streaming.com/example)</mat-label>
                        <input matInput formControlName="file" >
                    </mat-form-field>
                </div>-->
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Color 1 (fondo / texto listado de cámaras)*</mat-label>
                        <input type="color" matInput formControlName="color1" >
                        <mat-error *ngIf="f.color1.hasError('required')">
                          El color1 es <strong>requerida</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Color 2 (texto)*</mat-label>
                        <input type="color" matInput formControlName="color2" >
                        <mat-error *ngIf="f.color2.hasError('required')">
                          El color2 es <strong>requerida</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCELAR</button>
    <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite"  (click)="openConfirmationModal()">GUARDAR</button>
</mat-dialog-actions>
