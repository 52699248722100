import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CameraState, CameraPost, UserValidate, CameraByEnterprise, Camera, CameraViewModel, Zone, CameraById, CameraValidate, Color } from '../../models/alert/alert';
import { CameraZoneUpdate, CameraZoneViewModel } from 'src/app/models/camera/camera';
import { ReportViewModel } from 'src/app/models/reportAlert/report';
import { EnterpriseViewModel } from 'src/app/models/enterprise/enterprise';
import { GetZone } from 'src/app/models/zone/getZone';
import { GetCamera } from 'src/app/models/camera/getCamera';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Camera';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  private POST_ENDPOINTU: string = 'User';
  private BASE_URLU: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URLU: string = `${this.BASE_URLU}/${this.POST_ENDPOINTU}`;

  GetListCameraByEnterprise(EnterpriseId: number): Observable<CameraByEnterprise[]> {
    return this.httpClient.get<CameraByEnterprise[]>(`${this.REQUEST_URL}/GetListCameraByEnterprise/` + EnterpriseId);
  }

  GetAlertByCamera(CameraId: number, OptionId:number): Observable<Camera[]> {
    return this.httpClient.get<Camera[]>(`${this.REQUEST_URL}/GetAlertByCamera/` + CameraId + "/" + OptionId);
  }

  GetCameras(CameraId: number, OptionId:number): Observable<Camera[]> {
    return this.httpClient.get<Camera[]>(`${this.REQUEST_URL}/GetAlertByCamera/` + CameraId + "/" + OptionId);
  }

  GetCamerasIdEnterprise(idEnterprise: number, idHeadquarter:number): Observable<CameraZoneViewModel[]> {
    return this.httpClient.get<CameraZoneViewModel[]>(`${this.REQUEST_URL}/GetCamerasIdEnterprise/` + idEnterprise + "/" + idHeadquarter);
  }

  getListCameraState(): Observable<CameraState[]> {
    return this.httpClient.get<CameraState[]>(`${this.REQUEST_URL}/getListCameraState`);
  }

  postCameraAlert(camera: CameraPost): Observable<CameraPost>{
    return this.httpClient.post<CameraPost>(`${this.REQUEST_URL}/PostOcurrence`, camera);
  }

  postCameraAlertAttentioon(camera: CameraPost): Observable<CameraPost>{
    return this.httpClient.post<CameraPost>(`${this.REQUEST_URL}/PostCameraAlertAttention`, camera);
  }

  postUserValidate(userValidate: UserValidate): Observable<UserValidate> {
    return this.httpClient.post<UserValidate>(`${this.REQUEST_URLU}/ValidateUser`, userValidate);
  }

  postColor(ColorValidate: Color): Observable<Color> {
    return this.httpClient.put<Color>(`${this.REQUEST_URL}/PatchColor`, ColorValidate);
  }

  postCameraValidate(CameraValidate: CameraValidate): Observable<CameraValidate> {
    return this.httpClient.put<CameraValidate>(`${this.REQUEST_URL}/PatchCameraOcurrence`, CameraValidate);
  }

  getListCamera(filter: any): Observable<GetCamera> {
    let httpParams = new HttpParams({
      fromObject: filter,
    });
    return this.httpClient.get<GetCamera>(`${this.REQUEST_URL}/pag`, { params: httpParams });
  }

  getListCameraById(enterpriseId: number, userId:number,cameraId:number): Observable<CameraViewModel> {
    return this.httpClient.get<CameraViewModel>(`${this.REQUEST_URL}/GetListCameraByEnterpriseById/` + enterpriseId + "/" + userId + "/" + cameraId);
  }

  getListCameraFilter(enterpriseId: number, userId:number,cameraId:number): Observable<CameraViewModel[]> {
    return this.httpClient.get<CameraViewModel[]>(`${this.REQUEST_URL}/GetListCameraByEnterpriseByNoFilter/` + enterpriseId + "/" + userId + "/" + cameraId);
  }

  getZones(idEnterprise:number,idHeadquarter:number): Observable<GetZone> {
    return this.httpClient.get<GetZone>(`${this.REQUEST_URL}/GetZones/${idEnterprise}/${idHeadquarter}`);
  }

  deleteCamera(cameraId: number) {
    return this.httpClient.delete(`${this.REQUEST_URL}/DeleteCamera/${cameraId}`);
  }

  registerCamera(model: FormData): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/CreateCamera`, model);
  }

  updatedCamera(model: FormData): Observable<any>{
    return this.httpClient.post<any>(`${this.REQUEST_URL}/UpdateCamera`, model);
  }

  getCameraById(cameraId:number): Observable<CameraById> {
    return this.httpClient.get<CameraById>(`${this.REQUEST_URL}/GetListCameraById/` + cameraId);
  }

  getCameraByZone(zoneId: number): Observable<CameraZoneViewModel[]> {
    return this.httpClient.get<CameraZoneViewModel[]>(`${this.REQUEST_URL}/GetListCameraByZone/${zoneId}`);
  }

  getEnterprises(): Observable<EnterpriseViewModel[]> {
    return this.httpClient.get<EnterpriseViewModel[]>(`${this.REQUEST_URL}/GetEnterprises`);
  }

  getCameraByZoneCP(data: any): Observable<CameraZoneViewModel[]> {
    return this.httpClient.post<CameraZoneViewModel[]>(`${this.REQUEST_URL}/GetListCameraByZoneCP`,data);
  }

  updateCameraZone(camera: CameraZoneUpdate): Observable<number> {
    console.log(camera);
    return this.httpClient.put<number>(`${this.REQUEST_URL}/UpdateEnterpriseZone`, camera);
  }

  getImageUriHeathmap(cameraId: number, alertTypeId: number, dateFilter: string,idStorageFile: number){
    return this.httpClient.get<any>(`${this.BASE_URL}/heatmap/camera/${cameraId}/alert-type/${alertTypeId}/image-url/${dateFilter}/idStorageFile/${idStorageFile}`);
  }

  downloadHeatmap(cameraId: number, alertTypeId: number, dateFilter: string,idStorageFile: number) {
    // return this.httpClient.get(`${this.BASE_URL}/heatmap/camera/${cameraId}/alert-type/${alertTypeId}/blob-download`, { observe: 'response', responseType: 'blob' as 'json' });
    return this.httpClient.get(`${this.BASE_URL}/heatmap/camera/${cameraId}/alert-type/${alertTypeId}/image-download/${dateFilter}/idStorageFile/${idStorageFile}`, { responseType: 'blob' });
  }

  getHeathmapResumen(enterpriseFilter: any): Observable<ReportViewModel[]>{
    return this.httpClient.post<ReportViewModel[]>(`${this.BASE_URL}/heatmap/camera/alert-type/resumen`,enterpriseFilter);
  }
}
