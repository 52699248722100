import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ToastrService, IndividualConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastrService: ToastrService) { }

  private dialogs = new Subject<AlertDialog>();

  showSuccess() {
    this.toastrService.success('Hello world!', 'Toastr fun!');
  }

  public showMessage(title, message, severity, timeout = 7000) {

    const config: Partial<IndividualConfig> = {      
      titleClass: title,
      messageClass: message,
      closeButton: true,
      timeOut: timeout
    }; // no compiler error

    if (severity == MessageSeverity.success)
      this.toastrService.success(message, title, config);
    else if (severity == MessageSeverity.error)
      this.toastrService.error(message, title, config);
    else if (severity == MessageSeverity.warn)
      this.toastrService.warning(message, title, config);
  }


  showDialog(message: string)
  showDialog(message: string, type: DialogType, okCallback: (val?: any) => any)
  showDialog(message: string, type: DialogType, okCallback?: (val?: any) => any, cancelCallback?: () => any, okLabel?: string, cancelLabel?: string, defaultValue?: string)
  showDialog(message: string, type?: DialogType, okCallback?: (val?: any) => any, cancelCallback?: () => any, okLabel?: string, cancelLabel?: string, defaultValue?: string) {

    if (!type)
      type = DialogType.alert;

    this.dialogs.next({ message: message, type: type, okCallback: okCallback, cancelCallback: cancelCallback, okLabel: okLabel, cancelLabel: cancelLabel, defaultValue: defaultValue });
  }

  getDialogEvent(): Observable<AlertDialog> {
    return this.dialogs.asObservable();
  }


}


export enum MessageSeverity {
  default,
  info,
  success,
  error,
  warn,
  wait
}


//******************** Dialog ********************//
export class AlertDialog {
  constructor(public message: string, public type: DialogType, public okCallback: (val?: any) => any, public cancelCallback: () => any,
    public defaultValue: string, public okLabel: string, public cancelLabel: string) {

  }
}

export enum DialogType {
  alert,
  confirm,
  prompt
}
//******************** End ********************//
