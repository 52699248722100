import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-pin-modal',
  templateUrl: './pin-modal.component.html',
  styleUrls: ['./pin-modal.component.css']
})
export class PinModalComponent implements OnInit {

  pinForm: FormGroup;
  edit = false;
  image: File;
  headquarterSelect: number;
  headquarters = [];
  imagenPin = 'Seleccionar Imagen de Pin';

  constructor(
    public dialogRef: MatDialogRef<PinModalComponent>,
    private alertService: AlertService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private headquarterService: HeadquarterService,
    private formBuilder: FormBuilder,
    private zoneService: ZoneService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.headquarters = this.data.headquarters;
    this.setHeadquarter();
    if(this.data.action == AppConstants.OperationType.ADD){
      if(this.data.idHeadquarter == 0) this.edit = true;
      if(this.data.idHeadquarter){
        this.headquarterSelect = this.data.idHeadquarter;
      }
    }
    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.edit = true;
      this.pinForm.controls['name'].setValue(this.data.obj.name);
    }
  }

  createForm(){
    this.pinForm = this.formBuilder.group({
      idHeadquarter: ['', []],
      name: ['', [Validators.required]]
    })
  }

  setHeadquarter(){
    if(this.data.idHeadquarter>0){
      var headquarters = JSON.parse(localStorage.getItem(AppConstants.Session.HEADQUARTERS));
      var _this = this;
      var list = headquarters.filter(x => x.idEnterprise == _this.data.idEnterprise);
          if(list.length > 0){
            _this.headquarters = list;
            _this.headquarterSelect = list[0].id;
            return false;
          }
    }else{
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.data.idEnterprise);
          if(this.headquarters.length > 0){
            if(this.data.idHeadquarter == 0){
              this.headquarterSelect = this.headquarters[0].id;
            }else{
              this.headquarterSelect = this.data.idHeadquarter;
            }
          }
        }else{
          this.headquarters = [];
        }
        this.spinner.hide();
      });
    }
    
  }

  get f() { return this.pinForm.controls; }

  save(){
    if(this.pinForm.valid){
      if(this.image == null){
        this.alertService.showMessage(
          "Dede adjuntar la imagen del pin",
          "",
          MessageSeverity.error);
          return false;
      }

      var data = new FormData();
      data.append('id', (this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0));
      data.append('name', this.pinForm.value["name"]);
      data.append('file', this.image);
      data.append('idHeadquarter', this.headquarterSelect.toString());
      data.append('idEnterprise', this.data.idEnterprise);
      data.append('idUser', localStorage.getItem(AppConstants.Session.USERID));
      if(this.data.action == AppConstants.OperationType.ADD){
        this.spinner.show();
        this.zoneService.createPin(data).subscribe(_ => {
          console.log("message pin",_);
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_PIN, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
          this.spinner.hide();
        });
      }else{
       /* this.enterpriseService.updateEnterprisePin(data).subscribe(_ => {
          if(_ == null){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ZONE, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });*/
      }
    }else{
      this.pinForm.markAllAsTouched();
    }
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
  }

  onFileSelected(event) {
    var files = event.target.files;
    if (files.length > 0) {
      var file = files[0];
      if (file.type == "image/png" || file.type == "image/jpeg") {
        this.image = file;
        this.imagenPin = "Imagen de Pin Adjuntada";
      } else {
        this.image = null;
        this.imagenPin = 'Seleccionar Imagen de Pin';
        this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
      }
    }else{
      this.imagenPin = 'Seleccionar Imagen de Pin';
      this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
    }
  }

  convertName(name: string) {
    return name.split('.').slice(0, -1).join();
  }

}
