import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as signalR from "@microsoft/signalr";
import { GetEmailShowRoomModel } from 'src/app/models/showRoom/getEmailShowRoom';
import { GetReportAlertShowRoom } from 'src/app/models/showRoom/getReportAlertShowRoom';

@Injectable({
  providedIn: 'root'
})
export class ShowRoomService {
  pause = false;
  nivel = 0;
  presion = 0;
  temperatura = 0;
  imageDiagram = 1;
  resistencia:number;
  bomba = 0;
  valvulaSelenoide = 0;
  public data: any[];
  private hubConnection: signalR.HubConnection;

  constructor(
    private httpClient: HttpClient
  ) { }

  private REQUEST_URL_SHOWROOM: string = environment.showroomUrl;
  private REQUEST_URL: string = environment.sistemaWebAdmin+"/showroom";
  private REQUEST_URL_CAMERA: string = environment.camaraAlert;

  public startConnection = (url: string) => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addTransferChartDataListener = () => {

    this.hubConnection.on('transferchartdata', (data) => {

      this.data = data;
      console.log(data);

    });

    this.hubConnection.on('transferchartSignoutdata', (data) => {

      console.log(data);

    });
  }

  getListActuators(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/actuators`);
  }

  getListSensors(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/sensors`);
  }

  getSensorCameraCounting(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/camera/couting-people`);
  }

  getSensorValue(id): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/sensor/`+id);
  }

  getActuatorValue(id): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/actuator/`+id);
  }

  updateSensorValue(data): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL_SHOWROOM}/dt_command`,data);
  }

  updatePanelValue(data): Observable<any> {
    return this.httpClient.put<any>(`${this.REQUEST_URL}/panel`,data);
  }

  updateSensorlValue(data): Observable<any> {
    return this.httpClient.put<any>(`${this.REQUEST_URL}/sensor-humedad`,data);
  }

  getPanels(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/panels`);
  }

  getListLog(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL_SHOWROOM}/logs`);
  }

  updateActuators(data: any): Observable<any> {
   /* data.filter(_=>{
      _.value = _.dataValue;
    });*/
    return this.httpClient.patch<any>(`${this.REQUEST_URL_SHOWROOM}/actuators`,data);
  }

  getPause(){
    return this.pause;
  }

  setPause(val){
    this.pause = val;
  }

  startAlert(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL_CAMERA}/start_alerts`);
  }

  stopAlert(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL_CAMERA}/stop_alerts`);
  }

  getNivel(){
    return this.nivel + "%";
  }

  setNivel(value){
    this.nivel = value;
  }

  getTemperatura(){
    return this.temperatura;
  }

  setTemperatura(value){
    this.temperatura = value;
  }

  getPresion(){
    return parseFloat(this.presion.toString()).toFixed(2).toString();
  }

  setPresion(value){
    this.presion = value;
  }

  getResistencia(){
    return this.resistencia;
  }

  setResistencia(value){
    if(value){ this.updateImagePublic(4); }else{ this.updateImagePublic(3); }
    this.resistencia = value;
  }

  getImageDiagram(){
    return this.imageDiagram;
  }

  setImageDiagram(value){
    this.imageDiagram = value;
  }

  getBomba(){
    return this.bomba;
  }

  setBomba(value){
    if(value){
      this.updateImagePublic(2);
      var _this = this;
      setTimeout(()=>{
        if(_this.imageDiagram == 2){
          _this.updateImagePublic(3);
        }
      },10000);
    } else {
      this.updateImagePublic(1);
    }
    this.bomba = value;
  }

  getValvulaSelenoide(){
    return this.valvulaSelenoide;
  }

  setValvulaSelenoide(value){
    if(value){
      this.updateImagePublic(5);
      var _this = this;
      setTimeout(()=>{
        if(_this.imageDiagram == 5){
          _this.updateImagePublic(6);
        }
      },10000);
    } else {
      if(!value && this.getBomba()==1){
        this.updateImagePublic(2);
      }else if(!value && this.getBomba()==0){
        this.updateImagePublic(1);
      } else{
        this.updateImagePublic(6);
      }
    }
    this.valvulaSelenoide = value;
  }

  updateImagePublic(value){
    var data ={
      id: 0,
      value: value
    }
    this.updatePanelValue(data).subscribe(_ =>{
      console.log("update image");
    });
  }

  insertUpdateEmail(data): Observable<any> {
    return this.httpClient.put<any>(`${this.REQUEST_URL}/email`,data);
  }

  getEmails(): Observable<GetEmailShowRoomModel> {
    return this.httpClient.get<GetEmailShowRoomModel>(`${this.REQUEST_URL}/email`);
  }

  deleteEmail(id){
    return this.httpClient.delete<any>(`${this.REQUEST_URL}/email/` + id);
  }

  reportVideoanalitica(data): Observable<GetReportAlertShowRoom> {
    return this.httpClient.post<GetReportAlertShowRoom>(`${this.REQUEST_URL}/report/alert`,data);
  }
}
