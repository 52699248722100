import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Map, LngLatBounds, LngLat, Marker } from 'mapbox-gl';
import mapboxgl from 'mapbox-gl';
import { MapboxService } from 'mapbox-gl';
import * as MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import { AgmMap, MapsAPILoader } from '@agm/core';
declare var google: any;

@Component({
  selector: 'app-panel-gps',
  templateUrl: './panel-gps.component.html',
  styleUrls: ['./panel-gps.component.css']
})
export class PanelGPSComponent implements OnInit {
  @ViewChild('agmMap', { static: true }) agmMap: AgmMap;
  origin: number[] = [-77.04098497650233, -12.036230356017532]; // Coordenadas del origen (Longitud, Latitud)
  destination: number[] = [-77.92204403404787,-10.450804774012902]; // Coordenadas del destino (Longitud, Latitud) ,
  public markers: any[];
  apiKeyGM = "AIzaSyB70sStdEOl_sHU-GiUwtWg-WP99eLf7GE";
  //map: Map;
  map: Map;
  mapGM: any;
  latInicio = 37.7913;
  lngInicio = -122.3960;
  latDestino = 37.7749;
  lngDestino = -122.4194;
  agmMapOptions: any;
  zoom = 12;
  lat = 51.678418;
  lng = 7.809007;
  originGM = { lat: 19.4326, lng: -99.1332 };
  destinationGM = { lat: 20.6736, lng: -103.344 };
  centerLat = 51.678418;
  centerLng = 7.809007;
  originLat = 51.678418;
  originLng = 7.809007;
  destinationLat = 51.678418;
  destinationLng = 7.909007;
  waypoints = [];
  /*mapOptions: google.maps.MapOptions = {
    zoomControl: true,
    draggable: true
  };*/
  directionsService: any;
  directionsDisplay: any;
  devices = [{
    id: 1,name: "Zona 1", direction: { origin: [-77.04098497650233,-12.036230356017532], destination: [-77.92204403404787,-10.450804774012902]}
  },{
    id: 2,name: "Zona 2", direction: { origin: [-74.23392410751605, -15.86664539872316], destination: [-70.93588421856971, -17.196176750466424]}
  },{
    id: 3,name: "Zona 3", direction: { origin: [-78.58987956044771, -9.074790109996867], destination: [-77.03417288414991, -12.026692813166463]}
  },{
    id: 4,name: "Zona 4", direction: { origin: [-76.34158778467048, -6.478963173313768], destination: [-71.59596256358822, -16.42779012923858]}
  },{
    id: 5,name: "Zona 5", direction: { origin: [-70.25329741682341, -17.987965409885113], destination: [-80.68663397971476, -4.9136410156142025]}
  },{
    id: 6,name: "Zona 6", direction: { origin: [-78.79375332464798, -5.723345155627996], destination: [-75.21262622794198, -12.087424352346558]}
  }];
  selectedDirection: any;
  active = 0;
  directions: MapboxDirections;
  /*coordinates: number[][] = [
    [-99.1332, 19.4326], // Origen
    [-99.1689, 19.3969] // Destino
  ];*/
  constructor(private mapsAPILoader: MapsAPILoader){
    // this.active = this.devices[0].id;
    //this.configureMapsAPILoader();
  }
/*
  private configureMapsAPILoader(): void {
    const apiKey = this.apiKeyGM; // Reemplaza con tu API Key de Google Maps

    this.mapsAPILoader.load().then(() => {
      (window as any)['google']['maps'].load(() => {
        const maps = (window as any)['google']['maps'];
        const mapsConfig = {
          apiKey: apiKey,
          libraries: ['places']
        };
        maps.createMap(document.getElementById('map'), mapsConfig);
      });
    });
  }*/

  selectDirection(data: any) {
    this.active = data.id;
   // this.selectedDirection = data.direction;
    console.log("devices X => ",data.direction.origin + "---------" + data.direction.destination);
   // this.getDirections(data.direction.origin, data.direction.destination);

    // Actualiza las direcciones de origen y destino
    this.directions.setOrigin(data.direction.origin);
    this.directions.setDestination(data.direction.destination);

    // Obtén y muestra la nueva ruta en el mapa
   // this.directions.query();
  }

  ngOnInit() {

    /*this.mapsAPILoader.load().then(() => {
      const apiKey = 'AIzaSyB70sStdEOl_sHU-GiUwtWg-WP99eLf7GE';
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.initMap();
      };
      this.elementRef.nativeElement.appendChild(script);
    });*/
  }
/*

  calculateAndDisplayRoute() {
    const request = {
      origin: this.originGM,
      destination: this.destinationGM,
      travelMode: 'DRIVING'
    };

    this.directionsService.route(request, (result: any, status: any) => {
      console.log("result",result);
      console.log("status",status);
      if (status === 'OK') {
        this.directionsDisplay.setDirections(result);
      }
    });
  }


  initMap() {
    const mapOptions = {
      center: { lat: -34.397, lng: 150.644 }, // Coordenadas del centro del mapa
      zoom: 8 // Nivel de zoom del mapa
    };

    const map = new google.maps.Map(this.elementRef.nativeElement.querySelector('#map'), mapOptions);

    const origin = new google.maps.Marker({
      position: { lat: -34.397, lng: 150.644 }, // Coordenadas del origen
      map: map,
      title: 'Origen'
    });

    const destination = new google.maps.Marker({
      position: { lat: -33.867, lng: 151.206 }, // Coordenadas del destino
      map: map,
      title: 'Destino'
    });
  }
  ngOnInit(){
     // GOOGLE MAPS

     this.mapsAPILoader.load().then(() => {
      this.agmMapOptions = {
        // Configura aquí las opciones del mapa
        zoom: 7,
        center: {
          lat: 19.4326,
          lng: -99.1332
        },
        apiKey:  // Asigna la apiKey al atributo apiKey del componente agm-map
      };
    });

  }*/

  ngAfterViewInit() {

    // GOOGLE MAPS
    /*this.loadGoogleMaps().then(() => {
      this.setOriginAndDestination(); // Configura el origen y el destino
    });*/
    // MAP BOX
    mapboxgl.accessToken = 'pk.eyJ1Ijoic2ViYXN0aWFuOTZqcCIsImEiOiJjbGl4bjhpaGEwODB5M2xsMndvOWhxeXZqIn0.Yt4_ynMIZUiiJF4TS3exVA';

    this.map = new mapboxgl.Map({
      container: 'mapPanelGPS',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.origin,
      zoom: 7
    });

    this.directions = new MapboxDirections({
      accessToken: mapboxgl.accessToken,
      unit: 'metric',
      profile: 'mapbox/driving',
      interactive: false, // Deshabilitar la interacción con el control de direcciones
      controls: {
        inputs: false, // Deshabilitar los campos de entrada de ubicaciones
        instructions: false // Deshabilitar las instrucciones paso a paso
      }
    });

    this.map.addControl(this.directions, 'top-left');
/*
    map.on('idle', () => {
      const directions = new MapboxDirections({
        accessToken: mapboxgl.accessToken,
        unit: 'metric',
        profile: 'mapbox/driving',
        interactive: false, // Deshabilitar la interacción con el control de direcciones
        controls: {
          inputs: false, // Deshabilitar los campos de entrada de ubicaciones
          instructions: false // Deshabilitar las instrucciones paso a paso
        }
      });

      this.map.addControl(this.directions, 'top-left');
      map.addControl(directions);

      directions.setOrigin(this.origin);
      directions.setDestination(this.destination);

      map.on('click', (event) => {
        if (this.selectedDirection) {
          // Obtener las coordenadas del clic
          const lngLat = event.lngLat;

          // Obtener la dirección seleccionada
          const direction = this.selectedDirection;
          console.log("direction new",direction);
          // Actualizar las direcciones de inicio y fin
          directions.setOrigin(direction.origin);
          directions.setDestination(direction.destination);
          // Mostrar la ruta en el mapa
         // directions.query();
        }
      });
/*
      // Agregar el icono de auto en la ruta
      const carIcon = document.createElement('div');
      carIcon.className = 'car-icon'; // Estilos CSS para el icono de auto

      const carMarker = new mapboxgl.Marker(carIcon)
        .setLngLat(this.originGM) // Coordenadas del auto
        .addTo(map);

      // Actualizar la posición del auto mientras se mueve la ruta
      directions.on('route', (e) => {
        const route = e.route[0];
        const coordinates = route.geometry.coordinates;

        let currentIndex = 0;

        const animateCar = () => {
          if (currentIndex < coordinates.length) {
            const lngLat = coordinates[currentIndex];
            carMarker.setLngLat(lngLat);

            currentIndex++;

            requestAnimationFrame(animateCar);
          }
        };

        animateCar();
      });
    });*/

    /*
    this.map = new Map({
      accessToken: 'pk.eyJ1Ijoic2ViYXN0aWFuOTZqcCIsImEiOiJjbGl4bjhpaGEwODB5M2xsMndvOWhxeXZqIn0.Yt4_ynMIZUiiJF4TS3exVA',
      container: 'mapPanelGPS',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.origin,
      zoom: 7
    });

    this.map.on('idle', () => {
      this.map.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [this.origin, this.destination] // coordenadas de origen y destino
          }
        }
      });

      this.map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#888',
          'line-width': 8
        }
      });
    });
*/












    /*this.map = new Map({
      accessToken: 'pk.eyJ1Ijoic2ViYXN0aWFuOTZqcCIsImEiOiJjbGl4bjhpaGEwODB5M2xsMndvOWhxeXZqIn0.Yt4_ynMIZUiiJF4TS3exVA',
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 7,
      center: origin
    });

    const geoJSON = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [this.origin,this.destination]//this.coordinates
      }
    };

    this.map.on('load', () => {
      alert("dasdas");
      this.map.addSource('route', {
        type: 'geojson',
        data: geoJSON
      });

      this.map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#FF0000',
          'line-width': 2
        }
      });
    });*/
  }

  /*loadGoogleMaps(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Obtén el apiKey desde tu fuente de datos
      const apiKey = 'AIzaSyB70sStdEOl_sHU-GiUwtWg-WP99eLf7GE';

      // Carga la API de Google Maps con el apiKey
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }*/

 /* setOriginAndDestination() {
    // Configura aquí el origen y el destino según tus necesidades
    this.originGM = { lat: 19.4326, lng: -99.1332 }; // Origen de ejemplo
    this.destinationGM = { lat: 20.6736, lng: -103.344 }; // Destino de ejemplo
  }

  getDirections(origin: any, destination: any) {
  this.directionsService
    .getDirections([origin.lng, origin.lat], [destination.lng, destination.lat])
    .subscribe((directions: any) => {
      // Aquí puedes manejar la respuesta de las direcciones
      console.log(directions);
    });
  }*/
}
