import { Component, OnInit, ViewChild } from '@angular/core';
import { UserModalComponent } from 'src/app/shared/components/modals/user/user-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../services/user/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EmitterService } from '../../../../shared/services/emitter.service';
import { AlertService, MessageSeverity } from '../../../../shared/services/alert.service';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { UserAssingModalComponent } from 'src/app/shared/components/modals/user/user-assing-modal/user-assing-modal.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

    loading: boolean = false;
    dataSource = new MatTableDataSource();
    displayedColumns: string[] = ['index','username', 'fullName','role', 'sendEmail', 'enabled', 'action']; //'rol',
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    subscription: Subscription;

    pageEvent: PageEvent;
    pageIndex: number = 0;
    pageSize: number = AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION;
    length: number = 0;

    enterprises = [];
    enterpriseSelect: number = 0;

    page: number = 1;
    countMaxUserByEnterprise: number = 0;
    isAdminGys: boolean = false;
    headquarters = [];
    headquarterSelect = 0;
    color1: string;
    color2: string;
    colorFont: string;
    colorFont2: string;
    searchText:string="";
    timer:any;
    timeoutVal = 800;
    enabledRead: number;
    enabledWrite: number;

    constructor(
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private userService: UserService,
      private router: Router,
      private enterpriseService: EnterpriseService,
      private emitterService: EmitterService,
      private alertService: AlertService,
      private headquarterService: HeadquarterService
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'Administración > <span class="font-weight-bold">Usuarios</span>';
    }

  ngOnInit() {
      this.setLS();
      this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
      this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
      this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
      //this.getEnterprises();
      this.dataSource.paginator = this.paginator;
      this.getUsers();
      this.subscription = this.emitterService.getMessage().subscribe(message => {
        if (message == AppConstants.EmitterService.ListUsers) {
          this.getUsers();
        }
        if (message == AppConstants.EmitterService.ListUsersEdit) {
          this.getUsers();
        }
      });
    }

    setLS(){
      this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
      this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
      this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
      this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
      //validate Permisses menu
      var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
      var page = this.router.url.split("/");
      var i = page[page.length-1];
      var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
      if(itemTag.length>0){
        this.enabledRead = itemTag[0].activeRead;
        this.enabledWrite = itemTag[0].activeWrite;
      }else{
        this.enabledRead = 0;
        this.enabledWrite = 0;
      }
    }

    openAssign(element){
      let dialogRef = this.dialog.open(UserAssingModalComponent, {
          data: {
              title: AppConstants.TitleModal.TITLE_USER_ASSING_MODAL + " - " + element.fullName,
              obj: element,
              action: AppConstants.OperationType.UPDATE,
              color: this.color1,
              enabledWrite: this.enabledWrite,
              enabledRead: this.enabledRead,
              colorFont: this.color2
          },
          width: '800px',
          hasBackdrop: true
      });
      /*dialogRef.afterClosed().subscribe(_ => {
          this.page = 1;
          this.paginator.pageIndex = 0;
          this.getUsers();
      });*/
    }

    openAddModal() {
        let dialogRef = this.dialog.open(UserModalComponent, {
            data: {
                title: AppConstants.TitleModal.REGISTER_TITLE_USER_MODAL,
                action: AppConstants.OperationType.ADD,
                enterpriseId: this.enterpriseSelect,
                headquarters: this.headquarters,
                enabledWrite: this.enabledWrite,
                enabledRead: this.enabledRead,
                idHeadquarter: this.headquarterSelect,
                color: this.color1,
                colorFont: this.color2
            },
        });
        dialogRef.afterClosed().subscribe(_ => {
          if (_.result == true) {
            this.page = 1;
            this.paginator.pageIndex = 0;
            this.getUsers();
          }
        });
    }

    openEditModal(obj) {
        let dialogRef = this.dialog.open(UserModalComponent, {
            data: {
                title: AppConstants.TitleModal.EDIT_TITLE_USER_MODAL,
                action: AppConstants.OperationType.UPDATE,
                row : obj,
                headquarters: this.headquarters,
                enabledWrite: this.enabledWrite,
                enabledRead: this.enabledRead,
                idHeadquarter: this.headquarterSelect,
                color: this.color1,
                colorFont: this.color2
            },
        });
        dialogRef.afterClosed().subscribe(_ => {
          if (_.result == true) {
            this.getUsers();
          }
        });
    }

    getHeadquarters() {
      if (this.isAdminGys) {
        this.spinner.show();
        this.headquarterService.getListAll().subscribe((response) => {
          if(response.error == null){
            this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
            this.headquarterSelect = 0;
          }else{
            this.headquarters = [];
          }
          this.getUsers();
          this.spinner.hide();
        },
          (error: HttpErrorResponse) => {
            this.headquarters = [];
            this.spinner.hide();
          });
      } else {
        this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
      }
    }

    delete(obj){
        let dialogRef = this.dialog.open(MessageModalComponent, {
            data: {
                title: AppConstants.TitleModal.DELETE_TITLE_USER_CONFIRM,
                message: `${AppConstants.MessageModal.DELETE_USER_CONFIRMATION_MESSAGE1} ${obj.fullName} ? ${AppConstants.MessageModal.DELETE_USER_CONFIRMATION_MESSAGE2}`,
                hasButton: true,
                contentButton: {
                    yes: 'SI',
                    no: 'CANCELAR'
                }
            },
        })

        dialogRef.afterClosed().subscribe(response => {
            if (response.result == true) {
              this.page = 1;
              this.paginator.pageIndex = 0;
              this.deleteUser(obj.userId);
            }
        });
    }

    getUsers() {
      this.spinner.show();
      var filter = {
        Page: this.page,
        Rows: AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION,
        IdHeadquarter: this.headquarterSelect,
        IdEnterprise: this.enterpriseSelect,
        Filter: this.searchText
      };

      this.userService.getUsers(filter).subscribe((response) => {
        this.dataSource = new MatTableDataSource(response["rows"]);
        this.pageIndex = +response["page"]-1;
        //this.pageSize = response["records"];
        this.length = response["total"];
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    }

    getServerData(event?: PageEvent) {
      this.page = event.pageIndex + 1;
      this.getUsers();
    }

  deleteUser(enterpriseId: number) {
    this.spinner.show();
    this.userService.deleteUser(enterpriseId).subscribe((response => {
      this.spinner.hide();
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_USER, AppConstants.MessageModal.DELETE, MessageSeverity.success);
      this.getUsers();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_USER, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  getEnterpriseById(enterpriseId: number) {
    this.spinner.show();
    this.enterpriseService.getEnterpriseById(enterpriseId).subscribe((response => {
      this.countMaxUserByEnterprise = response.maximumUsers;
      this.spinner.hide();
    }),
    (error: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  applyUpFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchText = filterValue.trim().toLowerCase();
      var _this = this;
      window.clearTimeout(_this.timer);
        _this.timer = window.setTimeout(() => {
            _this.page = 1;
            _this.paginator.pageIndex = 0;
            _this.getUsers();
          }, _this.timeoutVal);
  }

  applyPressFilter(event: Event) {
    var _this = this;
    window.clearTimeout(_this.timer);
  }
}
