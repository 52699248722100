import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCameraIframeStreaming } from 'src/app/models/streaming/getCameraIframeStreaming';
import { GetCameraStreaming } from 'src/app/models/streaming/getCameraStreaming';
import { GetCameraTokenStreaming } from 'src/app/models/streaming/getCameraTokenStreaming';
import { GetCameraTopStreaming } from 'src/app/models/streaming/getCameraTopStreaming';
import { ValidateDomainStreaming } from 'src/app/models/streaming/validateDomainStreaming';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  constructor(private httpClient: HttpClient) { }

  private POST_ENDPOINT: string = 'Streaming';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;


  getCameraByZone(zoneId: number): Observable<GetCameraStreaming> {
    return this.httpClient.get<GetCameraStreaming>(`${this.REQUEST_URL}/cameras-by-zone/${zoneId}`);
  }

  getCameraByEnterprise(enterpriseId: number,headquarterId: number): Observable<GetCameraStreaming> {
    return this.httpClient.get<GetCameraStreaming>(`${this.REQUEST_URL}/cameras-by-enterprise/${enterpriseId}/${headquarterId}`);
  }

  getCameraToken(token: string): Observable<GetCameraTokenStreaming> {
    return this.httpClient.get<GetCameraTokenStreaming>(`${this.REQUEST_URL}/camera-by-token/${token}`);
  }

  getCameraZoneToken(token: string): Observable<GetCameraTopStreaming> {
    return this.httpClient.get<GetCameraTopStreaming>(`${this.REQUEST_URL}/cameras-by-zone/token/${token}`);
  }

  getCameraEnterpriseToken(token: string): Observable<GetCameraTopStreaming> {
    return this.httpClient.get<GetCameraTopStreaming>(`${this.REQUEST_URL}/cameras-by-enterprise/token/${token}`);
  }

  getCameraIframeToken(token: string): Observable<GetCameraTokenStreaming> {
    return this.httpClient.get<GetCameraTokenStreaming>(`${this.REQUEST_URL}/camera-iframe-by-token/${token}`);
  }

  getIframeCamera(token: string): Observable<GetCameraIframeStreaming> {
    return this.httpClient.get<GetCameraIframeStreaming>(`${this.REQUEST_URL}/iframe-camera/${token}`);
  }
}
