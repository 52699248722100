<section class="panel-showroom">
    <!--<div class="menu">
        <div class="items-menu">
            <div class="item-menu"  [ngClass]="itemMenu == 1 ? 'activeItem' : 'inactiveItem'" (click)="showMenuItem(1)" >IOT</div>
            <div class="item-menu"  [ngClass]="itemMenu == 2 ? 'activeItem' : 'inactiveItem'" (click)="showMenuItem(2)">STREAMING</div>
            <div class="item-menu"  [ngClass]="itemMenu == 3 ? 'activeItem' : 'inactiveItem'" (click)="showMenuItem(3)">VA</div>
        </div>
    </div>
    <div *ngIf="itemMenu == 1" >
        <app-showroom-iot></app-showroom-iot>
    </div>
    <div *ngIf="itemMenu == 2">
        <app-showroom-streaming></app-showroom-streaming>
    </div>
    <div *ngIf="itemMenu == 3">
        <app-showroom-va></app-showroom-va>  
    </div>-->
    <div class="panelPublic" *ngIf="!showLab">
        <div class="title" >PLATAFORMA VIDEOANALÍTICA</div>
        <div class="panelColumns" style="flex:40%;" >
            <div style="flex:50%;text-align: -webkit-center;">
              <app-showroom-va-streaming></app-showroom-va-streaming>
            </div>
            <div style="flex:50%;">
              <app-showroom-va></app-showroom-va>
                <!--<img src="../../../../../assets/images/image-iframe.jpeg" width="100%" height="400px">-->
            </div>
            <!--<iframe width="100%" height="400px" src="../../../../../../assets/digital-twins/index.html"></iframe>-->
        </div>
        <!--<div class="panelControl" (click)="nextPanel()" >
            <img [src]="imagePanel" width="680px" >
            <div id="actuator1" class="actuator" ></div>
        </div>-->
    </div>
    <div *ngIf="showLab">
        <app-showroom-panel-diagram-public></app-showroom-panel-diagram-public>
    </div>


  
    <div class="icon-menu" *ngIf="!showMenu" (click)="selectMenu()" >
     
          <mat-icon>keyboard_arrow_left</mat-icon>
        
      </div>
    
      <div class="menu-options" *ngIf="showMenu" >
        <div class="panel-buttons" *ngIf="staticActuators.length > 0">
         <button mat-raised-button (click)="toggleFullScreen()" [ngClass]="{'background-primary': isFullScreen, 'button-inactive' : !isFullScreen }" class="mt-2 w-100 buttonActuator">PANTALLA COMPLETA</button>
         <button mat-raised-button (click)="goControl()" [ngClass]="{'background-primary': isFullScreen, 'button-inactive' : !isFullScreen }" class="mt-2 w-100 buttonActuator">PANTALLA CONTROL</button>
         <button mat-raised-button (click)="showPBI()" class="mt-2 w-100 buttonActuator">VER REPORTE SENSOR</button>
        </div>
        <div class="icon-menu2" (click)="selectMenu()" >
          <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
      </div>
    

<div class="logoGySEnterprise" >
    <span class="title-logoEnterprise">G<span style="font-size: 40px;font-weight:100;color:#ffcc2c" >&</span>S</span>
    <span style="color:rgb(31, 81, 101)">gestión y sistemas</span>
  </div>
</section>