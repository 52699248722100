import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'message-modal',
    templateUrl: 'message-modal.component.html',
    styleUrls: ['./message-modal.component.css']
})

export class MessageModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<MessageModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() { }

    close(){
        this.data.result = false
        this.dialogRef.close(this.data)
    }

    onClickAccept(){
        this.data.result = true
        this.dialogRef.close(this.data)
    }
}