import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-widget-modal',
  templateUrl: './widget-modal.component.html',
  styleUrls: ['./widget-modal.component.css']
})
export class WidgetModalComponent implements OnInit {
  dashboardForm: FormGroup;
  headquarterSelect: number;
  headquarters = [];
  edit = false;
  disabledHeadquarter = false;
  contentWidget: any;
  selectWidgetType: number;
 // itemsGraph: Array<ItemGraphWidgetModel> = new Array<ItemGraphWidgetModel>();
  widgetTypes = [
    {
      id: 1,
      name: 'Gráfico lineal',
      icon: 'linear_scale'
    },
    {
      id: 2,
      name: 'Gráfico de barra',
      icon: 'insert_chart_outlined'
    },
    {
      id: 3,
      name: 'Gráfico gauge',
      icon: 'wifi_tethering'
    },
  ];

  hours=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
  sensorsData = [];
  selectTypeMinY1 = 1;
  selectTypeMaxY1 = 1;
  sensors = [];

  constructor(
    public dialogRef: MatDialogRef<WidgetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private panelIOTService: PanelIOTService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.createForm();
    this.getSensors();
    if(this.data.action == AppConstants.OperationType.UPDATE){
      console.log("data",this.data.obj);
      this.selectWidgetType = this.data.obj.idWidgetType;
      this.dashboardForm.controls['name'].setValue(this.data.obj.name);

      var dataHead = JSON.parse(this.data.obj.contentHead);
      this.dashboardForm.controls['hourX'].setValue(dataHead.hourX);
      this.dashboardForm.controls['nameY1'].setValue(dataHead.nameY1);
      this.dashboardForm.controls['minY1'].setValue(dataHead.minY1);
      this.dashboardForm.controls['maxY1'].setValue(dataHead.maxY1);
      this.dashboardForm.controls['undY1'].setValue(dataHead.undY1);

      if(this.data.obj.idWidgetType == 3){ //gauge
        this.dashboardForm.controls['sensor'].setValue(dataHead.sensor);
        this.dashboardForm.controls['valueInterval'].setValue(dataHead.valueInterval);
      }

      var dataD = JSON.parse(this.data.obj.contentData);
      this.sensorsData = dataD;
    }else{
        this.sensorsData.push({id:0,pos:0,color: "black",sensor: 0,name:"",valueStart:0,valueEnd:0})
    }
  }

  createForm(){
    this.dashboardForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(30),  Validators.minLength(2)]],
      hourX: [''],
      nameY1: [''],
      minY1: [''],
      maxY1: [''],
      undY1:[''],
      sensor:[''],
      valueInterval:[]
      /*nameY2: [],
      minY2: [],
      maxY2: [],
      undY2:[],*/
    });
  }

  get f() { return this.dashboardForm.controls; }

  save(){
    if(this.dashboardForm.valid){
      const content = {
        hourX: this.dashboardForm.value["hourX"],
        nameY1: this.dashboardForm.value["nameY1"],
        minY1: this.dashboardForm.value["minY1"],
        maxY1: this.dashboardForm.value["maxY1"],
        undY1: this.dashboardForm.value["undY1"],
        valueInterval: this.dashboardForm.value["valueInterval"],
        sensor: this.dashboardForm.value["sensor"],
      }
      const payload = {
        name: this.dashboardForm.value["name"],
        id: this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0,
        idDashboard: this.data.idDashboard,
        idEnterprise: this.data.idEnterprise,
        idSystem: 2, //IOT
        idWidgetType: this.selectWidgetType,
        idUser: localStorage.getItem(AppConstants.Session.USERID),
        contentHead: JSON.stringify(content),
        contentBody: JSON.stringify(this.sensorsData)
      }
      this.spinner.show();
      if(this.data.action == AppConstants.OperationType.ADD){
        this.dashboardService.createWidget(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_WIDGET, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
              this.spinner.hide();
          }
        });
      }else{
        this.dashboardService.updateWidget(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_WIDGET, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
            this.spinner.hide();
          }
        });
      }
    }else{
      this.dashboardForm.markAllAsTouched();
    }
  }

  onlyNumberKey(evt) {

    // Only ASCII character in that range allowed
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
  }

  getSensors(){
    this.spinner.show();
    var filter = {
      idEnterprise: +localStorage.getItem(AppConstants.Session.ENTERPRISE),
      idHeadquarter: +localStorage.getItem(AppConstants.Session.HEADQUARTER),
      idZone: 0,
      idGateway: 0
    }
    this.panelIOTService.getList(filter).subscribe((_) => {
      if(_.error == null){
        this.sensors = _.data;
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  close() {
    this.dialog.closeAll();
  }

  changeWidgetType(id){
    this.selectWidgetType = id;
    this.dashboardForm.controls['name'].setValue(this.widgetTypes[this.selectWidgetType-1].name);
  }

  addItem(item){
    this.sensorsData.push({id:0,pos:(item.pos+1),color: "black",sensor: 0,name:"",valueStart:0,valueEnd:0})
  }

  removeItem(item){
    this.sensorsData = this.sensorsData.filter(x => x.pos != item.pos);
    var pos = 0;
    this.sensorsData.forEach(x => {
      x.pos = pos;
      pos++;
    });
  }

  selectSensor(idSensor,pos){
    this.sensorsData.forEach(x => {
      if(x.pos == pos){
        x.sensor = idSensor;
      }
    });
  }

  onSearchChangeInput(searchValue,pos){
    this.sensorsData.forEach(x => {
      if(x.pos == pos){
        x.name = searchValue;
      }
    });
  }

  onSearchChangeInputValueStart(searchValue,pos){
    this.sensorsData.forEach(x => {
      if(x.pos == pos){
        x.valueStart = searchValue;
      }
    });
  }

  onSearchChangeInputValueEnd(searchValue,pos){
    this.sensorsData.forEach(x => {
      if(x.pos == pos){
        x.valueEnd = searchValue;
      }
    });
  }

  changeColor(color,pos){
    this.sensorsData.forEach(x => {
      if(x.pos == pos){
        x.color = color;
      }
    });
    console.log("data color",this.sensorsData);
  }

}
