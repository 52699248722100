import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { MessageModalComponent } from '../../message/message-modal.component';

@Component({
  selector: 'app-enterprise-streaming-modal',
  templateUrl: './enterprise-streaming-modal.component.html',
  styleUrls: ['./enterprise-streaming-modal.component.css']
})
export class EnterpriseStreamingModalComponent implements OnInit {
  streamingForm: FormGroup
  title: string;
  loading: boolean = false;
  isAdminGys: boolean = false;
  sessionUserId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private enterpriseService: EnterpriseService,
    private alertService: AlertService,
    private emitterService: EmitterService) { }

  ngOnInit() {
    this.title = this.data.title;
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.sessionUserId = localStorage.getItem(AppConstants.Session.USERID);
    this.createForm();
    this.getData();
  }

  getData() {
    this.spinner.show();
    this.enterpriseService.getEnterpriseStreaming(this.data.row.id).subscribe((response => {
      if(response.error == null && response.enterpriseStreaming != null){
        //this.streamingForm.get('rtmp').setValue(response.enterpriseStreaming.rtmp);
        this.streamingForm.get('api').setValue(response.enterpriseStreaming.api);
        this.streamingForm.get('title').setValue(response.enterpriseStreaming.title);
        this.streamingForm.get('description').setValue(response.enterpriseStreaming.description);
        this.streamingForm.get('titleMultiple').setValue(response.enterpriseStreaming.titleMultiple);
        this.streamingForm.get('descriptionMultiple').setValue(response.enterpriseStreaming.descriptionMultiple);
        //this.streamingForm.get('file').setValue(response.enterpriseStreaming.file);
        this.streamingForm.get('color1').setValue(response.enterpriseStreaming.color1);
        this.streamingForm.get('color2').setValue(response.enterpriseStreaming.color2);
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  createForm() {
    this.streamingForm = this.formBuilder.group({
        idEnterprise: this.data.row.id,
        //rtmp: ['', []],
        api: ['', [Validators.required]],
        //file: ['', []],
        title: ['', [Validators.required]],
        titleMultiple: ['', [Validators.required]],
        description: ['', [Validators.required]],
        descriptionMultiple: ['', [Validators.required]],
        color1: ['', [Validators.required]],
        color2: ['', [Validators.required]],
        userId: parseInt(this.sessionUserId)
    });
  }

  get f() { return this.streamingForm.controls; }

  openConfirmationModal() {
    if (this.streamingForm.valid) {
      let dialogRef = this.dialog.open(MessageModalComponent, {
          data: {
              title: AppConstants.TitleModal.ENTERPRISE_STREAMING_UPDATE,
              message: AppConstants.MessageModal.UPDATE_MESSAGE_ENTERPRISE_STREAMING_CONFIRM,
              hasButton: true,
              contentButton: {
                  yes: 'SI',
                  no: 'CANCELAR'
              }
          },
      });

      dialogRef.afterClosed().subscribe(response => {
          if (response.result == true) {
            this.update();
          }
      });

    } else {
      this.streamingForm.markAllAsTouched();
    }
  }

  update() {
    if(this.streamingForm.valid){
      this.spinner.show();
      this.enterpriseService.updateEnterpriseStreaming(this.streamingForm.value).subscribe((response => {
        this.alertService.showMessage("", AppConstants.MessageModal.UPDATE_ENTERPRISE_STREAMING, MessageSeverity.success);
        this.spinner.hide();
        this.close();
      }),
        (error: HttpErrorResponse) => {
          this.alertService.showMessage("", error.error.message, MessageSeverity.error);
          this.spinner.hide();
        });
    }else{
      this.streamingForm.markAllAsTouched();
      this.alertService.showMessage("", AppConstants.MessageModal, MessageSeverity.error);
    }
  }

  close() {
    this.dialog.closeAll();
  }

}
