import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EmitterService } from '../../../../shared/services/emitter.service';
import { ReportService } from '../../../../services/reportAlert/report.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConstants } from '../../../../shared/constants/app.contants';
import { CameraService } from '../../../../services/camera/camera.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MessageSeverity, AlertService } from 'src/app/shared/services/alert.service';
import * as FileSaver from 'file-saver';
import { PanelCameraModalComponent } from 'src/app/shared/components/modals/panel/camera/panel-camera-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/helpers/format-datepicker';
import { LOCALE_ID } from '@angular/core';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
  ]
})
export class ReportComponent implements OnInit {

  loading: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['index','camera', 'typeAlert',  'insertDateOcurrence', 'enabled', 'userName', 'updateCamera', 'insertDate',  'action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('paginatorTop', { static: false }) paginatorTop: MatPaginator;
  @ViewChild('paginatorBottom', { static: false }) paginatorBottom: MatPaginator;
  subscription: Subscription;
  alertError = 0;

  cameras = [];
  users = [];
  cameraAlert = [];
  stats = [
    {
      id: 1, name: 'Pendiente'
    },
    {
      id: 2, name: 'En Proceso'
    },
    {
      id: 3, name: 'Atendido'
    }
  ];
  enterprises = [];
  enterpriseSelect: number;
  alertSelect: number = 0;
  cameraSelect: number = 0;
  userSelect: number = 0;
  statuselect: number = 0;

  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 0;
  length: number = 0;

  reportForm: FormGroup;

  myDate = new Date();
  zoneId: number = 0;
  date = new Date();
  dateF = new Date();
  timeI: string;
  timeF: string;
  dateV:string;
  isAdministrator=false;
  dateIView:string;
  dateFView:string;
  headquarterSelect = 0;
  enterprisesZones = [];
  enterpriseZoneSelect: number = 0;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  enabledRead: number;
  enabledWrite: number;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private spinner: NgxSpinnerService,
    private ReportService: ReportService,
    private userService: UserService,
    private enterpriseService: EnterpriseService,
    private CameraService: CameraService,
    private datePipe: DatePipe,
    private AlertService: AlertService,
    private zoneService: ZoneService,
    private formBuilder: FormBuilder,) {
      document.getElementById("titleHeaderNav").innerHTML = 'Video Analítica > <span class="font-weight-bold">Reportes</span>';
    }

  ngOnInit() {
    this.setLS();
    this.createForm();
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.getZonesByEnterprise();
    this.userList();
    this.dataSource.paginator = this.paginator;
    this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
    this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
    this.dateV = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  getZonesByEnterprise() {
    this.enterprisesZones = [];
    this.spinner.show();
    this.zoneService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe(response => {
      if(response.error == null){
        var zones = response.data.filter(_ => _.type == 1);
        this.enterprisesZones = zones;
        if(this.enterprisesZones.length>0){
          this.enterpriseZoneSelect = this.enterprisesZones[0]["id"];
        }else{
          this.AlertService.showMessage("No tiene zonas VA registradas, debes de crear una zona para usar este módulo", "", MessageSeverity.error);
        }
        this.changeZone(0);
      }else{
        this.enterprisesZones = [];
        this.cameras = [];
      }
      this.cameraList();
      this.spinner.hide();
    });
  }

  changeZone(e: any) {
    if(e != 0){
      this.enterpriseZoneSelect = e.id;
    }
    this.cameraList();
  }


   ngAfterContentChecked(): void {
        if (this.paginatorTop) {
          this.paginatorBottom.length = this.paginatorTop.length;
        }
    }

    handlePaginatorTop(e): void {
        const { pageSize, pageIndex } = e;
        this.paginatorTop.pageSize = pageSize
        this.paginatorTop.pageIndex = pageIndex;
        this.paginatorTop.page.emit(e);
    }

    handlePaginatorBottom(e): void {
        const { pageSize, pageIndex } = e;
        this.paginatorBottom.pageSize = pageSize
        this.paginatorBottom.length = this.paginatorTop.length;
        this.paginatorBottom.pageIndex = pageIndex;
      }

  checkError(){
    if(!this.alertError){
      this.alertError = 1;
    }else{
      this.alertError = 0;
    }
  }

  createForm() {
    this.reportForm = this.formBuilder.group({
        zoneId: ['', [Validators.required]],
        enterpriseId: ['', [Validators.required]],
        startDateReport: ['', [Validators.required]],
        endDateReport: ['', [Validators.required]],
        startTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
        endTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
        statusReport: ['', [Validators.required]],
        cameraReport: ['', [Validators.required]],
        alertReport: ['', [Validators.required]],
        userReport: ['', [Validators.required]],
    });
  }

  _keyUp(event: any) {
    let charCode = (event.which) ? event.which : event.keyCode;
      console.log(event.keyCode)
      if (charCode != 46 && charCode != 58 && charCode > 31
        && (charCode < 48 || charCode > 57)) {
        return false;
      }
  }

  get f() { return this.reportForm.controls; }

  cameraList() {
    this.cameras = [];
    var data = {
      idZone: this.enterpriseZoneSelect,
      idEnterprise: this.enterpriseSelect,
      idHeadquarter: this.headquarterSelect
    }
    console.log("filter",data);
    this.CameraService.getCameraByZoneCP(data).subscribe((response => {
      this.cameras = response;
      if(this.cameras.length>1){
        this.cameraSelect = 0;
      }else if(this.cameras.length!=0){
        this.cameraSelect = this.cameras[0].cameraId;
      }
      this.changeCamera(1);
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  userList() {
    this.users = [];
    var filter = {
      idEnterprise: this.enterpriseSelect,
      idHeadquarter: this.headquarterSelect
    };
    this.userService.getUserEnterpriseHeadquarters(filter).subscribe((response) => {
      if(response.error == null){
        this.users = this.users.concat(response.data);
      }
      this.userSelect = 0;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  changeCameras() {
    this.changeCamera(0);
  }

  changeCamera(id: number) {
    this.spinner.show();
    this.cameraAlert = [];
    this.alertSelect = 0;
    this.CameraService.GetAlertByCamera(this.cameraSelect,0).subscribe((response) => {
      this.cameraAlert = response;
      this.alertSelect = 0;
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.cameraAlert = []
      });
  }

  searchs() { 
    this.getListReportAlert(1, 'ALL'); 
  }

  exp() {
    // this.loading = true;//ruta no existe, activar cuando el servicio funcione
    this.ReportService.downloadFile(
      this.enterpriseSelect,
      this.datePipe.transform(this.date, 'dd-MM-yyyy'),
      this.datePipe.transform(this.dateF, 'dd-MM-yyyy'),
      this.timeI,
      this.timeF,
      this.statuselect,
      this.cameraSelect,
      this.alertSelect,
      this.userSelect,
      this.alertError
    ).subscribe(
      (response) => {
        const data: Blob = new Blob([response], {
          type: response.type
        });
        FileSaver.saveAs(data, 'archivo');
        this.loading=false;
      },
      (error) => {
        this.loading = false;
        this.AlertService.showMessage(AppConstants.TitleModal.DOWNLOAD_FILES, AppConstants.MessageModal.DOWNLOAD_FILE_MESSAGE, MessageSeverity.error);
      },()=>{this.loading=false;}
    );
  }

  setData(){
    let search = this.reportForm.value;
    try{
      this.dateIView = search.startDateReport;//this.datePipe.transform(search.startDateReport, 'dd/MM/yyyy');
      this.dateFView = search.endDateReport; //this.datePipe.transform(search.endDateReport, 'dd/MM/yyyy');
      // alert("try catch 2 - " + this.dateIView);
     // search.startDateReport = this.datePipe.transform(search.startDateReport, 'dd/MM/yyyy');
     // search.endDateReport = this.datePipe.transform(search.endDateReport, 'dd/MM/yyyy');
    }catch{
      this.dateIView = search.startDateReport;
      this.dateFView = search.endDateReport;

      search.startDateReport = search.startDateReport;
      search.endDateReport = search.endDateReport;
    }

    this.searchs();
  }

  setDatas(){
    let search = this.reportForm.value;

    this.dateIView = this.datePipe.transform(search.startDateReport, 'dd/MM/yyyy');
    this.dateFView = this.datePipe.transform(search.endDateReport, 'dd/MM/yyyy');

   // search.startDateReport = this.datePipe.transform(search.startDateReport, 'dd/MM/yyyy');
   // search.endDateReport = this.datePipe.transform(search.endDateReport, 'dd/MM/yyyy');

  }

  getListReportAlert(id: number, value: string) {
    this.spinner.show();
    if (value == '%') {
      this.loading = true;
      var filter = {
        enterpriseId: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        dateI: this.dateV,
        dateF: this.dateV,
        timeI: this.timeI,
        timeF: this.timeF,
        statusId: this.statuselect,
        cameraId: this.cameraSelect,
        alertId: this.alertSelect,
        userId: this.userSelect,
        error: this.alertError
      };
      filter.dateI = this.datePipe.transform(filter.dateI, 'dd/MM/yyyy');
      filter.dateF = this.datePipe.transform(filter.dateF, 'dd/MM/yyyy');
      this.ReportService.getReport(filter).subscribe((response) => {
        if(response.length > 0){
          this.dataSource.data = response;
        }else{
          this.dataSource.data = response;
          this.AlertService.showMessage(
            AppConstants.TitleModal.WARNING_TITLE,
            AppConstants.MessageModal.DATA_NULL_SEARCH,
            MessageSeverity.error);
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    } else {
      this.loading = true;
      var filterS = {
        enterpriseId: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        dateI: this.dateIView,
        dateF: this.dateFView,
        timeI: this.timeI,
        timeF: this.timeF,
        statusId: this.statuselect,
        cameraId: this.cameraSelect,
        alertId: this.alertSelect,
        userId: this.userSelect,
        error: this.alertError
      };
      filterS.dateI = this.datePipe.transform(filterS.dateI, 'dd/MM/yyyy');
      filterS.dateF = this.datePipe.transform(filterS.dateF, 'dd/MM/yyyy');
      this.ReportService.getReport(filterS).subscribe((response) => {
        if(response.length > 0){
          this.dataSource.data = response;
        }else{
          this.dataSource.data = response;
          this.AlertService.showMessage(
            AppConstants.TitleModal.WARNING_TITLE,
            AppConstants.MessageModal.DATA_NULL_SEARCH,
            MessageSeverity.error);
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    }
  }

  alerta(element) {
    let dialogRef = this.dialog.open(PanelCameraModalComponent, {
      data: { ocurrenceId: element.id_Ocurrence, cameraId: element.id_Camera, enterpiseId: this.enterpriseSelect },
      width: '800px', hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(() => {
      this.setData();
    });
  }

}
