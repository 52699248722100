import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { User, UserPut } from '../../../../models/user/user';
import { UserService } from '../../../../services/user/user.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { EmitterService } from '../../../services/emitter.service';
import { MessageModalComponent } from '../message/message-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminService } from '../../../../services/admin/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoleService } from 'src/app/services/role/role.service';

@Component({
    selector: 'user-modal',
    templateUrl: 'user-modal.component.html'
})

export class UserModalComponent implements OnInit {
    userForm: FormGroup
    title: string;
    hide = true;
    repeatHide = true;
    loading: boolean = false;
    roles = [];
    showEdit: boolean = false;
    isAdminGys: boolean = false;
    sessionUserId: string;
    roleSelect = 0;
    headquarterSelect = 0;
    headquarters = [];

    constructor(
        public dialogRef: MatDialogRef<UserModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        public dialog: MatDialog,
        private userService: UserService,
        private alertService: AlertService,
        private emitterService: EmitterService,
        private adminService: AdminService,
        private roleService: RoleService
    ) { }

    ngOnInit() {
      this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
      this.sessionUserId = localStorage.getItem(AppConstants.Session.USERID);
      this.headquarters = this.data.headquarters;
      this.createForm()
      this.setValues();
      this.getRoles();
    }

    createForm() {
        this.showEdit = this.data.action == AppConstants.OperationType.UPDATE ? true : false;
        this.userForm = this.formBuilder.group({
            // documentNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
            email: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
            names: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
            lastName: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
            role: ['', [Validators.required]],
            password: ['', [Validators.minLength(6)]],
            confirmPassword: ['', [Validators.minLength(6)]],
            status: [true, [Validators.required]],
            sendEmail: [true, [Validators.required]],
            //idHeadquarter: ['', []],
            // locked: [false],
        });
    }

    passwordMatcher(): boolean {
      const password = this.userForm.get('password');
      const confirmPassword = this.userForm.get('confirmPassword');

      if (password.value === confirmPassword.value) {
        return true;
      }
      return false ;
    }

    get f() { return this.userForm.controls; }

    openConfirmationModal() {
      if (this.userForm.valid) {


      if(!this.passwordMatcher()){
        this.alertService.showMessage("", "No coincide la contraseña con la contraseña de confirmación", MessageSeverity.error);
        return false;
      }


        let message = '';
        switch(this.data.action){
            case AppConstants.OperationType.ADD:
                message = AppConstants.TitleModal.REGISTER_TITLE_USER_MODAL
                break;
            case AppConstants.OperationType.UPDATE:
                message = AppConstants.TitleModal.EDIT_TITLE_USER_MODAL
                break;
        }
        let dialogRef = this.dialog.open(MessageModalComponent, {
            data: {
                title: message,
                message: this.data.action == AppConstants.OperationType.UPDATE ? AppConstants.MessageModal.UPDATE_MESSAGE_USER_CONFIRM : AppConstants.MessageModal.REGISTER_MESSAGE_USER_CONFIRM,
                hasButton: true,
                contentButton: {
                    yes: 'SI',
                    no: 'CANCELAR'
                }
            },
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response.result == true) {
              if (this.data.action == AppConstants.OperationType.UPDATE) {
                var mdel = new UserPut();
                    mdel.userId = this.data.row.userId;
                    mdel.enterpriseId = 0;//this.data.row.enterpriseId;
                    mdel.rolId = 0;//this.userForm.value["role"];
                    // mdel.dni = this.userForm.value["documentNumber"];
                    mdel.lasName = this.userForm.value["lastName"].trim();
                    mdel.names = this.userForm.value["names"].trim();
                    mdel.email = this.userForm.value["email"].trim();
                    // mdel.password = this.userForm.value["password"];
                    // mdel.confirmNewPassword = this.userForm.value["confirmPassword"];
                    mdel.enabled = this.unparseCheckBox(this.userForm.value["status"]);
                    mdel.sendEmail = this.unparseCheckBox(this.userForm.value["sendEmail"]);
                    mdel.password = this.userForm.value["password"];
                    // mdel.locked = this.unparseCheckBox(this.userForm.value["locked"]);
                    mdel.userUpdate = this.sessionUserId;
                    mdel.idHeadquarter = 0;//this.headquarterSelect;

                this.updateUser(mdel);

              } else if (this.data.action == AppConstants.OperationType.ADD) {
                var model = new User();
                    model.enterpriseId = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE));//this.data.enterpriseId;
                    model.idHeadquarter = parseInt(localStorage.getItem(AppConstants.Session.HEADQUARTER));
                    model.rolId = this.roleSelect;//this.userForm.value["role"];
                    // model.dni = this.userForm.evalue["documentNumber"];
                    model.lasName = this.userForm.value["lastName"].trim();
                    model.names = this.userForm.value["names"].trim();
                    model.email = this.userForm.value["email"].trim();
                    model.password = this.userForm.value["password"];
                    // model.password = this.userForm.value["password"];
                    // model.confirmNewPassword = this.userForm.value["confirmPassword"];
                    model.enabled = this.unparseCheckBox(this.userForm.value["status"]);
                    model.sendEmail = this.unparseCheckBox(this.userForm.value["sendEmail"]);
                    model.userCreation = this.sessionUserId;
                    model.idHeadquarter = 0;//this.headquarterSelect;
                this.createUser(model);
              }
            }
        });

      } else {
        this.userForm.markAllAsTouched();
      }
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
  }

  close() {
    this.dialog.closeAll();
  }

  unparseCheckBox(id: boolean) {
    if (id) return 1; else return 0;
  }

  createUser(model: User) {
    this.spinner.show();
    this.userService.createUser(model).subscribe((response => {
      if(response.message){
        this.alertService.showMessage(AppConstants.TitleModal.CREATE_USER, response.message, MessageSeverity.error);
      }else{
        this.alertService.showMessage(AppConstants.TitleModal.CREATE_USER, AppConstants.MessageModal.CREATE, MessageSeverity.success);
        this.data.result = true;
        this.dialogRef.close(this.data);
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.CREATE_USER, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }
/*
  getRoles() {
    this.spinner.show();
    let arrayRolesAdminEnterprise = [AppConstants.UserRole.AdministradorGyS, AppConstants.UserRole.UsuarioGyS, AppConstants.UserRole.UsuarioGySComercial];
    this.adminService.getEnterprises().subscribe((response) => {
      this.roles = this.isAdminGys == true ? response : response.filter(f => !arrayRolesAdminEnterprise.includes(f.rolId));
      if(this.data.action == AppConstants.OperationType.ADD){
        this.userForm.controls['role'].setValue('');
      }else{
        this.userForm.controls['role'].setValue(this.roles.length > 0 ? this.data.row.rolId : '');
      }

      if (this.data.action != AppConstants.OperationType.UPDATE)
        this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }
*/
  clearFields() {
    this.userForm.controls['email'].setValue('');
    this.userForm.controls['names'].setValue('');
    this.userForm.controls['lastName'].setValue('');
    //this.userForm.controls['role'].setValue('');
    this.userForm.controls['password'].setValue('');
    this.userForm.controls['confirmPassword'].setValue('');
    this.userForm.controls['status'].setValue(true);
    this.userForm.controls['sendEmail'].setValue(false);
    // this.userForm.controls['locked'].setValue(false);
  };

  setValues() {
    this.clearFields();
    if (this.data.action == AppConstants.OperationType.UPDATE) {
      this.spinner.show();
      this.userService.getUserById(this.data.row.userId).subscribe((response => {
        this.userForm.patchValue({
          // documentNumber: this.data.row.dni,
          email: response.username,
          names: response.names,
          lastName: response.lasName,
          role: 0,//response.rolId,
          // password: '******',
          // confirmPassword: '******',
          status: response.enabled,
          sendEmail: response.sendEmail,
         // idHeadquarter: response.idHeadquarter
          // locked: response.locked
        });
        this.headquarterSelect = !response.idHeadquarter ? 0 : response.idHeadquarter;
        this.spinner.hide();
      }),
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    }
  }

  updateUser(model: UserPut) {
    this.spinner.show();
    this.userService.updateUser(model).subscribe((response => {
      if(response.message){
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_USER, response.message, MessageSeverity.error);
      }else{
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_USER, AppConstants.MessageModal.UPDATE_USER, MessageSeverity.success);
        this.data.result = true;
        this.dialogRef.close(this.data);
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_USER, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getRoles() {
    this.spinner.show();
    this.roleService.getListAll(parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE))).subscribe((response) => {
      if(response.error == null){
        this.roles = response.data;
        if(this.roles.length>0){
          this.roleSelect = this.roles[0].id;
        }
      }else{
        this.roles = [];
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.roles = [];
        this.spinner.hide();
      });
}
}
