import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { RoleService } from 'src/app/services/role/role.service';
import { UserService } from 'src/app/services/user/user.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-user-new-assing-modal',
  templateUrl: './user-new-assing-modal.component.html',
  styleUrls: ['./user-new-assing-modal.component.css']
})
export class UserNewAssingModalComponent implements OnInit {

  userForm: FormGroup;
  enterpriseSelect: number;
  enterprises = [];
  headquarterSelect: number;
  headquarters = [];
  roleSelect: number;
  roles = [];

  constructor(
    public dialogRef: MatDialogRef<UserNewAssingModalComponent>,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private enterpriseService: EnterpriseService,
    private headquarterService: HeadquarterService,
    private roleService: RoleService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getEnterprises();
  }

  getEnterprises(){
        this.spinner.show();
        var idEnterprise = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE).toString());
        var data = {
          Enterprise: idEnterprise
        }
       
        this.enterpriseService.getValidateMasterEnterprise(data).subscribe((response) => {
          this.enterpriseService.getEnterprises().subscribe((response2) => {
            if(response.code == 200){
              this.enterprises = response2.filter(f => f.enabled == AppConstants.Flag.Active);
            }else{
              this.enterprises = response2.filter(f => f.enabled == AppConstants.Flag.Active && f.enterpriseId == idEnterprise);
            }
            this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
            this.getHeadquarters();
          },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
            });
        },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
          });

        
  }

  getHeadquarters() {
      this.spinner.show();
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
          this.headquarterSelect = 0;
          if(this.headquarters.length == 1){
            this.headquarterSelect = this.headquarters[0].id;
          }else{
            this.headquarterSelect = 0;
          }
        }else{
          this.headquarters = [];
        }
        this.getRoles();
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.headquarters = [];
          this.spinner.hide();
        });
  }

  getRoles() {
    this.spinner.show();
    this.roleService.getListAll(this.enterpriseSelect).subscribe((response) => {
      if(response.error == null){
        this.roles = response.data;
        if(this.roles.length>0){
          this.roleSelect = this.roles[0].id;
        }
      }else{
        this.roles = [];
      }

      //UPDATE
      if(this.data.action == AppConstants.OperationType.UPDATE){
        this.enterpriseSelect = this.data.obj.idEnterprise;
        this.headquarterSelect = this.data.obj.idHeadquarter;
        this.roleSelect = this.data.obj.idRole;
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.roles = [];
        this.spinner.hide();
      });
}


  createForm(){
    this.userForm = this.formBuilder.group({
      idHeadquarter: ['', [Validators.required]],
      idEnterprise: ['', [Validators.required]],
      idRole: ['', [Validators.required]]
    })
  }

  get f() { return this.userForm.controls; }

  save(){
    if(this.userForm.valid){
      var data = {
        id: this.data.obj == null ? 0: this.data.obj.id,
        idUser: this.data.idUser,
        idRole: this.roleSelect,
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        AuditInsertUser:localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
        this.userService.createoUpadteEnterpriseHeadquarter(data).subscribe(_ => {
          if(!_.message){
            if(this.data.action == AppConstants.OperationType.ADD){
              this.alertService.showMessage(AppConstants.TitleModal.CREATE_USER_RELATIONSHIP, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            }else{
              this.alertService.showMessage(AppConstants.TitleModal.UPDATE_USER_RELATIONSHIP, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            }
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
          this.spinner.hide();
        });
    }else{
      this.userForm.markAllAsTouched();
    }
  }

  changeEnterprise(data) {
    this.enterpriseSelect = data.value;
    this.getHeadquarters();
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
  }

}
