import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ValidateDomainStreaming } from 'src/app/models/streaming/validateDomainStreaming';
import { GetDomainIframeStreaming } from 'src/app/models/streamingDomainIframe/getDomainIframeStreaming';

@Injectable({
  providedIn: 'root'
})
export class StreamingDomainIframeService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'StreamingDomainIframe';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getList(idEnterprise:number): Observable<GetDomainIframeStreaming> {
    return this.httpClient.get<GetDomainIframeStreaming>(`${this.REQUEST_URL}/${idEnterprise}`);
  }

  create(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}`,data);
  }

  update(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}`,data);
  }

  delete(id: number){
    return this.httpClient.delete<any>(`${this.REQUEST_URL}/` + id);
  }

  validateDomain(domain: string): Observable<ValidateDomainStreaming> {
    return this.httpClient.post<ValidateDomainStreaming>(`${this.REQUEST_URL}/validate-by-domain`,{domain: domain});
  }
}
