import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { enterpriseStorage } from 'src/app/models/enterprise/enterpriseStorage';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { MessageModalComponent } from '../../message/message-modal.component';
import { StorageService } from 'src/app/services/storage/storage.service';
import { storageFileDirectory } from 'src/app/models/storage/storageFileDirectory';

@Component({
  selector: 'app-enterprise-storage-modal',
  templateUrl: './enterprise-storage-modal.component.html',
  styleUrls: ['./enterprise-storage-modal.component.css']
})
export class EnterpriseStorageModalComponent implements OnInit {
  fileForm: FormGroup
  title: string;
  loading: boolean = false;
  isAdminGys: boolean = false;
  disabledDirectoryType: boolean = false;
  sessionUserId: string;
  storageFileSelect: number = 1;
  directoryTypeSelect: number = 1;
  storageTypeSelect: number = 1;
  storageTypeSelect2: number = 1;
  listFiles: Array<storageFileDirectory>;
  listFilesInit: Array<storageFileDirectory>;
  arrayData: enterpriseStorage[];
  isDirectoryHeadquarter:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EnterpriseStorageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private storageService: StorageService,
    private alertService: AlertService,
    private emitterService: EmitterService) { }

  ngOnInit() {
    this.title = this.data.title;
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.sessionUserId = localStorage.getItem(AppConstants.Session.USERID);
    this.isDirectoryHeadquarter = this.data.row.headquarterStorageEnabled == 1 ? true : false;
    this.createForm();
    this.getData();
  }

  checkHeadquarter(){
    this.isDirectoryHeadquarter = !this.isDirectoryHeadquarter;
    if(this.isDirectoryHeadquarter){
      if(this.directoryTypeSelect == 1){
        this.listFiles = this.listFilesInit.filter(x => x.blobStorage == 1 && x.isEnterprise == 1);
      }else if(this.directoryTypeSelect == 2){
        this.listFiles = this.listFilesInit.filter(x => x.dataLakeStorage == 1 && x.isEnterprise == 1);
      }
    }else{
      if(this.directoryTypeSelect == 1){
        this.listFiles = this.listFilesInit.filter(x => x.blobStorage == 1);
      }else if(this.directoryTypeSelect == 2){
        this.listFiles = this.listFilesInit.filter(x => x.dataLakeStorage == 1);
      }
    }

    //disabled storage
    if(this.listFilesInit.filter(x => x.dataLakeStorage == 1 && x.isEnterprise == 1).length == 0 && this.isDirectoryHeadquarter){
      if(this.directoryTypeSelect == 2){
        this.directoryTypeSelect = 1;
        this.listFiles = this.listFilesInit.filter(x => x.blobStorage == 1 && x.isEnterprise == 1);
      }
      this.disabledDirectoryType = true;
    }else{
      this.disabledDirectoryType = false;
    }

  }

  changeDirectory(data){
    this.directoryTypeSelect = data.value;
    if(this.isDirectoryHeadquarter){
      if(this.directoryTypeSelect == 1){
        this.listFiles = this.listFilesInit.filter(x => x.blobStorage == 1 && x.isEnterprise == 1);
      }else if(this.directoryTypeSelect == 2){
        this.listFiles = this.listFilesInit.filter(x => x.dataLakeStorage == 1 && x.isEnterprise == 1);
      }
    }else{
      if(this.directoryTypeSelect == 1){
        this.listFiles = this.listFilesInit.filter(x => x.blobStorage == 1);
      }else if(this.directoryTypeSelect == 2){
        this.listFiles = this.listFilesInit.filter(x => x.dataLakeStorage == 1);
      }
    }
    /*
    if(data.value == 1){
      this.listFiles = this.listFilesInit.filter(x => x.blobStorage == 1);
    }else if(data.value == 2){
      this.listFiles = this.listFilesInit.filter(x => x.dataLakeStorage == 1);
    }*/
  }

  getData() {
    this.spinner.show();
    this.storageService.get(this.data.row.id,0).subscribe((response => {
      if(response.error == null && response.data != null){
        this.listFilesInit = response.data.fileDirectories;
        console.log("filter dictories",response.data.fileDirectories);
        this.listFiles = response.data.fileDirectories.filter(x => x.blobStorage == 1);
        this.storageTypeSelect = response.data.idStorageType;
        this.storageTypeSelect2 = response.data.idStorageType2;
        this.fileForm.patchValue({
          idStorageType: response.data.idStorageType,
          storagePath: response.data.storagePath,
          idStorageType2: response.data.idStorageType2,
          storagePath2: response.data.storagePath2,
          dataLakePath: response.data.dataLakePath,
        });
        if(this.isDirectoryHeadquarter){
          if(this.directoryTypeSelect == 1){
            this.listFiles = this.listFilesInit.filter(x => x.blobStorage == 1 && x.isEnterprise == 1);
          }else if(this.directoryTypeSelect == 2){
            this.listFiles = this.listFilesInit.filter(x => x.dataLakeStorage == 1 && x.isEnterprise == 1);
          }
        }else{
          if(this.directoryTypeSelect == 1){
            this.listFiles = this.listFilesInit.filter(x => x.blobStorage == 1);
          }else if(this.directoryTypeSelect == 2){
            this.listFiles = this.listFilesInit.filter(x => x.dataLakeStorage == 1);
          }
        }

        //disabled storage
        if(this.listFilesInit.filter(x => x.dataLakeStorage == 1 && x.isEnterprise == 1).length == 0 && this.isDirectoryHeadquarter){
          this.disabledDirectoryType = true;
        }else{
          this.disabledDirectoryType = false;
        }
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  createForm() {
    this.fileForm = this.formBuilder.group({
        idDirectoryType: [1,[Validators.required]],
        idStorageType: [0,[Validators.required]],
        storagePath: [''],
        idStorageType2: [0,[Validators.required]],
        storagePath2: [''],
        dataLakePath: ['']
    });
  }

  get f() { return this.fileForm.controls; }

  openConfirmationModal() {
    if (this.fileForm.valid) {
      let dialogRef = this.dialog.open(MessageModalComponent, {
          data: {
              title: AppConstants.TitleModal.UPDATE_HEADQUARTER_FILE,
              message: AppConstants.MessageModal.UPDATE_MESSAGE_ENTERPRISE_SETTING_CONFIRM,
              hasButton: true,
              contentButton: {
                  yes: 'SI',
                  no: 'CANCELAR'
              }
          },
      });

      dialogRef.afterClosed().subscribe(response => {
          if (response.result == true) {
            this.update();
          }
      });

    } else {
      this.fileForm.markAllAsTouched();
    }
  }

  update() {

    if(this.fileForm.valid){

      this.listFiles.forEach(obj => {
        obj.idEnterprise = this.data.row.id;
        obj.idHeadquarter = 0;
      });

      var data = {
        idEnterprise: this.data.row.id,
        idHeadquarter: 0,
        idStorageType: this.fileForm.value["idStorageType"],
        idStorageType2: this.fileForm.value["idStorageType2"],
        storagePath: this.fileForm.value["storagePath"],
        storagePath2: this.fileForm.value["storagePath2"],
        dataLakePath: this.fileForm.value["dataLakePath"],
        fileDirectories: this.listFilesInit,
        isDirectoryHeadquarter: this.isDirectoryHeadquarter ? 1:0,
      }
      this.spinner.show();
      this.storageService.update(data).subscribe((response => {
        this.alertService.showMessage("", AppConstants.MessageModal.UPDATE_ENTERPRISE_SETTING, MessageSeverity.success);
        this.spinner.hide();
        this.data.result = true;
        this.dialogRef.close(this.data);
      }),
        (error: HttpErrorResponse) => {
          this.alertService.showMessage("", error.error.messages, MessageSeverity.error);
          this.spinner.hide();
        });
    }else{
      this.fileForm.markAllAsTouched();
      this.alertService.showMessage("", AppConstants.MessageModal, MessageSeverity.error);
    }
  }

  close() {
    this.data.result = false;
    this.dialogRef.close(this.data);
  }

  changeValue(item,event:any){
    this.listFilesInit.forEach(obj => {
      if(obj.idFileDirectory==item.idFileDirectory){
        obj.name = event.target.value;
      }
    });
  }
}

