<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
    <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
    <form [formGroup]="sensorForm" [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' " >
      <div class="row">
          <div class="col-12" >
              <mat-form-field class="w-100">
                  <mat-label>Sede*</mat-label>
                  <mat-select
                      [disabled]="disabledHeadquarter"
                      formControlName="idHeadquarter"
                      (selectionChange)="changeHeadquarter($event)"
                      [(ngModel)]="headquarterSelect">
                      <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                          {{headquarter.name}}
                      </mat-option>
                  </mat-select>

                  <mat-error *ngIf="f.idHeadquarter.hasError('required')">
                    La sede es <strong>requerida</strong>.
                </mat-error>
              </mat-form-field>
          </div>
          <div class="col-12" >
            <mat-form-field class="w-100">
                <mat-label>Gateway*</mat-label>
                <mat-select
                    formControlName="idGateway"
                    (selectionChange)="changeGateway($event)"
                    [(ngModel)]="gatewaySelect">
                    <mat-option *ngFor="let gateway of gateways" [value]="gateway.id">
                        {{gateway.name}}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="f.idGateway.hasError('required')">
                  El gateway es <strong>requerida</strong>.
              </mat-error>
            </mat-form-field>
        </div>
          <div class="col-12">
              <mat-form-field class="w-100">
                  <mat-label>Nombre*</mat-label>
                  <input matInput formControlName="name" maxlength="50">
                  <mat-error *ngIf="f.name.hasError('required')">
                      El Nombre es <strong>requerido</strong>.
                  </mat-error>
              </mat-form-field>
          </div>
          <div class="col-12">
              <mat-form-field class="w-100">
                  <mat-label>Descripción</mat-label>
                  <input matInput formControlName="description" maxlength="50">
              </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label>Nomenclatura*</mat-label>
                <input matInput formControlName="nomenclature" >
                <mat-error *ngIf="f.nomenclature.hasError('required')">
                    La nomenclatura <strong>requerida</strong>.
                </mat-error>
                <mat-error *ngIf="f.nomenclature.errors?.maxlength">
                  La nomenclatura no puede ser mayor a 30 caracteres.
                </mat-error>
                <mat-error *ngIf="f.nomenclature.errors?.minlength">
                  La nomenclatura no puede ser menor a 2 caracteres.
                </mat-error>
            </mat-form-field>
         </div>
         <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label>Tipo de Actuador</mat-label>
                <mat-select
                    formControlName="idActuatorType"
                    (selectionChange)="changeActuatorType($event)"
                    [(ngModel)]="actuatorTypeSelect">
                    <mat-option *ngFor="let actuator of actuatorTypes" [value]="actuator.id">
                        {{actuator.name}}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="f.idActuatorType.hasError('required')">
                  El tipo de actuador es <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label>Tipo de Data</mat-label>
                <mat-select
                    formControlName="idActuatorDataType"
                    (selectionChange)="changeActuatorDataType($event)"
                    [(ngModel)]="actuatorDataTypeSelect">
                    <mat-option *ngFor="let actuator of actuatorDataTypes" [value]="actuator.id">
                        {{actuator.name}}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="f.idActuatorDataType.hasError('required')">
                  El tipo de data es <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row col-12" *ngIf="actuatorTypeSelect == 1"> <!-- TANQUE -->
            <div class="col-6">
              <mat-form-field class="w-100">
                  <mat-label>Capacidad Máxima(TNM)*</mat-label>
                  <input matInput formControlName="maxCapacity" >
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="w-100">
                  <mat-label>Capacidad Mínima (Warrant)</mat-label>
                  <input matInput formControlName="minCapacity" >
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="w-100">
                  <mat-label>Altura Máxima(MTS)*</mat-label>
                  <input matInput formControlName="maxHeight" >
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="w-100">
                  <mat-label>Densidad</mat-label>
                  <input matInput formControlName="density" >
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="w-100">
                  <mat-label>Radio</mat-label>
                  <input matInput formControlName="radio" >
              </mat-form-field>
            </div>
        </div>
      </div>
  </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
