import { Component, Input, OnInit } from '@angular/core';
import DPlayer from '../../../../../assets/js/dplayer_mod.js';

@Component({
  selector: 'app-streampublic',
  templateUrl: './streampublic.component.html',
  styleUrls: ['./streampublic.component.css']
})
export class StreampublicComponent implements OnInit {

  @Input('id') id : any;
  @Input('url') url : any;
  @Input('child') child : any;

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit(){
    var IsiPhone = navigator.userAgent.indexOf("iPhone") != -1 ;
    var IsiPod = navigator.userAgent.indexOf("iPod") != -1 ;
    var IsiPad = navigator.userAgent.indexOf("iPad") != -1 ;

    var IsiPhoneOS = IsiPhone || IsiPad || IsiPod ;
    var video;
    //var url = "https://live.smartechlatam.online/claro/chimbotetest";
    //if (IsiPhoneOS) {
      video = {
	      quality: [
            {
                name: 'HD',
                url: this.url+'/index.m3u8',
                type: 'hls',
            },
            {
                name: '480',
                url: this.url +'_480/index.m3u8',
                type: 'hls',
            },
            {
                name: '720',
                url: this.url +'_720/index.m3u8',
                type: 'hls',
            },
        ],
        defaultQuality: 0,
      }
    /*} else {
      video = {
	      quality: [
            /*{
                name: 'HD',
                url: url+'.flv',
                type: 'flv',
            },
            {
                name: '480',
                url: url+'_480.flv',
                type: 'flv',
            },
            {
                name: '720',
                url:this.url+'.flv',
                type: 'flv',
            },
        ],
        defaultQuality: 0,
      }
    //}*/

    var myVideo = document.getElementById( this.id )
    const dp = new DPlayer({
      container: myVideo,
      screenshot: false,
      live: !this.child,
      preload: 'none',
      autoplay: !this.child,
      airplay: !this.child,
      mutex: false,
      hotkey: false,
      video: video,
      volume:0
    });

    if(this.child){
      document.querySelector('.dplayer').classList.remove('dplayer-controller');
    }
  }

}
