<div class="container">


    <section class="filter mt-3">

        <form [formGroup]="reportForm" action="">
          <div class="row">
            <div class="col-md-10 col-lg-10 filterAlert">
        <div class="row">
                    <!--<div class="col-lg-3 col-md-6 col-xs-12">
                      <mat-form-field class="w-100">
                          <mat-label>Empresa</mat-label>
                          <mat-select (selectionChange)="changeEnterprise($event)" formControlName="enterpriseId" [(ngModel)]="enterpriseSelect">
                              <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                                  {{enterprise.businessName}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-md-6 col-xs-12">
                      <mat-form-field class="w-100">
                          <mat-label>Sede</mat-label>
                          <mat-select (selectionChange)="changeHeadquarter($event)" formControlName="idHeadquarter"  [(ngModel)]="headquarterSelect">
                              <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                                  {{headquarter.name}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </div>-->
                    <div class="col-lg-3 col-md-6 col-xs-12">
                      <mat-form-field class="w-100">
                        <mat-label>Seleccionar Zona</mat-label>
                        <mat-select formControlName="zoneId" (selectionChange)="changeZone()"
                                    [(ngModel)]="enterpriseZoneSelect">
                          <mat-option *ngFor="let zone of enterprisesZones" [value]="zone.id">
                            {{zone.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.zoneId.hasError('required')">
                            La zona es <strong> obligatorio</strong>
                          </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <mat-form-field class="w-100" color="primary">
                        <mat-label>Cámara</mat-label>
                        <mat-select (selectionChange)="changeCamera()"
                                    [(ngModel)]="cameraSelect" formControlName="cameraReport">
                          <mat-option [value]="0" *ngIf="listCameras.length > 0">
                            Todos
                          </mat-option>
                          <mat-option *ngFor="let camera of listCameras" [value]="camera.cameraId">
                            {{camera.camera}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                <div class="col-md-6 col-lg-3">
                  <mat-form-field class="w-100" color="primary">
                    <mat-label>Tipo de alerta</mat-label>
                    <mat-select [(ngModel)]="alertSelect" formControlName="alertReport">
                      <mat-option [value]="0" >
                        Todos
                      </mat-option>
                      <mat-option *ngFor="let cameraAlerts of cameraAlertType" [value]="cameraAlerts.id_Alert">
                        {{cameraAlerts.c_Alert}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
            </div>
              <div class="row">
              <div class="col-md-6 col-lg-3">
                  <mat-form-field class="w-100" color="primary">
                      <mat-label>Fecha inicio</mat-label>
                      <input matInput formControlName="startDateReport" readonly [matDatepicker]="picker1" maxlength="10" [(ngModel)] = "date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 ></mat-datepicker>
                      <mat-error *ngIf="f.startDateReport.hasError('required')">
                        La fecha de Inicio es <strong> obligatorio</strong>
                      </mat-error>
                      <mat-error *ngIf="f.startDateReport.hasError('pattern')">
                        La fecha de Inicio<strong> no tiene el formato correcto</strong>
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-md-6 col-lg-3">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Fecha Fin</mat-label>
                    <input matInput formControlName="endDateReport" readonly [matDatepicker]="picker2" maxlength="10" [(ngModel)] = "dateF">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 ></mat-datepicker>
                    <mat-error *ngIf="f.endDateReport.hasError('required')">
                      La fecha Final es<strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.endDateReport.hasError('pattern')">
                      La fecha Final<strong> no tiene el formato correcto</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-lg-3">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Hora inicio</mat-label>
                    <input matInput formControlName="startTimeReport" maxlength="5" [(ngModel)]="timeI" value="{{timeI}}" (keypress)="_keyUp($event)">
                    <mat-error *ngIf="f.startTimeReport.hasError('required')">
                      La Hora Inicial es<strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.startTimeReport.hasError('pattern')">
                      La Hora Inicial tiene <strong> formato incorrecto</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-lg-3">
                  <mat-form-field class="w-100" color="primary">
                      <mat-label>Hora fin</mat-label>
                      <input matInput formControlName="endTimeReport" maxlength="5" [(ngModel)]="timeF" value="{{timeF}}" (keypress)="_keyUp($event)">
                      <mat-error *ngIf="f.endTimeReport.hasError('required')">
                        La Hora Final es <strong> obligatorio</strong>
                      </mat-error>
                      <mat-error *ngIf="f.endTimeReport.hasError('pattern')">
                        La Hora Final tiene <strong> formato incorrecto</strong>
                      </mat-error>
                  </mat-form-field>
              </div>
        </div>
        </div>

      <div class="col-md-2 col-lg-2" style="padding: 12px;">
        <button mat-button class="mr-3 btn-color" (click)="searchAlerts()">VER ALERTAS</button>
        <button mat-button class="mr-3 btn-color" (click)="showAllAlert()">VER TODOS</button>
      </div></div>
        </form>
    </section>

    <section class="filter mt-3">
      <div class="row">

            <div (dragover)="false"
               (dragend)="false">
               <canvas id="canvas" width="720" height="520" style="border-style: solid; border-color:#D9CDAB"></canvas>
            </div>
            <div class="col-12 col-md-4">
                <div class="col-12">
                    <div class="labelAlerta mt-2" [style.color]="color1" >
                      <span>Estado de alertas {{filterCamera}}</span>
                      <span>Nº de Alertas: {{countAlerts}}</span>
                    </div>
                    <div class="mouseOverNav scroll">
                        <ul class="list-group">
                            <!--<li  *ngFor="let item of cameraAlert; let i = index"
                                class="list-group-item list-group-items" [ngStyle]="{'cursor':'pointer','background-color': item.color, 'color': '#fff'}"
                                (click)="alerta(item.ocurennceId,item.cameraId)">
                                <div style="display: flex;justify-content: center;">
                                    <div style="display: flex;width: 10%;justify-content: center;"><i class="fa fa-map-marker fa-lg" style="margin-left:-8px;"></i> </div>
                                    <div style="display: flex;width: 90%;"><label style="cursor:pointer;" class="lableAlertView"><b>Cámara:</b> {{item.camera}} - <b>Alerta:</b> {{item.alert}}</label></div>
                                </div>
                            </li>-->
                            <li  *ngFor="let item of cameraAlert; let i = index"
                                class="list-group-item list-group-items" [ngStyle]="{'cursor':'pointer','background-color': item.color, 'color': '#fff'}"
                                (click)="alerta(item.ocurennceId,0)">
                                <div style="display: flex;justify-content: center;">
                                    <!--<div style="display: flex;width: 10%;justify-content: center;"><i class="fa fa-map-marker fa-lg" style="margin-left:-8px;"></i> </div>-->
                                    <div style="display: flex;width: 100%;">
                                      <div class="lableAlertView">
                                        <div class="block-alert">
                                          <span class="span-alert"><mat-icon>photo_camera</mat-icon>{{item.camera}}</span>
                                          <span class="span-alert">
                                            <mat-icon  *ngIf="item.alertId == 8">supervisor_account</mat-icon>
                                            <mat-icon  *ngIf="item.alertId == 7">phonelink_ring</mat-icon>
                                          </span> <!-- {{item.alert}} -->
                                        </div>
                                        <div>
                                          <span class="span-alert"><mat-icon>calendar_today</mat-icon>{{item.date}}</span>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div [style.color]="color1" class="divLegend mt-2">
                      <div class="item-legend">
                        <mat-icon>supervisor_account</mat-icon> Sin casco
                      </div>
                      <div class="item-legend">
                        <mat-icon>phonelink_ring</mat-icon> Uso de celular
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="filter mt-3">
        <div class="row">
            <div class="col-12 col-md-8 offset-md-2">
                <div class="row m-0 section-legend">
                    <div class="col-6 col-lg-3 text-center pt-3 pt-lg-0 my-auto">
                        <i class="fa fa-square rotateIcon alert-state"></i>
                        <span class="pl-2">Alerta</span>
                    </div>
                    <div class="col-6 col-lg-3 text-center pt-3 pt-lg-0 my-auto">
                        <i class="fa fa-square rotateIcon inprogress-state"></i>
                        <span class="pl-2">En Proceso</span>
                    </div>
                    <div class="col-6 col-lg-3 text-center pt-3 pt-lg-0 my-auto">
                        <i class="fa fa-square rotateIcon normal-state"></i>
                        <span class="pl-2">Normal</span>
                    </div>
                    <div class="col-6 col-lg-3 text-center pt-3 pt-lg-0 my-auto">
                        <i class="fa fa-square rotateIcon disabled-state"></i>
                        <span class="pl-2">Desactivado</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2 my-auto pt-2 pt-md-0">

            </div>
        </div>
    </section>

</div>
