import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { StreamingDomainIframeService } from 'src/app/services/streamingDomainIframe/streamingDomainIframe.service';
import { ItemdomainIframeStreamingModalComponent } from 'src/app/shared/components/modals/domain-iframe-streaming/domain-iframe-streaming-modal.component';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-domain-iframe-streaming',
  templateUrl: './domain-iframe-streaming.component.html',
  styleUrls: ['./domain-iframe-streaming.component.css']
})
export class StreamingDomainIframeComponent implements OnInit {
  loading: boolean = false;
  dataSource = new MatTableDataSource();
  dataSources = new MatTableDataSource();
  displayedColumns: string[] = ['domain','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginators: MatPaginator;
  enterprises = [];
  headquarterSelect = 0;
  enterpriseSelect: number = 0;
  headquarters=[];
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  enabledRead: number;
  enabledWrite: number;

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private securityService: StreamingDomainIframeService,
    private enterpriseService: EnterpriseService,
    private headquarterService: HeadquarterService,
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'Streaming > <span class="font-weight-bold"> Dominios Iframe </span>';
    }

  ngOnInit() {
    this.setLS();
    this.dataSource.paginator = this.paginator;
    this.dataSources.paginator = this.paginators;
    this.paginator._intl.itemsPerPageLabel = '';
    this.paginators._intl.itemsPerPageLabel = '';
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.getList();
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  getEnterprises() {
    this.spinner.show();
    this.enterpriseService.getEnterprises().subscribe((response) => {
        this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
        this.enterpriseSelect = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE));
        this.getHeadquarters();
        //this.getZonesByEnterprise(this.enterpriseSelect);
      //  this.spinner.hide();
    },
    (error: HttpErrorResponse) => {
        this.spinner.hide();
    });
}

getHeadquarters() {
 // if (this.isAdminGys) {
    //this.spinner.show();
    this.headquarterService.getListAll().subscribe((response) => {
      if(response.error == null){
        this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
        this.headquarterSelect = 0;
      }else{
        this.headquarters = [];
      }
      this.getList();
      //this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.headquarters = [];
        this.spinner.hide();
      });
  //} else {
   // this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
  //}
}

changeEnterprise(data) {
  this.enterpriseSelect = data.value;
  this.getList();//this.getHeadquarters();
}

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
    this.getList();
  }

  getList(){
    this.securityService.getList(this.enterpriseSelect).subscribe((_) => {
      if(_.error == null){
        this.dataSource.data = _.security;
      }
      this.spinner.hide();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openAddModal() {
    let dialogRef = this.dialog.open(ItemdomainIframeStreamingModalComponent, {
      data: { title: 'Agregar dominio', action: 0,idEnterprise: this.enterpriseSelect , enabledWrite: this.enabledWrite,
      enabledRead: this.enabledRead,obj:null},
      width: '500px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      this.getList();
    });
  }

  openEditModal(data){
      let dialogRef = this.dialog.open(ItemdomainIframeStreamingModalComponent, {
        data: { title: 'Actualizar dominio', action: 1,idEnterprise: this.enterpriseSelect ,
        enabledWrite: this.enabledWrite,
              enabledRead: this.enabledRead,obj:data},
        width: '500px',
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(_ => {
        this.getList();
      });
  }

  delete(obj) {
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
        message: `${AppConstants.MessageModal.DELETE_SECURITY_CONFIRMATION_MESSAGE1}`,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteSecurity(obj.id);
      }
    });

  }

    deleteSecurity(id: number) {
      this.spinner.show();
      this.securityService.delete(id).subscribe((response => {
        this.spinner.hide();
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SECURITY, AppConstants.MessageModal.DELETE, MessageSeverity.success);
        this.getList();
      }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SECURITY, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
    }
}
