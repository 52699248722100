import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterprisePinMaps } from 'src/app/models/enterprise/enterprisePinMaps';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { environment } from 'src/environments/environment';
import { ImageModalComponent } from '../../image/image-modal.component';
import { PinModalComponent } from '../enterprise-pin-modal/pin-modal.component';

@Component({
  selector: 'app-enterprise-pin-list-modal',
  templateUrl: './enterprise-pin-list-modal.component.html',
  styleUrls: ['./enterprise-pin-list-modal.component.css']
})
export class EnterprisePinListModalComponent implements OnInit {

  pinMapfiles: File[] = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['headquarter','name', 'action'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  listPinMaps = [];

  constructor(
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private zoneService: ZoneService,
    private alertService: AlertService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.getList();
  }

  getList(){
    this.spinner.show();
    var filter = {
      IdEnterprise: this.data.idEnterprise,
      IdHeadquarter: this.data.idHeadquarter
    };
    this.zoneService.getListPines(filter).subscribe((response => {
      this.listPinMaps = response.data;
      this.dataSource.data = this.listPinMaps;
      this.spinner.hide();
    }),
    (error: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  onFilePinMapSelected(event) {
    var files = event.target.files;
    if (files.length > 0) {
      var file = files[0];
      if (file.type == "image/png" || file.type == "image/jpeg") {
        console.log(this.listPinMaps);
        if (this.listPinMaps.filter(f => f.name == this.convertName(file.name)).length == 0) {
          var model = new EnterprisePinMaps();
          model.name = this.convertName(file.name);
          model.file = file;
          this.listPinMaps.push(model);
          this.pinMapfiles.push(file);
          this.dataSource.data = this.listPinMaps;
        } else {
          this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_PINMAP_DUPLICATE, MessageSeverity.warn);
        }
      } else {
        this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
      }
      event.srcElement.value = '';
    }
  }

  convertName(name: string) {
    return name.split('.').slice(0, -1).join();
  }

  deletePinMap(obj){
    this.spinner.show();
    var data = {
      IdUser: localStorage.getItem(AppConstants.Session.USERID),
      Id: obj.id
    }
    this.zoneService.deletePin(data).subscribe((response => {
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_PIN, AppConstants.MessageModal.DELETE, MessageSeverity.success);
      this.getList();
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_PIN, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  showImage(element){
    console.log(element);
    var path = element.path;
    if(path != null && path != ""){
        if(element.idStorageFile == 1){ // Mode Local
          path = environment.pathFile + path;
        }
        this.dialog.open(ImageModalComponent, {
            data: {
              title: AppConstants.TitleModal.UPDATE_ZONE,
              image: path},
            width: '500px',
            hasBackdrop: true
        });
    }else{
      this.alertService.showMessage("", "No tiene imagen subida", MessageSeverity.error);
    }
  }

  addModalPin(){
    this.dialog.open(PinModalComponent, {
      data: {
        title: AppConstants.TitleModal.CREATE_PIN,
        idEnterprise: this.data.idEnterprise,
        idHeadquarter: this.data.idHeadquarter,
        headquarters: this.data.headquarters,
        action: 0
      },
      width: '500px',
      hasBackdrop: true
  });
  }

}
