import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import 'fabric';
import { AlertService, MessageSeverity } from '../../../../shared/services/alert.service';
import { OcurrenceService } from 'src/app/services/Ocurrence/ocurrence.service';
import { PanelCameraModalComponent } from 'src/app/shared/components/modals/panel/camera/panel-camera-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CameraZoneViewModel } from 'src/app/models/camera/camera';
import { MatDialog } from '@angular/material/dialog';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { CameraService } from 'src/app/services/camera/camera.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/helpers/format-datepicker';
//import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
declare const fabric: any;

@Component({
  selector: 'app-camera-alert',
  templateUrl: './camera-alert.component.html',
  styleUrls: ['./camera-alert.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class CameraAlertComponent implements OnInit {

  loading: boolean = false;
  enterprises = [];
  enterpriseSelect: number = 0;
  enterprisesZones = [];
  enterpriseZoneSelect: number = 0;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['index', 'camera', 'enabled'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  subscription: Subscription;
  listCameras = new Array<CameraZoneViewModel>();
  reportForm: FormGroup;
  myDate = new Date();
  alertSelect: number = 0;
  idStorageType: number;
  date = new Date();
  dateF = new Date();
  timeI: string;
  timeF: string;
  dateV:string;
  headquarterSelect = 0;
  headquarters = [];
  dateIView:string;
  dateFView:string;
  isAdminGys: boolean = false;
  image: any;
  file: File = null;
  canvas: any;
  genredCanva: boolean = true;
  nameMarker: string = '';
  cameraId: number = 0;
  cameraSelect: number = 0;
  cameras = [];
  zoneId: number = 0;
  filterCamera = '';
  countAlerts = 0;
  isAdministrator=false;
  cameraAlert = [];
  cameraAlertType = [];
  filter = false;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  //private connection: HubConnection;

  constructor(
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private enterpriseService: EnterpriseService,
      private cameraService: CameraService,
      private alertService: AlertService,
      private OcurrenceService: OcurrenceService,
      private formBuilder: FormBuilder,
      private datePipe: DatePipe,
      private headquarterService: HeadquarterService,
      private zoneService: ZoneService,
  ) {
    document.getElementById("titleHeaderNav").innerHTML = 'Video Analítica > <span class="font-weight-bold">Alertas</span>';
  }

  ngOnInit() {
    this.setTheme();
    this.createForm();
    this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
    this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
    this.dateV = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.getCanvas();
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.getZonesByEnterprise();

    var _this = this;
    const connection = new signalR.HubConnectionBuilder()
    .withUrl(environment.fnVA + '/api')
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

    connection.on('newAlert', (data) => {
      console.log("data alert",data);
      //_this.countAlerts = _this.countAlerts+1;
      if(data.stateId == 1){ data.color = "red" }else{ data.color = "blue"; }
      if(!_this.filter){
        //_this.cameraAlert.splice(0, 0, data);
        _this.ocurrenceListByCamera();
      }else{
        if(
          _this.enterpriseSelect == data.enterpriseId &&
          ( _this.alertSelect == data.alertId || _this.alertSelect == 0 )
        ){
          _this.ocurrenceListByCamera();
          //_this.cameraAlert.splice(0, 0, data);
        }
      }
    });

    connection.start()
      .catch(console.error);

/*
       setInterval(() => {
        this.ocurrenceListByCamera();
    }, 2000);*/
  }

  createForm() {
    this.reportForm = this.formBuilder.group({
        zoneId: ['', [Validators.required]],
        startDateReport: ['', [Validators.required]],
        endDateReport: ['', [Validators.required]],
        startTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
        endTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
        alertReport: [0, []],
        cameraReport: [0, [Validators.required]]
    });
  }

  setTheme(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
  }

  refreshAlerts(){
      this.updateColor();
      this.getCameras(this.enterpriseZoneSelect,0);
      this.ocurrenceListByCamera();
  }

  updateColor(){
    let data = this.listCameras;
    for(let j= 0;j < data.length;j++){
      let registers =
      {
         cameraId: data[j].cameraId
      }
      this.cameraService.postColor(registers).subscribe(
        result => {
        },
        (error: HttpErrorResponse) => {
          this.alertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
         }
     );
    }
  }

  get f() { return this.reportForm.controls; }

  showAllAlert(){
    this.cameraSelect = 0;
    this.alertSelect = 0;
    this.filterCamera =  "";
    this.filter = false;
    this.ocurrenceListByCamera();
  }

  getCanvas() {
    this.canvas = new fabric.Canvas('canvas',{selection: false});
    var _this = this;
    this.canvas.on('mouse:down', function (e) {
      if(e.button === 1) {
        if( e.target!= null){
          _this.filterCamera =  " - "+ e.target.camera;
          _this.cameraSelect = e.target.cameraId;
          _this.changeCamera();
          _this.reportForm.controls['cameraReport'].setValue(_this.cameraSelect);
        }else{
          console.log("null left click",e);
        }
      }
        return false;
    });
  }

  getZonesByEnterprise() {
    this.enterprisesZones = [];
    this.spinner.show();
    this.zoneService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe(response => {
      if(response.error == null){
        var zones = response.data.filter(_ => _.type == 1);
        this.enterprisesZones = zones;
        if(this.enterprisesZones.length>0){
          this.enterpriseZoneSelect = this.enterprisesZones[0]["id"];
        }else{
          this.alertService.showMessage("No tiene zonas VA registradas, debes de crear una zona para usar este módulo", "", MessageSeverity.error);
        }
        this.changeZone();
      }else{
        this.enterprisesZones = [];
        this.cameras = [];
      }
     // this.getCameras(this.enterpriseZoneSelect,1);
      this.spinner.hide();
    });
  }

  getCameras(zoneId: number,init: number) {
    this.spinner.show();
    var data = {
      idZone: zoneId,
      idEnterprise: this.enterpriseSelect,
      idHeadquarter: this.headquarterSelect
    }
    this.cameraService.getCameraByZoneCP(data).subscribe((response => {
      this.listCameras = response.filter(x => x.enabled == 1);
      this.dataSource.data = this.listCameras;
      this.spinner.hide();
      if(init){
        this.clearCanvas();
        this.createMarkers();
      }
      this.ocurrenceListByCamera();
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  clearCanvas() {
    this.canvas.remove(...this.canvas.getObjects());
    var json = this.canvas.toJSON();
    this.canvas.clear();
    this.canvas.loadFromJSON(json, this.canvas.renderAll.bind(this.canvas));
    var objects = this.canvas.getObjects();
      for(var i = 0; i < objects.length; i++){
        this.canvas.remove(objects[i]);
    }
    this.canvas.renderAll();
  }

  positionMarkers() {
    let data = this.canvas._objects.filter(f => f.cameraId != undefined);
    data.forEach(f => {
      let index = this.listCameras.findIndex(item => item.cameraId == f.cameraId);
      if (index != -1) {
        var obj = this.listCameras[index];
            obj.cameraPosX = f.group.left;
            obj.cameraPosY = f.group.top;
      }
    });
  }

  createMarkers() {
    var path = "m216.210938 0c-122.664063 0-222.460938 99.796875-222.460938 222.460938 0 154.175781 222.679688 417.539062 222.679688 417.539062s222.242187-270.945312 222.242187-417.539062c0-122.664063-99.792969-222.460938-222.460937-222.460938zm67.121093 287.597656c-18.507812 18.503906-42.8125 27.757813-67.121093 27.757813-24.304688 0-48.617188-9.253907-67.117188-27.757813-37.011719-37.007812-37.011719-97.226562 0-134.238281 17.921875-17.929687 41.761719-27.804687 67.117188-27.804687 25.355468 0 49.191406 9.878906 67.121093 27.804687 37.011719 37.011719 37.011719 97.230469 0 134.238281zm0 0";
    for (var i = 0; i < this.listCameras.length; i++) {
      var obj = this.listCameras[i];
      if (obj.cameraPosX != 0 && obj.cameraPosY != 0) {
        //Dynamic red - alerts
        var color = obj.cameraColor;
        if(color == "red"){
          if(this.cameraAlert.length == 0){
            color = "green";
          }else{
            if(this.cameraAlert.filter(x => x.camera == obj.camera).length < 1){
              color = "green";
            }
          }
        }
        this.newGroup(obj,color);
      }
    }
  }

  newGroup(obj,color) {
    var _this = this;
    new fabric.Image.fromURL(obj.pathPinMap,
      function(img) {
        img.scaleToWidth(40);
        img.scaleToHeight(40);
        var img1 = img.set({
          originX: 'center',
          originY: 'bottom',
          angle: 0,
          top: -10,
          scaleX: obj.oppositeMapDirection == '0' ? 0.1 : -0.1,
          scaleY: 0.1,
          selectable:false,
          camera: obj != null ? obj.camera : "",
          cameraId: obj != null ? obj.cameraId : 0,
        });

        var text = new fabric.Text((obj != null ? obj.camera : ""),
          {
            textBackgroundColor:color,
            fill: 'white',
            fontSize: 15,
            originX: 'center',
            originY: 'center',
            selectable:false,
            camera: obj != null ? obj.camera : "",
            cameraId: obj != null ? obj.cameraId : 0,
          }
        );

        var group = new fabric.Group([text, img1], {
          left: obj.cameraPosX,
          top: obj.cameraPosY,
          borderColor: 'transparent',
          hasControls: false,
          hasRotatingPoint: false,
          camera: obj != null ? obj.camera : "",
          cameraId: obj != null ? obj.cameraId : 0,
          selectable:false
        });

        _this.canvas.add(group);
        _this.canvas.renderAll();
      });
  }

  stopDragging(element) {
    element.lockMovementX = true;
    element.lockMovementY = true;
  }

  changeImage(urlImage : string) {
    var _this = this;
    fabric.Image.fromURL(urlImage, (img) => {
      img.set({
        opacity: 0.5,
        scaleX: this.canvas.width / img.width,
        scaleY: this.canvas.height / img.height,
        selectable:true
      });
      _this.canvas.setBackgroundImage(img, _this.canvas.requestRenderAll.bind(_this.canvas));
      _this.getCameras(this.zoneId,1);
    });
  }

  ocurrenceListByCamera() {
    var filterData;
    if(this.filter){
        filterData = {
          dateI: this.reportForm.value["startDateReport"],
          dateF: this.reportForm.value["endDateReport"],
          timeI: this.timeI,
          timeF: this.timeF,
          zoneId: this.enterpriseZoneSelect,
          alertId: this.reportForm.value["alertReport"],
          cameraId: this.reportForm.value["cameraReport"]
        };
        filterData.dateI = this.datePipe.transform(filterData.dateI, 'dd/MM/yyyy');
        filterData.dateF = this.datePipe.transform(filterData.dateF, 'dd/MM/yyyy');
    }else{
      filterData = {
        dateI: 0,
        dateF: 0,
        timeI: 0,
        timeF: 0,
        zoneId: this.enterpriseZoneSelect,
        alertId: this.alertSelect,
        cameraId: this.cameraSelect
      };
    }

    this.OcurrenceService.GetListOcurrenceCP(filterData).subscribe((response) => {
      this.cameraAlert = response;
      this.countAlerts = response.length;
      if(response.length > 0 && this.filter == false){
        var dateI = this.cameraAlert[response.length - 1].date;
        this.date = dateI;
        this.dateF = this.myDate;
      }
      this.spinner.hide();
      if(this.countAlerts > 0){
        this.clearCanvas();
        this.createMarkers();
      }
      if(filterData.cameraId != 0){
        this.filterCamera = " - "+ this.listCameras.find(x => x.cameraId == filterData.cameraId).camera;
      }else{
        this.filterCamera = " - todas las cámaras";
      }
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        this.cameraAlert = [];
      });
  }

  changeCamera() {
    this.cameraAlertType = [];
    this.alertSelect = 0;
    var cameraId = this.cameraSelect;// this.filterCameraId = 0 ? this.cameraSelect : this.filterCameraId;
    //if(cameraId != 0){
      this.cameraService.GetAlertByCamera(cameraId,0).subscribe((response) => {
        this.cameraAlertType = response;
        this.alertSelect = cameraId;
        this.spinner.hide();
        this.alertSelect = 0;
        this.canvas.renderAll();
        this.searchAlerts();
      },
        (error: HttpErrorResponse) => {
          this.cameraAlertType = []
        });
    //}

  }

  alerta(id,id1) {
    let dialogRef = this.dialog.open(PanelCameraModalComponent, {
      data: { ocurrenceId: id, cameraId: id1, enterpiseId: this.enterpriseSelect },
      width: '800px', hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe((_) => {
      if(_.update){
        this.refreshAlerts();
      }
    });
  }

  searchAlerts(){
    if (this.reportForm.valid) {
      this.filter = true;
      this.ocurrenceListByCamera();
    }else{
      this.reportForm.markAllAsTouched();
    }
  }

  _keyUp(event: any) {
    let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode != 58 && charCode > 31
        && (charCode < 48 || charCode > 57)) {
        return false;
      }
  }

  changeZone() {
    this.spinner.show();
    this.nameMarker = ''
    this.clearCanvas();
    this.zoneId = this.enterpriseZoneSelect;
    var item = this.enterprisesZones.filter(f => f.id == this.zoneId);
    if(item.length>0){
      if(item[0].image != null && item[0].image != ""){
        this.changeImage(item[0].image);
      }else{
        this.getCameras(this.zoneId,1);
      }
    }else{
      this.spinner.hide();
    }
  }

}
