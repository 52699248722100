import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user/user.service';
import { UserNewAssingModalComponent } from '../user-new-assing-modal/user-new-assing-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { MessageModalComponent } from '../../message/message-modal.component';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';

@Component({
  selector: 'app-user-assing-modal',
  templateUrl: './user-assing-modal.component.html',
  styleUrls: ['./user-assing-modal.component.css']
})
export class UserAssingModalComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['enterprise','headquarter','role','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  items = [];

  constructor(
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private enterpriseService: EnterpriseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
    this.list();
  }

  list(){
    this.spinner.show();
    var data = {
      Enterprise: +localStorage.getItem(AppConstants.Session.ENTERPRISE)
    }
   
    this.enterpriseService.getValidateMasterEnterprise(data).subscribe((response) => {
      this.userService.getUserEnterprisesHeadquarters(this.data.obj.userId).subscribe((response2) => {
        var dataList = response2;
        if(response.code != 200){
          dataList = response2.filter(f => f.idEnterprise == data.Enterprise);
        }
        console.log("list",dataList);
        this.dataSource.data = dataList;
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
          this.spinner.hide();
      });
    });
  }

  add(){
    let dialogRef = this.dialog.open(UserNewAssingModalComponent, {
      data: {
        action: AppConstants.OperationType.ADD,
        title: AppConstants.TitleModal.TITLE_USER_NEW_ASSING_MODAL,
        idUser: this.data.obj.userId,
        color: this.data.color,
        colorFont: this.data.colorFont
      },
      width: '500px',
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
          this.list();
      }
  });
  }

  delete(element){
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
          title: AppConstants.TitleModal.DELETE_TITLE_USER_ASSING_CONFIRM,
          message: `${AppConstants.MessageModal.DELETE_USER_ASSIGN_CONFIRMATION_MESSAGE1}`,
          hasButton: true,
          contentButton: {
              yes: 'SI',
              no: 'CANCELAR'
          }
      },
  })

  dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteUser(element.id);
      }
  });
  }

  deleteUser(enterpriseId: number) {
    this.spinner.show();
    var data = {
      id: enterpriseId,
      AuditUpdateUser: localStorage.getItem(AppConstants.Session.USERID)
    }

    this.userService.deleteEnterpriseHeadquarter(data).subscribe((response => {
      this.spinner.hide();
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_USER_ASSIGN, AppConstants.MessageModal.DELETE, MessageSeverity.success);
      this.list();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_USER_ASSIGN, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  close() {
    this.dialog.closeAll();
  }

  openEditModal(item){
    let dialogRef = this.dialog.open(UserNewAssingModalComponent, {
      data: {
        action: AppConstants.OperationType.UPDATE,
        title: AppConstants.TitleModal.TITLE_USER_NEW_ASSING_MODAL,
        idUser: this.data.obj.userId,
        color: this.data.color,
        obj: item,
        colorFont: this.data.colorFont
      },
      width: '500px',
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
          this.list();
      }
  });
  }
}
