<div class="container">


  <section class="filter mt-3">
      <div class="row">
          <div class="col-12">
              <div class="row">
                  <div class="col-6 col-md-4">
                      <mat-form-field class="w-100">
                          <mat-label>Zona</mat-label>
                          <mat-select (selectionChange)="getCameras($event.value)" [(ngModel)]="zoneSelect">
                              <mat-option *ngFor="let zone of enterprisesZones" [value]="zone.id">
                                  {{zone.name}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-6 col-xs-12">
                  <mat-form-field class="w-100" color="primary">
                      <mat-label>Fecha inicio</mat-label>
                      <input matInput readonly [matDatepicker]="picker1" maxlength="10" [(ngModel)] = "date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 ></mat-datepicker>
                      <mat-error *ngIf="f.startDate.hasError('required')">
                        La fecha de Inicio es <strong> obligatorio</strong>
                      </mat-error>
                      <mat-error *ngIf="f.startDate.hasError('pattern')">
                        La fecha de Inicio<strong> no tiene el formato correcto</strong>
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Fecha Fin</mat-label>
                    <input matInput readonly [matDatepicker]="picker2" maxlength="10" [(ngModel)] = "dateF">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 ></mat-datepicker>
                    <mat-error *ngIf="f.endDate.hasError('required')">
                      La fecha Final es<strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.endDate.hasError('pattern')">
                      La fecha Final<strong> no tiene el formato correcto</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Hora inicio</mat-label>
                    <input matInput  maxlength="5" [(ngModel)]="timeI" value="{{timeI}}" (keypress)="_keyUp($event)">
                    <mat-error *ngIf="f.startHour.hasError('required')">
                      La Hora Inicial es<strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.startHour.hasError('pattern')">
                      La Hora Inicial tiene <strong> formato incorrecto</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                  <mat-form-field class="w-100" color="primary">
                      <mat-label>Hora fin</mat-label>
                      <input matInput maxlength="5" [(ngModel)]="timeF" value="{{timeF}}" (keypress)="_keyUp($event)">
                      <mat-error *ngIf="f.endHour.hasError('required')">
                        La Hora Final es <strong> obligatorio</strong>
                      </mat-error>
                      <mat-error *ngIf="f.endHour.hasError('pattern')">
                        La Hora Final tiene <strong> formato incorrecto</strong>
                      </mat-error>
                  </mat-form-field>
              </div>
              </div>
          </div>
      </div>
  </section>

  <section class="filter mt-3">
      <div class="row">
          <div class="col-8 col-md-8 col-xs-12 ex4">
              <canvas id="heat-map"></canvas>
              <div class="flex-container" [style.display]="displayListStadistics">
                <div class="slider-width">
                  <ion-range-slider id="sliderElement"></ion-range-slider>
                </div>
              </div>
          </div>
          <div class="col-4 col-md-4 col-xs-12 ex4">
              <div class="col-12">
                  <mat-form-field class="w-100">
                      <mat-label>Cámaras</mat-label>
                      <mat-select (selectionChange)="changeCamera($event.value)" [(ngModel)]="cameraSelect">
                          <mat-option *ngFor="let camera of cameras" [value]="camera.cameraId">
                              {{camera.camera}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>

                  <div class="button-bot-padding " *ngFor="let alerType of alertTypes, let i = index">
                      <button [disabled]="cameraId == 0" [ngClass]="{'active': alertTypeId == alerType.id}" mat-raised-button class="color-primary w-100" (click)="showPicture(alerType.id)">{{alerType.name}}</button>
                  </div>
                  <div class="row">
                    <div class="col-6 col-xs-12 button-bot-padding">
                      <button mat-raised-button class="btn-color color-primary w-100" color="secundary"  type="button" (click)="searchAlerts()"  >Buscar</button>
                    </div>
                    <div class="col-6 col-xs-12 button-bot-padding">
                      <button [disabled]="alertTypeId == 0" (click)="donwloadPicture()" mat-raised-button color="primary" class="btn-color color-primary w-100">Descargar </button>
                    </div>
                  </div>
              </div>
          </div>

      </div>
  </section>
  <section class="filter mt-3">
      <div class="row">
          <div class="heat-container">
              <p class="heat-p">Mayor cantidad de alerta en un mismo punto</p>
              <div>
                  <img width=300 [src]="heatSpectrum" />
                  <div class="legends-color-heatspectrum">
                      <h5>10</h5>
                      <h5>5</h5>
                      <h5>0</h5>
                  </div>
              </div>
              <p class="heat-p">Menor cantidad de alerta en un mismo punto</p>
          </div>
      </div>
      <p *ngIf="lastDateUrl != ''" class="mt-3 text-center">Última imagen actualizada - {{lastDateUrl}}</p>
  </section>
  <section class="table-responsive mt-3">
      <div class="row" style="width: 100%;">
        <div class="col-6">
            <h5>Total de Alertas: {{length}}</h5>
        </div>
      </div>
      <mat-paginator #paginatorTop (page)="handlePaginatorBottom($event)" [pageSize]="12"  [hidePageSize]="true" ></mat-paginator>
      <table class="table" mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef> # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <ng-container matColumnDef="typeAlert">
          <th scope="col" class="text-center" *matHeaderCellDef> Tipo de Alarma </th>
          <td class="text-center" *matCellDef="let element"> {{element.typeAlert}} </td>
        </ng-container>

        <ng-container matColumnDef="insertDateOcurrence">
          <th scope="col" class="text-center" *matHeaderCellDef>Fecha de Registro</th>
          <td class="text-center" *matCellDef="let element"> {{element.insertDateOcurrence}} </td>
        </ng-container>

        <ng-container matColumnDef="userName">
          <th scope="col" class="text-center" *matHeaderCellDef> Usuario Atención </th>
          <td class="text-center" *matCellDef="let element"> {{element.userName}} </td>
        </ng-container>

        <ng-container matColumnDef="enabled">
          <th scope="col" class="text-center" *matHeaderCellDef>Estado</th>
          <td class="text-center" *matCellDef="let element"> {{element.enabled}} </td>
        </ng-container>

        <ng-container matColumnDef="updateCamera">
          <th scope="col" class="text-center" *matHeaderCellDef> Fecha Modificación</th>
          <td class="text-center" *matCellDef="let element"> {{element.updateCamera}} </td>
        </ng-container>

        <ng-container matColumnDef="insertDate">
          <th scope="col" class="text-center" *matHeaderCellDef> Fecha Atención</th>
          <td class="text-center" *matCellDef="let element"> {{element.insertDate}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <mat-paginator #paginatorBottom (page)="handlePaginatorTop($event)" [pageSize]="12" [hidePageSize]="true"></mat-paginator>
  </section>

</div>
