import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EnterpriseContractModalComponent } from '../../../../shared/components/modals/enterprise/enterprise-contract-modal/enterprise-contract-modal.component';
import { EnterpriseModalComponent } from '../../../../shared/components/modals/enterprise/enterprise-modal.component';
import { MessageModalComponent } from '../../../../shared/components/modals/message/message-modal.component';
import { AppConstants } from '../../../../shared/constants/app.contants';
import { AlertService, MessageSeverity } from '../../../../shared/services/alert.service';
import { EmitterService } from '../../../../shared/services/emitter.service';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseSettingModalComponent } from 'src/app/shared/components/modals/enterprise/enterprise-setting-modal/enterprise-setting-modal.component';
import { EnterpriseStreamingModalComponent } from 'src/app/shared/components/modals/enterprise/enterprise-streaming-modal/enterprise-streaming-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EnterpriseSystemModalComponent } from 'src/app/shared/components/modals/enterprise/enterprise-system-modal/enterprise-system-modal.component';
import { EnterpriseStorageModalComponent } from 'src/app/shared/components/modals/enterprise/enterprise-storage-modal/enterprise-storage-modal.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-enterprise',
    templateUrl: './enterprise.component.html',
    styleUrls: ['./enterprise.component.css']
})
export class EnterpriseComponent implements OnInit {

    dataSource = new MatTableDataSource();
    displayedColumns: string[] = ['index', 'ruc', 'businessName', 'address', 'enabled', 'action'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    subscription: Subscription;
    searchText: string = '';
    filterRadio: string = '2';
    isBusiness: boolean = false;
    pageEvent: PageEvent;
    pageIndex: number = 0;
    pageSize: number = AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION;
    length: number = 0;
    page: number = 1;
    color1: string;
    color2: string;
    enabledRead: number;
    enabledWrite: number;
    colorFont: string;
    colorFont2: string;
    timer:any;
		timeoutVal = 800;

    constructor(
      private spinner: NgxSpinnerService,
      public dialog: MatDialog,
      private enterpriseService: EnterpriseService,
      private emitterService: EmitterService,
      private router: Router,
      private alertService: AlertService
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'Administración > <span class="font-weight-bold">Empresas</span>';
    }

  ngOnInit() {
      this.setLS();
      this.isBusiness = +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGySComercial ? true : false;
      this.dataSource.paginator = this.paginator;
      this.paginator._intl.itemsPerPageLabel = '';
      this.getEnterprises();
      this.paginator._intl.itemsPerPageLabel = '';

      this.subscription = this.emitterService.getMessage().subscribe(message => {
        if (message == AppConstants.EmitterService.ListEnterprise) {
          this.getEnterprises();
        }
      });
    }

    setLS(){
      this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
      this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
      this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
      this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
      //validate Permisses menu
      var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
      var page = this.router.url.split("/");
      var i = page[page.length-1];
      var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
      if(itemTag.length>0){
        this.enabledRead = itemTag[0].activeRead;
        this.enabledWrite = itemTag[0].activeWrite;
      }else{
        this.enabledRead = 0;
        this.enabledWrite = 0;
      }
    }

    openAddModal() {
        let dialogRef = this.dialog.open(EnterpriseModalComponent, {
            data: {
              title: AppConstants.TitleModal.CREATE_ENTERPRISE ,
              operation: AppConstants.OperationType.ADD,
              color: this.color1,
              enabledWrite: this.enabledWrite,
              enabledRead: this.enabledRead,
              colorFont: this.color2
            },
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response.result == true) {
            this.page = 1;
            this.paginator.pageIndex = 0;
            this.getEnterprises();
          }
      });
    }

    openEditModal(obj) {
        let dialogRef = this.dialog.open(EnterpriseModalComponent, {
            data: {
              title: AppConstants.TitleModal.UPDATE_ENTERPRISE + " - " + obj.name,
              operation: AppConstants.OperationType.UPDATE,
              row : obj,
              color: this.color1,
              enabledWrite: this.enabledWrite,
              enabledRead: this.enabledRead,
              colorFont: this.color2
            },
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response.result == true) {
            this.getEnterprises();
          }
      });
    }

    openEditSettings(obj){
      let dialogRef = this.dialog.open(EnterpriseSettingModalComponent, {
        data: {
          operation: AppConstants.OperationType.UPDATE ,
          title: AppConstants.TitleModal.UPDATE_ENTERPRISE_SETTING + " - " + obj.name, row : obj,
          color: this.color1,
          enabledWrite: this.enabledWrite,
          enabledRead: this.enabledRead,
          colorFont: this.color2
       },
        width: '800px',
      });
    }

    openEditStreaming(obj){
      let dialogRef = this.dialog.open(EnterpriseStreamingModalComponent, {
        data: { row : obj , title: AppConstants.TitleModal.SETTING_STREAMING_ENTERPRISE + " - " + obj.name,
        operation: AppConstants.OperationType.UPDATE,
        color: this.color1,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        colorFont: this.color2
      },
        width: '800px',
      });
    }

    openEditSystem(obj){
      let dialogRef = this.dialog.open(EnterpriseSystemModalComponent, {
        data: { row : obj, title: AppConstants.TitleModal.SETTING_SYSTEM_ENTERPRISE + " - " + obj.name ,
        operation: AppConstants.OperationType.UPDATE,
        color: this.color1,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        colorFont: this.color2
      },
        width: '800px',
      });
    }

    openStorage(obj){
      let dialogRef = this.dialog.open(EnterpriseStorageModalComponent, {
        data: { row : obj , title: AppConstants.TitleModal.DIRECTORY_STORAGE_ENTERPRISE + " - " + obj.name,
        operation: AppConstants.OperationType.UPDATE,
        color: this.color1,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        colorFont: this.color2
      },
        width: '800px',
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response.result == true) {
          this.page = 1;
          this.paginator.pageIndex = 0;
          this.getEnterprises();
        }
      });
    }

    openContractModal(obj) {
        let dialogRef = this.dialog.open(EnterpriseContractModalComponent, {
            data: { title: this.isBusiness == true ? 'Visualizar Contrato' + " - " + obj.name : 'Registro Contrato' + " - " + obj.name, row : obj ,
            operation: AppConstants.OperationType.UPDATE,
            color: this.color1,
            enabledWrite: this.enabledWrite,
            enabledRead: this.enabledRead,
            colorFont: this.color2
          },
            width: '800px',
        });
    }

    delete(obj) {
        let dialogRef = this.dialog.open(MessageModalComponent, {
            data: {
                title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
                message: `${AppConstants.MessageModal.DELETE_ENTERPRISE_CONFIRMATION_MESSAGE1}  ${obj.name} ? ${AppConstants.MessageModal.DELETE_ENTERPRISE_CONFIRMATION_MESSAGE2}`,
                hasButton: true,
                contentButton: {
                    yes: 'SI',
                    no: 'CANCELAR'
                }
            },
            width: '600px',
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response.result == true) {
              this.deleteEnterprise(obj.id);
            }
        });
    }

  getEnterprises() {
    this.dataSource.data = [];
    this.spinner.show();
    var filter = {
      Page: this.page,
      Rows: AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION,
      Filter: this.searchText
    }
    this.enterpriseService.getList(filter).subscribe((_) => {
      if(!_.error){
        this.dataSource = new MatTableDataSource(_["rows"]);
        this.pageIndex = +_["page"]-1;
        this.length = _["total"];
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        console.log("err enterprise",error);
        this.spinner.hide();
    });
  }

  deleteEnterprise(enterpriseId: number) {
    this.spinner.show();
    this.enterpriseService.deleteEnterprise(enterpriseId).subscribe((response => {
      if(response.code == 200){
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_ENTERPRISE, AppConstants.MessageModal.DELETE, MessageSeverity.success);
        this.page = 1;
        this.paginator.pageIndex = 0;
        this.getEnterprises();
        this.spinner.hide();
      }else{
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_ENTERPRISE, response.message, MessageSeverity.error);
        this.spinner.hide();
      }
    }),
      (err: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_ENTERPRISE, err.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  getServerData(event?: PageEvent) {
    this.page = event.pageIndex + 1;
    this.getEnterprises();
  }

  applyUpFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchText = filterValue.trim().toLowerCase();
      var _this = this;
      window.clearTimeout(_this.timer);
        _this.timer = window.setTimeout(() => {
            _this.page = 1;
            _this.paginator.pageIndex = 0;
            _this.getEnterprises();
          }, _this.timeoutVal);
  }

  applyPressFilter(event: Event) {
    var _this = this;
    window.clearTimeout(_this.timer);
  }
}
