import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { StreamingStadisticsService } from 'src/app/services/streamingStadistics/streamingStadistics.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { CameraService } from '../../../../services/camera/camera.service';
import { MessageSeverity, AlertService } from 'src/app/shared/services/alert.service';
import * as FileSaver from 'file-saver';
import { sum } from 'lodash';
import { StreamingStadisticsModel } from 'src/app/models/streamingStadistics/streamingStadistics';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/helpers/format-datepicker';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-streaming-stadistics',
  templateUrl: './streaming-stadistics.component.html',
  styleUrls: ['./streaming-stadistics.component.css']
})
export class StreamingStadisticsComponent implements OnInit {
  dataSource = new MatTableDataSource();
  dataSources = new MatTableDataSource();
  displayedColumns: string[] = ['index','enterprise', 'camera',  'createdAt', 'ip', 'pc_movil', 'browser', 'origin', 'originDomain', 'userAgent'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('chart', { static: false }) canvas: ElementRef;
  @ViewChild('chart2', { static: false }) canvas2: ElementRef;
  @ViewChild('chart3', { static: false }) canvas3: ElementRef;
  @ViewChild('chart4', { static: false }) canvas4: ElementRef;

  enterpriseSelect: number = 0;
  cameraSelect = "TODOS";
  length: number = 0;
  isAdministratorStreaming=false;
  reportForm: FormGroup;
  camerasSelect = new FormControl();
  myDate = new Date();
  pageEvent: PageEvent;
  pageSize: number = 12;
  cameras = [];
  enterprises = [];
  date = new Date();
  dateF = new Date();
  timeI: string;
  timeF: string;
  dateV:string;
  showData = false;
  showPaginator = true;
  displayListStadistics = 'block';
  dateIView:string;
  dateFView:string;
  dataStreamingStadistics: Array<StreamingStadisticsModel>;
  headquarterSelect = 0;
  headquarters = [];
  dropdownSettings:IDropdownSettings;
  selectedItems = [];
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  showGraph = false;

  constructor(public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private enterpriseService: EnterpriseService,
    private datePipe: DatePipe,
    private AlertService: AlertService,
    private headquarterService: HeadquarterService,
    private CameraService: CameraService,
    private streamingStadisticsService: StreamingStadisticsService,
    private formBuilder: FormBuilder) {
      document.getElementById("titleHeaderNav").innerHTML = 'Streaming > <span class="font-weight-bold">Estadísticas</span>';
      this.camerasSelect.setValue([0]);
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'cameraId',
        textField: 'camera',
        tooltipField: 'Cámara',
        selectAllText: 'Todos',
        unSelectAllText: 'Limpiar',
        searchPlaceholderText: 'Buscar',
        noFilteredDataAvailablePlaceholderText: 'Item no encontrado',
        itemsShowLimit: 3,
        allowSearchFilter: false, //input search
      };/*
      Chart.register(ChartDataLabels);
      Chart.defaults.set('plugins.datalabels', {
        color: 'black'
      });*/
  }

  ngOnInit() {
    this.setTheme();
    if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.AdministradorEmpresaStreaming) this.isAdministratorStreaming = true;
    this.createForm();
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.cameraList();
    this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
    this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
    this.dateV = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    //this.setData();
  }

  setTheme(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
  }

  _keyUp(event: any) {
    let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode != 58 && charCode > 31
        && (charCode < 48 || charCode > 57)) {
        return false;
      }
  }

  createForm() {
    this.reportForm = this.formBuilder.group({
        startDateReport: ['', [Validators.required]],
        endDateReport: ['', [Validators.required]],
        startTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
        endTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
        idEnterprise: ['0', [Validators.required]],
        idHeadquarter: ['0', [Validators.required]],
       // cameraReport: [[], [Validators.required]],
        idCameras:[],
        showGraph: [false]
    });
  }

  get f() { return this.reportForm.controls; }

  checkGraph(refreshData){
    if(!refreshData){
      this.showPaginator = !this.showPaginator;
      this.reportForm.controls["showGraph"].setValue(this.reportForm.value["showGraph"] == false ? true:false);
    }
     
    if(this.reportForm.value["showGraph"]){ 
      this.showData = true;
      /*if (this.canvas) {
        this.canvas.nativeElement.destroy(); // Destruye el gráfico existente si ya está creado
      }*/
      //this.displayListStadistics = 'block';
      //var chartExist = Chart.getChart("myChart123");
      /*var chartExist2 = Chart.getChart("myChart2");
      var chartExist3 = Chart.getChart("myChart3");
      var chartExist4 = Chart.getChart("myChart4");
      if (chartExist != undefined){
        chartExist.destroy();
        /*chartExist2.destroy();
        chartExist3.destroy();
        chartExist4.destroy();*/
      // }*/
    //}

   // if(this.reportForm.get("showGraph").value){
      this.displayListStadistics = 'none';
      if(!refreshData){this.displayListStadistics = 'none';}
      this.loadChartCamerasByVisit();
      this.loadPiePCMobile();
      this.loadChartTypesVisit();
      this.loadLineVisitByMonth();
    }else{
      this.showData = false;
      this.displayListStadistics = 'block';
    }
  }

  getHeadquarters() {
    // if (this.isAdminGys) {
       //this.spinner.show();
       this.headquarterService.getListAll().subscribe((response) => {
         if(response.error == null){
           this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
          // this.headquarterSelect = 0;
         }else{
           this.headquarters = [];
         }
         this.cameraList();
         //this.spinner.hide();
       },
         (error: HttpErrorResponse) => {
           this.headquarters = [];
           this.spinner.hide();
         });
     //} else {
      // this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
     //}
   }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
    this.cameraList();
  }

  cameraList() {
    this.cameras = [];
    this.CameraService.GetCamerasIdEnterprise(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
      setTimeout(()=> { this.cameras = response; },1000);
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  getEnterprises() {
    this.spinner.show();
    this.enterpriseService.getEnterprises().subscribe((response) => {
      if(response != null){
        this.enterprises = response;
        this.enterpriseSelect = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE));
        this.getHeadquarters();
      }
      this.spinner.hide();
    },
    (error: HttpErrorResponse) => {
        this.spinner.hide();
    });
  }

  changeEnterprise(data) {
    this.enterpriseSelect = data.value;
    this.getHeadquarters();
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  setFilter(){
    /*let search = this.reportForm.value;
    try{
      this.dateIView = search.startDateReport;
      this.dateFView = search.endDateReport;
    }catch{
      this.dateIView = search.startDateReport;
      this.dateFView = search.endDateReport;

      search.startDateReport = search.startDateReport;
      search.endDateReport = search.endDateReport;
    }*/

    var idCameras = "";
    this.selectedItems.forEach(x => {
      idCameras = idCameras+","+ x.cameraId;
    });


    if(idCameras == ""){
      this.AlertService.showMessage(
        AppConstants.MessageModal.FILTER_CAMERA_VALIDATE,"",
        MessageSeverity.error);
      return false;
    }

    if(this.date > this.dateF){
      this.AlertService.showMessage(
        AppConstants.MessageModal.FILTER_DATES_VALIDATE,"",
        MessageSeverity.error);
      return false
    }

    var filterS = {
      dateI: this.reportForm.value["startDateReport"],
      dateF: this.reportForm.value["endDateReport"],
      timeI: this.timeI,
      timeF: this.timeF,
      idCamera: idCameras.replace(idCameras.charAt(0), ""),
      idEnterprise: this.enterpriseSelect,
    };
    filterS.dateI = this.datePipe.transform(filterS.dateI, 'yyyy-MM-dd');
    filterS.dateF = this.datePipe.transform(filterS.dateF, 'yyyy-MM-dd');
    return filterS;
  }

  setData(){
    this.spinner.show();
    this.streamingStadisticsService.getList(this.setFilter()).subscribe((response) => {
      if(response.error == null){
        if(response.data.length > 0){
          this.dataSource.data = response.data;
          this.paginator.pageIndex = 0;
        }else{
          this.dataSource.data = response.data;
          this.AlertService.showMessage(
            AppConstants.TitleModal.WARNING_TITLE,
            AppConstants.MessageModal.DATA_NULL_SEARCH,
            MessageSeverity.error);
        }
        this.dataStreamingStadistics = response.data;
        this.length = response.data.length;
        this.checkGraph(1);
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  exportExcel(){
    this.streamingStadisticsService.downloadExportExcel(this.setFilter()).subscribe((response) => {
      const data: Blob = new Blob([response], {
        type: response.type
      });
      FileSaver.saveAs(data, 'archivo');
    });
  }

  loadChartCamerasByVisit(){
    /*var chartExist = Chart.getChart("myChart123"); // <canvas> id
    if (chartExist != undefined)
      chartExist.destroy();*/

    var arrayCameras = [];
    if(this.dataStreamingStadistics.length>0){
      this.dataStreamingStadistics.forEach(element => {
        if(element.camera == null || element.camera == "" || !element.camera){ element.camera = "vista múltiple"; }
        arrayCameras.push(element.camera);
      });
    }
    var dataCameras = this.find_duplicate_in_array(arrayCameras);
    var arrayColors = [];
    var labels = Object.keys(dataCameras);
    if(dataCameras){
      labels.forEach(element => {
        arrayColors.push(this.random_rgb());
      });
    }

    var data = {
        labels: labels,
        datasets: [{
          data: Object.values(dataCameras),
          backgroundColor: arrayColors,
          borderColor: arrayColors,
          borderWidth: 1
        }]
      };

    return new Chart(this.canvas.nativeElement, {
      type: 'bar',
      data: data,
      options: {
        //indexAxis: 'y',
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: 'Cámaras / Cantidad de Visitas'
          },
          tooltip: {
            enabled: true,
            usePointStyle: true
          },
          datalabels: {
            display: true,
            color: 'white'
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 15,
            bottom: 0
          }
        },
        scales: {
          xAxes: [{
            ticks: {stepSize:10, beginAtZero: true} 
          }],
          yAxes: [{
            ticks: {
              autoSkip: false
            }
          }]
        },
        /*scales: {
          xAxes: {  beginAtZero: true,ticks: {stepSize:10} },
          y: { ticks: {autoSkip: false} }
        },*/
        events: [],
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  loadPiePCMobile(){
    var arrayPCMobile = [];
    if(this.dataStreamingStadistics.length>0){
      this.dataStreamingStadistics.forEach(element => {
        arrayPCMobile.push(element.pc_movil);
      });
    }

    var dataReport = this.find_duplicate_in_array(arrayPCMobile);

    /*var chartExist = Chart.getChart("myChart2"); // <canvas> id
    if (chartExist != undefined)
      chartExist.destroy();*/
    var data = {
        datasets: [{
            data: Object.values(dataReport),
            backgroundColor: [
                "#FF6384",
                "#4BC0C0",
                "#89aeeb"
            ],
            label: 'My dataset' // for legend
        }],
        labels: Object.keys(dataReport)
    };

    return new Chart(this.canvas2.nativeElement, {
      type: 'doughnut',
      data: data,
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          },
          title: {
            display: true,
            text: '% Uso PC / Móvil'
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            formatter: (value, ctx) => {
              let dataArr = ctx.chart.data.datasets[0].data;
              let total = sum(dataArr);     // sum from lodash
              let percentage = (value * 100 / total).toFixed(2) + "%";
              return percentage;
            },
            color: 'white',
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });
  }

  loadLineVisitByMonth(){

    var arrayVisitTime = [];
    if(this.dataStreamingStadistics.length>0){
      this.dataStreamingStadistics.forEach(element => {
        var dates =element.createdAt.split(" ");
        var date = dates[0].split("/");
        arrayVisitTime.push(date[1]+" "+date[2]);
      }); //
    }
    arrayVisitTime = arrayVisitTime.sort(function(a, b){return b.trim() - a.trim()});
    arrayVisitTime = arrayVisitTime.reverse();

    var dataReport = this.find_duplicate_in_array(arrayVisitTime);
    var arrayLabels = [];
    Object.keys(dataReport).forEach(_ => {
      var d = _.split(" ");
      var monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];
      var dd  = monthNames[(parseInt(d[0])-1)] + " "+ d[1];
      arrayLabels.push(dd);
    });
    /*/var chartExist = Chart.getChart("myChart3");
    if (chartExist != undefined)
      chartExist.destroy();*/
    var data = {
        labels: arrayLabels,
        datasets: [{
          data: Object.values(dataReport),
          fill: false,
          borderColor: 'rgb(218, 41, 28)',
          tension: 0.1
        }]
      };
    return new Chart(this.canvas3.nativeElement, {
      type: 'line',
      data: data,
      options: {
        scales: {
          xAxes: [{
            ticks: {autoSkip: false, maxRotation: 75,minRotation: 75} 
          }]
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: 'Visitas por Mes y Año'
          },
          tooltip: {
            enabled: false,
            usePointStyle: false
          },
          datalabels: {
            display: false,
          }
        },
        events: [],
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          onComplete: function({ chart }) {
            const ctx = chart.ctx;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            chart.config.data.datasets.forEach(function(dataset, i) {
              const meta = chart.getDatasetMeta(i);

              meta.data.forEach(function(bar, index) {
                if (dataset.data[index] > 0) {
                  var data = dataset.data[index];
                  ctx.fillText(data.toString(), bar.x, bar.y - 7);
                }
              });
            });
          }
        }
      }
    });
  }

  loadChartTypesVisit(){

    var arrayTypesVisit = [];
    if(this.dataStreamingStadistics.length>0){
      this.dataStreamingStadistics.forEach(element => {
        arrayTypesVisit.push(element.origin);
      });
    }

    var dataTypes = this.find_duplicate_in_array(arrayTypesVisit);
    var arrayColors = ['#374394','#3e8b23','#bc4004'];
    var labels = Object.keys(dataTypes);
    /*var chartExist = Chart.getChart("myChart4");
    if (chartExist != undefined)
      chartExist.destroy();*/
    var data = {
        labels: labels,
        datasets: [{
          data: Object.values(dataTypes),
          backgroundColor: arrayColors,
          borderColor: arrayColors,
          borderWidth: 1
        }]
      };
    return new Chart(this.canvas4.nativeElement, {
      type: 'bar',
      data: data,
      options: {
        //indexAxis: 'x',
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: 'Tipo de Visita / Cantidad de Visitas'
          },
          tooltip: {
            enabled: false,
            usePointStyle: false
          },
          datalabels: {
            color: 'white',
          }
        },
        scales: {
          xAxes: [{
            ticks: {stepSize:10, beginAtZero: true} 
          }]
        },
        events: [],
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  find_duplicate_in_array(array){
    const count = {}
    const result = []

    array.forEach(item => {
        if (count[item]) {
           count[item] +=1
           return
        }
        count[item] = 1
    })

    for (let prop in count){
        if (count[prop] >=2){
            result.push(prop)
        }
    }

    return count;

    }

    random_rgb() {
      const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
      const r = randomBetween(0, 255);
      const g = randomBetween(0, 255);
      const b = randomBetween(0, 255);
      return `rgb(${r},${g},${b})`;
    }

}
