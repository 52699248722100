<div class="row title-modal">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
        <mat-icon color="primary" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="text-right">
                    <button type="button" (click)="addModalPin()" mat-raised-button color="primary" class="color-white mb-1">Agregar Pin</button>
                </div>

                <div class="table-responsive">
                    <table class="table" mat-table [dataSource]="dataSource">
                    
                        <ng-container matColumnDef="headquarter" *matHeaderCellDef  [style.background]="color1">
                            <th scope="col" class="text-center" *matHeaderCellDef> Sede </th>
                            <td class="text-center" *matCellDef="let element2"> {{element2.headquarter}} </td>
                        </ng-container>
                    <ng-container matColumnDef="name">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1"> Pin </th>
                        <td class="text-center" *matCellDef="let element2"> {{element2.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1"> Acción </th>
                        <td class="text-center" *matCellDef="let element2">
                            <mat-icon color="primary" class="icon-cursor" (click)="showImage(element2)">remove_red_eye</mat-icon>
                            <mat-icon color="primary" class="icon-cursor" (click)="deletePinMap(element2)">delete</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>