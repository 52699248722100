<div class="row title-modal"  [style.color]="data.color" [style.borderColor]="data.color" >
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
    <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
      <form [formGroup]="dashboardForm" >
         <!-- <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Nombre del Widget*</mat-label>
                    <input matInput formControlName="name" >
                    <mat-error *ngIf="f.name.errors?.minlength">
                      El nombre no puede ser menor a 1 caracteres.
                    </mat-error>
                    <mat-error *ngIf="f.name.hasError('required')">
                        El nombre <strong>requerida</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.name.errors?.maxlength">
                      El nombre no puede ser mayor a 100 caracteres.
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-tab-group style="align-items: center !important;">
                  <mat-tab label="First">
                    IOT
                  </mat-tab>
                  <mat-tab label="Second">
                    Video analítica
                  </mat-tab>
                  <mat-tab label="Third">
                    Streaming
                  </mat-tab>
                </mat-tab-group>
              </div>
          </div>-->
          <div class="row mt-2 content-types">
            <div class="col-lg-4">
              <div class="listTypesW">
                <li *ngFor="let item of widgetTypes" class="item-widget-type" (click)="changeWidgetType(item.id)" >
                  <span><mat-icon [ngStyle]="{'color': selectWidgetType == item.id ? data.color : ''}" class="icon-display">{{item.icon}}</mat-icon></span>
                  <span [ngStyle]="{'color': selectWidgetType == item.id ? data.color : ''}">{{item.name}}</span>
                </li>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="row" *ngIf="selectWidgetType > 0">
                <div class="col-lg-3">
                  <mat-form-field class="w-100">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="name"  maxlength="100">
                    <mat-error *ngIf="f.name.hasError('required') || f.name.hasError('whitespace')">
                        El nombre es <strong>requerido</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.name.hasError('maxlength')">
                        El nombre es <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-3" *ngIf="selectWidgetType == 1" >
                  <mat-form-field class="w-100">
                    <mat-label>Eje X (horas)</mat-label>
                    <mat-select formControlName="hourX">
                      <mat-option *ngFor="let z of hours" [value]="z">
                        {{z}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-3"  *ngIf="selectWidgetType == 3" >
                  <mat-form-field class="w-100">
                    <mat-label>Sensor</mat-label>
                    <mat-select formControlName="sensor">
                      <mat-option *ngFor="let sensor of sensors" [value]="sensor.id">
                        {{sensor.nomenclature}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-3"  *ngIf="selectWidgetType == 3" >
                  <mat-form-field class="w-100">
                    <mat-label>Valor de Intervalo</mat-label>
                    <input matInput formControlName="valueInterval" >
                  </mat-form-field>
                </div>
              </div>

                <div *ngIf="selectWidgetType == 1 || selectWidgetType == 2"> <!-- bar / lineal -->

                  <div class="row">
                    <div class="col-lg-3">
                      <mat-form-field class="w-100">
                        <mat-label>Nombre Y</mat-label>
                        <input matInput formControlName="nameY1"  maxlength="100">
                        <mat-error *ngIf="f.nameY1.hasError('required') || f.nameY1.hasError('whitespace')">
                            El nombre es <strong>requerido</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.nameY1.hasError('maxlength')">
                            El nombre es <strong>requerido</strong>.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <mat-form-field class="w-100">
                        <mat-label>El min de Y</mat-label>
                        <mat-select formControlName="minY1">
                          <mat-option [value]="0">Auto</mat-option>
                          <mat-option *ngFor="let z of hours" [value]="z">
                            {{z}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.minY1.hasError('required')">
                          El min de Y <strong>requerido</strong>.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <mat-form-field class="w-100">
                        <mat-label>El max de Y</mat-label>
                        <mat-select formControlName="maxY1">
                          <mat-option [value]="0">Auto</mat-option>
                          <mat-option *ngFor="let z of hours" [value]="z">
                            {{z}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.maxY1.hasError('required')">
                          El max de Y <strong>requerido</strong>.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <mat-form-field class="w-100">
                        <mat-label>Unidad de Y</mat-label>
                        <input matInput formControlName="undY1"  maxlength="30">
                        <mat-error *ngIf="f.undY1.hasError('required')">
                          La Unidad de Y es <strong>requerido</strong>.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="mt-3" *ngIf="selectWidgetType == 1" > <!-- lineal -->
                      <div class="row mb-1">
                        <div class="col-lg-3 text-center">Sensor</div>
                        <div class="col-lg-3 text-center">Color</div>
                        <div class="col-lg-3" ></div>
                      </div>
                      <div *ngIf="sensorsData.length > 0">
                        <div  class="row mt-1" *ngFor="let item of sensorsData" >
                          <div class="col-lg-3 col-xs-12">
                            <fieldset class="form-group" >
                              <select class="control-form" (change)="selectSensor($event.target.value,item.pos)" >
                                <option value="0">Seleccione sensor</option>
                                <option *ngFor="let sensor of sensors" [value]="sensor.id" [selected]="item.sensor == sensor.id?true:false" >
                                  {{sensor.nomenclature}}
                                </option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="col-lg-3 col-xs-12 text-center">
                            <fieldset class="w-100">
                              <input type="color" class="control-form-color" (blur)="changeColor($event.target.value,item.pos)" value="{{item.color}}">
                            </fieldset>
                          </div>
                          <div class="col-lg-3 col-xs-12">
                            <mat-icon *ngIf="sensorsData.length == (item.pos + 1)" [ngStyle]="{'color': data.color}" (click)="addItem(item)" class="add-sensor" >add_circle_outline</mat-icon>
                            <mat-icon *ngIf="item.pos > 0 || sensorsData.length > 1"  (click)="removeItem(item)" class="add-sensor" >remove_circle_outline</mat-icon>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div  class="mt-3" *ngIf="selectWidgetType == 2" > <!-- Gráfico Barra -->
                    <div class="row mb-1">
                      <div class="col-lg-3 text-center">Nombre</div>
                      <div class="col-lg-3 text-center">Sensor</div>
                      <div class="col-lg-3 text-center">Color</div>
                      <div class="col-lg-3" ></div>
                    </div>
                    <div *ngIf="sensorsData.length > 0">
                      <div  class="row mt-1" *ngFor="let item of sensorsData" >
                        <div class="col-lg-3 col-xs-12 text-center">
                          <fieldset class="w-80">
                            <input type="text" class="control-form" style="width: 100%;" value="{{item.name}}" (input)="onSearchChangeInput($event.target.value,item.pos)" >
                          </fieldset>
                        </div>
                        <div class="col-lg-3 col-xs-12 text-center">
                          <fieldset class="form-group" >
                            <select class="control-form" (change)="selectSensor($event.target.value,item.pos)" >
                              <option value="0">Seleccione sensor</option>
                              <option *ngFor="let sensor of sensors" [value]="sensor.id" [selected]="item.sensor == sensor.id?true:false" >
                                {{sensor.nomenclature}}
                              </option>
                            </select>
                          </fieldset>
                        </div>
                        <div class="col-lg-3 col-xs-12 text-center">
                          <fieldset class="w-100">
                            <input type="color" class="control-form-color"  (blur)="changeColor($event.target.value,item.pos)" value="{{item.color}}">
                          </fieldset>
                        </div>
                        <div class="col-lg-3 col-xs-12">
                          <mat-icon *ngIf="sensorsData.length == (item.pos + 1)" [ngStyle]="{'color': data.color}" (click)="addItem(item)" class="add-sensor" >add_circle_outline</mat-icon>
                          <mat-icon *ngIf="item.pos > 0 || sensorsData.length > 1"  (click)="removeItem(item)" class="add-sensor" >remove_circle_outline</mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="selectWidgetType == 3">  <!-- gauge -->
                  <div class="row mb-1">
                    <div class="col-lg-3 text-right" >Valor Inicio</div>
                    <div class="col-lg-3 text-right" >Valor Fin</div>
                    <div class="col-lg-3 text-center" >Color</div>
                    <div class="col-lg-3" ></div>
                  </div>
                      <div *ngIf="sensorsData.length > 0">
                        <div class="row mt-1" *ngFor="let item of sensorsData" >
                          <div class="col-lg-3 col-xs-12">
                            <fieldset class="w-100">
                              <input type="number" class="control-form text-right" step="0.01" onkeypress="return onlyNumberKey(event)" (input)="onSearchChangeInputValueStart($event.target.value,item.pos)"  value="{{item.valueStart}}">
                            </fieldset>
                          </div>
                          <div class="col-lg-3 col-xs-12">
                            <fieldset class="w-100">
                              <input type="number" class="control-form text-right"  step="0.01" onkeypress="return onlyNumberKey(event)" (input)="onSearchChangeInputValueEnd($event.target.value,item.pos)"  value="{{item.valueEnd}}">
                            </fieldset>
                          </div>
                          <div class="col-lg-3 col-xs-12 text-center">
                            <fieldset class="w-100">
                              <input type="color" class="control-form-color" (blur)="changeColor($event.target.value,item.pos)" value="{{item.color}}">
                            </fieldset>
                          </div>
                          <div class="col-lg-3 col-xs-12">
                            <mat-icon *ngIf="sensorsData.length == (item.pos + 1)" [ngStyle]="{'color': data.color}" (click)="addItem(item)" class="add-sensor" >add_circle_outline</mat-icon>
                            <mat-icon *ngIf="item.pos > 0 || sensorsData.length > 1"  (click)="removeItem(item)" class="add-sensor" >remove_circle_outline</mat-icon>
                          </div>
                        </div>
                      </div>
                </div>
            </div>
          </div>
      </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button  [style.background]="data.color" [style.color]="data.colorFont" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
