import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user/user.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  hide = true;
  repeatHide = true;
  userForm: FormGroup
  headquarterSelect = 0;
  headquarters = [];
  showEdit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private alertService: AlertService,
  ) {
    document.getElementById("titleHeaderNav").innerHTML = '<span class="font-weight-bold">Mi Perfil</span>';
  }

  ngOnInit() {
    this.createForm()
    this.setValues();
  }

  createForm() {
    this.userForm = this.formBuilder.group({
        // documentNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
        email: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
        names: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
        lastName: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
        role: ['', [Validators.required]],
        password: ['', [Validators.minLength(6)]],
        confirmPassword: ['', [Validators.minLength(6)]],
        status: [true, [Validators.required]],
        sendEmail: [true, [Validators.required]],
        idHeadquarter: ['', []],
        idUser: [localStorage.getItem(AppConstants.Session.USERID)]
        // locked: [false],
    });
  }

  passwordMatcher(): boolean {
    const password = this.userForm.get('password');
    const confirmPassword = this.userForm.get('confirmPassword');

    if (password.value === confirmPassword.value) {
      return true;
    }
    return false ;
  }

  get f() { return this.userForm.controls; }

  setValues() {
      this.spinner.show();
      this.userService.getUserById(parseInt(localStorage.getItem(AppConstants.Session.USERID))).subscribe((response => {
        this.userForm.patchValue({
          // documentNumber: this.data.row.dni,
          email: response.username,
          names: response.names,
          lastName: response.lasName,
          // password: '******',
          // confirmPassword: '******',
          status: response.enabled,
          sendEmail: response.sendEmail,
          idHeadquarter: response.idHeadquarter
          // locked: response.locked
        });
        this.headquarterSelect = !response.idHeadquarter ? 0 : response.idHeadquarter;
        this.spinner.hide();
      }),
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  save(){

    if(!this.passwordMatcher()){
      this.alertService.showMessage("", "No coincide la contraseña con la contraseña de confirmación", MessageSeverity.error);
      return false;
    }

    this.spinner.show();
    this.userService.updateUserMyProfile(this.userForm.value).subscribe((response => {
      this.alertService.showMessage(AppConstants.TitleModal.UPDATE_USER, AppConstants.MessageModal.UPDATE_USER, MessageSeverity.success);
      localStorage.setItem(AppConstants.Session.USER,this.userForm.get('names').value+ " "+this.userForm.get('lastName').value);
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_USER, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

}
