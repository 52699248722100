<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
    </div> 
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="gatewayForm" [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="row">
                <div class="col-12" *ngIf="edit" >
                    <mat-form-field class="w-100">
                        <mat-label>Sede*</mat-label>
                        <mat-select
                            formControlName="idHeadquarter"
                            (selectionChange)="changeHeadquarter($event)"
                            [(ngModel)]="headquarterSelect">
                            <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                                {{headquarter.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.idHeadquarter.hasError('required')">
                          La sede es <strong>requerida</strong>.
                      </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Nombre*</mat-label>
                        <input matInput formControlName="name" maxlength="50">
                        <mat-error *ngIf="f.name.hasError('required')">
                            El Nombre es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Descripción</mat-label>
                        <input matInput formControlName="description" maxlength="50">
                    </mat-form-field>
                </div>
                <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Nomenclatura*</mat-label>
                    <input matInput formControlName="nomenclature" >
                    <mat-error *ngIf="f.nomenclature.errors?.minlength">
                      La nomenclatura no puede ser menor a 2 caracteres.
                    </mat-error>
                    <mat-error *ngIf="f.nomenclature.hasError('required')">
                        La nomenclatura <strong>requerida</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.nomenclature.errors?.maxlength">
                      La nomenclatura no puede ser mayor a 30 caracteres.
                    </mat-error>
                </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>IP*</mat-label>
                        <input matInput formControlName="ip" >
                        <mat-error *ngIf="f.ip.hasError('required')">
                            La IP <strong>requerida</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.ip.errors?.pattern || f.ip.hasError('maxlength') || f.ip.hasError('minlength')">
                            La ip tiene formato <strong>inválido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>MAC*</mat-label>
                        <input matInput formControlName="mac" >
                        <mat-error *ngIf="f.mac.hasError('required') || f.mac.hasError('whitespace')">
                          La MAC es <strong>requerida</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.mac.errors?.pattern">
                          La MAC tiene formato <strong>inválido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCELAR</button>
  <button mat-raised-button [style.background]="data.color"  [style.color]="data.colorFont" *ngIf="data.enabledWrite" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
