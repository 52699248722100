import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { GatewayService } from 'src/app/services/gateway/gateway.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import { SensorService } from 'src/app/services/sensor/sensor.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { PanelSensorModalComponent } from 'src/app/shared/components/modals/panel-sensor-modal/panel-sensor-modal.component';
import { SensorModalComponent } from 'src/app/shared/components/modals/sensor/sensor-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { environment } from 'src/environments/environment';
import 'fabric';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
declare const fabric: any;
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Router } from '@angular/router';
import { ActuatorDetailPanelModalComponent } from 'src/app/shared/components/modals/actuator-detail-panel-modal/actuator-detail-panel-modal.component';
import { ActuatorService } from 'src/app/services/actuator/actuator.service';

@Component({
  selector: 'app-panel-iot',
  templateUrl: './panel-iot.component.html',
  styleUrls: ['./panel-iot.component.css']
})
export class PanelIotComponent implements OnInit {
  loading: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['enterprise','headquarter','gateway','name','description','nomenclature','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isAdminGys: boolean = false;
  countMaxUserByEnterprise: number = 0;
  enterprises = [];
  headquarters = [];
  gateways = [];
  subscription: Subscription;
  enterpriseSelect: number = 0;
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION;
  length: number = 0;
  page: number = 1;
  headquarterSelect: number = 0;
  gatewaySelect: number = 0;
  zones = [];
  zoneSelect: number = 0;
  canvas: any;
  nameMarker: string = '';
  sensors = [];
  showSensorEdit = false;
  showGraphSensor = false;
  showSensorsGraphs = false;
  showSensorsTable:boolean = true;
  displayListStadistics = 'block';
  dataStreaming = [];
  colorToggle = "";
  initPage = false;
  private _hubConnection: HubConnection;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  guardTotal: number = 0;
  guardTotalTQ: number = 0;
  contentTotal: number = 0;
  isGuardTotal = false;
  enabledRead: number;
  enabledWrite: number;
  editSetting = false;

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private enterpriseService: EnterpriseService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private actuatorService: ActuatorService,
    private headquarterService: HeadquarterService,
    private gatewayService: GatewayService,
    private panelIOTService: PanelIOTService,
    private zoneService: ZoneService
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'IOT > <span class="font-weight-bold">Panel de Actuadores y Sensores</span>';
      /*var _this = this;
      const connection =  this._hubConnection = new HubConnectionBuilder()
        .withUrl(environment.signalR +"/api")
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      connection.on('eventHubIOT', (message) => {
        if(_this.showSensorsTable){
            var data = message.data;
            console.log("data eventHubIOT",data);
            _this.dataStreaming = this.panelIOTService.getDataStreamingSensors();
            _this.dataStreaming.forEach(g => {
              if (data[g.nomenclature] != null) {
                g.value = data[g.nomenclature];
              }
              _this.panelIOTService.setDataStreamingSensors(_this.dataStreaming);
              _this.removeValueSensors();
              _this.createValueSensor();
            });
        }
      });

      connection.start()
        .catch(console.error);*/
        var _this = this;
        setInterval(()=>{
          console.log('update',_this.panelIOTService.getUpdatePanelIOT());
          if(_this.panelIOTService.getUpdatePanelIOT()){
            _this.dataStreaming = this.panelIOTService.getDataStreamingSensors();
            _this.dataStreaming.forEach(g => {
              //_this.panelIOTService.setDataStreamingSensors(_this.dataStreaming);
              _this.removeValueSensors();
              _this.getList();
             // _this.createValueSensor();
              _this.panelIOTService.setUpdatePanelIOT(0);
            });
          }
        },1000);
   }

   offEdit(){
    this.editSetting = this.editSetting == true ? false : true;
    if(this.editSetting){
      this.showGraph();
    }
   }

   setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  ngOnInit() {
    this.setLS();
    this.panelIOTService.setDataStreamingSensors([]);
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.getGuardTotalTQ();
    this.getZones();
    this.getCanvas();
    var _this = this;
   /*setInterval(function () {
      if(_this.showSensorsTable){
        _this.dataStreaming = _this.panelIOTService.getDataStreamingSensors();
        _this.removeValueSensors();
        _this.createValueSensor();
      }
    }, 1000);*/
  }

  setTheme(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
  }

  getGuardTotalTQ(){
    this.actuatorService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
      var actuators = response.data.filter(x => x.idActuatorType == 1);
      var totalguards = 0;
      actuators.forEach(x => {
        totalguards = totalguards + x.minCapacity;
      });
      this.guardTotalTQ = totalguards;
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  showTable(event: MatSlideToggleChange){
    this.initPage = true;
    this.showSensorsTable = event.checked;
    event.checked = this.showSensorsTable ? false : true;
    if(!event.checked){
      this.createValueSensor();
    }else{
      this.removeValueSensors();
    }
  }

  showView(event: MatSlideToggleChange){
    this.initPage = true;
    if(event.checked){
      this.displayListStadistics = 'none';
    }else{
      this.displayListStadistics = 'block';
    }
  }

  getCanvas() {
    this.canvas = new fabric.Canvas('canvas',{
      selection: true,
      allowTouchScrolling: true,
      enableRetinaScaling: true
    });
  }

  changeEnterprise(data) {
    this.countMaxUserByEnterprise = this.enterprises.find(f => f.enterpriseId == data.value).maximumUsers;
    this.enterpriseSelect = data.value;
    this.gatewaySelect = 0;
    this.headquarterSelect = 0;
    this.headquarters = [];
    this.gateways = [];
    this.getHeadquarters();
  }

  getEnterprises() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.enterpriseService.getEnterprises().subscribe((response) => {
        this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
        this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
        this.spinner.hide();
        this.getHeadquarters();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    } else {
      this.enterpriseSelect = +localStorage.getItem(AppConstants.Session.ENTERPRISE)
      this.getEnterpriseById(this.enterpriseSelect);
      this.getList();
    }
  }

  getHeadquarters(){
    if (this.isAdminGys) {
      this.spinner.show();
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
          if(this.headquarters.length > 0){
            this.headquarterSelect = this.headquarters[0].id;
          }
          this.spinner.hide();
          this.getZones();
          this.getGateways();
        }else{
          this.headquarters = [];
          this.gateways = [];
          this.spinner.hide();
        }
      },
        (error: HttpErrorResponse) => {
          this.headquarters = [];
          this.gateways = [];
          this.spinner.hide();
        });
    } else {
      this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER);
    }
  }

  getGateways(){
    this.gatewayService.getListAll().subscribe((response) => {
      if(response.error == null){
        if(this.headquarterSelect == 0){
          var gateways = response.data.filter(x => x.idEnterprise == this.enterpriseSelect);
          this.gateways = gateways;
        }else{
          var gateways = response.data.filter(x => x.idEnterprise == this.enterpriseSelect && x.idHeadquarter == this.headquarterSelect);
          this.gateways = gateways;
        }
        if(this.gateways.length > 0){
          this.isGuardTotal = this.gateways[0].isGuardTotal == 1 ? true : false;
          this.gatewaySelect = this.gateways[0].id;
          this.guardTotal = gateways[0].guardTotal;
        }
      }else{
        this.gateways = [];
      }
      this.getList();
    },
      (error: HttpErrorResponse) => {
        this.gateways = [];
      });
  }

  showGuardTank(){
    let dialogRef = this.dialog.open(ActuatorDetailPanelModalComponent, {
      data: {
        title: AppConstants.TitleModal.LIST_ACTUATOR_PANEL_IOT,
        action: AppConstants.OperationType.UPDATE,
        headquarters: this.headquarters,
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        idGateway: this.gatewaySelect,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        color: this.color1,
        colorFont: this.color2,
        obj:null},
      width: '500px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        this.guardTotalTQ = _.totalguard;
      }
    });
  }

  getZones(){
    this.zoneService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
      if(response.error == null){
        this.zones = response.data.filter(x => x.type == 2);
        if(this.zones.length>0){
          this.zoneSelect = this.zones[0].id;
          var image = this.zones[0].image;
          if(this.zones[0].idStorageFile == 1){ // Mode Local
            image = environment.pathFile + image;
          }
          this.changeImage(image);
          this.headquarterSelect = this.zones[0].idHeadquarter;
          this.getGateways();
        } else {
          this.alertService.showMessage("No tiene zonas IOT registradas, debes de crear una zona para usar este módulo", "", MessageSeverity.error);
          this.changeImage('./assets/images/sin_image.jpg');
        }
      }
    });
  }


  getEnterpriseById(enterpriseId: number) {
    this.spinner.show();
    this.enterpriseService.getEnterpriseById(enterpriseId).subscribe((response => {
      this.countMaxUserByEnterprise = response.maximumUsers;
      this.spinner.hide();
    }),
    (error: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getList(){
    this.spinner.show();
    if(this.zoneSelect && this.gatewaySelect){
      var objects = this.canvas.getObjects();
      for(var i = 0; i < objects.length; i++){
        this.canvas.remove(objects[i]);
      }
      this.canvas.renderAll();
      var filter = {
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        idZone: this.zoneSelect,
        idGateway: this.gatewaySelect
      }
      var contentTotal = 0;
      this.panelIOTService.getList(filter).subscribe((_) => {
        if(_.error == null){
          this.sensors = _.data;
          this.dataStreaming = _.data;
          this.panelIOTService.setDataStreamingSensors(this.sensors);
          this.showSensorsGraphs = true;
          this.createMarkers();
          this.createValueSensor();
          var _this = this;
          _.data.forEach(x =>{
            if(x.idActuatorType == 1){ //TANQUE
              contentTotal += parseFloat(x.value);
            }
          });
          _this.contentTotal = contentTotal;
        }
        this.spinner.hide();
      });
    }
    this.spinner.hide();
  }

  createMarkers() {
    for (var i = 0; i < this.sensors.length; i++) {
      var obj = this.sensors[i];
      this.newGroup(obj);
    }
  }

  createValueSensor() {
    console.log("crear valores streaming",this.dataStreaming.length);
    for (var i = 0; i < this.dataStreaming.length; i++) {
      var obj = this.dataStreaming[i];
      if(obj.idActuatorType != 2){ // no valvulas
        this.newValue(obj);
      }
    }
  }

  removeValueSensors(){
    var objects = this.canvas.getObjects();
    for (var i = 0; i < this.dataStreaming.length; i++) {
      var obj = this.dataStreaming[i];
      for(var ii = 0; ii < objects.length; ii++){
        if(obj.id +"_value" == objects[ii].id){
          this.canvas.remove(objects[ii]);
        }
      }
    }
    this.canvas.renderAll();
  }

  newValue(obj) {
    if(obj.value == null || obj.value == undefined){ obj.value = 0; }
    //actuator
    if(obj.isActuator){
        var salveGuarda = "";
        if(!this.isGuardTotal){
          salveGuarda = "\n S:" + obj.minCapacity+ " TN";
        }
        var text = new fabric.Text( "V:"+ obj.value.toString() + "TN (" + ((((parseFloat(obj.value.toString())/100) / (parseFloat(obj.maxCapacity)/100)))*100).toFixed(2)   +"%) \n CM:" + obj.maxCapacity + " TN"+salveGuarda,
        {
          selection: false,
          selectable: false,
          textBorderColor: this.color1,
          textShadow: this.color1,
          backgroundColor: 'transparent',
          fontSize: 12,
          fontWeight: 'bold',
          textBackgroundColor:'transparent',
          fill: this.color1,
          //fontSize: 20,
          strokeWidth: 0,
          selectionRadius: 20,
          originX: 'center',
          originY: 'center'
        }
          );

          var textBoundingRect = text.getBoundingRect();
          var background = new fabric.Rect({
            top: textBoundingRect.top,
            left: textBoundingRect.left,
            width: textBoundingRect.width,
            height: textBoundingRect.height,
            fill: 'transparent',//'#00000033',
            rx: 5,
            ry: 5,
            objectCaching: false,
          });
          var group = new fabric.Group([background,text], {
            left: parseFloat(obj.posX)-100,
            top: parseFloat(obj.posY)+20,
            borderColor: this.color1,
            strokeWidth: 0,
            selectionRadius: 20,
            rx: 5,
            ry: 5,
            hasControls: false,
            hasRotatingPoint: false,
            id: obj.id+"_value",
            selectable: false,
            selection: false,
            isGraph: 0,
            fill:'red'
            });
            this.canvas.add(group);


            /* densidad */

            var imgDensity = "../../../../../assets/actuator/density/aceite.png";
            var _this= this;
            if(obj.density != obj.contentDensity){ imgDensity = "../../../../../assets/actuator/density/agua.png";}
            new fabric.Image.fromURL(imgDensity,
              function(img) {
                var img1 = img.set({
                  originX: 'center',
                  originY: 'bottom',
                  top: -10,
                  scaleX: 0.5,
                  scaleY: 0.5
                });
                  var group = new fabric.Group([img1], {
                    left: parseFloat(obj.posX)-80,
                    top: parseFloat(obj.posY)+80,
                    borderColor: 'transparent',
                    strokeWidth: 0,
                    selectionRadius: 20,
                    rx: 5,
                    ry: 5,
                    hasControls: false,
                    hasRotatingPoint: false,
                    id: obj.id+"_value",
                    selectable: false,
                    selection: false,
                    isGraph: 0
                  });
                  _this.canvas.add(group);
              });
           /* var colorDensity = "rgb(252, 235, 0)";
            if(obj.density != obj.contentDensity){ colorDensity = "blue";}
              var density = new fabric.Circle({
                radius: 5,
                fill: colorDensity,
                stroke: colorDensity,
                strokeWidth: 5
            }); 
            var group2 = new fabric.Group([density], {
              
              });
              this.canvas.add(group2);*/
    }else{//sensor
      var _this = this;
      var text = new fabric.Text(obj.nomenclature, {
        textBorderColor: this.color1,
          textShadow: this.color1,
          backgroundColor: 'transparent',
          fontSize: 15,
          fontWeight: 'bold',
          textBackgroundColor:'transparent',
          fill: this.color1,
        originX: 'center', 
        originY: 'center',});
      var textBoundingRect = text.getBoundingRect();
      var background = new fabric.Rect({
        top: textBoundingRect.top,
        left: textBoundingRect.left,
        width: textBoundingRect.width,
        height: textBoundingRect.height,
        fill: '#00000033',
        rx: 5,
        ry: 5,
        objectCaching: false,
      });

      new fabric.Image.fromURL(obj.pin,
        function(img) {
          var img1 = img.set({
            originX: 'center',
            originY: 'bottom',
            top: -10,
            scaleX: 0.15,
            scaleY: 0.15
          });
            var group = new fabric.Group([text,background, img1], {
             /* left: obj.posX,
              top: obj.posY,
              borderColor: 'transparent',
              hasControls: false,
              hasRotatingPoint: false,
              id: obj.id,
              isGraph: 0*/
              left: parseFloat(obj.posX)+10,
              top: parseFloat(obj.posY)+20,
              borderColor: 'transparent',
              strokeWidth: 0,
              selectionRadius: 20,
              rx: 5,
              ry: 5,
              hasControls: false,
              hasRotatingPoint: false,
              id: obj.id+"_value",
              selectable: false,
              selection: false,
              isGraph: 0
            });
            _this.canvas.add(group);
        });
        /*
        var group = new fabric.Group([background,text], {
          
          });
          this.canvas.add(group);*/

          var text2 = new fabric.Text(obj.value.toString(),
          {
            backgroundColor: 'transparent',
            selection: false,
            selectable: false,
            textBorderColor: this.color1,
            textShadow: this.color1,
            textBackgroundColor:'transparent',
            fill: this.color1,
            fontSize: 20,
            strokeWidth: 0,
            selectionRadius: 20,
            originX: 'center',
            originY: 'center'
          }
            );
            var group2 = new fabric.Group([text2], {
              left: parseFloat(obj.posX)+20,
              top: parseFloat(obj.posY)-8,
              borderColor: 'transparent',
              strokeWidth: 0,
              selectionRadius: 20,
              rx: 5,
              ry: 5,
              hasControls: false,
              hasRotatingPoint: false,
              id: obj.id+"_value",
              selectable: false,
              selection: false,
              isGraph: 0
              });
             this.canvas.add(group2);
    }

  }

  newGroup(obj) {
      // text = new fabric.Text(obj.nomenclature, {selection: false,selectable: false,textBackgroundColor:obj.stateColor,fill: 'white', fontSize: 15, originX: 'center', originY: 'center'});

      var _this = this;
      if(obj.isActuator){
        var color = "";
        if(obj.state == 0){
          color = "green";
        }else if(obj.state == 1){
          color = "gray";
        }else if(obj.state == 2){
          color = "red";
        }
        var text = new fabric.Text(obj.nomenclature, {
          
          textBorderColor: color,
          textShadow: color,
          backgroundColor: 'transparent',
          fontSize: 15,
          fontWeight: 'bold',
          textBackgroundColor:'transparent',
          fill: color,
          originX: 'center', 
          originY: 'center'});
        var img = "";

      
       /* if(obj.value != "" && obj.value != null && obj.imageActuator2 != "" && obj.imageActuator2 != null){
          if(obj.value == "1"){
            img = obj.imageActuator2;
          }
        }*/
        var scale = 0.7;
        if(obj.idActuatorType == 1){ // tanques
          var porc = Math.round((obj.value*100)/obj.maxCapacity);
          if(porc <= 0){
            img = "../../../../../assets/actuator/tanque/tq_0.png";
          }else if(porc <= 25 && porc > 0){
            img = "../../../../../assets/actuator/tanque/tq_1.png";
          }else if(porc <= 50 && porc > 25){
            img = "../../../../../assets/actuator/tanque/tq_2.png";
          }else if(porc <= 75 && porc > 50){
            img = "../../../../../assets/actuator/tanque/tq_3.png";
          }else if(porc <= 99 && porc > 75){
            img = "../../../../../assets/actuator/tanque/tq_4.png";
          }else if(porc >= 100){
            img = "../../../../../assets/actuator/tanque/tq_5.png";
          }
        }else if(obj.idActuatorType == 2){//valvula
          scale = 0.5;
          img = "../../../../../assets/actuator/valvula/"+obj.state+".png";
        }

        var opacity = 1;
       /* if(obj.state == 4){
          opacity = 0.3;
        }*/

        new fabric.Image.fromURL(img,
          function(img) {
            var img1 = img.set({
              originX: 'center',
              originY: 'bottom',
              top: -10,
              scaleX: scale,
              scaleY: scale
            });
              var group = new fabric.Group([text, img1], {
                left: obj.posX,
                top: obj.posY,
                borderColor: 'transparent',
                hasControls: false,
                hasRotatingPoint: false,
                selectable: false,
                selection: false,
                opacity: opacity,
                id: obj.id,
                isGraph: 0
              });
              _this.canvas.add(group);
              _this.nameMarker = '';
          });
      }else{
        var _this = this;
        /* var text = new fabric.Text(obj.nomenclature, {textBackgroundColor:obj.stateColor,fill: 'white', fontSize: 15, originX: 'center', originY: 'center'});
       
       var group = new fabric.Group([text], {
          left: obj.posX,
          top: obj.posY,
          borderColor: 'transparent',
          hasControls: false,
          hasRotatingPoint: false,
          id: obj.id,
          isGraph: 0
          });
          this.canvas.add(group);*/
      }
      /*
      var group = new fabric.Group([text], {
      left: obj.posX,
      top: obj.posY,
      borderColor: 'transparent',
      hasControls: false,
      hasRotatingPoint: false,
      id: obj.id,
      selectable: false,
      selection: false,
      isGraph: 0
      });
      this.canvas.add(group);*/
  }


  newGraphSensor(obj) {
    var text = new fabric.Text(obj.nomenclature, {textBackgroundColor:'#1f5165',fill: 'white', fontSize: 15, originX: 'center', originY: 'center'});
    var group = new fabric.Group([text], {
    left: obj.posX,
    top: obj.posY + 50,
    borderColor: 'transparent',
    hasControls: false,
    hasRotatingPoint: false,
    id: obj.id
    });
    this.canvas.add(group);
}

  openEditModal(data){
      let dialogRef = this.dialog.open(SensorModalComponent, {
        data: {
          title: AppConstants.TitleModal.UPDATE_SENSOR,
          action: 0,
          gateways: this.gateways,
          headquarters: this.headquarters,
          idEnterprise: this.enterpriseSelect,
          idHeadquarter: this.headquarterSelect,
          idGateway: this.gatewaySelect,
          isAdminGys: this.isAdminGys,
          obj:data},
        width: '500px',
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(_ => {
        this.getList();
      });
  }

    changeHeadquarter(data) {
      this.headquarterSelect = data.value;
      this.getGateways();
      this.getZones();
    }

    changeGateway(data) {
      this.gatewaySelect = data.value;
      this.getList();
    }

    changeZone(data) {
      this.zoneSelect = data.value;
      var zone = this.zones.filter(x => x.id == this.zoneSelect);
      if(zone.length>0)
      {
        this.headquarterSelect = zone[0].idHeadquarter;
        this.getGateways();

        var image = zone[0].image;
        if(image){
          if(zone[0].idStorageFile == 1){ // Mode Local
            image = environment.pathFile + image;
          }
          this.changeImage(image);
        }else {
          this.changeImage('./assets/images/sin_image.jpg');
        }
      }else {
        this.changeImage('./assets/images/sin_image.jpg');
      }

      this.getList();
    }

    changeImage(urlImage : string) {
        fabric.Image.fromURL(urlImage, (img) => {
          img.set({
            opacity: 0.5,
            scaleX: this.canvas.width / img.width,
            scaleY: this.canvas.height / img.height,
          });
          this.canvas.setBackgroundImage(img, this.canvas.requestRenderAll.bind(this.canvas));
        });
    }

  openModal(id){
    document.getElementById('actuactorPanel'+id).style.display="initial";
  }


    showGraph(){
      var _this = this;
      this.canvas.on("mouse:up", function(e) {
        if(!_this.showGraphSensor && e.target != undefined && e.target != null){
          _this.showGraphSensor = true;
          _this.showSensorEdit = true;
          var dataS = null;
          _this.sensors.forEach(g => {
            if ((g.id == e.target.id || g.id + "_value" == e.target.id) && g.state == 0) {
              dataS = g;
              _this.openModal(g.id);
            }
          });
          _this.showGraphSensor = false;
          _this.showSensorEdit = false;
        }
      });
    }

    save(){
      var data = {
        isGuardTotal: this.isGuardTotal == true ? 1:0,
        guardTotal: this.guardTotal,
        idGateway: this.gatewaySelect,
        idUser: +localStorage.getItem(AppConstants.Session.USERID)
      }
      this.gatewayService.updateSettingGateway(data).subscribe((response) => {
        if(response.message == "" || !response.message){
          this.alertService.showMessage("Se actualizo el total guarda correctamente", "", MessageSeverity.success);
          this.getList();
        }else{
          this.alertService.showMessage("Ocurrió un problema al actualizar el guarda", "", MessageSeverity.error);
        }
      });
    }

    isGuardTotalSwitch(event: MatSlideToggleChange){
        if(event.checked){
          this.isGuardTotal = true;
        }else{
          this.isGuardTotal = false;
        }
    }
}
