import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { GraphNewAssignModalComponent } from '../graph-new-assign-modal/graph-new-assign-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { MessageModalComponent } from '../../message/message-modal.component';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { SensorTypeService } from 'src/app/services/sensor-type/sensor-type.service';

@Component({
  selector: 'app-graph-assign-modal',
  templateUrl: './graph-assign-modal.component.html',
  styleUrls: ['./graph-assign-modal.component.css']
})
export class GraphAssignModalComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['graph','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  items = [];

  constructor(
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private sensorTypeService: SensorTypeService,
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
    this.list();
  }

  list(){
    this.spinner.show();
    this.sensorTypeService.getListGraphAll(this.data.idSensorType).subscribe((response) => {
      if(!response.error){
        this.dataSource.data = response.data;
      }else{
        this.dataSource.data = [];
      }

      this.spinner.hide();
    },
    (error: HttpErrorResponse) => {
        this.spinner.hide();
    });
  }

  add(){
    let dialogRef = this.dialog.open(GraphNewAssignModalComponent, {
      data: {
        title: AppConstants.TitleModal.TITLE_USER_NEW_ASSING_MODAL,
        idUser: this.data.obj.userId,
        idSensorType: this.data.idSensorType,
        color: this.data.color,
        colorFont: this.data.colorFont
      },
      width: '500px',
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
          this.list();
      }
  });
  }

  delete(element){
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
          title: AppConstants.TitleModal.DELETE_TITLE_USER_ASSING_CONFIRM,
          message: `${AppConstants.MessageModal.DELETE_USER_ASSIGN_CONFIRMATION_MESSAGE1}`,
          hasButton: true,
          contentButton: {
              yes: 'SI',
              no: 'CANCELAR'
          }
      },
  })

  dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteUser(element.id);
      }
  });
  }

  deleteUser(id: number) {
    this.spinner.show();
    var data = {
      id: id,
      AuditUpdateUser: localStorage.getItem(AppConstants.Session.USERID)
    }

    this.sensorTypeService.deleteGraph(data).subscribe((response => {
      this.spinner.hide();
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_USER_ASSIGN, AppConstants.MessageModal.DELETE, MessageSeverity.success);
      this.list();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_USER_ASSIGN, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  close() {
    this.dialog.closeAll();
  }
}
