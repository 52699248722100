<div class="container mt-3 panel-profile">
  <form [formGroup]="userForm" autocomplete="off">
      <!--<div class="row">
        <div class="col-12 col-md-12 text-center">
          <img width="150px" class="image-profile" src="../../../../assets/images/user-noimage.png">
        </div>
      </div>-->
      <div class="row mt-2">
          <div class="col-12 col-md-12">
              <mat-form-field class="w-100">
                  <mat-label>Correo</mat-label>
                  <input matInput formControlName="email" maxlength="100" readonly>
                  <mat-error *ngIf="f.email.hasError('required')">
                      El correo es <strong>requerido</strong>.
                  </mat-error>
                   <mat-error *ngIf="f.email.hasError('maxlength')">
                      El correo debe tener máximo <strong>100 caractéres</strong>.
                  </mat-error>
                  <mat-error *ngIf="f.email.hasError('email')">
                      Error en el <strong>formato</strong>.
                  </mat-error>
              </mat-form-field>
          </div>
      </div>
      <div class="row">
          <div class="col-12 col-md-6">
              <mat-form-field class="w-100">
                  <mat-label>Nombres(s)</mat-label>
                  <input matInput formControlName="names" maxlength="30">
                  <!--<mat-error *ngIf="f.names.hasError('required')">
                      El número de documento es <strong>requerido</strong>.
                  </mat-error>-->
                  <mat-error *ngIf="f.names.hasError('maxlength')">
                      El número de documento debe tener máximo <strong>30 caractéres</strong>.
                  </mat-error>
                  <mat-error *ngIf="f.names.hasError('whitespace')">
                      El número de documento es <strong>requerido</strong>.
                  </mat-error>
              </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
              <mat-form-field class="w-100">
                  <mat-label>Apellidos</mat-label>
                  <input matInput formControlName="lastName" maxlength="30">
                  <!--<mat-error *ngIf="f.lastName.hasError('required')">
                      El Apellido es <strong>requerido</strong>.
                  </mat-error>-->
                  <mat-error *ngIf="f.lastName.hasError('maxlength')">
                      El Apellido debe tener máximo <strong>30 caractéres</strong>.
                  </mat-error>
                  <mat-error *ngIf="f.lastName.hasError('whitespace')">
                      El Apellido es <strong>requerido</strong>.
                  </mat-error>
              </mat-form-field>
          </div>
      </div>
      <div class="row">
          <div class="col-12 col-md-6">
              <mat-form-field class="w-100">
                  <mat-label>Nueva contraseña</mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'" autocomplete="off" formControlName="password" minlength="6" maxlength="20" >
                  <mat-icon matSuffix (click)="hide = !hide" class="icon-cursor" color="primary">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-error *ngIf="f.password.hasError('minlength')">
                      La contraseña debe tener <strong>6 caractéres</strong>.
                  </mat-error>
              </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
              <mat-form-field class="w-100">
                  <mat-label>Repetir Nueva Contraseña</mat-label>
                  <input matInput [type]="repeatHide ? 'password' : 'text'" autocomplete="off" formControlName="confirmPassword" minlength="6" maxlength="20" >
                  <mat-icon matSuffix (click)="repeatHide = !repeatHide" class="icon-cursor" color="primary">{{repeatHide ? 'visibility_off' : 'visibility'}}</mat-icon>

                  <mat-error *ngIf="f.confirmPassword.hasError('minlength')">
                      La contraseña debe tener <strong>6 caractéres</strong>.
                  </mat-error>
              </mat-form-field>
          </div>
      </div>
<!--
      <div class="row">
          <div class="col-12 col-md-6">
              <mat-form-field class="w-100">
                    <mat-label>Sede</mat-label>
                    <mat-select
                          formControlName="idHeadquarter"
                          (selectionChange)="changeHeadquarter($event)"
                          [(ngModel)]="headquarterSelect">
                    <mat-option [value]="0" > Todas </mat-option>
                    <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                      {{headquarter.name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.role.hasError('required')">
                        El rol es <strong>requerido</strong>.
                    </mat-error>
              </mat-form-field>
          </div>
      </div>

      <div class="row" style="margin-top: 20px;">
          <div class="col">
              <mat-checkbox labelPosition="before" formControlName="sendEmail">Enviar Correo</mat-checkbox>
          </div>
           <div class="col" *ngIf="showEdit">
              <mat-checkbox labelPosition="before" formControlName="locked">Bloqueado</mat-checkbox>
          </div>
      </div> -->
  </form>
  <div class="mt-3 text-right">
    <button mat-raised-button color="primary" (click)="save()">GUARDAR</button>
  </div>

</div>
