import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetZone } from 'src/app/models/zone/getZone';
import { GetPin } from 'src/app/models/zone/getPin';
import { GetPowerBI } from 'src/app/models/power-bi/getPowerbi';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService {
  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'PowerBI';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getList(parameters: any): Observable<GetZone> {
    let httpParams = new HttpParams({
      fromObject: parameters,
    });
    return this.httpClient.get<GetZone>(`${this.REQUEST_URL}/pag`, { params: httpParams });
  }

  getListAll(idEnterprise: number,idHeadquarter: number,idSystem: number): Observable<GetPowerBI> {
    return this.httpClient.get<GetPowerBI>(`${this.REQUEST_URL}/enterprise/${idEnterprise}/headquarter/${idHeadquarter}/system/${idSystem}`);
  }

  create(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}`,data);
  }
}
