import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetSensorType } from 'src/app/models/sensor-type/get-sensor-type';
import { GetSensorTypeGraph } from 'src/app/models/sensor-type/get-sensor-type-graph';
import { GetGraph } from 'src/app/models/panelIOT/getGraph';

@Injectable({
  providedIn: 'root'
})
export class SensorTypeService {
  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'SensorType';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;
  private REQUEST_PANELIOT_URL: string = `${this.BASE_URL}/PanelIOT`;

  getList(parameters: any): Observable<GetSensorType> {
    let httpParams = new HttpParams({
      fromObject: parameters,
    });
    return this.httpClient.get<GetSensorType>(`${this.REQUEST_URL}/pag`, { params: httpParams });
  }

  getListAll(): Observable<GetSensorType> {
    return this.httpClient.get<GetSensorType>(`${this.REQUEST_URL}`);
  }

  getListGraphAll(idSensorType: number): Observable<GetSensorTypeGraph> {
    return this.httpClient.get<GetSensorTypeGraph>(`${this.REQUEST_URL}/graphs/${idSensorType}`);
  }

  create(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}`,data);
  }

  createGraph(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}/graph`,data);
  }

  update(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}`,data);
  }

  delete(data: any){
    return this.httpClient.patch<any>(`${this.REQUEST_URL}`,data);
  }

  deleteGraph(data: any){
    return this.httpClient.patch<any>(`${this.REQUEST_URL}/graph`,data);
  }

  getListGraphs(): Observable<GetGraph> {
    return this.httpClient.get<GetGraph>(`${this.REQUEST_PANELIOT_URL}/graph`);
  }
}
