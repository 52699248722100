import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { GatewayService } from 'src/app/services/gateway/gateway.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { SensorService } from 'src/app/services/sensor/sensor.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { SensorModalComponent } from 'src/app/shared/components/modals/sensor/sensor-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { environment } from 'src/environments/environment';
import { PanelSensorModalComponent } from 'src/app/shared/components/modals/panel-sensor-modal/panel-sensor-modal.component';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import 'fabric';
import { MatSlideToggleChange } from '@angular/material';
import { Router } from '@angular/router';
import { PanelActuatorModalComponent } from 'src/app/shared/components/modals/panel-actuator-modal/panel-actuator-modal.component';
declare const fabric: any;

@Component({
  selector: 'app-location-iot',
  templateUrl: './location-iot.component.html',
  styleUrls: ['./location-iot.component.css']
})
export class LocationIotComponent implements OnInit {
  loading: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['enterprise','headquarter','gateway','name','description','nomenclature','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isAdminGys: boolean = false;
  countMaxUserByEnterprise: number = 0;
  enterprises = [];
  colorToggle = "";
  headquarters = [];
  gateways = [];
  subscription: Subscription;
  enterpriseSelect: number = 0;
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION;
  length: number = 0;
  page: number = 1;
  headquarterSelect: number = 0;
  gatewaySelect: number = 0;
  zones = [];
  zoneSelect: number = 0;
  canvas: any;
  nameMarker: string = '';
  sensors = [];
  displayListStadistics = 'block';
  showSensorEdit = false;
  showGraphSensor = false;
  showPanels = false;
  sensorsModals = [];
  sensorName = "";
  sensorId = 0;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  enabledRead: number;
  enabledWrite: number;

  constructor(
    private el: ElementRef,
    public dialog: MatDialog,
    private alertService: AlertService,
    private enterpriseService: EnterpriseService,
    private spinner: NgxSpinnerService,
    private emitterService: EmitterService,
    private sensorService: SensorService,
    private headquarterService: HeadquarterService,
    private gatewayService: GatewayService,
    private panelIOTService: PanelIOTService,
    private router: Router,
    private zoneService: ZoneService
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'IOT > <span class="font-weight-bold">Configuración de Panel</span>';
    }

  ngOnInit() {
    this.setLS();
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.getZones();
    this.getCanvas();
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  getCanvas() {
    this.canvas = new fabric.Canvas('canvas',{selection: false});
  }

  changeEnterprise(data) {
    this.countMaxUserByEnterprise = this.enterprises.find(f => f.enterpriseId == data.value).maximumUsers;
    this.enterpriseSelect = data.value;
    this.gatewaySelect = 0;
    this.headquarterSelect = 0;
    var objects = this.canvas.getObjects();
      for(var i = 0; i < objects.length; i++){
        this.canvas.remove(objects[i]);
    }
    this.headquarters = [];
    this.gateways = [];
    this.sensors = [];
    this.getHeadquarters();
  }

  getEnterprises() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.enterpriseService.getEnterprises().subscribe((response) => {
        this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
        this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
        this.getHeadquarters();
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    } else {
      this.enterpriseSelect = +localStorage.getItem(AppConstants.Session.ENTERPRISE)
      this.getEnterpriseById(this.enterpriseSelect);
      this.getList();
    }
  }

  getHeadquarters() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
          if(this.headquarters.length > 0){
            this.headquarterSelect = this.headquarters[0].id;
          }
          this.getZones();
          this.getGateways();
        }else{
          this.headquarters = [];
          this.gateways = [];
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.headquarters = [];
          this.gateways = [];
          this.spinner.hide();
        });
    } else {
      this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER);
    }
  }

  getGateways(){
    this.gatewayService.getListAll().subscribe((response) => {
      if(response.error == null){
        if(this.headquarterSelect == 0){
          var gateways = response.data.filter(x => x.idEnterprise == this.enterpriseSelect);
          this.gateways = gateways;
        }else{
          var gateways = response.data.filter(x => x.idHeadquarter == this.headquarterSelect);
          this.gateways = gateways;
        }

        if(this.gateways.length > 0){
          this.gatewaySelect = gateways[0].id;
        }
      }else{
        this.gateways = [];
      }
      this.getList();
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.gateways = [];
      });

  }

  getZones(){
    this.zoneService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
      if(response.error == null){
        this.zones = response.data.filter(x => x.type == 2);
        if(this.zones.length>0){
          this.zoneSelect = this.zones[0].id;
          var image = this.zones[0].image;
         /* if(this.zones[0].idStorageTypeBlob == 1){ // Mode Local
            image = this.zones[0].storagePathBlob + image;
          }*/
          this.changeImage(image);
          this.headquarterSelect = this.zones[0].idHeadquarter;
          this.getGateways();
        } else {
          this.alertService.showMessage("No tiene zonas IOT registradas, debes de crear una zona para usar este módulo", "", MessageSeverity.error);
          this.changeImage('./assets/images/sin_image.jpg');
        }
        this.spinner.hide();
      }
    });
  }

  showView(event: MatSlideToggleChange){
    if(event.checked){
      this.displayListStadistics = 'none';
    }else{
      this.displayListStadistics = 'block';
    }
  }

  showTable(event: MatSlideToggleChange){
    //this.initPage = true;
    //this.showSensorsTable = event.checked;
    //event.checked = this.showSensorsTable ? false : true;
    this.showPanels = event.checked;
    if(event.checked){
      for (var i = 0; i < this.sensors.length; i++) {
        var obj = this.sensors[i];
        this.newGraphGroup(obj);
        this.canvas.renderAll();
      }
    }else{
      var objects = this.canvas.getObjects();
      for (var i = 0; i < this.sensors.length; i++) {
        var obj = this.sensors[i];
        for(var ii = 0; ii < objects.length; ii++){
          if(obj.id +"_panel" == objects[ii].id){
            this.canvas.remove(objects[ii]);
          }
        }
      }
      this.canvas.renderAll();
    }
  }


  getEnterpriseById(enterpriseId: number) {
    this.spinner.show();
    this.enterpriseService.getEnterpriseById(enterpriseId).subscribe((response => {
      this.countMaxUserByEnterprise = response.maximumUsers;
      this.spinner.hide();
    }),
    (error: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getList(){
    this.spinner.show();
    if(this.zoneSelect && this.gatewaySelect){
      var objects = this.canvas.getObjects();
      for(var i = 0; i < objects.length; i++){
        this.canvas.remove(objects[i]);
      }
      this.canvas.renderAll();
      var filter = {
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        idZone: this.zoneSelect,
        idGateway: this.gatewaySelect
      }
      this.panelIOTService.getList(filter).subscribe((_) => {
        if(_.error == null){
          this.sensors = _.data;
          this.createMarkers();
        }
        this.spinner.hide();
      });
    }
    this.spinner.hide();
  }

  createMarkers() {
    for (var i = 0; i < this.sensors.length; i++) {
      var obj = this.sensors[i];
      this.newGroup(obj);
      //add Graph
      if(this.showPanels){
        this.newGraphGroup(obj);
      }
    }
  }

  newGroup(obj) {


      var _this = this;
      if(obj.isActuator){
        var text = new fabric.Text(obj.nomenclature, {textBackgroundColor:"gray",fill: 'white', id: obj.id,fontSize: 15, originX: 'center', originY: 'center'});
        var img = "";
        if(obj.idActuatorType == 1){ // tanques
          var porc = (obj.value/obj.maxCapacity)*100;
          if(obj.value <= 0){
            img = "../../../../../assets/actuator/tanque/tq_0.png";
          }else if(porc <= 30 && porc > 0){
            img = "../../../../../assets/actuator/tanque/tq_1.png";
          }else if(porc <= 70 && porc > 30){
            img = "../../../../../assets/actuator/tanque/tq_2.png";
          }else if(porc < 100 && porc > 70){
            img = "../../../../../assets/actuator/tanque/tq_3.png";
          }else if(obj.value == obj.maxCapacity){
            img = "../../../../../assets/actuator/tanque/tq_4.png";
          }
        }else if(obj.idActuatorType == 2){//valvula
            img = "../../../../../assets/actuator/valvula/"+obj.value+".png";
        }
        
        new fabric.Image.fromURL(img,
          function(img) {
            var img1 = img.set({
              originX: 'center',
              originY: 'bottom',
              top: -10,
              id: obj.id,
              scaleX: 0.7,
              scaleY: 0.7
            });
              var group = new fabric.Group([text, img1], {
                left: obj.posX,
                top: obj.posY,
                borderColor: 'transparent',
                hasControls: false,
                hasRotatingPoint: false,
                id: obj.id,
                isGraph: 0
              });
              console.log("actuadores ",group);
              _this.canvas.add(group);
          });
      }else{
        var text = new fabric.Text(obj.nomenclature, {textBackgroundColor:obj.stateColor,fill:this.color1, fontSize: 15, originX: 'center', originY: 'center',
        fontWeight: 'bold',});
        var textBoundingRect = text.getBoundingRect();
        var background = new fabric.Rect({
          top: textBoundingRect.top,
          left: textBoundingRect.left,
          width: textBoundingRect.width,
          height: textBoundingRect.height,
          fill: '#00000033',
          rx: 5,
          ry: 5,
          objectCaching: false,
        });

        new fabric.Image.fromURL(obj.pin,
          function(img) {
            var img1 = img.set({
              originX: 'center',
              originY: 'bottom',
              top: -10,
              scaleX: 0.15,
              scaleY: 0.15
            });
              var group = new fabric.Group([text,background, img1], {
                left: obj.posX,
                top: obj.posY,
                borderColor: 'transparent',
                hasControls: false,
                hasRotatingPoint: false,
                id: obj.id,
                isGraph: 0
              });
              _this.canvas.add(group);
          });
        /*var group = new fabric.Group([background,text], {
          left: obj.posX,
          top: obj.posY,
          borderColor: 'transparent',
          hasControls: false,
          hasRotatingPoint: false,
          id: obj.id,
          isGraph: 0
          });
          this.canvas.add(group);*/
      }

  }


  newGraphGroup(obj) {
    var text = new fabric.Text("Panel - " + obj.nomenclature, { width: 70,
      height: 100,textBackgroundColor:'transparent',fill: 'white', fontSize: 15, originX: 'center', originY: 'center'});
      var textBoundingRect = text.getBoundingRect();
      var background = new fabric.Rect({
        top: textBoundingRect.top,
        left: textBoundingRect.left,
        width: 250,
        height: 150,
        fill: '#1bc0dbba'
      });

    var group = new fabric.Group([background,text],{
      width: 250,
      height: 100,
      borderColor: 'white',
      left: obj.posGraphX,
      top: obj.posGraphY + 50,
      hasControls: false,
      hasRotatingPoint: false,
      id: obj.id+"_panel",
      isGraph: 1
    });
    this.canvas.add(group);
}


  newGraphSensor(obj) {
    var text = new fabric.Text(obj.nomenclature, {textBackgroundColor:'#1f5165',fill: 'white', fontSize: 15, originX: 'center', originY: 'center'});
    var group = new fabric.Group([text], {
    left: obj.posX,
    top: obj.posY + 50,
    borderColor: 'transparent',
    hasControls: false,
    hasRotatingPoint: false,
    id: obj.id
    });
    this.canvas.add(group);
}

openAddActuatorModal(){
  let dialogRef = this.dialog.open(PanelActuatorModalComponent, {
    data: {
      title: AppConstants.TitleModal.ADD_ACTUATOR_PANEL,
      idEnterprise: this.enterpriseSelect,
      idHeadquarter: this.headquarterSelect,
      idGateway: this.gatewaySelect,
      idZone: this.zoneSelect,
      enabledWrite: this.enabledWrite,
      enabledRead: this.enabledRead,
      isAdminGys: this.isAdminGys,
      action: AppConstants.OperationType.ADD,
      obj:null},
    width: '500px',
    hasBackdrop: true
  });
  dialogRef.afterClosed().subscribe(_ => {
    this.getList();
  });
}

  openAddModal() {
    this.save(true);
    // validate exist sensors
    this.sensorService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
      if(response.error == null){
        if(this.headquarterSelect > 0){
          this.sensorsModals = response.data.filter(x => x.idEnterprise == this.enterpriseSelect && x.idHeadquarter == this.headquarterSelect && x.idGateway == this.gatewaySelect);
        }else{
          this.sensorsModals = response.data.filter(x => x.idEnterprise == this.enterpriseSelect && x.idGateway == this.gatewaySelect);
        }
        if(this.sensorsModals.length>0){
          let dialogRef = this.dialog.open(PanelSensorModalComponent, {
            data: {
              title: AppConstants.TitleModal.ADD_SENSOR_PANEL,
              idEnterprise: this.enterpriseSelect,
              idHeadquarter: this.headquarterSelect,
              idGateway: this.gatewaySelect,
              idZone: this.zoneSelect,
              enabledWrite: this.enabledWrite,
              enabledRead: this.enabledRead,
              isAdminGys: this.isAdminGys,
              action: AppConstants.OperationType.ADD,
              sensorName: this.sensors[0] != null ? this.sensors[0].nomenclatureInit : '',
              sensorId: this.sensors[0] != null ? this.sensors[0].id : 0,
              sensors: this.sensorsModals,
              obj:null},
            width: '800px',
            hasBackdrop: true
          });
          dialogRef.afterClosed().subscribe(_ => {
            this.getList();
          });
        }else{
          this.alertService.showMessage("No tiene sensores registrados", "", MessageSeverity.error);
        }
      }
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        this.alertService.showMessage("No tiene sensores registrados", "", MessageSeverity.error);
    });
  }

  getSensors(){
    this.spinner.show();

  }

  openEditModal(data){
      let dialogRef = this.dialog.open(SensorModalComponent, {
        data: {
          title: AppConstants.TitleModal.UPDATE_SENSOR,
          action:  AppConstants.OperationType.ADD,
          gateways: this.gateways,
          headquarters: this.headquarters,
          idEnterprise: this.enterpriseSelect,
          idHeadquarter: this.headquarterSelect,
          enabledWrite: this.enabledWrite,
          enabledRead: this.enabledRead,
          idGateway: this.gatewaySelect,
          isAdminGys: this.isAdminGys,
          sensors: this.sensorsModals,
          obj:data},
        width: '800px',
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(_ => {
        this.getList();
      });
  }


    changeHeadquarter(data) {
      this.headquarterSelect = data.value;
      this.getZones();
    }

    changeGateway(data) {
      this.gatewaySelect = data.value;
      this.getList();
    }

    changeZone(data) {
      this.zoneSelect = data.value;
      var zone = this.zones.filter(x => x.id == data.value)[0];
      this.headquarterSelect = zone.idHeadquarter;
      this.getGateways();

      var image = zone.image;
      if(image){
         /* if(zone.idStorageTypeBlob == 1){ // Mode Local
          image = environment.pathFile + image;
        } */ 
        this.changeImage(image);
      }else {
        this.changeImage('./assets/images/sin_image.jpg');
      }
      this.getList();
    }

    handleDrop() {
      this.listenMovement();
    }

    changeImage(urlImage : string) {
        fabric.Image.fromURL(urlImage, (img) => {
          img.set({
            opacity: 0.5,
            scaleX: this.canvas.width / img.width,
            scaleY: this.canvas.height / img.height,
          });
          this.canvas.setBackgroundImage(img, this.canvas.requestRenderAll.bind(this.canvas));
        });
    }


    listenMovement(){
      var _this = this;

      this.canvas.on("mouse:up", function(e) {
      /*});
      this.canvas.on("object:moving", function(e) {*/
        var obj = e.target;
        var canvas = obj.canvas;
        var top = obj.top;
        var left = obj.left;
        var pan_x = 0;//canvas.viewportTransform[4];
        var pan_y = 0;//canvas.viewportTransform[5];

        var c_width = 1000;//canvas.width;
        var c_height = 500;//canvas.height;
        var w = obj.width * obj.scaleX

        var left_adjust, right_adjust
        if(obj.originX == "center") {
          left_adjust = right_adjust = w / 2;
        } else {
          left_adjust = 0;
          right_adjust = w;
        }

        var h = obj.height * obj.scaleY;
        var top_adjust, bottom_adjust;
        if(obj.originY == "center") {
          top_adjust = bottom_adjust = h / 2;
        } else {
          top_adjust = 0;
          bottom_adjust = h;
        }
        var top_margin = 0;
        var bottom_margin = 0;
        var left_margin = 0;
        var right_margin = 0;

        var top_bound = top_margin + top_adjust - pan_y;
        var bottom_bound = c_height - bottom_adjust - bottom_margin - pan_y;
        var left_bound = left_margin + left_adjust - pan_x;
        var right_bound = c_width - right_adjust - right_margin - pan_x;

        if( w > c_width ) {
          obj.left=(left_bound);
        } else {
          obj.left=(Math.min(Math.max(left, left_bound), right_bound));
        }

        if( h > c_height ) {
          obj.top=(top_bound);
        } else {
          obj.top=(Math.min(Math.max(top, top_bound), bottom_bound));
        }
        var objects = _this.canvas.getObjects();
        _this.sensors.forEach(g => {
          if (g.id == obj.id || g.id + "_panel" == obj.id) {
              for(var i = 0; i < objects.length; i++){
                if (g.id == objects[i]["id"] || g.id + "_panel" == objects[i]["id"]) {
                  _this.canvas.remove(objects[i]);
                }
              }
              if(obj.isGraph == 0){
                g.posX = obj.left;
                g.posY = obj.top;
              }else{
                g.posGraphX = obj.left;
                g.posGraphY = obj.top;
              }

              _this.canvas.renderAll();
              _this.newGroup(g);
              if(_this.showPanels){
                _this.newGraphGroup(g);
              }
          }
        });
      });
    }

    save(newItem = false){
      if(!newItem){
        this.spinner.show();
      }
      this.panelIOTService.updateSensorPanelIOT(this.sensors).subscribe((response => {
        /*if(response.message == null){
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_TITLE, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
        }*/
        this.spinner.hide();
      }),
        (error: HttpErrorResponse) => {
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_TITLE, error.error.messages, MessageSeverity.error);
          this.spinner.hide();
        });
    }

    doubleClick(){
      var _this = this;
      /*this.canvas.on("mouse:down", function(e) {
        if(!_this.showGraphSensor){
          _this.showGraphSensor = true;
          _this.showSensorEdit = true;
          var dataS = null;
          _this.sensors.forEach(g => {
            if (g.id == e.target.id) {
              dataS = g;
            }
          });
          //_this.newGraphSensor(dataS);
          _this.showGraphSensor = false;
          _this.showSensorEdit = false;
        }
      });*/
      this.canvas.on("mouse:dblclick", function(e) {
          if(!_this.showSensorEdit){
            _this.showSensorEdit = true;
            var dataS = null;
            _this.sensors.forEach(g => {
              console.log(g.id+"---------------",e);
              if (g.id == e.target.id) {
                dataS = g;
              }
            });

            _this.sensorService.getListAll(_this.enterpriseSelect,_this.headquarterSelect).subscribe((response) => {
              if(response.error == null){
                if(_this.headquarterSelect > 0){
                  _this.sensorsModals = response.data.filter(x => x.idEnterprise == _this.enterpriseSelect && x.idHeadquarter == _this.headquarterSelect && x.idGateway == _this.gatewaySelect);
                }else{
                  _this.sensorsModals = response.data.filter(x => x.idEnterprise == _this.enterpriseSelect && x.idGateway == _this.gatewaySelect);
                }
                if(_this.sensorsModals.length>0){
                  let dialogRef = _this.dialog.open(PanelSensorModalComponent, {
                    data: {
                      title: AppConstants.TitleModal.UPDATE_SENSOR_PANEL,
                      idEnterprise: _this.enterpriseSelect,
                      idHeadquarter: _this.headquarterSelect,
                      idGateway: _this.gatewaySelect,
                      enabledWrite: _this.enabledWrite,
                      enabledRead: _this.enabledRead,
                      idZone: _this.zoneSelect,
                      isAdminGys: _this.isAdminGys,
                      action: AppConstants.OperationType.UPDATE,
                      obj:dataS,
                      sensors:_this.sensorsModals
                    },
                    width: '800px',
                    hasBackdrop: true
                  });
                  dialogRef.afterClosed().subscribe(_ => {
                    _this.showSensorEdit = false;
                    _this.getList();
                  });
                }
              }
            });
          }
      });
    }

    editSensor(element){
      this.sensorService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
        if(response.error == null){
          if(this.headquarterSelect > 0){
            this.sensorsModals = response.data.filter(x => x.idEnterprise == this.enterpriseSelect && x.idHeadquarter == this.headquarterSelect && x.idGateway == this.gatewaySelect);
          }else{
            this.sensorsModals = response.data.filter(x => x.idEnterprise == this.enterpriseSelect && x.idGateway == this.gatewaySelect);
          }
          if(this.sensorsModals.length>0){

            let dialogRef = this.dialog.open(PanelSensorModalComponent, {
              data: {
                title: AppConstants.TitleModal.UPDATE_SENSOR_PANEL,
                idEnterprise: this.enterpriseSelect,
                idHeadquarter: this.headquarterSelect,
                idGateway: this.gatewaySelect,
                idZone: this.zoneSelect,
                isAdminGys: this.isAdminGys,
                action: AppConstants.OperationType.UPDATE,
                obj:element,
                sensors:this.sensorsModals
              },
              width: '800px',
              hasBackdrop: true
            });
            dialogRef.afterClosed().subscribe(_ => {
              this.showSensorEdit = false;
              this.getList();
            });
        }
      }
    });
    }
}
