<section class="container">
  <div class="row section-name">
    <div class="header-title-options">
        <div class="row w-100">
          <div class="col-lg-10 col-xs-12">
            <mat-form-field [color]="color1" appearance="standard">
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="applyUpFilter($event)" (keypress)="applyPressFilter($event)" maxlength="200" #input>
            </mat-form-field>
          </div>
        </div>
    </div>
  </div>
</section>

<section class="container">
   <!-- <div class="row mt-3 section-name">
        <div class="col">
          Lista de Sedes
        </div>
    </div>
    <section class="filter mt-3">
      <div class="row">
          <div class="col-12 col-lg-8">
              <div class="row">
                  <div class="col-12 col-lg-6" *ngIf="isAdminGys">
                      <mat-form-field class="w-100">
                          <mat-label>Empresa</mat-label>
                          <mat-select
                                      (selectionChange)="changeEnterprise($event)"
                                      [(ngModel)]="enterpriseSelect">
                               <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                                  {{enterprise.businessName}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
              </div>
          </div>
          <div class="col-12 col-lg-8"></div>
          <div class="col-6 col-lg-2 offset-lg-2 my-auto">
              <button mat-raised-button color="primary" class="color-white w-100 p-0" (click)="openAddModal()">AGREGAR</button>
          </div>
      </div>
  </section>-->

    <div class="table-responsive">
      <table class="table" mat-table [dataSource]="dataSource" >

        <ng-container matColumnDef="index" >
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <ng-container matColumnDef="enterprise">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Empresa </th>
          <td class="text-center" *matCellDef="let element"> {{element.enterprise}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Nombre </th>
          <td class="text-center" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > Descripción </th>
          <td class="text-center" *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="nomenclature">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2" > Nomenclatura </th>
          <td class="text-center" *matCellDef="let element"> {{element.nomenclature}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Acciones </th>
          <td class="text-center" *matCellDef="let element"  >
            <mat-icon color="primary" class="icon-cursor" [style.color]="color1" (click)="openSettingModal(element)"  matTooltip="Configuración de directorios de archivos" *ngIf="element.headquarterStorageEnabled">folder_open</mat-icon>
            <mat-icon color="primary" class="icon-cursor" [style.color]="color1" *ngIf="enabledWrite" (click)="openEditModal(element)" matTooltip="Editar" >create</mat-icon>
            <mat-icon color="primary" class="icon-cursor" [style.color]="color1" *ngIf="enabledRead && !enabledWrite" (click)="openEditModal(element)" matTooltip="Ver" >remove_red_eye</mat-icon>
            <mat-icon color="primary" class="icon-cursor" [style.color]="color1"  *ngIf="enabledWrite" (click)="delete(element)" matTooltip="Eliminar" >delete</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <!--<mat-paginator
                     [pageSize]="pageSize"
                     [hidePageSize]="true">
      </mat-paginator>-->
      <mat-paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [hidePageSize]="true"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="getServerData($event)">
      </mat-paginator>
    </div>

</section>

<button mat-fab class="fab-button"  *ngIf="enabledWrite" [style.background]="color1" [style.color]="color2" aria-label="Add icon" (click)="openAddModal()" >
  <mat-icon>add</mat-icon>
</button>
