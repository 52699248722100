import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-zone-modal',
  templateUrl: './zone-modal.component.html',
  styleUrls: ['./zone-modal.component.css']
})
export class ZoneModalComponent implements OnInit {

  locationForm: FormGroup;
  typeSelect: number;
  headquarterSelect: number;
  headquarters = [];
  edit = false;
  showNewPin = false;
  image: File;
  imagePin: File;
  showError = false;
  types = []
  imagenZone = 'Seleccionar Imagen de Zona';
  imagenPin = 'Seleccionar Imagen de Pin';

  constructor(
    public dialogRef: MatDialogRef<ZoneModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private zoneService: ZoneService,
    private headquarterService: HeadquarterService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) {
    var systems = this.data.systems;
      if(systems.Va > 0 || systems.va > 0){
        this.types.push({
          id: 1,
          name: "Videoanalítica"
        });
      }

      if(systems.Iot > 0 || systems.iot > 0){
        this.types.push({
          id: 2,
          name: "IOT"
        });
      }
    this.typeSelect = this.types[0].id;
  }

  ngOnInit() {
    this.createForm();
    this.headquarters = this.data.headquarters;
    this.setHeadquarter();
    if(this.data.action == AppConstants.OperationType.ADD){
      if(this.data.idHeadquarter == 0) this.edit = true;

      if(this.data.idHeadquarter != 0){
        this.headquarterSelect = this.data.idHeadquarter;
      }
    }

    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.edit = true;
      this.headquarterSelect = this.data.obj.idHeadquarter;
      this.typeSelect = this.data.obj.type;
      this.locationForm.controls['name'].setValue(this.data.obj.name);
      this.locationForm.controls['description'].setValue(this.data.obj.description);
      this.locationForm.controls['nomenclature'].setValue(this.data.obj.nomenclature);
      this.imagenZone = "Seleccionar una nueva imagen de Zona";
      var pines = this.data.pines.filter(x=>x.idHeadquarter == this.data.obj.idHeadquarter);
      if(pines.length>0){
        this.showNewPin = false;
      }else{
        this.showNewPin = true;
      }
    }
  }

  setHeadquarter(){
    if(this.data.idHeadquarter > 0){
      var headquarters = JSON.parse(localStorage.getItem(AppConstants.Session.HEADQUARTERS));
      var _this = this;
      var list = headquarters.filter(x => x.idEnterprise == _this.data.idEnterprise);
          if(list.length > 0){
            _this.headquarters = list;
            _this.headquarterSelect = list[0].id;
            
            var pines = _this.data.pines.filter(x=>x.idHeadquarter == _this.headquarterSelect);
            if(pines.length>0){
              _this.showNewPin = false;
            }else{
              _this.showNewPin = true;
            }
          }
    }else{
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.data.idEnterprise);
          if(this.headquarters.length > 0){
            if(this.data.idHeadquarter == 0){
              this.headquarterSelect = this.headquarters[0].id;
            }else{
              this.headquarterSelect = this.data.idHeadquarter;
            }
            var pines = this.data.pines.filter(x=>x.idHeadquarter == this.headquarterSelect);
            if(pines.length>0){
              this.showNewPin = false;
            }else{
              this.showNewPin = true;
            }
          }
        }else{
          this.headquarters = [];
        }
        this.spinner.hide();
      });
    }
    
  }

  createForm(){
    this.locationForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(50)]],
      description: ['', []],
      nomenclature: ['', [Validators.required,  Validators.maxLength(30),  Validators.minLength(2)]],
      idZoneType: ['', [Validators.required]],
      idHeadquarter: ['', []],
      namePin: []
    })
  }

  get f() { return this.locationForm.controls; }

  save(){
    if(this.locationForm.valid){
      this.showError = false;
      if(this.image == null && this.data.action == AppConstants.OperationType.ADD){
        this.alertService.showMessage(
          "Dede adjuntar una imagen de la zona",
          "",
          MessageSeverity.error);
          return false;
      }
      const payload = {
        name:this.locationForm.value["name"],
        nomenclature:this.locationForm.value["nomenclature"],
        description:this.locationForm.value["description"] ? this.locationForm.value["description"] : "",
        type: this.typeSelect,
        id: this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0,
        idHeadquarter: this.headquarterSelect,
        idEnterprise: this.data.idEnterprise,
        idUser: localStorage.getItem(AppConstants.Session.USERID),
        file: this.image
      }
      if(this.data.action == AppConstants.OperationType.ADD){

        if(this.showNewPin){
          if(this.imagePin == null){
            this.alertService.showMessage(
              "Dede adjuntar una imagen del pin",
              "",
              MessageSeverity.error);
              return false;
          }
          if(this.locationForm.value["namePin"] == null || this.locationForm.value["namePin"] == ""){
            this.alertService.showMessage(
              "El nombre del pin es requerido",
              "",
              MessageSeverity.error);
              return false;
          }
          this.savePin();
        }

        this.spinner.show();
        this.zoneService.create(payload).subscribe(_ => {
          if(_.message == null ||_.message == ""){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_GATEWAY, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
          this.spinner.hide();
        });
      }else{
        this.zoneService.update(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ZONE, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
          this.spinner.hide();
        });
      }
    }else{
      this.locationForm.markAllAsTouched();
      this.showError = true;
    }
  }

  savePin(){
    var data = new FormData();
    data.append('id', (this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0));
    data.append('name', this.locationForm.value["namePin"]);
    data.append('file', this.imagePin);
    data.append('idHeadquarter', this.headquarterSelect.toString());
    data.append('idEnterprise', this.data.idEnterprise);
    data.append('idUser', localStorage.getItem(AppConstants.Session.USERID));
      this.zoneService.createPin(data).subscribe(_ => {
        if(_ == null){}else{
          this.alertService.showMessage(
            _.message,
            "",
            MessageSeverity.error);
        }
      });
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
    if(this.headquarterSelect > 0 && this.typeSelect == 1  && this.data.action == AppConstants.OperationType.ADD){
      var pines = this.data.pines.filter(x=>x.idHeadquarter == this.headquarterSelect);
      if(pines.length>0){
        this.showNewPin = false;
      }else{
        this.showNewPin = true;
      }
    }else{
      this.showNewPin = false;
    }
  }

  changeType(data) {
    this.typeSelect = data.value;
    if(this.headquarterSelect > 0 && this.typeSelect == 1 && this.data.action == AppConstants.OperationType.ADD){
      var pines = this.data.pines.filter(x=>x.idHeadquarter == this.headquarterSelect);
      if(pines.length>0){
        this.showNewPin = false;
      }else{
        this.showNewPin = true;
      }
    }else{
      this.showNewPin = false;
    }
  }

  close() {
    this.dialog.closeAll();
  }

  onFileSelected(event) {
    var files = event.target.files;
    if (files.length > 0) {
      var file = files[0];
      if (file.type == "image/png" || file.type == "image/jpeg") {
        this.image = file;
        this.imagenZone = "Imagen de Zona Adjuntada";
      } else {
        this.imagenZone = "Seleccionar Imagen de Zona";
        this.image = null;
        this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
      }
    }else{
      this.imagenZone = "Seleccionar Imagen de Zona";
      this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
    }
  }

  onFileSelectedPin(event) {
    var files = event.target.files;
    if (files.length > 0) {
      var file = files[0];
      if (file.type == "image/png" || file.type == "image/jpeg") {
        this.imagePin = file;
        this.imagenPin = "Imagen de Pin Adjuntada";
      } else {
        this.imagenPin = "Seleccionar Imagen de Pin";
        this.imagePin = null;
        this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
      }
    }else{
      this.imagenZone = "Seleccionar Imagen de Pin";
      this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
    }
  }
}
