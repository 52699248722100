<div class="row title-modal" [style.color]="data.color" [style.borderColor]="data.color">
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
    <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container"  style="position:relative;margin-bottom: 60px;">
    <section class="table-responsive mt-3">
      <table class="table" mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <ng-container matColumnDef="graph">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> Gráfico </th>
          <td class="text-center" *matCellDef="let element"> {{element.graph}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> Acciones </th>
          <td class="text-center" *matCellDef="let element">
            <mat-icon [style.color]="data.colorFont" class="icon-cursor" *ngIf="data.enabledWrite" (click)="delete(element)">delete</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="12"
                     [hidePageSize]="true">
      </mat-paginator>
    </section>

    <button mat-fab class="fab-button-modal" [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite" aria-label="Add icon" (click)="add()" >
      <mat-icon>add</mat-icon>
    </button>
  </div>

</mat-dialog-content>
