import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CameraStreaming } from 'src/app/models/streaming/cameraStreaming';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-camera-streaming',
    templateUrl: './camera-streaming.component.html',
    styleUrls: ['./camera-streaming.component.css']
})

export class CameraStreamingComponent implements OnInit {

    loading: boolean = false;
    isAdministrator=false;
    isAdministratorStreaming=false;
    enterprises = [];
    enterpriseSelect: number = 0;
    enterpriseToken: string;
    enterprisesZones = [];
    enterpriseZoneSelect: number = 0;
    listCameras = new Array<CameraStreaming>();
    listInitCameras = new Array<CameraStreaming>();
    listStreaming = [];
    limitTitleStreaming = 15;
    inputSearch = "";
    headquarterSelect = 0;
    headquarters=[];

    constructor(
        private enterpriseService: EnterpriseService,
        private streamingService: StreamingService,
        private spinner: NgxSpinnerService,
        public dialog: MatDialog,
        private headquarterService: HeadquarterService,
        private alertService: AlertService
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'Streaming > <span class="font-weight-bold"> Cámaras</span>';
    }

    ngOnInit() {
      ///this.getEnterprises();
      if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.AdministradorGyS) this.isAdministrator = true;
      if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.AdministradorEmpresaStreaming) this.isAdministratorStreaming = true;
      this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
      this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
      this.getCameras();
    }

    changeHeadquarter(data) {
      this.headquarterSelect = data.value;
      this.getCameras();
    }

    showStreaming(token: string,enterprise: number){
        var port = document.location.port;
        if(port){ port = ":"+port;}
        var hostname = document.location.protocol +'//'+ document.location.hostname + ((port != "80") ? port : "");
        var url = hostname+"/streaming/camera/" + token;
        if(enterprise == 1){
          url = hostname+"/streaming/enterprise/" + token;
        }
        window.open(url, '_blank').focus();
    }

    copyIframe(token: string){
      this.spinner.show();
        this.streamingService.getIframeCamera(token).subscribe(_ => {
          if(_.error == null){
            let listener = (e: ClipboardEvent) => {
              e.clipboardData.setData('text/plain', _.iframe);
              e.preventDefault();
            };
            document.addEventListener('copy', listener);
            document.execCommand('copy');
            document.removeEventListener('copy', listener);
            this.alertService.showMessage("Se copió el link del iframe de la cámara", "", MessageSeverity.success);
          }
          this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
            this.spinner.hide();
        });
    }

    getEnterprises() {
        this.spinner.show();
        this.enterpriseService.getEnterprises().subscribe((response) => {
            /*this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
            this.enterpriseSelect = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE));*/
            this.enterpriseToken = response.filter(f => f.enterpriseId == this.enterpriseSelect)[0].token.toString();
           // this.getHeadquarters();
            //this.getZonesByEnterprise(this.enterpriseSelect);
          //  this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
            this.spinner.hide();
        });
    }

    getHeadquarters() {
     // if (this.isAdminGys) {
        //this.spinner.show();
        this.headquarterService.getListAll().subscribe((response) => {
          if(response.error == null){
            this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
            this.headquarterSelect = 0;
          }else{
            this.headquarters = [];
          }
          this.getCameras();
          //this.spinner.hide();
        },
          (error: HttpErrorResponse) => {
            this.headquarters = [];
            this.spinner.hide();
          });
      //} else {
       // this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
      //}
    }

    changeEnterprise(data) {
      this.enterpriseSelect = data.value;
      this.listCameras = [];
      this.listStreaming = [];
      this.enterpriseToken = this.enterprises.filter(f => f.enterpriseId == this.enterpriseSelect)[0].token;
      this.getHeadquarters();
      //this.getZonesByEnterprise(this.enterpriseSelect);
    }

    getZonesByEnterprise(enterpriseId: number) {
        this.enterprisesZones = [];
        this.spinner.show();
        this.enterpriseService.getEnterpriseById(enterpriseId).subscribe((response => {
            if (response.enterpriseZone.length > 0) {
                this.enterprisesZones = response.enterpriseZone;
                this.enterpriseZoneSelect = this.enterprisesZones[0]["zoneId"];
            }
            this.spinner.hide();
        }),
        (error: HttpErrorResponse) => {
            this.spinner.hide();
        });
    }

    getCameras() {
        this.spinner.show();
        this.listCameras = new Array<CameraStreaming>();
        this.streamingService.getCameraByEnterprise(this.enterpriseSelect,this.headquarterSelect).subscribe((res => {
          if(res.error == null){
            this.listCameras = res.camera;
            this.listCameras.forEach(function (value) {
              value.play = false;

              if(value.streamingImage == null && !value.streamingImage){ value.streamingImage = "../../../../../assets/images/sin_image.jpg"; }
              //else{ value.streamingImage =  environment.pathFile + value.streamingImage;}

              if(value.streamingDescription == null && !value.streamingDescription){ value.streamingDescription = ""; }
            });
            this.listInitCameras = this.listCameras;
          }
          this.spinner.hide();
        }),
        (error: HttpErrorResponse) => {
            this.spinner.hide();
        });
    }

    playStreaming(id){
      var item = this.listCameras.filter(x => x.cameraId == id);
      if(item){
        item[0].play = true;
      }
    }

   /* changeZone(data) {
      var item = this.enterprisesZones.filter(f => f.zoneId == data.value);
      this.enterpriseToken = item[0].token;
      this.listStreaming = [];
      this.getCameras(data.value);
    }*/

    loadStream(data:CameraStreaming){
       var id = "video"+data.cameraId.toString();
       let searchData = this.listStreaming.filter(x => x.id.toString().startsWith(id));
        if(searchData.length > 0 ){
            for(let i = 0;this.listStreaming.length > i;i++){
                if(this.listStreaming[i].id == id){
                    this.listStreaming.splice(i,1);
                }
            }
        }else{
            this.listStreaming = this.listStreaming.concat({
                                                            id:id,
                                                            name:data.camera,
                                                            linkStre:data.streamingUrl,
                                                            /*urlFile: data.streamingFile,
                                                            streamingKey: data.streamingKey,*/
                                                            token: data.token
                                                            });
        }
    }


    search(){
      if(this.inputSearch != ""){
        this.listCameras = this.listCameras.filter(x => x.camera.toLowerCase().indexOf(this.inputSearch.toLowerCase()) != -1);
      }else{
        this.listCameras = this.listInitCameras;
      }
    }
}
