import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CameraService } from 'src/app/services/camera/camera.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Chart } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import { MessageSeverity, AlertService } from 'src/app/shared/services/alert.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';

@Component({
  selector: 'app-modal-report-video-analitica-show-room',
  templateUrl: './modal-report-video-analitica-show-room.component.html',
  styleUrls: ['./modal-report-video-analitica-show-room.component.css']
})
export class ModalReportVideoAnaliticaShowRoomComponent implements OnInit {
 
  cameraSelect: number;
  cameraAlertType = [];
  reportForm: FormGroup;
  myDate = new Date();
  alertSelect: number = 0;
  date = new Date();
  dateF = new Date();
  timeI: string;
  timeF: string;
  dateV:string;
  isAdministrator=false;
  dateIView:string;
  dateFView:string;
  colors = ["red","blue","green","yellow","black","gray","purple","lightgray"];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings;
  chartItem:any;

  labelsChart = [];
  dataChart:any;
  
  constructor(
    private cameraService: CameraService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private AlertService: AlertService,
    private formBuilder: FormBuilder,
    private showRoomService: ShowRoomService
  ) {
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id_CameraAlert',
      textField: 'c_Alert',
      tooltipField: 'Tipo de Alerta',
      selectAllText: 'Todos',
      unSelectAllText: 'Limpiar',
      searchPlaceholderText: 'Buscar',
      noFilteredDataAvailablePlaceholderText: 'Item no encontrado',
      itemsShowLimit: 3,
      allowSearchFilter: false, //input search
    };
  }

  ngOnInit(): void {
    this.cameraSelect = 228;//235;//
    this.createForm();
    this.listAlertType();
    this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
    this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
    this.dateV = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.graphLine();
  }

  graphLine(){

    this.chartItem=new Chart("myChart", {
      type: 'line',
      data: this.dataChart,
      options: {
        scales: {
          xAxes: [{
              ticks: {
                  autoSkip: false,
                  maxRotation: 75,
                  minRotation: 75
              }
          }
        ]
        },
        plugins: {
          legend: {
            display: true
          },
          title: {
            display: true,
            text: 'Cantidad de Alertas por Tipo'
          },
          datalabels: {
            display: true,
          }
        },
        events: [],
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          onComplete: function({ chart }) {
            const ctx = chart.ctx;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            chart.config.data.datasets.forEach(function(dataset, i) {
              const meta = chart.getDatasetMeta(i);

              meta.data.forEach(function(bar, index) {
                if (dataset.data[index] > 0) {
                  var data = dataset.data[index];
                  ctx.fillText(data.toString(), bar.x, bar.y - 7);
                }
              });
            });
          }
        },
      }
    });
  }

  removeData(chart) {
      chart.data.labels.pop();
      chart.data.datasets.forEach((dataset) => {
          dataset.data.pop();
      });
      chart.update();
  }
 

  createForm(){
    this.reportForm = this.formBuilder.group({
      startDateReport: ['', [Validators.required]],
      endDateReport: ['', [Validators.required]],
      startTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
      endTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
      alertTypes: []
    });
  }

  listAlertType(){
    this.cameraService.GetAlertByCamera(this.cameraSelect,0).subscribe((response) => {
      this.cameraAlertType = response;
      this.selectedItems = this.cameraAlertType;
      this.reportForm.patchValue({
        alertTypes: this.selectedItems 
      });
    },
      (error: HttpErrorResponse) => {
        this.cameraAlertType = []
      });
  }

  _keyUp(event: any) {
    let charCode = (event.which) ? event.which : event.keyCode;
      console.log(event.keyCode)
      if (charCode != 46 && charCode != 58 && charCode > 31
        && (charCode < 48 || charCode > 57)) {
        return false;
      }
  }


  get f() { return this.reportForm.controls; }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  closeModal(){
    this.dialog.closeAll();
  }

  search(){
    var alerts = [];
    this.selectedItems.forEach(x => {
      alerts.push(x.id_CameraAlert);
    });

    var filter = {
      dateI: this.reportForm.value["startDateReport"],
      dateF: this.reportForm.value["endDateReport"],
      timeI: this.timeI,
      timeF: this.timeF,
      idAlert: alerts
    };
    filter.dateI = this.datePipe.transform(filter.dateI, 'yyyy-MM-dd');
    filter.dateF = this.datePipe.transform(filter.dateF, 'yyyy-MM-dd');
    this.showRoomService.reportVideoanalitica(filter).subscribe(
      result => {
        if(result.error == null){
          this.chartItem.destroy();
          var data = result.data;
          var labels = [];
          var labelAlerts = [];
          var datasets = [];
          var values = [];
          var i = 0;
          data.forEach(x=>{
            var label = this.selectedItems.filter(y => y.id_CameraAlert == x.idAlert)[0].c_Alert;
            x.data.forEach(y=>{
              labels.push(y.date);
              values.push(y.qty);
            });
            datasets.push({
              label: label,
              data: values,
              borderColor: this.colors[i],
              backgroundColor: this.colors[i],
            });
            values = [];
            i++;
          })
          this.dataChart = {
            labels: this.removeDuplicates(labels),
            datasets: datasets
          };
          this.graphLine();
        }
        
      },
      (error: HttpErrorResponse) => {
        this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
    });
  }

  removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
  }
}
