import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { GatewayService } from 'src/app/services/gateway/gateway.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { SensorService } from 'src/app/services/sensor/sensor.service';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { SensorModalComponent } from 'src/app/shared/components/modals/sensor/sensor-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.css']
})
export class SensorComponent implements OnInit {
  loading: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['enterprise','headquarter','gateway','name','description','nomenclature','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isAdminGys: boolean = false;
  countMaxUserByEnterprise: number = 0;
  enterprises = [];
  headquarters = [];
  gateways = [];
  subscription: Subscription;
  enterpriseSelect: number = 0;
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION;
  length: number = 0;
  page: number = 1;
  headquarterSelect: number = 0;
  gatewaySelect: number = 0;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  searchText: string = '';
  timer:any;
  enabledRead: number;
  enabledWrite: number;
	timeoutVal = 800;

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private router: Router,
    private enterpriseService: EnterpriseService,
    private spinner: NgxSpinnerService,
    private emitterService: EmitterService,
    private sensorService: SensorService,
    private headquarterService: HeadquarterService,
    private gatewayService: GatewayService,
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'Administración > <span class="font-weight-bold">Sensores</span>';
    }

  ngOnInit() {
    this.setLS();
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.getGateways();
    //this.getList(1);

    this.subscription = this.emitterService.getMessage().subscribe(message => {
      if (message == AppConstants.EmitterService.ListUsers) {
        this.getList();
      }
      if (message == AppConstants.EmitterService.ListUsersEdit) {
        this.getList();
      }
    });
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  changeEnterprise(data) {
    this.countMaxUserByEnterprise = this.enterprises.find(f => f.enterpriseId == data.value).maximumUsers;
    this.enterpriseSelect = data.value;
    this.gatewaySelect = 0;
    this.headquarterSelect = 0;
    this.headquarters = [];
    this.gateways = [];
    this.getHeadquarters();
  }

  getEnterprises() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.enterpriseService.getEnterprises().subscribe((response) => {
        this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
        this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
        this.getHeadquarters();
        this.getList();
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    } else {
      this.enterpriseSelect = +localStorage.getItem(AppConstants.Session.ENTERPRISE);
      this.getEnterpriseById(this.enterpriseSelect);
      this.getList();
    }
  }

  getHeadquarters() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
          if(this.headquarters.length > 0){
            this.headquarterSelect = 0;
          }
          this.getGateways();
        }else{
          this.headquarters = [];
          this.gateways = [];
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.headquarters = [];
          this.gateways = [];
          this.spinner.hide();
        });
    } else {
      this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
      this.getList();
      this.getGateways();
    }
  }

  getGateways(){
    this.gatewayService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
      if(response.error == null){
        this.gateways = response.data;
        if(this.gateways.length > 1){
          this.gatewaySelect = 0;
        }else{
          this.gatewaySelect = this.gateways[0].id;
        }
        this.getList();
      }else{
        this.gateways = [];
      }
    },
      (error: HttpErrorResponse) => {
        this.gateways = [];
      });

  }


  getEnterpriseById(enterpriseId: number) {
    this.spinner.show();
    this.enterpriseService.getEnterpriseById(enterpriseId).subscribe((response => {
      this.countMaxUserByEnterprise = response.maximumUsers;
      this.spinner.hide();
    }),
    (error: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getList(){
    var filter = {
      Page: this.page,
      Rows: AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION,
      IdEnterprise: this.enterpriseSelect,
      IdHeadquarter: this.headquarterSelect,
      IdGateway: this.gatewaySelect,
      Filter: this.searchText
    };
    this.spinner.show();
    this.sensorService.getList(filter).subscribe((_) => {
      if(_.error == null){
        this.dataSource = new MatTableDataSource(_["rows"]);
        this.pageIndex = +_["page"]-1;
        this.length = _["total"];
      }
      this.spinner.hide();
    });
  }

  getServerData(event?: PageEvent) {
    this.page = event.pageIndex + 1;
    this.getList();
  }

  openAddModal() {
    let dialogRef = this.dialog.open(SensorModalComponent, {
      data: {
        title: AppConstants.TitleModal.CREATE_SENSOR,
        action: AppConstants.OperationType.ADD,
        gateways: this.gateways,
        headquarters: this.headquarters,
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        idGateway: this.gatewaySelect,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        isAdminGys: this.isAdminGys ,
        color: this.color1,
        colorFont: this.color2,
        obj:null},
      width: '500px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        this.page = 1;
        this.paginator.pageIndex = 0;
        this.getList();
      }
    });
  }

  openEditModal(data){
      let dialogRef = this.dialog.open(SensorModalComponent, {
        data: {
          title: AppConstants.TitleModal.UPDATE_SENSOR,
          action: AppConstants.OperationType.UPDATE,
          gateways: this.gateways,
          headquarters: this.headquarters,
          idEnterprise: this.enterpriseSelect,
          idHeadquarter: this.headquarterSelect,
          idGateway: this.gatewaySelect,
          isAdminGys: this.isAdminGys,
          enabledWrite: this.enabledWrite,
          enabledRead: this.enabledRead,
          color: this.color1,
          colorFont: this.color2,
          obj:data},
        width: '500px',
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(_ => {
        if (_.result == true) {
          this.getList();
        }
      });
  }

  delete(obj) {
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
        message: `${AppConstants.MessageModal.DELETE_DEFAULT}`,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteSecurity(obj.id);
      }
    });

  }

    deleteSecurity(id: number) {
      this.spinner.show();
      this.sensorService.delete(id).subscribe((response => {
        this.spinner.hide();
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SENSOR, AppConstants.MessageModal.DELETE, MessageSeverity.success);
        this.getList();
      }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SENSOR, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
    }

    changeHeadquarter(data) {
      this.headquarterSelect = data.value;
      this.getGateways();
      this.getList();
    }

    changeGateway(data) {
      this.gatewaySelect = data.value;
      this.getList();
    }

    applyUpFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.searchText = filterValue.trim().toLowerCase();
        var _this = this;
        window.clearTimeout(_this.timer);
          _this.timer = window.setTimeout(() => {
              _this.page = 1;
              _this.paginator.pageIndex = 0;
              _this.getList();
            }, _this.timeoutVal);
    }

    applyPressFilter(event: Event) {
      var _this = this;
      window.clearTimeout(_this.timer);
    }
}
