<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
    <mat-icon color="primary"  [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
    <form [formGroup]="securityForm" action=""  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
      <div class="row">
          <div class="col-12">
              <mat-form-field class="w-100">
                  <mat-label>Nombre</mat-label>
                  <input matInput formControlName="name" maxlength="50">
                  <mat-error *ngIf="f.name.hasError('required')">
                      El Nombre es <strong>requerida</strong>.
                  </mat-error>
              </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label>IP</mat-label>
                <input matInput formControlName="ip" >
                <mat-error *ngIf="f.ip.hasError('required')">
                    La IP <strong>requerida</strong>.
                </mat-error>
            </mat-form-field>
         </div>
          <div class="col-12">
          <mat-form-field class="w-100">
              <mat-label>RTMP (Ejemplo: rtmp://example.com o http://10.21.33.1)</mat-label>
              <input matInput formControlName="streamingRtmp" maxlength="500">
              <mat-error *ngIf="f.streamingRtmp.hasError('required')">
                RTMP es <strong>requerido</strong>.
              </mat-error>
          </mat-form-field>
          </div>
            <div class="col-12">
              <mat-form-field class="w-100">
                  <mat-label>FILE (Ejemplo: https://example.com o http://10.21.33.1)</mat-label>
                  <input matInput formControlName="streamingFile" maxlength="500">
                  <mat-error *ngIf="f.streamingFile.hasError('required')">
                      El Link File es <strong>requerido</strong>.
                  </mat-error>
              </mat-form-field>
          </div>
      </div>
  </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button  [style.color]="data.colorFont" [style.background]="data.color" (click)="save()" *ngIf="data.enabledWrite">GUARDAR</button>
</mat-dialog-actions>
