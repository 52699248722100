import { Component, Input, OnInit } from '@angular/core';
import * as Chart from 'chart.js'

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.css']
})
export class ChartLineComponent implements OnInit {
  @Input('id') id : any;
  @Input('type') type : any;
  @Input('idCanvas') idCanvas : any;
  @Input('labelsChart') labelsChart : any;
  @Input('valuesChart') valuesChart : any;
  @Input('colorArray') colorArray : any;
  @Input('optionFilter') optionFilter : any;
  @Input('showroom') showroom : any;

  public chart: any;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const labels = this.labelsChart;
    const arrayData = this.valuesChart;
    var data;
    if(this.showroom == "1"){
      data = {
        labels: labels,
        datasets: [
          {
            data: arrayData,
            borderColor: function(context) {
              const chart = context.chart;
              const {ctx, chartArea} = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getGradient(ctx, chartArea);
            },
          },
        ]
      };
    }else{
      data = {
        labels:labels, //
        datasets: arrayData
      };
    }

    let width, height, gradient;
    function getGradient(ctx, chartArea) {
      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'blue');
        gradient.addColorStop(0.5, 'yellow');
        gradient.addColorStop(1, 'red');
      }

      return gradient;
    }
    console.log('chartLine'+this.idCanvas);
    this.chart = new Chart('chartLine'+this.idCanvas,{
      type: 'line',
      data: data,
      options:{
        scales: {
            ticks: {
              autoSkip: false,
              maxRotation: 75,
              minRotation: 75
          },

          /* xAxes:[
                {
                scaleLabel: {
                  display: true,
                  labelString: 'probability'
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 75,
                    minRotation: 75
                }
            }
          ]*/
        /* },
        yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'probability'
            }
          }]*/
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }
}
