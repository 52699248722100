<div class="header-streaming">
  <img src="../../../../assets/images/logo-claro.svg">
</div>
<div class="content-live">

    <div class="videoStreaming" >
      <div class="panel-header">
        <div class="video-initial panel-streaming-title">
          <h4 class="title"  >{{title}}</h4>
          <h4 class="description"  >{{description}}</h4>
        </div>
        <div class="search-container">
          <div class="search-input">
            <input type="text" class="input-search"  (keyup)="search()" [(ngModel)]="inputSearch" placeholder="Buscar Zona">
          </div>
          <div class="search-icon">
            <img src="../../../assets/svg/search.svg" width="50px">
          </div>
          <div class="search-icon-mobile">
            <img src="../../../assets/svg/search.svg" width="50px" (click)="showSearchMobile();" >
          </div>
        </div>
      </div>
      <div class="panel-streaming">
          <div class="video-initial">
            <div class="video-streaming" *ngIf="showLive">
              <app-streampublic id="stream_{{id}}" [id]="id" [url]="url" [child]=false ></app-streampublic>
            </div>
            <div class="content-video-initial">
              <div class="title-video">
                <mat-label class="text-label-video-initial"  >{{camera}}</mat-label>
                <mat-label class="text-label-video-initial-description"  >{{cameraDescription}}</mat-label>
              </div>
            </div>
          </div>
          <div class="list-videos">
            <div class="list-item-video" *ngFor="let item of cameras" >
              <div class="list-item-video-content" (click)="openCamera(item)">
                <div class="list-video">
                  <img src="{{item.streamingImage}}" class="image-preview-streaming" >
                </div>
                <div class="video-text">
                  <mat-label class="item-video-title"   >{{(item.camera.length>limitLengthTitleStreaming)? (item.camera | slice:0:limitLengthTitleStreaming)+'...':(item.camera)}}</mat-label>
                  <mat-label class="item-video-description"   >{{(item.streamingDescription.length>limitLengthDescriptionStreaming)? (item.streamingDescription | slice:0:limitLengthDescriptionStreaming)+'...':(item.streamingDescription)}}</mat-label>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <!--<div class="">
      <button mat-raised-button (click)="showStreaming()">Vista Múltiple - TOP 4</button>
    </div>-->
</div>
<div class="line-mobile"></div>
<div class="footer-streaming">
  <div class="social-media" >
    <div>
      <img src="../../../../assets/images/logo-claro.svg" class="image-social-media" >
    </div>
    <div>
      <ul class="images-social-media">
        <li><a href="https://www.facebook.com/AmericaMovilPeruSAC" target="_blank"><img src="../../../../assets/images/social-media/facebook.svg"></a></li>
        <li><a href="https://www.instagram.com/claroperu" target="_blank"><img src="../../../../assets/images/social-media/instagram.svg"></a></li>
        <li><a href="https://twitter.com/ClaroPeru" target="_blank"><img src="../../../../assets/images/social-media/twitter.svg"></a></li>
        <li><a href="https://www.youtube.com/channel/UCsMp8a5skhqS72uCRwNdZdA" target="_blank"><img src="../../../../assets/images/social-media/youtube.svg"></a></li>
      </ul>
    </div>
  </div>
  <div class="text-copyright" >
    <h5>Todos los derechos reservados 2022</h5>
  </div>
</div>
<div class="panel-search-mobile" *ngIf="searchMobile">
  <div class="div-search-mobile">
    <div class="div-search-image-arrow-left" (click)="showSearchMobile();">
      <img src="../../../assets/images/arrow-left.png" width="30px" height="30px" >
    </div>
    <div class="div-input-search-mobile">
      <input type="text" class="input-search-mobile"  (keyup)="searchInputMobile()" [(ngModel)]="inputSearchMobile" placeholder="Buscar Zona">
    </div>
  </div>
  <div>
    <div class="item-streaming-mobile" *ngFor="let item of cameras" (click)="openCamera(item)">
        <div class="div-search-image-arrow-left">
          <img src="../../../assets/svg/search.svg" width="30px" height="30px" >
        </div>
        <div class="div-input-search-mobile">
          <mat-label class="item-streaming-title"   >{{(item.camera.length>limitLengthTitleStreaming)? (item.camera | slice:0:limitLengthTitleStreaming)+'...':(item.camera)}}</mat-label>
        </div>
    </div>
  </div>
</div>
