import { Component, OnInit,OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { StreamingDomainIframeService } from 'src/app/services/streamingDomainIframe/streamingDomainIframe.service';
import { StreamingStadisticsService } from 'src/app/services/streamingStadistics/streamingStadistics.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-streaming',
  templateUrl: './view-streaming.component.html',
  styleUrls: ['./view-streaming.component.css']
})
export class ViewStreamingComponent implements OnInit{
  token: string;
  url: string;
  urlFile: string;
  streamingKey: string;
  id: string;
  title: string;
  description: string;
  camerasInit = [] as any;
  cameras = [] as any;
  camera: string;
  cameraDescription: string;
  logo: string;
  text: string;
  searchMobile = false;
  logoEnterprise: string;
  showLive = false;
  textLive = ''; //Validando cámara
  inputSearch: string;
  inputSearchMobile: string;
  limitLengthTitleStreaming = 25;
  limitLengthDescriptionStreaming = 40;
  idCamera = 0;
  urlStadistics: string;
  successValidate = 0;
  enterpriseId = 0;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private streamingStadisticsService: StreamingStadisticsService,
    private streamingDomainIframeService: StreamingDomainIframeService,
    private streamingService: StreamingService) {
      this.spinner.show();
    }

  ngOnInit(){
    this.urlStadistics = (window.location != window.parent.location)
    ? document.referrer
    : document.location.href;
    if(!this.urlStadistics){
      if(window.location.ancestorOrigins != null){
        this.urlStadistics = window.location.ancestorOrigins[0];
      }
    }

    /*if(this.urlStadistics == ""){
      this.title = 'SU NAVEGADOR NO SOPORTA LA TRANSMISIÓN';
      this.saveStadistics();
      this.spinner.hide();
    }else{
      this.streamingDomainIframeService.validateDomain(this.urlStadistics).subscribe( _ => {
        if(_.error == null && _.validate){*/
          this.title = 'Tráfico en vivo';
          this.successValidate = 1;
          this.loadData();
        /*}else{
          this.title = 'NO TIENE PERMISOS PARA VER LA TRANSMISIÓN';
          this.saveStadistics();
          this.spinner.hide();
        }
      });
    }*/
  }

  saveStadistics(){
    var data = {
      successValidate: this.successValidate,
      originDomain: this.urlStadistics,
      idCamera: this.idCamera,
      idEnterprise: this.enterpriseId,
      origin: 'vista individual'
    }
    this.streamingStadisticsService.createStadistics(data).subscribe(_ => {});
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: { preventDefault: () => void; }) {
    event.preventDefault();
  }

  loadData(){
      this.route.params.subscribe(params => {
        this.token = params['token'];
        this.streamingService.getCameraToken(this.token).subscribe( _ => {
          if(_.camera != null){
            var camera = _.camera;
            if(environment.logoPrincipal != ""){
              this.logo = environment.logoPrincipal;
            }

            if(environment.logoEnterprise != ""){
              this.logoEnterprise = environment.logoEnterprise;
            }

            document.body.style.background = environment.themeColor1 + " !important";
            this.text = environment.themeColor2;
            //this.urlFile = camera.streamingFile;
            //this.streamingKey = camera.streamingKey;
            this.url = camera.streamingUrl;
            _.cameras.forEach(function (value) {
              if(value.streamingImage == null && !value.streamingImage){
                //value.streamingImage = "../../../../../assets/images/sin_image.jpg";
                value.streamingImage = "../../../../../assets/images/logo-claro.png";
              }
              else value.streamingImage =  value.streamingImage;
            });
            this.cameras = _.cameras;
            this.enterpriseId = _.camera.enterpriseId;
            this.id = "video"+camera.cameraId.toString();
            this.camera = camera.camera;
            this.idCamera = camera.cameraId;
            this.camerasInit =  _.cameras;
            this.cameraDescription = camera.streamingDescription;
            this.title = _.enterprise.title;
            this.description = _.enterprise.description;
            this.showLive = true;
          }else{
            this.textLive = 'No se encontró la cámara';
          }
          this.saveStadistics();
          this.spinner.hide();
        });
     });

     var width = screen.width;
     if(width <= 390 && width > 360){
        this.limitLengthTitleStreaming = 8;
        this.limitLengthDescriptionStreaming = 38;
      }
     if(width <= 360){
        this.limitLengthTitleStreaming = 6;
        this.limitLengthDescriptionStreaming = 35;
     }
  }

  showStreaming(){

  }

  openCamera(item:any){
    var port = document.location.port;
    if(port){ port = ":"+port;}
    var hostname = document.location.protocol +'//'+ document.location.hostname + ((port != "80") ? port : "");
    var url = hostname+"/streaming/camera/" + item.token;
    window.location.href = url;
  }

  search(){
    if(this.inputSearch != ""){
      this.cameras = this.cameras.filter((x: { camera: string; }) => x.camera.toLowerCase().indexOf(this.inputSearch.toLowerCase()) != -1);
    }else{
      this.cameras = this.camerasInit;
    }
  }

  searchInputMobile(){
    if(this.inputSearchMobile != ""){
      this.cameras = this.cameras.filter((x: { camera: string; }) => x.camera.toLowerCase().indexOf(this.inputSearchMobile.toLowerCase()) != -1);
    }else{
      this.cameras = this.camerasInit;
    }
  }

  showSearchMobile(){
    this.searchMobile = !this.searchMobile;
  }

}
