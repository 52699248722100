import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { CameraTopStreaming } from 'src/app/models/streaming/cameraTopStreaming.js';
import { StreamingDomainIframeService } from 'src/app/services/streamingDomainIframe/streamingDomainIframe.service';
import { StreamingStadisticsService } from 'src/app/services/streamingStadistics/streamingStadistics.service';

@Component({
  selector: 'app-multiple-streaming',
  templateUrl: './multiple-streaming.component.html',
  styleUrls: ['./multiple-streaming.component.css']
})
export class MultiplestreamingComponent implements OnInit{
  token: string;
  logo: string;
  text: string;
  logoEnterprise: string;
  hideLive = true;
  titleMultipleStreaming = 'Validando...';
  descriptionMultipleStreaming = '';
  textLive = 'Validando empresa';
  cameras = Array<CameraTopStreaming>();
  iconRigth: string;
  showControls = true;
  enterpriseId = 0;
  iconLeft: string;
  url: string;
  successValidate = 0;

  constructor(
    private route: ActivatedRoute,
    private streamingDomainIframeService: StreamingDomainIframeService,
    private streamingStadisticsService: StreamingStadisticsService,
    private spinner: NgxSpinnerService,
    private streamingService: StreamingService) {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.iconLeft = environment.pathFile + "assets/svg/left-pointer-carousel.svg";
      this.iconRigth = environment.pathFile + "assets/svg/right-pointer-carousel.svg";
    });
  }

  ngOnInit(){
    this.url = (window.location != window.parent.location)
    ? document.referrer
    : document.location.href;
    if(!this.url){
      if(window.location.ancestorOrigins != null){
        this.url = window.location.ancestorOrigins[0];
      }
    }

    /*if(this.url == ""){
      this.titleMultipleStreaming = 'SU NAVEGADOR NO SOPORTA LA TRANSMISIÓN';
      this.spinner.hide();
      this.saveStadistics();
    }else{
      this.streamingDomainIframeService.validateDomain(this.url).subscribe( _ => {
        if(_.error == null && _.validate){*/
          this.titleMultipleStreaming = 'Tráfico en vivo';
          this.successValidate = 1;
          this.descriptionMultipleStreaming = 'Visualiza y selecciona las zonas del tráfico que deseas';
          this.loadData();
        /*}else{
          this.descriptionMultipleStreaming = "";
          this.titleMultipleStreaming = 'NO TIENE PERMISOS PARA VER LA TRANSMISIÓN';
          this.saveStadistics();
          this.spinner.hide();
        }
      });
    }*/
  }


  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }


  saveStadistics(){
    var data = {
      successValidate: this.successValidate,
      originDomain: this.url,
      idEnterprise: this.enterpriseId,
      idCamera: 0,
      origin: 'vista múltiple'
    }
    this.streamingStadisticsService.createStadistics(data).subscribe(_ => {});
  }

  loadData(){
    this.streamingService.getCameraEnterpriseToken(this.token).subscribe( _ => {
        console.log(_);
      if(_.error == null){
        if(environment.logoPrincipal != ""){
          this.logo = environment.logoPrincipal;
        }

        if(environment.logoEnterprise != ""){
          this.logoEnterprise = environment.logoEnterprise;
        }

        document.body.style.background = environment.themeColor1 + " !important";
        this.text = environment.themeColor2;

        this.hideLive = false;
        this.enterpriseId = _.cameras[0].enterpriseId;
        _.cameras.forEach(function (value) {
            if(value.streamingImage == null && !value.streamingImage){
              //value.streamingImage = "../../../../../assets/images/sin_image.jpg";
              value.streamingImage = "../../../../../assets/images/logo-claro.png";
            }
            else value.streamingImage =  environment.pathFile + value.streamingImage;
        });
        this.cameras = _.cameras;
        console.log(this.cameras);
        var _this = this;
        setTimeout(function(){
          _this.loadCarousel();
          _this.spinner.hide();
        }, 5000);
      }else{
        this.textLive = 'No se encontró la empresa';
        this.spinner.hide();
      }
      this.saveStadistics();
    });

    var win = window,
    doc = document,
    docElem = doc.documentElement,
    body = doc.getElementsByTagName('body')[0],
    x = win.innerWidth || docElem.clientWidth || body.clientWidth,
    y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
    //alert(x + ' × ' + y);
  }

  loadCarousel(){
    var slide = $('.slider-single');
    var slideTotal = slide.length - 1;
    var slideCurrent = -1;
    //var _intervalRight: string | number | NodeJS.Timeout | undefined;
    var _this = this;
    function slideInitial() {
      if(slide.length == 1){
        _this.showControls = false;
        slide.addClass('active');
      }else{
        slide.addClass('proactivede');
        slideRight();
      }
    }
    function slideRight() {
      //clearInterval(_intervalRight);
      //_intervalRight = setInterval(slideRight, 999999); //4500
      if (slideCurrent < slideTotal) {
        slideCurrent++;
      } else {
        slideCurrent = 0;
      }

      if (slideCurrent > 0) {
        var preactiveSlide = slide.eq(slideCurrent - 1);
      } else {
        var preactiveSlide = slide.eq(slideTotal);
      }
      var activeSlide = slide.eq(slideCurrent);
      if (slideCurrent < slideTotal) {
        var proactiveSlide = slide.eq(slideCurrent + 1);
      } else {
        var proactiveSlide = slide.eq(0);

      }

      slide.each(function() {
        var thisSlide = $(this);
        if (thisSlide.hasClass('preactivede')) {
          thisSlide.removeClass('preactivede preactive active proactive').addClass('proactivede');
        }
        if (thisSlide.hasClass('preactive')) {
          thisSlide.removeClass('preactive active proactive proactivede').addClass('preactivede');
        }
      });
      preactiveSlide.removeClass('preactivede active proactive proactivede').addClass('preactive');
      activeSlide.removeClass('preactivede preactive proactive proactivede').addClass('active');
      proactiveSlide.removeClass('preactivede preactive active proactivede').addClass('proactive');
    }

    function slideLeft() {
      //clearInterval(_intervalRight);
     // _intervalRight = setInterval(slideRight, 4500);
      if (slideCurrent > 0) {
        slideCurrent--;
      } else {
        slideCurrent = slideTotal;
      }

      if (slideCurrent < slideTotal) {
        var proactiveSlide = slide.eq(slideCurrent + 1);
      } else {
        var proactiveSlide = slide.eq(0);
      }
      var activeSlide = slide.eq(slideCurrent);
      if (slideCurrent > 0) {
        var preactiveSlide = slide.eq(slideCurrent - 1);
      } else {
        var preactiveSlide = slide.eq(slideTotal);
      }
      slide.each(function() {
        var thisSlide = $(this);
        if (thisSlide.hasClass('proactivede')) {
          thisSlide.removeClass('preactive active proactive proactivede').addClass('preactivede');
        }
        if (thisSlide.hasClass('proactive')) {
          thisSlide.removeClass('preactivede preactive active proactive').addClass('proactivede');
        }
      });
      preactiveSlide.removeClass('preactivede active proactive proactivede').addClass('preactive');
      activeSlide.removeClass('preactivede preactive proactive proactivede').addClass('active');
      proactiveSlide.removeClass('preactivede preactive active proactivede').addClass('proactive');
    }
    var left = $('.slider-left');
    var right = $('.slider-right');
    left.on('click', function() {
      slideLeft();
    });
    right.on('click', function() {
      slideRight();
    });
    slideInitial();
    var _isOpen = false;
    $(".dropbtn").on('click', function(e) {
      if (_isOpen) {
        $("#myDropdown").slideUp(100);
        _isOpen = false;
      }
      else {
        $("#myDropdown").slideDown(100);
        _isOpen = true;
      }
      e.stopPropagation();
    });

    $(window).on('click', function() {
      $("#myDropdown").hide();
      _isOpen = false;
    });


    $(".dropbtnbir").on('click', function(e) {
      if (_isOpen) {
        $("#myDropdownbir").slideUp(300);
        _isOpen = false;
      }
      else {
        $("#myDropdownbir").slideDown(300);
        _isOpen = true;
      }
      e.stopPropagation();
    });
  }

  showCamera(token:string){
    var port = document.location.port;
    if(port){ port = ":"+port;}
    var hostname = document.location.protocol +'//'+ document.location.hostname + ((port != "80") ? port : "");
    var url = hostname+"/streaming/camera/" + token;
    window.location.href = url;
    this.spinner.show();
  }
}
