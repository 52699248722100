<div class="container">
  <section class="filter">
      <form [formGroup]="reportForm" action="">
        <div class="row">
          <div class="col-md-8 col-lg-8">
                <mat-form-field class="w-100" color="primary">
                  <mat-label>Tipo de alerta</mat-label>
                  <mat-select formControlName="alertReport" [(ngModel)]="alertSelect" (selectionChange)="changeAlert($event)">
                    <mat-option [value]="0" *ngIf="cameraAlertType.length > 0">
                      Todos
                    </mat-option>
                    <mat-option *ngFor="let cameraAlerts of cameraAlertType" [value]="cameraAlerts.id_Alert">
                      {{cameraAlerts.c_Alert}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
          </div>
          <div class="col-md-4 col-lg-4" style="padding: 12px;">
            <button mat-button class="mr-3 btn-color" (click)="showReport()">VER REPORTE</button>
            <!--<button mat-button class="mr-3 btn-color" (click)="searchAlerts()">VER ALERTAS</button>
            <button mat-button class="mr-3 btn-color" (click)="showAllAlert()">VER TODOS</button>-->
          </div>
        </div>
      </form>
  </section>

  <section class="filter mb-3">
    <div class="row">
      <div class="col-lg-7 col-md-7 ex4" >
        <div style="    display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        height: 100%;">
          <img [src]="imgAlert" width="400px" height="250px">
        </div>
      </div>
      <!--
        <div (dragover)="false"
             (dragend)="false">
             <canvas id="canvas" width="720" height="520" style="border-style: solid; border-color:#D9CDAB"></canvas>
          </div>-->
      <div class="col-lg-5 col-md-5">
          <div class="col-12" style="border: solid 1px #31bfe9;border-radius: 20px;box-shadow: 0px 0px 10.5px #31bfe9;">
              <div class="labelAlerta mt-2">
                <span>Estado de alertas</span>
                <span>Nº de Alertas: {{countAlerts}}</span>
              </div>
                  <div class="mouseOverNav scroll">
                      <ul class="list-group">
                          <li  *ngFor="let item of cameraAlert; let i = index"
                              class="list-group-item list-group-items" [ngStyle]="{'cursor':'pointer','background-color': '#31bfe9', 'color': '#fff'}"
                              (click)="alerta(item.ocurennceId,0)">
                              <div style="display: flex;justify-content: center;">
                                  <div style="display: flex;width: 10%;justify-content: center;"><i class="fa fa-map-marker fa-lg" style="margin-left:-8px;"></i> </div>
                                  <div style="display: flex;width: 90%;"><label style="cursor:pointer;" class="lableAlertView"><b>Cámara:</b> {{item.camera}} - <b>Alerta:</b> {{item.alert}}</label></div>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>