export const AppConstants = {
    Paginado: {
        DEFAULT_PAGE_INDEX_PAGINATION: 0,
        DEFAULT_PAGE_SIZE_PAGINATION: 12
    },

  TitleModal: {
    LOGIN_TITLE: "Inicio Sesión.",
    REGISTER_TITLE: "Registro.",
    UPDATE_TITLE: "Actualizar",
    ERROR_TITLE: "Error",
    WARNING_TITLE: "Advertencia",
    LICENSE_EXPIRATION_TITLE: "¡Vencimiento de Licencia!",
    DATA_ERROR_TITLE: "Error de Datos",
    DELETE_CONFIRMATION_TITLE: "Eliminar",
    REGISTER_TITLE_USER_MODAL: 'Registrar Usuario',
    TITLE_USER_ASSING_MODAL: 'Listado de Empresas/Sedes',
    TITLE_USER_NEW_ASSING_MODAL: 'Agregar nueva Empresas/Sedes',
    DELETE_TITLE_USER_ASSING_CONFIRM: 'Eliminar Relación de Empresa/Sede',
    EDIT_TITLE_USER_MODAL: 'Editar Usuario',
    REGISTER_TITLE_USER_CONFIRM: 'Registrar Usuario',
    DELETE_TITLE_USER_CONFIRM: 'Eliminar',

    CREATE_CAMERA: 'Crear Cámara',
    UPDATE_CAMERA: 'Actualizar Cámara',
    DELETE_CAMERA: 'Eliminar Cámara',

    CREATE_ENTERPRISE: 'Crear Empresa',
    UPDATE_ENTERPRISE: 'Actualizar Empresa',
    DELETE_ENTERPRISE: 'Eliminar Empresa',
    CREATE_ENTERPRISE_CONTRACT: 'Crear Contrato',
    UPDATE_ENTERPRISE_CONTRACT: 'Actualizar Contrato',
    DIRECTORY_STORAGE_ENTERPRISE: 'Configuración de directorios de archivos',
    UPDATE_ENTERPRISE_SETTING: 'Configuración',
    SETTING_SYSTEM_ENTERPRISE: 'Sistemas permitidos',
    SETTING_STREAMING_ENTERPRISE: 'Streaming',

    CREATE_USER: 'Crear Usuario',
    UPDATE_USER: 'Actualizar Usuario',
    DELETE_USER: 'Eliminar Usuario',
    DELETE_USER_ASSIGN: 'Eliminar Relación Empresa/Usuario',

    CREATE_SECURITY: 'Crear dominio',
    UPDATE_SECURITY: 'Actualizar dominio',
    DELETE_SECURITY: 'Eliminar dominio',

    CREATE_STREAMING_SERVER: 'Crear Servidor',
    UPDATE_STREAMING_SERVER: 'Actualizar servidor',
    DELETE_STREAMING_SERVER: 'Eliminar servidor',

    CONFIRM_TITLE: "Confirmar",
    CONFIRM_TITLE_RECORDER: "Grabar ubicación",
    DELETE_SMS_CONFIRMATION_TITLE: "Eliminar Destino de SMS",
    REGISTER_MESSAGE_USER_CONFIRM: '',
    UPLOAD_FILES: "Adjuntar Archivos",
    DOWNLOAD_FILES: "Descargar Archivos",

    DELETE_SMS: 'Destinataro Eliminado',
    CREATE_OCURRENCE: 'Atención de Alarmas',
    CREATE_SMS: 'Registrar Destinatario',
    UPDATE_SMS: 'Actualizar Destinatario',
    ADD_ZONE_CAMERA: 'Agregar Zona de Ubicación',
    UPDATE_CAMERA_ZONE: 'Actualizar Zona de Ubicación',
    ERROR_DATOS: '¡Error de datos!',
    ERROR_LICENCE: '¡Vencimiento de licencia!',

    ENTERPRISE_SETTING_UPDATE: "Actualizar Configuración",
    ENTERPRISE_STREAMING_UPDATE: "Actualizar Streaming",

    CREATE_SERVER_STREAMING: 'Crear Servidor',
    UPDATE_SERVER_STREAMING: 'Actualizar Servidor',
    DELETE_SERVER_STREAMING: 'Eliminar Servidor',

    CREATE_HEADQUARTER: 'Crear Sede',
    UPDATE_HEADQUARTER: 'Actualizar Sede',
    DELETE_HEADQUARTER: 'Eliminar Sede',

    CREATE_ROLE: 'Crear Rol',
    UPDATE_ROLE: 'Actualizar Rol',
    DELETE_ROLE: 'Eliminar Rol',

    CREATE_GATEWAY: 'Crear Gateway',
    UPDATE_GATEWAY: 'Actualizar Gateway',
    DELETE_GATEWAY: 'Eliminar Gateway',

    CREATE_DASHBOARD: 'Crear Dashboard',
    UPDATE_DASHBOARD: 'Actualizar Dashboard',
    DELETE_DASHBOARD: 'Eliminar Dashboard',

    CREATE_WIDGET: 'Crear Widget',
    UPDATE_WIDGET: 'Actualizar Widget',
    DELETE_WIDGET: 'Eliminar Widget',

    CREATE_SENSOR_READINGPLACE: 'Crear Lugar de lectura de Sensores',
    UPDATE_SENSOR_READINGPLACE: 'Actualizar Lugar de lectura de Sensores',
    DELETE_SENSOR_READINGPLACE: 'Eliminar Lugar de lectura de Sensores',

    CREATE_SENSOR_TYPE: 'Crear Tipo de Sensor',
    UPDATE_SENSOR_TYPE: 'Actualizar Tipo de Sensor',
    DELETE_SENSOR_TYPE: 'Eliminar Tipo de Sensor',

    LIST_SENSOR_TYPE_ASSIGN: 'Lista de Gráfico asociados a ',
    CREATE_SENSOR_TYPE_ASSIGN: 'Añadir Gráfico al Tipo de Sensor',
    DELETE_SENSOR_TYPE_ASSIGN: 'Eliminar Gráfico de Sensor',

    CREATE_SENSOR: 'Crear Sensor',
    UPDATE_SENSOR: 'Actualizar Sensor',
    DELETE_SENSOR: 'Eliminar Sensor',

    CREATE_ACTUATOR: 'Crear Actuador',
    UPDATE_ACTUATOR: 'Actualizar Actuador',
    DELETE_ACTUATOR: 'Eliminar Actuador',

    CREATE_ZONE: 'Crear Zona',
    UPDATE_ZONE: 'Actualizar Zona',
    DELETE_ZONE: 'Eliminar Zona',

    LIST_PIN_MAP_TITLE: "Lista de PINES",
    CREATE_PIN: 'Crear PIN',
    UPDATE_PIN: 'Actualizar PIN',
    DELETE_PIN: 'Eliminar PIN',

    UPDATE_HEADQUARTER_FILE: 'Actualizar Configuración de Archivos',
    ADD_SENSOR_PANEL: 'Agregar Sensor a Panel',
    ADD_ACTUATOR_PANEL: 'Agregar Actuador a Panel',
    UPDATE_SENSOR_PANEL: 'Actualizar Sensor en Panel',

    CREATE_USER_RELATIONSHIP: 'Añadir empresa a usuario',
    UPDATE_USER_RELATIONSHIP: 'Actualizar empresa a usuario',
    LIST_ACTUATOR_PANEL_IOT: 'Lista de Actuadores',
    POWER_BI: 'URL Power BI',
  },

  MessageModal: {
    LICENSE_EXPIRATION_MESSAGE: "La empresa xxxxxxxxx tiene la licencia vencida desde el día 21/02/20. Comunicarse al teléfono 01-555-5678.",
    DATA_ERROR_MESSAGE: "Los datos ingresados son incorrectos o no se encuentran en el sistema. Solo dispone de 2 intentos más.",
    DELETE_DEFAULT: "¿Estás seguro que deseas Eliminar este item?",
    DELETE_CAMERA_CONFIRMATION_MESSAGE1: "¿Estás seguro que deseas Eliminar la cámara",
    DELETE_SECURITY_CONFIRMATION_MESSAGE1: "¿Estás seguro que deseas eliminar el dominio?",
    DELETE_EMAIL_CONFIRMATION_MESSAGE1: "¿Estás seguro que deseas eliminar el correo?",
    DELETE_SERVER_CONFIRMATION_MESSAGE1: "¿Estás seguro que deseas eliminar el servidor?",
    DELETE_CAMERA_CONFIRMATION_MESSAGE2: "Luego de completada la acción no se podrá deshacer.",
    DELETE_SECURITY_CONFIRMATION_MESSAGE2: "Luego de completada la acción no se podrá deshacer.",
    UPDATE_CAMERA: 'Se actualizó la cámara correctamente.',
    DELETE_ENTERPRISE_CONFIRMATION_MESSAGE1: "¿Estás seguro que deseas Eliminar la empresa",
    DELETE_ENTERPRISE_CONFIRMATION_MESSAGE2: "Luego de completada la acción no se podrá deshacer.",
    REGISTER_MESSAGE_USER_CONFIRM: '¿Está seguro que desea registrar al usuario?',
    DELETE_USER_CONFIRMATION_MESSAGE1: "¿Estás seguro que deseas Eliminar al usuario ",
    DELETE_USER_ASSIGN_CONFIRMATION_MESSAGE1: "¿Estás seguro que deseas Eliminar esta relación?",
    DELETE_USER_CONFIRMATION_MESSAGE2: "Luego de completada la acción no se podrá deshacer.",
    UPDATE_ENTERPRISE: 'Se actualizó la empresa correctamente.',
    CREATE: 'Se creó el registro correctamente.',
    DELETE: 'Se eliminó el registro correctamente.',
    UPDATE_USER: 'Se actualizó el usuario correctamente.',
    UPDATE_SECURITY: 'Se actualizó el dominio correctamente.',
    UPDATE_MESSAGE_USER_CONFIRM: '¿Está seguro que desea actualizar al usuario?',
    UPDATE: 'Se actualizó correctamente.',

    REGISTER_MESSAGE_ALERT_ERROR_CONFIRM: '¿Está seguro que desea marcar esta alerta como falso positivo?',
    REGISTER_MESSAGE_ALERT_CONFIRM: '¿Está seguro que desea atender la alarma?',
    REGISTER_MESSAGE_LOCATION_CONFIRM: '¿Está Seguro de Registrar la nueva ubicación de las cámaras?',
    DELETE_SMS_CONFIRMATION_MESSAGE: '¿Está seguro de eliminar al destinatario de SMS',
    EDIT_MESSAGE_SMS_CONFIRM: '¿Está seguro que desea actualizar al destinatario?',
    SOMETHING_WRONG: "Un error interno ha ocurrido.",
    NOT_AUTENTICATION: 'Usuario o Contraseña incorrecta.',
    UPLOAD_FILE_INCORRECT: "Debe seleccionar un archivo .JPG, .JPGE o .PNG",
    UPLOAD_FILE_INCORRECT_CONTRACTS: "Debe seleccionar un archivo .PDF, .ZIP o .DOCX",
    UPLOAD_FILE_INCORRECT_CONTRACTS_SIZE: "El archivo adjunto no debe ser superior a 10 Mb.",
    UPLOAD_FILE_ZONE_DUPLICATE: "La Zona de Ubicación seleccionada ya ha sido agregada.",
    USERNAME_PASSWORD_ERROR_MESSAGE: "Contraseña incorrecto.",
    DOWNLOAD_FILE_MESSAGE: "Ocurrio un error en la descarga del archivo.",

    OCURRENCE_MESSAGER_CONFIRM: 'La alarma de ',
    OCURRENCE_NEXT_CONFIRM: ' se atendió correctamente',
    DATA_REPET: 'La alerta ya ha sido agregada',
    DATA_NULL: 'Agregar al menos una alerta.',
    CREATE_SMS: 'Destinatario Creado',
    UPDATE_SMS: 'Destinatario Actualizado',
    REQUIRED_FIELDS_MESSAGE: "Faltan campos por completar.",
    REGISTER_MESSAGE_SMS_CONFIRM: '¿Está seguro que desea registrar al destinatario?',
    ADD_ZONE_CAMERA_MESSAGE_DUPLICATE: 'La Camara Seleciona ya se encuentra Añadida al Plano.',
    UPDATE_CAMERA_ZONE: 'Se actualizó correctamente.',
    REGISTER_MESSAGE_CAMERA_CONFIRM: '¿Está seguro que desea registrar la cámara?',
    EDIT_MESSAGE_CAMERA_CONFIRM: '¿Está seguro que desea actualizar la cámara?',
    DATA_REPET_CAMERA: 'de la camara ya se encuentra registrada para la empresa.',
    DATA_NULL_REQUIRED: 'Faltan datos de la cámara por completar.',
    DATA_NULL_REQUIRED_PARAMTER: 'Seleccione al menos un alerta y sus parámetros.',
    DATA_NULL_SEARCH: 'No hay resultados para el filtro seleccionado.',
    CAMERA_PCURRENCE_NOT_DELETE: 'La alerta de la cámara ya cuenta con ocurrencias registradad.',

    SEARCH_ALERT_NOT_SELECTED_HEATMAP: 'Seleccione la cámara y el tipo de alerta para poder mostrar las alertas.',
    UPDATE_MESSAGE_ENTERPRISE_SETTING_CONFIRM: '¿Estás seguro que deseas actualizar la configuración?',
    UPLOAD_FILE_PINMAP_DUPLICATE: "El Pin del mapa seleccionada ya ha sido agregada.",
    UPDATE_ENTERPRISE_SETTING: 'Se actualizó los datos de la configuración correctamente.',
    UPDATE_MESSAGE_ENTERPRISE_STREAMING_CONFIRM: '¿Estás seguro que deseas actualizar el streaming?',
    UPDATE_ENTERPRISE_STREAMING: 'Se actualizó los datos del streaming correctamente.',
    REQUIRED_ENTERPRISE_SETTING_PATH_FILE: "Debe colocar la ruta de archivo si elige el modo local",

    CAMERA_EXIST_ZONE: "Ya exista la cámara en la zona.",
    FILTER_DATES_VALIDATE: 'La fecha de inicio debe ser antes o igual que la fecha fin',
    FILTER_CAMERA_VALIDATE: 'Debe seleccionar al menos una cámara',
    FILTER_SENSOR_VALIDATE: 'Debe seleccionar al menos un sensor',
    PANELIOT_ACTUATOR_DATA: "Se actualizo la data de los actuadores correctamente",
  },

    TypeModal: {
        REGISTER : 'REGISTER',
        EDIT: 'EDIT'
    },
    Session: {
        ACCESS_TOKEN: 'ACCESS_TOKEN',
        TOKEN_TYPE: 'TOKEN_TYPE',
        USER_TYPE: 'USER_TYPE',
        ROLE: 'ROLE',
        MENU_ROLE: 'MENU_ROLE',
        REFRESH_TOKEN: 'REFRESH_TOKEN',
        EXPIRATION_TIME: 'EXPIRATION_TIME',
        USERNAME: 'USERNAME',
        LANGUAGE: 'LANGUAGE',
        USERDATA: 'USERDATA',
        LAST_URL: 'LAST_URL',
        USERID: 'USERID',
        ENTERPRISE: 'ENTERPRISE',
        ENTERPRISES: 'ENTERPRISES',
        HEADQUARTER: 'HEADQUARTER',
        HEADQUARTERS: 'HEADQUARTERS',
        ROLID: 'ROLID',
        ROLNAME: 'ROLNAME',
        FIRST_PAGE: 'FIRST_PAGE',
        MENU: 'MENU',
        THEME: 'THEME',
        THEME_COLOR1: 'THEME_COLOR1',
        THEME_COLOR2: 'THEME_COLOR2',
        THEME_COLOR_FONT: 'THEME_COLOR_FONT',
        THEME_COLOR_FONT2: 'THEME_COLOR_FONT2',
        USER: 'USER',
        ENTERPRISE_SYSTEM: 'ENTERPRISE_SYSTEM',
        MENU_ICON: 'MENU_ICON',
        MENU_TITLE: 'MENU_TITLE',
        CHANGE_ENTERPRISE_MENU: 'CHANGE_ENTERPRISE_MENU',
        LOGO: 'LOGO_GYS',
    },

  UserRole: {
    ADMIN: 'ADMIN',
    CLIENT: 'CLIENT',
    ENTERPRISE: 'ENTERPRISE',
    ENTERPRISE_USER: 'ENTERPRISE_USER',
    AdministradorGyS: 4,
    UsuarioGyS: 5,
    UsuarioGySComercial: 6,
    AdministradorEmpresa: 7,
    UsuarioEmpresa: 8,
    AdministradorEmpresaStreaming: 13
  },

  OperationType: {
    ADD: 1,
    UPDATE: 2,
    DELETE: 3
  },

  EmitterService: {
    ListEnterprise: 1,
    ListUsers: 2,

    ListSms: 3,
    ListPanelCamara: 4,
    ListEnterpriseFilter:5,
    ListCamera: 6,
    ListUsersEdit: 7,

  },

  Flag: {
    Active: 1,
    Inactive: 0
  },

  BlobStorage: {
    ZoneFiles: 3,
    ContractsFiles: 5,
    AdendasFiles: 8
  },

  Auth:{
    TitleForm: 'Acceso a plataforma',
    DescriptionForm: 'Comienza ha gestionar tu empresa',
    TitlePasswordForm: 'Olvide mi contraseña',
    DescriptionPasswordForm: 'Escribe tu correo para que te enviemos una nueva contraseña.'
  }

}

export const ConstantMessages = {

}

export const ArrayExtensionContracts = [
  "zip",
  "pdf",
  "docx"
];
