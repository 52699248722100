<div class="container">
      <!--<div class="row mt-3 section-name">
          <div class="col">
            Reporte de Sensores
          </div>
      </div>-->

    <section class="filter mt-3">
        <div class="col-12">
          <form [formGroup]="reportForm" action="">


            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
                  <mat-form-field class="w-100" color="primary">
                      <mat-label>Fecha inicio</mat-label>
                      <input matInput formControlName="startDateReport" readonly [matDatepicker]="picker1" maxlength="10" [(ngModel)] = "date" >
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 ></mat-datepicker>
                      <mat-error *ngIf="f.startDateReport.hasError('required')">
                        La fecha de Inicio es <strong> obligatorio</strong>
                      </mat-error>
                      <mat-error *ngIf="f.startDateReport.hasError('pattern')">
                        La fecha de Inicio<strong> no tiene el formato correcto</strong>
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Fecha Fin</mat-label>
                    <input matInput formControlName="endDateReport" readonly [matDatepicker]="picker2" maxlength="10" [(ngModel)] = "dateF">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 ></mat-datepicker>
                    <mat-error *ngIf="f.endDateReport.hasError('required')">
                      La fecha Final es<strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.endDateReport.hasError('pattern')">
                      La fecha Final<strong> no tiene el formato correcto</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Hora inicio</mat-label>
                    <input matInput formControlName="startTimeReport" maxlength="5" [(ngModel)]="timeI" value="{{timeI}}" (keypress)="_keyUp($event)">
                    <mat-error *ngIf="f.startTimeReport.hasError('required')">
                      La Hora Inicial es<strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.startTimeReport.hasError('pattern')">
                      La Hora Inicial tiene <strong> formato incorrecto</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                  <mat-form-field class="w-100" color="primary">
                      <mat-label>Hora fin</mat-label>
                      <input matInput formControlName="endTimeReport" maxlength="5" [(ngModel)]="timeF" value="{{timeF}}" (keypress)="_keyUp($event)">
                      <mat-error *ngIf="f.endTimeReport.hasError('required')">
                        La Hora Final es <strong> obligatorio</strong>
                      </mat-error>
                      <mat-error *ngIf="f.endTimeReport.hasError('pattern')">
                        La Hora Final tiene <strong> formato incorrecto</strong>
                      </mat-error>
                  </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!--<div class="col-12 col-lg-3" *ngIf="isAdminGys">
                  <mat-form-field class="w-100">
                      <mat-label>Empresa</mat-label>
                      <mat-select
                          formControlName="idEnterprise"
                          (selectionChange)="changeEnterprise($event)"
                           [(ngModel)]="enterpriseSelect">
                           <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                              {{enterprise.businessName}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>Sede</mat-label>
                  <mat-select
                              formControlName="idHeadquarter"
                              (selectionChange)="changeHeadquarter($event)"
                              [(ngModel)]="headquarterSelect">
                       <mat-option [value]="0" *ngIf="headquarters.length > 1">
                                Todas
                       </mat-option>
                       <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                          {{headquarter.name}}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>-->
              <div class="col-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>Zonas</mat-label>
                  <mat-select
                              formControlName="idZone"
                              (selectionChange)="changeZone($event)"
                              [(ngModel)]="zoneSelect">
                       <mat-option [value]="0" *ngIf="zones.length > 1">
                                Todas
                       </mat-option>
                       <mat-option *ngFor="let zone of zones" [value]="zone.id">
                          {{zone.name}}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>Gateway</mat-label>
                  <mat-select
                      formControlName="idGateway"
                      (selectionChange)="changeGateway($event)"
                      [(ngModel)]="gatewaySelect">
                      <mat-option [value]="0" *ngIf="gateways.length > 1">
                        Todos
                      </mat-option>
                      <mat-option *ngFor="let gateway of gateways" [value]="gateway.id">
                          {{gateway.name}}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12">
                <label class="mb-1" style="color: rgba(0, 0, 0, 0.6);font-size:13px">Actuadores y Sensores</label>
                <ng-multiselect-dropdown
                    formControlName = "idSensors"
                    [placeholder]="'Seleccionar'"
                    [settings]="dropdownSettings"
                    [data]="sensors"
                    [(ngModel)]="selectedItems"
                    (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
              </div>
              <div class="col-lg-3 col-md-3 col-xs-12">
                <button mat-raised-button class="btn-color w-100 mt-3"  *ngIf="zones.length > 0" [style.background]="color1" [style.color]="color2" (click)="search()">BUSCAR</button>
              </div>
            </div>
          </form>
        </div>
    </section>
    <section class="table-responsive mt-3">
      <mat-paginator #paginatorTop (page)="handlePaginatorBottom($event)" [pageSize]="12"  [hidePageSize]="true" ></mat-paginator>

      <table class="table" mat-table [dataSource]="dataSource" >

        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="enterprise">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Empresa </th>
          <td class="text-center" *matCellDef="let element"> {{element.enterprise}} </td>
        </ng-container>

        <ng-container matColumnDef="headquarter">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Sede </th>
          <td class="text-center" *matCellDef="let element"> {{element.headquarter}} </td>
        </ng-container>

        <ng-container matColumnDef="zone">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Zona</th>
          <td class="text-center" *matCellDef="let element"> {{element.zone}} </td>
        </ng-container>

        <ng-container matColumnDef="gateway">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Gateway </th>
          <td class="text-center" *matCellDef="let element"> {{element.gateway}} </td>
        </ng-container>

        <ng-container matColumnDef="isActuator">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"></th>
          <td class="text-center" *matCellDef="let element"> <span *ngIf="isActuator==1">Actuador</span> <span *ngIf="isActuator==1">Sensor</span>  </td>
        </ng-container>

        <ng-container matColumnDef="sensor">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Item</th>
          <td class="text-center" *matCellDef="let element"> {{element.sensor}} </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Valor</th>
          <td class="text-center" *matCellDef="let element"> {{element.value}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Fecha</th>
          <td class="text-center" *matCellDef="let element"> {{element.date}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <mat-paginator #paginatorBottom (page)="handlePaginatorTop($event)" [pageSize]="12" [hidePageSize]="true"></mat-paginator>
    </section>
  </div>
