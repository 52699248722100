
    <div #canvases>
        <div *ngFor="let item of dataSensors" class="modalPanel" [style.left.px]="item.posGraphX + 25" [style.top.px]="item.posGraphY - 34"  >
            <div  *ngIf="item.isActuator" [ngClass]="item.isActuator==1 ? 'actuatorInputModal'  : 'actuatorModal' " [ngStyle]="{'box-shadow': '0px 0px 10.5px'+color }" [id]="'actuactorPanel'+item.id" [style.display]="'none'" >
                <div class="icon-close" (click)="closeModal(item.id)" >
                    <mat-icon class="icon-c" [style.background]="color" [style.color]="colorFont">close</mat-icon>
                </div>
                <!-- Actuator  -->
                    <div *ngIf="item.idActuatorDataType == 1"> <!-- Campo texto -->
                      <div class="row" style="padding: 25px 10px;">
                        <div class="col-lg-12">
                          <input type="text" class="form-control text-center" [(ngModel)]="valueInput" >
                        </div>
                        <div class="col-lg-12 mt-1">
                          <button class="btn btn-default" [style.background]="color" [style.color]="colorFont" (click)="saveInput(item)" type="button">LIBERAR</button>
                          <span style="font-size: 12px;"  [style.color]="color"  >*Disponible: {{item.value-item.minAlert}} TN</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="item.idActuatorDataType == 2"> <!-- ON / OFF -->
                      <div class="countdown-gauge" id="countdown-gauge{{item.id}}" (click)="on_off(item)" *ngIf="item.value=='1'" >
                        <div class="toggle-gauge" style="box-shadow:0px 0px 10.5px #fff;padding:27px;border-color:#0eb94d"  ></div>
                        <span class="text-gauge">ON</span>
                      </div>
                      <div class="countdown-gauge" id="countdown-gauge{{item.id}}" (click)="on_off(item)" *ngIf="item.value=='0'" >
                        <div class="toggle-gauge" style="box-shadow:0px 0px 10.5px #fff;padding:27px;border-color:red"  ></div>
                        <span class="text-gauge">OFF</span>
                      </div>
                    </div>
            </div>
            <div  *ngIf="!item.isActuator" [ngClass]="item.isActuator==1 ? 'actuatorInputModal'  : 'actuatorModal' " [ngStyle]="{'box-shadow': '0px 0px 10.5px'+color }" [id]="'actuactorPanel'+item.id" [style.display]="'none'" >
              <div class="icon-close" (click)="closeModal(item.id)" >
                  <mat-icon class="icon-c" [style.background]="color" [style.color]="colorFont">close</mat-icon>
              </div>

              <!-- Sensor  -->
             
                   <div *ngIf="item.idGraph==1" class="item-actuator item-type-slider">
                      <div style="margin: 10px 0px;">
                        <div>
                          <div class="chart-container">
                              <canvas id="chartLineStreaming_{{item.nomenclature}}" ></canvas>
                              <div class="div-value-slider" >
                                  <span [ngStyle]="{'box-shadow': '0px 0px 10.5px'+color }">{{ item.nomenclature }}</span>
                                  <span class="value-slider" id="value-slider{{item.nomenclature}}" ></span>
                              </div>
                          </div>
                            <!-- <app-chart-line-streaming [id]="item.id" [name]="item.nomenclature"  [idCanvas]="item.id + '_'" [labelChart]="item.nomenclature"></app-chart-line-streaming> -->
                        </div>
                      </div>
                    </div>
          </div>
        </div>
    </div>
