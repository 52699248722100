<div class="container">
    <!--<div class="row mt-3 section-name">
        <div class="col">
          Panel IOT
        </div>
    </div>-->
    <section class="filter mt-3">
      <div class="row">
          <div class="col-12 col-lg-12">
              <div class="row">
                  <!--<div class="col-12 col-lg-3" *ngIf="isAdminGys">
                      <mat-form-field class="w-100">
                          <mat-label>Empresa</mat-label>
                          <mat-select
                                      (selectionChange)="changeEnterprise($event)"
                                      [(ngModel)]="enterpriseSelect">
                               <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                                  {{enterprise.businessName}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-3">
                    <mat-form-field class="w-100">
                      <mat-label>Sede</mat-label>
                      <mat-select
                                  (selectionChange)="changeHeadquarter($event)"
                                  [(ngModel)]="headquarterSelect">
                           <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                              {{headquarter.name}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>-->
                  <div class="col-12 col-lg-3">
                    <mat-form-field class="w-100">
                      <mat-label>Zonas</mat-label>
                      <mat-select
                                  (selectionChange)="changeZone($event)"
                                  [(ngModel)]="zoneSelect">
                           <mat-option *ngFor="let zone of zones" [value]="zone.id">
                              {{zone.name}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-3">
                    <mat-form-field class="w-100">
                      <mat-label>Gateway</mat-label>
                      <mat-select
                          (selectionChange)="changeGateway($event)"
                          [(ngModel)]="gatewaySelect">
                          <mat-option *ngFor="let gateway of gateways" [value]="gateway.id">
                              {{gateway.name}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-5">
                    Vista Gráfica
                    <mat-slide-toggle
                        toggleColor circle="{{color2}}" bar="#d0d0d0"
                        (change)="showView($event)"
                        class="example-margin mr-2">
                        Vista Tabla
                    </mat-slide-toggle>
                    <span style="font-size: 30px;margin: 0px 10px;">|</span>
                    <mat-slide-toggle
                        toggleColor circle="{{color2}}" bar="#d0d0d0"
                        (change)="showTable($event)"
                        class="example-margin mr-2">
                        Mostrar Paneles
                    </mat-slide-toggle>
                  </div>
                <!--  <div class="col-12 col-lg-1">
                    <button mat-raised-button [style.background]="color2" class="color-white" *ngIf="enabledWrite" (click)="save()">GUARDAR CAMBIOS</button>
                  </div>
                  <div class="col-12 col-lg-2">
                    <button mat-raised-button color="primary" class="color-white w-100 p-0" (click)="openAddModal()">AGREGAR SENSOR</button>
                  </div>-->
              </div>
          </div>
      </div>
  </section>

  <section *ngIf="displayListStadistics == 'none'">
    <div class="row" style="justify-content: center;z-index:1;position: relative;">
      <table class="table">
        <thead>
          <tr>
            <th>Item</th>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Nomenclatura</th>
            <th>Tipo de Gráfico</th>
            <th>Lugar de lectura</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of sensors" (click)="editSensor(item)" style="cursor: pointer;">
            <td>
              <span *ngIf="item.isActuator" >Actuador</span>
              <span *ngIf="!item.isActuator" >Sensor</span>
            </td>
            <td>{{item.sensor}}</td>
            <td>{{item.stateSensor}}</td>
            <td>{{item.nomenclature}}</td>
            <td>{{item.graph}}</td>
            <td>{{item.sensorReadingPlace}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

<!--
  <div class="btn-save" >
    Vista Gráfica
    <mat-slide-toggle
        (change)="showView($event)"
        [color]="colorToggle"
        class="example-margin mr-2">
        Vista Tabla
    </mat-slide-toggle>
    <span style="font-size: 30px;margin: 0px 10px;">|</span>
    <mat-slide-toggle

                        (change)="showTable($event)"
                        [color]="colorToggle"
                        class="example-margin mr-2">
                        Mostrar Paneles
                    </mat-slide-toggle>
    <button mat-raised-button [style.background]="color2" class="color-white" (click)="save()">GUARDAR CAMBIOS</button>
  </div>-->

  <section [style.display]="displayListStadistics"  >
    <div class="row" style="justify-content: center;z-index:1;">
        <div (dragover)="false" (dragend)="false" (mouseup)="handleDrop()" (mousedown)="doubleClick()" >
          <canvas id="canvas" width="1000" height="500" style="border-style: solid; border-color:#D9CDAB"></canvas>
          <!--<div class="legend-sensor-panel">
            <div class="legend-state"><div class="sensor-panel installed"></div> instalado</div>
            <div class="legend-state"><div class="sensor-panel actived"></div> activo</div>
            <div class="legend-state"><div class="sensor-panel inactived"></div> inactivo</div>
          </div>-->
        </div>
    </div>
  </section>

  </div>

  <button mat-fab class="fab-button3" *ngIf="enabledWrite && zones.length > 0" [style.background]="color1" aria-label="Add icon" (click)="save()" >
    <mat-icon>save</mat-icon>
  </button>

  <button mat-fab class="fab-button2" *ngIf="enabledWrite && zones.length > 0" [style.background]="color1" aria-label="Add icon" (click)="openAddActuatorModal()" >
    <mat-icon>settings_input_composite</mat-icon>
  </button>

  <button mat-fab class="fab-button" *ngIf="enabledWrite && zones.length > 0" [style.background]="color1" aria-label="Add icon" (click)="openAddModal()" >
    <mat-icon>select_all</mat-icon>
  </button>
