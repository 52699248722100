<div class="row">
  <div class="col-lg-8 col-xs-12">
    <!-- <mat-tab-group>
      <mat-tab label="Map-box">-->
    <!-- <mgl-map class="mapbox-gps"  [style]="'mapbox://styles/mapbox/streets-v11'" [zoom]="12" [center]="[origin[0], origin[1]]">
      <mgl-layer
        id="route"
        type="line"
        [source]="{
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [origin, destination]
            }
          }
        }"
        [layout]="{ 'line-join': 'round', 'line-cap': 'round' }"
        [paint]="{ 'line-color': '#888', 'line-width': 8 }">
      </mgl-layer>
    </mgl-map>

    <mgl-map
    class="mapbox-gps"
    [style]="'mapbox://styles/mapbox/streets-v11'"
    [zoom]="12"
    [center]="origin"
  >
    <mgl-layer
      id="route"
      type="line"
      [source]="{
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [origin,destination]
          }
        }
      }"
    ></mgl-layer>
  </mgl-map>
-->

        <div id="mapPanelGPS" class="mapbox-gps"></div>
      <!-- <agm-direction [origin]="originGM" [destination]="destinationGM" [travelMode]="'DRIVING'"></agm-direction>
     </mat-tab>
    <mat-tab label="Google Maps" >

    </mat-tab>

  </mat-tab-group>
   <div style="width: 500px;height:300px;margin:200px;">
     <agm-map [latitude]="lat" [longitude]="lng" [zoom]="12" [mapOptions]="mapOptions">
        <agm-marker [latitude]="originGM.lat" [longitude]="originGM.lng" label="Origen"></agm-marker>
        <agm-marker [latitude]="destinationGM.lat" [longitude]="destinationGM.lng" label="Destino"></agm-marker>
      </agm-map>
      <agm-map [latitude]="originGM.lat" [longitude]="originGM.lng" [zoom]="zoom" #agmMap>
        <agm-marker [latitude]="originGM.lat" [longitude]="originGM.lng" label="A"></agm-marker>
        <agm-marker [latitude]="destinationGM.lat" [longitude]="destinationGM.lng" label="B"></agm-marker>
      </agm-map>
      <div #mapContainer style="height: 400px;"></div>
    </div>-->
  </div>

  <div class="col-lg-4 col-xs-12">
    <h5 style="padding: 20px;text-align:center;"><b>Lista de GPS</b></h5>
    <div style="padding: 0px 20px;">
      <div *ngFor="let item of devices; let i = index" (click)="selectDirection(item)">

        <div class="mb-2 item-device-active" *ngIf="active == item.id" >{{item.name}}</div>
        <div class="mb-2 item-device" *ngIf="active != item.id" >{{item.name}}</div>
      </div>
    </div>
  </div>
</div>
