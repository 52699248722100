import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { GatewayService } from 'src/app/services/gateway/gateway.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-gateway-modal',
  templateUrl: './gateway-modal.component.html',
  styleUrls: ['./gateway-modal.component.css']
})
export class GatewayModalComponent implements OnInit {

  gatewayForm: FormGroup;
  headquarterSelect: number;
  headquarters = [];
  edit = false;
  disabledHeadquarter = false;

  constructor(
    public dialogRef: MatDialogRef<GatewayModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private headquarterService: HeadquarterService,
    private gatewayService: GatewayService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    this.headquarters = this.data.headquarters;
    this.setHeadquarter();
    if(this.data.action == AppConstants.OperationType.ADD){
      if(this.data.idHeadquarter == 0) this.edit = true;
      if(this.data.idHeadquarter){
        this.headquarterSelect = this.data.idHeadquarter;
      }
    }
    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.edit = true;
      this.headquarterSelect = this.data.obj.idHeadquarter;
      this.gatewayForm.controls['idHeadquarter'].setValue(this.data.obj.idHeadquarter);
      this.gatewayForm.controls['name'].setValue(this.data.obj.name);
      this.gatewayForm.controls['description'].setValue(this.data.obj.description);
      this.gatewayForm.controls['nomenclature'].setValue(this.data.obj.nomenclature);
      this.gatewayForm.controls['ip'].setValue(this.data.obj.ip);
      this.gatewayForm.controls['mac'].setValue(this.data.obj.mac);
    }
  }

  setHeadquarter(){
    if(this.data.idHeadquarter > 0){
      var headquarters = JSON.parse(localStorage.getItem(AppConstants.Session.HEADQUARTERS));
      var _this = this;
      var items = headquarters.filter(x => x.idEnterprise == _this.data.enterpriseId);
      if(items.length > 0){
        _this.headquarters = items;
        if(_this.data.idHeadquarter != 0){
          _this.headquarterSelect = items[0].id;
          _this.disabledHeadquarter = true;
        }
      }
    }else{
      this.getHeadquarters();
    }
  }


  getHeadquarters() {
    this.spinner.show();
    this.headquarterService.getListAll().subscribe((response) => {
      if(response.error == null){
        this.headquarters = response.data.filter(f => f.idEnterprise == this.data.idEnterprise);
        console.log("enterprises",this.headquarters[0]);
        if(this.data.obj != null){
          this.headquarterSelect = this.data.obj.idHeadquarter;
        }else{
          this.headquarterSelect = this.headquarters[0].id;
        }
      }else{
        this.headquarters = [];
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.headquarters = [];
        this.spinner.hide();
      });
}


  createForm(){
    this.gatewayForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(50)]],
      description: ['', []],
      nomenclature: ['', [Validators.required,  Validators.maxLength(30),  Validators.minLength(2)]],
      ip: ['', [Validators.required, Validators.maxLength(15) , Validators.minLength(7),
        Validators.pattern('(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
      mac: ['', [Validators.required,
      Validators.pattern('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$')]],
      idHeadquarter: ['', []]
    })
  }

  get f() { return this.gatewayForm.controls; }

  save(){
    if(this.gatewayForm.valid){
      const payload = {
        name:this.gatewayForm.value["name"],
        nomenclature:this.gatewayForm.value["nomenclature"],
        description:this.gatewayForm.value["description"],
        ip:this.gatewayForm.value["ip"],
        mac:this.gatewayForm.value["mac"],
        id: this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0,
        idHeadquarter: this.headquarterSelect,
        idEnterprise: this.data.idEnterprise,
        idUser: localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
      if(this.data.action == AppConstants.OperationType.ADD){
        this.gatewayService.create(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_GATEWAY, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
              this.spinner.hide();
          }
        });
      }else{
        this.gatewayService.update(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_GATEWAY, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
            this.spinner.hide();
          }
        });
      }
    }else{
      this.gatewayForm.markAllAsTouched();
    }
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
  }


  close() {
    this.dialog.closeAll();
  }
}
