import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActuatorModel } from 'src/app/models/actuator/actuator';
import { ActuatorService } from 'src/app/services/actuator/actuator.service';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-actuator-detail-panel-modal',
  templateUrl: './actuator-detail-panel-modal.component.html',
  styleUrls: ['./actuator-detail-panel-modal.component.css']
})
export class ActuatorDetailPanelModalComponent implements OnInit {
  
  actuators = Array<ActuatorModel>();
  
  constructor(
    public dialogRef: MatDialogRef<ActuatorDetailPanelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private actuatorService: ActuatorService,
    private paneliotService: PanelIOTService) { }

  ngOnInit() {
    this.list();
  }

  list(){
    this.actuatorService.getListAll(this.data.idEnterprise,this.data.idHeadquarter).subscribe((response) => {
      this.actuators = response.data.filter(x => x.idActuatorType == 1);
      console.log("now values",this.actuators);
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  save(){
    var data = {
      Data: this.actuators
    }
    this.paneliotService.updateActuatorData(data).subscribe(_ => {
      if(!_.message){
        this.alertService.showMessage("", AppConstants.MessageModal.PANELIOT_ACTUATOR_DATA, MessageSeverity.success);
        this.data.result = true;
        var totalguard = 0;
        this.actuators.forEach(x => {
          totalguard += x.minCapacity;
        });
        this.data.totalguard = totalguard;
        this.dialogRef.close(this.data);
      }else{
        this.alertService.showMessage(
          _.message,
          "",
          MessageSeverity.error);
          this.spinner.hide();
      }
    });
  }

  changeInputGuard(event:any,id){
    var value = (event.target as HTMLInputElement).value;
    this.actuators.forEach(x =>{
      if(x.id == id){
        console.log("min capacity "+ parseFloat(value));
        x.minCapacity = parseFloat(value);
      }
    });
  }

  changeInputDensity(event:any,id){
    var value = (event.target as HTMLInputElement).value;
    this.actuators.forEach(x =>{
      if(x.id == id){
        console.log("density "+ parseFloat(value));
        x.density = parseFloat(value);
      }
    });
  }

  changeInputRadio(event:any,id){
    var value = (event.target as HTMLInputElement).value;
    this.actuators.forEach(x =>{
      if(x.id == id){
        console.log("radio "+ parseFloat(value));
        x.radio = parseFloat(value);
      }
    });
  }
}
