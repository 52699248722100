<div class="row title-modal">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
        <mat-icon color="primary" mat-dialog-close (click)="closeDialog()">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="smsForm" action="">
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Apellidos y Nombres</mat-label>
                        <input matInput [(ngModel)]="name" formControlName="name" maxlength="80">
                        <mat-error *ngIf="f.name.hasError('required') || f.name.hasError('whitespace')">
                            Apellidos y Nombres es <strong>requerido</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.name.hasError('maxlength')">
                            Apellidos y Nombres debe tener <strong>80 carácteres</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Nº Celular</mat-label>
                        <input matInput [(ngModel)]="cellphone" maxlength="9" OnlyNumbers formControlName="cellphone">
                        <mat-error *ngIf="f.cellphone.hasError('required') || f.cellphone.hasError('whitespace')">
                            Celular es <strong>requerido</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.cellphone.hasError('maxlength')">
                            Celular debe tener <strong>9 carácteres máximo</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.cellphone.hasError('minlength')">
                            Celular debe tener <strong>9 carácteres mínimo</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Alerta</mat-label>
                        <mat-select [(ngModel)]="alertSelect" formControlName="alert">
                            <mat-option *ngFor="let item of listAlert" [value]="item.id_Alert">
                                {{item.c_Alert}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <button mat-raised-button color="primary" (click)="getArray()">AGREGAR</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-7">
                <section class="table-responsive mt-3">
                    <table class="table table-custom" mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="index">
                        <th scope="col" class="text-center" *matHeaderCellDef> # </th>
                        <td class="text-center" *matCellDef="let element; let i = index">
                        {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
                        </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="id">
                        <th scope="col" class="text-center" *matHeaderCellDef> # </th>
                        <td class="text-center" *matCellDef="let j = index"> {{j + 1}} </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="nameAlert">
                        <th scope="col" class="text-center" *matHeaderCellDef> Alerta </th>
                        <td class="text-center" *matCellDef="let element"> {{element.nameAlert}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th scope="col" class="text-center" *matHeaderCellDef> Acción </th>
                        <td class="text-center" *matCellDef="let element">
                        <mat-icon color="primary" class="icon-cursor" (click)="delete(element)">delete</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                </section>
                </div>
                <div class="col-12 col-md-5 my-auto">
                    <mat-checkbox labelPosition="before" formControlName="status">Habilitado</mat-checkbox>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="closeDialog()">REGRESAR</button>
    <button mat-raised-button color="primary" (click)="openConfirmationModal()">GUARDAR</button>
</mat-dialog-actions>

