import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-dashboard-modal',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.css']
})
export class DashboardModalComponent implements OnInit {
  dashboardForm: FormGroup;
  headquarterSelect: number;
  headquarters = [];
  edit = false;
  disabledHeadquarter = false;

  constructor(
    public dialogRef: MatDialogRef<DashboardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.edit = true;
      this.dashboardForm.controls['name'].setValue(this.data.obj.name);
    }
  }

  createForm(){
    this.dashboardForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(30),  Validators.minLength(2)]]
    })
  }

  get f() { return this.dashboardForm.controls; }

  save(){
    if(this.dashboardForm.valid){
      const payload = {
        name:this.dashboardForm.value["name"],
        id: this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0,
        idEnterprise: this.data.idEnterprise,
        idUser: localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
      if(this.data.action == AppConstants.OperationType.ADD){
        this.dashboardService.create(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_DASHBOARD, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
              this.spinner.hide();
          }
        });
      }else{
        this.dashboardService.update(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_DASHBOARD, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
            this.spinner.hide();
          }
        });
      }
    }else{
      this.dashboardForm.markAllAsTouched();
    }
  }

  close() {
    this.dialog.closeAll();
  }
}
