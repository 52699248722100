<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{title}}
    </div>
    <div class="col-2 text-right">
        <mat-icon color="primary" [style.color]="data.color"  [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>

<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="saveForm" action=""  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100" color="primary">
                        <mat-label>Fecha de suscripción*</mat-label>
                        <input matInput [matDatepicker]="subscriptionDate" formControlName="subscriptionDate" readonly>
                        <mat-datepicker-toggle matSuffix [for]="subscriptionDate"></mat-datepicker-toggle>
                        <mat-datepicker #subscriptionDate></mat-datepicker>
                        <mat-error *ngIf="f.subscriptionDate.hasError('required')">
                            La fecha de suscripción es <strong>requerida</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <button mat-raised-button  [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite"  (click)="saveArray()">AGREGAR</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100" color="primary">
                        <mat-label>Inicio de Vigencia*</mat-label>
                        <input matInput [matDatepicker]="startValidity" [max]="maxStartValidity" formControlName="startValidity" (dateChange)="startValidityDatehandler($event)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="startValidity"></mat-datepicker-toggle>
                        <mat-datepicker #startValidity></mat-datepicker>
                        <mat-error *ngIf="f.startValidity.hasError('required')">
                            La fecha de inicio de vigencia es <strong>requerida</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100" color="primary">
                        <mat-label>Fin de Vigencia*</mat-label>
                        <input matInput [matDatepicker]="endValidity" [min]="minEndValidity" formControlName="endValidity" (dateChange)="endValidityDatehandler($event)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="endValidity"></mat-datepicker-toggle>
                        <mat-datepicker #endValidity></mat-datepicker>
                        <mat-error *ngIf="f.endValidity.hasError('required')">
                            La fecha de fin de vigencia es <strong>requerida</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Archivo de Contrato*</mat-label>
                        <ngx-mat-file-input (change)="onFileSelectedContract($event)"  formControlName="file">></ngx-mat-file-input>
                        <mat-icon matSuffix [style.color]="data.color">folder</mat-icon>
                        <mat-error *ngIf="f.file.hasError('required')">
                            El contrato es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Archivo de Adenda</mat-label>
                        <ngx-mat-file-input (change)="onFileSelectedAdenda($event)"  formControlName="adenda"></ngx-mat-file-input>
                        <mat-icon matSuffix [style.color]="data.color">folder</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <mat-form-field class="w-100">
                        <mat-label>Motivo de Vigencia</mat-label>
                        <textarea matInput rows="6" formControlName="motiveValidity" maxlength="800"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="table-responsive mt-3">
                <table class="table" mat-table [dataSource]="dataSource" >
                    <ng-container matColumnDef="index">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> # </th>
                        <td class="text-center" *matCellDef="let element; let i = index"> {{i+1}} </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="numberContract">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> Número </th>
                        <td class="text-center" *matCellDef="let element"> {{element.numberContract}} </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="subscription">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> Contrato </th>
                        <td class="text-center" *matCellDef="let element">
                            {{element.contractName}}
                        </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="adenda">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> Adenda </th>
                        <td class="text-center" *matCellDef="let element">
                        {{element.adendaName}}
                        </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="download">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> Descargar </th>
                        <td class="text-center" *matCellDef="let element">
                            <mat-icon color="primary" class="icon-cursor" [style.color]="data.color" (click)="viewContract(element)" style="font-size: 15px; width:15px; text-align:center;">archive</mat-icon> Contrato
                            <mat-icon color="primary" class="icon-cursor" [style.color]="data.color" (click)="viewAdenda(element)" style="font-size: 15px; width:15px; text-align:center;" *ngIf="element.adendaName">archive</mat-icon> Adenda
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.colorFont"> Acción </th>
                        <td class="text-center" *matCellDef="let element">
                        <mat-icon color="primary" class="icon-cursor" [style.color]="data.color" (click)="delete(element)" *ngIf="!isBusiness && data.enabledWrite">delete</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCELAR</button>
</mat-dialog-actions>
