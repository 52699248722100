import { Component, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { PanelCameraAlertModalComponent } from './alert/panel-camera-alert-modal.component';
import { OcurrenceService } from '../../../../../services/Ocurrence/ocurrence.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsServices } from 'src/app/services/alert/alert.service';
import { environment } from 'src/environments/environment';
import { MessageModalComponent } from '../../message/message-modal.component';

@Component({
  selector: 'panel-camera-modal',
  templateUrl: './panel-camera-modal.component.html',
  styleUrls: ['./panel-camera-modal.component.css'],
  providers: [DatePipe]
})

export class PanelCameraModalComponent implements OnInit {

  hide = true;
  repeatHide = true;

  model: string = "";
  alertName:string = "";
  fecha: string = "";
  hora: string = "";
  alert: number;
  ocurrence: number;
  path: string = "";
  state: number;
  error: string = "";
  messageButton: string = "";
  attetion: number;

  myDate = new Date();
  localSave: string;
  horaSave: string;

  loading: boolean = false;
  update = false;
  alerts = [];
  index = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PanelCameraModalComponent>,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private OcurrenceService: OcurrenceService,
    private emitterService: EmitterService,
  ) { }

  ngOnInit() {
    this.extractAlert();
    this.localSave = this.datePipe.transform(this.myDate, 'yyyy/MM/dd');
    this.horaSave = this.datePipe.transform(this.myDate, 'HH:mm');
    this.alerts = this.data.alerts;
    this.index = this.data.index;
  }

  leftAlert(){
    this.dialogRef.close({update:false,newId: this.data.beforeId});
  }

  rightAlert(){
    this.dialogRef.close({update:false,newId: this.data.afterId});

    /*var newAfterId = this.index + 1,newBefore = this.index - 1;
    if(this.index > 0){
      this.data.beforeId = this.alerts[newBefore].ocurennceId;
      if(alerts > (this.index+1)){
        this.data.afterId = this.alerts[newAfterId].ocurennceId;
      }
    }else{
      if(alerts > (this.index+1)){
        this.data.afterId = this.alerts[newAfterId].ocurennceId;
      }
    }
    this.index = this.index+1;*/
  }

  zoom(){
    let customFunction = function (url) {
      window.open(url);
    };

    // define elements
    let options = {
      container: "#container",
      zoom: "#zoomlevel",
      zoomIn: "#zoomin",
      zoomOut: "#zoomout",
      src: this.path,

      // from x, from y, to x, to y, title, url or function, ...any function arguments
      map: []
    };

    let ZoomPan = function (options) {
      // options / customisables
      let zoomSteps = options.zoomSteps || 100,
        stepSize = options.stepSize || 10,
        wheelSize = options.wheelSize || options.stepSize || 10,
        maxImageScale = options.maxImageScale || 1,
        imageSrc = options.src,
        map = options.map || [],
        container = document.querySelector(options.container),
        zoom = document.querySelector(options.zoom),
        zoomIn = document.querySelector(options.zoomIn),
        zoomOut = document.querySelector(options.zoomOut);

      // automatics
      let containerWidth,
        containerHeight,
        containerRatio,
        minImageScale,
        imageScaleRange,
        imageScale = 1,
        imageTranslateX = 0,
        imageTranslateY = 0,
        focalPoint,
        displayFocalPoint;

      zoom.min = 0;
      zoom.max = zoomSteps;
      zoom.value = zoomSteps / 2;
      zoom.value = 0;

      // handle interaction results

      let loadUrl = function (url) {
        window.location.assign(url);
      };

      let triggerMapClick = function (i) {
        let call = loadUrl;
        let args = map[i].slice(5);
        if (typeof map[i][5] === "function") {
          call = map[i][5];
          args = map[i].slice(6);
        }
        call.apply(this, args);
      };

      let naturaliseCoords = function (coords) {
        return {
          x: displayFocalPoint.x + (coords.x - containerWidth / 2) / imageScale,
          y: displayFocalPoint.y + (coords.y - containerHeight / 2) / imageScale
        };
      };

      let findMapMatch = function (coords) {
        for (let i in map) {
          if (
            map[i][0] < coords.x &&
            map[i][1] < coords.y &&
            map[i][2] > coords.x &&
            map[i][3] > coords.y
          ) {
            return i;
          }
        }
        return null;
      };

      let makeCoords = function (x, y) {
        return { x, y };
      };

      // handle events

      let dragging = false;
      let moved = false;

      let processClick = function (x, y) {
        let mapMatch = findMapMatch(naturaliseCoords(makeCoords(x, y)));
        if (mapMatch) {
          triggerMapClick(mapMatch);
        }
      };

      let hoverMatch = null;
      let handleMove = function (x, y, xMove, yMove) {
        let coords = naturaliseCoords(makeCoords(x, y));

        let mapMatch = findMapMatch(naturaliseCoords(makeCoords(x, y)));
        if (mapMatch !== null && mapMatch !== hoverMatch) {
          hoverMatch = mapMatch;
          image.style.cursor = "pointer";
          image.title = map[mapMatch][4];
        }

        if (mapMatch === null && hoverMatch !== null) {
          hoverMatch = null;
          image.style.cursor = "";
          image.title = "";
        }

        if (!dragging) {
          return;
        }

        focalPoint = { x: focalPoint.x - xMove, y: focalPoint.y - yMove };
        moved = true;

        positionImage();
      };

      let handleClick = function (e) {
        if (moved) {
          return;
        }
        processClick(e.clientX - e.target.x, e.clientY - e.target.y);
      };

      let handleMouseDown = function (e) {
        e.preventDefault();
        dragging = true;
        moved = false;
      };

      let handleMouseMove = function (e) {
        handleMove(e.clientX, e.clientY, e.movementX, e.movementY);
      };

      let handleMouseUp = function (e) {
        dragging = false;
        moved = false;
      };

      let handleTap = function (e) {
        processClick(
          e.touches[0].clientX - e.touches[0].target.x,
          e.touches[0].clientY - e.touches[0].target.y
        );
      };

      let lastTouch, touchStartEvent;
      let startPosition = {};
      let handleTouchStart = function (e) {
        touchStartEvent = e;
        startPosition = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        this.startDrag(e);
      };

      let handleTouchMove = function (e) {
        let touch = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        if (!lastTouch) {
          lastTouch = { x: touch.x, y: touch.y };
        }
        handleMove(touch.x, touch.y, touch.x - lastTouch.x, touch.y - lastTouch.y);
        lastTouch = { x: touch.x, y: touch.y };
      };

      let handleTouchEnd = function (e) {
        lastTouch = null;
        if (!moved) {
          handleTap(touchStartEvent);
        }
        this.endDrag(e);
      };

      // position / size calculations

      let getContainerSize = function () {
        containerWidth = container.offsetWidth;
        containerHeight = container.offsetHeight;
        containerRatio = containerHeight / containerWidth;
      };

      let checkFocalPoint = function () {
        displayFocalPoint = { x: focalPoint.x, y: focalPoint.y };
        let xScaleWidth = image.naturalWidth * imageScale;
        let xScaleMargin = (image.naturalWidth - xScaleWidth) / 2;
        let xMax = xScaleMargin + xScaleWidth - containerWidth / 2;
        let xMin = xScaleMargin + containerWidth / 2;

        if (displayFocalPoint.x > xMax) displayFocalPoint.x = xMax;
        if (displayFocalPoint.x < xMin) displayFocalPoint.x = xMin;
        if (xScaleWidth < containerWidth) {
          displayFocalPoint.x = xScaleMargin + xScaleWidth / 2;
        }

        let yScaleHeight = image.naturalHeight * imageScale;
        let yScaleMargin = (image.naturalHeight - yScaleHeight) / 2;
        let yMax = yScaleMargin + yScaleHeight - containerHeight / 2;
        let yMin = yScaleMargin + containerHeight / 2;

        if (displayFocalPoint.y > yMax) displayFocalPoint.y = yMax;
        if (displayFocalPoint.y < yMin) displayFocalPoint.y = yMin;
        if (yScaleHeight < containerHeight) {
          displayFocalPoint.y = yScaleMargin + yScaleHeight / 2;
        }
        if (dragging) {
          focalPoint = displayFocalPoint;
        }
      };

      let positionImage = function () {
        checkFocalPoint();

        imageTranslateX =
          -(image.naturalWidth - containerWidth) / 2 -
          (displayFocalPoint.x - image.naturalWidth / 2);
        imageTranslateY =
          -(image.naturalHeight - containerHeight) / 2 -
          (displayFocalPoint.y - image.naturalHeight / 2);

        updateStyles();
      };

      let sizeImage = function () {
        if (!focalPoint) {
          return;
        }
        if (containerRatio > imageRatio) {
          minImageScale = containerWidth / image.naturalWidth;
        } else {
          minImageScale = containerHeight / image.naturalHeight;
        }

        imageScale =
          minImageScale +
          ((maxImageScale - minImageScale) / zoomSteps) * zoom.value * 8; // mas lupa

        positionImage();
      };

      let updateStyles = function () {
        image.style.transform = `translateX(${imageTranslateX}px) translateY(${imageTranslateY}px) scale(${imageScale})`;
      };

      let updateZoom = function () {
        sizeImage();
      };

      // init

      let setupImage = function () {
        container.appendChild(image);
        imageRatio = image.naturalHeight / image.naturalWidth;
        focalPoint = { x: image.naturalWidth / 2, y: image.naturalHeight / 2 };
        sizeImage();
        image.addEventListener("click", handleClick);
        image.addEventListener("mousedown", handleMouseDown);
        image.addEventListener("mousemove", handleMouseMove);
        image.addEventListener("mouseup", handleMouseUp);
        image.addEventListener("touchstart", handleTouchStart);
        image.addEventListener("touchmove", handleTouchMove);
        image.addEventListener("touchend", handleTouchEnd);
      };

      window.addEventListener("resize", function () {
        getContainerSize();
        sizeImage();
      });

      zoom.addEventListener("input", updateZoom);
      zoom.addEventListener("change", updateZoom);

      zoomIn.addEventListener("click", function () {
        zoom.value = parseInt(zoom.value) + stepSize;
        updateZoom();
      });

      zoomOut.addEventListener("click", function () {
        zoom.value = parseInt(zoom.value) - stepSize;
        updateZoom();
      });

      container.addEventListener("wheel", function (e) {
        e.preventDefault();
        zoom.value =
          parseInt(zoom.value) - (e.deltaY / Math.abs(e.deltaY)) * wheelSize;
        updateZoom();
      });

      let imageRatio;
      let image = new Image();
      image.onload = setupImage;
      image.src = imageSrc;

      getContainerSize();
    };

    ZoomPan(options);

  }

  extractAlert() {
    this.loading = true;
    this.getOcurrence(this.data.ocurrenceId);
    this.loading = false;
  }

  getOcurrence(id){
    this.OcurrenceService.GetListOcurrenceById(id).subscribe((response) => {
      this.state = response.stateId;
      this.alert = response.alertId;
      this.path = response.patch;
      this.error = response.error;
      this.ocurrence = response.ocurennceId;
      this.attetion = response.attetionId;
      this.alertName = response.alert;
      var alert = "Atendido";
      if(response.error == "1"){
        alert = "Falso positivo";
      }else{
        if(response.stateId == 1){ alert = "Pendiente";  }
        else if(response.stateId == 2){ alert = "En Proceso";  }
      }
      this.model = response.camera + " - " + alert;
      this.fecha = this.datePipe.transform(response.time, 'dd/MM/yyyy');
      this.hora = this.datePipe.transform(response.time, 'HH:mm');
      this.messageButton = response.message;
      this.zoom();
    },
    (error: HttpErrorResponse) => {
      this.loading = false;
    });
  }

  errorAlert(){
    const dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.CONFIRM_TITLE,
        message: AppConstants.MessageModal.REGISTER_MESSAGE_ALERT_ERROR_CONFIRM,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
    })

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.loading = true;
        this.OcurrenceService.CheckError(this.ocurrence).subscribe(_ =>{
          if(_ != null){
            this.update = true;
            this.dialogRef.close({update:true,newId:0});
          }
          this.loading = false;
        });
      }
    });
  }

  openModal2() {
    let dialogRef = this.dialog.open(PanelCameraAlertModalComponent, {
      data: {
        title: "Atención de alarma", fech2: this.fecha, hora2: this.hora, mode2: this.model,stateId: this.state,attetionI: this.attetion,
        enterpiseId: this.data.enterpiseId, ocurrenceId: this.ocurrence, alertId: this.alert, cameraId: this.data.cameraId
      },
      width: '700px',
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(response => {
      this.update = response.update;
      this.closeOpen();
    });

  }

  closeOpen(){
    if(this.update){
      this.emitterService.sendMessage(AppConstants.EmitterService.ListPanelCamara);
      this.dialogRef.close({update:this.update,newId:0});
    }
  }

  closeModal(){
    this.dialogRef.close({update:false,newId:0});
  }

  onMouseMove(e) {
    console.log(e);
    var zoomer = e.currentTarget;
    var offsetX = 0;
    var offsetY = 0;
    var x = 0;
    var y = 0;
    e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
    e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
    x = offsetX/zoomer.offsetWidth*100
    y = offsetY/zoomer.offsetHeight*100
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
  }

  getUrl(){
    return this.path;
  }
}
