
    <div class="widget">
      <div class="fecha">
        <p id="diaSemana" class="diaSemana mr-1"></p>
        <p id="dia" class="dia mr-1"></p>
        <p class="mr-1">de</p>
        <p id="mes" class="mes mr-1"></p>
        <p class="mr-1">del</p>
        <p id="anio" class="anio mr-1"></p>
      </div>
      <div class="reloj">
        <p id="horas" class="horas"></p>
        <p>:</p>
        <p id="minutos" class="minutos"></p>
        <p>:</p>
        <div class="cajaSegundos">
          <p id="ampm" class="ampm"></p>
          <p id="segundos" class="segundos"></p>
        </div>
      </div>
    </div>