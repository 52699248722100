import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AdminService } from 'src/app/services/admin/admin.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { GetUserInfo, UserInfo } from "src/app/models/admin/admin";
import { ThemeService } from 'src/app/services/theme/theme.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error = "";
  success = "";
  descriptionForm = AppConstants.Auth.DescriptionForm;
  titleForm = AppConstants.Auth.TitleForm;
  logo: string;
  hide = true;
  logoEnterprise: string;
  background: string;
  color1: string;
  color2: string;
  titleLogin: string;
  showLogin = true;
  form = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  formPassword = new FormGroup({
    username: new FormControl('', [Validators.required])
  });

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    //private msService: MsalService,
    private authService: AuthService,
    private adminService: AdminService,
    private themeService: ThemeService
    ) {
      this.spinner.hide();
      if(environment.logoPrincipal != ""){
        this.logo = environment.logoEnterprise;
      }

      if(environment.logoEnterprise != ""){
        this.logoEnterprise = environment.logoEnterprise;
      }

      document.body.style.background = environment.themeColor1 + " !important";
      this.color1 = environment.themeColor1;
      this.color2 = environment.themeColor2;
      this.titleLogin = environment.titleLogin;
    }

  ngOnInit() {
    if(localStorage.getItem("isAzure") != null && localStorage.getItem("isAzure") == "1"){  // ACCESS MICROSOFT AZURE
      this.spinner.show();
      /*if(this.msService.instance.getActiveAccount() != null){
        this.generateToken(localStorage.getItem(AppConstants.Session.USERNAME));
      }*/
    }

    if(localStorage.getItem(AppConstants.Session.USERNAME) != null && localStorage.getItem(AppConstants.Session.USERNAME) != ""){
      this.spinner.show();
      this.getUserInfo(localStorage.getItem(AppConstants.Session.USERNAME));
    }
  }

  get f(){
    return this.form.controls;
  }

  get fPassword(){
    return this.formPassword.controls;
  }

  showPassword(){
    this.error = "";
    this.descriptionForm = AppConstants.Auth.DescriptionPasswordForm;
    this.titleForm = AppConstants.Auth.TitlePasswordForm;
    this.showLogin = false;
  }

  showSignIn(){
    this.error = "";
    this.descriptionForm = AppConstants.Auth.DescriptionForm;
    this.titleForm = AppConstants.Auth.TitleForm;
    this.showLogin = true;
  }

  login(){
    if(this.form.status === 'VALID'){
      this.spinner.show();
      this.authService.login(this.form.value).subscribe(res => {
        if(res.error){
          this.error = res.error.message;
          this.spinner.hide();
        }else{
          localStorage.setItem("_token_VD_GYS",res.token);
          localStorage.setItem("isAzure","0");
          var username = this.form.get("username").value;
          localStorage.setItem(AppConstants.Session.USERNAME,username);
          this.getUserInfo(username);
        }
      });
    }
  }

  password(){
    if(this.formPassword.status === 'VALID'){
      this.spinner.show();
      this.authService.password(this.formPassword.value).subscribe(res => {
        if(res.message == null){
          this.showLogin = true;
          this.error = "";
          this.success = "Se acaba de enviar su nueva contraseña. Revise su bandeja de entrada o spam";
        }else{
          this.error = res.error.message;
          this.success = "";
        }
        this.spinner.hide();
      });
    }
  }


  loginMS(){
    localStorage.setItem(AppConstants.Session.USERNAME,this.form.get("username").value);
    localStorage.setItem("isAzure","1");
  }

  generateToken(username: string){
    this.authService.generateToken().subscribe( _ => {
      if(_.token != null || _.token != ""){
          localStorage.setItem("_token_VD_GYS",_.token);
          this.getUserInfo(username);
      }
    });
  }

  getUserInfo(username: string) {
    this.adminService.getUserInfoByUsername(username)
    .subscribe((response: GetUserInfo) => {
      console.log(response);
      if(response.error == null){
        var enterprise = response.data;
        localStorage.setItem(AppConstants.Session.USERID, enterprise.userId.toString());
        localStorage.setItem(AppConstants.Session.USER, enterprise.fullName.toString());
        localStorage.setItem(AppConstants.Session.ENTERPRISES, JSON.stringify(enterprise.enterprises));
        localStorage.setItem(AppConstants.Session.HEADQUARTERS, JSON.stringify(enterprise.headquarters));
        localStorage.setItem(AppConstants.Session.ENTERPRISE_SYSTEM, enterprise.systems.toString());
        localStorage.setItem(AppConstants.Session.MENU_ICON, "0");
        localStorage.setItem(AppConstants.Session.CHANGE_ENTERPRISE_MENU, "0");
        if(enterprise.enterprises.length>0){
          var idEnterprise = enterprise.enterprises[0]['id'];
          localStorage.setItem(AppConstants.Session.ENTERPRISE, idEnterprise.toString());
          var listHeadquarters = enterprise.headquarters;
          var headquarters = listHeadquarters.filter(x => x.idEnterprise = idEnterprise);
          var idHeadquarter = headquarters[0].id;
          localStorage.setItem(AppConstants.Session.HEADQUARTER, idHeadquarter.toString());
          var listmenuEnterpriseHeadquarterRole = enterprise.menuEnterpriseHeadquarterRole;
          var listmenuEnterpriseHeadquarterRoles = listmenuEnterpriseHeadquarterRole.filter(x => x.idEnterprise == idEnterprise && x.idHeadquarter == idHeadquarter);
          var role = listmenuEnterpriseHeadquarterRoles[0].role;
          localStorage.setItem(AppConstants.Session.ROLE, role.toString());
          localStorage.setItem(AppConstants.Session.MENU_ROLE, JSON.stringify(enterprise.menuEnterpriseHeadquarterRole));
          localStorage.setItem(AppConstants.Session.LOGO, enterprise.enterprises[0]['logo'].toString());
          localStorage.setItem(AppConstants.Session.THEME_COLOR1, enterprise.enterprises[0]['themeColor1'].toString());
          localStorage.setItem(AppConstants.Session.THEME_COLOR2, enterprise.enterprises[0]['themeColor2'].toString());
          var idUser = parseInt(localStorage.getItem(AppConstants.Session.USERID));
          this.adminService.getMenuEnterpriseRole(parseInt(idEnterprise),parseInt(localStorage.getItem(AppConstants.Session.HEADQUARTER)),idUser).subscribe((response) => {
              var menu = response.data;
              localStorage.setItem(AppConstants.Session.MENU, JSON.stringify(menu));
              for (var i = 0; i < menu.length; i++) {
                var link = menu[i].tag;
                var childs = menu.filter(x => x.idParent == menu[i].id);
                if (childs.length > 0) {
                  var redirect = false;
                  for(var ii = 0; ii<childs.length; ii++){
                    if(childs[ii].activeRead == 1 || childs[ii].activeWrite == 1){
                      link = childs[ii].tag;
                      if(link == "dashboard"){ link = "home/"+link; }
                      else{
                        link = "home/"+link;
                      }
                      localStorage.setItem(AppConstants.Session.FIRST_PAGE, link);
                      redirect= true;
                      break;
                    }
                  }
                  if(redirect){
                    this.router.navigate([link]);
                    break;
                  }
                }else{
                  if(menu[i].activeRead == 1 || menu[i].activeWrite == 1){
                    if(link == "dashboard"){ link = "home/"+link; }
                        else{
                          link = "home/"+link;
                        }
                    this.router.navigate([link]);
                    break;
                  }
                }
            }
          });
        }
      }else{
        this.error = response.error.message;
      }
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      //  localStorage.clear();
       // this.authService.logout();
    });
}

}
