<div class="container" >
  <!--<mat-tab-group  [dynamicHeight] = "true" >
    <mat-tab label="Video Analítica">

    </mat-tab>
    <mat-tab label="IOT">
    </mat-tab>
    <mat-tab label="Streaming">
    </mat-tab>
  </mat-tab-group>-->
  <div class="header-tabs mt-2">
    <div class="tab-item" *ngFor="let item of dashboards" (click)="selectDashboard(item.id)"  [ngStyle]="{'border-color': dashboardSelect == item.id ? color1 : ''}" >
      <div class="text-tab-item" [ngStyle]="{'color': dashboardSelect == item.id ? color1 : ''}" >
        {{item.name}}
      </div>
      <div class="ml-2" *ngIf="dashboardSelect == item.id">
        <mat-icon color="primary" class="icon-cursor" [style.color]="color1" (click)="openEditModal(item)" matTooltip="Editar" *ngIf="enabledWrite">create</mat-icon>
        <mat-icon color="primary" class="icon-cursor" [style.color]="color1" (click)="openEditModal(item)" matTooltip="Editar" *ngIf="enabledRead && !enabledWrite">remove_red_eye</mat-icon>
        <mat-icon color="primary" class="icon-cursor" [style.color]="color1" (click)="delete(item)" matTooltip="Eliminar"  *ngIf="enabledWrite">delete</mat-icon>
      </div>
    </div>
    <div *ngIf="listDashboards2.length > 0" >
      <button mat-icon-button [matMenuTriggerFor]="moreDashboards">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #moreDashboards="matMenu" >
        <button mat-menu-item *ngFor="let item of listDashboards2" (click)="changeMoreDashboard(item)"  >
          {{item.name}}
        </button>
      </mat-menu>
    </div>
    <!--<div class="tab-item">
      <div class="text-tab-item" [ngStyle]="{'color': dashboard==2 ? colorFont2 : ''}" (click)="selectDashboard(2)">
        IOT
      </div>
      <div class="ml-2" *ngIf="dashboard == 2">
        <mat-icon color="primary" class="icon-cursor" [style.color]="color1" matTooltip="Editar">create</mat-icon>
        <mat-icon color="primary" class="icon-cursor" [style.color]="color1" matTooltip="Eliminar">delete</mat-icon>
      </div>
    </div>
    <div class="tab-item">
      <div class="text-tab-item" [ngStyle]="{'color': dashboard==3 ? colorFont2 : ''}" (click)="selectDashboard(3)">
        Streaming
      </div>
      <div class="ml-2" *ngIf="dashboard == 3">
        <mat-icon color="primary" class="icon-cursor" [style.color]="color1" matTooltip="Editar">create</mat-icon>
        <mat-icon color="primary" class="icon-cursor" [style.color]="color1" matTooltip="Eliminar">delete</mat-icon>
      </div>
    </div>-->
  </div>

  <div class="mt-2 row" *ngIf="contentDashboard">

   <!--<ngx-widget-grid [rows]="6" [columns]="4" [highlightNextPosition]="false" class="widged-grid" [showGrid]="false" (widgetPositionChange)="onWidgetChange($event)" >-->
      <div *ngFor="let w of widgets" class="col-lg-6 col-xs-12 mt-2"><!-- w.position {height: 1,left: 1,top: 1,width: 1} [(position)]="w.position" -->

        <!--<ngx-widget
          [position] = "{top: 1,left: 1,width: 1,height: 1}"
          [movable]="false" [resizable]="false">-->
          <div class="item-graph-dashboard">
            <div style="height:100%;width:100%; padding:10px;">
              <div class="header-widget" >
                <div>
                  <h5>{{w.name}}</h5>
                </div>
                <div *ngIf="enabledEditWidget">
                  <mat-icon color="primary" class="icon-cursor" matTooltip="Editar" [style.color]="color1" *ngIf="enabledWrite" (click)="openEditWidget(w)">create</mat-icon>
                  <mat-icon class="icon-cursor" [style.color]="color1" matTooltip="Ver" *ngIf="enabledRead && !enabledWrite" (click)="openEditWidget(w)">remove_red_eye</mat-icon>
                  <mat-icon color="primary" class="icon-cursor" matTooltip="Eliminar" *ngIf="enabledWrite" [style.color]="color1" (click)="deleteWidget(w)">delete</mat-icon>
                </div>
              </div>
              <app-chart-line *ngIf="w.idWidgetType == 1"  [id]="w.id" [idCanvas]="w.idCanvas" [valuesChart]="w.dataSets" [showroom]="0" [labelsChart]="w.labels"  [labelChart]="w.name" ></app-chart-line>
              <app-chart-bar *ngIf="w.idWidgetType == 2"  [id]="w.id" [idCanvas]="w.idCanvas" [valuesChart]="w.dataSets" [showroom]="0" [labelsChart]="w.labels"  [labelChart]="w.name" ></app-chart-bar>
              <app-chart-gauge *ngIf="w.idWidgetType == 3" [id]="w.id" [valueChart]="w.valueSensor" [valueInterval]="w.valueInterval" [labelsChart]="w.contentData" ></app-chart-gauge>
            </div>
          </div>
        <!--</ngx-widget>-->
      </div>

    <!-- </ngx-widget-grid>-->
  </div>


</div>

<button mat-fab class="fab-button3" (click)="editWidgets()" [style.background]="color1" aria-label="Add icon"  *ngIf="enabledWrite && widgets.length > 0">
  <mat-icon *ngIf="!enabledEditWidget" >edit</mat-icon>
  <mat-icon *ngIf="enabledEditWidget" >close</mat-icon>
</button>

<button mat-fab class="fab-button2" (click)="addWidget()" [style.background]="color1" aria-label="Add icon"  *ngIf="enabledWrite && dashboardSelect > 0">
  <mat-icon>widgets</mat-icon>
</button>
<button mat-fab class="fab-button" (click)="newDashboard()" [style.background]="color1" aria-label="Add icon"  *ngIf="enabledWrite">
  <mat-icon>add</mat-icon>
</button>
