import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ReportViewModel } from '../../models/reportAlert/report';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Alert';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getReport(enterpriseFilter: any): Observable<ReportViewModel[]> {
    return this.httpClient.post<ReportViewModel[]>(`${this.REQUEST_URL}/GetAlertList`, enterpriseFilter);
  }

  downloadFile(enterpriseId: number,
               dateI: string,
               dateF: string,
               timeI: string,
               timeF: string,
               statusId: number,
               cameraId: number,
               alertId: number,
               userId: number,
               error: number): Observable<Blob> {

                let params: HttpParams = new HttpParams();
                params = params.append('enterpriseId', enterpriseId.toString());
                params = params.append('dateI', dateI.toString());
                params = params.append('dateF', dateF.toString());
                params = params.append('timeI', timeI.toString());
                params = params.append('timeF', timeF.toString());
                params = params.append('statusId', statusId.toString());
                params = params.append('cameraId', cameraId.toString());
                params = params.append('alertId', alertId.toString());
                params = params.append('userId', userId.toString());
                params = params.append('error', error.toString());

    return this.httpClient.get<Blob>(`${this.REQUEST_URL}/Excel`, {params:params,
      responseType: 'blob' as 'json'
    })
      .pipe(catchError(this.parseErrorBlob))
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }

}