import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatDialog } from '@angular/material/dialog';
import { MessageSeverity, AlertService } from 'src/app/shared/services/alert.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { GatewayService } from 'src/app/services/gateway/gateway.service';
import { MatTableDataSource } from '@angular/material/table';
import { ZoneService } from 'src/app/services/zone/zone.service';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/helpers/format-datepicker';
@Component({
  selector: 'app-report-activity-actuator',
  templateUrl: './report-activity-actuator.component.html',
  styleUrls: ['./report-activity-actuator.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class ReportActivityActuatorComponent implements OnInit {

  cameraSelect: number;
  cameraAlertType = [];
  reportForm: FormGroup;
  myDate = new Date();
  alertSelect: number = 0;
  date = new Date();
  dateF = new Date();
  timeI: string;
  timeF: string;
  dateV:string;
  isAdministrator=false;
  dateIView:string;
  dateFView:string;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings;
  chartItem:any;

  labelsChart = [];
  dataChart:any;
  isAdminGys: boolean = false;
  enterprises = [];
  headquarters = [];
  gateways = [];
  enterpriseSelect: number = 0;
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION;
  length: number = 0;
  page: number = 1;
  headquarterSelect: number = 0;
  gatewaySelect: number = 0;
  zones = [];
  zoneSelect: number = 0;
  sensors = [];

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['index','headquarter', 'zone','actuator', 'value','createdAt', 'createdBy', 'startDate', 'finishDate', 'state'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('paginatorTop', { static: false }) paginatorTop: MatPaginator;
  @ViewChild('paginatorBottom', { static: false }) paginatorBottom: MatPaginator;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;

  constructor(
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private AlertService: AlertService,
    private formBuilder: FormBuilder,
    private panelIOTService: PanelIOTService,
    private headquarterService: HeadquarterService,
    private spinner: NgxSpinnerService,
    private enterpriseService: EnterpriseService,
    private gatewayService: GatewayService,
    private zoneService: ZoneService
  ) {
    document.getElementById("titleHeaderNav").innerHTML = 'IOT > <span class="font-weight-bold">Reporte de Actividades</span>';
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nomenclature',
      tooltipField: 'Sensor',
      selectAllText: 'Todos',
      unSelectAllText: 'Limpiar',
      searchPlaceholderText: 'Buscar',
      noFilteredDataAvailablePlaceholderText: 'Item no encontrado',
      itemsShowLimit: 6,
      allowSearchFilter: false, //input search
    };
  }

  ngOnInit(): void {
    this.setTheme();
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.createForm();
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.getZonesList()
    this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
    this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
    this.dateV = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.dataSource.paginator = this.paginator;
  }

  setTheme(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
  }

  ngAfterContentChecked(): void {
    if (this.paginatorTop) {
      this.paginatorBottom.length = this.paginatorTop.length;
    }
}

handlePaginatorTop(e): void {
    const { pageSize, pageIndex } = e;
    this.paginatorTop.pageSize = pageSize
    this.paginatorTop.pageIndex = pageIndex;
    this.paginatorTop.page.emit(e);
}

handlePaginatorBottom(e): void {
    const { pageSize, pageIndex } = e;
    this.paginatorBottom.pageSize = pageSize
    this.paginatorBottom.length = this.paginatorTop.length;
    this.paginatorBottom.pageIndex = pageIndex;
  }

  createForm(){
    this.reportForm = this.formBuilder.group({
      startDateReport: ['', [Validators.required]],
      endDateReport: ['', [Validators.required]],
      startTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
      endTimeReport: ['', [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")]],
      idEnterprise: ['', [Validators.required]],
      idHeadquarter: ['', [Validators.required]],
      idGateway: ['', [Validators.required]],
      idZone: ['', [Validators.required]],
      idSensors: ['']
    });
  }

  getEnterprises() {
    this.spinner.show();
    if (this.isAdminGys) {
      this.enterpriseService.getEnterprises().subscribe((response) => {
        this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
        this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
        this.getHeadquarters();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    } else {
      this.enterpriseSelect = +localStorage.getItem(AppConstants.Session.ENTERPRISE);
      this.getHeadquarters();
    }
  }

  _keyUp(event: any) {
    let charCode = (event.which) ? event.which : event.keyCode;
      console.log(event.keyCode)
      if (charCode != 46 && charCode != 58 && charCode > 31
        && (charCode < 48 || charCode > 57)) {
        return false;
      }
  }


  get f() { return this.reportForm.controls; }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  closeModal(){
    this.dialog.closeAll();
  }

  search(){
    var idSensors = [];
    this.selectedItems.forEach(x => {
      idSensors.push(x.id);
    });

    if(idSensors.length == 0){
      this.AlertService.showMessage(
        AppConstants.MessageModal.FILTER_SENSOR_VALIDATE,"",
        MessageSeverity.error);
      return false;
    }

    if(this.date > this.dateF){
      this.AlertService.showMessage(
        AppConstants.MessageModal.FILTER_DATES_VALIDATE,"",
        MessageSeverity.error);
      return false
    }

    var filter = {
      dateI: this.reportForm.value["startDateReport"],
      dateF: this.reportForm.value["endDateReport"],
      timeI: this.timeI,
      timeF: this.timeF,
      idEnterprise: this.enterpriseSelect,
      idHeadquarter: this.headquarterSelect,
      idGateway: this.gatewaySelect,
      idZone: this.zoneSelect,
      idSensors: idSensors
    };
    filter.dateI = this.datePipe.transform(filter.dateI, 'yyyy-MM-dd');
    filter.dateF = this.datePipe.transform(filter.dateF, 'yyyy-MM-dd');
    this.spinner.show();
    this.panelIOTService.reportActivity(filter).subscribe(
      result => {
        if(result.error == null){
          this.dataSource.data = result.data;
          if(result.data.length == 0){
            this.AlertService.showMessage("No tiene registros", "", MessageSeverity.error);
          }
        }
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
    });
  }

  changeEnterprise(data) {
    this.enterpriseSelect = data.value;
    this.getHeadquarters();
  }

  getHeadquarters() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
          if(this.headquarters.length == 1){
            this.headquarterSelect = this.headquarters[0]["id"];
          }else{
            this.headquarterSelect = 0;
          }
          this.getZonesList()
        }else{
          this.headquarters = [];
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.headquarters = [];
          this.spinner.hide();
        });
    } else {
      this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
     // this.getList(1);
    }
  }

  getGateways(){
    this.gatewayService.getListAll().subscribe((_) => {
      if(_.error == null){
        if(this.headquarterSelect == 0){
          this.gateways = _.data.filter(x => x.idEnterprise == this.enterpriseSelect);
        }else{
          this.gateways = _.data.filter(x => x.idEnterprise == this.enterpriseSelect && x.idHeadquarter == this.headquarterSelect);
        }

        if(this.gateways.length == 1){
          this.gatewaySelect = this.gateways[0]["id"];
        }else{
          this.gatewaySelect = 0;
        }
      }
      this.getSensors();
    });
  }

  getZonesList() {
    this.zoneService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe((response) => {
      if(response.error == null){
        this.zones = response.data.filter(x => x.type == 2);
        if(this.zones.length == 1){
          this.zoneSelect = this.zones[0]["id"];
        }else if(this.zones.length>1){
          this.zoneSelect = 0;
        }else{
          this.AlertService.showMessage("No tiene zonas IOT registradas, debes de crear una zona para usar este módulo", "", MessageSeverity.error);
        }
      }
      this.getGateways();
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  getSensors(){
    var filter = {
      idEnterprise: this.enterpriseSelect,
      idHeadquarter: this.headquarterSelect,
      idZone: this.zoneSelect,
      idGateway: this.gatewaySelect
    }
    this.spinner.show();
    this.selectedItems = [];
    this.panelIOTService.getList(filter).subscribe((_) => {
      this.sensors = [];
      if(_.error == null){
        var s = _.data.filter(x => x.idActuatorType == 1);
        this.sensors=this.sensors.concat(s);
        console.log("sensors",this.sensors);
      }
      this.spinner.hide();
    });
  }

  changeGateway(data) {
    this.gatewaySelect = data.value;
    this.getSensors();
    //this.getList(1);
  }

  changeZone(data) {
    this.zoneSelect = data.value;
    var zone = this.zones.filter(x => x.id == data.value)[0];
    this.headquarterSelect = zone.idHeadquarter;
    this.getGateways();
    this.getSensors();
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
    this.getZonesList();
  }

}

