<div class="container">
    <div class="row mt-3">
      <div class="col menu-tour">
        ShowRoom > <span class="color-primary font-weight-bold"> Email </span>
      </div>
    </div>
    <div class="row mt-4 section-name">
      <div class="col-lg-6 col-xs-12">
        Lista de Correos para envío de notificaciones de alertas
      </div>
      <div class="col-lg-6 col-xs-12 text-right">
        <button mat-raised-button color="primary" class="color-white" (click)="openAddModal()">AGREGAR</button>
      </div>
    </div>

    <section class="table-responsive mt-3">
      <table class="table" mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef> # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th scope="col" class="text-center" *matHeaderCellDef> Correo </th>
          <td class="text-center" *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th scope="col" class="text-center" *matHeaderCellDef> Acciones </th>
          <td class="text-center" *matCellDef="let element">
            <mat-icon color="primary" class="icon-cursor" (click)="openEditModal(element)">create</mat-icon>
            <mat-icon color="primary" class="icon-cursor" (click)="delete(element)">delete</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="12"
                     [hidePageSize]="true">
      </mat-paginator>
    </section>

  </div>

