import { Component, Input} from '@angular/core';
import { cubicBezier, Gauge } from 'gauge-chart-js';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
declare var require: any
const gradstop = require('gradstop');
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chart-switch',
  templateUrl: './chart-switch.component.html',
  styleUrls: ['./chart-switch.component.css']
})
export class ChartSwitchComponent {
  private _hubConnection: HubConnection;
  @Input('id') id : any;
  @Input('valueChart') valueChart : any;
  @Input('radius') radius : any;
  @Input('idOrigin') idOrigin : any;
  easeIn = cubicBezier(0, 0, 0.2, 5);
  mainGauge:Gauge;
  maxValue:any;
  backgroundColor = '';
  titleSwitch: string;
  boxShadowColor = '';
  showData = true;

  constructor(public showroomService: ShowRoomService) {

    var _this = this;
      const connection =  this._hubConnection = new HubConnectionBuilder()
        .withUrl(environment.wsUrl +"/ws/showroom",{
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build();
        console.log(environment.wsUrl +"/ws/showroom");
        connection.on("streamingActuator", (actuator) => {
          var actuator = actuator.data;
          if(actuator.id == _this.idOrigin){
            if(actuator.value){
              _this.backgroundColor = '#01c4e7';
              _this.boxShadowColor ="0px 0px 10.5px #01c4e7";
              if(_this.idOrigin == 3){ _this.titleSwitch = "CLOSE"; }else{ _this.titleSwitch = "ON"; }
          }else{
              _this.backgroundColor = '#fff';
              _this.titleSwitch = "OFF";
              _this.boxShadowColor ="0px 0px 10.5px #fff";
              if(_this.idOrigin == 3){ _this.titleSwitch = "OPEN"; }else{ _this.titleSwitch = "OFF"; }
          }
          _this.valueChart = actuator.value;
          }
        });
        connection.start().catch(err => document.write(err));
    //setInterval(function () {
      /*if(_this.showData && _this.idOrigin){
        _this.showData = false;
        _this.showroomService.getActuatorValue(_this.idOrigin).subscribe(_ => {
          if(_.data.value){
              _this.backgroundColor = '#01c4e7';
              _this.boxShadowColor ="0px 0px 10.5px #01c4e7";
              if(_this.idOrigin == 3){ _this.titleSwitch = "CLOSE"; }else{ _this.titleSwitch = "ON"; }
          }else{
              _this.backgroundColor = '#fff';
              _this.titleSwitch = "OFF";
              _this.boxShadowColor ="0px 0px 10.5px #fff";
              if(_this.idOrigin == 3){ _this.titleSwitch = "OPEN"; }else{ _this.titleSwitch = "OFF"; }
          }
          _this.valueChart = _.data.value;
          _this.showData = true;
        });
      }*/
   /* },
    1000);*/
  }

  async ngAfterViewInit(): Promise<void> {
    /*const linear = cubicBezier(0, 0, 1, 1);
    const container = document.getElementById('countdown-gauge'+this.id);
    const sharedConfig = {
      container,
      gaugeRadius: this.radius,
      lineWidth: 0,
      fromAngle: 0,
      toAngle: 360,
      animationDuration: 500,
      easing: linear
    };*/

    var color;
    if(this.idOrigin == 3){

      if(this.valueChart){
        this.backgroundColor = '#01c4e7';
        this.boxShadowColor ="0px 0px 10.5px #01c4e7";
        this.titleSwitch = "CLOSE";
        color = '#01c4e7';
      }else{
        this.backgroundColor = '#fff';
        this.titleSwitch = "OPEN";
        this.boxShadowColor ="0px 0px 10.5px #fff";
        color = '#c80003';
      }
    }else{

      if(this.valueChart){
        this.backgroundColor = '#01c4e7';
        this.boxShadowColor ="0px 0px 10.5px #01c4e7";
        this.titleSwitch = "ON";
        color = '#01c4e7';
      }else{
        this.backgroundColor = '#fff';
        this.titleSwitch = "OFF";
        this.boxShadowColor ="0px 0px 10.5px #fff";
        color = '#c80003';
      }
    }
    //this.valueChart = !this.valueChart;
    /**this.maxValue = sharedConfig.toAngle - sharedConfig.fromAngle;
    const gaugeBackground = new Gauge({
      ...sharedConfig,
      color: color,
      lineWidth: 0,
      animationDuration: 500
    });

    gaugeBackground.setValue(this.maxValue);*/

  }
/*
  wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }*/

  on_off(){
    this.showData = false;
    //const linear = cubicBezier(0, 0, 1, 1);
    if(this.idOrigin == 4){ //resistencia
      var _this = this;
      setInterval(function(){
        var resistencia = _this.showroomService.getResistencia();
        if(!resistencia){
          _this.backgroundColor = '#fff';
          _this.boxShadowColor ="0px 0px 10.5px #fff";
          _this.titleSwitch = "OFF";
          _this.valueChart = 0;
        }else{
          _this.valueChart = 1;
          _this.backgroundColor = '#01c4e7';
          _this.boxShadowColor ="0px 0px 10.5px #01c4e7";
          _this.titleSwitch = "ON";
        }
      },1000);
    }else{
      var color;
      if(this.idOrigin == 3){
        if(!this.valueChart){
          color = '#01c4e7';
          this.backgroundColor = '#01c4e7';
          this.boxShadowColor ="0px 0px 10.5px #01c4e7";
          this.titleSwitch = "CLOSE";
        }else{
          this.backgroundColor = '#fff';
          this.boxShadowColor ="0px 0px 10.5px #fff";
          color = '#c80003';
          this.titleSwitch = "OPEN";
        }
      }else{
        if(!this.valueChart){
          color = '#01c4e7';
          this.backgroundColor = '#01c4e7';
          this.boxShadowColor ="0px 0px 10.5px #01c4e7";
          this.titleSwitch = "ON";
        }else{
          this.backgroundColor = '#fff';
          this.boxShadowColor ="0px 0px 10.5px #fff";
          color = '#c80003';
          this.titleSwitch = "OFF";
        }
      }

      this.valueChart = !this.valueChart;
    }

    setTimeout(_ =>{
      this.showData = true;
    },2000);
/*
    const container = document.getElementById('countdown-gauge'+this.id);
    const sharedConfig = {
      container,
      gaugeRadius: this.radius,
      lineWidth: 0,
      color: color,
      fromAngle: 0,
      toAngle: 360,
      animationDuration: 500,
      easing: linear
    };
    const gaugeBackground = new Gauge({
      ...sharedConfig,
      color: color,
      lineWidth: 0,
      animationDuration: 500
    });

    gaugeBackground.setValue(this.maxValue);*/

  }
}
