import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-headquarter-modal',
  templateUrl: './headquarter-modal.component.html',
  styleUrls: ['./headquarter-modal.component.css']
})
export class HeadquarterModalComponent implements OnInit {

  headquarterForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<HeadquarterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private headquarterService: HeadquarterService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    if(this.data.action == AppConstants.OperationType.UPDATE){
      console.log("log",this.data.obj);
      this.headquarterForm.controls['name'].setValue(this.data.obj.name);
      this.headquarterForm.controls['description'].setValue(this.data.obj.description);
      this.headquarterForm.controls['nomenclature'].setValue(this.data.obj.nomenclature);
    }
  }

  createForm(){
    this.headquarterForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(50)]],
      description: ['', []],
      nomenclature: ['', [Validators.required,  Validators.maxLength(30),  Validators.minLength(2)]]
    })
  }

  get f() { return this.headquarterForm.controls; }

  save(){
    if(this.headquarterForm.valid){
      const payload = {
        name:this.headquarterForm.value["name"],
        nomenclature:this.headquarterForm.value["nomenclature"],
        description:this.headquarterForm.value["description"],
        id: AppConstants.OperationType.UPDATE == this.data.action ? this.data.obj.id : 0,
        idEnterprise: this.data.idEnterprise,
        idUser: localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
      if(this.data.action == AppConstants.OperationType.ADD){
        this.headquarterService.create(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_HEADQUARTER, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
            this.spinner.hide();
          }
        });
      }else if(this.data.action == AppConstants.OperationType.UPDATE){
        this.headquarterService.update(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_HEADQUARTER, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
              this.spinner.hide();
          }
        });
      }
    }else{
      this.headquarterForm.markAllAsTouched();
    }
  }

  close() {
    this.dialog.closeAll();
  }
}
