export class Alert {
  id_Alert: number;
  id_AlertParameter: number;
  n_Value: number;
  c_Alert: string;
}

export class CameraByEnterprise {
  id_Enterprise: number;
  id_Camera: number;
  c_Camera: string;
  b_Enabled: number;
  id_CameraState: number;
  c_Color: string;
  c_State: string;
}

export class Camera {
  id_Camera: number;
  id_CameraAlert: number;
  c_Alert: string;
  time: Date;
  c_Model: string;
}

export class CameraState {
  id_CameraState: number;
  c_State: string;
  c_Color: string;
}

export class CameraPost {
  idOcurrence: number;
  EnterpriseId: number;
  CameraId: number;
  AlertId: number;
  Description: string;
  userId: number;
  stateId: number;
  idAttention: number;
  DateRegister: string;
}

export class UserValidate {
  userId: number;
  password: string;
}

export class Color {
  cameraId: number;
}

export class CameraValidate {
  cameraId: number;
  alertId: number;
}

export class OcurrenceCamera{
  ocurennceId: number;
  alert: string;
  camera: string;
  time: Date;
  alertId: number;
  patch: string;
  enterpriseId: number;
  stateId: number;
  message: string;
  idStorageFile: number;
  attetionId: number;
  date: string;
  cameraId: number;
  error: string;
}

export class OcurrenceId{
  id:number;
}

export class CameraViewModel{
  cameraId:number;
  Model:string;
  IpAdress: string;
  Camera: string;
  zone:string;
  Alerts: string;
  active:boolean;
}

export class Zone {
  id: number;
  name: string;
  idEnterprise: number;
}

export class CameraById{
  cameraId: number;
  zoneId: number;
  enaledId: boolean;
  camera: string;
  model: string;
  serie: string;
  mac: string;
  ipAdress: string;
  streamingDomain: string;
  token: string;
  streamingRtmp: string;
  streamingKey: string;
  streamingUrl: string;
  streamingDescription: string;
  streamingImage: string;
  idServer: number;
  idHeadquarter: number;
}

export class AlertParameter{
  parameterId: number;
  c_Parameter: string;
  unit: string;
  value: number;
}

export class AlertUpdateList{
  id:number;
  parameter:string;
  value: number;
}
