import { Component, Input, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
//import {StreamingPlugin} from 'chartjs-plugin-streaming';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chart-line-streaming',
  templateUrl: './chart-line-streaming.component.html',
  styleUrls: ['./chart-line-streaming.component.css']
})
export class ChartLineStreamingComponent implements OnInit {
  private _hubConnection: HubConnection;
  @Input('id') id : any;
  @Input('idCanvas') idCanvas : any;
  @Input('labelsChart') labelsChart : any;
  @Input('name') name : any;
  colors = ["#31bfe9"]; //#652c77"#01c4e7", ["#99f2c8","#f5af19","#F37335","#91EAE4"];
  color = "";
  aligns = [3,1];
  lastValue = 0;
  valueSensor = 0;
 // @Input('valuesChart') valuesChart : any;
 // @Input('colorArray') colorArray : any;
  consultQuery = true;
  align = 0;
  value = 0;
  public chart: any;
  chartColors = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
  };

  constructor(public showroomService: ShowRoomService) {
    this.color = this.colors[Math.floor(Math.random() * this.colors.length)];
   // this.align = this.aligns[Math.floor(Math.random() * this.aligns.length)];
    var _this = this;
      const connection =  this._hubConnection = new HubConnectionBuilder()
        .withUrl(environment.wsUrl +"/ws/showroom",{
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build();
        var _this = this;
        connection.on("streamingSensors", (sensores) => {
          var sensor = sensores.data.filter(x => x.id == _this.id);
          if(sensor[0] != null){
            _this.valueSensor = sensor[0].value;
            _this.setSensors(sensor[0].id,sensor[0].value);
          }
        });
        connection.on("streamingHumedad", (sensores) => {
          for (var i = 0; i < sensores.length; i++) {
            if(_this.id == sensores[i].x){
              _this.valueSensor = sensores[i].y;
            }
          }
        });
        connection.start().catch(err => document.write(err));
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {

    //Chart.pluginService.register(StreamingPlugin);
    var _this = this;
    var color = Chart.helpers.color;
   // const randomColor = Math.floor(Math.random()*16777215).toString(16);
    const data2 = {
      datasets: [
        {
         // label: 'Dataset 1 (Linear Interpolation)',
          /*backgroundColor: function(context) {
            const chart = context.chart;
            const {ctx, chartArea} = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }
            return _this.getGradient(ctx, chartArea,_this.color);
          },*/
          /*backgroundColor: "blue",
			    borderColor: "blue", */
          backgroundColor: "lightgray",
          borderColor: this.color,
          lineTension: 0,
          borderDash: [8, 4],
          data: [],
          fill: true
        }
      ]
    };

    function randomScalingFactor(){
      return _this.valueSensor;
    }

    function onRefresh(chart) {
      const now = Date.now();
      var _this = this;
      chart.config.data.datasets.forEach(function(dataset) {
        dataset.data.push({
          x: now,
          y: randomScalingFactor()
        });
      });
    }
    /*const onRefresh = chart => {
      const now = Date.now();
      var _this = this;
      // chart.data.datasets
      chart.config.data.datasets.forEach(dataset => {
        dataset.data.push({
          x: now,
          y: _this.valueSensor
        });
      });

      if(this.consultQuery && !this.showroomService.getPause()){
          this.consultQuery = false;
          this.showroomService.getSensorValue(this.id).subscribe((_) => {
            var valueS = 0;
            if(_.error == null && _.data != null){
              this.value = _.data.y;
              valueS = this.value;
              this.setSensors(this.id,this.value);
              chart.data.datasets.forEach(dataset => {
                  dataset.data.push({
                    x: now,
                    y: _.data.y
                  });
              });
            }

            this.align++;
            this.lastValue = valueS;
            this.consultQuery = true;
          });
      }
    };*/
    /*
    Chart.defaults.set('plugins.streaming', {
      duration: 20000
    });

    Chart.plugins.register({
      streaming:{
        duration: 20000
      }
    });
*/
    var _this = this;


    setTimeout(function(){

    var canvas  = <HTMLCanvasElement> document.getElementById('chartLineStreaming'+_this.idCanvas);
    const ctx = canvas.getContext('2d');

    _this.chart = new Chart(ctx,{
      type: 'line',
      data: data2,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        title: {
          display: false,
          text: ''
        },
        scales: {
          xAxes: [{
            type: 'linear', // Utiliza el tipo 'linear' para el eje X
            position: 'bottom'
            /*type: 'realtime',
            realtime: {
              duration: 60000, // line time graph
              ttl: 60000,
              refresh: 2000,
              delay: 2000,
              onRefresh: onRefresh,
              frameRate: 90,
              pause: false
            },*/
          }],
          yAxes: [{
            scaleLabel: {
              display: false,
              labelString: 'Valor'
            }
          }]
        },
        tooltips: {
          mode: 'nearest',
          intersect: false
        },
        hover: {
          mode: 'nearest',
          intersect: false
        }
      }
    })

  }
    , 3000);


/*

    const labels = this.labelsChart;
    const arrayData = this.valuesChart;
    const data = {
      labels:labels,
      datasets: [
        {
          data: arrayData,
          borderColor: function(context) {
            const chart = context.chart;
            const {ctx, chartArea} = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }
            return getGradient(ctx, chartArea);
          },
        },
      ]
    };
*/

   /*
    this.chart = new Chart('chartLine'+this.id,{
      type: 'line',
      data: data,
      options:{
        scales: {
          xAxes: {
              ticks: {
                  autoSkip: false,
                  maxRotation: 75,
                  minRotation: 75
              }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });*/
  }

  getGradient(ctx, chartArea, color) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, '#ffffff45');
      gradient.addColorStop(0.5, color);
      gradient.addColorStop(1, '#ffffff45');
    }

    return gradient;
  }

  setSensors(id,value){
    if(id == 3){ // nivel
      this.showroomService.setNivel(value);
    }else if(id == 5){ // presion
      this.showroomService.setPresion(value);
    }else if(id == 1){ // temperatura
      this.showroomService.setTemperatura(value);
    }
  }
}
