<div class="row title-modal">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon color="primary" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="pinForm" >
            <div class="row">
                <div class="col-12">
                <mat-form-field class="w-100" >
                    <mat-label>Sede*</mat-label>
                    <mat-select
                            formControlName="idHeadquarter"
                            (selectionChange)="changeHeadquarter($event)"
                            [(ngModel)]="headquarterSelect"
                    >
                        <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                            {{headquarter.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.idHeadquarter.hasError('required')">
                      La sede es <strong>requerida</strong>.
                  </mat-error>
                </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Nombre*</mat-label>
                        <input matInput formControlName="name" >
                        <mat-error *ngIf="f.name.hasError('required')">
                            El nombre es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 text-center mb-2">
                    <button type="button" mat-raised-button (click)="fileInput.click()">{{imagenPin}}</button>
                    <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">
                    <!--<label class="col-12" style="padding: 0px;margin-bottom: 5px;">Subir nueva imagen</label>
                    <input (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">-->
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCELAR</button>
  <button mat-raised-button color="primary" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
