
<div class="container">
  <div class="icon-close" (click)="closeModal()" >
    <mat-icon class="icon-c">close</mat-icon>
  </div>
</div>

<section class="filter mt-3">
  <div class="col-12">
    <iframe
    width="100%"
    height="450"
    frameborder="0"
    style="border:0"
    allowfullscreen
    [src]="url">
    </iframe> 
  </div>
</section>
  