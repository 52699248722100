import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetStorageFile } from 'src/app/models/storage/getStorageFile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private httpClient: HttpClient
  ) {}

  private POST_ENDPOINT: string = 'Storage';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  update(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}`,data);
  }

  get(idEnterprise: number,idHeadquarter: number): Observable<GetStorageFile> {
    return this.httpClient.get<GetStorageFile>(`${this.REQUEST_URL}/enterprise/${idEnterprise}/headquarter/${idHeadquarter}`);
  }
}
