import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin/admin.service';
import { UserService } from 'src/app/services/user/user.service';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';

@Component({
  selector: 'app-modal-iot-filter',
  templateUrl: './modal-iot-filter.component.html',
  styleUrls: ['./modal-iot-filter.component.css']
})
export class ModalIotFilterComponent implements OnInit {
  filterForm: FormGroup;
  title: string;
  hide = true;
  repeatHide = true;
  loading: boolean = false;
  roles = [];
  showEdit: boolean = false;
  isAdminGys: boolean = false;
  sessionUserId: string;
  months = ['ENERO','FEBRERO','MARZO','ABRIL','MAYO','JUNIO','JULIO','AGOSTO','SETIEMBRE','OCTUBRE','NOVIEMBRE','DICIEMBRE'];
  days:Array<number>;

  constructor(
      public dialogRef: MatDialogRef<ModalIotFilterComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private formBuilder: FormBuilder,
      private spinner: NgxSpinnerService,
      public dialog: MatDialog,
      private userService: UserService,
      private alertService: AlertService,
      private emitterService: EmitterService,
      private adminService: AdminService
  ) {
    this.days = Array.from({length:31},(v,k)=>k+1);
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
      this.filterForm = this.formBuilder.group({
          year: ['', [Validators.required]],
          month: ['', [Validators.required]],
          day: ['', [Validators.required]],
          starHour: ['', [Validators.required]],
          endHour: ['', [Validators.required]],
          startTime: ['', [Validators.required]],
          endTimeReport: ['', [Validators.required]]
      });
  }

  get f() { return this.filterForm.controls; }

  openConfirmationModal() {
   // alert(this.filterForm.valid);
    //alert(this.filterForm.errors);
    //if (this.filterForm.valid) {
      this.data.result = true;
      this.data.optionFilter = this.data.optionFilter;
      this.dialogRef.close(this.data);
    //} else {
     // this.data.result = false;
     // this.filterForm.markAllAsTouched();
    //}
}

close() {
  this.data.result = false;
  this.dialog.closeAll();
}

_keyUp(event: any) {
  let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode != 58 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
}

unparseCheckBox(id: boolean) {
  if (id) return 1; else return 0;
}

checkFilter(){
  if(this.data.optionFilter) this.data.optionFilter = 1; else this.data.optionFilter = 0;
}

}
