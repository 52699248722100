import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-enterprise-system-modal',
  templateUrl: './enterprise-system-modal.component.html',
  styleUrls: ['./enterprise-system-modal.component.css']
})
export class EnterpriseSystemModalComponent implements OnInit {
  selectIOT = false;
  selectVideoanalitica = false;
  selectShowRoom = false;
  selectStreaming = false;
  title:string;
  menu = [];
  menuAdmin = [];
  menuUser = [];

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private enterpriseService: EnterpriseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.getData();
  }

  getData() {
    this.spinner.show();
    this.enterpriseService.getEnterpriseSystem(this.data.row.id).subscribe((response => {
      if(response.error == null && response.data != null){
        this.selectIOT = Boolean(response.data.iot);
        this.selectVideoanalitica = Boolean(response.data.va);
        this.selectShowRoom = Boolean(response.data.showroom);
        this.selectStreaming = Boolean(response.data.streaming);
        this.menu = response.data.menu;
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }


  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  update() {
    this.menuAdmin = this.getUniqueListBy(this.menuAdmin, 'id');
    this.menuUser = this.getUniqueListBy(this.menuUser, 'id');
    var data = {
        idUser: parseInt(localStorage.getItem(AppConstants.Session.USERID)),
        idEnterprise: this.data.row.id,
        iot: this.selectIOT ? 1 : 0,
        va:this.selectVideoanalitica ? 1 : 0,
        streaming:this.selectStreaming ? 1 : 0,
        showroom:this.selectShowRoom ? 1 : 0,
        menuAdmin: JSON.stringify(this.menuAdmin),
        menuUser: JSON.stringify(this.menuUser),
        systems: ''
      }

      var systems = {
        iot: data.iot,
        va: data.va,
        streaming: data.streaming,
        showroom: data.showroom
      }

      data.systems = JSON.stringify(systems);

      this.spinner.show();
      this.enterpriseService.updateEnterpriseSystem(data).subscribe((response => {
        this.alertService.showMessage("", AppConstants.MessageModal.UPDATE_ENTERPRISE_STREAMING, MessageSeverity.success);
        this.spinner.hide();
        this.close();
      }),
        (error: HttpErrorResponse) => {
          this.alertService.showMessage("", error.error.message, MessageSeverity.error);
          this.spinner.hide();
        });
  }

  close() {
    this.dialog.closeAll();
  }

  changeVideoanaltica($event){
    this.selectVideoanalitica = $event.checked;
    if(this.selectVideoanalitica){
      var mva = this.menu.filter(x => x.va == 1);
      this.menuAdmin = this.menuAdmin.concat(mva.filter(x => x.isAdmin == 1));
      this.menuUser = this.menuUser.concat(mva.filter(x => x.isUser == 1));
    }else{
      this.menuAdmin = this.menuAdmin.filter(function(item) {
        return item.va !== 1
      })
      this.menuUser = this.menuUser.filter(function(item) {
        return item.va !== 1
      })
    }
  }

  changeIOT($event){
    this.selectIOT = $event.checked;
    if(this.selectIOT){
      var miot = this.menu.filter(x => x.iot == 1);
      this.menuAdmin = this.menuAdmin.concat(miot.filter(x => x.isAdmin == 1));
      this.menuUser = this.menuUser.concat(miot.filter(x => x.isUser == 1));
    }else{
      this.menuAdmin = this.menuAdmin.filter(function(item) {
        return item.iot !== 1
      })
      this.menuUser = this.menuUser.filter(function(item) {
        return item.iot !== 1
      })
    }
  }

  changeStreaming($event){
    this.selectStreaming = $event.checked;
    if(this.selectStreaming){
      var mStreaming = this.menu.filter(x => x.streaming == 1);
      this.menuAdmin = this.menuAdmin.concat(mStreaming.filter(x => x.isAdmin == 1));
      this.menuUser = this.menuUser.concat(mStreaming.filter(x => x.isUser == 1));
    }else{
      this.menuAdmin = this.menuAdmin.filter(function(item) {
        return item.streaming !== 1
      })
      this.menuUser = this.menuUser.filter(function(item) {
        return item.streaming !== 1
      })
    }
  }

  changeShowRoom($event){
    this.selectShowRoom = $event.checked;
  }

}
