import { Injectable } from "@angular/core";
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AppConstants } from "../constants/app.contants";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /*//license
    var d = new Date();
    var date = d.getDate();
    var month = (d.getMonth()+1);
    var day = date.toString();
    if(date < 10){ day = "0"+date; }
    var m = month.toString();
    if(month < 10){ m = "0"+month; }
    var datestring = d.getFullYear() + "-" + m + "-" + day ;
    if(datestring >= environment.expiredLicense){
      this.router.navigate(['/license']);
      return false;
    }*/

    if (localStorage.getItem(AppConstants.Session.USERID))
      return true;

    this.router.navigate(['/']);
    return false;

    //return true;
  }
}
