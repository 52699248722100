import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ReportViewModel } from 'src/app/models/reportAlert/report';
import { GetHeatmap } from 'src/app/models/heatmap/getHeatmap';

@Injectable({
  providedIn: 'root'
})
export class HeatMapService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Heatmap';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getImageUriHeathmap(data:any): Observable<GetHeatmap>{
    return this.httpClient.post<GetHeatmap>(`${this.REQUEST_URL}`,data);
  }

  downloadHeatmap(cameraId: number, alertTypeId: number, dateFilter: string,idStorageFile: number) {
    // return this.httpClient.get(`${this.BASE_URL}/heatmap/camera/${cameraId}/alert-type/${alertTypeId}/blob-download`, { observe: 'response', responseType: 'blob' as 'json' });
    return this.httpClient.get(`${this.REQUEST_URL}/camera/${cameraId}/alert-type/${alertTypeId}/image-download/${dateFilter}/idStorageFile/${idStorageFile}`, { responseType: 'blob' });
  }

  getHeathmapResumen(enterpriseFilter: any): Observable<ReportViewModel[]>{
    return this.httpClient.post<ReportViewModel[]>(`${this.REQUEST_URL}/camera/alert-type/resumen`,enterpriseFilter);
  }
}
