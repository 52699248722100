<div class="panelControl" (click)="nextPanel()" >
    <img [src]="imagePanel" class="image-panel" >
    <div id="actuator3" class="actuator" (click)="showPanel(3)" ></div> <!-- Valvula angular -->
    <div id="actuator4" class="actuator" (click)="showPanel(4)"></div>
    <div id="actuator8" class="actuator" (click)="showPanel(8)"></div>
    <div id="actuator9" class="actuator" (click)="showPanel(9)"></div>
    <div id="actuator1" class="actuator" (click)="showPanel(1)"></div>
    <div id="actuator2" class="actuator" (click)="showPanel(2)"></div>
    <div id="actuator14" class="actuator" (click)="showPanel(14)"></div>
</div>

<div *ngFor="let item of actuators" >
    <div class="actuatorModal" [id]="'actuactorPanel'+item.id" [style.display]="'none'" ><!-- Valvula angular -->
        <div class="icon-close" (click)="closeModal(item.id)" >
            <mat-icon class="icon-c">close</mat-icon>
        </div>
        <app-showroom-actuator-panel [data]="item" [items]="actuators" ></app-showroom-actuator-panel>
    </div>
</div>




  
<div class="icon-menu" *ngIf="!showMenu" (click)="selectMenu()" >
    <div>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </div>
  </div>

  <div class="menu-options" *ngIf="showMenu" >
    <div class="panel-buttons" *ngIf="staticActuators.length > 0">
        <button mat-raised-button (click)="goControl()" [ngClass]="{'background-primary': isFullScreen, 'button-inactive' : !isFullScreen }" class="mb-2 w-100 buttonActuator">PANTALLA PÚBLICA</button>
        <button mat-raised-button (click)="playButtonAlert()" [ngClass]="{'background-primary': startAlert === 0, 'button-active' : startAlert === 1 }" class="w-100 buttonActuator">INICIAR ALERTAS</button>

        <button mat-raised-button (click)="stopButtonAlert()" [ngClass]="{'background-primary': stopAlert === 1, 'button-inactive' : stopAlert === 0 }" class="mt-2 w-100 buttonActuator">DETENER ALERTAS</button>
      
        <button mat-raised-button (click)="playButtonShowRoom()" [ngClass]="{'background-primary': startButton === 0, 'button-active' : startButton === 1 }" class="w-100 mt-2 buttonActuator">INICIAR SHOW ROOM</button>

        <button mat-raised-button (click)="stopButtonShowRoom()" [ngClass]="{'background-primary': stopButton === 1, 'button-inactive' : stopButton === 0 }" class="mt-2 w-100 buttonActuator">CERRAR SHOW ROOM</button>
        <button mat-raised-button (click)="toggleFullScreen()" [ngClass]="{'background-primary': isFullScreen, 'button-inactive' : !isFullScreen }" class="mt-2 w-100 buttonActuator">PANTALLA COMPLETA</button>
        <button mat-raised-button (click)="showPBI()" class="mt-2 w-100 buttonActuator">VER REPORTE</button>
    </div>
    <div class="icon-menu2" (click)="selectMenu()" >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  </div>


<div class="logoGySEnterprise" >
  <span class="title-logoEnterprise">G<span style="font-size: 40px;font-weight:100;color:#ffcc2c" >&</span>S</span>
  <span style="color:rgb(31, 81, 101)">gestión y sistemas</span>
</div>

<div class="logoGySEnterprise2" >
  <span class="title-logoEnterprise">G<span style="font-size: 40px;font-weight:100;color:#ffcc2c" >&</span>S LAB</span>
</div>

<div class="lbl lblNivel" >{{nivel}}</div>
<div class="lbl lblPresion" >{{presion}}</div>
<div class="lbl lblTemperatura" >{{temperatura}}</div>

