import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CameraComponent } from './components/home/administration/camera/camera.component';
import { EnterpriseComponent } from './components/home/administration/enterprise/enterprise.component';
import { HeatmapComponent } from './components/home/video-analytics/heatmap/heatmap.component';
import { LocationCameraComponent } from './components/home/video-analytics/location/location-camera.component';
import { SmsComponent } from './components/home/administration/sms/sms.component';
import { UserComponent } from './components/home/administration/user/user.component';
import { ShowroomComponent } from './components/home/master/showroom/showroom.component';
import { HomeComponent } from './components/home/home.component';
import { CameraAlertComponent } from './components/home/video-analytics/camera-alert/camera-alert.component';
import { ReportComponent } from './components/home/video-analytics/report/report.component';
import { CameraStreamingComponent } from './components/home/streaming/camera/camera-streaming.component';
import { StreamingDomainIframeComponent } from './components/home/streaming/domain-iframe/domain-iframe-streaming.component';
import { ServerStreamingComponent } from './components/home/streaming/server/server-streaming.component';
import { StreamingStadisticsComponent } from './components/home/streaming/stadistics/streaming-stadistics.component';
import { LicenseComponent } from './components/license/license.component';
import { LoginComponent } from './components/login/login.component';
import { IframeStreamingComponent } from './components/streaming/iframe-streaming/iframe-streaming.component';
import { MultiplestreamingComponent } from './components/streaming/multiple-streaming/multiple-streaming.component';
import { ViewStreamingComponent } from './components/streaming/view-streaming/view-streaming.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { MsalGuard } from '@azure/msal-angular';
import { ShowroomPanelComponent } from './components/home/master/showroom/showroom-panel/showroom-panel.component';
import { ShowroomPanelDiagramComponent } from './components/home/master/showroom/showroom-panel-diagram/showroom-panel-diagram.component';
import { ShowroomPanelDiagramPublicComponent } from './components/home/master/showroom/showroom-panel-diagram-public/showroom-panel-diagram-public.component';
import { HeadquarterComponent } from './components/home/administration/headquarter/headquarter.component';
import { GatewayComponent } from './components/home/administration/gateway/gateway.component';
import { SensorComponent } from './components/home/administration/sensor/sensor.component';
import { ActuatorComponent } from './components/home/administration/actuator/actuator.component';
import { ZoneComponent } from './components/home/administration/zone/zone.component';
import { LocationIotComponent } from './components/home/iot/location-iot/location-iot.component';
import { ReportIOTComponent } from './components/home/iot/report/iot-report.component';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { PanelIotComponent } from './components/home/iot/panel-iot/panel-iot.component';
import { ShowroomEmailComponent } from './components/home/master/showroom/showroom-email/showroom-email.component';
import { UserProfileComponent } from './components/home/profile/user-profile/user-profile.component';
import { SensorReadingplaceComponent } from './components/home/administration/sensor-readingplace/sensor-readingplace.component';
import { RoleComponent } from './components/home/administration/role/role.component';
import { ReportAlertIOTComponent } from './components/home/iot/report-alert/alert-iot-report.component';
import { SensorTypeComponent } from './components/home/administration/sensor-type/sensor-type.component';
import { PanelGPSComponent } from './components/home/iot/panel-gps/panel-gps.component';
import { EnterpriseProfileComponent } from './components/home/profile/enterprise-profile/enterprise-profile.component';
import { ReportActivityActuatorComponent } from './components/home/iot/report-activity-actuator/report-activity-actuator.component';
import { DigitalTwinsComponent } from './components/home/iot/digital-twins/digital-twins.component';
import { ReportBiIOTComponent } from './components/home/iot/report-bi-iot/report-bi-iot.component';
import { ReportBiVAComponent } from './components/home/video-analytics/report-bi-va/report-bi-va.component';

/*let routes: Routes = [];
if(localStorage.getItem('isAzure') == "1"){
  routes = [
    { path: '', component: LoginComponent , runGuardsAndResolvers: 'always',canActivate: [MsalGuard]},
    { path: 'license', component: LicenseComponent , runGuardsAndResolvers: 'always'},
    {
      path: 'home', component: HomeComponent, runGuardsAndResolvers: 'always', canActivate: [MsalGuard],
      children: [
        { path: '', redirectTo: 'home', pathMatch: 'full', runGuardsAndResolvers: 'always' },
        { path: 'dashboard', component: DashboardComponent, runGuardsAndResolvers: 'always' },

        {
          path: 'admin',
          children: [
            { path: 'enterprise', component: EnterpriseComponent, runGuardsAndResolvers: 'always' },
            { path: 'users', component: UserComponent, runGuardsAndResolvers: 'always' },
            { path: 'sedes', component: HeadquarterComponent, runGuardsAndResolvers: 'always' },
            { path: 'gateway', component: GatewayComponent, runGuardsAndResolvers: 'always' },
            { path: 'zone', component: ZoneComponent, runGuardsAndResolvers: 'always' },
            { path: 'actuator', component: ActuatorComponent, runGuardsAndResolvers: 'always' },
            { path: 'sensor', component: SensorComponent, runGuardsAndResolvers: 'always' },
          ]
        },

        {
          path: 'streaming',
          children: [
            { path: 'cameras', component: CameraStreamingComponent, runGuardsAndResolvers: 'always' },
            { path: 'servers', component: ServerStreamingComponent, runGuardsAndResolvers: 'always' },
            { path: 'stadistics', component: StreamingStadisticsComponent, runGuardsAndResolvers: 'always' },
            { path: 'domain-iframe', component: StreamingDomainIframeComponent, runGuardsAndResolvers: 'always' }
          ]
        },
        {
          path: 'video-analytics',
          children: [
            { path: 'camera', component: CameraComponent, runGuardsAndResolvers: 'always' },
            { path: 'location', component: LocationCameraComponent, runGuardsAndResolvers: 'always' },
            { path: 'heatmap', component: HeatmapComponent, runGuardsAndResolvers: 'always' },
            { path: 'alerts', component: CameraAlertComponent, runGuardsAndResolvers: 'always' },
            { path: 'report', component: ReportComponent, runGuardsAndResolvers: 'always' },
          ]
        },
        {
          path: 'iot',
          children: [
            { path: 'panel', component: PanelIotComponent, runGuardsAndResolvers: 'always' },
            { path: 'panel-sensor', component: PanelSensorComponent, runGuardsAndResolvers: 'always' },
            { path: 'report', component: ReportIOTComponent, runGuardsAndResolvers: 'always' },
          ]
        },
        { path: 'gys/showroom/email', component: ShowroomEmailComponent, runGuardsAndResolvers: 'always'}
      ]
    },
    {
      path: 'streaming',
      children: [
        { path: ':token', component: IframeStreamingComponent , pathMatch: 'full'},
        { path: 'camera/:token', component: ViewStreamingComponent, pathMatch: 'full'},
        { path: 'enterprise/:token', component: MultiplestreamingComponent , pathMatch: 'full'},
      ]
    },
    {
      path: 'gys',
      children: [
        { path: 'showroom/panel', component: ShowroomPanelComponent, runGuardsAndResolvers: 'always'},
        { path: 'showroom', component: ShowroomComponent, pathMatch: 'full'},
        { path: 'showroom/panel/diagram', component: ShowroomPanelDiagramComponent, runGuardsAndResolvers: 'always'},
        { path: 'showroom/panel/diagram/public', component: ShowroomPanelDiagramPublicComponent, runGuardsAndResolvers: 'always'},
      ]
    }
  ];
}else{*/
let routes: Routes = [
    { path: '', component: LoginComponent , runGuardsAndResolvers: 'always'},
    { path: 'license', component: LicenseComponent , runGuardsAndResolvers: 'always'},
    {
      path: 'home', component: HomeComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard],
      children: [
        { path: '', redirectTo: 'home', pathMatch: 'full', runGuardsAndResolvers: 'always' },
        { path: 'dashboard', component: DashboardComponent, runGuardsAndResolvers: 'always' },
        {
          path: 'admin',
          children: [
            { path: 'enterprise', component: EnterpriseComponent, runGuardsAndResolvers: 'always' },
            { path: 'users', component: UserComponent, runGuardsAndResolvers: 'always' },
            { path: 'roles', component: RoleComponent, runGuardsAndResolvers: 'always' },
            { path: 'headquarters', component: HeadquarterComponent, runGuardsAndResolvers: 'always' },
            { path: 'cameras', component: CameraComponent, runGuardsAndResolvers: 'always' },
            { path: 'gateway', component: GatewayComponent, runGuardsAndResolvers: 'always' },
            { path: 'zone', component: ZoneComponent, runGuardsAndResolvers: 'always' },
            { path: 'actuator', component: ActuatorComponent, runGuardsAndResolvers: 'always' },
            { path: 'sensor-readingplace', component: SensorReadingplaceComponent, runGuardsAndResolvers: 'always' },
            { path: 'sensor', component: SensorComponent, runGuardsAndResolvers: 'always' },
            { path: 'sensor-type', component: SensorTypeComponent, runGuardsAndResolvers: 'always' },
            { path: 'profile', component: UserProfileComponent, runGuardsAndResolvers: 'always' },
            { path: 'enterprise-setting', component: EnterpriseProfileComponent, runGuardsAndResolvers: 'always' },
           ] 
        },

        {
          path: 'streaming',
          children: [
            { path: 'cameras', component: CameraStreamingComponent, runGuardsAndResolvers: 'always' },
            { path: 'servers', component: ServerStreamingComponent, runGuardsAndResolvers: 'always' },
            { path: 'stadistics', component: StreamingStadisticsComponent, runGuardsAndResolvers: 'always' },
            { path: 'domain-iframe', component: StreamingDomainIframeComponent, runGuardsAndResolvers: 'always' }
          ]
        },
        {
          path: 'video-analytics',
          children: [
            { path: 'location', component: LocationCameraComponent, runGuardsAndResolvers: 'always' },
            { path: 'heatmap', component: HeatmapComponent, runGuardsAndResolvers: 'always' },
            { path: 'alerts', component: CameraAlertComponent, runGuardsAndResolvers: 'always' },
            { path: 'report', component: ReportComponent, runGuardsAndResolvers: 'always' },
            { path: 'report-powerbi', component: ReportBiVAComponent, runGuardsAndResolvers: 'always' },
          ]
        },
        {
          path: 'iot',
          children: [
            { path: 'location-iot', component: LocationIotComponent, runGuardsAndResolvers: 'always' },
            { path: 'panel-iot', component: PanelIotComponent, runGuardsAndResolvers: 'always' },
            { path: 'panel-gps', component: PanelGPSComponent, runGuardsAndResolvers: 'always' },
            { path: 'report', component: ReportIOTComponent, runGuardsAndResolvers: 'always' },
            { path: 'report-alert-iot', component: ReportAlertIOTComponent, runGuardsAndResolvers: 'always' },
            { path: 'report-actuator-activity', component: ReportActivityActuatorComponent, runGuardsAndResolvers: 'always' },
            { path: 'report-powerbi', component: ReportBiIOTComponent, runGuardsAndResolvers: 'always' },
            { path: 'digitaltwins', component: DigitalTwinsComponent, runGuardsAndResolvers: 'always' },
          ]
        },
        { path: 'gys/showroom/email', component: ShowroomEmailComponent, runGuardsAndResolvers: 'always'}
      ]
    },
    {
      path: 'streaming',
      children: [
        { path: ':token', component: IframeStreamingComponent , pathMatch: 'full'},
        { path: 'camera/:token', component: ViewStreamingComponent, pathMatch: 'full'},
        { path: 'enterprise/:token', component: MultiplestreamingComponent , pathMatch: 'full'},
      ]
    },
    {
      path: 'gys',
      children: [
        { path: 'showroom/panel', component: ShowroomPanelComponent, runGuardsAndResolvers: 'always'},
        { path: 'showroom', component: ShowroomComponent, pathMatch: 'full'},
        { path: 'showroom/panel/diagram', component: ShowroomPanelDiagramComponent, runGuardsAndResolvers: 'always'},
        { path: 'showroom/panel/diagram/public', component: ShowroomPanelDiagramPublicComponent, runGuardsAndResolvers: 'always'},
      ]
    }
  ];
//}


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
