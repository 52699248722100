import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { LoginModel, TokenModel } from '../../models/user/user';
import { Router } from '@angular/router';
import { AuthLogin } from '../../models/auth/login';
import { AppConstants } from '../../shared/constants/app.contants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  private BASE_URL: string = `${environment.identityServer}`;
  private BASE_URL_AUTH: string = `${environment.sistemaWebAdmin}`;

  getAuthTokenAsync(model: LoginModel): Observable<TokenModel> {
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
    };

    return this.httpClient.post<TokenModel>(`${this.BASE_URL}`,`client_id=${model.client_id}&grant_type=${model.grant_type}&client_secret=${model.client_secret}&enterprise=${model.enterprise}&dni=${model.dni}&KeyboardId=${model.KeyboardId}&HashCodes1=${model.HashCodes1}&HashCodes2=${model.HashCodes2}&HashCodes3=${model.HashCodes3}&HashCodes4=${model.HashCodes4}&HashCodes5=${model.HashCodes5}&HashCodes6=${model.HashCodes6}`,httpOptions);
  }

  logout(token) {
    return this.httpClient.get<any>(`${this.BASE_URL_AUTH}/auth/logout/${token}` /*{headers:{skip_auth:"true"}}*/);
  }

  login(data:any): Observable<AuthLogin> {
    return this.httpClient.post<AuthLogin>(`${this.BASE_URL_AUTH}/auth/login`, data, /*{headers:{skip_auth:"true"}}*/);
  }

  password(data:any): Observable<any> {
    return this.httpClient.post<any>(`${this.BASE_URL_AUTH}/auth/password`, data, /*{headers:{skip_auth:"true"}}*/);
  }

  generateToken(): Observable<AuthLogin> {
    var data = { username: localStorage.getItem(AppConstants.Session.USERNAME) };
    return this.httpClient.post<AuthLogin>(`${this.BASE_URL_AUTH}/auth/generate-token`, data);
  }
}
