import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { EmitterService } from '../../../../services/emitter.service';
import { AppConstants, ArrayExtensionContracts } from '../../../../constants/app.contants';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common'
import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/helpers/format-datepicker';

@Component({
    selector: 'app-enterprise-contract-modal',
    templateUrl: './enterprise-contract-modal.component.html',
    styleUrls: ['./enterprise-contract-modal.component.css'],
    providers: [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    ]
})
export class EnterpriseContractModalComponent implements OnInit {
    saveForm: FormGroup;
    loading: boolean = false;
    dataSource = new MatTableDataSource();
    displayedColumns: string[] = ['index', 'numberContract', 'subscription', 'adenda', 'download', 'action'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    subscription: Subscription;
    sessionUserId: string;
    isBusiness: boolean = false;
    listContractsDelete = new Array<number>();
    title: string;
    maxStartValidity;
    minEndValidity;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        public dialog: MatDialog,
        private enterpriseService: EnterpriseService,
        private alertService: AlertService,
        private emitterService: EmitterService,
        public datepipe: DatePipe
    ) { }

    ngOnInit() {
      this.title = this.data.title;
      this.sessionUserId = localStorage.getItem(AppConstants.Session.USERID);
      this.dataSource.paginator = this.paginator;
      this.createForm();
      this.getEnterprisesContracts(this.data.row.id);
      // this.paginator._intl.itemsPerPageLabel = '';
    }

    createForm() {
        this.saveForm = this.formBuilder.group({
          subscriptionDate: ['', [Validators.required]],
          startValidity: ['', [Validators.required]],
          endValidity: ['', [Validators.required]],
          motiveValidity: ['Contrato Vigente'],
          file: ['', [Validators.required]],
          adenda: ['', ]
      })
    }

    get f() { return this.saveForm.controls; }

    dateToString(date: Date) {
      return this.datepipe.transform(date, 'yyyy-MM-dd');
    }

    saveArray(){
      if (this.saveForm.valid) {
        var data = new FormData();
          data.append('subscription', this.dateToString(this.saveForm.value["subscriptionDate"]));
          data.append('validityStart', this.dateToString(this.saveForm.value["startValidity"]));
          data.append('validityEnd', this.dateToString(this.saveForm.value["endValidity"]));
          data.append('userCreation', this.sessionUserId);
          data.append('reasonValidity', this.saveForm.value["motiveValidity"]);
          data.append('canceled', '0');
          if (this.saveForm.value["file"] != '' && this.saveForm.value["file"] != null) {
            data.append('file', this.saveForm.value["file"]["files"][0]);
          }
          if (this.saveForm.value["adenda"] != '' && this.saveForm.value["adenda"] != null) {
            data.append('adenda', this.saveForm.value["adenda"]["files"][0]);
          }
          data.append('enterpriseId', this.data.row.id);
          this.createEnterpriseContrato(data);
      }else{
        this.saveForm.markAllAsTouched();
      }
    }

    createEnterpriseContrato(data: FormData) {
      this.spinner.show();
      this.enterpriseService.createEnterpriseContract(data).subscribe((response => {
        this.spinner.hide();
        this.getEnterprisesContracts(this.data.row.id);
      }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.CREATE_ENTERPRISE_CONTRACT, "Falta configurar la configuración de directorios de esta empresa", MessageSeverity.error);
        this.spinner.hide();
      });
    }

    delete(obj) {
      let index = -1;

      for (var i = 0; i < this.dataSource.data.length; i++) {
        if (this.dataSource.data[i]['contractId'] == obj.contractId) {
          index = i;
          break;
        }
      }
      this.spinner.show();
      this.enterpriseService.deleteContract(obj.enterpriseId,obj.contractId,obj.numberContract).subscribe((response => {
        this.spinner.hide();
      }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_SMS, 'Error', MessageSeverity.error);
        this.spinner.hide();
      });

      this.dataSource.data.splice(index, 1);
      if (obj.contractId != undefined) {
        this.listContractsDelete.push(obj.contractId);
      }

      this.dataSource = new MatTableDataSource(this.dataSource.data);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      });
    }

    viewContract(obj) {
      this.downloadFile(AppConstants.BlobStorage.ContractsFiles, this.getPathName(obj.pathContract), obj.pathContractName);
    }

    close() {
      this.dialog.closeAll();
    }

    viewAdenda(obj) {
      this.downloadFile(AppConstants.BlobStorage.AdendasFiles, this.getPathName(obj.pathAdenda), obj.pathAdendaName);
    }

    getPathName(name: string) {
      var arrayString = name.split('/');
      return arrayString[arrayString.length - 1]
    }

    startValidityDatehandler(event) {
      this.minEndValidity = event.value;
    }

    endValidityDatehandler(event) {
      this.maxStartValidity = event.value;
    }

    downloadFile(idDirectory: number, fileName: string, nameFile: string): void {
      this.spinner.show();
      this.enterpriseService.downloadFile(this.data.row.id,idDirectory, fileName).subscribe(
        (response) => {

          const data: Blob = new Blob([response], {
            type: response.type
          });
          FileSaver.saveAs(data, nameFile);
        },
        (error) => {
          this.spinner.hide();
          this.alertService.showMessage(AppConstants.TitleModal.DOWNLOAD_FILES, AppConstants.MessageModal.DOWNLOAD_FILE_MESSAGE, MessageSeverity.error);
        }
      ).add(() => {
        this.spinner.hide();
      });
    }

    getEnterprisesContracts(enterpriseId: number) {
      this.dataSource.data = [];
      //this.spinner.show();
      this.enterpriseService.getEnterpriseContracts(enterpriseId).subscribe((response) => {
        this.dataSource.data = response;
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    }

    onFileSelectedContract(event) {
      var files = event.target.files;
      if (files.length > 0) {
        var file = files[0];
        var extension = file.name.split('.');
        var extensionFile = extension[extension.length - 1];
        if (ArrayExtensionContracts.filter(f => f == extensionFile).length == 0) {
          this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT_CONTRACTS, MessageSeverity.warn);
          this.saveForm.controls['file'].setValue('');
          files = [];
          return;
        }
        if (file.size / 1024 / 1024 > 10) {
          this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT_CONTRACTS_SIZE, MessageSeverity.warn);
          this.saveForm.controls['file'].setValue('');
          files = [];
          return;
        }
      }
    }

    onFileSelectedAdenda(event) {
      var files = event.target.files;
      if (files.length > 0) {
        var file = files[0];
        var extension = file.name.split('.');
        var extensionFile = extension[extension.length - 1];
        if (ArrayExtensionContracts.filter(f => f == extensionFile).length == 0) {
          this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT_CONTRACTS, MessageSeverity.warn);
          this.saveForm.controls['adenda'].setValue('');
          files = [];
          return;
        }
        if (file.size / 1024 / 1024 > 10) {
          this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT_CONTRACTS_SIZE, MessageSeverity.warn);
          this.saveForm.controls['adenda'].setValue('');
          files = [];
          return;
        }
      }
    }

}
