<div class="row title-modal">
    <div class="col-10">
        Filtro IOT
    </div>
    <div class="col-2 text-right">
        <mat-icon color="primary" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="filterForm" autocomplete="off">
            <div class="row">
                <div class="col-12 col-md-12">
                    <mat-form-field class="w-100">
                        <mat-label>Año</mat-label>
                        <mat-select formControlName="year" >
                            <mat-option [value]="2022">2022</mat-option>
                            <mat-option [value]="2021">2021</mat-option>
                            <mat-option [value]="2021">2020</mat-option>
                            <mat-option [value]="2021">2019</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Mes</mat-label>
                        <mat-select formControlName="month" >
                            <mat-option *ngFor="let month of months" [value]="month">
                                {{month}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Día</mat-label>
                        <mat-select formControlName="day" >
                            <mat-option *ngFor="let day of days" [value]="day">
                                {{day}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Hora inicio</mat-label>
                        <input matInput type="time" formControlName="startTime" (keypress)="_keyUp($event)">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Hora fin</mat-label>
                        <input matInput type="time" formControlName="endTimeReport" (keypress)="_keyUp($event)">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<div class="row">
    <div class="col-lg-5" >
        Streaming <mat-slide-toggle [checked]="data.optionFilter == 1" class="mt-3" (change)="checkFilter()" [(ngModel)]="data.optionFilter" ></mat-slide-toggle> Filtro
    </div>
    <div class="col-lg-7 text-right">
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>CANCELAR</button>
            <button mat-raised-button color="primary" (click)="openConfirmationModal()">APLICAR</button>
        </mat-dialog-actions>
    </div>
</div>

