import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { CameraZoneViewModel } from 'src/app/models/camera/camera';
import { CameraService } from 'src/app/services/camera/camera.service';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService,MessageSeverity } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import 'fabric';
import { MatDialog } from '@angular/material/dialog';
import { OcurrenceService } from 'src/app/services/Ocurrence/ocurrence.service';
import { Subscription } from 'rxjs';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { ModalReportVideoAnaliticaShowRoomComponent } from 'src/app/shared/components/modals/showroom/modal-report-video-analitica-show-room/modal-report-video-analitica-show-room.component';

declare const fabric: any;

@Component({
  selector: 'app-showroom-va',
  templateUrl: './showroom-va.component.html',
  styleUrls: ['./showroom-va.component.css']
})
export class ShowroomVaComponent implements OnInit {
  imgAlert = "../../../../assets/images/sin_image.jpg";
  loading: boolean = false;
  enterprises = [];
  enterpriseSelect: number = 0;
  enterprisesZones = [];
  enterpriseZoneSelect: number = 0;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['index', 'camera', 'enabled'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  subscription: Subscription;
  listCameras = new Array<CameraZoneViewModel>();
  reportForm: FormGroup;
  myDate = new Date();
  alertSelect: number = 0;
  idStorageFile: number;
  date = new Date();
  dateF = new Date();
  timeI: string;
  timeF: string;
  dateV:string;

  dateIView:string;
  dateFView:string;

  image: any;
  file: File = null;
  canvas: any;
  genredCanva: boolean = true;
  nameMarker: string = '';
  cameraId: number = 0;
  cameraSelect: number = 0;
  cameras = [];
  zoneId: number = 0;
  filterCamera = '';
  countAlerts = 0;
  isAdministrator=false;
  cameraAlert = [];
  cameraAlertType = [];
  filter = 0;
  private _hubConnection: HubConnection;

  constructor(
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private enterpriseService: EnterpriseService,
      private cameraService: CameraService,
      private alertService: AlertService,
      private OcurrenceService: OcurrenceService,
      private showRoomService: ShowRoomService,
      private formBuilder: FormBuilder,
      private datePipe: DatePipe
  ) {
    this.cameraSelect = 235//228;
   }

  ngOnInit() {
    this.createForm();
    this.timeI = this.datePipe.transform("Tue Aug 18 2020 00:00:58 GMT-0500", 'HH:mm');
    this.timeF = this.datePipe.transform("Tue Aug 18 2020 23:59:58 GMT-0500", 'HH:mm');
    this.dateV = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');


      this.enterpriseSelect = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE));
      this.getZonesByEnterprise(68);
      this.getCanvas();
      this.changeCamera();

      const connection =  this._hubConnection = new HubConnectionBuilder()
      .withUrl(environment.wsUrl +"/ws/showroom",{
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();
      var _this = this;
      connection.on("newAlertShowRoom", (data) => {
        _this.cameraAlert.splice(0, 0, data);
        _this.countAlerts++;
        _this.OcurrenceService.GetListOcurrenceById(data.ocurennceId).subscribe((response) => {
          if(response.idStorageFile == 1){
            _this.imgAlert = environment.pathFile + response.patch;
            }
        });
      });
      connection.start().catch(err => document.write(err));
     /* connection.start().then(function () {

        var filterData;
            filterData = {
              dateI: 0,
              dateF: 0,
              timeI: 0,
              timeF: 0,
              zoneId: 142,
              alertId: _this.alertSelect,
              cameraId: 228
            };
        console.log(filterData);
        connection.stream('GetAlerts', filterData.toString()).subscribe({
          next: (response) => {
              console.log("return data alerts",response);
              _this.cameraAlert = response;
              _this.countAlerts = response.length;
              if(response.length > 0 && _this.filter == 0){
                var dateI = _this.cameraAlert[response.length - 1].date;
                _this.date = dateI;
                _this.dateF = _this.myDate;
              }
              _this.spinner.hide();
              if(_this.countAlerts > 0){
                _this.clearCanvas();
                _this.createMarkers();
                _this.alerta(_this.cameraAlert[response.length - 1].ocurennceId,1);
              }
          },
          complete: () => {
            console.log("finished.");
          },
          error: (err) => {
            console.log(err);
          }
        });
      })*/





     /* window.setInterval(() => {
          this.socketShowRoom.emit('showroomshowroom/va', {});
      }, 100);
      this.refreshAlerts(1);
    //this.changeCamera();
    setInterval(() => {
      this.refreshAlerts(0);
    }, 2000);

    setInterval(() => {
      window.location.reload();
    },600000);*/
  }

  createForm() {
    this.reportForm = this.formBuilder.group({
        enterpriseId: [68, [Validators.required]],
        zoneId: [142, [Validators.required]],
        cameraReport: [228, [Validators.required]],
        alertReport: [0, []],
    });
  }

  refreshAlerts(init){
      this.updateColor();
      this.getCameras(this.enterpriseZoneSelect,0);
      this.ocurrenceListByCamera(init);
  }
/*
  cameraList() {
    this.cameras = [];
    this.cameraService.GetListCameraByEnterprise(this.enterpriseSelect).subscribe((response) => {
      this.cameras = this.cameras.concat(response);
      this.cameraSelect = 0;
      this.createMarkers();
      this.changeCamera();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }*/

  updateColor(){
    let data = this.listCameras;
    for(let j= 0;j < data.length;j++){
      let registers =
      {
         cameraId: data[j].cameraId
      }
      this.cameraService.postColor(registers).subscribe(
        result => {
        },
        (error: HttpErrorResponse) => {
          this.alertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
         }
     );
    }
  }

  get f() { return this.reportForm.controls; }

  showAllAlert(){
    this.cameraSelect = 228;
    this.alertSelect = 0;
    this.filterCamera =  "";
    this.filter = 0;
    this.ocurrenceListByCamera(0);
  }

  getCanvas() {
    this.canvas = new fabric.Canvas('canvas');
    var _this = this;
    this.canvas.on('mouse:down', function (e) {
        _this.filterCamera =  " - "+ e.target.camera;
        _this.cameraSelect = e.target.cameraId;
        _this.changeCamera();
        _this.reportForm.controls['cameraReport'].setValue(_this.cameraSelect);
        _this.ocurrenceListByCamera(0);
        // _this.cameraAlert = _this.cameraAlert.filter(x => x.cameraId == _this.cameraSelect);
        return false;
    });
  }

    getEnterprises() {
      this.enterpriseService.getEnterprises().subscribe((response) => {
        this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
        this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
        this.getZonesByEnterprise(this.enterpriseSelect);
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    }

  getZonesByEnterprise(enterpriseId: number) {
    this.enterprisesZones = [];
    this.enterpriseService.getEnterpriseById(enterpriseId).subscribe((response => {
      if (response.enterpriseZone.length > 0) {
        this.idStorageFile = response.idStorageFile;
        this.enterprisesZones = response.enterpriseZone;
        this.enterprisesZones.forEach(function(e){
          if(response.idStorageFile == 1){ // Mode Local
            e.zonePath = environment.pathFile + e.zonePath;
          }
        });
        this.enterpriseZoneSelect = this.enterprisesZones[0]["zoneId"];
        if(this.enterprisesZones[0]["zonePath"]){
          this.changeImage(this.enterprisesZones[0]["zonePath"]);
        }else{
          this.changeImage('./assets/images/sin_image.jpg');
        }
        //this.getCameras(this.enterpriseZoneSelect,1);
        this.zoneId = this.enterpriseZoneSelect;
        this.reportForm.controls['zoneId'].setValue(this.zoneId);
        //this.ocurrenceListByCamera();
        this.getCameras(this.zoneId,1);
      } else {
        this.changeImage('./assets/images/sin_image.jpg');
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  getCameras(zoneId: number,init: number) {
    this.cameraService.getCameraByZoneCP(zoneId).subscribe((response => {
      this.listCameras = response;
      this.dataSource.data = this.listCameras;
      this.spinner.hide();
      if(init){
        this.clearCanvas();
        this.createMarkers();
      }
    }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  clearCanvas() {
    this.canvas.remove(...this.canvas.getObjects());
  }

  positionMarkers() {
    let data = this.canvas._objects.filter(f => f.cameraId != undefined);
    data.forEach(f => {
      let index = this.listCameras.findIndex(item => item.cameraId == f.cameraId);
      if (index != -1) {
        var obj = this.listCameras[index];
            obj.cameraPosX = f.group.left;
            obj.cameraPosY = f.group.top;
      }
    });
  }

  createMarkers() {
    for (var i = 0; i < this.listCameras.length; i++) {
      var obj = this.listCameras[i];
      if (obj.cameraPosX != 0 && obj.cameraPosY != 0) {
        //Dynamic red - alerts
        var color = obj.cameraColor;
        if(color == "red"){
          if(this.cameraAlert.filter(x => x.camera == obj.camera).length < 1){
            color = "green";
          }
        }
        this.newGroup(obj,color);
      }
    }
  }

  newGroup(obj,color) {
    var _this = this;
    if(this.idStorageFile == 1){ // Mode Local
      obj.pathPinMap = environment.pathFile + obj.pathPinMap;
    }
    new fabric.Image.fromURL(obj.pathPinMap,
      function(img) {
        img.scaleToWidth(40);
        img.scaleToHeight(40);
        var img1 = img.set({
          originX: 'center',
          originY: 'bottom',
          angle: 0,
          top: -10,
          scaleX: obj.oppositeMapDirection == '0' ? 0.1 : -0.1,
          scaleY: 0.1
        });

        var text = new fabric.Text(obj.camera, //.replace(" ","\n")
          {
            textBackgroundColor:color,
            fill: 'white',
            fontSize: 15,
            originX: 'center',
            originY: 'center'
          }
        );

        var group = new fabric.Group([text, img1], {
          left: obj.cameraPosX,
          top: obj.cameraPosY,
          borderColor: 'transparent',
          hasControls: false,
          hasRotatingPoint: false,
          camera: obj.camera,
          cameraId: obj.cameraId,
        });

        _this.canvas.add(group);
      });
  }

  stopDragging(element) {
    element.lockMovementX = true;
    element.lockMovementY = true;
  }

  changeImage(urlImage : string) {
    fabric.Image.fromURL(urlImage, (img) => {
      img.set({
        opacity: 0.5,
        scaleX: this.canvas.width / img.width,
        scaleY: this.canvas.height / img.height,
      });
      this.canvas.setBackgroundImage(img, this.canvas.requestRenderAll.bind(this.canvas));
    });
  }

  ocurrenceListByCamera(init) {
    var filterData;
    //if(this.filter){
        filterData = {
          dateI: 0,
          dateF: 0,
          timeI: 0,
          timeF: 0,
          zoneId: 142,
          alertId: this.alertSelect,
          cameraId: 228
        };
    /*}else{
      filterData = {
        dateI: 0,
        dateF: 0,
        timeI: 0,
        timeF: 0,
        zoneId: 142,
        alertId: this.alertSelect,
        cameraId: 228
      };
    }
*/
    this.OcurrenceService.GetListOcurrenceCP(filterData).subscribe((response) => {
      this.cameraAlert = response;
      this.countAlerts = response.length;
      if(response.length > 0 && this.filter == 0){
        var dateI = this.cameraAlert[response.length - 1].date;
        this.date = dateI;
        this.dateF = this.myDate;
      }
      this.spinner.hide();
      if(this.countAlerts > 0){
        this.clearCanvas();
        this.createMarkers();
        this.alerta(this.cameraAlert[0].ocurennceId,1);
      }
     /* if(filterData.cameraId != 0){
        this.filterCamera = " - "+ this.listCameras.find(x => x.cameraId == filterData.cameraId).camera;
      }else{
        this.filterCamera = " - todas las cámaras";
      }*/
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        this.cameraAlert = [];
      });
  }

  showReport(){
    let dialogRef = this.dialog.open(ModalReportVideoAnaliticaShowRoomComponent, {
      data: {
        title: AppConstants.TitleModal.UPDATE_SENSOR,
        action: 0,
      },
      panelClass: 'report-modal',
      width: '1000px',
      hasBackdrop: true
    });
  }

  changeCamera() {
    this.cameraAlertType = [];
    this.alertSelect = 0;
    var cameraId = this.cameraSelect;// this.filterCameraId = 0 ? this.cameraSelect : this.filterCameraId;
    //if(cameraId != 0){
      this.cameraService.GetAlertByCamera(cameraId,0).subscribe((response) => {
        this.cameraAlertType = response;
        //this.alertSelect = cameraId;
        this.spinner.hide();
        this.searchAlerts();
        //this.searchAlerts();
      },
        (error: HttpErrorResponse) => {
          this.cameraAlertType = []
        });
    //}

  }

  alerta(id,init) {
      this.OcurrenceService.GetListOcurrenceById(id).subscribe((response) => {
        if(response.idStorageFile == 1){
          this.imgAlert = environment.pathFile + response.patch;
        }
      });
    /*var _this = this;
    setInterval(()=>{
      _this.filter = false;
    },10000);*/
    /*let dialogRef = this.dialog.open(PanelCameraModalComponent, {
      data: { ocurrenceId: id, cameraId: id1, enterpiseId: this.enterpriseSelect },
      width: '800px', hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshAlerts();
    });*/
  }

  searchAlerts(){
    if (this.reportForm.valid) {
      //this.filter = true;
      this.imgAlert = "../../../../assets/images/sin_image.jpg";
      var data = this.reportForm.value;
      this.ocurrenceListByCamera(0);
      /*var zone = this.reportForm.value["zoneId"];
      if(this.enterpriseZoneSelect != zone){
        this.changeZone(zone);
      }else{
        var data = this.reportForm.value;
        this.ocurrenceListByCamera(data);
      }*/
    }
  }

  _keyUp(event: any) {
    let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode != 58 && charCode > 31
        && (charCode < 48 || charCode > 57)) {
        return false;
      }
  }

  changeZone() {
    this.nameMarker = ''
    this.clearCanvas();
    this.zoneId = this.enterpriseZoneSelect;
    var item = this.enterprisesZones.filter(f => f.zoneId == this.zoneId);
    if(item[0]["zonePath"] != null && item[0]["zonePath"] != ""){
      this.changeImage(item[0]["zonePath"]);
    }
    this.getCameras(this.zoneId,1);
  }

  changeAlert(data){
    this.alertSelect = data.value;
    this.searchAlerts();
  }
}
