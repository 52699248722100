import { Component, Input, OnInit } from '@angular/core';
import * as Chart from 'chart.js'

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.css']
})
export class ChartBarComponent implements OnInit {
  @Input('id') id : any;
  @Input('type') type : any;
  @Input('idCanvas') idCanvas : any;
  @Input('labelsChart') labelsChart : any;
  @Input('valuesChart') valuesChart : any;
  @Input('colorArray') colorArray : any;
  @Input('optionFilter') optionFilter : any;
  @Input('showroom') showroom : any;

  chart: Chart;
  constructor() { }

  ngOnInit(): void {
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ngAfterViewInit(): void {
    const labels = this.labelsChart;
    const arrayData = this.valuesChart;
    var data = {
        labels: labels,
        datasets: arrayData
    }
       /*   [{
            label: "Ventas 2018",
            data: [65,34,65,65,2343,65,213,6523,6523,3],//arrayData,
            backgroundColor: "yellow"
          }]
      };

    let width, height, gradient;
    function getGradient(ctx, chartArea) {
      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'blue');
        gradient.addColorStop(0.5, 'yellow');
        gradient.addColorStop(1, 'red');
      }

      return gradient;
    }*/
    this.chart = new Chart('chartBar'+this.idCanvas,{
      type: 'bar',
      data: data,
      options: {
      tooltips: {
        cornerRadius: 0,
        caretSize: 0,
        xPadding: 16,
        yPadding: 10,
       // backgroundColor: "rgba(0, 150, 100, 0.9)",
        titleFontStyle: "normal",
        titleMarginBottom: 15
      },
      plugins: {
      datalabels: {
        display: false
      }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      },
        title: {
          display: false,
          text: ""
        },
        /*leyend: {
          display: false
        },*/
      }
    });
  }
}
