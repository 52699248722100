import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetActuator } from 'src/app/models/actuator/getActuator';
import { GetActuatorType } from 'src/app/models/actuator/getActuatorType';
import { GetActuatorDataType } from 'src/app/models/actuator/getActuatorDataType';

@Injectable({
  providedIn: 'root'
})
export class ActuatorService {
  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Actuator';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getList(parameters: any): Observable<GetActuator> {
    let httpParams = new HttpParams({
      fromObject: parameters,
    });
    return this.httpClient.get<GetActuator>(`${this.REQUEST_URL}/pag`, { params: httpParams });
  }

  getListAll(idEnterprise:number,idHeadquarter:number): Observable<GetActuator> {
    return this.httpClient.get<GetActuator>(`${this.REQUEST_URL}/enterprise/${idEnterprise}/headquarter/${idHeadquarter}`);
  }

  create(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}`,data);
  }

  update(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}`,data);
  }

  delete(id: number){
    return this.httpClient.delete<any>(`${this.REQUEST_URL}/` + id);
  }

  getListActuatorTypes(): Observable<GetActuatorType> {
    return this.httpClient.get<GetActuatorType>(`${this.REQUEST_URL}/types`);
  }

  getListActuatorDataTypes(): Observable<GetActuatorDataType> {
    return this.httpClient.get<GetActuatorDataType>(`${this.REQUEST_URL}/data-types`);
  }
}
