
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Pipe } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PowerBIService } from 'src/app/services/power-bi/power-bi.service';
import { PowerbiModalComponent } from 'src/app/shared/components/modals/powerbi-modal/powerbi-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';

@Component({
  selector: 'app-report-bi-va',
  templateUrl: './report-bi-va.component.html',
  styleUrls: ['./report-bi-va.component.css']
})
export class ReportBiVAComponent implements OnInit {

  url:SafeResourceUrl;
  enterpriseSelect: number = 0;
  headquarterSelect: number = 0;
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  enabledRead: number;
  enabledWrite: number;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private powerBIService: PowerBIService) {
    document.getElementById("titleHeaderNav").innerHTML = 'Videoanalítica > <span class="font-weight-bold">Reporte Power BI</span>';
  }

  ngOnInit() {
    this.setLS();
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.getPowerBI();
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  getPowerBI() {
      this.spinner.show();
      this.powerBIService.getListAll(this.enterpriseSelect,this.headquarterSelect,1).subscribe((response) => {
        if(response.data.length>0){
          this.url = this._sanitizer.bypassSecurityTrustResourceUrl(response.data[0].url);
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
  }

  openAddModal() {
    let dialogRef = this.dialog.open(PowerbiModalComponent, {
      data: {
        title: AppConstants.TitleModal.POWER_BI,
        action: AppConstants.OperationType.ADD,
        idSystem:1,
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        color: this.color1,
        colorFont: this.color2,
        obj:null},
      width: '500px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
       /* this.page = 1;
        this.paginator.pageIndex = 0;
        this.getList(); */
      }
    });
  }
}
