import { ChangeContext } from '@angular-slider/ngx-slider';
import { Component, Input, OnInit } from '@angular/core';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-showroom-actuator-panel',
  templateUrl: './showroom-actuator-panel.component.html',
  styleUrls: ['./showroom-actuator-panel.component.css']
})
export class ShowroomActuatorPanelComponent implements OnInit {
  private _hubConnection: HubConnection;
  @Input('data') item : any;
  @Input('items') items : any;
  dataValue: any;
  imagePanel = environment.pathFile + "assets/showroom/1.png";
  imagePanelOrden = 1;
  showActuatorPanel=false;
  actuators = [];
  staticActuators = [];
  startButton = 0;
  stopButton = 0;
  startAlert = 0;
  stopAlert = 0;
  stepIndex: number = 0;
  showScreenshot = false;
  showMenu = false;
  tiempoDisabledResistencia = false;
  disabledResistencia: any;
  offResistencia: any;
  showData = true;

  constructor(
    private alertService: AlertService,
    public showroomService: ShowRoomService) {}

  ngOnInit(): void {
    if(this.item.id != 5 && this.item.id != 8 && this.item.id != 9){
      var _this = this;
      this.dataValue = this.item.value;
      const connection =  this._hubConnection = new HubConnectionBuilder()
        .withUrl(environment.wsUrl +"/ws/showroom",{
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build();
        var _this = this;
        console.log(environment.wsUrl +"/ws/showroom");
        connection.on("streamingActuator", (actuator) => {
          var actuator = actuator.data;
          if(actuator.id == _this.item.idOrigin){
            if(_this.item.maxLimit > 0){
              var value = 0;
              if(actuator.id == 2){ // centrifuga
                if(actuator.value == 0){
                  value = 0;
                }else{
                  value = (actuator.value-85)/1.7;
                }
              }else if(actuator.id == 1){ // faja
                if(actuator.value == 0){
                  value = 0;
                }else{
                  value = (actuator.value-36.061)/2.1894;
                }
              }
              _this.item.value = value;
              console.log("value actuator: ",value);
            }
          }
        });
        connection.start().catch(err => document.write(err));
    /*setInterval(function () {
      if(_this.showData){
        _this.showData = false;
        _this.showroomService.getActuatorValue(_this.item.idOrigin).subscribe(_ => {
          if(_.data.maxLimit > 0){
            _this.item.value =(_.data.value * 100) / _.data.maxLimit;
          }
          _this.showData = true;
        });
      }
    },
    1000)*/
  };
  }

  onInputChange(changeContext: ChangeContext,item:any): void {
    var data = changeContext.value;
    var value = data;
    if(item.maxLimit > 0){
      if(item.id == 2){ // centrifuga
        if(data == 0){
          value = 0;
        }else{
          value = (1.7*data+85);
        }
      }else if(item.id == 1){ // faja
        if(data == 0){
          value = 0;
        }else{
          value = 2.1894*data+36.061;
        }
      }
    }
    this.dataValue = parseFloat(value.toString());
    this.save();
  }


  onChangeToogle(value, id){
    var value = this.dataValue;
    if(this.item.id == 4){ //resistencia
      if(!this.tiempoDisabledResistencia){
        if(this.item.maxLimit > 0){
          if(value){this.dataValue = 0; }else{this.dataValue = this.item.maxLimit; }
        }else{
          if(value){this.dataValue = 0; }else{this.dataValue = 1; }
        }
        this.item.value = this.dataValue;
        var _this = this;
        this.save();
        if(this.dataValue){
          this.showroomService.setResistencia(true);
          this.offResistencia = setInterval(function () {
            _this.item.value = 0;
            _this.dataValue = 0;
            _this.tiempoDisabledResistencia = true;
            _this.showroomService.setResistencia(false);
            _this.save();
            clearInterval(_this.offResistencia);
          },
          30000);
          this.disabledResistencia = setInterval(function () {
            _this.save();
            _this.tiempoDisabledResistencia = false;
            clearInterval(_this.offResistencia);
            clearInterval(_this.disabledResistencia);
          },
          180000);

        }else{
          this.showroomService.setResistencia(false);
          clearInterval(_this.disabledResistencia);
          clearInterval(_this.offResistencia);
          this.tiempoDisabledResistencia = false;
        }
      }else{
        this.alertService.showMessage("", "Por favor, esperar 3 minutos para volver a activar", MessageSeverity.error);
      }
    }else{
      if(this.item.maxLimit > 0){
        if(value){this.dataValue = 0; }else{this.dataValue = this.item.maxLimit; }
      }else{
        if(value){this.dataValue = 0; }else{this.dataValue = 1; }
      }
      if(this.item.id == 8){ this.showroomService.setBomba(this.dataValue);  } // Bomba
      if(this.item.id == 9){ this.showroomService.setValvulaSelenoide(this.dataValue);  } // Válvula Selenoide
      this.save();


      if(this.item.id == 8){
        if(this.dataValue){
            this.alertService.showMessage("", "Se prendió la bomba", MessageSeverity.success);
        }else{
          this.alertService.showMessage("", "Se apagó la bomba", MessageSeverity.error);
        }
      }
    }
  }

  onChangeToogleChild(idParent, value, id){
    if(value){ this.dataValue = 0; }else{ this.dataValue = 1; }
    this.item.value = !this.dataValue;
    this.save();
  }

  onInputChangeSlider($event: any,item:any) {
    this.stepIndex = +$event.value;
    var array = item.stepOptions.split(",");
    var data = parseInt(array[this.stepIndex]);
    this.dataValue = data;
    this.save();
  }

  save(){
    var data = {
      id: this.item.id,
      value: this.dataValue
    }

    this.showroomService.updateSensorValue(data).subscribe(_ => {
      console.log("UPDATE SENSOR: ",_);
    });
  }

}
