<div class="row title-modal" [style.color]="data.color" [style.borderColor]="data.color">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
        <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="userForm" autocomplete="off"  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="row">
                <!-- <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>DNI</mat-label>
                        <input matInput formControlName="documentNumber" OnlyNumbers maxlength="8">
                        <mat-error *ngIf="f.documentNumber.hasError('required')">
                            El número de documento es <strong>requerido</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.documentNumber.hasError('minlength')">
                            El número de documento debe tener mínimo <strong>8 dígitos</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.documentNumber.hasError('maxlength')">
                            El número de documento debe tener máximo <strong>8 dígitos</strong>.
                        </mat-error>
                    </mat-form-field>
                </div> -->
                <div class="col-12 col-md-12">
                    <mat-form-field class="w-100">
                        <mat-label>Correo*</mat-label>
                        <input matInput formControlName="email" autocomplete="off" maxlength="100">
                        <mat-error *ngIf="f.email.hasError('required')">
                            El correo es <strong>requerido</strong>.
                        </mat-error>
                         <mat-error *ngIf="f.email.hasError('maxlength')">
                            El correo debe tener máximo <strong>100 caractéres</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.email.hasError('email')">
                            Error en el <strong>formato</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Nombres(s)*</mat-label>
                        <input matInput formControlName="names" maxlength="30">
                        <!--<mat-error *ngIf="f.names.hasError('required')">
                            El número de documento es <strong>requerido</strong>.
                        </mat-error>-->
                        <mat-error *ngIf="f.names.hasError('maxlength')">
                            El número de documento debe tener máximo <strong>30 caractéres</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.names.hasError('whitespace')">
                            El número de documento es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Apellidos*</mat-label>
                        <input matInput formControlName="lastName" autocomplete="off" maxlength="30">
                        <!--<mat-error *ngIf="f.lastName.hasError('required')">
                            El Apellido es <strong>requerido</strong>.
                        </mat-error>-->
                        <mat-error *ngIf="f.lastName.hasError('maxlength')">
                            El Apellido debe tener máximo <strong>30 caractéres</strong>.
                        </mat-error>
                        <mat-error *ngIf="f.lastName.hasError('whitespace')">
                            El Apellido es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label *ngIf="showEdit" >Nueva contraseña</mat-label>
                        <mat-label *ngIf="!showEdit" >Contraseña</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" minlength="6" maxlength="20" >
                        <mat-icon matSuffix (click)="hide = !hide" class="icon-cursor" color="primary">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="f.password.hasError('minlength')">
                            La contraseña debe tener <strong>6 caractéres</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label *ngIf="showEdit" >Repetir Nueva Contraseña</mat-label>
                        <mat-label *ngIf="!showEdit" >Repetir Contraseña</mat-label>
                        <input matInput [type]="repeatHide ? 'password' : 'text'" formControlName="confirmPassword" minlength="6" maxlength="20" >
                        <mat-icon matSuffix (click)="repeatHide = !repeatHide" class="icon-cursor" color="primary">{{repeatHide ? 'visibility_off' : 'visibility'}}</mat-icon>

                        <mat-error *ngIf="f.confirmPassword.hasError('minlength')">
                            La contraseña debe tener <strong>6 caractéres</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="data.action == 1">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                          <mat-label>Rol*</mat-label>
                          <mat-select formControlName="role">
                               <mat-option *ngFor="let rol of roles" [value]="rol.id">
                                  {{rol.name}}
                              </mat-option>
                          </mat-select>
                          <mat-error *ngIf="f.role.hasError('required')">
                              El rol es <strong>requerido</strong>.
                          </mat-error>
                    </mat-form-field>
                </div>
            </div>
           <!--     <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                          <mat-label>Sede</mat-label>
                          <mat-select
                                formControlName="idHeadquarter"
                                (selectionChange)="changeHeadquarter($event)"
                                [(ngModel)]="headquarterSelect">
                          <mat-option [value]="0" > Todas </mat-option>
                          <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                            {{headquarter.name}}
                          </mat-option>
                          </mat-select>
                          <mat-error *ngIf="f.role.hasError('required')">
                              El rol es <strong>requerido</strong>.
                          </mat-error>
                    </mat-form-field>
                </div>
            </div>-->



            <div class="row">
                <div class="col">
                    <mat-checkbox labelPosition="before" formControlName="status">Habilitado</mat-checkbox>
                </div>
                <div class="col">
                    <mat-checkbox labelPosition="before" formControlName="sendEmail">Enviar Correo</mat-checkbox>
                </div>
                <!-- <div class="col" *ngIf="showEdit">
                    <mat-checkbox labelPosition="before" formControlName="locked">Bloqueado</mat-checkbox>
                </div> -->
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCELAR</button>
    <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite" (click)="openConfirmationModal()">GUARDAR</button>
</mat-dialog-actions>

