import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MessageModalComponent } from '../message/message-modal.component';
import { SmsService } from '../../../../services/sms/sms.service';
import { AppConstants } from '../../../constants/app.contants';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageSeverity, AlertService } from '../../../services/alert.service';
import { Subscription } from 'rxjs';
import { EmitterService } from '../../../../shared/services/emitter.service';
import { AlertSmsPending } from '../../../../models/sms/sms';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.css'],
  providers: [DatePipe]
})
export class SMSModalComponent implements OnInit {

  loading: boolean = false;

  name: string;
  cellphone: string;
  alertSelect:number;
  list = [];
  listId = [];
  listAlert:AlertSmsPending[];

  myDate = new Date();

  localSave: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  subscription: Subscription;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id','nameAlert','action'];

  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 0;
  length: number = 0;

  smsForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private dialogRef: MatDialogRef<SMSModalComponent>,
    private SmsService: SmsService,
    private AlertService: AlertService,
    private emitterService: EmitterService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.localSave = this.datePipe.transform(this.myDate, 'HH:mm');
    this.dataSource.paginator = this.paginator;
    this.subscription = this.emitterService.getMessage().subscribe(message => {
      if (message == AppConstants.EmitterService.ListSms) {
        this.dataExtrac();
      }
    });

    this.alert();
    this.createForm();
    this.condition();
    this.dataSource.filteredData = this.list;
  }

  closeDialog() {
    this.dialogRef.close()
  }

  delete(obj) {
    let index = -1;
    for (var i = 0; i < this.list.length; i++) {
      if (this.list[i]['nameAlert'] == obj.nameAlert) {
        index = i;
        break;
      }
    }

    this.dataSource.data.splice(index, 1);
    this.listId.splice(index, 1);

    this.dataSource = new MatTableDataSource(this.dataSource.data);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    });

  }

  createForm() {
    this.smsForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(80), this.noWhitespaceValidator]],
        status: [true, [Validators.required]],
        alert: [''],
        cellphone: ['', [Validators.required, Validators.maxLength(9), Validators.minLength(9), this.noWhitespaceValidator]]
    })
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getArray(){
    if(this.alertSelect > 0){
      if(this.listId.length == 0){
        this.listId = this.listId.concat({AlertId:this.alertSelect});
        for(let items of this.listAlert){
          if(items.id_Alert == this.alertSelect){
            this.list = this.list.concat({nameAlert:items.c_Alert});
          }
        }
      }else{
          let searchData = this.listId.filter(fil => fil.AlertId.toString().toLowerCase().startsWith(this.alertSelect));
          if(searchData.length > 0){
            this.AlertService.showMessage(
              AppConstants.TitleModal.WARNING_TITLE,
              AppConstants.MessageModal.DATA_REPET,
              MessageSeverity.error);

            return false;
          }
          else{
            this.listId = this.listId.concat({AlertId:this.alertSelect});
            for(let items of this.listAlert){
              if(items.id_Alert == this.alertSelect){
                this.list = this.list.concat({nameAlert:items.c_Alert});
              }
            }
          }
      }
    }else{
      this.AlertService.showMessage(
        AppConstants.TitleModal.WARNING_TITLE,
        "No seleccionó ninguna alerta para agregar",
        MessageSeverity.error);

      return false;
    }
    this.dataSource.data = this.list;
  }

  get f() { return this.smsForm.controls; }

  openConfirmationModal() {
    let message = '';

    if (this.smsForm.valid) {
      switch (this.data.typeId) {
        case 0:
          message = AppConstants.MessageModal.REGISTER_MESSAGE_SMS_CONFIRM
          break;
        case 1:
          message = AppConstants.MessageModal.EDIT_MESSAGE_SMS_CONFIRM
          break;
      }
      let dialogRef = this.dialog.open(MessageModalComponent, {
        data: {
          title: 'Confirmar Registro',
          message: message,
          hasButton: true,
          contentButton: {
            yes: 'SI',
            no: 'CANCELAR'
          }
        },
      });

      dialogRef.afterClosed().subscribe(response => {
        if (this.smsForm.valid) {
          if(this.data.typeId == 0){
            if (response.result == true) {
              this.spinner.show();
              this.registerData();
              this.spinner.hide();
            }
          }else{
            if (response.result == true) {
              this.spinner.show();
              this.updateata();
              this.spinner.hide();
            }
          }
        }else{
          this.smsForm.markAllAsTouched();
        }
      });
    }else{
      this.smsForm.markAllAsTouched();
      if(this.listId.length == 0)
      {
        this.AlertService.showMessage(
          AppConstants.TitleModal.WARNING_TITLE,
          AppConstants.MessageModal.DATA_NULL,
          MessageSeverity.error);

        return false;
      }
    }
  }

  dataExtrac() {
    this.list = this.list;
  }

  close() {
    this.dialog.closeAll();
  }

  alert(){
    this.SmsService.getAlertByEmterprise(this.data.enterpriseId).subscribe((response) => {
      this.listAlert = response
    },
    (error: HttpErrorResponse) => {
      this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
    });
  }

  condition() {
    if (this.data.typeId == 1) {
      this.SmsService.getSmsByid(this.data.enterpriseId, this.data.idSelect).subscribe((response) => {
        this.smsForm.patchValue({
          status: response.active
        });
        this.name = response.userName;
        this.cellphone = response.cellphone;
        this.list = response.alerts;
        for(let item of response.alerts){
          if(item.alertId > 0){
            this.listId = this.listId.concat({AlertId:item.alertId});
          }
        }
        this.dataSource.data = this.list;
      },
        (error: HttpErrorResponse) => {
          this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
        });
    } else {
      this.name = "";
      this.cellphone = "";
      this.list = [];
    }
  }

  registerData() {
    /*
    let registerData = this.smsForm.value;

    if (!this.smsForm.controls.name.valid ||
      !this.smsForm.controls.cellphone.valid) {

        this.AlertService.showMessage(
          AppConstants.TitleModal.WARNING_TITLE,
          AppConstants.MessageModal.REQUIRED_FIELDS_MESSAGE,
          MessageSeverity.error);
      return false;
    }
    if(this.listId.length == 0)
    {
      this.AlertService.showMessage(
        AppConstants.TitleModal.WARNING_TITLE,
        AppConstants.MessageModal.DATA_NULL,
        MessageSeverity.error);

      return false;
    }

    let valueint = 0;

    if( registerData.status == true){
      valueint = 1;
    }else{
      valueint = 0;
    }

    let register =
    {
      id_Enterprise: this.data.enterpriseId,
      UserId: parseInt(localStorage.getItem(AppConstants.Session.USERID)),
      c_UserSms: this.name,
      c_Celphone: this.cellphone,
      enabled: valueint,
      DateRegister: this.localSave,
      SmsAlerts: this.listId,
    }

    let formData = new FormData();
    formData.append('data', JSON.stringify(register));

    this.SmsService.registerSms(formData).subscribe(
      result => {
        this.AlertService.showMessage(
          AppConstants.TitleModal.CREATE_SMS,
          AppConstants.MessageModal.CREATE_SMS,
          MessageSeverity.success);
        this.close();
        this.emitterService.sendMessage(AppConstants.EmitterService.ListSms);
      },
      (error: HttpErrorResponse) => {
        this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
    });
    */
  }

  updateata() {
    /*
    let registerData = this.smsForm.value;

    if (!this.smsForm.controls.name.valid ||
      !this.smsForm.controls.cellphone.valid) {

        this.AlertService.showMessage(
          AppConstants.TitleModal.WARNING_TITLE,
          AppConstants.MessageModal.REQUIRED_FIELDS_MESSAGE,
          MessageSeverity.error);
      return false;
    }

    if(this.listId.length == 0)
    {
      this.AlertService.showMessage(
        AppConstants.TitleModal.WARNING_TITLE,
        AppConstants.MessageModal.DATA_NULL,
        MessageSeverity.error);

      return false;
    }

    let valueint = 0;

    if( registerData.status == true){
      valueint = 1;
    }else{
      valueint = 0;
    }

    let update =
    {
      id_Sms: this.data.idSelect,
      UserId: parseInt(localStorage.getItem(AppConstants.Session.USERID)),
      id_Enterprise:this.data.enterpriseId,
      c_UserSms: this.name,
      c_Celphone: this.cellphone,
      enabled: valueint,
      DateRegister: this.localSave,
      SmsAlerts: this.listId
    }

    let formData = new FormData();
    formData.append('data', JSON.stringify(update));

    this.SmsService.updatedSms(formData).subscribe(
      result => {
        this.AlertService.showMessage(
          AppConstants.TitleModal.UPDATE_SMS,
          AppConstants.MessageModal.UPDATE_SMS,
          MessageSeverity.success);
        this.close();
        this.emitterService.sendMessage(AppConstants.EmitterService.ListSms);
      },
      (error: HttpErrorResponse) => {
        this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
    });
    */
  }

}
