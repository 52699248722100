import { EnterpriseViewModel } from "../enterprise/enterprise";
import { HeadquarterModel } from "../headquarter/headquarter";
import { MenuEnterpriseHeadquarterRoleModel } from "../menu/menuEnterpriseHeadquarterRoleModel";

export class Admin {
}

export class Rol {
  rolId: number;
  rolName: string;
  enanled: number;
}

export class Menu {
  menuSection: Array<MenuSection>;
  enterpriseSystem: EnterpriseSystem;
}

export class EnterpriseSystem {
  va: number;
  showroom: number;
  iot: number;
  streaming: number;
}

export class MenuSection {
  heading: string;
  icon: string;
  link: string;
  pages: Array<Pages>;
}

export class Pages {
  title: string;
  link: string;
  icon: string;
}

export class GetUserInfo {
  data: UserInfo;
  error: Error;
}

export class UserInfo {
  userId : number;
  enterprises : Array<EnterpriseViewModel>;
  headquarters : Array<HeadquarterModel>;
  menuEnterpriseHeadquarterRole : Array<MenuEnterpriseHeadquarterRoleModel>;
  fullName: string;
  roleId : number;
  enterpriseId: number;
  idHeadquarter: number;
  token: string;
  systems: string;
}


