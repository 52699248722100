import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { RoleService } from 'src/app/services/role/role.service';
import { SensorTypeService } from 'src/app/services/sensor-type/sensor-type.service';
import { UserService } from 'src/app/services/user/user.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-graph-new-assign-modal',
  templateUrl: './graph-new-assign-modal.component.html',
  styleUrls: ['./graph-new-assign-modal.component.css']
})
export class GraphNewAssignModalComponent implements OnInit {

  userForm: FormGroup;
  graphSelect: number;
  graphs = [];

  constructor(
    public dialogRef: MatDialogRef<GraphNewAssignModalComponent>,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private sensorTypeService: SensorTypeService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getGraphs();
  }

  getGraphs() {
    this.spinner.show();
    this.sensorTypeService.getListGraphs().subscribe((response) => {
      if(response.error == null){
        this.graphs = response.data;
        if(this.graphs.length>0){
          this.graphSelect = this.graphs[0].id;
        }
      }else{
        this.graphs = [];
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.graphs = [];
        this.spinner.hide();
      });
}

  createForm(){
    this.userForm = this.formBuilder.group({
      idGraph: ['', [Validators.required]]
    })
  }

  get f() { return this.userForm.controls; }

  save(){
    if(this.userForm.valid){
      var data = {
        idGraph: this.graphSelect,
        idSensorType: this.data.idSensorType,
        idUser:localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
        this.sensorTypeService.createGraph(data).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_USER_RELATIONSHIP, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
          this.spinner.hide();
        });
    }else{
      this.userForm.markAllAsTouched();
    }
  }


}
