import { Options } from '@angular-slider/ngx-slider';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ShowRoomService } from 'src/app/services/showroom/showroom.service';
import { ModalReportSensorPbiShowRoomComponent } from 'src/app/shared/components/modals/showroom/modal-report-sensor-pbi/modal-report-sensor-pbi-show-room.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-showroom',
  templateUrl: './showroom.component.html',
  styleUrls: ['./showroom.component.css']
})
export class ShowroomComponent implements OnInit {
  public itemMenu: number;
  public steps: Array<number>;
  showLab = false;
  isFullScreen: boolean = false;
  imagePanel = environment.pathFile + "assets/showroom/1.png";
  imagePanelOrden = 1;
  showMenu = false;
  docElement: HTMLElement;
  finalValue: number = 0;
  showDataPanel=true;
  elem: any;
  private _hubConnection: HubConnection;
  options: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
    selectionBarGradient: {
      from: 'white',
      to: '#01c4e7'
    }
  };

  options2: Options = {
    floor: 0,
    ceil: 100,
    showTicks: false,
    showTicksValues: true,
    stepsArray: [
      {value: 0},
      {value: 10},
      {value: 25},
      {value: 50},
      {value: 100}
    ],
    showSelectionBar: true,
    selectionBarGradient: {
      from: 'white',
      to: '#01c4e7'
    }
  };
  actuators = [];
  staticActuators = [];
  constructor(private router: Router ,
    public dialog: MatDialog,
    public showroomService: ShowRoomService,) {
    this.itemMenu = 1;
    this.showroomService.getPanels().subscribe(_=>{
      if(_.error == null){
        var sc = _.data.filter( x => x.id == 'sharedControl')[0];
        if(sc.value){ _this.showLab = true;}else{ _this.showLab = false; }// _this.router.navigate(['/gys/showroom/panel/diagram/public']) }
      }
    });

    var _this = this;
    const connection =  this._hubConnection = new HubConnectionBuilder()
      .withUrl(environment.wsUrl +"/ws/showroom",{
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      }).build();
      var _this = this;
      connection.on("sharedDiagram", (data) => {
        _this.showLab = data;
        _this.openFullscreen();
      });
      connection.start().catch(err => document.write(err));
   /* setInterval(function () {
      if(_this.showDataPanel){
        _this.showroomService.getPanels().subscribe(_=>{
          if(_.error == null){
            _this.showDataPanel = false;
            var sc = _.data.filter( x => x.id == 'sharedControl')[0];
            console.log("redirrecion a diagrama",sc.value);
            if(sc.value){ _this.showLab = true;}else{ _this.showLab = false; }// _this.router.navigate(['/gys/showroom/panel/diagram/public']) }
            _this.showDataPanel = true;
          }
        });
      }
    },
    500);*/

  }

  getListActuators(){
    this.showroomService.getListActuators().subscribe((_) => {
      this.actuators = _.data;
      var start = parseInt(this.actuators.filter(_ => _.id == 6)[0].value);
      var stop = parseInt(this.actuators.filter(_ => _.id == 7)[0].value);
      var startAlert = parseInt(this.actuators.filter(_ => _.id == 11)[0].value);
      var stopAlert = parseInt(this.actuators.filter(_ => _.id == 12)[0].value);
     // if(start==1 && stop==0){ start = 0; }

      this.actuators.filter(_ => {
        let options;
        _.idPanel = "panelActuator"+_.id;
        if(_.maxLimit > 0){
          _.value =(_.value * 100) / _.maxLimit;
        }
        if(_.type==2){
          _.colors = ['#e5f6ff', '#82cfff'];
        }else{
          if(_.stepOptions != "" && _.stepOptions){
            var stepsArray = [];
            var array = _.stepOptions.split(",");
            array.forEach(function(n) {
              stepsArray.push({value: n});
            })
            options = {
              floor: 0,
              ceil: 100,
              showTicks: false,
              showTicksValues: true,
              stepsArray: stepsArray,
              showSelectionBar: true,
              selectionBarGradient: {
                from: 'white',
                to: '#01c4e7'
              }
            };
          }else{
            options = {
              floor: 0,
              ceil: 100,
              showSelectionBar: true,
              selectionBarGradient: {
                from: 'white',
                to: '#01c4e7'
              }
            }
          }
        }

        if(_.type == 6){
          _.relationshipActuators.filter(__ => {
            __.idPanel = "panelActuator"+__.id;
            __.update = 0;
          });
        }
        _.dataValue =  _.value;
        _.options = options;
        _.update = 0;
        _.beforeValue = _.value;
      });
      this.staticActuators = this.actuators;
      this.staticActuators = this.staticActuators.filter(_ => _.id != 6 && _.id != 7 && _.id != 11 && _.id != 12);

    },
      (error: HttpErrorResponse) => {

    });
  }
  ngOnInit() {
    this.docElement = document.documentElement;
    this.getListActuators();
  }

  goControl(){
    this.router.navigate(['/gys/showroom/panel/diagram/public']);
  }

  showMenuItem(item){
    this.itemMenu = item;
  }


  selectMenu(){
    this.showMenu = !this.showMenu;
  }

  toggleFullScreen() {
    if (!this.isFullScreen) {
      this.docElement.requestFullscreen();
    }
    else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }


  nextPanel(){
    this.imagePanelOrden++;
    if(this.imagePanelOrden == 8){
      this.imagePanelOrden=1;
    }

    this.imagePanel = environment.pathFile + "assets/showroom/"+this.imagePanelOrden+".png";
  }
/*
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }*/


  getDate(){
    var d = new Date(),
        hora = d.getHours(),
        minutos = d.getMinutes(),
        segundos = d.getSeconds(),
        month = (d.getMonth()+1),
        day = d.getDate().toString();
        if(parseInt(day) < 10){ day = "0"+day.toString(); }
        var m = month.toString();
        if(month < 10){ m = "0"+month; }
  return d.getFullYear() + "-" + m + "-" + day + " "+ hora+":"+minutos+":"+segundos;
}


openFullscreen() {
  if (this.elem.requestFullscreen) {
    this.elem.requestFullscreen();
  } else if (this.elem.mozRequestFullScreen) {
    /* Firefox */
    this.elem.mozRequestFullScreen();
  } else if (this.elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    this.elem.webkitRequestFullscreen();
  } else if (this.elem.msRequestFullscreen) {
    /* IE/Edge */
    this.elem.msRequestFullscreen();
  }
}

  showPBI(){
    let dialogRef = this.dialog.open(ModalReportSensorPbiShowRoomComponent, {
      width: '1000px',
    });
  }
}
