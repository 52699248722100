import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetStreamingServer } from 'src/app/models/streamingServer/getStreamingServer';
import { GetStreamingStadistics } from 'src/app/models/streamingStadistics/getStreamingStadistics';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StreamingStadisticsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'StreamingStadistics';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getList(data: any): Observable<GetStreamingStadistics> {
    return this.httpClient.post<GetStreamingStadistics>(`${this.REQUEST_URL}/getList`,data);
  }

  downloadExportExcel(data: any){
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/export-excel-list`, data,{
      responseType: 'blob' as 'json'
    })
      .pipe(catchError(this.parseErrorBlob))
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }

  createStadistics(data){
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var nameOffset,verOffset;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
    browserName = "Opera";
    // In MSIE, the true version is after "MSIE" in userAgent
    }else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
    browserName = "Microsoft Internet Explorer";
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
      browserName = "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
      browserName = "Safari";
    // In Firefox, the true version is after "Firefox"
    }else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
    browserName = "Firefox";
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
              (verOffset=nAgt.lastIndexOf('/')) )
    {
      browserName = nAgt.substring(nameOffset,verOffset);
      if (browserName.toLowerCase()==browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    var OSName="Unknown OS";
    if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
    if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";
    if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX";
    if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux";

    const detectDeviceType = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ? 'Móvil'
      : 'PC';

    var body = {
      browser: browserName,
      userAgent: navigator.userAgent,
      os: OSName,
      successValidate: data.successValidate,
      ip: this.getIpCliente(),
      pc_movil: detectDeviceType(),
      originDomain: data.originDomain,
      idCamera: data.idCamera,
      origin: data.origin,
      idEnterprise: data.idEnterprise
    };

    return this.httpClient.post<any>(`${this.REQUEST_URL}`,body);
  }

  getIpCliente() {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", "https://api.ipify.org/", false );
    xmlHttp.send( null );
    return xmlHttp.responseText;
  }
}
