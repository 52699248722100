<div class="row title-modal">
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
      <mat-icon color="primary" (click)="closeModal()" >close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
<div class="container">
  <div class="row">
    <div class="col-12 col-md-4">
      <label style="font:bold">Cámara:<span>{{data.mode2}}</span></label>
    </div>
    <div class="col-12 col-md-4">
      <label style="font:bold">Fecha de registro:<span>{{data.fech2}}</span></label>
    </div>
    <div class="col-12 col-md-4">
      <label style="font:bold">Hora:<span>{{data.hora2}}</span></label>
    </div>
  </div>
  <form [formGroup]="alertForm" action="" class="mt-3">
    <!-- <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field class="w-100">
          <mat-label>Contraseña</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" maxlength="6" OnlyNumbers>
          <mat-icon matSuffix (click)="hide = !hide" class="icon-cursor" color="primary">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="f.password.hasError('required')">
            La contraseña es <strong>requerida</strong>.
          </mat-error>
        </mat-form-field>
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Observación</mat-label>
          <textarea matInput rows="6" maxlength="500" formControlName="description"></textarea>
          <mat-error *ngIf="f.description.hasError('required')">
            La observación es <strong>requerida</strong>.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button color="primary" (click)="openConfirmDialog()">GUARDAR</button>
</mat-dialog-actions>