import { Component, Input } from '@angular/core';
import DPlayer from '../../../../../assets/js/dplayer_mod.js';

@Component({
  selector: 'app-item-camera-streaming',
  templateUrl: './item-camera-streaming.component.html',
  styleUrls: ['./item-camera-streaming.component.css']
})
export class ItemCameraStreamingComponent{
  @Input('id') id : any;
  @Input('url') url : any;

  constructor() {}

  ngAfterViewInit(){
    var IsiPhone = navigator.userAgent.indexOf("iPhone") != -1 ;
    var IsiPod = navigator.userAgent.indexOf("iPod") != -1 ;
    var IsiPad = navigator.userAgent.indexOf("iPad") != -1 ;
    var IsiPhoneOS = IsiPhone || IsiPad || IsiPod ;
    var video;
    //if (IsiPhoneOS) {
      video = {
	      quality: [
            {
                name: 'HD',
                url: this.url+'/index.m3u8',
                type: 'hls',
            },
            {
                name: '480',
                url: this.url +'_480/index.m3u8',
                type: 'hls',
            },
            {
                name: '720',
                url: this.url +'_720/index.m3u8',
                type: 'hls',
            },
        ],
        defaultQuality: 0,
      }
    /*} else {
      video = {
	      quality: [
            {
                name: 'HD',
                url: this.url+'.flv',
                type: 'flv',
            },
            {
                name: '480',
                url: this.url +'_480.flv',
                type: 'flv',
            },
            {
                name: '720',
                url: this.url +'_720.flv',
                type: 'flv',
            },
        ],
        defaultQuality: 0,
      }
    }*/

    const dp = new DPlayer({
      container: document.getElementById(this.id),
      screenshot: false,
      live: true,
      preload: 'none',
      fill: true,
      airplay: true,
      mutex: true,
      hotkey: true,
      video: video
    });
  }

}
