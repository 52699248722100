import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { GatewayService } from 'src/app/services/gateway/gateway.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { SensorReadingplaceService } from 'src/app/services/sensor-readingplace/sensor-readingplace.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-sensor-readingplace-modal',
  templateUrl: './sensor-readingplace-modal.component.html',
  styleUrls: ['./sensor-readingplace-modal.component.css']
})
export class SensorReadingplaceModalComponent implements OnInit {

  gatewayForm: FormGroup;
  headquarterSelect: number;
  headquarters = [];
  edit = false;

  constructor(
    public dialogRef: MatDialogRef<SensorReadingplaceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private headquarterService: HeadquarterService,
    private sensorReadingplaceService: SensorReadingplaceService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    this.headquarters = this.data.headquarters;
    this.setHeadquarter();
    if(this.data.action == AppConstants.OperationType.ADD){
      if(this.data.idHeadquarter == 0) this.edit = true;
      if(this.data.idHeadquarter){
        this.headquarterSelect = this.data.idHeadquarter;
      }
    }

    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.edit = true;
      this.headquarterSelect = this.data.obj.idHeadquarter;
      this.gatewayForm.controls['name'].setValue(this.data.obj.name);
      this.gatewayForm.controls['description'].setValue(this.data.obj.description);
    }
  }

  getHeadquarters() {
    this.spinner.show();
    this.headquarterService.getListAll().subscribe((response) => {
      if(response.error == null){
        this.headquarters = response.data.filter(f => f.idEnterprise == this.data.idEnterprise);
        console.log("enterprises",this.headquarters[0]);
        if(this.data.obj != null){
          this.headquarterSelect = this.data.obj.idHeadquarter;
        }else{
          this.headquarterSelect = this.headquarters[0].id;
        }
      }else{
        this.headquarters = [];
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.headquarters = [];
        this.spinner.hide();
      });
}

  setHeadquarter(){
    if(this.data.idHeadquarter > 0){
      var headquarters = JSON.parse(localStorage.getItem(AppConstants.Session.HEADQUARTERS));
      var _this = this;
      var items = headquarters.filter(x => x.idEnterprise == _this.data.enterpriseId);
      if(items.length > 0){
        _this.headquarters = items;
        if(_this.data.idHeadquarter != 0){
          _this.headquarterSelect = items[0].id;
        }
      }
    }else{
      this.getHeadquarters();
    }
    /*var headquarters = JSON.parse(localStorage.getItem(AppConstants.Session.HEADQUARTERS));
    var _this = this;
    var list = headquarters.filter(x => x.idEnterprise == _this.data.idEnterprise);
        if(list.length > 0){
          _this.headquarters = list;
          _this.headquarterSelect = list[0].id;
        }*/
  }

  createForm(){
    this.gatewayForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(50)]],
      description: ['', []],
      idHeadquarter: ['', []]
    })
  }

  get f() { return this.gatewayForm.controls; }

  save(){
    if(this.gatewayForm.valid){
      const payload = {
        name:this.gatewayForm.value["name"],
        description:this.gatewayForm.value["description"],
        id: this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0,
        idHeadquarter: this.headquarterSelect,
        idEnterprise: this.data.idEnterprise,
        idUser: localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
      if(this.data.action == AppConstants.OperationType.ADD){
        this.sensorReadingplaceService.create(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_SENSOR_READINGPLACE, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
              this.spinner.hide();
          }
        });
      }else{
        this.sensorReadingplaceService.update(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_SENSOR_READINGPLACE, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
            this.spinner.hide();
          }
        });
      }
    }else{
      this.gatewayForm.markAllAsTouched();
    }
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
  }


  close() {
    this.dialog.closeAll();
  }
}
