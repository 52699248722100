<section class="container">
  <div class="row section-name">
    <div class="header-title-options">
        <div class="row w-100">
          <div class="col-lg-10 col-xs-12">
            <mat-form-field appearance="standard">
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="applyFilter($event)" #input>
            </mat-form-field>
          </div>
        </div>
    </div>
  </div>
</section>
<div class="container">

   <!-- <div class="row mt-3 section-name">
      <div class="col">
        Lista de Dominios permitidos para uso de Iframe
      </div>
    </div>

    <section class="filter mt-3" >
      <div class="row">
        <div class="col-lg-4 col-xs-12">
          <mat-form-field class="w-100">
            <mat-label>Empresa</mat-label>
            <mat-select (selectionChange)="changeEnterprise($event)"
                        [(ngModel)]="enterpriseSelect">
              <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                {{enterprise.businessName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-xs-12">
          <mat-form-field class="w-100">
              <mat-label>Sede</mat-label>
              <mat-select
                          (selectionChange)="changeHeadquarter($event)"
                          [(ngModel)]="headquarterSelect">
                  <mat-option [value]="0" >
                      Todas
                  </mat-option>
                  <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                      {{headquarter.name}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-xs-12 my-auto">
          <button mat-raised-button color="primary" class="color-white" (click)="openAddModal()">AGREGAR</button>
        </div>
      </div>
    </section>-->

    <section class="table-responsive">
      <table class="table" mat-table [dataSource]="dataSource" >

        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>

        <ng-container matColumnDef="domain">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Dominio </th>
          <td class="text-center" *matCellDef="let element"> {{element.domain}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Acciones </th>
          <td class="text-center" *matCellDef="let element">
            <mat-icon color="primary" class="icon-cursor" matTooltip="Editar" [style.color]="color1" *ngIf="enabledWrite" (click)="openEditModal(element)">create</mat-icon>
            <mat-icon color="primary" class="icon-cursor" matTooltip="Ver" [style.color]="color1" *ngIf="enabledRead && !enabledWrite" (click)="openEditModal(element)">remove_red_eye</mat-icon>
            <mat-icon color="primary" class="icon-cursor" matTooltip="Eliminar" *ngIf="enabledWrite" [style.color]="color1" (click)="delete(element)">delete</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="12"
                     [hidePageSize]="true">
      </mat-paginator>
    </section>

  </div>

  <button mat-fab class="fab-button" *ngIf="enabledWrite" [style.background]="color2" aria-label="Add icon" (click)="openAddModal()" >
    <mat-icon>add</mat-icon>
  </button>

