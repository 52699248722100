import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OnlyDecimalDirective } from '../directives/only-decimal.directive';
import { OnlyLettersDirective } from '../directives/only-letters.directive';
import { OnlyNumbersDirective } from '../directives/only-numbers.directive';
import { OnlyTextDirective } from '../directives/only-text.directive';
import { DecimalAmountDirective } from '../directives/decimal-amount.directive';
import { ToggleColorDirective } from '../directives/toggle-color.directive';
import { OutlineBorderDirective } from '../directives/form-border-color.directive';

@NgModule({
  imports: [

  ],
  declarations: [
    OnlyDecimalDirective,
    OnlyLettersDirective,
    OnlyNumbersDirective,
    OnlyTextDirective,
    DecimalAmountDirective,
    ToggleColorDirective,
    OutlineBorderDirective
  ],
  providers: [
    //HelperService
  ],
  exports: [
    CommonModule,
    //ModalModule,
    //NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OnlyLettersDirective,
    OnlyNumbersDirective,
    OnlyDecimalDirective,
    OnlyTextDirective,
    DecimalAmountDirective,
    ToggleColorDirective,
    OutlineBorderDirective
  ]
})

export class SharedModule { }
