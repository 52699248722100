<div class="container">
<!--
    <div class="row mt-3 section-name">
        <div class="col">
          Cámaras
        </div>
    </div>
  -->
    <section class="filter mt-3" >
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="row">
            <!--<div class="col-12 col-lg-3 col-xs-12" *ngIf="isAdministrator">
              <mat-form-field class="w-100">
                <mat-label>Empresa</mat-label>
                <mat-select (selectionChange)="changeEnterprise($event)"
                            [(ngModel)]="enterpriseSelect">
                  <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                    {{enterprise.businessName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-3 col-xs-12">
              <mat-form-field class="w-100">
                  <mat-label>Sede</mat-label>
                  <mat-select
                              (selectionChange)="changeHeadquarter($event)"
                              [(ngModel)]="headquarterSelect">
                      <mat-option [value]="0" >
                          Todas
                      </mat-option>
                      <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                          {{headquarter.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12 col-lg-2 col-xs-12" *ngIf="!isAdministratorStreaming">
              <mat-form-field class="w-100" color="primary">
                  <mat-label>Zona de Ubicación</mat-label>
                  <mat-select (selectionChange)="changeZone($event)"
                      [(ngModel)]="enterpriseZoneSelect">
                      <mat-option *ngFor="let zone of enterprisesZones" [value]="zone.zoneId">
                          {{zone.zoneName}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>-->

            <div class="col-12 col-lg-3 col-xs-12">
              <mat-form-field class="w-100" color="primary">
                  <mat-label>Buscar Cámara</mat-label>
                  <input (keyup)="search()" [(ngModel)]="inputSearch"  matInput maxlength="500">
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-4 col-xs-12">
              <div class="buttons-camera-streaming">
                <button mat-raised-button *ngIf="listCameras.length > 0" (click)="showStreaming(enterpriseToken,1)">Vista Múltiple - TOP 4</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="filter mt-3 mb-3">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-xs-12 mb-3" *ngFor="let item of listCameras; let i = index">
            <mat-card class="example-card">
              <mat-card-header>
                <mat-card-title>
                {{ (item.camera.length>limitTitleStreaming) ? (item.camera | slice:0:limitTitleStreaming)+ '...' :(item.camera) }}
                </mat-card-title>
              </mat-card-header>

              <div class="slider-image"  *ngIf="!item.play">
                  <img class="img" src="{{item.streamingImage}}" alt="img-camera" />
                  <div class="play-body" >
                    <div class="play-image">
                      <img class="img-play" src="../../../../../assets/images/play.png" (click)="playStreaming(item.cameraId)" alt="img-play" />
                    </div>
                  </div>
              </div>

              <div *ngIf="item.play">
                <app-streampublic id="stream_{{item.cameraId}}" [id]="item.cameraId" [url]="item.streamingUrl" ></app-streampublic>
              </div>

              <mat-card-content class="mt-3">
                <p>{{ item.streamingDescription }}</p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button (click)="showStreaming(item.token,0)" ><mat-icon class="icon-copy">video_library</mat-icon> Live</button>
                <button mat-button (click)="copyIframe(item.token)" ><mat-icon class="icon-copy">code</mat-icon> Iframe</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
    </section>
</div>

