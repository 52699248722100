
    <div class="container mt-3">
        <form [formGroup]="settingForm" autocomplete="off"  [ngClass]=" enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="row" >
              <div class="col-12 col-lg-12" style="display: grid;justify-content: center;">
                <img [src]="logo" width="200px" height="180px" style="background-color:#00000030" >
                <button type="button" mat-raised-button (click)="fileInput.click()">Seleccionar Logo</button>
                <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">
              </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Color Primario*</mat-label>
                        <input formControlName="color1" matInput maxlength="100" [style.background]="colorPrimary"   [(colorPicker)]="colorPrimary"  [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)', '#19af35', '#aaafab', '#f4a619', '#1d26c3']" >
                        <mat-error *ngIf="f.color1.hasError('required')">
                            El Color 1 es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Color Secundario*</mat-label>
                        <input formControlName="color2" matInput [style.background]="colorSecundary" [(colorPicker)]="colorSecundary" maxlength="100" [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)', '#19af35', '#aaafab', '#f4a619', '#1d26c3']" >
                        <mat-error *ngIf="f.color2.hasError('required')">
                            El Color 2 es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
 

        <div class="mt-3 text-right">
            <button mat-raised-button  [style.color]="color2"  [style.background]="color1" *ngIf="enabledWrite"  (click)="openConfirmationModal()">GUARDAR</button>
        </div>
    </div>
