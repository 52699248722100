<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
    <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
      <form [formGroup]="roleForm" [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
          <div class="row">
              <div class="col-12">
                  <mat-form-field class="w-100">
                      <mat-label>Nombre*</mat-label>
                      <input matInput formControlName="name" maxlength="50">
                      <mat-error *ngIf="f.name.hasError('required')">
                          El Nombre es <strong>requerido</strong>.
                      </mat-error>
                  </mat-form-field>
                    <table class="table table-responsive mt-2 tableFixHead" style="height: 300px;">
                      <thead >
                        <tr [style.background]="data.color" [style.color]="data.colorFont" >
                          <th  [style.background]="data.color">Menu</th>
                          <th  [style.background]="data.color">Lectura</th>
                          <th  [style.background]="data.color">Escritura</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of menu" >
                          <td class="icon-td">
                            <span>
                              <mat-icon class="icon-cursor" [style.color]="color" >{{item.icon}}</mat-icon>
                            </span>
                            <span class="ml-2">{{item.name}}</span>
                          </td>
                          <td>
                            <span *ngIf="showCheckbox(item)" >
                              <input type="checkbox" (click)="activeRead(item)" *ngIf="item.activeRead" checked>
                              <input type="checkbox" (click)="activeRead(item)" *ngIf="!item.activeRead"  >
                            </span>
                          </td>
                          <td>
                            <span *ngIf="showCheckbox(item)">
                              <input type="checkbox" (click)="activeWrite(item)" *ngIf="item.activeWrite" checked>
                              <input type="checkbox" (click)="activeWrite(item)" *ngIf="!item.activeWrite"  >
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
              </div>
          </div>
      </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont"  *ngIf="data.enabledWrite" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
