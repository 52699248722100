<div class="row title-modal">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon color="primary" [mat-dialog-close]="true">close</mat-icon>
    </div>
  </div>
  <mat-dialog-content class="mat-typography mt-3">
    <div class="container">
      <form [formGroup]="sensorForm" [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' " >

            <div class="row mt-2">
              <div class="col-lg-12 col-xs-12">
                  <mat-form-field class="w-100">
                      <mat-label>Actuador*</mat-label>
                      <mat-select formControlName="idActuator" (selectionChange)="changeSensor($event)" >
                          <mat-option *ngFor="let sensor of actuators" [value]="sensor.id">
                              {{sensor.name}}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="f.idActuator.hasError('required')">
                        El actuador es <strong>requerida</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-lg-12 col-xs-12">
                <mat-form-field class="w-100">
                    <mat-label>Nomenclatura*</mat-label>
                    <input matInput formControlName="nomenclature" >
                    <mat-error *ngIf="f.nomenclature.hasError('required')">
                        La nomenclatura <strong>requerida</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.nomenclature.errors?.maxlength">
                      La nomenclatura no puede ser mayor a 30 caracteres.
                    </mat-error>
                    <mat-error *ngIf="f.nomenclature.errors?.minlength">
                      La nomenclatura no puede ser menor a 2 caracteres.
                    </mat-error>
                </mat-form-field>
              </div>
            </div>
      </form>
    </div>
  </mat-dialog-content>

      <div class="row mt-3">
        <div class="col-lg-6 col-xs-12">
            <button mat-button class="btn-danger" (click)="delete()" *ngIf="data.enabledWrite">ELIMINAR</button>
        </div>
        <div class="col-lg-6 col-xs-12 text-right">
            <button mat-button mat-dialog-close>CERRAR</button>
        <button mat-raised-button color="primary" (click)="save()" *ngIf="data.enabledWrite">GUARDAR</button>
        </div>
      </div>

