import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Rol, Menu, UserInfo, GetUserInfo } from '../../models/admin/admin';
import { GetMenu } from 'src/app/models/menu/getMenu';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Admin';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getEnterprises(): Observable<Rol[]> {
    return this.httpClient.get<Rol[]>(`${this.REQUEST_URL}/GetRoles`);
  }

  getMenu(roleId: number,enterpriseId: number): Observable<Menu> {
    return this.httpClient.get<Menu>(`${this.REQUEST_URL}/Role/${roleId}/enterprise/${enterpriseId}/GetMenu`);
  }

  getUserInfoByUsername(username: string) : Observable<GetUserInfo> {
    return this.httpClient.get<GetUserInfo>(`${this.REQUEST_URL}/user/${username}/info`);
  }

  getMenuEnterprise(enterpriseId: number): Observable<GetMenu> {
    return this.httpClient.get<GetMenu>(`${this.REQUEST_URL}/menu/enterprise/${enterpriseId}`);
  }

  getMenuEnterpriseRole(idEnterprise:number, idHeadquarter:number,idUser:number): Observable<GetMenu>{
    return this.httpClient.get<GetMenu>(`${this.REQUEST_URL}/menu/enterprise/${idEnterprise}/headquarter/${idHeadquarter}/user/${idUser}`);
  }
}
