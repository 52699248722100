<div class="container">
    <!--<div class="row mt-3 section-name">
        <div class="col">
          Panel de Sensores
        </div>
    </div>-->
    <section class="filter mt-3">
      <div class="row">
          <div class="col-12 col-lg-12">
              <div class="row">
                  <!--<div class="col-12 col-lg-3" *ngIf="isAdminGys">
                      <mat-form-field class="w-100">
                          <mat-label>Empresa</mat-label>
                          <mat-select
                                      (selectionChange)="changeEnterprise($event)"
                                      [(ngModel)]="enterpriseSelect">
                               <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                                  {{enterprise.businessName}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-3">
                    <mat-form-field class="w-100">
                      <mat-label>Sede</mat-label>
                      <mat-select
                                  (selectionChange)="changeHeadquarter($event)"
                                  [(ngModel)]="headquarterSelect">
                           <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                              {{headquarter.name}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>-->
                  <div class="col-12 col-lg-3">
                    <mat-form-field class="w-100">
                      <mat-label>Zonas</mat-label>
                      <mat-select
                                  (selectionChange)="changeZone($event)"
                                  [(ngModel)]="zoneSelect">
                           <mat-option *ngFor="let zone of zones" [value]="zone.id">
                              {{zone.name}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-3">
                    <mat-form-field class="w-100">
                      <mat-label>Gateway</mat-label>
                      <mat-select
                          (selectionChange)="changeGateway($event)"
                          [(ngModel)]="gatewaySelect">
                          <mat-option *ngFor="let gateway of gateways" [value]="gateway.id">
                              {{gateway.name}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2" style="text-align: -webkit-center;">
                    <mat-label>Mostrar Valores</mat-label>
                    <br>
                    <mat-slide-toggle
                        (change)="showTable($event)"
                         toggleColor circle="{{color1}}" bar="#d0d0d0"
                        class="example-margin"
                        [checked]="showSensorsTable">
                    </mat-slide-toggle>
                  </div>
                  <div class="col-12 col-lg-3 mt-3">
                    Vista Gráfica
                    <mat-slide-toggle
                        (change)="showView($event)"
                         toggleColor circle="{{color1}}" bar="#d0d0d0"
                        class="example-margin mr-2">
                        Vista Tabla
                    </mat-slide-toggle>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="mt-4" [style.display]="displayListStadistics">
    <div style="display: flex;justify-content: center;">
      <div style="
          text-align: center;
          text-align: center;
        border: solid 1px transparent;
        padding: 6px;
      " [style.color]="color2" [style.background]="color1" >
        <label *ngIf="isGuardTotal" >Total Warrant</label>
        <label *ngIf="!isGuardTotal" >Total Warrant x TQ</label>
        <h5 *ngIf="!editSetting && isGuardTotal">{{guardTotal}}</h5>
        <h5 *ngIf="!editSetting && !isGuardTotal">{{guardTotalTQ}}</h5>
        <div *ngIf="editSetting && isGuardTotal">
          <input type="number" class="control-form text-center"   [(ngModel)]="guardTotal"  value="{{guardTotal}}">
        </div>
        <div *ngIf="editSetting && !isGuardTotal">
          {{guardTotalTQ}}
        </div>
      </div>  
      <div style="text-align: center;
          border: solid 1px transparent;
          padding: 6px;
          text-align: center;margin-left: 2%;
      " [style.color]="color2" [style.background]="color1" >
        <label>Total Contenido</label>
        <h5>{{contentTotal}}</h5>
      </div>
      <div style="display: flex;align-items: center;color:black;margin-left: 2%;" *ngIf="editSetting">
          Warrant x Tanque 
          <mat-slide-toggle (change)="isGuardTotalSwitch($event)"
           toggleColor circle="{{color1}}" bar="#d0d0d0"
           [(ngModel)]="isGuardTotal"
            class="example-margin mr-2">
         </mat-slide-toggle>      
         Total Warrant
      </div>
    </div>
    <div class="row" style="justify-content: center;z-index:1;position: relative;text-align: -webkit-center;">
      <div class="col-lg-12 col-xs-12" style="padding: 0px;">
        <div (dragover)="false" (dragend)="false" (mouseup)="showGraph()">
          <canvas id="canvas" width="1000" height="500" style="border-style: solid; border-color:#D9CDAB"></canvas>
          <!--<div class="legend-sensor-panel">
            <div class="legend-state"><div class="sensor-panel installed"></div> instalado</div>
            <div class="legend-state"><div class="sensor-panel actived"></div> activo</div>
            <div class="legend-state"><div class="sensor-panel inactived"></div> inactivo</div>
          </div>-->
          <app-sensor-graph-modal *ngIf="showSensorsGraphs" [idGateway]="gatewaySelect"  [sensors]="sensors" style="z-index: 9999999999999 !important;"></app-sensor-graph-modal>
        </div>
      </div>
    </div>
    <div class="row" style="text-align: -webkit-center;">
        <div  class="col-lg-6 col-xs-12">
          <span class="title-legend mb-1"><b>Densidad</b></span>
          <div class="row">
            <div  class="col-lg-6 col-xs-12" >
              <p style="text-align: -webkit-center; font-size: 12px;">
                <!-- <span style="width:20px;height: 20px;display: block;border-radius: 50%;background-color: rgb(252, 235, 0);"></span>
                --> <img src="../../../../../assets/actuator/density/aceite.png" style="width:20px;display: block;">
                Aceite</p>
              </div>
            <div  class="col-lg-6 col-xs-12" > 
              <p style="text-align: -webkit-center;font-size: 12px;">
                <!--<span style="width:20px;height: 20px;display: block;border-radius: 50%;background-color: blue;"></span> 
                --> <img src="../../../../../assets/actuator/density/agua.png" style="width:20px;display: block;">
                Mezcla</p></div>
          </div>
        </div>
        <div  class="col-lg-6 col-xs-12">
          <span class="title-legend"><b>Estado</b></span>
          <div class="row">
            <div class="col-lg-4 col-xs-12"><p style="text-align: -webkit-center;font-size: 12px;"><span style="width:20px;height: 20px;display: block;border-radius: 50%;background-color: green;"></span> Conectado</p></div>
            <div class="col-lg-4 col-xs-12"><p style="text-align: -webkit-center;font-size: 12px;"><span style="width:20px;height: 20px;display: block;border-radius: 50%;background-color: gray;"></span> Desconectado</p></div>
            <div class="col-lg-4 col-xs-12"><p style="text-align: -webkit-center;font-size: 12px;"><span style="width:20px;height: 20px;display: block;border-radius: 50%;background-color: red;"></span> Error conexión</p></div>
          </div>
        </div>
    </div>
  </section>

  <section *ngIf="displayListStadistics == 'none'">
    <div class="row" style="justify-content: center;z-index:1;position: relative;">
      <table class="table">
        <thead>
          <tr>
            <th>Item</th>
            <th>Estado</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of dataStreaming" >
            <td>{{item.nomenclature}}</td>
            <td>{{item.stateSensor}}</td>
            <td>{{item.value}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
<!--
   <div class="btn-save" >

  </div>
-->
<button mat-fab class="fab-button" *ngIf="!editSetting && enabledWrite && gateways.length > 0" [style.background]="color1" aria-label="Add icon" (click)="offEdit()" >
  <mat-icon>create</mat-icon>
</button>

<button mat-fab class="fab-button" *ngIf="editSetting && enabledWrite && gateways.length > 0" [style.background]="color1" aria-label="Add icon" (click)="offEdit()" >
  <mat-icon>close</mat-icon>
</button>

<button mat-fab class="fab-button2" *ngIf="editSetting && enabledWrite && gateways.length > 0" [style.background]="color1" aria-label="Add icon" (click)="save()" >
  <mat-icon>save</mat-icon>
</button>

<button mat-fab class="fab-button3" *ngIf="editSetting && enabledWrite" [style.background]="color1" aria-label="Add icon" (click)="showGuardTank()" >
  <mat-icon>settings_input_composite</mat-icon>
</button>

<button mat-fab class="fab-button2" *ngIf="!editSetting && enabledWrite" [style.background]="color1" aria-label="Add icon" (click)="showGuardTank()" >
  <mat-icon>settings_input_composite</mat-icon>
</button>

  </div>
