import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { EnterprisePinMaps } from 'src/app/models/enterprise/enterprisePinMaps';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { EnterpriseZones } from '../../../../models/enterprise/enterprise';
import { AppConstants } from '../../../constants/app.contants';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { EmitterService } from '../../../services/emitter.service';

@Component({
    selector: 'app-enterprise-modal',
    templateUrl: './enterprise-modal.component.html',
    styleUrls: ['./enterprise-modal.component.css']
})
export class EnterpriseModalComponent implements OnInit {

    loading: boolean = false;
    enterpriseForm: FormGroup;
    files: File[] = [];
    pinMapfiles: File[] = [];
    dataSource = new MatTableDataSource();
    dataSource2 = new MatTableDataSource();
    displayedColumns: string[] = ['name', 'action']; //'index',
    displayedColumns2: string[] = ['name', 'action']; //'index',
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatPaginator, { static: false }) paginator2: MatPaginator;
    subscription: Subscription;
    listZones = new Array<EnterpriseZones>();
    listPinMaps = new Array<EnterprisePinMaps>();
    listZonesDelete = new Array<number>();
    listPinMapsDelete = new Array<number>();
    idStorageFile: number;

    todayDate: Date = new Date();
    showEdit: boolean = false;
    isBusiness: boolean = false;
    sessionUserId: string;

    constructor(
        public dialogRef: MatDialogRef<EnterpriseModalComponent>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private enterpriseService: EnterpriseService,
        private alertService: AlertService,
        private emitterService: EmitterService,
        public datepipe: DatePipe
    ) {
      this.dataSource.paginator = this.paginator;
      this.dataSource2.paginator = this.paginator2;
    }

    ngOnInit() {
      this.isBusiness = +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGySComercial ? true : false;
      this.sessionUserId = localStorage.getItem(AppConstants.Session.USERID);
      this.createForm();
      this.setValues();
    }

    createForm() {
      this.showEdit = this.data.operation == AppConstants.OperationType.UPDATE ? true : false;
        this.enterpriseForm = this.formBuilder.group({
            ruc: ['-', [Validators.required, Validators.minLength(11), this.noWhitespaceValidator]],
            socialReason: ['', [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]],
            legalRepresentative: ['', [Validators.maxLength(80)]],
            domain: ['', [Validators.required,Validators.maxLength(255)]],
            phoneLegalRepresentative: ['', [Validators.maxLength(11)]],
            contact: ['', [Validators.required, Validators.maxLength(80), this.noWhitespaceValidator]],
            phoneContact: ['', [Validators.required, Validators.maxLength(11)]],
            address: ['', [Validators.maxLength(100)]],
            reference: ['', [Validators.maxLength(100)]],
            status: [true, [Validators.required]],
            expirationDate: ['', [Validators.required]],
            maxUsers: ['', [Validators.required, Validators.maxLength(4)]],
            codeEnterprise: '',
        })
    }

  get f() { return this.enterpriseForm.controls; }

  setValues() {
    this.clearFields();
    if (this.data.operation == AppConstants.OperationType.UPDATE) {
      this.spinner.show();
      this.enterpriseService.getEnterpriseById(this.data.row.id).subscribe((response => {
        console.log(response);
        this.enterpriseForm.patchValue({
          ruc: response.ruc,
          socialReason: response.businessName,
          legalRepresentative: response.legalRepresentative,
          phoneLegalRepresentative: response.representativeTelephone,
          contact: response.contac,
          phoneContact: response.contacTelephone,
          address: response.address,
          reference: response.reference,
          status: response.enabled,
          expirationDate: response.expirationDate,
          maxUsers: response.maximumUsers,
          codeEnterprise: response.codeEnterprise,
          domain: response.domain,
        });
        this.listZones = response.enterpriseZone;
        this.listPinMaps = response.enterprisePinMap;
        this.dataSource.data = this.listZones;
        this.dataSource2.data = this.listPinMaps;
        /*this.paginator.pageIndex = 0;
        this.paginator2.pageIndex = 0;*/
        this.idStorageFile = response.idStorageFile;
        this.spinner.hide();
      }),
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
    }else{
      var unix = Math.round(+new Date()/1000);
      this.enterpriseForm.patchValue({
        //ruc: unix,
        maxUsers: 9999999,
        expirationDate: '3000-01-01',
      });
    }
  }

  updateEnterprise(data: FormData) {
    this.spinner.show();
    this.enterpriseService.updateEnterprise(data).subscribe((response => {
      if(!response.message){
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ENTERPRISE, AppConstants.MessageModal.UPDATE_ENTERPRISE, MessageSeverity.success);
        this.data.result = true;
        this.dialogRef.close(this.data);
        this.emitterService.sendMessage(AppConstants.EmitterService.ListEnterpriseFilter);
      }else{
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ENTERPRISE, response.message, MessageSeverity.error);
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ENTERPRISE, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  save() {
    if (this.enterpriseForm.valid) {
      var data = new FormData();

      if (this.data.operation == AppConstants.OperationType.UPDATE) {
        data.append('enterpriseId', this.data.row.id);
        data.append('ruc', this.enterpriseForm.value["ruc"]);
        data.append('businessName', this.enterpriseForm.value["socialReason"].trim());
        data.append('legalRepresentative', this.enterpriseForm.value["legalRepresentative"] ? this.enterpriseForm.value["legalRepresentative"] : "");
        data.append('contac', this.enterpriseForm.value["contact"].trim());
        data.append('representativeTelephone', this.enterpriseForm.value["phoneLegalRepresentative"] ? this.enterpriseForm.value["phoneLegalRepresentative"] : "");
        data.append('contacTelephone', this.enterpriseForm.value["phoneContact"]);
        data.append('address', this.enterpriseForm.value["address"] ? this.enterpriseForm.value["address"] : "");
        data.append('reference', this.enterpriseForm.value["reference"] ? this.enterpriseForm.value["reference"] : "");
        data.append('maximumUsers', this.enterpriseForm.value["maxUsers"]);
        data.append('domain', this.enterpriseForm.value["domain"]);
        data.append('gys', "0");
        data.append('userUpdate', this.sessionUserId);
        data.append('enabled', this.unparseCheckBox(this.enterpriseForm.value["status"]).toString());
        data.append('expirationDate', this.dateToString(this.enterpriseForm.value["expirationDate"]));
        for (var i = 0; i < this.listZonesDelete.length; i++) {
          data.append('zonesDelete', this.listZonesDelete[i].toString());
        }
        for (var i = 0; i < this.files.length; i++) {
          data.append('file' + i, this.files[i]);
        }
        for (var i = 0; i < this.listPinMapsDelete.length; i++) {
          data.append('pinMapsDelete', this.listPinMapsDelete[i].toString());
        }
        for (var i = 0; i < this.pinMapfiles.length; i++) {
          data.append('filePinMap' + i, this.pinMapfiles[i]);
        }
        this.updateEnterprise(data);

      } else if (this.data.operation == AppConstants.OperationType.ADD) {
        data.append('ruc', this.enterpriseForm.value["ruc"]);
        data.append('businessName', this.enterpriseForm.value["socialReason"].trim());
        data.append('legalRepresentative', this.enterpriseForm.value["legalRepresentative"].trim());
        data.append('contac', this.enterpriseForm.value["contact"].trim());
        data.append('representativeTelephone', this.enterpriseForm.value["phoneLegalRepresentative"]);
        data.append('contacTelephone', this.enterpriseForm.value["phoneContact"]);
        data.append('address', this.enterpriseForm.value["address"].trim());
        data.append('reference', this.enterpriseForm.value["reference"].trim());
        data.append('maximumUsers', this.enterpriseForm.value["maxUsers"]);
        data.append('domain', this.enterpriseForm.value["domain"].trim());
        data.append('gys', '0');
        data.append('userCreation', this.sessionUserId);
        data.append('enabled', this.unparseCheckBox(this.enterpriseForm.value["status"]).toString());
        data.append('expirationDate', this.dateToString(this.enterpriseForm.value["expirationDate"]));
        for (var i = 0; i < this.files.length; i++) {
            data.append('file' + i, this.files[i]);
        }
        for (var i = 0; i < this.pinMapfiles.length; i++) {
          data.append('filePinMap' + i, this.pinMapfiles[i]);
        }
        this.createEnterprise(data);
      }
    } else {
      this.enterpriseForm.markAllAsTouched();
    }
  }

  unparseCheckBox(id: boolean) {
    if (id) return 1; else return 0;
  }

  close() {
    this.data.result = false;
    this.dialogRef.close(this.data);
  }

  clearFields() {
    this.enterpriseForm.controls['ruc'].setValue('');
    this.enterpriseForm.controls['socialReason'].setValue('');
    this.enterpriseForm.controls['legalRepresentative'].setValue('');
    this.enterpriseForm.controls['phoneLegalRepresentative'].setValue('');
    this.enterpriseForm.controls['contact'].setValue('');
    this.enterpriseForm.controls['phoneContact'].setValue('');
    this.enterpriseForm.controls['address'].setValue('');
    this.enterpriseForm.controls['reference'].setValue('');
    this.enterpriseForm.controls['status'].setValue(true);
    this.enterpriseForm.controls['expirationDate'].setValue('');
    this.enterpriseForm.controls['domain'].setValue('');
    this.listZonesDelete = new Array<number>();
    this.listPinMapsDelete = new Array<number>();
  }

  createEnterprise(data: FormData) {
    this.spinner.show();
    this.enterpriseService.createEnterprise(data).subscribe((response => {
      if(!response.message){
        this.alertService.showMessage(AppConstants.TitleModal.CREATE_ENTERPRISE, AppConstants.MessageModal.CREATE, MessageSeverity.success);
        this.data.result = true;
        this.dialogRef.close(this.data);
        this.emitterService.sendMessage(AppConstants.EmitterService.ListEnterpriseFilter);
      }else{
        this.alertService.showMessage(AppConstants.TitleModal.CREATE_ENTERPRISE, response.message, MessageSeverity.error);
      }
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.CREATE_ENTERPRISE, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  onFileSelected(event) {
    var files = event.target.files;
    if (files.length > 0) {
      var file = files[0];
      if (file.type == "image/png" || file.type == "image/jpeg") {
        console.log(this.listZones);
        if (this.listZones.filter(f => f.zoneName == this.convertName(file.name)).length == 0) {
          var model = new EnterpriseZones();
          model.zoneName = this.convertName(file.name);
          model.file = file;

          this.listZones.push(model);
          this.files.push(file);
          this.dataSource.data = this.listZones;
        } else {
          this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_ZONE_DUPLICATE, MessageSeverity.warn);
        }
      } else {
        this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
      }
      event.srcElement.value = '';
    }
  }

  onFilePinMapSelected(event) {
    var files = event.target.files;
    if (files.length > 0) {
      var file = files[0];
      if (file.type == "image/png" || file.type == "image/jpeg") {
        console.log(this.listPinMaps);
        if (this.listPinMaps.filter(f => f.name == this.convertName(file.name)).length == 0) {
          var model = new EnterprisePinMaps();
          model.name = this.convertName(file.name);
          model.file = file;
          this.listPinMaps.push(model);
          this.pinMapfiles.push(file);
          this.dataSource2.data = this.listPinMaps;
        } else {
          this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_PINMAP_DUPLICATE, MessageSeverity.warn);
        }
      } else {
        this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
      }
      event.srcElement.value = '';
    }
  }

  convertName(name: string) {
    return name.split('.').slice(0, -1).join();
  }

  delete(obj) {
    let index = -1;
    let indexFile = -1;
    for (var i = 0; i < this.listZones.length; i++) {
      if (this.listZones[i]['zoneName'] == obj.zoneName) {
        index = i;
        break;
      }
    }

    if (obj.zoneId == undefined) {
      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i]['name'] == obj.file.name) {
          indexFile = i;
          break;
        }
      }
    }

    if (index != -1) {
      this.dataSource.data.splice(index, 1);
    }
    if (indexFile != -1) {
      this.files.splice(indexFile, 1);
    }

    if (obj.zoneId != undefined) {
      this.listZonesDelete.push(obj.zoneId);
    }

    this.dataSource = new MatTableDataSource(this.dataSource.data);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    });

  }

  deletePinMap(obj){
    let index = -1;
    let indexFile = -1;
    for (var i = 0; i < this.listPinMaps.length; i++) {
      if (this.listPinMaps[i]['name'] == obj.name) {
        index = i;
        break;
      }
    }

    if (obj.pinMapId == undefined) {
      for (var i = 0; i < this.pinMapfiles.length; i++) {
        if (this.files[i]['name'] == obj.file.name) {
          indexFile = i;
          break;
        }
      }
    }

    if (index != -1) {
      this.dataSource2.data.splice(index, 1);
    }
    if (indexFile != -1) {
      this.pinMapfiles.splice(indexFile, 1);
    }

    if (obj.pinMapId != undefined) {
      this.listPinMapsDelete.push(obj.pinMapId);
    }

    this.dataSource2 = new MatTableDataSource(this.dataSource2.data);
    setTimeout(() => {
      this.dataSource2.paginator = this.paginator2;
    });

  }

  dateToString(date: Date) {
    return this.datepipe.transform(date, 'yyyy-MM-dd');
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
