import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import 'fabric';
import { AlertService, MessageSeverity } from '../../../../shared/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CameraZoneUpdate, CameraZoneViewModel } from 'src/app/models/camera/camera';
import { MatDialog } from '@angular/material/dialog';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { CameraService } from 'src/app/services/camera/camera.service';
import { environment } from 'src/environments/environment';
import { LocationPinCameraModalComponent } from 'src/app/shared/components/modals/location-pin-camera/location-pin-camera-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { Router } from '@angular/router';
declare const fabric: any;

@Component({
    selector: 'app-location-camera',
    templateUrl: './location-camera.component.html',
    styleUrls: ['./location-camera.component.css']
})
export class LocationCameraComponent implements OnInit {

    loading: boolean = false;
    enterprises = [];
    enterpriseSelect: number = 0;
    enterprisesZones = [];
    enterpriseZoneSelect: number = 0;
    dataSource = new MatTableDataSource();
    displayedColumns: string[] = ['index', 'camera', 'pin', 'posicion', 'enabled','delete'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('paginatorTop', { static: false }) paginatorTop: MatPaginator;
    @ViewChild('paginatorBottom', { static: false }) paginatorBottom: MatPaginator;
    subscription: Subscription;
    listCameras = new Array<CameraZoneViewModel>();
    idPinMap: number;
    pathPinMap: string;
    directionPinMap: string;
    idStorageFile: number;
    image: any;
    file: File = null;
    canvas: any;
    genredCanva: boolean = true;
    nameMarker: string = '';
    cameraId: number = 0;
    sessionUserId: string;
    headquarterSelect: number = 0;
    headquarters=[];
    color1: string;
    color2: string;
    colorFont: string;
    colorFont2: string;
    enabledRead: number;
    enabledWrite: number;
    widthDefault = 720;
    heightDefault = 520;
    isPhone = false;
    isTablet = false;

    constructor(
        public dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private enterpriseService: EnterpriseService,
        private cameraService: CameraService,
        private router: Router,
        private alertService: AlertService,
        private headquarterService: HeadquarterService,
        private zoneService: ZoneService
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'Video Analítica > <span class="font-weight-bold">Ubicación Geográfica</span>';
      const userAgent: string = navigator.userAgent || navigator.vendor;
      const isMobileDevice = (): boolean => {
        const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
        return regexs.some((b) => userAgent.match(b))
      }
      const isTabletDevice = (): boolean => {
        const regexs = [/iPad/i, /iPod/i, /tablet/i, /tablet|ipad/i, /^((?!chrome|android).)*safari/i]
        return regexs.some((b) => userAgent.match(b))
      }
      this.isPhone = isMobileDevice();
      this.isTablet = isTabletDevice();
     }

    ngOnInit() {
      this.setLS();
      this.sessionUserId = localStorage.getItem(AppConstants.Session.USERID);
      this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
      this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
      this.getZonesByEnterprise();
      this.dataSource.paginator = this.paginator;
      this.paginator._intl.itemsPerPageLabel = '';
      this.getCanvas();
    }

    setLS(){
      this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
      this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
      this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
      this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
      //validate Permisses menu
      var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
      var page = this.router.url.split("/");
      var i = page[page.length-1];
      var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
      if(itemTag.length>0){
        this.enabledRead = itemTag[0].activeRead;
        this.enabledWrite = itemTag[0].activeWrite;
      }else{
        this.enabledRead = 0;
        this.enabledWrite = 0;
      }
    }

  getCanvas() {
    this.canvas = new fabric.Canvas('canvasLocation',{selection: false});
    if(this.isPhone){
      this.widthDefault = this.widthDefault / 2.5;
      this.heightDefault = this.heightDefault / 2.5;
    }
    this.canvas.setWidth(this.widthDefault);
    this.canvas.setHeight(this.heightDefault);
  }

  ngAfterContentChecked(): void {
    if (this.paginatorTop) {
      this.paginatorBottom.length = this.paginatorTop.length;
    }
}

changeHeadquarter(data) {
  this.headquarterSelect = data.value;
  this.nameMarker = ''
  this.clearDataTable();
  this.clearCanvas();
  this.enterpriseZoneSelect = 0;
  this.getZonesByEnterprise();
}

handlePaginatorTop(e): void {
    const { pageSize, pageIndex } = e;
    this.paginatorTop.pageSize = pageSize
    this.paginatorTop.pageIndex = pageIndex;
    this.paginatorTop.page.emit(e);
}

handlePaginatorBottom(e): void {
    const { pageSize, pageIndex } = e;
    this.paginatorBottom.pageSize = pageSize
    this.paginatorBottom.length = this.paginatorTop.length;
    this.paginatorBottom.pageIndex = pageIndex;
  }


  openAddModal() {
        const dialogRef = this.dialog.open(MessageModalComponent, {
            data: {
                title: AppConstants.TitleModal.CONFIRM_TITLE_RECORDER,
                message: AppConstants.MessageModal.REGISTER_MESSAGE_LOCATION_CONFIRM,
                hasButton: true,
                contentButton: {
                    yes: 'ACEPTAR',
                    no: 'CANCELAR'
                }
            },
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response.result == true) {
              this.saveUbicationMarkers();
            }
        });
    }

    getEnterprises() {
      this.spinner.show();
      this.enterpriseService.getEnterprises().subscribe((response) => {
        this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
        this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
        this.getHeadquarters();
       // this.getZonesByEnterprise(this.enterpriseSelect);
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    }

    getHeadquarters() {
        this.listCameras = [];
        this.enterprisesZones = [];
        this.spinner.show();
        this.headquarterService.getListAll().subscribe((response) => {
          if(response.error == null){
            this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
            if(this.headquarters.length>0){
              this.headquarterSelect = this.headquarters[0].id;
            }else{
              this.headquarterSelect = 0;
            }
            this.getZonesByEnterprise();
          }else{
            this.headquarters = [];
          }
          this.spinner.hide();
        },
          (error: HttpErrorResponse) => {
            this.headquarters = [];
            this.spinner.hide();
          });

    }

    changeEnterprise(data) {
      this.enterpriseSelect = data.value;
      this.clearDataTable();
      this.clearCanvas();
      this.getHeadquarters();
    }

    openModal(element){
      if(element.cameraPosX == 0){
        let dialogRef = this.dialog.open(LocationPinCameraModalComponent, {
          data: { idEnterprise : this.enterpriseSelect,idHeadquarter: this.headquarterSelect,
            enabledWrite: this.enabledWrite,
            enabledRead: this.enabledRead,
          color:this.color1,
        colorFont: this.color2 },
          width: '800px',
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result.data != null){
            this.idPinMap = result.data.pinMap;
            this.directionPinMap = result.data.direction;
            this.pathPinMap = result.data.path;
            this.nameMarker = element.camera;
            this.cameraId = element.cameraId;
            let index = this.listCameras.findIndex(item => item.cameraId == this.cameraId);
            if (index != -1) {
              var obj2 = this.listCameras[index];
                  obj2.idPinMap = this.idPinMap;
                  obj2.id_Zone = this.enterpriseZoneSelect;
                  obj2.oppositeMapDirection = this.directionPinMap.toString();
            }
           this.handleDrop();
          }
        });
      }else{
        this.alertService.showMessage(AppConstants.MessageModal.CAMERA_EXIST_ZONE, "", MessageSeverity.error);
      }
    }

  clearDataTable() {
    this.dataSource.data = [];
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    });
  }

  getZonesByEnterprise() {
    this.enterprisesZones = [];
    this.spinner.show();
    this.zoneService.getListAll(this.enterpriseSelect,this.headquarterSelect).subscribe(response => {
      if(response.error == null){
        var zones = response.data.filter(_ => _.type == 1);
        this.enterprisesZones = zones;
        if(this.enterprisesZones.length > 0){
            var data = this.enterprisesZones[0];
            this.enterpriseZoneSelect = data.id;
            var zonePath = data.image;
            if(data.idStorageTypeBlob == 1){ // Mode Local
              zonePath = data.storagePathBlob + zonePath;
            }
            this.changeImage(zonePath);
        } else {
          this.alertService.showMessage("No tiene zonas VA registradas, debes de crear una zona para usar este módulo", "", MessageSeverity.error);
          this.changeImage('./assets/images/sin_image.jpg');
        }
        this.getCameras();
      }else{
        this.enterprisesZones = [];
      }
      this.spinner.hide();
    });
  }

  changeZone(data) {
    this.nameMarker = ''
    this.clearDataTable();
    this.clearCanvas();
    this.enterpriseZoneSelect = data.value;
    var item = this.enterprisesZones.filter(f => f.id == data.value);
    console.log("zone change",item);
    if(item.length>0){
      var data = item[0];
      var zonePath = data.image;
      if(data.idStorageTypeBlob == 1){ // Mode Local
        zonePath = data.storagePathBlob + zonePath;
      }
      this.changeImage(zonePath);
    }else{
      this.changeImage('./assets/images/sin_image.jpg');
    }
    this.getCameras();
  }

  getCameras() {
    this.listCameras = new Array<CameraZoneViewModel>();
    if(this.enterpriseZoneSelect > 0){
      this.spinner.show();
      this.cameraService.getCameraByZone(this.enterpriseZoneSelect).subscribe((response => {
        this.listCameras = response;
        this.dataSource.data = this.listCameras;
        this.createMarkers();
        this.spinner.hide();
      }),
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    }

  }

  democlcik(data) {
    this.nameMarker = data.camera;
    this.cameraId = data.cameraId;
  }

  deleteMark(e){
    var data = this.canvas._objects.filter(f => f.cameraId == e.cameraId);
    var index = this.canvas._objects.findIndex(f => f.cameraId == e.cameraId);
    var obj = this.canvas.getObjects();
    this.listCameras.forEach(f => {
      if(f.cameraId == e.cameraId) {
        f.cameraPosX=0;
        f.cameraPosY=0;
        f.idPinMap=0;
        f.oppositeMapDirection='-';
      }
    });
    if (data.length > 0) {
      this.canvas.remove(obj[index]);
    }
  }
  handleDrop() {
    this.listenMovement();
    if (this.nameMarker != '') {

      let data = this.canvas._objects.filter(f => f.cameraId == this.cameraId);
      if (data.length > 0) {
        this.alertService.showMessage(AppConstants.TitleModal.ADD_ZONE_CAMERA, AppConstants.MessageModal.ADD_ZONE_CAMERA_MESSAGE_DUPLICATE, MessageSeverity.warn);
        this.nameMarker = '';
        return;
      } else {
        var _this = this;
        var text = new fabric.Text(_this.nameMarker, {textBackgroundColor:'#1f5165',fill: 'white', fontSize: 15, originX: 'center', originY: 'center'});
        if(this.idStorageFile == 1){ // Mode Local
           this.pathPinMap = environment.pathFile + this.pathPinMap;
        }

        new fabric.Image.fromURL(this.pathPinMap,
          function(img) {
            if(_this.isPhone){
              img.scaleToWidth(40 / 2.5);
              img.scaleToHeight(40 / 2.5);
            }else{
              img.scaleToWidth(40);
              img.scaleToHeight(40);
            }
            var img1 = img.set({
              originX: 'center',
              originY: 'bottom',
              top: -10,
              scaleX: _this.directionPinMap == '0' ? 0.1 : -0.1,
              scaleY: 0.1
            });
              var group = new fabric.Group([text, img1], {
              left: 50,
              top: 150,
              borderColor: 'transparent',
              cameraId: _this.cameraId,
              idPinMap: _this.idPinMap,
              hasControls: false,
              hasRotatingPoint: false
              });
              _this.canvas.add(group);
              _this.nameMarker = '';
          });
      }
    }
  }

  clearCanvas() {
    this.canvas.remove(...this.canvas.getObjects());
  }

  createMarkers() {
    for (var i = 0; i < this.listCameras.length; i++) {
      var obj = this.listCameras[i];
      console.log(obj);
      if (obj.cameraPosX != 0 && obj.cameraPosY != 0 && obj.id_Zone == this.enterpriseZoneSelect) {
        this.newGroup(obj);
      }
    }
  }

  newGroup(obj) {
    var _this = this;
    if(obj.idStorageType == 1){ // Mode Local
      obj.pathPinMap = environment.pathFile + obj.pathPinMap;
    }
    new fabric.Image.fromURL(obj.pathPinMap,
      function(img) {
        if(_this.isPhone){
          img.scaleToWidth(40 / 2.5);
          img.scaleToHeight(40 / 2.5);
        }else{
          img.scaleToWidth(40);
          img.scaleToHeight(40);
        }
        var img1 = img.set({
          originX: 'center',
          originY: 'bottom',
          angle: 0,
          top: -10,
          scaleX: obj.oppositeMapDirection == '0' ? 0.1 : -0.1,
          scaleY: 0.1
        });
        console.log("id Pin Map"+obj.idPinMap);
        var text = new fabric.Text(obj.camera, {textBackgroundColor:'#1f5165',fill: 'white', fontSize: 15, originX: 'center', originY: 'center'});
          var group = new fabric.Group([text, img1], {
          left: obj.cameraPosX,
          top: obj.cameraPosY,
          borderColor: 'transparent',
          hasControls: false,
          hasRotatingPoint: false,
          cameraId: obj.cameraId,
          idPinMap: obj.idPinMap
          });
          _this.canvas.add(group);
      });
  }


  saveUbicationMarkers() {
    this.spinner.show();
    var model = new CameraZoneUpdate();
    this.positionMarkers();
    model.cameras = this.listCameras;
    model.userUpdate = this.sessionUserId;
    this.cameraService.updateCameraZone(model).subscribe((response => {
      this.alertService.showMessage(AppConstants.TitleModal.UPDATE_CAMERA_ZONE, AppConstants.MessageModal.UPDATE_CAMERA_ZONE, MessageSeverity.success);
      this.clearCanvas();
      this.getCameras();
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_CAMERA_ZONE, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  positionMarkers() {
    let data = this.canvas._objects.filter(f => f.cameraId != undefined);
    data.forEach(f => {
      let index = this.listCameras.findIndex(item => item.cameraId == f.cameraId);
      if (index != -1) {
        var obj = this.listCameras[index];
            obj.cameraPosX = f.left;
            obj.cameraPosY = f.top;
      }
    });
  }

  changeImage(urlImage : string) {
      fabric.Image.fromURL(urlImage, (img) => {
        img.set({
          opacity: 0.5,
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height,
        });
        this.canvas.setBackgroundImage(img, this.canvas.requestRenderAll.bind(this.canvas));
      });
  }

  listenMovement(){
    var _this = this;
    this.canvas.on("object:moving", function(e) {
      var obj = e.target;
      console.log('PINMAP',obj);
      var canvas = obj.canvas;
      var top = obj.top;
      var left = obj.left;
      var pan_x = canvas.viewportTransform[4];
      var pan_y = canvas.viewportTransform[5];

      var c_width = canvas.width;
      var c_height = canvas.height;

      var w = obj.width * obj.scaleX

      var left_adjust, right_adjust
      if(obj.originX == "center") {
        left_adjust = right_adjust = w / 2;
      } else {
        left_adjust = 0;
        right_adjust = w;
      }

      var h = obj.height * obj.scaleY;
      var top_adjust, bottom_adjust;
      if(obj.originY == "center") {
        top_adjust = bottom_adjust = h / 2;
      } else {
        top_adjust = 0;
        bottom_adjust = h;
      }
      var top_margin = 0;
      var bottom_margin = 0;
      var left_margin = 0;
      var right_margin = 0;

      var top_bound = top_margin + top_adjust - pan_y;
      var bottom_bound = c_height - bottom_adjust - bottom_margin - pan_y;
      var left_bound = left_margin + left_adjust - pan_x;
      var right_bound = c_width - right_adjust - right_margin - pan_x;

      if( w > c_width ) {
        obj.left=(left_bound);
      } else {
        obj.left=(Math.min(Math.max(left, left_bound), right_bound));
      }

      if( h > c_height ) {
        obj.top=(top_bound);
      } else {
        obj.top=(Math.min(Math.max(top, top_bound), bottom_bound));
      }
    });
  }

}
