import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActuatorService } from 'src/app/services/actuator/actuator.service';
import { GatewayService } from 'src/app/services/gateway/gateway.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { SensorService } from 'src/app/services/sensor/sensor.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-actuator-modal',
  templateUrl: './actuator-modal.component.html',
  styleUrls: ['./actuator-modal.component.css']
})
export class ActuatorModalComponent implements OnInit {

  sensorForm: FormGroup;
  gatewaySelect: number;
  headquarterSelect: number;
  edit = false;
  editHeadquarter = false;
  gateways = [];
  headquarters = [];
  disabledHeadquarter = false;
  actuatorTypes = [];
  actuatorDataTypes = [];
  actuatorTypeSelect = 0;
  actuatorDataTypeSelect = 0;

  constructor(
    public dialogRef: MatDialogRef<ActuatorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private actuatorService: ActuatorService,
    public dialog: MatDialog,
    private headquarterService: HeadquarterService,
    private spinner: NgxSpinnerService,
    private gatewayService: GatewayService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.getActuatorTypes();
    this.getActuatorDataTypes();
    this.headquarters = this.data.headquarters;
    this.gateways = this.data.gateways;
    this.setHeadquarter();
    if(this.data.action == AppConstants.OperationType.ADD){
      this.editHeadquarter = false;
      this.edit = false;
      /*if(this.data.idGateway > 0){
        this.headquarterSelect = (this.data.gateways.filter(x => x.id == this.data.idGateway)[0]).idHeadquarter;
      }*/

      if(this.data.idHeadquarter){
        this.headquarterSelect = this.data.idHeadquarter;
      }
      if(this.data.idGateway){
        this.gatewaySelect = this.data.idGateway;
      }
    }

    if(this.data.action == AppConstants.OperationType.UPDATE){
      this.edit = true;
      this.editHeadquarter = true;
      this.headquarterSelect = this.data.obj.idHeadquarter;
      this.gatewaySelect = this.data.obj.idGateway;
      this.sensorForm.controls['name'].setValue(this.data.obj.name);
      this.sensorForm.controls['description'].setValue(this.data.obj.description);
      this.sensorForm.controls['nomenclature'].setValue(this.data.obj.nomenclature);
      this.sensorForm.controls['maxCapacity'].setValue(this.data.obj.maxCapacity);
      this.sensorForm.controls['minCapacity'].setValue(this.data.obj.minCapacity);
      this.sensorForm.controls['maxHeight'].setValue(this.data.obj.maxHeight);
      this.sensorForm.controls['density'].setValue(this.data.obj.density);
      this.sensorForm.controls['radio'].setValue(this.data.obj.radio);
    }
  }

  getActuatorTypes(){
        this.spinner.show();
        this.actuatorService.getListActuatorTypes().subscribe((response) => {
          this.actuatorTypes = response.data;
          if(this.data.action == AppConstants.OperationType.UPDATE){
            this.actuatorTypeSelect = this.data.obj.idActuatorType;
          }else{
            this.actuatorTypeSelect = this.actuatorTypes[0]["id"];
          }
          this.spinner.hide();
        },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
          });
  }

  getActuatorDataTypes(){
    this.spinner.show();
    this.actuatorService.getListActuatorDataTypes().subscribe((response) => {
      this.actuatorDataTypes = response.data;
      if(this.data.action == AppConstants.OperationType.UPDATE){
        this.actuatorDataTypeSelect = this.data.obj.idActuatorDataType;
      }else{
        this.actuatorDataTypeSelect = this.actuatorDataTypes[0]["id"];
      }

      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
}

  setHeadquarter(){
    if(this.data.idHeadquarter > 0){
      var headquarters = JSON.parse(localStorage.getItem(AppConstants.Session.HEADQUARTERS));
      var _this = this;
      var items = headquarters.filter(x => x.idEnterprise == _this.data.idEnterprise);
      if(items.length > 0){
        _this.headquarters = items;
        if(_this.data.idHeadquarter != 0){
          _this.headquarterSelect = items[0].id;
          _this.disabledHeadquarter = true;
        }
      }
    }else{
      this.getHeadquarters();
    }
  }

  
  getHeadquarters() {
    this.spinner.show();
    this.headquarterService.getListAll().subscribe((response) => {
      if(response.error == null){
        this.headquarters = response.data.filter(f => f.idEnterprise == this.data.idEnterprise);
        if(this.data.obj != null){
          this.headquarterSelect = this.data.obj.idHeadquarter;
        }else{
          this.headquarterSelect = this.headquarters[0].id;
        }
      }else{
        this.headquarters = [];
      }
    },
      (error: HttpErrorResponse) => {
        this.headquarters = [];
        this.spinner.hide();
      });
}

  createForm(){
    this.sensorForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(50)]],
      description: ['', []],
      nomenclature: ['', [Validators.required,  Validators.maxLength(30),  Validators.minLength(2)]],
      idHeadquarter: ['', []],
      idActuatorDataType: ['', [Validators.required]],
      idActuatorType: ['', [Validators.required]],
      idGateway: ['', [Validators.required]],
      maxCapacity: [0, [Validators.required]],
      minCapacity: [0, []],
      maxHeight: [0, [Validators.required]],
      density: [0, []],
      radio: [0, []],
    })
  }

  get f() { return this.sensorForm.controls; }

  save(){
    if(this.sensorForm.valid){
      const payload = {
        name:this.sensorForm.value["name"],
        nomenclature:this.sensorForm.value["nomenclature"],
        description:this.sensorForm.value["description"],
        id: this.data.action == AppConstants.OperationType.UPDATE ? this.data.obj.id : 0,
        idHeadquarter: this.headquarterSelect,
        idEnterprise: this.data.idEnterprise,
        idGateway: this.gatewaySelect,
        idActuatorDataType: this.actuatorDataTypeSelect,
        idActuatorType: this.actuatorTypeSelect,
        maxCapacity: this.sensorForm.value["maxCapacity"],
        minCapacity: this.sensorForm.value["minCapacity"],
        maxHeight: this.sensorForm.value["maxHeight"],
        density: this.sensorForm.value["density"],
        radio: this.sensorForm.value["radio"],
        idUser: localStorage.getItem(AppConstants.Session.USERID)
      }
      this.spinner.show();
      if(this.data.action == AppConstants.OperationType.ADD){
        this.actuatorService.create(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.CREATE_SENSOR, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
              this.spinner.hide();
          }
        });
      }else{
        this.actuatorService.update(payload).subscribe(_ => {
          if(!_.message){
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_SENSOR, AppConstants.MessageModal.UPDATE, MessageSeverity.success);
            this.spinner.hide();
            this.data.result = true;
            this.dialogRef.close(this.data);
          }else{
            this.alertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
            this.spinner.hide();
          }
        });
      }
    }else{
      this.sensorForm.markAllAsTouched();
    }
  }

  close() {
    this.dialog.closeAll();
  }

  changeGateway(data) {
    this.gatewaySelect = data.value;
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
    this.gateways = this.data.gateways.filter(x => x.idHeadquarter == data.value);
    if(this.gateways.length>0){
      this.gatewaySelect = this.gateways[0].id;
    }
  }

  changeActuatorDataType(data) {
    this.actuatorDataTypeSelect = data.value;
  }

  changeActuatorType(data) {
    this.actuatorTypeSelect = data.value;
  }

}

