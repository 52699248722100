<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
    </div>
  </div>
  <mat-dialog-content class="mat-typography mt-3">
<table class="table">
    <thead>
        <tr [style.background]="data.color" [style.color]="data.colorFont" >
            <th>Actuador</th>
            <th>Warrant</th>
            <th>Densidad</th>
            <th>Radio</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of actuators" > 
            <td>{{item.name}}</td>
            <td><input type="number" step="0.01" class="form-control"  value="{{item.minCapacity}}" (keyup)="changeInputGuard($event,item.id)"  ></td>
            <td><input type="number" step="0.01" class="form-control"  value="{{item.density}}" (keyup)="changeInputDensity($event,item.id)" ></td>
            <td><input type="number" step="0.01" class="form-control"  value="{{item.radio}}" (keyup)="changeInputRadio($event,item.id)" ></td>
        </tr>
    </tbody>
</table>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite" (click)="save()">GUARDAR</button>
</mat-dialog-actions>