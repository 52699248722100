<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{title}}
    </div>
    <div class="col-2 text-right">
        <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="settingForm" autocomplete="off"  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="row" >
              <div class="col-12 col-lg-12" style="display: grid;justify-content: center;">
                <img [src]="logo" width="200px" height="180px" >
                <button type="button" mat-raised-button (click)="fileInput.click()">Seleccionar Logo</button>
                <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">
              </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Color Fondo*</mat-label>
                        <input matInput type="color" formControlName="color1" maxlength="100">
                        <mat-error *ngIf="f.color1.hasError('required')">
                            El Color 1 es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Color Texto*</mat-label>
                        <input matInput type="color" formControlName="color2" maxlength="100">
                        <mat-error *ngIf="f.color2.hasError('required')">
                            El Color 2 es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCELAR</button>
    <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite"  (click)="openConfirmationModal()">GUARDAR</button>
</mat-dialog-actions>
