<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
  <div class="col-10">
    Añadir Pin en la zona
  </div>
  <div class="col-2 text-right">
    <mat-icon [style.color]="data.color"  [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
    <form [formGroup]="locationForm" action="">
      <div class="row">
         <!-- <div class="col-12">
              <mat-form-field class="w-100">
                  <mat-label>Pin</mat-label>
                  <mat-select formControlName="idPinMap">
                    <mat-option *ngFor="let z of listPinMaps" [value]="z.id">
                      {{z.name}}
                    </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>-->
          <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label>Dirección</mat-label>
                <mat-select formControlName="oppositeMapDirection">
                  <mat-option [value]="0">Predeterminado</mat-option>
                  <mat-option [value]="1">Opuesta</mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <h4><b>Seleccione el icono de preferencia:</b></h4>
            <div *ngIf="listPinMaps.length > 0">
              <h4>Mis Pines</h4>
              <div class="row">
                <div class="col-3" *ngFor="let item of listPinMaps">
                  <img src="{{item.path}}" class="item-pin" (click)="selectPinList(item)" >
                </div>
              </div>
            </div>
            <hr  *ngIf="listPinMaps.length > 0">
            <div *ngIf="listDefaultPinMaps.length > 0">
              <div class="row">
                <div class="col-3" *ngFor="let item of listDefaultPinMaps">
                  <img src="{{item.path}}" class="item-pin" (click)="selectPinList(item)" >
                </div>
              </div>
            </div>
          </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCELAR</button>
  <button mat-raised-button [style.color]="data.colorFont" [style.background]="data.color"  (click)="openConfirmationModal()">AGREGAR</button>
</mat-dialog-actions>
