import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CameraComponent } from './components/home/administration/camera/camera.component';
import { EnterpriseComponent } from './components/home/administration/enterprise/enterprise.component';
import { HeatmapComponent } from './components/home/video-analytics/heatmap/heatmap.component';
import { LocationCameraComponent } from './components/home/video-analytics/location/location-camera.component';
import { SmsComponent } from './components/home/administration/sms/sms.component';
import { UserComponent } from './components/home/administration/user/user.component';
import { ShowroomComponent } from './components/home/master/showroom/showroom.component';
import { HomeComponent } from './components/home/home.component';
import { ReportComponent } from './components/home/video-analytics/report/report.component';
import { CameraStreamingComponent } from './components/home/streaming/camera/camera-streaming.component';
import { StreamingDomainIframeComponent } from './components/home/streaming/domain-iframe/domain-iframe-streaming.component';
import { ServerStreamingComponent } from './components/home/streaming/server/server-streaming.component';
import { StreamingStadisticsComponent } from './components/home/streaming/stadistics/streaming-stadistics.component';
import { LicenseComponent } from './components/license/license.component';
import { LoginComponent } from './components/login/login.component';
import { RedirectLogoutComponent } from './components/redirect/redirect-logout.component';
import { IframeStreamingComponent } from './components/streaming/iframe-streaming/iframe-streaming.component';
import { MultiplestreamingComponent } from './components/streaming/multiple-streaming/multiple-streaming.component';
import { ViewStreamingComponent } from './components/streaming/view-streaming/view-streaming.component';
import { MaterialModule } from './shared/modules/material.module';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { MainNavComponent } from './shared/components/main-nav/main-nav.component';
import { CameraModalComponent } from './shared/components/modals/camera/camera-modal.component';
import { EnterpriseContractModalComponent } from './shared/components/modals/enterprise/enterprise-contract-modal/enterprise-contract-modal.component';
import { ItemdomainIframeStreamingModalComponent } from './shared/components/modals/domain-iframe-streaming/domain-iframe-streaming-modal.component';
import { EnterpriseModalComponent } from './shared/components/modals/enterprise/enterprise-modal.component';
import { EnterpriseSettingModalComponent } from './shared/components/modals/enterprise/enterprise-setting-modal/enterprise-setting-modal.component';
import { EnterpriseStreamingModalComponent } from './shared/components/modals/enterprise/enterprise-streaming-modal/enterprise-streaming-modal.component';
import { ImageModalComponent } from './shared/components/modals/image/image-modal.component';
import { MessageModalComponent } from './shared/components/modals/message/message-modal.component';
import { PanelCameraAlertModalComponent } from './shared/components/modals/panel/camera/alert/panel-camera-alert-modal.component';
import { PanelCameraModalComponent } from './shared/components/modals/panel/camera/panel-camera-modal.component';
import { ModalParameterComponent } from './shared/components/modals/parameter/parameter-modal.component';
import { ServerStreamingModalComponent } from './shared/components/modals/server-streaming/server-streaming-modal.component';
import { SMSModalComponent } from './shared/components/modals/sms/sms-modal.component';
import { UserModalComponent } from './shared/components/modals/user/user-modal.component';
import { ItemCameraStreamingComponent } from './shared/components/streaming/item-camera-streaming/item-camera-streaming.component';
import { StreampublicComponent } from './shared/components/streaming/public-streaming/streampublic.component';

import { SharedModule } from './shared/modules/shared.module';
import { AuthGuard } from './shared/guards/auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthInterceptor } from './shared/helpers/jwt.interceptor';
import { MsalBroadcastService, MsalGuard, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from './shared/security/msal-config';
import { ShowroomPanelComponent } from './components/home/master/showroom/showroom-panel/showroom-panel.component';
import { ShowroomIotComponent } from './components/home/master/showroom/showroom-iot/showroom-iot.component';
import { ShowroomVaComponent } from './components/home/master/showroom/showroom-va/showroom-va.component';
import { LocationPinCameraModalComponent } from './shared/components/modals/location-pin-camera/location-pin-camera-modal.component';
import { CameraAlertComponent } from './components/home/video-analytics/camera-alert/camera-alert.component';
//import { ChartsModule } from '@carbon/charts-angular';
import { ChartGaugeComponent } from './shared/components/charts/chart-gauge/chart-gauge.component';
import { ClockComponent } from './shared/components/clock/clock.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ChartSwitchComponent } from './shared/components/charts/chart-switch/chart-switch.component';
import { ModalScreenshotComponent } from './shared/components/modals/showroom/modal-screenshot/modal-screenshot.component';
import { NgxCaptureModule } from 'ngx-capture';
import { ChartLineComponent } from './shared/components/charts/chart-line/chart-line.component';
import { ModalIotFilterComponent } from './shared/components/modals/showroom/modal-iot-filter/modal-iot-filter.component';
import { ChartLineStreamingComponent } from './shared/components/charts/chart-line-streaming/chart-line-streaming.component';
import { ChartDigitalNumberComponent } from './shared/components/charts/chart-digital-number/chart-digital-number.component';
import { ChartSwitchVerticalComponent } from './shared/components/charts/chart-switch-vertical/chart-switch-vertical.component';
import { ShowroomVAStreamingComponent } from './components/home/master/showroom/showroom-va-streaming/showroom-va-streaming.component';
import { ShowroomCameraStreamingComponent } from './components/home/master/showroom/showroom-camera-streaming/showroom-camera-streaming.component';
import { ShowroomPanelDiagramComponent } from './components/home/master/showroom/showroom-panel-diagram/showroom-panel-diagram.component';
import { ShowroomActuatorPanelComponent } from './components/home/master/showroom/showroom-panel-diagram/showroom-actuator-panel/showroom-actuator-panel.component';
import { ShowroomPanelDiagramPublicComponent } from './components/home/master/showroom/showroom-panel-diagram-public/showroom-panel-diagram-public.component' ;
import { ShowRoomService } from './services/showroom/showroom.service';
import { HeadquarterComponent } from './components/home/administration/headquarter/headquarter.component';
import { ActuatorComponent } from './components/home/administration/actuator/actuator.component';
import { SensorComponent } from './components/home/administration/sensor/sensor.component';
import { GatewayComponent } from './components/home/administration/gateway/gateway.component';
import { LocationIotComponent } from './components/home/iot/location-iot/location-iot.component';
import { HeadquarterModalComponent } from './shared/components/modals/headquarter/headquarter-modal.component';
import { ZoneModalComponent } from './shared/components/modals/zone/zone-modal.component';
import { GatewayModalComponent } from './shared/components/modals/gateway/gateway-modal.component';
import { SensorModalComponent } from './shared/components/modals/sensor/sensor-modal.component';
import { ZoneComponent } from './components/home/administration/zone/zone.component';
import { EnterprisePinListModalComponent } from './shared/components/modals/zone/enterprise-pin-list-modal/enterprise-pin-list-modal.component';
import { PinModalComponent } from './shared/components/modals/zone/enterprise-pin-modal/pin-modal.component';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { HeadquarterStorageModalComponent } from './shared/components/modals/headquarter/headquarter-storage-modal/headquarter-storage-modal.component';
import { PanelSensorModalComponent } from './shared/components/modals/panel-sensor-modal/panel-sensor-modal.component';
import { PanelIotComponent } from './components/home/iot/panel-iot/panel-iot.component';
import { SensorGraphModalComponent } from './shared/components/modals/sensor-graph-modal/sensor-graph-modal.component';
import { ShowroomEmailComponent } from './components/home/master/showroom/showroom-email/showroom-email.component';
import { ModalEmailShowRoomComponent } from './shared/components/modals/showroom/modal-email-show-room/modal-email-show-room.component';
import { ModalReportVideoAnaliticaShowRoomComponent } from './shared/components/modals/showroom/modal-report-video-analitica-show-room/modal-report-video-analitica-show-room.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ReportIOTComponent } from './components/home/iot/report/iot-report.component';
import { EnterpriseSystemModalComponent } from './shared/components/modals/enterprise/enterprise-system-modal/enterprise-system-modal.component';
import { ModalReportSensorPbiShowRoomComponent } from './shared/components/modals/showroom/modal-report-sensor-pbi/modal-report-sensor-pbi-show-room.component';
import { EnterpriseProfileComponent } from './components/home/profile/enterprise-profile/enterprise-profile.component';
import { UserProfileComponent } from './components/home/profile/user-profile/user-profile.component';
import { SensorReadingplaceComponent } from './components/home/administration/sensor-readingplace/sensor-readingplace.component';
import { SensorReadingplaceModalComponent } from './shared/components/modals/sensor-readingplace-modal/sensor-readingplace-modal.component';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { EnterpriseStorageModalComponent } from './shared/components/modals/enterprise/enterprise-storage-modal/enterprise-storage-modal.component';
import { ErrorAuthenticateInterceptor } from './shared/helpers/error-authenticate.interceptor';
import { UserAssingModalComponent } from './shared/components/modals/user/user-assing-modal/user-assing-modal.component';
import { UserNewAssingModalComponent } from './shared/components/modals/user/user-new-assing-modal/user-new-assing-modal.component';
import { RoleComponent } from './components/home/administration/role/role.component';
import { RoleModalComponent } from './shared/components/modals/role/role-modal.component';
import { ReportAlertIOTComponent } from './components/home/iot/report-alert/alert-iot-report.component';
import { SensorTypeComponent } from './components/home/administration/sensor-type/sensor-type.component';
import { SensorTypeModalComponent } from './shared/components/modals/sensor-type-modal/sensor-type-modal.component';
import { GraphAssignModalComponent } from './shared/components/modals/sensor-type-modal/graph-assign-modal/graph-assign-modal.component';
import { GraphNewAssignModalComponent } from './shared/components/modals/sensor-type-modal/graph-new-assign-modal/graph-new-assign-modal.component';
import { DashboardModalComponent } from './shared/components/modals/dashboard/dashboard-modal.component';
import { WidgetModalComponent } from './shared/components/modals/dashboard/widget-modal/widget-modal.component';
import { ChartBarComponent } from './shared/components/charts/chart-bar/chart-bar.component';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { ActuatorModalComponent } from './shared/components/modals/actuator/actuator-modal.component';
import { PanelActuatorModalComponent } from './shared/components/modals/panel-actuator-modal/panel-actuator-modal.component';
import { MapService, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { PanelGPSComponent } from './components/home/iot/panel-gps/panel-gps.component';
import { AgmCoreModule } from '@agm/core';
import { ActuatorDetailPanelModalComponent } from './shared/components/modals/actuator-detail-panel-modal/actuator-detail-panel-modal.component';
import { ReportActivityActuatorComponent } from './components/home/iot/report-activity-actuator/report-activity-actuator.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DigitalTwinsComponent } from './components/home/iot/digital-twins/digital-twins.component';
import { PowerbiModalComponent } from './shared/components/modals/powerbi-modal/powerbi-modal.component';
import { ReportBiIOTComponent } from './components/home/iot/report-bi-iot/report-bi-iot.component';
import { ReportBiVAComponent } from './components/home/video-analytics/report-bi-va/report-bi-va.component';


@NgModule({
  declarations: [
    AppComponent,
    RedirectLogoutComponent,
    LayoutComponent,
    HomeComponent,
    MainNavComponent,
    CameraComponent,
    UserComponent,
    EnterpriseComponent,
    ReportComponent,
    CameraAlertComponent,
    LocationCameraComponent,
    SmsComponent,
    StreampublicComponent,

    UserModalComponent,
    CameraModalComponent,
    ModalParameterComponent,
    MessageModalComponent,
    EnterpriseModalComponent,
    EnterpriseContractModalComponent,

    PanelCameraModalComponent,
    PanelCameraAlertModalComponent,
    ItemdomainIframeStreamingModalComponent,
    ImageModalComponent,
    EnterpriseSettingModalComponent,
    ServerStreamingModalComponent,
    EnterpriseStreamingModalComponent,
    SMSModalComponent,
    HeatmapComponent,
    LicenseComponent,
    LoginComponent,
    ViewStreamingComponent,
    MultiplestreamingComponent,
    IframeStreamingComponent,
    StreamingDomainIframeComponent,
    CameraStreamingComponent,
    ItemCameraStreamingComponent,
    ServerStreamingComponent,
    StreamingStadisticsComponent,
    ShowroomComponent,
    ShowroomPanelComponent,
    ShowroomIotComponent,
    ShowroomVAStreamingComponent,
    ShowroomVaComponent,
    ZoneModalComponent,
    ChartGaugeComponent,
    ClockComponent,
    ChartSwitchComponent,
    ModalScreenshotComponent,
    ChartLineComponent,
    ModalIotFilterComponent,
    ChartLineStreamingComponent,
    ChartDigitalNumberComponent,
    ChartSwitchVerticalComponent,
    ShowroomCameraStreamingComponent,
    ShowroomPanelDiagramComponent,
    ShowroomActuatorPanelComponent,
    ShowroomPanelDiagramPublicComponent,
    HeadquarterComponent,
    ActuatorComponent,
    SensorComponent,
    GatewayComponent,
    LocationIotComponent,
    HeadquarterModalComponent,
    LocationPinCameraModalComponent,
    ZoneComponent,
    GatewayModalComponent,
    SensorModalComponent,
    EnterprisePinListModalComponent,
    PinModalComponent,
    DashboardComponent,
    HeadquarterStorageModalComponent,
    PanelSensorModalComponent,
    PanelIotComponent,
    SensorGraphModalComponent,
    ShowroomEmailComponent,
    ModalEmailShowRoomComponent,
    ModalReportVideoAnaliticaShowRoomComponent,
    ReportIOTComponent,
    EnterpriseSystemModalComponent,
    ModalReportSensorPbiShowRoomComponent,
    UserProfileComponent,
    EnterpriseProfileComponent,
    SensorReadingplaceComponent,
    SensorReadingplaceModalComponent,
    EnterpriseStorageModalComponent,
    UserAssingModalComponent,
    UserNewAssingModalComponent,
    RoleComponent,
    RoleModalComponent,
    ReportAlertIOTComponent,
    SensorTypeComponent,
    SensorTypeModalComponent,
    GraphAssignModalComponent,
    GraphNewAssignModalComponent,
    DashboardModalComponent,
    WidgetModalComponent,
    ChartBarComponent,
    ActuatorModalComponent,
    ActuatorComponent,
    PanelActuatorModalComponent,
    PanelGPSComponent,
    ActuatorDetailPanelModalComponent,
    ReportActivityActuatorComponent,
    DigitalTwinsComponent,
    ReportBiIOTComponent,
    PowerbiModalComponent,
    ReportBiVAComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    NgxSliderModule,
    ToastrModule.forRoot(),
    SharedModule,
    CommonModule,
    LayoutModule,
    //MsalModule,
    //ChartsModule,
    NgxCaptureModule,
    NgMultiSelectDropDownModule.forRoot(),
    IonRangeSliderModule,
    AmChartsModule,
    NgxMapboxGLModule,/*.withConfig({
      accessToken: 'pk.eyJ1Ijoic2ViYXN0aWFuOTZqcCIsImEiOiJjbGl4bjhpaGEwODB5M2xsMndvOWhxeXZqIn0.Yt4_ynMIZUiiJF4TS3exVA', // Optionnal, can also be set per map (accessToken input of mgl-map)
      // geocoderAccessToken: 'TOKEN' // Optionnal, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
    }),*/
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB70sStdEOl_sHU-GiUwtWg-WP99eLf7GE', // API token vacío
      libraries: ['places','directions'],
    }),
    ColorPickerModule
  ],
  providers: [
    DatePipe,
    //HttpService,
    AuthGuard,
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, // token bearer
    { provide: HTTP_INTERCEPTORS, useClass: ErrorAuthenticateInterceptor, multi: true }, // authenticate 403

    /*{ ALL Azure
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,*/
    ShowRoomService,
    MapService
  ],
  entryComponents: [
    UserModalComponent,
    CameraModalComponent,
    ModalParameterComponent,
    MessageModalComponent,
    EnterpriseModalComponent,
    EnterpriseContractModalComponent,
    PanelCameraModalComponent,
    PanelCameraAlertModalComponent,
    SMSModalComponent,
    ItemdomainIframeStreamingModalComponent,
    ImageModalComponent,
    EnterpriseSettingModalComponent,
    EnterpriseStreamingModalComponent,
    ServerStreamingModalComponent,
    LocationPinCameraModalComponent,
    ZoneModalComponent,
    ModalScreenshotComponent,
    ShowroomPanelComponent,
    ModalIotFilterComponent,
    HeadquarterModalComponent,
    GatewayModalComponent,
    SensorModalComponent,
    EnterprisePinListModalComponent,
    PinModalComponent,
    HeadquarterStorageModalComponent,
    PanelSensorModalComponent,
    ModalEmailShowRoomComponent,
    ModalReportVideoAnaliticaShowRoomComponent,
    EnterpriseSystemModalComponent,
    ModalReportSensorPbiShowRoomComponent,
    SensorReadingplaceModalComponent,
    EnterpriseStorageModalComponent,
    UserAssingModalComponent,
    UserNewAssingModalComponent,
    RoleModalComponent,
    SensorTypeModalComponent,
    GraphAssignModalComponent,
    GraphNewAssignModalComponent,
    DashboardModalComponent,
    WidgetModalComponent,
    ActuatorModalComponent,
    PanelActuatorModalComponent,
    ActuatorDetailPanelModalComponent,
    PowerbiModalComponent
  ],
  exports: [
    LayoutComponent,
    ToastrModule,
    IonRangeSliderModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]//, MsalRedirectComponent
})
export class AppModule { }
