import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //const skipIntercept = req.headers.has('skip_auth');
    //if (!skipIntercept){
      req = req.clone({
        setHeaders: {
            Authorization: `Bearer `+localStorage.getItem('_token_VD_GYS')
        }
      });
    //}
    return next.handle(req);
  }
}
