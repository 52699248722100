import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { StreamingDomainIframeService } from 'src/app/services/streamingDomainIframe/streamingDomainIframe.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { MessageSeverity, AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-domain-iframe-streaming-modal',
  templateUrl: './domain-iframe-streaming-modal.component.html',
  styleUrls: ['./domain-iframe-streaming-modal.component.css']
})
export class ItemdomainIframeStreamingModalComponent implements OnInit {
  securityForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private AlertService: AlertService,
    private streamingDomainIframeService: StreamingDomainIframeService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    if(this.data.action == 1){
      this.securityForm.controls['domain'].setValue(this.data.obj.domain);
    }
  }

  createForm(){
    this.securityForm = this.formBuilder.group({
      domain: ['', [Validators.required,  Validators.maxLength(500), this.noWhitespaceValidator]]
    })
  }

  get f() { return this.securityForm.controls; }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  save(){
    if(this.securityForm.valid){
      const security = {
        domain:this.securityForm.value["domain"],
        idEnterprise: this.data.idEnterprise,
        id: this.data.action == 1 ? this.data.obj.id : 0
      }
      if(this.data.action == 0){
        this.streamingDomainIframeService.create(security).subscribe(_ => {
          if(_.message == null || _.message == ""){
            this.AlertService.showMessage(AppConstants.TitleModal.CREATE_SECURITY, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.AlertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
      }else{
        this.streamingDomainIframeService.update(security).subscribe(_ => {
          if(_.message == null || _.message == ""){
            this.AlertService.showMessage(AppConstants.TitleModal.UPDATE_SECURITY, AppConstants.MessageModal.UPDATE_SECURITY, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.AlertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
      }
    }else{
      this.securityForm.markAllAsTouched();
    }
  }

  close() {
    this.dialog.closeAll();
  }

}
