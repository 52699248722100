<!-- <h4 *ngIf="showCamera">{{streaming}}</h4>
<div class="slider-image" *ngIf="streaming == ''" >
  <img class="img" src="{{imagePreview}}" alt="img-camera" *ngIf="!showLive" />
  <div class="play-body" (click)="showStreaming()"  >
    <div class="play-image" *ngIf="!showLive" >
      <img class="img-play" src="../../../../assets/images/play.png" alt="img-play" />
    </div>
    <div *ngIf="showLive">
      <app-item-camera-streaming id="stream_0" [id]="id"  [url]="streamingUrl" [urlFile]="streamingFile" [streamingKey]="streamingKey" [child]=false ></app-item-camera-streaming>
    </div>
  </div>
</div>
-->
<h4 *ngIf="showCamera">{{streaming}}</h4>
<div *ngIf="!showCamera">
  <app-item-camera-streaming id="stream_0" [id]="id"  [url]="streamingUrl" [child]=false ></app-item-camera-streaming>
</div>
