
<div class="row title-modal">
    <div class="col-12 text-right">
        <mat-icon color="primary" [mat-dialog-close]="true">close</mat-icon>
    </div>
  </div>
  <mat-dialog-content class="mat-typography mt-3">
    <div class="container text-center">
      <div class="row">
        <div class="col-12 col-md-12 divCanvasScreenshot" ></div>
      </div>
    </div>
  </mat-dialog-content>
  