<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="locationForm"  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' ">
            <div class="row">
                <div class="col-12" *ngIf="edit" >
                    <mat-form-field class="w-100">
                        <mat-label>Sede*</mat-label>
                        <mat-select
                            formControlName="idHeadquarter"
                            (selectionChange)="changeHeadquarter($event)"
                            [(ngModel)]="headquarterSelect">
                            <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                                {{headquarter.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="showError && f.idHeadquarter.hasError('required')">
                          La sede es <strong>requerida</strong>.
                      </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Tipo*</mat-label>
                        <mat-select
                            formControlName="idZoneType"
                            (selectionChange)="changeType($event)"
                            [(ngModel)]="typeSelect"
                            >
                            <mat-option *ngFor="let type of types" [value]="type.id">
                                {{type.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="showError && f.idZoneType.hasError('required')">
                          El tipo es <strong>requerido</strong>.
                      </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Nombre*</mat-label>
                        <input matInput formControlName="name" maxlength="50">
                        <mat-error *ngIf="showError && f.name.hasError('required')">
                            El Nombre es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Descripción</mat-label>
                        <input matInput formControlName="description" maxlength="50">
                        <mat-error *ngIf="showError && f.description.hasError('required')">
                            La descripción es <strong>requerida</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Nomenclatura*</mat-label>
                        <input matInput formControlName="nomenclature" >
                        <mat-error *ngIf="showError && f.nomenclature.hasError('required')">
                            La nomenclatura <strong>requerida</strong>.
                        </mat-error>
                        <mat-error *ngIf="showError && f.nomenclature.errors?.maxlength">
                          La nomenclatura no puede ser mayor a 30 caracteres.
                        </mat-error>
                        <mat-error *ngIf="f.nomenclature.errors?.minlength">
                          La nomenclatura no puede ser menor a 2 caracteres.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 text-center mb-2 mt-2">
                    <button type="button" [style.background]="data.color" [style.color]="data.colorFont"  mat-raised-button (click)="fileInput.click()">{{imagenZone}}</button>
                    <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">

                    <!--<label class="col-12" style="padding: 0px;margin-bottom: 5px;">Subir nueva imagen</label>
                    <input (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">-->
                </div>
                <div *ngIf="showNewPin">
                  <hr>
                  <h4 style="margin: 0;
                  margin-left: 12px;
                  margin-top: 10px;"><b>Nuevo Pin</b></h4>
                  <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Nombre de Pin*</mat-label>
                        <input matInput formControlName="namePin" >
                    </mat-form-field>
                  </div>
                  <div class="col-12 text-center mb-2 mt-2">
                    <button type="button" [style.background]="data.color" [style.color]="data.colorFont"   mat-raised-button (click)="fileInputPin.click()">{{imagenPin}}</button>
                    <input hidden (change)="onFileSelectedPin($event)" #fileInputPin type="file" id="file" accept="image/jpeg, image/jpg, image/png">
                    <!--
                      <label class="col-12" style="padding: 0px;margin-bottom: 5px;">Subir nueva imagen pin</label>
                      <input (change)="onFileSelectedPin($event)" #fileInputPin type="file" id="filePin" accept="image/jpeg, image/jpg, image/png">
                     -->
                  </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCELAR</button>
  <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont"  *ngIf="data.enabledWrite" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
