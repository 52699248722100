<h4 mat-dialog-title> 
    <mat-toolbar role="toolbar" class="task-header">
        <span> {{ data.title }} </span>
        <span class="fx-spacer"></span>
        <button mat-icon-button mat-dialog-close>
            <mat-icon mat-list-icon>close</mat-icon>
        </button>
    </mat-toolbar>
</h4>
<mat-dialog-content class="my-4 text-center">
    {{ data.message }}
</mat-dialog-content>
<mat-dialog-actions *ngIf="data.hasButton" align="end">
    <button mat-button (click)="close()">{{ data.contentButton.no }}</button>
    <button mat-raised-button color="primary" (click)="onClickAccept()">{{ data.contentButton.yes }}</button>
</mat-dialog-actions>
