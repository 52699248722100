<div class="row title-modal" [style.color]="data.color" [style.borderColor]="data.color">
  <div class="col-10">
      {{ data.title }}
  </div>
  <div class="col-2 text-right">
    <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
      <form [formGroup]="userForm" >
          <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Empresa*</mat-label>
                  <mat-select
                          formControlName="idEnterprise"
                          (selectionChange)="changeEnterprise($event)"
                          [(ngModel)]="enterpriseSelect">
                      <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                          {{enterprise.businessName}}
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.idEnterprise.hasError('required')">
                    La empresa es <strong>requerida</strong>.
                </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Sede*</mat-label>
                    <mat-select
                            formControlName="idHeadquarter"
                            (selectionChange)="changeHeadquarter($event)"
                            [(ngModel)]="headquarterSelect"
                    >
                        <mat-option [value]="0" *ngIf="headquarters.length>1" >TODAS</mat-option>
                        <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                            {{headquarter.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.idHeadquarter.hasError('required')">
                      La sede es <strong>requerida</strong>.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>Rol*</mat-label>
                  <mat-select
                          formControlName="idRole"
                          [(ngModel)]="roleSelect"
                  >
                      <mat-option *ngFor="let role of roles" [value]="role.id">
                          {{role.name}}
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.idRole.hasError('required')">
                    El rol es <strong>requerido</strong>.
                  </mat-error>
              </mat-form-field>
              </div>
          </div>
      </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>CANCELAR</button>
<button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
