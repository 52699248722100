import { BaseModel } from "../../shared/models/base-model";

export class UserViewModel {
  userId: number;
  enterpriseId: number;
  rolId: number;
  username: string;
  lasName: string;
  names: string;
  fullName: string;
  password: string;
  locked: number;
  lockedDate: Date;
  retries: number;
  enabled: number;
  rol: string;
  sendEmail: number;
  userCreation: string;
  userCreationDate: Date;
  userUpdate: string;
  userUpdateDate: Date;  
  idHeadquarter:number;
}

export class User {
  enterpriseId: number;
  rolId: number;
  dni: string;
  lasName: string;
  names: string;
  password: string;
  confirmNewPassword: string;
  enabled: number;
  sendEmail: number;
  email: string;
  userCreation: string;
  idHeadquarter:number;
}

export class UserPut {
  userId: number;
  enterpriseId: number;
  rolId: number;
  dni: string;
  lasName: string;
  names: string;
  password: string;
  confirmNewPassword: string;
  enabled: number;
  locked: number;
  sendEmail: number;
  email: string;
  userUpdate: string;
  idHeadquarter:number;
}

export class LoginModel extends BaseModel {
  client_id: string;
  grant_type: string;
  client_secret: string;
  enterprise: string;
  dni: string;
  KeyboardId: number;
  HashCodes1: string;
  HashCodes2: string;
  HashCodes3: string;
  HashCodes4: string;
  HashCodes5: string;
  HashCodes6: string;
}

export class TokenModel {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
}
