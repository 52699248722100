import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnterprisePinMaps } from 'src/app/models/enterprise/enterprisePinMaps';
import { PinModel } from 'src/app/models/zone/pin';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-location-pin-camera-modal',
  templateUrl: './location-pin-camera-modal.component.html',
  styleUrls: ['./location-pin-camera-modal.component.css']
})
export class LocationPinCameraModalComponent implements OnInit {

  locationForm: FormGroup;
  listPinMaps = Array<PinModel>();
  selectPinId:number;
  selectPinPath:number;
  listDefaultPinMaps = Array<PinModel>();
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LocationPinCameraModalComponent>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    private zoneService: ZoneService) { }

  ngOnInit() {
    this.createForm();
    this.clearFields();
    this.getListPin();
    this.getListPinDefault();
  }

  createForm() {
      this.locationForm = this.formBuilder.group({
       //   idPinMap: ['', Validators.required],
          oppositeMapDirection: [0, Validators.required],
      })
  }

  selectPinList(item){
    this.selectPinId = item.id;
    this.selectPinPath = item.path;
  }

  close() {
    this.dialogRef.close({
      event: 'close',
      data: null
    });
  }

  clearFields() {
    //this.locationForm.controls['idPinMap'].setValue('');
    this.locationForm.controls['oppositeMapDirection'].setValue(0);
  }

  getListPin() {

      var filter = {
        idEnterprise: this.data.idEnterprise,
        idHeadquarter: this.data.idHeadquarter,
        idSystem: 1
      }
      console.log(filter);
      this.zoneService.getListPines(filter).subscribe((response => {
        if(response.error == null){
          this.listPinMaps = response.data;
          /*if(this.listPinMaps.length > 0){
            this.locationForm.controls['idPinMap'].setValue(this.listPinMaps[0].id);
          }*/
        }
        /*if(response.idStorageFile == 1){ // Mode Local
           response.enterprisePinMap.forEach(function (x) {
            x.path = environment.pathFile + x.path;
          });
        }
        this.listPinMaps = response.enterprisePinMap;
        if(this.listPinMaps.length > 0){
          this.locationForm.controls['idPinMap'].setValue(this.listPinMaps[0].pinMapId);
        }*/
      }),
      (error: HttpErrorResponse) => {
      });
  }

  getListPinDefault(){
    var filter = {
      idSystem: 1
    }
    this.zoneService.getListDefaultPines(filter).subscribe((response => {
      if(response.error == null){
        this.listDefaultPinMaps = response.data;
      }
    }),
    (error: HttpErrorResponse) => {
    });
  }

  openConfirmationModal(){
    if(this.locationForm.valid && this.selectPinId) {
      //var pin = this.listPinMaps.filter(x => x.id == this.locationForm.get("idPinMap").value)[0];
      var data = {
        pinMap: this.selectPinId,//this.locationForm.get("idPinMap").value,
        direction: this.locationForm.get("oppositeMapDirection").value,
        path: this.selectPinPath
      };
      this.dialogRef.close({
        event: 'close',
        data: data
      });
    }else{
      this.alertService.showMessage("Debe completar todos los campos", "", MessageSeverity.error);
    }
  }

}
