import { Component, OnInit, Inject, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { MessageModalComponent } from '../message/message-modal.component';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { CameraService } from 'src/app/services/camera/camera.service';
import { AlertsServices } from 'src/app/services/alert/alert.service';
import { MessageSeverity,AlertService } from 'src/app/shared/services/alert.service';
import { CameraValidate } from 'src/app/models/alert/alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'parameter-modal',
    templateUrl: 'parameter-modal.component.html',
    styleUrls:['./parameter-modal.component.css']
})

export class ModalParameterComponent implements OnInit {

    loading: boolean = false;
    alerts = [];
    alertParameter = [];
    dataList = [];
    dataChange = [];
    copys = [];
    copia = [];
    dataCache = [];
    alertseSelect: number = 0;
    countIndex = 0;
    listStatus: number = 0;
    panelForm: FormGroup;
    listForm: FormGroup;
    loadingButton: boolean = false;
    isCompanyAdmin: boolean = false;

    dataSource = new MatTableDataSource();
    dataSources = new MatTableDataSource();
    displayedColumns: string[] = ['index', 'parameter', 'value', 'unit'];
    displayedColumn: string[] = ['index', 'name', 'action'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatPaginator, { static: true }) paginators: MatPaginator;
    subscription: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private dialogRef: MatDialogRef<ModalParameterComponent>,
        private AlertsServices :AlertsServices,
        private AlertService: AlertService,
        private formBuilder: FormBuilder,
        private cameraService:CameraService,
        private emitterService: EmitterService,
    ) { }

    ngOnInit() {
        this.createForm();
        this.dataSource.paginator = this.paginator;
        this.dataSources.paginator = this.paginators;
        this.getAlert();
    }

    createForm() {
        if(parseInt(localStorage.getItem(AppConstants.Session.ROLID) || "") == AppConstants.UserRole.AdministradorEmpresa) this.isCompanyAdmin=true;
        if(parseInt(localStorage.getItem(AppConstants.Session.ROLID) || "") == AppConstants.UserRole.AdministradorGyS
          || parseInt(localStorage.getItem(AppConstants.Session.ROLID) || "") == AppConstants.UserRole.UsuarioGyS
          || parseInt(localStorage.getItem(AppConstants.Session.ROLID) || "") == AppConstants.UserRole.AdministradorEmpresaStreaming){
        this.panelForm = this.formBuilder.group({
            alertId: ['', [Validators.required]],
        })
        this.listForm = this.formBuilder.group({
            valueId: ['', [Validators.required]],
        })
        this.loadingButton = true;
      }else{
        this.panelForm = this.formBuilder.group({
            alertId: [{value:'', disabled: false}, [Validators.required]],
        })
        this.listForm = this.formBuilder.group({
            valueId: [{value:'', disabled: false}, [Validators.required]],
        })
        this.loadingButton = false;
      }
    }

    closeDialog(){
        this.dialogRef.close()
    }

    getAlert() {
        this.spinner.show();
        this.AlertsServices.getAlert().subscribe((response) => {
            this.spinner.hide();
            console.log("alerts",response);
            this.alerts = response;
            this.alertseSelect = this.alerts[0]["id_Alert"];

            this.getAlertParametersTART(this.alerts[0]["id_Alert"]);

            this.loadData();

            this.loadDataAlert();

            this.addData();
        },
        (error: HttpErrorResponse) => {
            this.spinner.hide();
        });
    }

    remoneChange(id:number){
        let data = [];
        for(let i = 0; i< this.dataChange.length;i++){
            if(this.dataChange[i].alertId == id){
                data = data.concat({id:i});
            }
        }
        data = data.sort();
        data = data.reverse();
        for(let i = 0;data.length > i;i++){
            this.dataChange.splice(data[i].id,1);
        }
    }

    removeListDta(id:number){
        let datas = [];
        this.copys = this.dataSources.data;
        for(let ij = 0; ij< this.copys.length;ij++){
            if(this.copys[ij].id == id){
                datas = datas.concat({id:ij});
            }
        }
        datas = datas.sort();
        datas = datas.reverse();
        for(let i = 0;datas.length > i;i++){
            this.copys.splice(datas[i].id,1);
        }
    }

    removeListDtaCopia(id:number){
        let datas = [];
        for(let ij = 0; ij< this.copia.length;ij++){
            if(this.copia[ij].id == id){
                datas = datas.concat({id:ij});
            }
        }
        datas = datas.sort();
        datas = datas.reverse();
        for(let i = 0;datas.length > i;i++){
            this.copia.splice(datas[i].id,1);
        }
    }

    remoneChangeList(obj){
        let nn:number = obj.id;
        this.copia = this.copia.concat({id:this.alertseSelect,name:obj.name});
        this.spinner.show();

        var modelCamera = new CameraValidate;
        modelCamera.cameraId = this.data.idcameravalue;
        modelCamera.alertId = obj.id;

        this.cameraService.postCameraValidate(modelCamera).subscribe(response => {

            if (!response) {
                this.remoneChange(nn);
                this.removeListDta(nn);

                let dt = { value: nn};

                this.getAlertParameter(dt,1);
                this.dataSources.data =  this.copys;

            }else{
                this.AlertService.showMessage(
                    AppConstants.TitleModal.ERROR_TITLE,
                    AppConstants.MessageModal.CAMERA_PCURRENCE_NOT_DELETE,
                    MessageSeverity.error);
            }
        },
        (error: HttpErrorResponse) => {
            this.AlertService.showMessage(
                AppConstants.TitleModal.ERROR_TITLE,
                AppConstants.MessageModal.DATA_ERROR_MESSAGE,
                MessageSeverity.error);
        });

        this.spinner.hide();
    }

    sendData2(){
        let searchDataTable = this.alerts.filter(c => c.id_Alert.toString().toLowerCase().startsWith(this.alertseSelect));

        /*CAMBIAR POR ELIMINAR DE COPIA POR ID*/
        let sinR = [...new Set(this.copys)];
        this.copys = sinR;
        this.dataSources.data = this.copys;
        this.dataCache = this.dataCache.concat({id: this.alertseSelect});
        if(this.data.action == AppConstants.OperationType.ADD){
            this.remoneChange(this.alertseSelect);
            let vv = 0;
            let copys = [];
            if(this.dataChange.length == 0){
                this.dataChange = this.dataChange.concat(this.dataSource.data);
                this.countIndex = this.countIndex+ this.dataChange.length;
            }else{
                this.dataList = [];
                copys = this.dataChange;
                let searchData = this.dataChange.filter(c => c.alertId.toString().toLowerCase().startsWith(this.alertseSelect))
                if (searchData.length < 1) {
                    this.countIndex = this.countIndex+ this.dataSource.data.length;
                }
                for(let i of this.dataChange){
                    if(i.alertId != this.alertseSelect){
                        if(vv == 0){
                            this.dataChange = this.dataChange.concat(this.dataSource.data);
                            vv = 1;
                        }
                    }else{
                        if(vv == 0){
                            this.dataChange = [];
                            this.dataChange = this.dataSource.data;
                            this.dataChange = this.dataChange.concat(copys);
                            vv = 1;
                        }
                    }
                }
            }
        }
        else{
            /*ACTUALIZACIÓN*/
            this.remoneChange(this.alertseSelect);
            let vv = 0;
            let copyss = [];
            if(this.dataChange.length == 0){
                this.dataChange = this.dataChange.concat(this.dataSource.data);
            }else{
                this.dataList = [];
                copyss = this.dataChange;
                for(let i of this.dataChange){
                    if(i.alertId != this.alertseSelect){
                        this.dataChange = this.dataChange.concat(this.dataSource.data);
                    }else{
                        if(vv == 0){
                            this.dataChange = [];
                            this.dataChange = this.dataSource.data;
                            this.dataChange = this.dataChange.concat(copyss);
                            vv = 1;
                        }
                    }
                }
            }
        }
    }

    sendDataMail(){
        let searchDataTableSearch = this.copys.filter(c => c.id.toString().toLowerCase().startsWith(this.alertseSelect));
        if(searchDataTableSearch.length > 0){
            this.removeListDtaCopia(this.alertseSelect);
            this.sendDataMail();
        }else{
            let searchDataTable = this.alerts.filter(c => c.id_Alert.toString().toLowerCase().startsWith(this.alertseSelect));
            this.copys = this.copys.concat(
                [
                    {
                        id:this.alertseSelect,
                        name:searchDataTable[0].c_Alert
                    }
                ]
            );
            /*CAMBIAR POR ELIMINAR DE COPIA POR ID*/
            this.removeListDtaCopia(this.alertseSelect);
            this.copia = this.copia.concat(
                [
                    {
                        id:this.alertseSelect,
                        name:searchDataTable[0].c_Alert
                    }
                ]
            );
            /*CAMBIAR POR ELIMINAR DE COPIA POR ID*/
            let sinR = [...new Set(this.copys)];
            this.copys = sinR;
            this.dataSources.data = this.copys;
            this.dataCache = this.dataCache.concat({id: this.alertseSelect});
            if(this.data.action == AppConstants.OperationType.ADD){
                this.remoneChange(this.alertseSelect);
                let vv = 0;
                let copys = [];
                if(this.dataChange.length == 0){
                    this.dataChange = this.dataChange.concat(this.dataSource.data);
                    this.countIndex = this.countIndex+ this.dataChange.length;
                }else{
                    this.dataList = [];
                    copys = this.dataChange;
                    let searchData = this.dataChange.filter(c => c.alertId.toString().toLowerCase().startsWith(this.alertseSelect))
                    if (searchData.length < 1) {
                        this.countIndex = this.countIndex+ this.dataSource.data.length;
                    }
                    for(let i of this.dataChange){
                        if(i.alertId != this.alertseSelect){
                            if(vv == 0){
                                this.dataChange = this.dataChange.concat(this.dataSource.data);
                                vv = 1;
                            }
                        }else{
                            if(vv == 0){
                                this.dataChange = [];
                                this.dataChange = this.dataSource.data;
                                this.dataChange = this.dataChange.concat(copys);
                                vv = 1;
                            }
                        }
                    }
                }
            }
            else{
                /*ACTUALIZACIÓN*/
                this.remoneChange(this.alertseSelect);
                let vv = 0;
                let copyss = [];
                if(this.dataChange.length == 0){
                    this.dataChange = this.dataChange.concat(this.dataSource.data);
                }else{
                    this.dataList = [];
                    copyss = this.dataChange;
                    for(let i of this.dataChange){
                        if(i.alertId != this.alertseSelect){
                            this.dataChange = this.dataChange.concat(this.dataSource.data);
                        }else{
                            if(vv == 0){
                                this.dataChange = [];
                                this.dataChange = this.dataSource.data;
                                this.dataChange = this.dataChange.concat(copyss);
                                vv = 1;
                            }
                        }
                    }
                }
            }
        }
    }

    addData(){
        this.dataChange = [];
        let fg = [];
        this.AlertsServices.getAlert().subscribe((res) => {
            for(let i=0;i<res.length;i++){
                this.AlertsServices.getAlertarameterById(res[i].id_Alert,this.data.idcameravalue).subscribe((response) => {
                    for(let h =0;h < response.length;h++){
                        let sinR = [...new Set(response)];
                        var hash = {};
                        sinR = sinR.filter(function(current) {
                        var exists = !hash[current.parameterId];
                        hash[current.parameterId] = true;
                            return exists;
                        });
                        fg = sinR;
                        try{
                            this.dataChange = this.dataChange.concat([
                                {
                                    c_Parameter:fg[h].c_Parameter,
                                    parameterId:fg[h].parameterId,
                                    unit:fg[h].unit,
                                    value:fg[h].value,
                                    alertId:res[i].id_Alert
                                }
                            ]);
                        }catch{

                        }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.spinner.hide();
                });
            }
        },
        (error: HttpErrorResponse) => {
            this.spinner.hide();
        });
    }

    sendData(){
        let searchDataTable = this.alerts.filter(c => c.id_Alert.toString().toLowerCase().startsWith(this.alertseSelect));
        // this.copys = this.copys.concat(
        //     [
        //         {
        //             id:this.alertseSelect,
        //             name:searchDataTable[0].c_Alert
        //         }
        //     ]
        // );
        this.copys=[];
        /*CAMBIAR POR ELIMINAR DE COPIA POR ID*/
        this.removeListDtaCopia(this.alertseSelect);
        // this.copia = this.copia.concat(
        //     [
        //         {
        //             id:this.alertseSelect,
        //             name:searchDataTable[0].c_Alert
        //         }
        //     ]
        // );
        /*CAMBIAR POR ELIMINAR DE COPIA POR ID*/
        let sinR = [...new Set(this.copys)];
        this.copys = sinR;
        this.dataSources.data = this.copys;
        this.dataCache = this.dataCache.concat({id: this.alertseSelect});
        if(this.data.action == AppConstants.OperationType.ADD){
            this.remoneChange(this.alertseSelect);
            let vv = 0;
            let copys = [];
            if(this.dataChange.length == 0){
                this.dataChange = this.dataChange.concat(this.dataSource.data);
                this.countIndex = this.countIndex+ this.dataChange.length;
            }else{
                this.dataList = [];
                copys = this.dataChange;
                let searchData = this.dataChange.filter(c => c.alertId.toString().toLowerCase().startsWith(this.alertseSelect))
                if (searchData.length < 1) {
                    this.countIndex = this.countIndex+ this.dataSource.data.length;
                }
                for(let i of this.dataChange){
                    if(i.alertId != this.alertseSelect){
                        if(vv == 0){
                            this.dataChange = this.dataChange.concat(this.dataSource.data);
                            vv = 1;
                        }
                    }else{
                        if(vv == 0){
                            this.dataChange = [];
                            this.dataChange = this.dataSource.data;
                            this.dataChange = this.dataChange.concat(copys);
                            vv = 1;
                        }
                    }
                }
            }
        }
        else{
            /*ACTUALIZACIÓN*/
            this.remoneChange(this.alertseSelect);
            let vv = 0;
            let copyss = [];
            if(this.dataChange.length == 0){
                this.dataChange = this.dataChange.concat(this.dataSource.data);
            }else{
                this.dataList = [];
                copyss = this.dataChange;
                for(let i of this.dataChange){
                    if(i.alertId != this.alertseSelect){
                        this.dataChange = this.dataChange.concat(this.dataSource.data);
                    }else{
                        if(vv == 0){
                            this.dataChange = [];
                            this.dataChange = this.dataSource.data;
                            this.dataChange = this.dataChange.concat(copyss);
                            vv = 1;
                        }
                    }
                }
            }
        }
    }

    getAlertParameter(data,condition:number) {
        this.dataList = [];
        if(condition == 0){
            if(this.data.action == AppConstants.OperationType.ADD){

                this.AlertsServices.getAlertarameter(data.value).subscribe((response) => {
                    let searchData = this.dataChange.filter(x => x.alertId.toString().toLowerCase().startsWith(this.alertseSelect));
                    if(searchData.length < 1){
                        for(let i = 0; i < response.length; i++){
                            if(response[i].parameterId > 0){
                                this.dataList = this.dataList.concat([
                                    {
                                        c_Parameter:response[i].c_Parameter,
                                        parameterId:response[i].parameterId,
                                        unit:response[i].unit,
                                        value:0,
                                        alertId:this.alertseSelect
                                    }
                                ]);
                            }
                        }
                    }else{
                        for(let j =0;j < response.length;j++){
                            this.dataList = this.dataList.concat([
                                {
                                    c_Parameter:searchData[j].c_Parameter,
                                    parameterId:searchData[j].parameterId,
                                    unit:searchData[j].unit,
                                    value:searchData[j].value,
                                    alertId:searchData[j].alertId
                                }
                            ]);
                        }
                    }
                    let sinR = [...new Set(this.dataList)];
                    this.dataList = sinR;

                    this.dataSource.data =  this.dataList;
                    this.spinner.hide();
                },
                (error: HttpErrorResponse) => {
                    this.spinner.hide();
                });
                this.dataList = [];
            }
            else{
                /*ACTUALIZACIÓN*/
                this.dataList = [];
                this.AlertsServices.getAlertarameter(data.value).subscribe((response) => {
                    this.AlertsServices.getAlertarameterById(this.alertseSelect,this.data.idcameravalue ).subscribe((respons) => {

                        let searchData = this.dataChange.filter(x => x.alertId.toString().toLowerCase().startsWith(this.alertseSelect));
                        let searchDatas = this.copia.filter(x => x.id.toString().toLowerCase().startsWith(this.alertseSelect));

                        if(searchData.length < 1 && respons.length < 1 && searchDatas.length < 1){
                            /*BUSQUEDA 1*/

                            for(let i = 0; i < response.length; i++){
                                if(response[i].parameterId > 0){
                                    this.dataList = this.dataList.concat([
                                        {
                                            c_Parameter:response[i].c_Parameter,
                                            parameterId:response[i].parameterId,
                                            unit:response[i].unit,
                                            value:0,
                                            alertId:this.alertseSelect
                                        }
                                    ]);
                                }
                            }
                            let sinR = [...new Set(this.dataList)];
                            this.dataList = sinR;

                            this.dataSource.data =  this.dataList;
                        }else{
                            if(searchData.length < 1 && respons.length > 0 && searchDatas.length < 1){
                                /*BUSQUEDA 2*/

                                for(let i = 0; i < respons.length; i++){
                                    if(respons[i].parameterId > 0){
                                        this.dataList = this.dataList.concat([
                                            {
                                                c_Parameter:respons[i].c_Parameter,
                                                parameterId:respons[i].parameterId,
                                                unit:respons[i].unit,
                                                value:respons[i].value,
                                                alertId: this.alertseSelect
                                            }
                                        ]);
                                    }
                                }
                                let sinR = [...new Set(this.dataList)];
                                this.dataList = sinR;

                                this.dataSource.data =  this.dataList;
                                this.sendData2();
                            }else{
                                if(searchData.length < 1 && respons.length > 0 && searchDatas.length == 1){
                                    /*BUSQUEDA 3*/

                                        for(let ij = 0; ij < respons.length; ij++){
                                            if(respons[ij].parameterId > 0){
                                                this.dataList = this.dataList.concat([
                                                    {
                                                        c_Parameter:respons[ij].c_Parameter,
                                                        parameterId:respons[ij].parameterId,
                                                        unit:respons[ij].unit,
                                                        value:respons[ij].value,
                                                        alertId: this.alertseSelect
                                                    }
                                                ]);
                                            }
                                        }

                                        let sinR = [...new Set(this.dataList)];
                                        var hash = {};
                                        sinR = sinR.filter(function(current) {
                                            var exists = !hash[current.parameterId];
                                            hash[current.parameterId] = true;
                                            return exists;
                                        });
                                        this.dataList = sinR;

                                        this.dataSource.data =  this.dataList;
                                }else{
                                    /*BUSQUEDA 4*/

                                    if(searchData.length > 0 && searchDatas.length == 1){

                                        for(let i = 0; i < searchData.length; i++){
                                            if(searchData[i].parameterId > 0){
                                                this.dataList = this.dataList.concat([
                                                    {
                                                        c_Parameter:searchData[i].c_Parameter,
                                                        parameterId:searchData[i].parameterId,
                                                        unit:searchData[i].unit,
                                                        value:searchData[i].value,
                                                        alertId: this.alertseSelect
                                                    }
                                                ]);
                                            }
                                        }

                                        let sinR = [...new Set(this.dataList)];
                                        var hash = {};
                                        sinR = sinR.filter(function(current) {
                                            var exists = !hash[current.parameterId];
                                            hash[current.parameterId] = true;
                                            return exists;
                                        });
                                        this.dataList = sinR;

                                        this.dataSource.data =  this.dataList;
                                    }else{
                                         /*BUSQUEDA 5*/

                                        let dataLis = 0;
                                        this.AlertsServices.getAlertarameter(this.alertseSelect).subscribe((responses) => {
                                            if(dataLis == 0){
                                                for(let i = 0; i < responses.length; i++){
                                                    if(responses[i].parameterId > 0){
                                                        this.dataList = this.dataList.concat([
                                                            {
                                                                c_Parameter:responses[i].c_Parameter,
                                                                parameterId:responses[i].parameterId,
                                                                unit:responses[i].unit,
                                                                value:0,
                                                                alertId: this.alertseSelect
                                                            }
                                                        ]);
                                                    }
                                                }

                                                this.dataSource.data =  this.dataList;
                                                dataLis = 1;
                                            }else{
                                                this.dataList = [];
                                            }
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.spinner.hide();
                                        });

                                        this.dataSource.data =  this.dataList;
                                    }
                                }
                            }
                        }
                        this.spinner.hide();

                    });

                },
                (error: HttpErrorResponse) => {
                    this.spinner.hide();
                });
                this.dataList = [];
            }
        }else{
            this.dataList = [];
            let dataLis:  number = 0;
            if(data.value == this.alertseSelect){
                this.AlertsServices.getAlertarameter(data.value).subscribe((response) => {
                    if(dataLis == 0){
                        for(let i = 0; i < response.length; i++){
                            if(response[i].parameterId > 0){
                                this.dataList = this.dataList.concat([
                                    {
                                        c_Parameter:response[i].c_Parameter,
                                        parameterId:response[i].parameterId,
                                        unit:response[i].unit,
                                        value:0,
                                        alertId: data.value
                                    }
                                ]);
                            }
                        }
                        let sinR = [...new Set(this.dataList)];
                        this.dataList = sinR;

                        this.dataSource.data =  this.dataList;
                        dataLis = 1;
                    }else{
                        this.dataList = [];
                    }
                },
                (error: HttpErrorResponse) => {
                    this.spinner.hide();
                });
            }else{
                let dt = { value: 3};
            }
        }
    }

    getAlertParametersTART(id:number) {

        let cc = [];
        if(this.data.action == AppConstants.OperationType.ADD){
            let dataLis:  number = 0;
            this.spinner.show();
            this.AlertsServices.getAlertarameter(id).subscribe((response) => {

                if(dataLis == 0){
                    for(let i = 0; i < response.length; i++){
                        if(response[i].parameterId > 0){
                            cc = cc.concat([
                                {
                                    c_Parameter:response[i].c_Parameter,
                                    parameterId:response[i].parameterId,
                                    unit:response[i].unit,
                                    value:0,
                                    alertId: id
                                }
                            ]);
                        }
                    }

                    this.dataSource.data = cc;
                    dataLis = 1;
                }else{

                }
            },
            (error: HttpErrorResponse) => {
                this.spinner.hide();
            });
            this.spinner.hide();

        }
        else{
            /*ACTUALIZACIÓN*/

            let dataLis:  number = 0;
            this.spinner.show();
            this.AlertsServices.getAlertarameterById(id,this.data.idcameravalue ).subscribe((response) => {
                try{
                    if(response.length > 0){
                        if(dataLis == 0){
                            for(let i = 0; i < response.length; i++){
                                if(response[i].parameterId > 0){
                                    cc = cc.concat([
                                        {
                                            c_Parameter:response[i].c_Parameter,
                                            parameterId:response[i].parameterId,
                                            unit:response[i].unit,
                                            value:response[i].value,
                                            alertId: id
                                        }
                                    ]);
                                }
                            }

                            let sinR = [...new Set(cc)];
                            var hash = {};
                            sinR = sinR.filter(function(current) {
                                var exists = !hash[current.parameterId];
                                hash[current.parameterId] = true;
                                    return exists;
                            });
                            cc = sinR;

                            this.dataSource.data = cc;
                            dataLis = 1;
                            this.dataCache = this.dataCache.concat({id: id});
                        }

                    }else{
                        this.AlertsServices.getAlertarameter(id).subscribe((responses) => {
                            if(dataLis == 0){
                                for(let i = 0; i < responses.length; i++){
                                    if(responses[i].parameterId > 0){
                                        cc = cc.concat([
                                            {
                                                c_Parameter:responses[i].c_Parameter,
                                                parameterId:responses[i].parameterId,
                                                unit:responses[i].unit,
                                                value:0,
                                                alertId: id
                                            }
                                        ]);
                                    }
                                }

                                let sinR = [...new Set(cc)];
                                var hash = {};
                                sinR = sinR.filter(function(current) {
                                    var exists = !hash[current.parameterId];
                                    hash[current.parameterId] = true;
                                        return exists;
                                });
                                cc = sinR;

                                this.dataSource.data = cc;
                                dataLis = 1;
                            }else{

                            }
                        },
                        (error: HttpErrorResponse) => {
                            this.spinner.hide();
                        });
                    }
                }catch{
                    this.AlertsServices.getAlertarameter(id).subscribe((responses) => {
                        if(dataLis == 0){
                            for(let i = 0; i < responses.length; i++){
                                if(responses[i].parameterId > 0){
                                    cc = cc.concat([
                                        {
                                            c_Parameter:responses[i].c_Parameter,
                                            parameterId:responses[i].parameterId,
                                            unit:responses[i].unit,
                                            value:0,
                                            alertId: id
                                        }
                                    ]);
                                }
                            }

                            let sinR = [...new Set(cc)];
                            var hash = {};
                            sinR = sinR.filter(function(current) {
                                var exists = !hash[current.parameterId];
                                hash[current.parameterId] = true;
                                    return exists;
                            });
                            cc = sinR;

                            this.dataSource.data = cc;
                            dataLis = 1;
                        }else{

                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.spinner.hide();
                    });
                }
            },
            (error: HttpErrorResponse) => {
                this.spinner.hide();
            });
            this.spinner.hide();
            cc = [];
        }
    }

    loadData(){
        this.dataList = [];
        if(this.data.action == AppConstants.OperationType.ADD){

        }else{
            for(let is = 1;is < this.alerts.length;is++){
                this.AlertsServices.getAlertarameterById(this.alerts[is].id_Alert,this.data.idcameravalue ).subscribe((response) => {
                    try{
                        if(response.length > 0){
                            for(let i = 0; i < response.length; i++){
                                if(response[i].parameterId > 0){
                                    this.dataList = this.dataList.concat([
                                        {
                                            c_Parameter:response[i].c_Parameter,
                                            parameterId:response[i].parameterId,
                                            unit:response[i].unit,
                                            value:response[i].value,
                                            alertId: this.alerts[i].id_Alert
                                        }
                                    ]);
                                }
                            }

                            this.sendData();
                            let sinR = [...new Set(this.copys)];
                            var hash = {};
                            sinR = sinR.filter(function(current) {
                                var exists = !hash[current.id];
                                hash[current.id] = true;
                                return exists;
                            });
                            this.copys = sinR;
                            this.dataSources.data = this.copys;
                        }
                    }
                    catch{

                    }
                });
            }
        }
    }

    loadDataAlert(){
        this.dataList = [];
        this.AlertsServices.getAlert().subscribe((respon) => {
            for(let is = 0;is < respon.length;is++){
                this.AlertsServices.getAlertarameterById(respon[is].id_Alert,this.data.idcameravalue ).subscribe((response) => {
                    if(response.length > 0){
                        this.copys = this.copys.concat(

                                {
                                    id:respon[is].id_Alert,
                                    name:respon[is].c_Alert
                                }

                        );
                    }
                    let sinR = [...new Set(this.copys)];
                    var hash = {};
                    sinR = sinR.filter(function(current) {
                        var exists = !hash[current.id];
                        hash[current.id] = true;
                        return exists;
                    });
                    this.copys = sinR;
                    this.dataSources.data = this.copys;
                    this.copia = this.copys;
                });
            }
        },
        (error: HttpErrorResponse) => {
            this.spinner.hide();
        });
    }

    saveData(){
        if(this.dataChange.length < 1){
            this.spinner.show();
            this.AlertService.showMessage(
                AppConstants.TitleModal.WARNING_TITLE,
                AppConstants.MessageModal.DATA_NULL_REQUIRED_PARAMTER,
                MessageSeverity.error);
                this.spinner.hide();
            return false;
        }
        let message = '';
        switch (this.data.action) {
            case AppConstants.OperationType.ADD:
            message = AppConstants.MessageModal.REGISTER_MESSAGE_CAMERA_CONFIRM
            break;
            case AppConstants.OperationType.UPDATE:
            message = AppConstants.MessageModal.EDIT_MESSAGE_CAMERA_CONFIRM
            break;
        }
        let dialogRef = this.dialog.open(MessageModalComponent, {
                data: {
                title: 'Confirmar Registro',
                message: message,
                hasButton: true,
                hasBackdrop: false,
                contentButton: {
                    yes: 'SI',
                    no: 'CANCELAR'
                }
            },
        });
        dialogRef.afterClosed().subscribe(response => {

              if(this.data.action == AppConstants.OperationType.ADD){
                if (response.result == true) {
                    this.save();
                }
              }else{
                this.spinner.hide();
                if (response.result == true) {
                    this.edit();
                }
              }

        });
    }

    save(){
        this.spinner.show();
        let camerapre = [];
        let dataUnique = this.dataChange;
        for(let i = 0;dataUnique.length > i;i++){
            camerapre = camerapre.concat([
                {
                    AlertId:dataUnique[i].alertId,
                    ParameterId:dataUnique[i].parameterId,
                    ValueId:dataUnique[i].value
                }
            ]);
        }

        if(camerapre.length < 1){
            this.AlertService.showMessage(
                AppConstants.TitleModal.WARNING_TITLE,
                AppConstants.MessageModal.DATA_NULL_REQUIRED_PARAMTER,
                MessageSeverity.error);
                this.spinner.hide();
            return false;
        }

        let valueint = 0;

        if(  this.data.obj.status == true){
            valueint = 1;
        }else{
            valueint = 0;
        }

        let registers =
        {
            UserId: parseInt(localStorage.getItem(AppConstants.Session.USERID)),
            ZoneId: this.data.obj.zone,
            Camera: this.data.obj.camera,
            Model: this.data.obj.model,
            Serie: this.data.obj.serie,
            Mac: this.data.obj.mac,
            Ip: this.data.obj.ipAdress,
            Url: this.data.obj.urlStreaming,
            Enabled: valueint,
            DateRegister: this.data.obj.date,
            CameraAlerts: camerapre,
            StreamingDescription: this.data.obj.streamingDescription,
            IdServer: this.data.obj.idServer,
            IdEnterprise: this.data.obj.idEnterprise,
            IdHeadquarter: this.data.obj.idHeadquarter,
            ClearImagePreviewStreaming: this.data.obj.clearImagePreviewStreaming,
            SendAlert: 1
        }

        let formData = new FormData();
        formData.append('data', JSON.stringify(registers));

        this.cameraService.registerCamera(formData).subscribe(
            result => {
              if(!result.message){
                this.AlertService.showMessage(
                  AppConstants.TitleModal.CREATE_CAMERA,
                  AppConstants.MessageModal.CREATE,
                  MessageSeverity.success);
                  this.data.result = true;
                  if(this.data.action == AppConstants.OperationType.ADD){
                    this.dialog.closeAll();
                  }else{
                    this.dialogRef.close(this.data);
                  }
                this.emitterService.sendMessage(AppConstants.EmitterService.ListCamera);
              }else{
                this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, result.message, MessageSeverity.error);
              }
            },
            (error: HttpErrorResponse) => {
              this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
        });

        this.spinner.hide();

    }

    edit(){
        this.spinner.show();
        let  camerapre = [];
        let dataUnique:any = this.dataChange;
        for(let i = 0;dataUnique.length > i;i++){
            camerapre = camerapre.concat([
                {
                    AlertId:dataUnique[i].alertId,
                    ParameterId:dataUnique[i].parameterId,
                    ValueId:dataUnique[i].value
                }
            ]);
        }

        let valueint = 0;

        if(  this.data.obj.status == true){
            valueint = 1;
        }else{
            valueint = 0;
        }

        let updated =
        {
            CameraId: this.data.idcameravalue,
            IdHeadquarter: this.data.obj.idHeadquarter,
            UserId: parseInt(localStorage.getItem(AppConstants.Session.USERID)),
            ZoneId: this.data.obj.zone,
            Camera: this.data.obj.camera,
            Model: this.data.obj.model,
            Serie: this.data.obj.serie,
            Mac: this.data.obj.mac,
            Ip: this.data.obj.ipAdress,
            Url: this.data.obj.urlStreaming,
            Enabled: valueint,
            DateRegister: this.data.obj.date,
            CameraAlerts: camerapre,
            StreamingDescription: this.data.obj.streamingDescription,
            IdServer: this.data.obj.idServer,
            IdEnterprise: this.data.obj.idEnterprise,
            ClearImagePreviewStreaming: this.data.obj.clearImagePreviewStreaming,
            StreamingKey: this.data.obj.streamingKey,
            SendAlert: 1
        }
        let formData = new FormData();
        formData.append('data', JSON.stringify(updated));
        formData.append('file', this.data.obj.file[0]);
        this.cameraService.updatedCamera(formData).subscribe(
            result => {
              if(!result.message){
                this.AlertService.showMessage(
                  AppConstants.TitleModal.UPDATE_CAMERA,
                  AppConstants.MessageModal.UPDATE,
                  MessageSeverity.success);
                  this.data.result = true;
                  this.dialogRef.close(this.data);
                this.emitterService.sendMessage(AppConstants.EmitterService.ListCamera);
              }else{
                this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, result.message, MessageSeverity.error);
              }
            },
            (error: HttpErrorResponse) => {
              this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
          });

        this.spinner.hide();
    }

    close() {
        this.dialog.closeAll();
    }

}
