<div class="row title-modal"  [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
        <mat-icon [style.color]="data.color" mat-dialog-close (click)="closeDialog()">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
        <form [formGroup]="panelForm" action="">
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <mat-label>Alertas</mat-label>
                        <mat-select (selectionChange)="getAlertParameter($event,0)" formControlName="alertId" [(ngModel)]="alertseSelect">
                            <mat-option *ngFor="let alert of alerts" [value]="alert.id_Alert">
                                {{alert.c_Alert}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6 text-right">
                    <button mat-raised-button [style.background]="data.color"  [style.color]="data.colorFont" *ngIf="data.enabledWrite"(click)="sendDataMail()">AGREGAR</button>
                </div>
            </div>
        </form>

        <div class="row">
            <div class="col-5 my-auto pl-0">
                <h4><span>Parámetros</span></h4>
            </div>
            <div class="col-6 offset-1">
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <section class="table-responsive mt-3">

                    <form [formGroup]="listForm" action="">
                        <table class="table" mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="index">
                                <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.color2"> # </th>
                                <td class="text-center" *matCellDef="let element; let i = index">
                                    {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="parameter">
                                <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="color2"> Parámetros </th>
                                <td class="text-center" *matCellDef="let element"> {{element.c_Parameter}} </td>
                            </ng-container>

                            <ng-container matColumnDef="value">
                                <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="color2"> Valor </th>
                                <td class="text-center" *matCellDef="let element">
                                    <mat-form-field class="w-100">
                                        <mat-label>Valor</mat-label>
                                        <input type="text" [disabled]="isCompanyAdmin" matInput DecimalAmount ngDefaultControl maxlength="4" required [ngModelOptions]="{standalone: true}" [(ngModel)]="element.value" id="price" name="price">
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="unit">
                                <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="color2"> Unidad </th>
                                <td class="text-center" *matCellDef="let element"> {{element.unit}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </form>

                    <mat-paginator [pageSize]="12" [hidePageSize]="true">
                    </mat-paginator>

                </section>
            </div>
            <div class="col-12">
                <table class="table" mat-table [dataSource]="dataSources">

                    <ng-container matColumnDef="index">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.color2"> # </th>
                        <td class="text-center" *matCellDef="let element; let i = index">
                            {{this.paginators.pageIndex == 0 ? i + 1 : 1 + i + this.paginators.pageIndex * this.paginators.pageSize}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.color2"> Alerta </th>
                        <td class="text-center" *matCellDef="let elements"> {{elements.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="data.color" [style.color]="data.color2"> Acción </th>
                        <td class="text-center" *matCellDef="let elements">
                            <mat-icon color="primary" class="icon-cursor" (click)="remoneChangeList(elements)">delete</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumn" class="table-background"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>

                </table>

                <mat-paginator [pageSize]="12" [hidePageSize]="true">
                </mat-paginator>

            </div>
        </div>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="closeDialog()">REGRESAR</button>
    <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="data.enabledWrite" (click)="saveData()">ACEPTAR</button>
    <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" *ngIf="isCompanyAdmin &&  data.enabledWrite" (click)="saveData()">ACEPTAR</button>
</mat-dialog-actions>
