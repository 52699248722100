<div class="row title-modal"  [style.color]="data.color"  [style.borderColor]="data.color">
  <div class="col-10">
      {{title}}
  </div>
  <div class="col-2 text-right">
      <mat-icon  [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
  <div class="container">
      <form [formGroup]="fileForm" autocomplete="off"  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' " >
          <div class="row">
              <div class="col-12 col-md-12" >
                <mat-form-field class="w-100">
                    <mat-label>Tipo de directorio*</mat-label>
                    <mat-select formControlName="idDirectoryType" [disabled]="disabledDirectoryType" (selectionChange)="changeDirectory($event)" [(ngModel)]="directoryTypeSelect" >
                        <mat-option [value]="1" >Blob</mat-option>
                        <mat-option [value]="2" >Datalake</mat-option>
                    </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-12" *ngIf="directoryTypeSelect==1" >
                  <mat-form-field class="w-100">
                      <mat-label>Tipo de Almacenamiento*</mat-label>
                      <mat-select formControlName="idStorageType" [(ngModel)]="storageTypeSelect" >
                          <mat-option [value]="1" >Local</mat-option>
                          <mat-option [value]="2" >Azure</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>

              <div class="col-12 col-md-12" *ngIf="directoryTypeSelect==2" >
                <mat-form-field class="w-100">
                    <mat-label>Tipo de Almacenamiento*</mat-label>
                    <mat-select formControlName="idStorageType2" [(ngModel)]="storageTypeSelect2" >
                        <mat-option [value]="1" >Local</mat-option>
                        <mat-option [value]="2" >Azure</mat-option>
                    </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-12" *ngIf="directoryTypeSelect == 1">
                  <mat-form-field class="w-100">
                      <mat-label  *ngIf="storageTypeSelect == 1">Directorio de Archivos - Ubicación directorio assets front</mat-label>
                      <mat-label  *ngIf="storageTypeSelect == 2">Key Vault</mat-label>
                      <input matInput formControlName="storagePath" maxlength="500" >
                  </mat-form-field>
              </div>

              <div class="col-12 col-md-12" *ngIf="directoryTypeSelect == 2">
                <mat-form-field class="w-100">
                    <mat-label  *ngIf="storageTypeSelect2 == 1">Directorio de Archivos - Ubicación directorio assets front</mat-label>
                    <mat-label  *ngIf="storageTypeSelect2 == 2">Key Vault - Llave DataLake</mat-label>
                    <input matInput formControlName="storagePath2" maxlength="500" >
                </mat-form-field>
              </div>

              <div class="col-12 col-md-12" *ngIf="directoryTypeSelect == 2 && storageTypeSelect2 == 2">
                <mat-form-field class="w-100">
                    <mat-label>Ruta DataLake</mat-label>
                    <input matInput formControlName="dataLakePath" maxlength="500" >
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12" >
                  <h5>Lista de Directorios de Archivos</h5>
                  <table class="table" >
                      <thead  class="table-background">
                          <tr>
                              <th  [style.background]="data.color" [style.color]="data.colorFont">Directorio de Archivo</th>
                              <th  [style.background]="data.color" [style.color]="data.colorFont">Nombre Propio</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of listFiles" >

                              <td>{{item.fileDirectory}}</td>
                              <td style="padding: 3px !important;"><input type="text" (keyup)="changeValue(item,$event)" class="form-control" value="{{item.name}}" required></td>

                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </form>
  </div>
</mat-dialog-content>
<div>
  <div>
    <input type="checkbox"   [checked]="isDirectoryHeadquarter" [ngModel]="isDirectoryHeadquarter" (click)="checkHeadquarter()" > Habilitar Configuración por sede
  </div>
  <div>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>CANCELAR</button>
      <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" (click)="openConfirmationModal()" *ngIf="data.enabledWrite" >GUARDAR</button>
    </mat-dialog-actions>
  </div>
</div>

