import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StreamingServerService } from 'src/app/services/streamingServer/streamingServer.service';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-server-streaming-modal',
  templateUrl: './server-streaming-modal.component.html',
  styleUrls: ['./server-streaming-modal.component.css']
})
export class ServerStreamingModalComponent implements OnInit {

  securityForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private AlertService: AlertService,
    private streamingServerService: StreamingServerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createForm();
    if(this.data.action == 1){
      this.securityForm.controls['name'].setValue(this.data.obj.name);
      this.securityForm.controls['ip'].setValue(this.data.obj.ip);
      this.securityForm.controls['streamingRtmp'].setValue(this.data.obj.streamingRtmp);
      this.securityForm.controls['streamingFile'].setValue(this.data.obj.streamingFile);
    }
  }

  createForm(){
    this.securityForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(50)]],
      ip: ['', [Validators.required]],
      streamingRtmp: ['', [Validators.required]],
      streamingFile: ['', [Validators.required]]
    })
  }

  get f() { return this.securityForm.controls; }

  save(){
    if(this.securityForm.valid){
      const payload = {
        name:this.securityForm.value["name"],
        ip:this.securityForm.value["ip"],
        streamingRtmp:this.securityForm.value["streamingRtmp"],
        streamingFile:this.securityForm.value["streamingFile"],
        id: this.data.action == 1 ? this.data.obj.id : 0,
        idEnterprise: this.data.idEnterprise,
        idUser: localStorage.getItem(AppConstants.Session.USERID)
      }
      if(this.data.action == 0){
        this.streamingServerService.create(payload).subscribe(_ => {
          if(_.message == null || _.message == ""){
            this.AlertService.showMessage(AppConstants.TitleModal.CREATE_STREAMING_SERVER, AppConstants.MessageModal.CREATE, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.AlertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
      }else{
        this.streamingServerService.update(payload).subscribe(_ => {
          if(_.message == null || _.message == ""){
            this.AlertService.showMessage(AppConstants.TitleModal.UPDATE_STREAMING_SERVER, AppConstants.MessageModal.UPDATE_SECURITY, MessageSeverity.success);
            this.dialog.closeAll();
          }else{
            this.AlertService.showMessage(
              _.message,
              "",
              MessageSeverity.error);
          }
        });
      }
    }else{
      this.securityForm.markAllAsTouched();
    }
  }

  close() {
    this.dialog.closeAll();
  }
}
