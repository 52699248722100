import { Component, HostListener, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AppConstants } from '../../constants/app.contants';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin/admin.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
  animations: [
    trigger('menuIconAnimation', [
      state('closed', style({ transform: 'rotate(0deg)' })),
      state('open', style({ transform: 'rotate(180deg)' })),
      transition('closed <=> open', animate('200ms ease-in-out'))
    ])
  ]
})
export class MainNavComponent  implements OnInit {
  user: any;
  email: string;
  loading: boolean = false;
  navList = [];
  headquarters = [];
  enterpriseSelect = 0;
  headquarterSelect = 0;
  roleUser: string;
  enterprises = [];
  isExpanded: boolean = true;
  logo: string;
  color1: string;
  title: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  profileoptions = false;
  initMenuIcon: boolean;
  isPhone = false;
  isTablet = false;
  menuIcon = localStorage.getItem(AppConstants.Session.MENU_ICON) == "1" ? true:false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    //private authService: MsalService,

    private auService: AuthService,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    private router: Router) {
      const userAgent: string = navigator.userAgent || navigator.vendor;
      const isMobileDevice = (): boolean => {
        const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
        return regexs.some((b) => userAgent.match(b))
      }
      const isTabletDevice = (): boolean => {
        const regexs = [/iPad/i, /iPod/i, /tablet/i, /tablet|ipad/i, /^((?!chrome|android).)*safari/i]
        return regexs.some((b) => userAgent.match(b))
      }
    this.isPhone = isMobileDevice();
    this.isTablet = isTabletDevice();
    this.initMenuIcon = parseInt(localStorage.getItem(AppConstants.Session.MENU_ICON)) == 1 ? true : false;
    var color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    var color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    var logo = localStorage.getItem(AppConstants.Session.LOGO);
      if(color1 != null && color1 != ""){
        this.color1 = color1;
        this.colorFont = color1;
      }else{
        this.colorFont = environment.themeColorFont1;
        this.color1 = environment.themeColor1;
      }

      if(color2 != null && color2 != ""){
        this.color2 = color2;
        this.colorFont2 = color2;
      }else{
        this.color2 = environment.themeColor2;
        this.colorFont2 = environment.themeColorFont2;
      }

      if(this.logo == "" && this.logo == null){
        this.logo = '../../../'+environment.logoEnterprise;
      }else{
        this.logo = logo;
      }
      /*localStorage.setItem(AppConstants.Session.LOGO,this.logo);
      localStorage.setItem(AppConstants.Session.THEME_COLOR1,this.color1);
      localStorage.setItem(AppConstants.Session.THEME_COLOR2,this.color2);
      localStorage.setItem(AppConstants.Session.THEME_COLOR_FONT,this.colorFont);
      localStorage.setItem(AppConstants.Session.THEME_COLOR_FONT2,this.colorFont2);*/
      this.user = localStorage.getItem(AppConstants.Session.USER);
      this.email = localStorage.getItem(AppConstants.Session.USERNAME);
      this.listEnterprises();
      this.listHeadquarters();

      if(localStorage.getItem(AppConstants.Session.CHANGE_ENTERPRISE_MENU) == '1'){
        localStorage.setItem(AppConstants.Session.CHANGE_ENTERPRISE_MENU, '0');
        var item = localStorage.getItem(AppConstants.Session.FIRST_PAGE);
        if(item == "dashboard"){ item = "home/"+item; }
        this.router.navigate([item]);
      }
  }

  showProfileOptions(){
    this.profileoptions = this.profileoptions == false ? true:false;
  }

  listEnterprises(){
    var enterprises = JSON.parse(localStorage.getItem(AppConstants.Session.ENTERPRISES));
    this.enterprises = enterprises;
    if(localStorage.getItem(AppConstants.Session.ENTERPRISE) != ""){
      this.enterpriseSelect = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE));
    }else{
      this.enterpriseSelect = enterprises[0].id;
    }
  }

  listHeadquarters(change = 0){
    var headquarters = JSON.parse(localStorage.getItem(AppConstants.Session.HEADQUARTERS));
    console.log("list",headquarters);
    var _this = this;
    var h = headquarters.filter(x => x.idEnterprise == _this.enterpriseSelect);
    if(h.length > 0){
      _this.headquarters = h;
    }
    console.log("headquarters",this.headquarters);
   // this.headquarters = headquarters.filter(x => x.idEnterprise == this.enterpriseSelect);
    if(this.headquarters.length>1){
      this.headquarterSelect = 0;
      if(localStorage.getItem(AppConstants.Session.HEADQUARTER) != "" && localStorage.getItem(AppConstants.Session.HEADQUARTER) != "0" && change == 0){
        this.headquarterSelect = parseInt(localStorage.getItem(AppConstants.Session.HEADQUARTER));
      }else{
        this.headquarterSelect = this.headquarters[0].id;
      }
    }else{
      this.headquarterSelect = this.headquarters[0].id;
    }

    console.log("headquarterSelect",this.headquarterSelect);
    var listMenuEnterpriseHeadquarter = localStorage.getItem(AppConstants.Session.MENU_ROLE);

    var listMenuEH =  JSON.parse(listMenuEnterpriseHeadquarter).filter(x => x.idEnterprise == this.enterpriseSelect);
    if(listMenuEH.length == 1){
      if(listMenuEH[0].idHeadquarter == 0){
        var listmenuEnterpriseHeadquarterRoles = JSON.parse(listMenuEnterpriseHeadquarter).filter(x => x.idEnterprise == this.enterpriseSelect);
        var role = listmenuEnterpriseHeadquarterRoles[0].role;
        localStorage.setItem(AppConstants.Session.ROLE, role.toString());
        this.roleUser = localStorage.getItem(AppConstants.Session.ROLE);
      }else{
        var listmenuEnterpriseHeadquarterRoles = JSON.parse(listMenuEnterpriseHeadquarter).filter(x => x.idEnterprise == this.enterpriseSelect && x.idHeadquarter == this.headquarterSelect);
        var role = listmenuEnterpriseHeadquarterRoles[0].role;
        localStorage.setItem(AppConstants.Session.ROLE, role.toString());
        this.roleUser = localStorage.getItem(AppConstants.Session.ROLE);
      }
    }else{
      var listmenuEnterpriseHeadquarterRoles = JSON.parse(listMenuEnterpriseHeadquarter).filter(x => x.idEnterprise == this.enterpriseSelect && x.idHeadquarter == this.headquarterSelect);
      if(listmenuEnterpriseHeadquarterRoles.length>0){
        var role = listmenuEnterpriseHeadquarterRoles[0].role;
        localStorage.setItem(AppConstants.Session.ROLE, role.toString());
        this.roleUser = localStorage.getItem(AppConstants.Session.ROLE);
      }
    }

    if(change == 1){
      localStorage.setItem(AppConstants.Session.HEADQUARTER,this.headquarterSelect.toString());
     // location.reload();
     this.changeMenu();
    }


  }

  changeEnterprise(e){
    this.spinner.show();
    this.enterpriseSelect = e;
    localStorage.setItem(AppConstants.Session.ENTERPRISE,this.enterpriseSelect.toString());
    localStorage.setItem(AppConstants.Session.HEADQUARTER, "0");
    var enterprises = JSON.parse(localStorage.getItem(AppConstants.Session.ENTERPRISES));
    console.log("enterprise ID",e);
    console.log("enterprises",enterprises);
    var enterprise = enterprises.filter(x => x.id == e);
    console.log("enterprise change",enterprise);
    localStorage.setItem(AppConstants.Session.LOGO, enterprise[0].logo);
    localStorage.setItem(AppConstants.Session.THEME_COLOR1, enterprise[0].themeColor1);
    localStorage.setItem(AppConstants.Session.THEME_COLOR2, enterprise[0].themeColor2);
    this.listHeadquarters(1);
   // location.reload();
  }

  changeHeadquarter(e){
    this.spinner.show();
    this.headquarterSelect = e;
    localStorage.setItem(AppConstants.Session.HEADQUARTER,this.headquarterSelect.toString());
    this.changeMenu();
  }

  changeMenu(){
    var idUser = parseInt(localStorage.getItem(AppConstants.Session.USERID));
    this.adminService.getMenuEnterpriseRole(this.enterpriseSelect,this.headquarterSelect,idUser).subscribe((response) => {
      console.log("response",response);
      var menu = response.data;
          localStorage.setItem(AppConstants.Session.MENU, JSON.stringify(menu));
          for (var i = 0; i < menu.length; i++) {
            var link = menu[i].tag;
            var childs = menu.filter(x => x.idParent == menu[i].id && (x.activeRead == 1 || x.activeWrite==1));
            if (childs.length > 0) {
              link = childs[0].tag;
            }
            localStorage.setItem(AppConstants.Session.FIRST_PAGE, link);
            localStorage.setItem(AppConstants.Session.CHANGE_ENTERPRISE_MENU, '1');
            location.reload();
            break;
          }
      });
  }

  minMenu(){
    this.menuIcon = !this.menuIcon;
    var icon = this.menuIcon ? "1":"0";
    localStorage.setItem(AppConstants.Session.MENU_ICON, icon);
  }

ngOnInit() {
  this.getMenu();
}

ngOnChanges()
{
  document.getElementById("titleHeaderNav").innerHTML = localStorage.getItem(AppConstants.Session.MENU_TITLE);
}

async getMenu() {
  var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
  menu.forEach(x => {
    x.pages = menu.filter(y => y.idParent == x.id && (y.activeRead == 1 || y.activeWrite==1));
  });
  menu.forEach(function(item, index, object) {
    if (item.isChild === 1 || (item.activeWrite == 0 && item.activeRead  == 0 && item.pages == 0)) {
      object.splice(index, 1);
    }
  });
  this.navList =menu;
}

@HostListener('window:popstate', ['$event'])
onPopState(event) {
  window.history.forward();
}

logout(){
  this.spinner.show();
  this.auService.logout(localStorage.getItem("_token_VD_GYS")).subscribe(_ =>{
    localStorage.removeItem('_token_VD_GYS');
    if(localStorage.getItem("isAzure") != null && localStorage.getItem("isAzure") == "1"){
      localStorage.clear();
    }else{
      localStorage.clear();
      this.router.navigate(['/']);
    }
  });
}


myProfile(){
  this.router.navigate(['/home/admin/profile']);
}

hideMobileMenu(): void {
  if (window.innerWidth <= 600) {
    this.menuIcon = true;
  }
}

}
