import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {Chart} from 'chart.js';
import StreamingPlugin from 'chartjs-plugin-streaming';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-sensor-graph-modal',
  templateUrl: './sensor-graph-modal.component.html',
  styleUrls: ['./sensor-graph-modal.component.css']
})
export class SensorGraphModalComponent implements OnInit {
  @Input('sensors') sensors : any;
  @Input('idGateway') idGateway : any;
  dataSensors = [];
  public chart: any;
  dataIOTHub: any;
  //color = "#31bfe9";
  colors = ["#31bfe9"];
  valueSensor = 0;
  idCanvas = 0;
  myChart:any;
  valueInput = 0;
  color="";
  colorFont = "";

  constructor(
    private panelIOTService: PanelIOTService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private elementRef: ElementRef) {
    this.color = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    var _this = this;
    const connection = new signalR.HubConnectionBuilder()
        .withUrl(environment.signalR + '/api')
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
      connection.on('eventHubIOT', (message) => {
        _this.dataIOTHub = message;
      });

      connection.start()
        .catch(console.error);
  }

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    if(this.dataSensors.length==0){
      this.dataSensors = this.sensors;
      console.log("paneliot",this.dataSensors);
    }
 }

 on_off(item){
    this.spinner.show();
    var data = {
      id: item.id,
      value: item.value=='1' ? 0 : 1,
      idUser: localStorage.getItem(AppConstants.Session.USERID),
      idActuatorType: item.idActuatorType
    };
    item.value = data.value.toString();
   this.panelIOTService.createActivity(data).subscribe((response) => {
      this.panelIOTService.setUpdatePanelIOT(1);
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
 }

  ngAfterViewInit(): void {
    if(this.dataSensors.length>0){

 // ngAfterViewInit(): void {
   // Chart.register(StreamingPlugin);
    if(this.dataSensors.length>0){
          var _this = this;
        // const randomColor = Math.floor(Math.random()*16777215).toString(16);


          const onRefresh = chart => {
            var _this = this;
            var keys = chart.canvas.id.split("_");
            var data = _this.dataIOTHub;
            var value = 0;
            if(data != null && data != undefined){
              value = data[keys[1]];
            }
            _this.dataSensors.forEach(g => {
              if (g.nomenclature == keys[1]) {
                g.value = value;
              }
            });

            this.panelIOTService.setDataStreamingSensors(_this.dataSensors);

            _this.dataSensors;
            document.getElementById("value-slider"+keys[1]).innerHTML = value.toString();
            const now = Date.now();
            chart.data.datasets.forEach(dataset => {
              if (dataset.id === keys[1]) {
                dataset.data.push({
                  x: now,
                  y: value
                });
              }
            });

          };
          /*
          Chart.defaults.set('plugins.streaming', {
            duration: 20000
          });*/

          var _this = this;
          let index = 0
          for(let d of this.dataSensors){

              const data2 = {
                datasets: [
                  {
                  // label: 'Dataset 1 (Linear Interpolation)',
                    backgroundColor: function(context) {
                      const chart = context.chart;
                      const {ctx, chartArea} = chart;

                      if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                      }
                      return _this.getGradient(ctx, chartArea,_this.color);
                    },
                    borderColor: this.color,
                    borderDash: [8, 4],
                    data: [],
                    fill: true,
                    id: d.nomenclature
                  }
                ]
              };

              let htmlRef = this.elementRef.nativeElement.querySelector(`#chartLineStreaming_`+d.nomenclature);
              console.log("htmlRef",htmlRef + "-----" + d.nomenclature);
              new Chart(htmlRef, {
                type: 'line',
                data: data2,
                options: {
                 /* plugins: {
                    streaming: {
                      duration: 20000
                    },
                    legend: {
                      display: false
                    }
                  },
                  scales: {
                    x: {
                      type: 'realtime',
                      realtime: {
                        duration: 60000,
                        ttl: 60000,
                        refresh: 2000,
                        delay: 2000,
                        onRefresh: onRefresh,
                        frameRate: 90,
                        pause: false
                      },
                    },
                    y: {
                      title: {
                        display: false,
                        text: 'Valor'
                      }
                    }
                  },
                  interaction: {
                    intersect: false
                  }*/
                }
              });
              index++
          }
        }
    }
  }

  getGradient(ctx, chartArea, color) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, '#ffffff45');
      gradient.addColorStop(0.5, color);
      gradient.addColorStop(1, '#ffffff45');
    }

    return gradient;
  }

  closeModal(id){
    var data = {
      id: id,
      value: 0
    }
    //this.showroomService.updatePanelValue(data).subscribe(_ => {
      document.getElementById('actuactorPanel'+id).style.display="none";
    //});
  }

  onChangeToogle(value, id){
    /*var value = this.dataValue;
    if(this.item.id == 4){ //resistencia
      if(!this.tiempoDisabledResistencia){
        if(this.item.maxLimit > 0){
          if(value){this.dataValue = 0; }else{this.dataValue = this.item.maxLimit; }
        }else{
          if(value){this.dataValue = 0; }else{this.dataValue = 1; }
        }
        this.item.value = this.dataValue;
        var _this = this;
        this.save();
        if(this.dataValue){
          this.showroomService.setResistencia(true);
          this.offResistencia = setInterval(function () {
            _this.item.value = 0;
            _this.dataValue = 0;
            _this.tiempoDisabledResistencia = true;
            _this.showroomService.setResistencia(false);
            _this.save();
            clearInterval(_this.offResistencia);
          },
          30000);
          this.disabledResistencia = setInterval(function () {
            _this.save();
            _this.tiempoDisabledResistencia = false;
            clearInterval(_this.offResistencia);
            clearInterval(_this.disabledResistencia);
          },
          180000);

        }else{
          this.showroomService.setResistencia(false);
          clearInterval(_this.disabledResistencia);
          clearInterval(_this.offResistencia);
          this.tiempoDisabledResistencia = false;
        }
      }else{
        this.alertService.showMessage("", "Por favor, esperar 3 minutos para volver a activar", MessageSeverity.error);
      }
    }else{
      if(this.item.maxLimit > 0){
        if(value){this.dataValue = 0;  }else{this.dataValue = this.item.maxLimit; }
      }else{
        if(value){this.dataValue = 0; }else{this.dataValue = 1; }
      }
      if(this.item.id == 8){ this.showroomService.setBomba(this.dataValue);  } // Bomba
      if(this.item.id == 9){ this.showroomService.setValvulaSelenoide(this.dataValue);  } // Válvula Selenoide
      this.save();
    }*/
  }

  onInputChange($event: any,item:any) {
    /*this.stepIndex = +$event.value;
    var array = item.stepOptions.split(",");
    var data = parseInt(array[this.stepIndex]);
    this.dataValue = data;
    this.save();*/
  }

  onInputChangeSlider($event: any,item:any) {
    /*this.stepIndex = +$event.value;
    var array = item.stepOptions.split(",");
    var data = parseInt(array[this.stepIndex]);
    this.dataValue = data;
    this.save();*/
  }

  saveInput(item){
    var data = {
      id: item.id,
      value: this.valueInput,
      idUser: localStorage.getItem(AppConstants.Session.USERID),
      idGateway: this.idGateway,
      idActuatorType: item.idActuatorType
    };
    item.value = data.value.toString();
   this.panelIOTService.createActivity(data).subscribe((response) => {
      if(response.code == 0){
        this.panelIOTService.setUpdatePanelIOT(1);
      }else{
        this.alertService.showMessage("", response.message, MessageSeverity.error);
      }
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

}
