import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SmsViewModel, SmsByIdModel, AlertSmsPending } from '../../models/sms/sms';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Sms';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getSms(parameters: any): Observable<SmsViewModel[]> {
    let httpParams = new HttpParams({
      fromObject: parameters
    });
    return this.httpClient.get<SmsViewModel[]>(`${this.REQUEST_URL}/GetSms`, { params: httpParams });
  }

  deleteSms(smsId: number) {
    return this.httpClient.delete(`${this.REQUEST_URL}/DeleteSms/${smsId}`);
  }

  getSmsByid(enterpriseId: number, smsId: number): Observable<SmsByIdModel> {
    return this.httpClient.get<SmsByIdModel>(`${this.REQUEST_URL}/GetSmsById/` + enterpriseId + "/" + smsId);
  }

  registerSms(model: FormData) {
    return this.httpClient.post(`${this.REQUEST_URL}/CreateSms`, model);
  }

  updatedSms(model: FormData) {
    return this.httpClient.put(`${this.REQUEST_URL}/UpdateSms`, model);
  }

  getAlertByEmterprise(enterpriseId: number): Observable<AlertSmsPending[]> {
    return this.httpClient.get<AlertSmsPending[]>(`${this.REQUEST_URL}/GetAlertByEmterprise/` + enterpriseId);
  }

}
