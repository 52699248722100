<div class="container">
    <div class="row mt-2 section-name">
        <div class="col">
            Reporte de Alertas
        </div>
    </div>
    <div class="icon-close" (click)="closeModal()" >
      <mat-icon class="icon-c">close</mat-icon>
  </div>
  
    <section class="filter mt-3">
        <div class="col-12">
          <form [formGroup]="reportForm" action="">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
                  <mat-form-field class="w-100" color="primary">
                      <mat-label>Fecha inicio</mat-label>
                      <input matInput formControlName="startDateReport" readonly [matDatepicker]="picker1" maxlength="10" [(ngModel)] = "date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 ></mat-datepicker>
                      <mat-error *ngIf="f.startDateReport.hasError('required')">
                        La fecha de Inicio es <strong> obligatorio</strong>
                      </mat-error>
                      <mat-error *ngIf="f.startDateReport.hasError('pattern')">
                        La fecha de Inicio<strong> no tiene el formato correcto</strong>
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Fecha Fin</mat-label>
                    <input matInput formControlName="endDateReport" readonly [matDatepicker]="picker2" maxlength="10" [(ngModel)] = "dateF">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 ></mat-datepicker>
                    <mat-error *ngIf="f.endDateReport.hasError('required')">
                      La fecha Final es<strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.endDateReport.hasError('pattern')">
                      La fecha Final<strong> no tiene el formato correcto</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                <mat-form-field class="w-100" color="primary">
                    <mat-label>Hora inicio</mat-label>
                    <input matInput formControlName="startTimeReport" maxlength="5" [(ngModel)]="timeI" value="{{timeI}}" (keypress)="_keyUp($event)">
                    <mat-error *ngIf="f.startTimeReport.hasError('required')">
                      La Hora Inicial es<strong> obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f.startTimeReport.hasError('pattern')">
                      La Hora Inicial tiene <strong> formato incorrecto</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                  <mat-form-field class="w-100" color="primary">
                      <mat-label>Hora fin</mat-label>
                      <input matInput formControlName="endTimeReport" maxlength="5" [(ngModel)]="timeF" value="{{timeF}}" (keypress)="_keyUp($event)">
                      <mat-error *ngIf="f.endTimeReport.hasError('required')">
                        La Hora Final es <strong> obligatorio</strong>
                      </mat-error>
                      <mat-error *ngIf="f.endTimeReport.hasError('pattern')">
                        La Hora Final tiene <strong> formato incorrecto</strong>
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-lg-8 col-md-8 col-xs-12">
                    <label class="mb-1" style="color: rgba(0, 0, 0, 0.6);">Tipo de Alerta</label>
                    <ng-multiselect-dropdown
                        formControlName = "alertTypes"
                        [placeholder]="'Seleccionar'"
                        [settings]="dropdownSettings"
                        [data]="cameraAlertType"
                        [(ngModel)]="selectedItems"
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
              </div>
              <div class="col-lg-4 col-md-4">
                <button mat-raised-button class="btn-color w-100 mt-3" (click)="search()">BUSCAR</button>
              </div>
            </div>
          </form>
        </div>

        <div class="mt-4">
          <canvas id="myChart" height="350px" #chart>{{  chart  }}</canvas>
        </div>
    </section>
  
  </div>