import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { OcurrenceCamera, AlertUpdateList, OcurrenceId } from '../../models/alert/alert';
import { AppConstants } from 'src/app/shared/constants/app.contants';

@Injectable({
  providedIn: 'root'
})
export class OcurrenceService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Ocurrence';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  private POST_ENDPOINTS: string = 'Camera';
  private REQUEST_URLS: string = `${this.BASE_URL}/${this.POST_ENDPOINTS}`;

  GetListOcurrence(CameraId : number,EnterpriseId  : number): Observable<OcurrenceCamera[]> {
    return this.httpClient.get<OcurrenceCamera[]>(`${this.REQUEST_URL}/GetListOcurrence/`
      + CameraId + "/" +EnterpriseId);
  }

  GetListOcurrenceCP(data : any): Observable<OcurrenceCamera[]> {
    return this.httpClient.post<OcurrenceCamera[]>(`${this.REQUEST_URL}/GetListOcurrenceCP`,data);
  }

  GetListOcurrenceById(ocurennceId: number): Observable<OcurrenceCamera> {
    return this.httpClient.get<OcurrenceCamera>(`${this.REQUEST_URL}/GetListOcurrenceById/`
      + ocurennceId);
  }

  OcurrenceById(ocurennceId: number): Observable<OcurrenceId> {
    return this.httpClient.get<OcurrenceId>(`${this.REQUEST_URL}/getOcurrenceById/`
      + ocurennceId);
  }

  getListUpdateCamera(): Observable<AlertUpdateList> {
    return this.httpClient.get<AlertUpdateList>(`${this.REQUEST_URLS}/GetCameraUpdateColor`);
  }

  CheckError(id: number): Observable<OcurrenceId> {
    return this.httpClient.post<OcurrenceId>(`${this.REQUEST_URL}/check-error`,{id: id, idUser: localStorage.getItem(AppConstants.Session.USERID)});
  }
}
