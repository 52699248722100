import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CameraService } from 'src/app/services/camera/camera.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { MessageSeverity, AlertService } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { ModalParameterComponent } from '../parameter/parameter-modal.component';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImageModalComponent } from '../image/image-modal.component';
import { StreamingServerService } from 'src/app/services/streamingServer/streamingServer.service';
import { StreamingServerModel } from 'src/app/models/streamingServer/streamingServer';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { decryptData } from 'src/app/shared/helpers/decrypt';

@Component({
    selector: 'camera-modal',
    templateUrl: 'camera-modal.component.html',
    styleUrls: ['./camera-modal.component.css'],
    providers: [DatePipe]
})

export class CameraModalComponent implements OnInit {

  loading: boolean = false;
  cameraForm: FormGroup;
  checkStatus: boolean = false;
  zones = [];
  list = [];
  files: File[] = [];
  imageStreaming: string;
  isAdministratorStreaming=false;
  getServers: Array<StreamingServerModel>;
  myDate = new Date();
  sendAlert = 0;
  localSave: string;
  horaSave: string;
  streamingRtmp: string;
  streamingKey: string;
  live:string;
  clearImagePreviewStreaming = 0;
  headquarterSelect = 0;
  headquarters = [];
  disabledHeadquarter = false;
  disabledEnterprise = false;
  systems:any;
  titleVA = "Video Analítica";

  constructor(
    public dialogRef: MatDialogRef<CameraModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private cameraService:CameraService,
    private zoneService:ZoneService,
    private emitterService: EmitterService,
    private AlertService: AlertService,
    private serverService: StreamingServerService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private headquarterService: HeadquarterService
  ) {
    var dd = decryptData(localStorage.getItem(AppConstants.Session.ENTERPRISE_SYSTEM));
    this.systems = JSON.parse(dd);
    console.log(this.systems);
    if((this.systems.Streaming == null || this.systems.Streaming == undefined) && (this.systems.streaming == null || this.systems.streaming == undefined)){
      this.systems = JSON.parse(this.systems);
    }
    if(this.systems.va == 0 || this.systems.Va == 0){
      this.titleVA = "Datos Generales";
    }
   }

  ngOnInit() {
    this.headquarters = this.data.headquarters;
    this.setHeadquarter();
    if(this.data.action == AppConstants.OperationType.ADD){
      this.checkStatus = true;
      if(this.data.idHeadquarter != 0){
        this.headquarterSelect = this.data.idHeadquarter;
      }
    }
    this.getZonesList();
    this.localSave = this.datePipe.transform(this.myDate, 'yyyy/MM/dd');
    this.horaSave = this.datePipe.transform(this.myDate, 'HH:mm');
    this.createForm();
    this.getServersList();
    this.extractList();
  }

  setHeadquarter(){
    if(this.data.idHeadquarter > 0){
      var headquarters = JSON.parse(localStorage.getItem(AppConstants.Session.HEADQUARTERS));
      var _this = this;
      var items = headquarters.filter(x => x.idEnterprise == _this.data.enterpriseId);
      if(items.length > 0){
        _this.headquarters = items;
        if(_this.data.idHeadquarter != 0){
          _this.headquarterSelect = items[0].id;
          _this.disabledHeadquarter = true;
        }
      }
    }else{
      this.getHeadquarters();
    }
  }

  createForm() {
    /*if(
        parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.AdministradorGyS
      || parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.UsuarioGyS
      ){*/
        this.cameraForm = this.formBuilder.group({
          model: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
          serie: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
          mac: ['', [Validators.required,  Validators.maxLength(20), this.noWhitespaceValidator,
            Validators.pattern('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$')]],
          ipAdress: ['', [Validators.required, Validators.maxLength(15) , Validators.minLength(7),
                        Validators.pattern('(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
          zone: [0, []],
          camera: ['', [Validators.required, Validators.maxLength(500), this.noWhitespaceValidator]],
          //urlStreaming: ['', [Validators.required, Validators.maxLength(200), this.noWhitespaceValidator]],
          status: [, []],
          streamingDescription: ['', [Validators.maxLength(500)]],
          idServer: [0, []],
          date: [],
          ///streamingDomain: [''],
          streamingKey: [''],
          idHeadquarter: [0, [Validators.required]],
        })
    /*  }
     else if(parseInt(localStorage.getItem(AppConstants.Session.ROLID)) == AppConstants.UserRole.AdministradorEmpresaStreaming)
      {
        this.isAdministratorStreaming = true;
        this.cameraForm = this.formBuilder.group({
          model: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
          serie: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
          mac: ['', [Validators.required,  Validators.maxLength(20), this.noWhitespaceValidator]],
          ipAdress: ['', [Validators.required, Validators.maxLength(15) , Validators.minLength(7),
                        Validators.pattern('(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
          zone: [{value:'', disabled: true}, []],
          camera: ['', [Validators.required, Validators.maxLength(500), this.noWhitespaceValidator]],
          //urlStreaming: ['', [Validators.required, Validators.maxLength(200), this.noWhitespaceValidator]],
          status: [true, [Validators.required]],
          streamingDescription: ['', [Validators.maxLength(500)]],
          idServer: [{value:0, disabled: true}, []],
          date: [],
          streamingKey: [''],
          idHeadquarter: [0, [Validators.required]],
        })
      }else{
        this.cameraForm = this.formBuilder.group({
          model: [{value:'', disabled: true},[Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
          serie: [{value:'', disabled: true}, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
          mac: [{value:'', disabled: true}, [Validators.required,  Validators.maxLength(20), this.noWhitespaceValidator]],
          ipAdress: ['', [Validators.required, Validators.maxLength(15) , Validators.minLength(7),
                    Validators.pattern('(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
          zone: [0, []],
          camera: ['', [Validators.required, Validators.maxLength(500), this.noWhitespaceValidator]],
          //urlStreaming: ['', [Validators.required, Validators.maxLength(200), this.noWhitespaceValidator]],
          status: [true, [Validators.required]],
          idServer: [0, []],
          streamingDescription: ['', [Validators.maxLength(500)]],
          date: [],
          streamingKey: [''],
          idHeadquarter: [0, [Validators.required]],
      })
      }*/
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
    this.getZonesList();
  }

  setValues() {
    this.clearFields();
    this.spinner.show();
    this.cameraService.getCameraById(this.data.obj.id).subscribe((response) => {
      var port = document.location.port;
      if(port){ port = ":"+port;}
      var hostname = document.location.protocol +'//'+ document.location.hostname + ((port != "80") ? port : "");
      this.live = hostname+"/streaming/camera/" + response.token;
      this.streamingRtmp = response.streamingRtmp;
      this.streamingKey = response.streamingKey;
      this.cameraForm.patchValue({
        model:response.model,
        serie: response.serie,
        mac: response.mac,
        ipAdress: response.ipAdress,
        zone: response.zoneId,
        camera: response.camera,
        //urlStreaming: response.urlStreaming,
        status:  response.enaledId,
        streamingDescription: response.streamingDescription,
        idServer: response.idServer,
        streamingDomain: "", //response.streamingDomain,
        date: this.localSave+"T"+this.horaSave+":21.632Z",
        streamingKey: response.streamingKey,
        idHeadquarter: response.idHeadquarter,
      });
      this.checkStatus = response.enaledId;
      setTimeout(() => {
        this.headquarterSelect = response.idHeadquarter;
      },1000);
      //this.headquarterSelect = !response.idHeadquarter ? 0 : response.idHeadquarter;
      /*if(this.data.idHeadquarter == 0){
        this.getHeadquarters();
      }else{
        this.headquarterSelect = !response.idHeadquarter ? 0 : response.idHeadquarter;
        this.getZonesList();
      }*/
      this.imageStreaming = response.streamingImage;
    })
  }

  clearFields() {
    this.cameraForm.controls['model'].setValue('');
    this.cameraForm.controls['serie'].setValue('');
    this.cameraForm.controls['mac'].setValue('');
    this.cameraForm.controls['ipAdress'].setValue('');
    this.cameraForm.controls['zone'].setValue(0);
    this.cameraForm.controls['camera'].setValue('');
    this.cameraForm.controls['streamingDescription'].setValue('');
    //this.cameraForm.controls['streamingDomain'].setValue('');
    this.cameraForm.controls['idServer'].setValue('');
    //this.cameraForm.controls['urlStreaming'].setValue('');
    this.cameraForm.controls['status'].setValue(false);
    this.checkStatus = false;
  }

  getZonesList() {
    if(this.headquarterSelect>0)
    {
      this.spinner.show();
      this.zoneService.getListAll(this.data.enterpriseId,this.headquarterSelect).subscribe((response) => {
        console.log("respons ezones",response);
        if(!response.error){
          this.zones = response.data.filter(x => x.type == 1);
          if(this.data.action == AppConstants.OperationType.ADD && this.zones.length>0){
            this.cameraForm.controls['zone'].setValue(this.zones[0].id);
          }
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    }
  }

  getServersList() {
    this.spinner.show();
    this.serverService.getList(this.data.enterpriseId).subscribe((response) => {
      this.getServers = response.data;
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  extractList(){
    if(this.data.action == AppConstants.OperationType.ADD){
      this.list = this.data.listview.filteredData;

        this.cameraForm.patchValue({
          date: this.localSave+"T"+this.horaSave+":21.632Z"
        });

    }else{
      this.list = this.data.listview;
      this.setValues();
    }
  }

  openConfirmationModal() {
    if (this.cameraForm.valid) {
          this.sendAlert = 1;
          if(this.data.action == AppConstants.OperationType.ADD){
              this.save();
          }else{
              this.edit();
          }
    }else{
      this.cameraForm.markAllAsTouched();
      this.AlertService.showMessage(
        AppConstants.TitleModal.WARNING_TITLE,
        AppConstants.MessageModal.DATA_NULL_REQUIRED,
        MessageSeverity.error);
    }
  }

  openConfirmationModalSave() {
    if (this.cameraForm.valid) {
          this.sendAlert = 0;
          if(this.data.action == AppConstants.OperationType.ADD){
              this.save();
          }else{
              this.edit();
          }
    }else{
      this.cameraForm.markAllAsTouched();
      this.AlertService.showMessage(
        AppConstants.TitleModal.WARNING_TITLE,
        AppConstants.MessageModal.DATA_NULL_REQUIRED,
        MessageSeverity.error);
    }
  }

  openParameter(){
    let valueint = 0;

    if( this.checkStatus == true){
        valueint = 1;
    }else{
        valueint = 0;
    }
    const camera = {
        model:this.cameraForm.get('model').value,
        serie: this.cameraForm.get('serie').value,
        mac: this.cameraForm.get('mac').value,
        ipAdress: this.cameraForm.get('ipAdress').value,
        zone: this.cameraForm.get('zone').value,
        camera: this.cameraForm.get('camera').value,
        //urlStreaming: this.cameraForm.get('urlStreaming').value,
        status: this.cameraForm.get('status').value,
        streamingDescription: this.cameraForm.get('streamingDescription').value,
        date: this.cameraForm.get('date').value,
        idServer: this.cameraForm.get('idServer').value,
        idEnterprise: this.data.enterpriseId,
        streamingDomain: "",//this.cameraForm.get('streamingDomain').value,
        clearImagePreviewStreaming: this.clearImagePreviewStreaming,
        streamingKey: this.cameraForm.get('streamingKey').value,
        idHeadquarter: this.headquarterSelect,
        file: this.files,
        enabled: valueint
    }
    if(this.data.action == AppConstants.OperationType.ADD){
      let dialogRef = this.dialog.open(ModalParameterComponent, {
        data: {
          title: 'Alertas',
          obj: camera,
          action:this.data.action,
          enabledWrite: this.data.enabledWrite,
          enabledRead: this.data.enabledRead,
          color:this.data.color,
          colorFont: this.data.colorFont
        },
        width: '800px',
        hasBackdrop: false
      });
    }else{
      let dialogRef = this.dialog.open(ModalParameterComponent, {
        data: {
         title: 'Alertas',
         obj: camera,
         action:this.data.action,
         idcameravalue:this.data.obj.id,
         enabledWrite: this.data.enabledWrite,
         enabledRead: this.data.enabledRead,
         color:this.data.color,
         colorFont: this.data.colorFont
        },
        width: '800px',
        hasBackdrop: false
      });
    }
  }

  clickEnabled(){
    this.checkStatus = this.checkStatus ? false : true;
    this.cameraForm.controls['status'].setValue(this.checkStatus);
  }

  save() {
    let register = this.cameraForm.value;
    if(this.list != null){
      let searchData = this.list.filter(list => list.ipAdress.toString().toLowerCase().startsWith(register.ipAdress.toString().toLowerCase()))
      if (searchData.length > 0) {
        this.AlertService.showMessage(
          AppConstants.TitleModal.WARNING_TITLE,
          "El IP " + AppConstants.MessageModal.DATA_REPET_CAMERA,
          MessageSeverity.error);

        return false;
      }
      searchData = [];
      searchData = this.list.filter(list => list.camera.toLowerCase().startsWith(register.camera.toLowerCase()))
      if (searchData.length > 0) {
        this.AlertService.showMessage(
          AppConstants.TitleModal.WARNING_TITLE,
          "El nombre " + AppConstants.MessageModal.DATA_REPET_CAMERA,
          MessageSeverity.error);

        return false;
      }
    }

    if(!this.sendAlert){
      this.createCamera();
    }else{
      this.openParameter();
    }

  }

  edit(){
    let update = this.cameraForm.value;

    let searchData = this.list.filter(list => list.ipAdress.toString().toLowerCase().startsWith(update.ipAdress.toString().toLowerCase()))
    if (searchData.length > 0) {
      this.AlertService.showMessage(
        AppConstants.TitleModal.WARNING_TITLE,
        "El IP " + AppConstants.MessageModal.DATA_REPET_CAMERA,
        MessageSeverity.error);

      return false;
    }

    searchData = [];
    searchData = this.list.filter(list => list.camera.toLowerCase().startsWith(update.camera.toLowerCase()))
    if (searchData.length > 0) {
      this.AlertService.showMessage(
        AppConstants.TitleModal.WARNING_TITLE,
        "El nombre " + AppConstants.MessageModal.DATA_REPET_CAMERA,
        MessageSeverity.error);

      return false;
    }

    if(!this.sendAlert){
      this.editCamera();
    }else{
      this.openParameter();
    }

  }

  createCamera(){
    let valueint = 0;

    if( this.checkStatus == true){
        valueint = 1;
    }else{
        valueint = 0;
    }

    let create =
    {
            UserId: parseInt(localStorage.getItem(AppConstants.Session.USERID)),
            ZoneId: this.cameraForm.get('zone').value,
            Camera: this.cameraForm.get('camera').value,
            Model: this.cameraForm.get('model').value,
            Serie: this.cameraForm.get('serie').value,
            Mac: this.cameraForm.get('mac').value,
            Ip: this.cameraForm.get('ipAdress').value,
            IdEnterprise: this.data.enterpriseId,
            Url: '',
            Enabled: valueint,
            DateRegister: this.cameraForm.get('date').value,
            CameraAlerts: [],
            StreamingDescription: this.cameraForm.get('streamingDescription').value,
            streamingDomain: "",//this.cameraForm.get('streamingDomain').value,
            IdServer: this.cameraForm.get('idServer').value,
            ClearImagePreviewStreaming: this.clearImagePreviewStreaming,
            SendAlert: 0,
            idHeadquarter: this.headquarterSelect
    }

    let formData = new FormData();
    formData.append('data', JSON.stringify(create));
    formData.append('file', this.files[0]);
    this.cameraService.registerCamera(formData).subscribe(
            result => {
              if(!result.message){
                this.AlertService.showMessage(
                  AppConstants.TitleModal.CREATE_CAMERA,
                  AppConstants.MessageModal.CREATE,
                  MessageSeverity.success);
                  this.data.result = true;
                  this.dialogRef.close(this.data);
                this.emitterService.sendMessage(AppConstants.EmitterService.ListCamera);
              }else{
                this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, result.message, MessageSeverity.error);
              }
              this.spinner.hide();
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
    });
  }

  getHeadquarters() {
      this.spinner.show();
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.data.enterpriseId);
          console.log("enterprises",this.headquarters[0]);
          if(this.data.obj != null){
            this.headquarterSelect = this.data.obj.idHeadquarter;
          }else{
            console.log("enterprises",this.headquarters[0].id);
            this.headquarterSelect = this.headquarters[0].id;
          }
          console.log("idHeadquarter",this.headquarterSelect);
        //  this.cameraForm.controls['idHeadquarter'].setValue(this.headquarterSelect);
          this.getZonesList();
        }else{
          this.headquarters = [];
        }
      },
        (error: HttpErrorResponse) => {
          this.headquarters = [];
          this.spinner.hide();
        });
  }


  editCamera(){
    let valueint = 0;

    if( this.checkStatus == true){
        valueint = 1;
    }else{
        valueint = 0;
    }

    if(this.cameraForm.valid){
      let update =
      {
              CameraId: this.data.obj.id,
              UserId: parseInt(localStorage.getItem(AppConstants.Session.USERID)),
              ZoneId: this.cameraForm.get('zone').value,
              Camera: this.cameraForm.get('camera').value,
              Model: this.cameraForm.get('model').value,
              Serie: this.cameraForm.get('serie').value,
              Mac: this.cameraForm.get('mac').value,
              Ip: this.cameraForm.get('ipAdress').value,
              idEnterprise: parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE)),
              Url: '',
              Enabled: valueint,
              DateRegister: this.cameraForm.get('date').value,
              CameraAlerts: [],
              StreamingDescription: this.cameraForm.get('streamingDescription').value,
              IdServer: this.cameraForm.get('idServer').value,
              StreamingKey: this.cameraForm.get('streamingKey').value,
              ClearImagePreviewStreaming: this.clearImagePreviewStreaming,
              streamingDomain: "",//this.cameraForm.get('streamingDomain').value,
              SendAlert: 0,
              idHeadquarter: this.headquarterSelect
      }
      let formData = new FormData();
      formData.append('data', JSON.stringify(update));
      formData.append('file', this.files[0]);
      this.spinner.show();
      this.cameraService.updatedCamera(formData).subscribe(
              result => {
                if(!result.message){
                  this.AlertService.showMessage(
                    AppConstants.TitleModal.UPDATE_CAMERA,
                    AppConstants.MessageModal.UPDATE,
                    MessageSeverity.success);
                    this.data.result = true;
                    this.dialogRef.close(this.data);
                  this.emitterService.sendMessage(AppConstants.EmitterService.ListCamera);
                }else{
                  this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, result.message, MessageSeverity.error);
                }
                this.spinner.hide();
              },
              (error: HttpErrorResponse) => {
                this.spinner.hide();
                this.AlertService.showMessage(AppConstants.TitleModal.ERROR_TITLE, error.error.messages, MessageSeverity.error);
      });
    }else{
      this.cameraForm.markAllAsTouched();
    }


  }

  isNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  get f() { return this.cameraForm.controls; }

  close() {
    this.dialog.closeAll();
  }

  copy(val){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.AlertService.showMessage(
      "Se copio el texto correctamente","",
      MessageSeverity.success);
  }

  onFileSelected(event) {
    var files = event.target.files;
    if (files.length > 0) {
      var file = files[0];
      if (file.type == "image/png" || file.type == "image/jpeg") {
        this.files.push(file);
      } else {
        this.AlertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
      }
      event.srcElement.value = '';
    }
  }

  showImageStreaming(){
    let dialogRef = this.dialog.open(ImageModalComponent, {
      data: { image: this.imageStreaming},
      width: '800px',
      hasBackdrop: false
    });
  }

  deleteImageStreaming(){
    this.clearImagePreviewStreaming = 1;
    this.imageStreaming = "";
  }

}
