import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { EnterpriseService } from 'src/app/services/enterprise/enterprise.service';
import { HeadquarterService } from 'src/app/services/headquarter/headquarter.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { ImageModalComponent } from 'src/app/shared/components/modals/image/image-modal.component';
import { ZoneModalComponent } from 'src/app/shared/components/modals/zone/zone-modal.component';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { environment } from 'src/environments/environment';
import { EnterprisePinListModalComponent } from 'src/app/shared/components/modals/zone/enterprise-pin-list-modal/enterprise-pin-list-modal.component';
import { PinModalComponent } from 'src/app/shared/components/modals/zone/enterprise-pin-modal/pin-modal.component';
import { EnterprisePinMaps } from 'src/app/models/enterprise/enterprisePinMaps';
import { MatTabChangeEvent } from '@angular/material';
import { Router } from '@angular/router';
import { decryptData } from 'src/app/shared/helpers/decrypt';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.css']
})
export class ZoneComponent implements OnInit {


  loading: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['index','headquarter','type','name','description','nomenclature','action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource2 = new MatTableDataSource();
  displayedColumns2: string[] = ['headquarter','name', 'action'];
  @ViewChild(MatPaginator, { static: false }) paginator2: MatPaginator;
  isAdminGys: boolean = false;
  countMaxUserByEnterprise: number = 0;
  enterprises = [];
  headquarters = [];
  subscription: Subscription;
  enterpriseSelect: number = 0;
  headquarterSelect: number = 0;
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION;
  length: number = 0;
  page: number = 1;
  listPinMaps=[];
  pinMapfiles=[];
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  tab = 0;
  searchText: string = '';
  enabledRead: number;
  enabledWrite: number;
  timer:any;
	timeoutVal = 800;
  systems:any;

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private enterpriseService: EnterpriseService,
    private spinner: NgxSpinnerService,
    private emitterService: EmitterService,
    private router: Router,
    private headquarterService: HeadquarterService,
    private zoneService: ZoneService,
    ) {
      document.getElementById("titleHeaderNav").innerHTML = 'Administración > <span class="font-weight-bold">Zonas</span>';
      this.systems = JSON.parse(decryptData(localStorage.getItem(AppConstants.Session.ENTERPRISE_SYSTEM)));
      console.log(this.systems);
      if((this.systems.Iot == null || this.systems.Iot == undefined) && (this.systems.iot == null || this.systems.iot == undefined)){
        this.systems = JSON.parse(this.systems);
      }
    }

  ngOnInit() {
    this.setLS();
    this.isAdminGys = (+localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.AdministradorGyS || +localStorage.getItem(AppConstants.Session.ROLID) == AppConstants.UserRole.UsuarioGyS) ? true : false;
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
    this.enterpriseSelect = (+localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.headquarterSelect = (+localStorage.getItem(AppConstants.Session.HEADQUARTER));
    this.getList();
    this.getListPines();
    this.subscription = this.emitterService.getMessage().subscribe(message => {
      if (message == AppConstants.EmitterService.ListUsers) {
        this.getList();
      }
      if (message == AppConstants.EmitterService.ListUsersEdit) {
        this.getList();
      }
    });
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  changeEnterprise(data) {
    this.enterpriseSelect = data.value;
    this.getHeadquarters();
    this.headquarterSelect = 0;
    this.getList();
    this.getListPines();
  }

  changeHeadquarter(data) {
    this.headquarterSelect = data.value;
    this.getList();
    this.getListPines();
  }

  getEnterprises() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.enterpriseService.getEnterprises().subscribe((response) => {
        this.enterprises = response.filter(f => f.enabled == AppConstants.Flag.Active);
        this.enterpriseSelect = this.enterprises[0]["enterpriseId"];
        this.getHeadquarters();
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
        });
    } else {
      this.enterpriseSelect = +localStorage.getItem(AppConstants.Session.ENTERPRISE);
      this.getHeadquarters();
    }
  }

  getHeadquarters() {
    if (this.isAdminGys) {
      this.spinner.show();
      this.headquarterService.getListAll().subscribe((response) => {
        if(response.error == null){
          this.headquarters = response.data.filter(f => f.idEnterprise == this.enterpriseSelect);
          if(this.headquarters.length == 1){
            this.headquarterSelect = this.headquarters[0]["id"];
          }else{
            this.headquarterSelect = 0;
          }
          this.getList();
          this.getListPines();
        }else{
          this.headquarters = [];
        }
        this.spinner.hide();
      },
        (error: HttpErrorResponse) => {
          this.headquarters = [];
          this.spinner.hide();
        });
    } else {
      this.headquarterSelect = +localStorage.getItem(AppConstants.Session.HEADQUARTER)
      this.getList();
      this.getListPines();
    }
  }

  getEnterpriseById(enterpriseId: number) {
    this.spinner.show();
    this.enterpriseService.getEnterpriseById(enterpriseId).subscribe((response => {
      this.countMaxUserByEnterprise = response.maximumUsers;
      this.spinner.hide();
    }),
    (error: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getList(){
    var filter = {
      Page: this.page,
      Rows: AppConstants.Paginado.DEFAULT_PAGE_SIZE_PAGINATION,
      IdEnterprise: this.enterpriseSelect,
      IdHeadquarter: this.headquarterSelect,
      Filter: this.searchText
    };
    this.spinner.show();
    this.zoneService.getList(filter).subscribe((_) => {
      if(_.error == null){
        this.dataSource = new MatTableDataSource(_["rows"]);
        this.pageIndex = +_["page"]-1;
        this.length = _["total"];
      }
      this.spinner.hide();
    });
  }

  getServerData(event?: PageEvent) {
    this.page = event.pageIndex + 1;
    this.getList();
  }

  openPinMapModal() {
    let dialogRef = this.dialog.open(EnterprisePinListModalComponent, {
      data: {
        title: AppConstants.TitleModal.LIST_PIN_MAP_TITLE,
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        headquarters: this.headquarters,
        color: this.color1,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        colorFont: this.color2,
        obj:null},
      width: '500px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        this.getList();
        this.getListPines();
      }
    });
  }

  openAddModal() {
    let dialogRef = this.dialog.open(ZoneModalComponent, {
      data: {
        title: AppConstants.TitleModal.CREATE_ZONE,
        action: AppConstants.OperationType.ADD,
        headquarters: this.headquarters,
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        isAdminGys: this.isAdminGys ,
        pines: this.listPinMaps,
        color: this.color1,
        colorFont: this.color2,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        systems: this.systems,
        obj:null},
      width: '500px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        this.getList();
        this.getListPines();
      }
    });
  }

  openEditModal(data){
      let dialogRef = this.dialog.open(ZoneModalComponent, {
        data: {
          title: AppConstants.TitleModal.UPDATE_ZONE,
          action: AppConstants.OperationType.UPDATE,
          headquarters: this.headquarters,
          idHeadquarter: this.headquarterSelect,
          idEnterprise: this.enterpriseSelect,
          isAdminGys: this.isAdminGys,
          color: this.color1,
          systems:this.systems,
          colorFont: this.color2,
          enabledWrite: this.enabledWrite,
          enabledRead: this.enabledRead,
          obj:data},
        width: '500px',
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(_ => {
        if (_.result == true) {
          this.getList();
          this.getListPines();
        }
      });
  }

  delete(obj) {
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
        message: `${AppConstants.MessageModal.DELETE_DEFAULT}`,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteSecurity(obj.id);
      }
    });

  }

    deleteSecurity(id: number) {
      this.spinner.show();
      this.zoneService.delete(id).subscribe((response => {
        this.spinner.hide();
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_ZONE, AppConstants.MessageModal.DELETE, MessageSeverity.success);
        this.getList();
      }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_ZONE, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
    }

  showImage(element){
    var path = element.image;
    if(path != null && path != ""){
      this.spinner.show();
        var idStorageFile = element.idStorageTypeBlob;
        if(idStorageFile == 1){ // Mode Local
          path = element.storagePathBlob + path;
        }
        this.dialog.open(ImageModalComponent, {
            data: {
              title: AppConstants.TitleModal.UPDATE_ZONE,
              image: path
            },
            width: '500px',
            hasBackdrop: true
        });
        this.spinner.hide();

    }else{
      this.alertService.showMessage("", "No tiene imagen subida en esta ubicación", MessageSeverity.error);
    }
  }


  getListPines(){
    this.spinner.show();
    var filter = {
      IdEnterprise: this.enterpriseSelect,
      IdHeadquarter: this.headquarterSelect
    };
    this.zoneService.getListPines(filter).subscribe((response => {
      this.listPinMaps = response.data;
      this.dataSource2.data = this.listPinMaps;
      this.spinner.hide();
    }),
    (error: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  onFilePinMapSelected(event) {
    var files = event.target.files;
    if (files.length > 0) {
      var file = files[0];
      if (file.type == "image/png" || file.type == "image/jpeg") {
        console.log(this.listPinMaps);
        if (this.listPinMaps.filter(f => f.name == this.convertName(file.name)).length == 0) {
          var model = new EnterprisePinMaps();
          model.name = this.convertName(file.name);
          model.file = file;
          this.listPinMaps.push(model);
          this.pinMapfiles.push(file);
          this.dataSource.data = this.listPinMaps;
        } else {
          this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_PINMAP_DUPLICATE, MessageSeverity.warn);
        }
      } else {
        this.alertService.showMessage(AppConstants.TitleModal.UPLOAD_FILES, AppConstants.MessageModal.UPLOAD_FILE_INCORRECT, MessageSeverity.warn);
      }
      event.srcElement.value = '';
    }
  }

  convertName(name: string) {
    return name.split('.').slice(0, -1).join();
  }

  deletePinMap(obj){
    this.spinner.show();
    var data = {
      IdUser: localStorage.getItem(AppConstants.Session.USERID),
      Id: obj.id
    }
    this.zoneService.deletePin(data).subscribe((response => {
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_PIN, AppConstants.MessageModal.DELETE, MessageSeverity.success);
      this.getListPines();
      this.spinner.hide();
    }),
      (error: HttpErrorResponse) => {
        this.alertService.showMessage(AppConstants.TitleModal.DELETE_PIN, error.error.messages, MessageSeverity.error);
        this.spinner.hide();
      });
  }

  showImagePin(element){
    console.log(element);
    var path = element.path;
    if(path != null && path != ""){
        if(element.idStorageFile == 1){ // Mode Local
          path = environment.pathFile + path;
        }
        this.dialog.open(ImageModalComponent, {
            data: {
              title: AppConstants.TitleModal.UPDATE_ZONE,
              image: path},
            width: '500px',
            hasBackdrop: true
        });
    }else{
      this.alertService.showMessage("", "No tiene imagen subida", MessageSeverity.error);
    }
  }

  addModalPin(){
    let dialogRef = this.dialog.open(PinModalComponent, {
      data: {
        title: AppConstants.TitleModal.CREATE_PIN,
        idEnterprise: this.enterpriseSelect,
        idHeadquarter: this.headquarterSelect,
        headquarters: this.headquarters,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        action: AppConstants.OperationType.ADD,
        color: this.color1,
        colorFont: this.color2
      },
      width: '500px',
      hasBackdrop: true
  });
  dialogRef.afterClosed().subscribe(_ => {
    if (_.result == true) {
      this.getListPines();
    }
  });

  }


  applyFilterPin(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  applyUpFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchText = filterValue.trim().toLowerCase();
      var _this = this;
      window.clearTimeout(_this.timer);
        _this.timer = window.setTimeout(() => {
            _this.page = 1;
            _this.paginator.pageIndex = 0;
            _this.getList();
          }, _this.timeoutVal);
  }

  applyPressFilter(event: Event) {
    var _this = this;
    window.clearTimeout(_this.timer);
  }

  onTabChange(event: MatTabChangeEvent) {
    this.tab = event.index;
  }
}
