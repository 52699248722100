<div class="row title-modal">
    <div class="col-10">
      {{ model }}
    </div>
      <div class="col-2 text-right">
          <mat-icon color="primary" (click)="closeModal()">close</mat-icon>
      </div>
  </div>
  <mat-dialog-content class="mat-typography mt-3">
      <div class="container" style="border-color:transparent">
          <div class="row">
              <div class="col-12 col-md-3 containerLeft">
                  <div class="header">
                      <h3><span>Datos Alerta</span></h3>
                  </div>
                  <div class="mainbody mb-3">
                      <label class="lblBody">Cámara: <span>{{model}}</span></label>
                      <label class="lblBody">Alerta: <span>{{alertName}}</span></label>
                      <label class="lblBody">Fecha: <span>{{fecha}}</span></label>
                      <label class="lblBody">Hora: <span>{{hora}}</span></label>
                  </div>
                  <div class="mainbody mb-3" style="text-align: -webkit-center;" *ngIf="state != 3 && error == '0'"> <!--  class="button-attend" -->
                      <button mat-raised-button color="primary" class="align-self-end" (click)="openModal2()">{{messageButton}}</button>
                      <button class="mat-raised-button2" (click)="errorAlert()">MARCAR FALSO POSITIVO</button>
                  </div> 
              </div>
              <div class="img-col col-12 col-md-9 main text-center mt-3">
                  <!--<img id="image_background"  [src]="path" />-->
                      <div id="container" class="img-fluid"></div>
                      <div id="controls">
                      <button id="zoomout">-</button>
                      <input id="zoomlevel" type="range">
                      <button id="zoomin">+</button>
                      </div>
              </div>
          </div>
      </div>
      <div class="left-alert" (click)="leftAlert()" *ngIf="data.beforeId > 0" >
          <mat-icon style="font-size: 125px;">chevron_left</mat-icon>
      </div>
      <div class="right-alert" (click)="rightAlert()" *ngIf="data.afterId > 0" >
          <mat-icon style="font-size: 125px;">chevron_right</mat-icon>
      </div>
      
  </mat-dialog-content>
  <mat-dialog-actions align="end">
  
  </mat-dialog-actions>
  <loading *ngIf="loading"></loading>