export class CameraZoneViewModel {
  cameraId: number;
  camera: string;
  cameraPosX: number;
  cameraPosY: number;
  cameraColor: string;
  enabled: number;
  idPinMap: number;
  id_Zone: number;
  idStorageType: number;
  pinMap: string;
  oppositeMapDirection: string;
  pathPinMap: string;
}

export class CameraZoneUpdate {
  cameras: Array<CameraZoneViewModel>;
  userUpdate: string;
}

//export class CameraZoneUbicacion {
//  cameraId: number;
//  cameraColor: string;
//  cameraPosX: number;
//  cameraPosY: number;
//}
