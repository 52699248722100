import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetStreamingServer } from 'src/app/models/streamingServer/getStreamingServer';

@Injectable({
  providedIn: 'root'
})
export class StreamingServerService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'StreamingServer';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getList(idEnterprise: number): Observable<GetStreamingServer> {
    return this.httpClient.get<GetStreamingServer>(`${this.REQUEST_URL}/${idEnterprise}`);
  }

  create(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}`,data);
  }

  update(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}`,data);
  }

  delete(id: number){
    return this.httpClient.delete<any>(`${this.REQUEST_URL}/` + id);
  }
}
