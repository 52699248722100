<div class="row title-modal">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon color="primary" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
      <form [formGroup]="securityForm" action="">
        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Correo</mat-label>
                    <input matInput formControlName="email" maxlength="500">
                    <mat-error *ngIf="f.email.hasError('required') || f.email.hasError('whitespace')">
                        El Correo es <strong>requerida</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCELAR</button>
  <button mat-raised-button color="primary" (click)="save()">GUARDAR</button>
</mat-dialog-actions>
