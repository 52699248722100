
<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-12 col-lg-6 section-left" [style.background]="color1">
      <section class="row h-100">
        <div class="col-12 my-auto section-message">
          <div class="row justify-content-center logo-example">
            <div class="d-none d-md-block">
              <img src="../../../{{logo}}" class="img-fluid logo-gs" alt="">
            </div>
          </div>
          <div class="row justify-content-center welcome mt-2">
            <div class="d-none d-md-block">
              ¡Bienvenido!
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-6 d-none d-md-block">
              <hr class="separator">
            </div>
          </div>
          <div class="row justify-content-center platform-name">
            {{titleLogin}}
          </div>
        </div>
      </section>
    </div>
    <div class="col-12 col-lg-6 align-self-center">
      <div class="container" [style.color]="color1">
        <section>
          <div class="row">
            <div class="col pb-2">
              <h2>{{titleForm}}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              {{descriptionForm}}
            </div>
          </div>
          <div class="row mt-2">
            <div class="form col-lg-12 col-md-12" *ngIf="showLogin">
              <form [formGroup]="form" action="">
               <!-- <mat-form-field class="col-12">
                  <mat-label>Código de empresa</mat-label>
                  <mat-icon matPrefix >person</mat-icon>
                  <input matInput OnlyNumbers maxlength="6">
                  <mat-error *ngIf="f.username.hasError('required')">
                    El código es <strong>requerido</strong>.
                  </mat-error>
                  <mat-error *ngIf="f.username.hasError('minlength')">
                    El código es de <strong>6 dígitos</strong>.
                  </mat-error>
                </mat-form-field>-->
                <mat-form-field class="col-12">
                  <mat-label>Correo electrónico</mat-label>
                  <mat-icon matPrefix >portrait</mat-icon>
                  <input  matInput formControlName="username"  >
                  <mat-error *ngIf="f.username.touched && f.username.invalid" class="error-input">
                    * El usuario es requerido
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-12">
                  <mat-label>Contraseña</mat-label>
                  <mat-icon matPrefix >lock</mat-icon>
                  <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" minlength="6" maxlength="20" >
                  <mat-icon matSuffix (click)="hide = !hide" class="icon-cursor" >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-error *ngIf="f.password.touched && f.password.invalid" class="error-input">
                    * La contraseña es requerida
                  </mat-error>
                </mat-form-field>
              </form>
            </div>
            <div class="form col-lg-12 col-md-12" *ngIf="!showLogin">
              <form [formGroup]="formPassword" action="">
               <!-- <mat-form-field class="col-12">
                  <mat-label>Código de empresa</mat-label>
                  <mat-icon matPrefix >person</mat-icon>
                  <input matInput OnlyNumbers maxlength="6">
                  <mat-error *ngIf="f.username.hasError('required')">
                    El código es <strong>requerido</strong>.
                  </mat-error>
                  <mat-error *ngIf="f.username.hasError('minlength')">
                    El código es de <strong>6 dígitos</strong>.
                  </mat-error>
                </mat-form-field>-->
                <mat-form-field class="col-12">
                  <mat-label>Correo electrónico</mat-label>
                  <mat-icon matPrefix >portrait</mat-icon>
                  <input  matInput formControlName="username"  >
                  <mat-error *ngIf="fPassword.username.touched && fPassword.username.invalid" class="error-input">
                    * El usuario es requerido
                  </mat-error>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div class="alert alert-danger" *ngIf="error">
            {{error}}
          </div>
          <div class="alert alert-success" *ngIf="success">
            {{success}}
          </div>
          <span *ngIf="showLogin" (click)="showPassword()" class="span-click" >¿Olvidaste tu contraseña?, haz click aquí</span>
          <span *ngIf="!showLogin" (click)="showSignIn()" class="span-click" >Iniciar sesión, haz click aquí</span>

          <div class="row justify-content-center pt-5">
            <div class="col-lg-12 col-xs-12 mb-1">
              <button *ngIf="showLogin" mat-raised-button color="accent" class="btn-login w-100" (click)="login()" [style.background]="color2">INICIAR SESIÓN</button>
              <button *ngIf="!showLogin" mat-raised-button color="accent" class="btn-login w-100" (click)="password()" [style.background]="color2">ENVIAR CONTRASEÑA</button>
            </div>
            <!--<div class="col-lg-12 col-xs-12 divMS">
                <button class="btn btn-ms" type="button" (click)="loginMS()">
                  <div>
                    <img src="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" >
                  </div>
                  <span class="ml-2">INICIAR SESIÓN MICROSOFT</span>
                </button>
            </div>-->
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
