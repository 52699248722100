import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetEnterpriseSetting } from 'src/app/models/enterprise/getEnterpriseSetting';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseSettingService {

  constructor(
    private httpClient: HttpClient
  ) {}

  private POST_ENDPOINT: string = 'EnterpriseSetting';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getEnterpriseSetting(enterpriseId: number): Observable<GetEnterpriseSetting> {
    return this.httpClient.get<GetEnterpriseSetting>(`${this.REQUEST_URL}/${enterpriseId}`);
  }

  updateEnterpriseSetting(model: FormData): Observable<Error> {
    return this.httpClient.put<Error>(`${this.REQUEST_URL}`, model);
  }
}
