
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { PanelIOTService } from 'src/app/services/panel-iot/panel-iot.service';
import { DashboardModalComponent } from 'src/app/shared/components/modals/dashboard/dashboard-modal.component';
import { WidgetModalComponent } from 'src/app/shared/components/modals/dashboard/widget-modal/widget-modal.component';
import { MessageModalComponent } from 'src/app/shared/components/modals/message/message-modal.component';
import { ModalReportSensorPbiShowRoomComponent } from 'src/app/shared/components/modals/showroom/modal-report-sensor-pbi/modal-report-sensor-pbi-show-room.component';
import { AppConstants } from 'src/app/shared/constants/app.contants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  color1: string;
  color2: string;
  colorFont: string;
  colorFont2: string;
  enabledRead: number;
  enabledWrite: number;
  dashboards = [];
  enterpriseSelect: number;
  contentDashboard = false;
  enabledEditWidget = false;
  newitemDashboard = false;
  dashboardSelect: number;
  widgets = [];
  initListDashboards = [];
  listDashboards2 = [];
  isPhone = false;
  isTablet = false;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private router: Router,
    private panelIOTService: PanelIOTService,
    private dashboardService: DashboardService) {
    document.getElementById("titleHeaderNav").innerHTML = '<span class="font-weight-bold">Dashboard</span>';
    const userAgent: string = navigator.userAgent || navigator.vendor;
    const isMobileDevice = (): boolean => {
      const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
      return regexs.some((b) => userAgent.match(b))
    }
    const isTabletDevice = (): boolean => {
      const regexs = [/iPad/i, /iPod/i, /tablet/i, /tablet|ipad/i]
      return regexs.some((b) => userAgent.match(b))
    }
  this.isPhone = isMobileDevice();
  this.isTablet = isTabletDevice();
  }

  ngOnInit():void {
    this.enterpriseSelect = parseInt(localStorage.getItem(AppConstants.Session.ENTERPRISE));
    this.setLS();
    //this.getList();
  }

  ngAfterViewInit(){
    this.getList();
  }

  ngOnDestroy() {
    /*if (this.chart) {
      this.AmCharts.destroyChart(this.chart);
    }*/
  }

  getList(){
    this.spinner.show();
    this.dashboardService.getListAll(this.enterpriseSelect).subscribe((_) => {
      if(_.error == null){
        this.dashboards = [];
        this.listDashboards2 = [];
        this.initListDashboards = _.data;
        if(this.initListDashboards.length > 0){
          if(this.newitemDashboard){
            this.newitemDashboard = false;
            this.dashboardSelect = this.initListDashboards[this.initListDashboards.length-1].id;
          }else{
            this.dashboardSelect = this.initListDashboards[0].id;
          }
          if(!this.isPhone && !this.isTablet){
            this.dashboards = this.initListDashboards.filter((element,key) => key < 8);
            if(this.initListDashboards.length > 8){
              this.listDashboards2 = this.initListDashboards.filter((element,key) => key >= 8);
            }
          }else if(this.isTablet){
            this.dashboards = this.initListDashboards.filter((element,key) => key < 5);
            if(this.initListDashboards.length > 6){
              this.listDashboards2 = this.initListDashboards.filter((element,key) => key >= 5);
            }
          }else if(this.isPhone){
            this.dashboards = this.initListDashboards.filter((element,key) => key < 2);
            if(this.initListDashboards.length > 2){
              this.listDashboards2 = this.initListDashboards.filter((element,key) => key >= 2);
            }
          }
          this.getListWidgets();
        }else{
          this.spinner.hide();
        }
      }else{
        this.spinner.hide();
      }
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  getListWidgets(){
    this.spinner.show()
    this.dashboardService.getListAllWidgets(this.dashboardSelect).subscribe((_) => {
      if(_.error == null){
        this.widgets = _.data;
        console.log("widgets",_.data);
        this.contentDashboard = true;
        /*d.forEach(_ => {
          //_.widgetPosition = JSON.parse(_.position);
          var body = JSON.parse(_.contentData);
          var ii = 0;
          var datasets = [];
          body.forEach(c => {
            _this.panelIOTService.getSensorPanelIOT(c.sensor).subscribe((__) => {

              if(_.idWidgetType == 1){ // LINEAL
                // Set Labels
                var head = JSON.parse(_.contentHead);
                var labels = [];
                for(var i=0; i<= head.hourX;i++){
                  labels.push(i);
                }
                _.labels = labels;
                datasets.push({
                  label: __.nomenclature,
                  data: [],//arrayData,
                  backgroundColor: c.color
                });
              }else if(_.idWidgetType == 2){ //BAR
                  var labels = [];
                  labels.push(c.name);
                  _.labels = labels;
                  datasets.push({
                    data: [0],
                    backgroundColor: [c.color],
                    borderColor: [c.color],
                    borderWidth: 1
                  });
              }
              _.datasets = datasets;
              ii++;
              console.log("validate ",d.length+" == "+index+" && "+body.length+" == "+ii);
              if(d.length == index && !_this.contentDashboard){
                console.log("entrooo mostrar");
                _this.showContent(d);
                return false;
              }
            });
          });
          index++;
          /*
          _this.contentDashboard = true;
          _this.showContent(d);
        });*/
      }else{
        this.widgets = [];
      }
      this.spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }

  changeMoreDashboard(item){
    if(!this.isPhone && !this.isTablet){
      this.dashboards = this.initListDashboards.filter((element,key) => element.id >= item.id).slice(0, 8);
      if(this.initListDashboards.length > 8){
        this.listDashboards2 = this.initListDashboards.filter((element,key) => element.id != item.id && !this.searchArray(this.dashboards,element.id));
      }
    }else if(this.isTablet){
      this.dashboards = this.initListDashboards.filter((element,key) => element.id >= item.id).slice(0, 6);
      if(this.initListDashboards.length > 6){
        this.listDashboards2 = this.initListDashboards.filter((element,key) => element.id != item.id && !this.searchArray(this.dashboards,element.id));
      }
    }else if(this.isPhone){
      this.dashboards = this.initListDashboards.filter((element,key) => element.id >= item.id).slice(0, 2);
      if(this.initListDashboards.length > 2){
        this.listDashboards2 = this.initListDashboards.filter((element,key) => element.id != item.id && !this.searchArray(this.dashboards,element.id));
      }
    }
    this.dashboardSelect = item.id;
    this.getListWidgets();
  }

  searchArray(array, idItem){
    return  array.filter(x => x.id == idItem).length > 0 ? true:false;
  }


  selectDashboard(i){
    this.dashboardSelect = i;
    this.spinner.show();
    console.log("select dashboard");
    this.getListWidgets();
    this.contentDashboard = false;
    this.getContent(0);
  }

  setLS(){
    this.color1 = localStorage.getItem(AppConstants.Session.THEME_COLOR1);
    this.color2 = localStorage.getItem(AppConstants.Session.THEME_COLOR2);
    this.colorFont = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT);
    this.colorFont2 = localStorage.getItem(AppConstants.Session.THEME_COLOR_FONT2);
    //validate Permisses menu
    var menu = JSON.parse(localStorage.getItem(AppConstants.Session.MENU));
    var page = this.router.url.split("/");
    var i = page[page.length-1];
    var itemTag = menu.filter(x => x.tag != null && (x.tag.toString()).toLowerCase().includes(i));
    if(itemTag.length>0){
      this.enabledRead = itemTag[0].activeRead;
      this.enabledWrite = itemTag[0].activeWrite;
    }else{
      this.enabledRead = 0;
      this.enabledWrite = 0;
    }
  }

  getContent(id){
    this.contentDashboard = true;
  }

  addWidget(){
    let dialogRef = this.dialog.open(WidgetModalComponent, {
      width: '1200px',
      data:{
        title: AppConstants.TitleModal.CREATE_WIDGET,
        action: AppConstants.OperationType.ADD,
        idEnterprise: this.enterpriseSelect,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        color: this.color1,
        colorFont: this.color2,
        idDashboard: this.dashboardSelect
      }
    });

    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        this.selectDashboard(this.dashboardSelect);
      }
    });
  }

  /*

  ngAfterViewInit(){
    var _this = this;
    var chartColors = {
	red: 'rgb(255, 99, 132)',
	orange: 'rgb(255, 159, 64)',
	yellow: 'rgb(255, 205, 86)',
	green: 'rgb(75, 192, 192)',
	blue: 'rgb(54, 162, 235)',
	purple: 'rgb(153, 102, 255)',
	grey: 'rgb(201, 203, 207)'
};

function randomScalingFactor() {
	return (Math.random() > 0.5 ? 1.0 : -1.0) * Math.round(Math.random() * 100);
}


function onRefresh(chart) {
	chart.config.data.datasets.forEach(function(dataset) {
		dataset.data.push({
			x: Date.now(),
			y: randomScalingFactor()
		});
	});
}

var color = Chart.helpers.color;
var config = {
	type: 'line',
	data: {
		datasets: [{
			label: 'Dataset 1 (linear interpolation)',
			backgroundColor: color(chartColors.red).alpha(0.5).rgbString(),
			borderColor: chartColors.red,
			fill: false,
			lineTension: 0,
			borderDash: [8, 4],
			data: []
		}, {
			label: 'Dataset 2 (cubic interpolation)',
			backgroundColor: color(chartColors.blue).alpha(0.5).rgbString(),
			borderColor: chartColors.blue,
			fill: false,
			cubicInterpolationMode: 'monotone',
			data: []
		}]
	},
	options: {
		title: {
			display: true,
			text: 'Line chart (hotizontal scroll) sample'
		},
		scales: {
			xAxes: [{
				type: 'realtime',
				realtime: {
					duration: 20000,
					refresh: 1000,
					delay: 2000,
					onRefresh: onRefresh
				}
			}],
			yAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'value'
				}
			}]
		},
		tooltips: {
			mode: 'nearest',
			intersect: false
		},
		hover: {
			mode: 'nearest',
			intersect: false
		}
	}
};


    var canvas  = <HTMLCanvasElement> document.getElementById('myChart');
    var ctx = canvas.getContext('2d');
    console.log("chart ", ctx);
    _this.chart = new Chart(ctx, config);


  }*/

  showPBI(){
    let dialogRef = this.dialog.open(ModalReportSensorPbiShowRoomComponent, {
      width: '1000px',
    });
  }

  newDashboard(){
    let dialogRef = this.dialog.open(DashboardModalComponent, {
      width: '500px',
      data:{
        title: AppConstants.TitleModal.CREATE_DASHBOARD,
        action: AppConstants.OperationType.ADD,
        idEnterprise: this.enterpriseSelect,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        color: this.color1,
        colorFont: this.color2,
      }
    });
    var _this = this;
    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        _this.newitemDashboard = true;
        _this.getList();
      }
    });
  }

  onWidgetChange(event: any) {
    console.log(event);
  }

  openEditModal(data){
    let dialogRef = this.dialog.open(DashboardModalComponent, {
      data: {
        title: AppConstants.TitleModal.UPDATE_DASHBOARD,
        action: AppConstants.OperationType.UPDATE,
        idEnterprise: this.enterpriseSelect,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        obj:data,
        color: this.color1,
        colorFont: this.color2,
      },
      width: '500px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        if (_.result == true) {
          this.selectDashboard(this.dashboardSelect);
        }
      }
    });
}

delete(obj) {
  let dialogRef = this.dialog.open(MessageModalComponent, {
    data: {
      title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
      message: `${AppConstants.MessageModal.DELETE_DEFAULT}`,
      hasButton: true,
      contentButton: {
        yes: 'SI',
        no: 'CANCELAR'
      }
    },
    width: '600px',
  });

  dialogRef.afterClosed().subscribe(response => {
    if (response.result == true) {
      this.deleteSecurity(obj.id);
    }
  });

}

  deleteSecurity(id: number) {
    this.spinner.show();
    this.dashboardService.delete(id).subscribe((response => {
      this.spinner.hide();
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_DASHBOARD, AppConstants.MessageModal.DELETE, MessageSeverity.success);
      this.getList();
    }),
    (error: HttpErrorResponse) => {
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_DASHBOARD, error.error.messages, MessageSeverity.error);
      this.spinner.hide();
    });
  }

  editWidgets(){
    this.enabledEditWidget = !this.enabledEditWidget;
  }

  openEditWidget(element){
    let dialogRef = this.dialog.open(WidgetModalComponent, {
      width: '1200px',
      data:{
        title: AppConstants.TitleModal.UPDATE_WIDGET,
        action: AppConstants.OperationType.UPDATE,
        idEnterprise: this.enterpriseSelect,
        enabledWrite: this.enabledWrite,
        enabledRead: this.enabledRead,
        color: this.color1,
        colorFont: this.color2,
        idDashboard: this.dashboardSelect,
        obj: element
      }
    });

    dialogRef.afterClosed().subscribe(_ => {
      if (_.result == true) {
        this.selectDashboard(this.dashboardSelect);
      }
    });
  }

  deleteWidget(element){
    let dialogRef = this.dialog.open(MessageModalComponent, {
      data: {
        title: AppConstants.TitleModal.DELETE_CONFIRMATION_TITLE,
        message: `${AppConstants.MessageModal.DELETE_DEFAULT}  ${element.name} ? ${AppConstants.MessageModal.DELETE_CAMERA_CONFIRMATION_MESSAGE2}`,
        hasButton: true,
        contentButton: {
          yes: 'SI',
          no: 'CANCELAR'
        }
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response.result == true) {
        this.deleteD(element.id);
      }
    });
  }

  deleteD(id: number) {
    this.spinner.show();
    this.dashboardService.deleteWidget(id).subscribe((response => {
      this.spinner.hide();
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_WIDGET, AppConstants.MessageModal.DELETE, MessageSeverity.success);
      this.widgets = this.widgets.filter(x => x.id != id);
    }),
    (error: HttpErrorResponse) => {
      this.alertService.showMessage(AppConstants.TitleModal.DELETE_WIDGET, error.error.messages, MessageSeverity.error);
      this.spinner.hide();
    });
  }
}
