import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetZone } from 'src/app/models/zone/getZone';
import { GetPin } from 'src/app/models/zone/getPin';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Zone';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getList(parameters: any): Observable<GetZone> {
    let httpParams = new HttpParams({
      fromObject: parameters,
    });
    return this.httpClient.get<GetZone>(`${this.REQUEST_URL}/pag`, { params: httpParams });
  }

  getListAll(idEnterprise: number,idHeadquarter: number): Observable<GetZone> {
    return this.httpClient.get<GetZone>(`${this.REQUEST_URL}/enterprise/${idEnterprise}/headquarter/${idHeadquarter}`);
  }

  getListPines(parameters: any): Observable<GetPin> {
    let httpParams = new HttpParams({
      fromObject: parameters,
    });
    return this.httpClient.get<GetPin>(`${this.REQUEST_URL}/pin`, { params: httpParams });
  }

  getListDefaultPines(parameters: any): Observable<GetPin> {
    let httpParams = new HttpParams({
      fromObject: parameters,
    });
    return this.httpClient.get<GetPin>(`${this.REQUEST_URL}/pin/default`, { params: httpParams });
  }

  createPin(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}/pin`,data);
  }

  create(data: any){
    var formData = new FormData();
    formData.append("name",data.name);
    formData.append("nomenclature",data.nomenclature);
    formData.append("description",data.description);
    formData.append("idHeadquarter",data.idHeadquarter);
    formData.append("idEnterprise",data.idEnterprise);
    formData.append("idUser",data.idUser);
    formData.append("type",data.type);
    formData.append("file",data.file);
    return this.httpClient.post<any>(`${this.REQUEST_URL}`,formData);
  }

  update(data: any){
    var formData = new FormData();
    formData.append("name",data.name);
    formData.append("nomenclature",data.nomenclature);
    formData.append("description",data.description);
    formData.append("idHeadquarter",data.idHeadquarter);
    formData.append("idEnterprise",data.idEnterprise);
    formData.append("id",data.id);
    formData.append("idUser",data.idUser);
    formData.append("type",data.type);
    formData.append("file",data.file);
    return this.httpClient.put<any>(`${this.REQUEST_URL}`,formData);
  }

  delete(id: number){
    return this.httpClient.delete<any>(`${this.REQUEST_URL}/` + id);
  }

  deletePin(data: any): Observable<any> {
    return this.httpClient.put<any>(`${this.REQUEST_URL}/delete/pin`,data);
  }
}
