<section class="contentikki">
  <div class="header-streaming">
    <img src="../../../../assets/images/logo-claro.svg">
  </div>
  <div class="slider-container">
          <div class="slider-header" >
            <h4 class="title" >{{titleMultipleStreaming}}</h4>
            <h4 class="description" >{{descriptionMultipleStreaming}}</h4>
          </div>
          <div class="slider-content">

            <div *ngFor="let item of cameras" class="slider-single">
              <div class="slider-single-image">
                <div class="slider-image" (click)="showCamera(item.token)">
                  <app-streampublic id="stream_{{item.cameraId}}" [id]="item.cameraId" [url]="item.streamingUrl" [child]=false ></app-streampublic>
                  <!--<img class="img" src="{{item.camera.streamingImage}}" alt="imga-camera" />-->
                  <!--<div class="play-body">

                    <div class="play-image" (click)="showCamera(item.camera.token)" >
                      <img class="img-play" src="../../../../assets/images/play.png" alt="imga-play" />
                    </div>
                  </div>-->
                </div>
                <div class="box"></div>
                <p class="title1">
                  <span><b>{{item.camera}}</b></span>
                  <br>
                  <span> {{item.streamingDescription}} </span>
                </p>
              </div>
              <a class="slider-single-likes" href="javascript:void(0);"></a>
            </div>
          </div>
          <div class="control-carousel" *ngIf="showControls">
            <a class="slider-left" href="javascript:void(0);"><img width="35px" src="{{iconLeft}}"></a>
            <a class="slider-right" href="javascript:void(0);"><img width="35px" src="{{iconRigth}}"></a>
          </div>
  </div>
</section>
<div class="footer-streaming">
  <div class="social-media" >
    <div>
      <img src="../../../../assets/images/logo-claro.svg" class="image-social-media" >
    </div>
    <div>
      <ul class="images-social-media">
        <li><a href="https://www.facebook.com/AmericaMovilPeruSAC" target="_blank"><img src="../../../../assets/images/social-media/facebook.svg"></a></li>
        <li><a href="https://www.instagram.com/claroperu" target="_blank"><img src="../../../../assets/images/social-media/instagram.svg"></a></li>
        <li><a href="https://twitter.com/ClaroPeru" target="_blank"><img src="../../../../assets/images/social-media/twitter.svg"></a></li>
        <li><a href="https://www.youtube.com/channel/UCsMp8a5skhqS72uCRwNdZdA" target="_blank"><img src="../../../../assets/images/social-media/youtube.svg"></a></li>
      </ul>
    </div>
  </div>
  <div class="text-copyright" >
    <h5>Todos los derechos reservados 2022</h5>
  </div>
</div>

