import { AmChart, AmChartsService } from '@amcharts/amcharts3-angular';
import { Component, Input } from '@angular/core';
declare var require: any
const gradstop = require('gradstop');

@Component({
  selector: 'app-chart-gauge',
  templateUrl: './chart-gauge.component.html',
  styleUrls: ['./chart-gauge.component.css']
})
export class ChartGaugeComponent {
  @Input('id') id : any;
  @Input('valueChart') valueChart : any;
  @Input('labelsChart') labelsChart : any;
  @Input('valueInterval') valueInterval : any;
  chart: AmChart | undefined;

  constructor(private AmCharts: AmChartsService) {}

  ngAfterViewInit(){
    console.log("id",this.id);
    console.log("value",this.valueChart);
    console.log("labelsChart",this.labelsChart);
    var bands = [];
    var labels = JSON.parse(this.labelsChart);
    labels.forEach(element => {
        element.innerRadius = "90%",
        element.startValue = element.valueStart
        element.endValue = element.valueEnd
    });

    var endValue = labels[labels.length-1].endValue;

    this.chart = this.AmCharts.makeChart("chartdiv"+this.id, {
      "type": "gauge",
      //"theme": "light",
      "arrows": [
        {
          "value": this.valueChart
        }
      ],
      "axes": [ {
        "axisThickness": 1,
        "axisAlpha": 0.2,
        "tickAlpha": 0.2,
        "valueInterval": this.valueInterval,
        "bands":labels/* [
          {
            "color": "#84b761",
            "innerRadius": "90%",
            "endValue": 40,
            "startValue": 0
          },
          {
            "color": "#fdd400",
            "innerRadius": "90%",
            "endValue": 70,
            "startValue": 40
          },
          {
            "color": "#cc4748",
            "endValue": 100,
            "innerRadius": "90%",
            "startValue": 70
          }
        ]*/,
        /*"bottomText": "50%",
        "bottomTextYOffset": 0,*/
        "endValue": "100"
      }],
      /*"titles": [
        {
          "text": "Speedometer",
          "size": 15
        }
      ],*/
      });
  }

}
