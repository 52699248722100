import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Alert, AlertParameter, OcurrenceId } from '../../models/alert/alert';
import { AlertType } from '../../models/alert/alert-type';
import { AppConstants } from 'src/app/shared/constants/app.contants';

@Injectable({
  providedIn: 'root'
})
export class AlertsServices {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'Alert';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getAlert(): Observable<Alert[]> {
    return this.httpClient.get<Alert[]>(`${this.REQUEST_URL}/GetAlert`);
  }

  getAlertarameter(id:number): Observable<AlertParameter[]> {
    return this.httpClient.get<AlertParameter[]>(`${this.REQUEST_URL}/GetAlertParameter?alertId=`+ id);
  }

  getAlertarameterById(id:number,cameraId:number): Observable<AlertParameter[]> {
    return this.httpClient.get<AlertParameter[]>(`${this.REQUEST_URL}/GetListAlertParameterByCamera/`+ id+'/'+cameraId);
  }

  getAlertExcel(parameters: any): Observable<any> {
    let httpParams = new HttpParams({
      fromObject: parameters
    });
    return this.httpClient.get<any>(`${this.REQUEST_URL}/Excel`, { params: httpParams });
  }

  getTypes() : Observable<Array<AlertType>> {
    return this.httpClient.get<Array<AlertType>>(`${this.REQUEST_URL}/types`);
  }

  CheckError(id: number): Observable<OcurrenceId> {
    return this.httpClient.post<OcurrenceId>(`${this.REQUEST_URL}/check-error`,{id: id, idUser: localStorage.getItem(AppConstants.Session.USERID)});
  }

}
