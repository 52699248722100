<div class="row title-modal" [style.color]="data.color"  [style.borderColor]="data.color">
    <div class="col-10">
        {{ data.title }}
    </div>
    <div class="col-2 text-right">
      <mat-icon [style.color]="data.color" [mat-dialog-close]="true">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="mat-typography mt-3">
    <div class="container">
      <form [formGroup]="cameraForm" action="" >
        <mat-tab-group>
          <mat-tab label="{{titleVA}}" [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' "  *ngIf="systems.va > 0 || systems.Va > 0 || systems.Streaming > 0 || systems.streaming > 0">
            <div class="row mt-2" >
              <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                      <mat-label>Sede*</mat-label>
                      <mat-select
                            [disabled]="disabledHeadquarter"
                            formControlName="idHeadquarter"
                            (selectionChange)="changeHeadquarter($event)"
                            [(ngModel)]="headquarterSelect">
                      <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                        {{headquarter.name}}
                      </mat-option>
                      </mat-select>
                      <mat-error *ngIf="f.idHeadquarter.hasError('required')">
                          La sede es <strong>requerida</strong>.
                      </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label>Nombre*</mat-label>
                    <input matInput formControlName="camera"  maxlength="500">
                    <mat-error *ngIf="f.camera.hasError('required') || f.camera.hasError('whitespace')">
                        El nombre es <strong>requerido</strong>.
                    </mat-error>
                    <mat-error *ngIf="f.camera.hasError('maxlength')">
                        El nombre es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6" *ngIf="!isAdministratorStreaming">
                  <mat-form-field class="w-100">
                      <mat-label>Modelo*</mat-label>
                      <input matInput formControlName="model" maxlength="50">
                      <mat-error *ngIf="f.model.hasError('required') || f.model.hasError('whitespace')">
                          El modelo es <strong>requerido</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-6" *ngIf="!isAdministratorStreaming">
                  <mat-form-field class="w-100">
                      <mat-label>Serie*</mat-label>
                      <input matInput formControlName="serie" maxlength="30">
                      <mat-error *ngIf="f.serie.hasError('required') || f.serie.hasError('whitespace')">
                          El número de serie es <strong>requerido</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-6" *ngIf="!isAdministratorStreaming">
                  <mat-form-field class="w-100">
                      <mat-label>MAC*</mat-label>
                      <input matInput formControlName="mac" maxlength="20">
                      <mat-error *ngIf="f.mac.hasError('required') || f.mac.hasError('whitespace')">
                          La MAC es <strong>requerida</strong>.
                      </mat-error>
                      <mat-error *ngIf="f.mac.errors?.pattern">
                        La MAC tiene formato <strong>inválido</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-6" *ngIf="!isAdministratorStreaming">
                  <mat-form-field class="w-100">
                      <mat-label>IP*</mat-label>
                      <input type="text" matInput formControlName="ipAdress"
                          [ngModelOptions]="{standalone: true}" pInputText
                          maxlength="20" (keypress)="isNumberKey($event)">
                      <mat-error *ngIf="f.ipAdress.hasError('required')">
                          La ip es <strong>requerida</strong>.
                      </mat-error>
                      <mat-error *ngIf="f.ipAdress.hasError('ip') || f.ipAdress.hasError('pattern') || f.ipAdress.hasError('maxlength') || f.ipAdress.hasError('minlength')">
                          La ip tiene formato <strong>inválido</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-6" *ngIf="systems.va > 0 || systems.Va > 0">
                  <mat-form-field class="w-100">
                      <mat-label>Zona*</mat-label>
                      <mat-select formControlName="zone">
                        <mat-option *ngFor="let z of zones" [value]="z.id">
                          {{z.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="f.zone.hasError('required')">
                        La zona es <strong>requerida</strong>.
                      </mat-error>
                  </mat-form-field>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Streaming"  *ngIf="systems.Streaming > 0 || systems.streaming > 0"  [ngClass]=" data.enabledWrite != 1 ? 'enabled-inputs' : '' " >
              <div class="row mt-2" >
                <div class="col-6">
                  <mat-form-field class="w-100">
                      <mat-label>Descripción Streaming</mat-label>
                      <input matInput formControlName="streamingDescription"  maxlength="500">
                      <mat-error *ngIf="f.streamingDescription.hasError('maxlength')">
                        La descripción de streaming es <strong>excede el límite de caracteres</strong>.
                      </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6" *ngIf="!isAdministratorStreaming">
                  <mat-form-field class="w-100">
                      <mat-label>Servidor</mat-label>
                      <mat-select formControlName="idServer">
                        <mat-option *ngFor="let z of getServers" [value]="z.id">
                          {{z.name}}
                        </mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <!--<div class="col-6" >
                  <mat-form-field class="w-100">
                      <mat-label>Dominio Streaming (ejm: https://example.com )</mat-label>
                      <input matInput formControlName="streamingDomain"  maxlength="500">
                  </mat-form-field>
                </div>-->
              <div class="col-6" *ngIf="!isAdministratorStreaming">
                <mat-form-field class="w-100">
                    <mat-label>Key Streaming*</mat-label>
                    <input matInput formControlName="streamingKey"  maxlength="500">
                </mat-form-field>
              </div>
              <div class="col-6" *ngIf="this.data.action != 0">
                <div *ngIf="imageStreaming == '' || imageStreaming == null" >
                  <button type="button" mat-raised-button (click)="fileInput.click()" style="margin-top:15px;">Añadir Imagen Preview</button>
                </div>
                <div *ngIf="imageStreaming != '' && imageStreaming != null" style="display: flex;margin-top: 0px;justify-content: center;" >
                  <button type="button" mat-raised-button (click)="showImageStreaming()" >Ver Imagen Preview</button>
                  <button type="button" mat-raised-button class="btn-danger" (click)="deleteImageStreaming()" >Borrar Imagen </button>
                </div>
                <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/jpeg, image/jpg, image/png">
              </div>
            </div>
            <br>
            <div class="row" *ngIf="data.action != 0">
              <div class="col-12" *ngIf="!isAdministratorStreaming">
                <div class="streaming w-100">
                  <mat-label class="label-title">RTMP: </mat-label>
                  <mat-label class="streaming-label">{{streamingRtmp}} <mat-icon class="cursor-click" (click)="copy(streamingRtmp)" >link</mat-icon></mat-label>
                </div>
              </div>
              <div class="col-6">
                <div class="streaming w-100" *ngIf="!isAdministratorStreaming">
                  <mat-label class="label-title">Key Streaming: </mat-label>
                  <mat-label class="streaming-label">{{streamingKey}} <mat-icon class="cursor-click" (click)="copy(streamingKey)">link</mat-icon></mat-label>
                </div>
              </div>
              <div class="col-12">
                <div class="streaming w-100">
                  <mat-label class="label-title">Link Streaming: </mat-label>
                  <mat-label class="streaming-label">{{live}} <mat-icon class="cursor-click" (click)="copy(live)">link</mat-icon></mat-label>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
    </form>
    </div>
</mat-dialog-content>
<br>
<div class="row" *ngIf="data.action == 1">
  <div class="col-lg-2 col-xs-12" style="
      align-items: center;
      justify-content: center;
      justify-items: center;
      display: flex;
    ">
    <mat-checkbox labelPosition="before" formControlName="status" (click)="clickEnabled()" [checked]="checkStatus">Habilitado</mat-checkbox>
    </div>
    <div class="col-lg-10 col-xs-12 text-right">
        <button mat-button mat-dialog-close>CANCELAR</button>
        <button mat-raised-button color="primary" (click)="openConfirmationModal()" *ngIf="!isAdministratorStreaming && data.enabledWrite && (systems.va > 0 || systems.Va > 0)">AGREGAR ALERTAS</button>
        <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" class="ml-1" (click)="openConfirmationModalSave()" *ngIf="data.enabledWrite" >GUARDAR</button>
    </div>
</div>
<div class="row" *ngIf="data.action == 2">
    <div class="col-lg-3 col-xs-12" style="
          align-items: center;
          justify-content: center;
          justify-items: center;
          display: flex;
        ">
        <mat-checkbox labelPosition="before" formControlName="status" (change)="clickEnabled()"  [checked]="checkStatus">Habilitado</mat-checkbox>
    </div>
    <div class="col-lg-9 col-xs-12 text-right">
        <button mat-button mat-dialog-close>CANCELAR</button>
        <button mat-raised-button color="primary" (click)="openConfirmationModal()" *ngIf="!isAdministratorStreaming && data.enabledWrite && (systems.va > 0 || systems.Va > 0)">AGREGAR ALERTAS</button>
        <button mat-raised-button [style.background]="data.color" [style.color]="data.colorFont" class="ml-1" (click)="openConfirmationModalSave()" *ngIf="data.enabledWrite" >GUARDAR</button>
    </div>
</div>


