
  <div class="toggle-switch-container" [id]="id">
    <div class="toggle-switch switch-vertical">
      <input id="toggle-a" type="radio" name="switch"  [checked]="value" />
      <label for="toggle-a">Manual</label> 
      <input id="toggle-b" type="radio" name="switch" [checked]="!value" />
      <label for="toggle-b">Automático</label>
      <span class="toggle-outside">
        <span class="toggle-inside"></span>
      </span>
    </div>
  </div>