export const environment = {
/*  production: true,
  sistemaWebAdmin:  "{{sistemaWebAdmin}}",//"http://192.168.1.14:5000/api",//"http://192.168.2.51:5000/api",// "https://gys-plataforma-back.azurewebsites.net/api",
  identityServer: "http://localhost:16774/connect/token",
  showroomUrl: "http://192.168.1.14:5001",//"http://192.168.2.51:5001/api/showroom",
  clientId: 'userapp',
  clientSecret: 'd60d6b88-bf36-463f-ae90-b423188bf1bf',
  grantType: 'userapp_validator',
  azAdClientId: '84b95b61-c3c9-470d-aa86-f20a6fcdbac3',
  azAdTenantId: '932d69f5-a800-4527-9dcf-eabfe5c25af4',
  azScopeId : '32550834-19c0-4a14-bf40-aa0d61c7f1ce',
  azScopeName : 'access_as_user',
  expiredLicense: "{{expiredLicense}}",//'2030-07-11',
  themeColor1: "{{themeColor1}}", //'#1f5165',
  themeColor2: "{{themeColor2}}",
  themeColorFont1: "{{themeColorFont1}}",//'#1d1d2b',
  themeColorFont2: "{{themeColorFont2}}",//'#1bc0db',
  logoPrincipal: 'assets/images/example.png',
  logoEnterprise: 'assets/images/g&s-logo.png', //'assets/images/Claro.svg',
  pathFile: '../../../../',
  camaraAlert: 'http://192.168.2.53:6001/api/showroom',
  wsUrl: "http://192.168.1.14:5000",//"http://192.168.2.51:5000",//"https://gys-plataforma-back.azurewebsites.net:5000",
  fnVA: "{{fnVA}}",
  signalR: "{{signalR}}", //https://fcplataforma.azurewebsites.net
  titleLogin: "{{titleLogin}}"
};

 SHOWROOM
export const environment = {
  production: true,
  sistemaWebAdmin: "http://192.168.2.51:5000/api",
  identityServer: "http://localhost:16774/connect/token",
  showroomUrl: "http://192.168.2.51:5001/api/showroom",
  clientId: 'userapp',
  clientSecret: 'd60d6b88-bf36-463f-ae90-b423188bf1bf',
  grantType: 'userapp_validator',
  azAdClientId: '84b95b61-c3c9-470d-aa86-f20a6fcdbac3',
  azAdTenantId: '932d69f5-a800-4527-9dcf-eabfe5c25af4',
  azScopeId : '32550834-19c0-4a14-bf40-aa0d61c7f1ce',
  azScopeName : 'access_as_user',
  expiredLicense: '2030-07-11',
  themeColor1: '#1f5165',
  themeColor2: '#1f5165',
  themeColorFont: 'white',
  logoPrincipal: 'assets/images/example.png',
  logoEnterprise: 'assets/images/g&s-logo.png',
  pathFile: '../../../../',
  camaraAlert: 'http://192.168.2.53:6001/api/showroom',
  wsUrl: "http://192.168.2.51:5000",
  signalR: "https://fcplataforma.azurewebsites.net"
};*/

/* CLARO */
production: true,
sistemaWebAdmin: "https://backend.smartechlatam.online/api",
identityServer: "http://localhost:16774/connect/token",
showroomUrl: "http://192.168.1.30:5000/api/showroom",
clientId: 'userapp',
clientSecret: 'd60d6b88-bf36-463f-ae90-b423188bf1bf',
grantType: 'userapp_validator',
azAdClientId: '84b95b61-c3c9-470d-aa86-f20a6fcdbac3',
azAdTenantId: '932d69f5-a800-4527-9dcf-eabfe5c25af4',
azScopeId : '32550834-19c0-4a14-bf40-aa0d61c7f1ce',
azScopeName : 'access_as_user',
expiredLicense: '2030-07-11',
themeColor1: '#DA291C',
themeColor2: '#DA291C',
themeColorFont: 'white',
themeColorFont1: "white",//'#1d1d2b',
themeColorFont2: "white",//'#1bc0db',
logoPrincipal: 'assets/images/Claro.svg',
logoEnterprise: 'assets/images/Claro.svg',
pathFile: '/',
camaraAlert: 'http://192.168.2.229:6001/api/showroom',
wsUrl: "http://192.168.1.14:5000",//"http://192.168.2.51:5000",//"https://gys-plataforma-back.azurewebsites.net:5000",
fnVA: "https://fnfromiot02industria04qa.azurewebsites.net",
signalR: "https://fcplataforma.azurewebsites.net", //https://fcplataforma.azurewebsites.net
titleLogin: "Plataforma de industria 4.0"
};
