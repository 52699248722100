<section class="container">
  <div class="row section-name">
    <div class="header-title-options">
        <div class="row w-100">
          <div class="col-lg-10 col-xs-12">
            <mat-form-field appearance="standard">
              <mat-label>Buscador</mat-label>
              <input matInput  (keyup)="applyUpFilter($event)" (keypress)="applyPressFilter($event)" maxlength="200"  #input>
            </mat-form-field>
          </div>
        </div>
    </div>
  </div>
</section>
<div class="container">
    <!--<div class="row mt-3 section-name">
        <div class="col">
            Usuarios
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            Número máximo de usuarios permitidos: {{countMaxUserByEnterprise}}
        </div>
    </div>-->
    <!--< <section class="filter mt-3">
        <div class="row">
           div class="col-12 col-lg-8">
                <div class="row">
                    <div class="col-12 col-lg-6" *ngIf="isAdminGys">
                        <mat-form-field class="w-100">
                            <mat-label>Empresa</mat-label>
                            <mat-select
                                        (selectionChange)="changeEnterprise($event)"
                                        [(ngModel)]="enterpriseSelect">
                                 <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                                    {{enterprise.businessName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-lg-6">
                              <mat-form-field class="w-100">
                                  <mat-label>Sede</mat-label>
                                  <mat-select
                                              (selectionChange)="changeHeadquarter($event)"
                                              [(ngModel)]="headquarterSelect">
                                      <mat-option [value]="0" >
                                          Todas
                                      </mat-option>
                                      <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                                          {{headquarter.name}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 col-md-10 col-xs-12"></div>
            <div class="col-lg-2 col-md-2 col-xs-12 text-right">
                <button mat-raised-button color="primary" class="color-white w-100 p-0" (click)="openAddModal()">AGREGAR</button>
            </div>
        </div>
    </section>
 -->
    <section class="table-responsive">
      <table class="table" mat-table [dataSource]="dataSource" >

        <ng-container matColumnDef="index">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1"> # </th>
          <td class="text-center" *matCellDef="let element; let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
          </td>
        </ng-container>
        <!-- Position Column -->
        <!--<ng-container matColumnDef="headquarter">
          <th scope="col" class="text-center" *matHeaderCellDef> Sede </th>
          <td class="text-center" *matCellDef="let element">
            <span *ngIf="element.headquarter == '' || element.headquarter == null">TODAS</span>
            <span *ngIf="element.headquarter != ''">{{element.headquarter}}</span>
          </td>
        </ng-container>-->
        <!-- Position Column -->
        <ng-container matColumnDef="username">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Correo </th>
          <td class="text-center" *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="fullName">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Apellidos y Nombres </th>
          <td class="text-center" *matCellDef="let element"> {{element.fullName}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="role">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Rol </th>
          <td class="text-center" *matCellDef="let element"> {{element.role}} </td>
        </ng-container> 

        <ng-container matColumnDef="sendEmail">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Envío Correo</th>
          <td class="text-center" onclick="return false;" *matCellDef="let element">
            <mat-icon *ngIf="element.sendEmail">check</mat-icon>
            <mat-icon *ngIf="!element.sendEmail">close</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="enabled">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Habilitado</th>
          <td class="text-center" onclick="return false;" *matCellDef="let element">
            <mat-icon *ngIf="element.enabled">check</mat-icon>
            <mat-icon *ngIf="!element.enabled">close</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Acciones </th>
          <td class="text-center" *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="mainMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #mainMenu="matMenu">
              <button mat-menu-item (click)="openEditModal(element)" *ngIf="enabledWrite" ><mat-icon color="primary" class="icon-cursor" [style.color]="color1">create</mat-icon> Editar</button>
              <button mat-menu-item (click)="openEditModal(element)" *ngIf="!enabledWrite && enabledRead"  ><mat-icon color="primary" class="icon-cursor" [style.color]="color1">remove_red_eye</mat-icon> Ver</button>
              <button mat-menu-item (click)="openAssign(element)"><mat-icon color="primary" class="icon-cursor" [style.color]="color1">assignment</mat-icon> Asignar</button>
              <button mat-menu-item (click)="delete(element)"  *ngIf="enabledWrite"><mat-icon color="primary" class="icon-cursor"  [style.color]="color1">delete</mat-icon> Eliminar</button>
            </mat-menu>
            <!--
            <mat-icon color="primary" class="icon-cursor" matTooltip="Editar" (click)="openEditModal(element)">create</mat-icon>
            <mat-icon color="primary" class="icon-cursor" matTooltip="Eliminar" (click)="delete(element)">delete</mat-icon>-->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator #paginator
                [length]="length"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                [hidePageSize]="true"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="getServerData($event)"
                >
      </mat-paginator>
    </section>
</div>

<button mat-fab class="fab-button" [style.background]="color1" [style.color]="color2" aria-label="Add icon" *ngIf="enabledWrite"  (click)="openAddModal()" >
  <mat-icon>add</mat-icon>
</button>
