import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-screenshot',
  templateUrl: './modal-screenshot.component.html',
  styleUrls: ['./modal-screenshot.component.css']
})
export class ModalScreenshotComponent implements OnInit {
  screenshot : any; 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,) {
  }

  ngOnInit(): void {
    /*var container = document.querySelector('.divCanvasScreenshot');
    container.appendChild(this.data.screenshot);*/
    document.body.appendChild(this.data.screenshot);
  }

}
