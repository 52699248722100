import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserViewModel, User, UserPut } from '../../models/user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'User';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getUsers(parameters: any): Observable<UserViewModel[]> {
    let httpParams = new HttpParams({
      fromObject: parameters,
    });
    return this.httpClient.get<UserViewModel[]>(`${this.REQUEST_URL}/pag`, { params: httpParams });
  }

  getUserEnterprisesHeadquarters(idUser:number): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/enterprise-headquarter/${idUser}`);
  }

  getUserEnterpriseHeadquarters(data): Observable<any>{
    return this.httpClient.post<any>(`${this.REQUEST_URL}/list-enterprise-headquarters`, data);
  }

  createoUpadteEnterpriseHeadquarter(data): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/enterprise-headquarter`,data);
  }

  deleteEnterpriseHeadquarter(data): Observable<any> {
    return this.httpClient.put<any>(`${this.REQUEST_URL}/enterprise-headquarter`,data);
  }

  createUser(enterprise: User): Observable<Error> {
    return this.httpClient.post<Error>(`${this.REQUEST_URL}/CreateUser`, enterprise);
  }

  updateUser(model: UserPut): Observable<Error> {
    return this.httpClient.put<Error>(`${this.REQUEST_URL}/UpdateUser`, model);
  }

  deleteUser(userId: number) {
    return this.httpClient.delete(`${this.REQUEST_URL}/DeleteUser/${userId}`);
  }

  getUserById(userId: number): Observable<UserViewModel> {
    return this.httpClient.get<UserViewModel>(`${this.REQUEST_URL}/GetUser/${userId}`);
  }

  test() {
    return this.httpClient.get<string>(`${this.REQUEST_URL}/test`);
  }

  updateUserMyProfile(data): Observable<any>{
    return this.httpClient.post<number>(`${this.REQUEST_URL}/profile`, data);
  }

}
