import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetTheme } from 'src/app/models/theme/getTheme';
import { Theme } from 'src/app/models/theme/theme';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private httpClient: HttpClient) { }

  private POST_ENDPOINT: string = 'Theme';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getTheme(enterpriseId: number): Observable<GetTheme> {
    return this.httpClient.get<GetTheme>(`${this.REQUEST_URL}/${enterpriseId}`);
  }

  updateTheme(model: Theme): Observable<number> {
    return this.httpClient.put<number>(`${this.REQUEST_URL}/${model.enterpriseId}`, model);
  }
}
