import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetPanelIOTActivity } from 'src/app/models/panelIOT/getPanelIOTActivity';
import { GetPanelIOTSensor } from 'src/app/models/panelIOT/getPanelIOTSensor';
import { GetReportPanelIOTSensor } from 'src/app/models/panelIOT/getReportPanelIOTSensor';
import { PanelIOTSensorModel } from 'src/app/models/panelIOT/panelIOTSensor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PanelIOTService {
  dataSensors = [];
  updatePanelIOT = 0;

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = 'PanelIOT';
  private BASE_URL: string = `${environment.sistemaWebAdmin}`;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getDataStreamingSensors(){
    return this.dataSensors;
  }

  setDataStreamingSensors(data){
    this.dataSensors = data;
  }

  createSensor(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}/sensor`,data);
  }

  updateSensor(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}/sensor`,data);
  }

  createActuator(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}/actuator`,data);
  }

  updateActuator(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}/actuator`,data);
  }

  deleteSensor(id){
    return this.httpClient.delete<any>(`${this.REQUEST_URL}/sensor/`+id,id);
  }

  getList(filter: any): Observable<GetPanelIOTSensor> {
    let httpParams = new HttpParams({
      fromObject: filter,
    });
    return this.httpClient.get<GetPanelIOTSensor>(`${this.REQUEST_URL}`,{ params: httpParams });
  }

  getSensorPanelIOT(id: number): Observable<PanelIOTSensorModel> {
    return this.httpClient.get<PanelIOTSensorModel>(`${this.REQUEST_URL}/sensor-panel/`+id);
  }

  updateSensorPanelIOT(data: any) {
    var dataS = {
      sensors: data
    }
    return this.httpClient.put<any>(`${this.REQUEST_URL}/sensor/pos`,dataS);
  }

  reportSensors(data): Observable<GetReportPanelIOTSensor> {
    return this.httpClient.post<GetReportPanelIOTSensor>(`${this.REQUEST_URL}/report/sensor`,data);
  }

  reportAlerts(data): Observable<GetReportPanelIOTSensor> {
    return this.httpClient.post<GetReportPanelIOTSensor>(`${this.REQUEST_URL}/report/alert`,data);
  }

  reportActivity(data): Observable<GetPanelIOTActivity> {
    return this.httpClient.post<GetPanelIOTActivity>(`${this.REQUEST_URL}/report/actuator/activity`,data);
  }
  /*let httpParams = new HttpParams({
      fromObject: filter,
    });
    return this.httpClient.post<GetPanelIOTActivity>(`${this.REQUEST_URL}/`,{ params: httpParams });
  */ 
  createActivity(data: any){
    return this.httpClient.post<any>(`${this.REQUEST_URL}/actuator/activity`,data);
  }

  setUpdatePanelIOT(value){
    this.updatePanelIOT = value;
  }

  getUpdatePanelIOT(){
    return this.updatePanelIOT;
  }

  updateActuatorData(data: any){
    return this.httpClient.put<any>(`${this.REQUEST_URL}/actuator/data`,data);
  }
}
