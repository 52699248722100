<section class="container">
  <div class="row section-name">
    <div class="header-title-options">
        <div class="row w-100">
          <div class="col-lg-10 col-xs-12">
            <mat-form-field appearance="standard">
              <mat-label>Buscador</mat-label>
              <input matInput  (keyup)="applyUpFilter($event)" (keypress)="applyPressFilter($event)" maxlength="200"  #input>
            </mat-form-field>
          </div>
        </div>
    </div>
  </div>
</section>
<div class="container">
  <!--<div class="row mt-3 section-name">
    <div class="col">
      Cámaras
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      Podrás modificar la información de las cámaras que utiliza tu empresa
    </div>
  </div>

  <section class="filter mt-3">
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Empresa</mat-label>
              <mat-select
                        (selectionChange)="changeEnterprise($event)"
                          [(ngModel)]="enterpriseSelect">
                <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.enterpriseId">
                  {{enterprise.businessName}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field class="w-100">
                <mat-label>Sede</mat-label>
                <mat-select
                            (selectionChange)="changeHeadquarter($event)"
                            [(ngModel)]="headquarterSelect">
                    <mat-option [value]="0" >
                        Todas
                    </mat-option>
                    <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                        {{headquarter.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-2 offset-lg-2 my-auto">
        <button mat-raised-button color="primary" class="color-white w-100" (click)="openAddModal()">AGREGAR</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p><b>API CÁMARAS IFRAME: </b> {{iframe}}</p>
      </div>
    </div>
  </section>-->

  <section class="table-responsive">
    <table class="table" mat-table [dataSource]="dataSource" >

      <ng-container matColumnDef="index">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> # </th>
        <td class="text-center" *matCellDef="let element; let i = index">
          {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
        </td>
      </ng-container>


      <ng-container matColumnDef="headquarter">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Sede </th>
        <td class="text-center" *matCellDef="let element"> {{element.headquarter}} </td>
      </ng-container>

      <ng-container matColumnDef="model">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Modelo </th>
        <td class="text-center" *matCellDef="let element"> {{element.model}} </td>
      </ng-container>

      <ng-container matColumnDef="ipAdress">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Ip </th>
        <td class="text-center" *matCellDef="let element"> {{element.ipAdress}} </td>
      </ng-container>

      <ng-container matColumnDef="camera">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Cámara</th>
        <td class="text-center" *matCellDef="let element"> {{element.camera}} </td>
      </ng-container>

      <ng-container matColumnDef="zone" *ngIf="!isAdministratorStreaming">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Zona</th>
        <td class="text-center" *matCellDef="let element"> {{element.zone}} </td>
      </ng-container>

      <ng-container matColumnDef="alerts" *ngIf="!isAdministratorStreaming">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Alertas</th>
        <td class="text-center" *matCellDef="let element"> {{element.alerts}} </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2">Habilitado</th>
        <td class="text-center"  *matCellDef="let element">
          <mat-icon *ngIf="element.active">check</mat-icon>
          <mat-icon *ngIf="!element.active">close</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th scope="col" class="text-center" *matHeaderCellDef [style.background]="color1" [style.color]="color2"> Acciones </th>
        <td class="text-center" *matCellDef="let element">
          <mat-icon color="primary" class="icon-cursor" [style.color]="color1" *ngIf="enabledWrite"  matTooltip="Editar" (click)="openEditModal(element)">create</mat-icon>
          <mat-icon color="primary" class="icon-cursor" [style.color]="color1" *ngIf="enabledRead && !enabledWrite"  matTooltip="Ver" (click)="openEditModal(element)">remove_red_eye</mat-icon>
          <mat-icon color="primary" class="icon-cursor" [style.color]="color1" matTooltip="Eliminar" *ngIf="enabledWrite" (click)="delete(element)">delete</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-background"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [hidePageSize]="true"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="getServerData($event)">
    </mat-paginator>
  </section>

</div>

<button mat-fab class="fab-button" *ngIf="enabledWrite" [style.background]="color1" [style.color]="color2" aria-label="Add icon" (click)="openAddModal()" >
  <mat-icon>add</mat-icon>
</button>
